import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Flex,
  Text,
  Icon,
  HStack,
} from '@chakra-ui/react'

export type NewAdditionalInformation = {
  question: string
  required: boolean
  label: string
  questionType: string
}

export type Schema = {
  additionalInformation: NewAdditionalInformation[]
  newAdditionalInformation: NewAdditionalInformation
}

import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Button, FormChildrenProps } from 'ui'

export function IconMenuItem({
  label,
  onClick,
}: {
  label: string
  onClick?: () => void
}) {
  return (
    <MenuItem as={Box} onClick={onClick}>
      <HStack spacing="0" gap="1">
        <Icon boxSize="5" as={PlusCircleIcon} />
        <Text>{label}</Text>
      </HStack>
    </MenuItem>
  )
}

export type AddQuestionMenuProps = {
  formik: FormChildrenProps<Schema>
  onSelect: () => void
  countIndex: number
}

export function AddQuestionMenu(props: AddQuestionMenuProps) {
  const setQuestionType = async (questionType: string) => {
    await props.onSelect()
    await props.formik.setFieldValue(
      `additionalInformation.${props.countIndex}.questionType`,
      questionType,
    )
  }

  return (
    <Flex
      alignItems="left"
      w="100%"
      _hover={{
        backgroundColor: 'gray.100',
      }}
    >
      <Box px="2" w="100%">
        <Box mt="-10px" position="absolute">
          <Menu preventOverflow>
            <MenuButton
              as={Button}
              iconName="plusCircle"
              iconPosition="left"
              iconBoxSize="5"
              label="Add Question"
              variant="unstyled"
              size="md"
              color="dark.600"
              fontWeight="lg"
            />
            <MenuList minW="0" zIndex="2">
              <IconMenuItem
                label="Single Text Line"
                onClick={async () => {
                  setQuestionType('text')
                }}
              />
              <IconMenuItem
                label="Multi Text Line"
                onClick={async () => {
                  setQuestionType('multi')
                }}
              />
              <IconMenuItem
                label="Address Input"
                onClick={async () => {
                  setQuestionType('address')
                }}
              />
              <IconMenuItem
                label="Number Input"
                onClick={async () => {
                  setQuestionType('number')
                }}
              />
              <IconMenuItem
                label="Phone Number"
                onClick={async () => {
                  setQuestionType('phone')
                }}
              />
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </Flex>
  )
}

export default AddQuestionMenu
