import { tw } from '@nickeltech/brise'
import ContainerProps, { NickelFillProps } from '../../types'

export type NickelCondensedProps = ContainerProps & NickelFillProps

export const NickelCondensed = tw((props: NickelCondensedProps) => (
  <svg
    width="28"
    height="28"
    viewBox="0 -1 28 20"
    xmlns="http://www.w3.org/2000/svg"
    {...{ ...props, fill: 'none' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78936 0.783095C8.15236 0.519242 7.43664 0.519242 6.79964 0.783095L3.12636 2.30462C2.48936 2.56847 1.98327 3.07456 1.71941 3.71156L0.19789 7.38484C-0.0659631 8.02184 -0.0659634 8.73756 0.19789 9.37456L1.71941 13.0478C1.98327 13.6848 2.48936 14.1909 3.12635 14.4548L6.79964 15.9763C7.43664 16.2402 8.15236 16.2402 8.78936 15.9763L12.4626 14.4548C13.0996 14.1909 13.6057 13.6848 13.8696 13.0478L15.3911 9.37456C15.655 8.73756 15.655 8.02184 15.3911 7.38484L13.8696 3.71156C13.6057 3.07456 13.0996 2.56847 12.4626 2.30462L8.78936 0.783095ZM11.2548 6.33794C11.6424 5.49514 10.7721 4.62506 9.92916 5.0126C8.60405 5.62179 7.07887 5.62179 5.75376 5.0126C4.9108 4.62506 4.04056 5.49514 4.42816 6.33794C5.03748 7.6628 5.03748 9.18769 4.42816 10.5125C4.04056 11.3553 4.9108 12.2254 5.75376 11.8379C7.07887 11.2287 8.60405 11.2287 9.92916 11.8379C10.7721 12.2254 11.6424 11.3553 11.2548 10.5125C10.6454 9.18769 10.6454 7.6628 11.2548 6.33794Z"
      fill={props.fill}
    />
  </svg>
))``

export default NickelCondensed
