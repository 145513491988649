import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

export const InputSizes = ['small', 'base', 'large']

const selectAmountInput = definePartsStyle((props) => {
  const { colorScheme: c } = props
  return {
    field: {
      fontSize: '3xl',
      fontWeight: 'medium',
      color: 'gray.800',
      border: '0px solid',
      _invalid: {
        borderColor: 'transparent',
        boxShadow: '0 0 0 0!important',
      },
      _dark: {
        bg: 'whiteAlpha.50',
      },
      _hover: {
        bg: 'gray.50',
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _focus: {
        border: '0px solid',
        boxShadow: 'none !important',
        _dark: {
          bg: 'whiteAlpha.100',
        },
      },
      _focusVisible: {
        boxShadow: 'none !important',
      },
    },
    addon: {
      border: '0px solid',
      borderColor: 'transparent',
      borderTopLeftRadius: 'full',
      borderBottomLeftRadius: 'full',
      bg: `${c}.500`,
      color: 'white',
      _dark: {
        bg: `${c}.300`,
        color: `${c}.900`,
      },
    },
    element: {
      bg: 'white',
      rounded: 'full',
      border: '0',
      borderColor: 'gray.100',
      _dark: {
        bg: 'whiteAlpha.50',
        borderColor: 'whiteAlpha.100',
      },
    },
  }
})

const InputStyle = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'gray.200',
    borderRadius: 'base',
    background: 'transparent',
    _placeholder: {
      color: 'gray.400',
    },
    _hover: {
      borderColor: 'gray.600',
    },
    _focus: {
      boxShadow: '0 0 0 1px #292524 !important',
    },
    _focusVisible: {
      boxShadow: '0 0 0 1px #292524 !important',
    },
    color: 'gray.800',
    _disabled: {
      color: 'gray.800',
      background: 'gray.100',
      border: '1px solid',
      borderColor: 'gray.200',
      opacity: '1.0',
      _hover: {
        borderColor: 'gray.200',
      },
    },
    _invalid: {
      borderColor: 'red.500',
      boxShadow: '0 0 0 1px #e02c1f !important',
      borderRadius: 'base !important',
    },
  },
  addon: {
    border: '1px solid',
    padding: '9px 12px',
    background: 'gray.100',
    borderColor: 'gray.200',
    borderRadius: 'base',
    color: 'gray.500',
    fontSize: 'small',
    _focusVisible: {
      borderColor: 'gray.800',
      boxShadow: '0 0 0 1px #292524 !important',
    },
  },
})

const InputSize = {
  small: definePartsStyle({
    field: defineStyle({
      px: '12px',
      py: '9px',
      fontSize: 'small',
    }),
  }),
  base: definePartsStyle({
    field: defineStyle({
      px: '12px',
      py: '13px',
      fontSize: 'small',
    }),
  }),
  big: definePartsStyle({
    field: defineStyle({
      px: '12px',
      py: '8px',
      fontSize: 'base',
    }),
  }),
  large: definePartsStyle({
    field: defineStyle({
      px: '12px',
      py: '16px',
      fontSize: 'base',
    }),
  }),
}

export const inputTheme = defineMultiStyleConfig({
  baseStyle: InputStyle,
  variants: {
    selectAmountInput: selectAmountInput,
  },
  sizes: InputSize,
  defaultProps: {
    size: 'small',
  },
})
