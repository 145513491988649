import { PaymentSummary } from './PaymentSummary'
import {
  DeliveryMethodSelectors,
  Fee,
  Loan,
  PaymentMethodSelectors,
} from './types'

const CREDIT_CARD_FEE = 0.029
const CHECK_FEE_CENTS = 200
type PaymentPerspective = 'customer' | 'merchant'

function calculateFeeAmountCents(
  amountDueCents: number,
  feePercent: number,
  paymentPerspective: PaymentPerspective,
) {
  if (paymentPerspective === 'merchant') {
    return Math.round(amountDueCents * feePercent)
  }

  // We need to charge slightly more than the amount due to cover the fee
  // See https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
  // for more details
  const increasedFeePercentage = 1 / (1 - feePercent) - 1

  const amountDueFeeCents = amountDueCents * increasedFeePercentage

  return Math.round(amountDueFeeCents)
}

function calculateAmountDueWithFeeCents(
  amountDueCents: number,
  feePercent: number,
  paymentPerspective: PaymentPerspective,
) {
  return (
    amountDueCents +
    calculateFeeAmountCents(amountDueCents, feePercent, paymentPerspective)
  )
}

function calculateCreditCardPaymentSummary(
  amountCents: number,
  passThroughPercentage: number,
  deliveryMethod: DeliveryMethodSelectors = 'ACH',
  paymentPerspective: PaymentPerspective,
  loan?: Loan,
): PaymentSummary {
  const passthroughFee = CREDIT_CARD_FEE * (passThroughPercentage / 100)
  const creditCardFeeAmonut = calculateFeeAmountCents(
    amountCents,
    passthroughFee,
    paymentPerspective,
  )

  const additionalAmount = deliveryMethod === 'Check' ? CHECK_FEE_CENTS : 0
  return new PaymentSummary(
    calculateAmountDueWithFeeCents(
      amountCents,
      passthroughFee,
      paymentPerspective,
    ) + additionalAmount,
    [
      {
        name: 'Credit Card Fee',
        amountCents: creditCardFeeAmonut,
        type: 'PLATFORM_FEE',
      },
      deliveryMethod === 'Check'
        ? {
            name: 'Check Fee',
            amountCents: CHECK_FEE_CENTS,
            type: 'CHECK_FEE',
          }
        : null,
    ].filter((x): x is Fee => !!x),
    'Credit Card',
    loan,
  )
}

export function calculatePaymentSummaryForMerchant(
  amountCents: number,
  selected: PaymentMethodSelectors,
  deliveryMethod: DeliveryMethodSelectors,
) {
  // Fee passthrough is always zero because the merchant is the one paying.
  // This gives us a rate of 2.9% instead of 2.99% if the passthrough were 100
  if (selected == 'Credit Card') {
    return calculateCreditCardPaymentSummary(
      amountCents,
      100,
      deliveryMethod,
      'merchant',
    )
  }

  if (selected == 'ACH Transfer') {
    const additionalAmount = deliveryMethod === 'Check' ? CHECK_FEE_CENTS : 0
    return new PaymentSummary(
      amountCents + additionalAmount,
      deliveryMethod === 'Check'
        ? [
            {
              name: 'Check Fee',
              amountCents: CHECK_FEE_CENTS,
              type: 'CHECK_FEE',
            },
          ]
        : [],
      'ACH Transfer',
    )
  }

  return new PaymentSummary(amountCents, [])
}

export function calculatePaymentSummary(
  amountCents: number,
  passThroughPercentage: number,
  selected: PaymentMethodSelectors,
  loan?: Loan,
): PaymentSummary {
  const loanFeeAmountCents = loan?.feeAmountInCents || 0
  const baseAmountCents = amountCents + loanFeeAmountCents
  if (selected == 'Credit Card') {
    return calculateCreditCardPaymentSummary(
      baseAmountCents,
      passThroughPercentage,
      'ACH',
      'customer',
      loan,
    )
  }

  if (selected == 'ACH Transfer') {
    return new PaymentSummary(baseAmountCents, [], 'ACH Transfer', loan)
  }

  return new PaymentSummary(baseAmountCents, [], undefined, loan)
}

export function replaceTrailingZeroes(value: string) {
  // Replaces any trailing zeroes with an empty string
  // See https://stackoverflow.com/questions/26299160/using-regex-how-do-i-remove-the-trailing-zeros-from-a-decimal-number
  return value.replace(
    /^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/,
    '$1$2$3',
  )
}

export function calculateFeePercentage(feePassthroughPercent: number) {
  const feePercent = CREDIT_CARD_FEE * (feePassthroughPercent / 100)
  const increasedFeePercentage = 1 / (1 - feePercent) - 1

  return (increasedFeePercentage * 100).toFixed(2)
}

export function calculateFeePercentageForMerchant(
  feePassthroughPercent: number,
) {
  return (CREDIT_CARD_FEE * (feePassthroughPercent / 100) * 100).toFixed(2)
}

export function getFeeString(feePassthroughPercent: number) {
  const feePercent = calculateFeePercentage(feePassthroughPercent)
  if (feePercent == '0.00') {
    return 'Free'
  }

  return `${replaceTrailingZeroes(
    calculateFeePercentage(feePassthroughPercent),
  )}% Fee`
}

export function getFeeStringV2(feePassthroughPercent: number) {
  const feeString = getFeeString(feePassthroughPercent)

  if (feeString === 'Free') {
    return 'No Fees'
  }

  return feeString
}
