import { FC, ReactNode } from 'react'
import { ContainerProps, SizeValue } from './types'
import { property, tw } from '@nickeltech/brise'
import * as OutlineHeroIcons from '@heroicons/react/24/outline'
import * as HeroIcons from '@heroicons/react/24/solid'

export type IconName =
  | 'arrowCircleDown'
  | 'chevronDown'
  | 'documentDuplicate'
  | 'arrowPath'
export type IconVariant = 'solid' | 'outline'

export const IconNameValues: Array<IconName> = [
  'arrowCircleDown',
  'chevronDown',
  'documentDuplicate',
  'arrowPath',
]

export type IconProps = {
  name: IconName
  size?: SizeValue
  disabled?: boolean
  outline?: boolean
  additionalProps?: Record<string, unknown>
} & ContainerProps &
  unknown

export const IconMap: Record<IconName, Record<IconVariant, FC | ReactNode>> = {
  arrowCircleDown: {
    solid: HeroIcons.ArrowDownCircleIcon,
    outline: OutlineHeroIcons.ArrowDownCircleIcon,
  },
  chevronDown: {
    solid: HeroIcons.ChevronDownIcon,
    outline: OutlineHeroIcons.ChevronDownIcon,
  },
  documentDuplicate: {
    solid: HeroIcons.DocumentDuplicateIcon,
    outline: OutlineHeroIcons.DocumentDuplicateIcon,
  },
  arrowPath: {
    solid: HeroIcons.ArrowPathIcon,
    outline: OutlineHeroIcons.ArrowPathIcon,
  },
}

export const IconDisabledStyle = property<Pick<IconProps, 'disabled'>>(
  'disabled',
  {
    false: '',
    true: 'pointer-events-none',
  },
  'false',
)

export const OutlineStyle = property<Pick<IconProps, 'outline'>>(
  'outline',
  {
    false: '',
    true: 'stroke-1',
  },
  'false',
)

export const IconSizeStyle = property<IconProps>(
  'size',
  {
    '2xs': 'w-[11px] h-[11px]',
    xs: 'w-3 h-3',
    small: 'w-3.5 h-3.5',
    base: 'w-4 h-4',
    medium: 'w-[18px] h-[18px]',
    large: 'w-5 h-5',
    xl: 'w-6 h-6',
    '2xl': 'w-8 h-8',
    '3xl': 'w-12 h-12',
  },
  'small',
)

export const Icon = tw((props: IconProps) => {
  let iconNode

  if (props.outline) {
    iconNode = IconMap[props.name].outline
  } else {
    iconNode = IconMap[props.name].solid
  }

  const styledIconNode = tw<IconProps>(iconNode as FC)`
    ${IconDisabledStyle}
    ${IconSizeStyle}
    ${OutlineStyle}
  `

  return styledIconNode(props)
})``

export default Icon
