import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'
import Dropdown from '../DropdownV2'
import TextFilterMenu, {
  TextFilterMenuItemProps,
} from '../FilterDropdown/TextFilterMenu'
import Row from '../v2/layout/Row'
import capitalize from 'lodash/capitalize'

export type Filter = {
  label: string
  value: string
}

export type StatusFilterProps = {
  items: Array<TextFilterMenuItemProps>
  onClick: (value: string) => void
  value?: string
}

const ChevronDown = tw(ChevronDownIcon)`
    w-4
    h-3
    stroke-gray-800
    stroke-2
`

const Text = tw.div<ContainerProps>`
    text-xs
    font-medium
    text-gray-600
`

const Container = tw(Row)`
    rounded
    px-2
    py-1
`

const StatusFilter = (props: StatusFilterProps) => {
  return (
    <Row gap="small" y="center">
      <Dropdown
        className="border !border-gray-300 h-[26px] relative top-[1px]"
        label={
          <Container y="center" gap="small">
            <Text>Status: {capitalize(props.value)}</Text>
            <ChevronDown />
          </Container>
        }
      >
        <Menu.Items className="absolute divide-y divide-gray-100 focus:outline-none top-[35px] z-10">
          <TextFilterMenu
            items={props.items}
            selected={
              props.value
                ? props.items.findIndex((item) => item.value === props.value)
                : 0
            }
            onClick={props.onClick}
          />
        </Menu.Items>
      </Dropdown>
    </Row>
  )
}

export default StatusFilter
