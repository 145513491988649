import { useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { Column, OverlaySectionPayouts } from 'ui'
import { TransactionStatusValue } from 'ui/src/components/v3/TransactionStatus'
import formatter from 'ui/src/formatter'
import { GetRefundDocument, GetRefundQuery } from '../../../operations-types'
import { PaymentHelper } from '../PaymentHelper'
import AdditionalPaymentDetail from '../AdditionalPaymentDetail'
import { OverlayHeader } from '../OverlayHeader'
import AmountHeader from '../PaymentAmountSubmitted'
import PaymentDetails, {
  BillingAddress,
  ContactInfo,
  PaymentMethod,
} from '../PaymentDetails'
import PaymentElements from '../PaymentElements'
import { reportErrorIfExists } from '../../../lib/utils'

type Refund = NonNullable<GetRefundQuery['refund']>['refund']

function getPayout(refund: Refund) {
  const payout = refund?.payout
  if (payout) {
    return [
      {
        id: payout.id,
        paidAt: payout.paidAt
          ? moment(payout.paidAt).format('MM/DD/YYYY')
          : undefined,
        refundAmount: formatter.format((refund.amountCents || 0) / 100),
      },
    ]
  }

  return []
}

type RefundInnerOverlayProps = {
  refundId: string
  onPaymentClick?: (paymentId: string) => void
  onPayoutClick?: (payoutId: string) => void
  exitOverlay: () => void
  onBack?: () => void
}

export default function RefundInnerOverlay({
  refundId,
  onPaymentClick,
  onPayoutClick,
  exitOverlay,
  onBack,
}: RefundInnerOverlayProps) {
  const { loading, data, error } = useQuery(GetRefundDocument, {
    variables: { refundId: refundId },
  })

  reportErrorIfExists(data?.refund?.error?.message || error)

  if (loading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const refund = data?.refund?.refund
  const payment = refund?.charge?.payment

  const paymentMethod = payment?.paymentMethod
  const paymentHelper = new PaymentHelper(payment!)

  const payout = getPayout(refund)

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <OverlayHeader
        onClose={() => exitOverlay()}
        onBack={onBack}
        transactionStatus={
          capitalize(
            data?.refund?.refund?.status || '',
          ) as TransactionStatusValue
        }
      />
      <AmountHeader
        label={'Refund Amount'}
        amount={(((refund?.amountCents || 0) * 1.0) / 100).toLocaleString(
          'en-US',
          { style: 'currency', currency: 'USD' },
        )}
        paymentSubmitted={moment(parseInt(refund?.createdAt || '')).format(
          'MMMM DD, YYYY',
        )}
      />
      <PaymentElements
        {...{
          paymentNode: (
            <Column gap="large" wGrow className="w-full">
              <PaymentDetails
                {...{
                  headerLabel: 'Original Payment',
                  linkText: 'View Payment',
                  onLinkClick: onPaymentClick
                    ? () => onPaymentClick(payment?.id!)
                    : undefined,
                  amountSubmitted: formatter.format(
                    paymentHelper.submittedAmountDollars(),
                  ),
                  feesCollected: formatter.format(
                    paymentHelper.platformFeeDollars(),
                  ),
                  chargedAmount: formatter.format(
                    paymentHelper.chargedAmountDollars(),
                  ),
                  netPayment: formatter.format(
                    paymentHelper.amountWithoutFeeDollars(),
                  ),
                  paymentMethod: paymentMethod?.card?.last4 ? (
                    <PaymentMethod
                      card={{
                        last4: paymentMethod?.card?.last4,
                        brand: paymentMethod?.card?.brand || '',
                      }}
                    />
                  ) : (
                    <PaymentMethod
                      achDebit={{
                        routingNumber: paymentMethod?.achDebit?.routingNumber!,
                      }}
                    />
                  ),
                  billingAddress: (
                    <BillingAddress
                      companyName={payment?.companyName}
                      address={paymentMethod?.billingDetails?.address}
                    />
                  ),
                  contactInfo: (
                    <ContactInfo
                      name={paymentMethod?.billingDetails?.name}
                      email={paymentMethod?.billingDetails?.email}
                    />
                  ),
                }}
              />
              <OverlaySectionPayouts
                title="Payouts"
                payouts={payout}
                initialExpanded
                onPayoutClick={onPayoutClick}
              />
            </Column>
          ),
          detailNode: (
            <AdditionalPaymentDetail
              {...{
                reference: {
                  paymentId: payment?.id || '',
                  refundId: refund?.id || '',
                  reference: refund?.externalRefundId || '',
                },
              }}
            />
          ),
        }}
      />
    </Flex>
  )
}
