import { BreadcrumbLink } from '@chakra-ui/react'

type BreadcrumbLinkItemProps = {
  text: string
  isCurrentPage: boolean
  onClick?: () => void
  isDisabled?: boolean
}

export function BreadcrumbLinkItem({
  text,
  isCurrentPage,
  onClick,
  isDisabled,
}: BreadcrumbLinkItemProps) {
  return (
    <BreadcrumbLink
      fontSize="md"
      isCurrentPage={isDisabled}
      color={isCurrentPage && !isDisabled ? 'gray.900' : 'gray.400'}
      fontWeight={isCurrentPage && !isDisabled ? 'medium' : 'normal'}
      onClick={() => !isDisabled && onClick && onClick()}
      cursor="!none"
    >
      {text}
    </BreadcrumbLink>
  )
}
