import { useQuery } from '@apollo/client'
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
  Divider,
  Spacer,
  VStack,
} from '@chakra-ui/react'
import {
  CurrencyDollarIcon,
  Square3Stack3DIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import currency from 'currency.js'
import moment from 'moment'
import { useRef, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import {
  Button,
  HFlex,
  InformationCard,
  Overlay,
  OverlayHeader,
  VFlex,
} from 'ui'
import { TRADE_ACCOUNTS_URL } from '../../../lib/urls'
import { TradeAccountDocument } from '../../../operations-types'
import CreatePaymentLinkOverlay from '../../paymentLinks/CreatePaymentLinkOverlay'
import { PaymentLinkTransactionCard } from '../../paymentLinks/PaymentLinkOverlay'
import PaymentLinkSuccessOverlay from '../../paymentLinks/PaymentLinkSuccessOverlay'
import { NewProjectModal } from './NewProjectModal'

type TradeAccountMenuProps = {
  onProjectClick: () => void
  onPaymentClick: () => void
}
const TradeAccountMenu = (props: TradeAccountMenuProps) => {
  return (
    <Menu>
      <Button as={MenuButton} label="Request" size="sm" aria-label="Request" />
      <MenuList>
        <MenuItem
          onClick={() => {
            props.onProjectClick()
          }}
          icon={<Icon as={Square3Stack3DIcon} boxSize="4" />}
        >
          Project Sheet
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onPaymentClick()
          }}
          icon={<Icon as={CurrencyDollarIcon} boxSize="4" />}
        >
          Payment
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

function TradeAccountOverlay() {
  const [open, setOpen] = useState(false)

  const { subdomain } = useOutletContext() as {
    subdomain: string
  }

  const [paymentOpen, setPaymentOpen] = useState(false)
  const [newPaymentLinkId, setNewPaymentLinkId] = useState('')
  const [success, setSuccess] = useState(false)

  const { tradeAccountId } = useParams() as { tradeAccountId: string }

  const { data: tradeAccountData } = useQuery(TradeAccountDocument, {
    variables: {
      tradeAccountId: tradeAccountId,
    },
  })

  const tradeAccount = tradeAccountData?.tradeAccount?.tradeAccount

  const payments = tradeAccount?.projects
    ?.map(
      (project) =>
        project.questionForms?.map(
          (questionForm) =>
            questionForm.answerForms?.map((answerForm) => answerForm.payment),
        ),
    )
    .flat(2)
    .filter(
      (payment) =>
        payment?.activity?.status === 'COMPLETED' ||
        payment?.activity?.status === 'PENDING' ||
        payment?.activity?.status === 'FAILED',
    )

  const focusRef = useRef(null)

  const navigate = useNavigate()

  return (
    <>
      <OverlayHeader
        onClose={() => {
          navigate(TRADE_ACCOUNTS_URL)
        }}
      />

      <Divider />

      <VFlex w="full" py="6" px="8" gap="4">
        <HFlex w="100%">
          <Text fontSize="2xl" fontWeight="semibold" pb="2">
            {tradeAccount?.name}
          </Text>
          <Spacer />
          <TradeAccountMenu
            onProjectClick={() => {
              setOpen(true)
            }}
            onPaymentClick={() => {
              setPaymentOpen(true)
            }}
          />
          <Overlay
            open={paymentOpen}
            setOpen={setPaymentOpen}
            focusRef={focusRef}
          >
            {success ? (
              <PaymentLinkSuccessOverlay
                id={newPaymentLinkId}
                organizationSubdomain={subdomain}
                onClose={() => {
                  setPaymentOpen(false)
                  setSuccess(false)
                }}
              />
            ) : (
              <CreatePaymentLinkOverlay
                setSuccess={setSuccess}
                onClose={() => setPaymentOpen(false)}
                setNewPaymentLinkId={setNewPaymentLinkId}
                focusRef={focusRef}
              />
            )}
          </Overlay>
          <NewProjectModal
            isOpen={open}
            onClose={() => {
              setOpen(false)
            }}
            tradeAccountId={tradeAccountId}
          />
        </HFlex>
        <HFlex w="100%" verticalAlign="top">
          <Text
            color="gray.500"
            fontSize="xs"
            fontWeight="normal"
            w="300px"
            whiteSpace="nowrap"
          >
            Business Address
          </Text>
          <VFlex w="100%">
            <Text fontSize="sm" color="gray.800">
              {tradeAccount?.address?.street1}, {tradeAccount?.address?.city}{' '}
              {tradeAccount?.address?.state}, {tradeAccount?.address?.zip}
            </Text>
          </VFlex>
        </HFlex>

        <HFlex w="100%" verticalAlign="top">
          <Text
            color="gray.500"
            fontSize="xs"
            fontWeight="normal"
            w="300px"
            whiteSpace="nowrap"
          >
            Total Sales
          </Text>
          <VFlex w="100%">
            <Text fontSize="sm" color="gray.800">
              {currency(
                tradeAccount?.transactionsSummary?.totalSalesInCents || 0,
                {
                  fromCents: true,
                },
              ).format()}
            </Text>
          </VFlex>
        </HFlex>

        <VFlex w="100%" verticalAlign="top" gap="2">
          <Text fontSize="xl" fontWeight="semibold" pb="2">
            Contacts
          </Text>
          <VStack w="100%" gap="4">
            {tradeAccount?.contacts?.map((contact, index) => (
              <InformationCard
                key={index}
                icon={
                  <Icon
                    as={UserCircleIcon}
                    borderRadius="4"
                    color="white"
                    bgColor="green.600"
                    boxSize="10"
                    p="6px"
                  />
                }
                firstInfo={`${contact?.firstName} ${contact?.lastName}`}
                secondInfo={''}
                thirdInfo={contact?.email || ''}
                fourthInfo={''}
              />
            ))}
          </VStack>
        </VFlex>

        <VFlex gap="4">
          <Text fontSize="xl" fontWeight="semibold" pb="2">
            Projects
          </Text>

          {tradeAccount?.projects?.length &&
          tradeAccount.projects.length > 0 ? (
            <VStack w="100%" gap="4">
              {tradeAccount?.projects?.map((project, index) => (
                <InformationCard
                  key={index}
                  icon={
                    <Icon
                      as={UserCircleIcon}
                      borderRadius="4"
                      color="white"
                      bgColor="green.600"
                      boxSize="10"
                      p="6px"
                    />
                  }
                  firstInfo={project?.projectName || ''}
                  secondInfo={
                    project.projectDetails?.propertyOwnership?.propertyType ||
                    ''
                  }
                  thirdInfo={`${moment(
                    project.projectDetails?.jobDetails?.projectStartDate,
                  ).format('MM-DD-YYYY')} to ${moment(
                    project.projectDetails?.jobDetails?.estimatedCompletionDate,
                  ).format('MM-DD-YYYY')}`}
                  fourthInfo={
                    project.projectDetails?.jobDetails?.contractValue || ''
                  }
                />
              ))}
            </VStack>
          ) : (
            <Box>
              <Text fontSize="sm" fontWeight="semibold">
                No projects found
              </Text>
            </Box>
          )}
        </VFlex>
        <VFlex>
          <Text fontSize="xl" fontWeight="semibold" pb="2">
            Transactions
          </Text>
          {payments?.map((payment, index) => (
            <Box py={2} key={index}>
              <PaymentLinkTransactionCard
                key={index}
                paymentId={payment?.id || ''}
                submittedAmountInCents={payment?.submittedAmountInCents || 0}
                status={payment?.activity?.status || ''}
                completedAt={payment?.createdAt || ''}
              />
            </Box>
          ))}
        </VFlex>
      </VFlex>
    </>
  )
}

export default TradeAccountOverlay
