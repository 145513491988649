import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    border: '1px solid #e7e5e4',
    borderRadius: 'md',
    boxShadow: 'none',
  },
})

export const cardTheme = defineMultiStyleConfig({ baseStyle })
