import { ReactNode } from 'react'
import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'
import Column from '../Layout/Column'
import styled from 'styled-components'

export type BannerCardProps = {
  banner?: ReactNode
  style?: React.CSSProperties
} & ContainerProps

export const BannerCardContainer = tw<BannerCardProps>(Column)`
  rounded-md
  shadow-lg
`

export const ImageWrapper = styled.div<ContainerProps>`
  width: 100%;
  height: 30%;
  min-height: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
`

export const BannerCard = (props: BannerCardProps) => {
  return (
    <BannerCardContainer
      className={`${props.className} border border-gray-200`}
    >
      {props.banner ? <ImageWrapper>{props.banner}</ImageWrapper> : ''}
      <Column spacing="large" grow wGrow y="center" className={`pb-0 pt-0`}>
        {props.children}
      </Column>
    </BannerCardContainer>
  )
}

export default BannerCard
