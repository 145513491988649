import {
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import {
  ExclamationTriangleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid'
import { tw } from '@nickeltech/brise'
import currency from 'currency.js'
import { FieldArray, useFormikContext } from 'formik'
import { Button, Column, ColumnProps, Row, Separator } from 'ui'
import * as yup from 'yup'
import { usePaymentLinkStore } from '../layout/PaymentLinkStore'

import { ContactInputFields } from './PaymentLinkSuccessOverlay'
import pluralize from 'pluralize'
import { useLazyQuery } from '@apollo/client'
import { DownloadInvoiceDocument } from '../../operations-types'
import { downloadInvoiceFile } from '../../lib/utils'

type AddRecipientDialogProps = {
  isOpen?: boolean
  onCloseAction?: () => void
}

export const BulkPaymentValidationSchema = yup.object({
  modalOpen: yup.boolean(),
  selectedIndex: yup.number(),
  currentCount: yup.number(),
  newContact: yup
    .object({
      name: yup.string(),
      email: yup.string(),
      phoneNumber: yup.string(),
    })
    .optional(),
  paymentLinks: yup.array().of(
    yup.object({
      id: yup.string(),
      invoiceAmount: yup.string().required('Invoice Amount is required'),
      alreadyPaid: yup.string().required('Already Paid is required'),
      youRequest: yup.string().required('You Request is required'),
      message: yup.string().nullable(),
      title: yup.string().required('Title is required'),
      recipients: yup
        .array()
        .of(
          yup.object({
            name: yup.string().required('Name is required'),
            email: yup.string(),
            phoneNumber: yup.string(),
          }),
        )
        .min(1),
    }),
  ),
})

export type BulkPaymentValidationSchemaType = yup.InferType<
  typeof BulkPaymentValidationSchema
>

export const AddRecipientDialog = (props: AddRecipientDialogProps) => {
  const { values, setFieldValue } =
    useFormikContext<BulkPaymentValidationSchemaType>()

  return (
    <FieldArray
      name={`paymentLinks[${values.selectedIndex}].recipients`}
      render={(vals) => (
        <Column wGrow gap="small">
          <Modal
            size="2xl"
            isOpen={values.modalOpen || false}
            onClose={
              props.onCloseAction
                ? props.onCloseAction
                : () => {
                    setFieldValue('selectedIndex', undefined)
                    setFieldValue('modalOpen', false)
                  }
            }
            trapFocus={false}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Recipient</ModalHeader>
              {props.onCloseAction && <ModalCloseButton />}
              <ModalBody w="100%">
                <ContactInputFields
                  recipientInputName="newContact.name"
                  emailInputName="newContact.email"
                  phoneNumberInputName="newContact.phoneNumber"
                />
                <InputGroup py="4" className="w-full">
                  <Button
                    onClick={async () => {
                      vals.push({
                        name: values.newContact.name,
                        phoneNumber: values.newContact.phoneNumber,
                        email: values.newContact.email,
                      })

                      await setFieldValue('newContact', {
                        name: '',
                        phoneNumber: '',
                        email: '',
                      })

                      await setFieldValue('modalOpen', false)
                    }}
                    _hover={
                      !values.newContact.name ||
                      (!values.newContact.phoneNumber &&
                        !values.newContact.email)
                        ? {}
                        : {
                            cursor: 'pointer',
                            bgColor: 'dark.700',
                          }
                    }
                    className="w-full"
                    label="Add Recipient"
                    isDisabled={
                      !values.newContact.name ||
                      (!values.newContact.phoneNumber &&
                        !values.newContact.email)
                    }
                  />
                </InputGroup>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Column>
      )}
    />
  )
}

type BulkPaymentLinkElementProps = {
  index: number
}

const StepIndicator = tw<ColumnProps & { selected?: boolean }>(Column)`
    w-4
    h-4
    p-4
    rounded
    items-center
    justify-center
    text-xs
    ${(props) => {
      if (!props.selected) {
        return `!bg-gray-200 text-xs text-gray-800`
      } else {
        return `!bg-yellow-500 text-xs text-white`
      }
    }}

`

const BulkPaymentLinkElement = (props: BulkPaymentLinkElementProps) => {
  const { values, setFieldValue } =
    useFormikContext<BulkPaymentValidationSchemaType>()

  const { setPaymentLinks, paymentLinks } = usePaymentLinkStore()

  const toaster = useToast()

  const { errors } = useFormikContext<BulkPaymentValidationSchemaType>()

  const [downloadInvoice, { loading }] = useLazyQuery(DownloadInvoiceDocument, {
    variables: {
      questionFormId: (values.paymentLinks || [])[props.index]?.id || '',
    },
  })

  const downloadPDF = async () => {
    await downloadInvoiceFile(
      downloadInvoice,
      () => {
        toaster({
          status: 'success',
          title: 'Invoice downloaded',
        })
      },
      () => {
        toaster({
          status: 'error',
          title: 'Error downloading invoice',
        })
      },
    )
  }

  const currentPaymentLink =
    (values.paymentLinks || [])?.length > 0
      ? (values.paymentLinks || [])[props.index]
      : {
          title: '',
          invoiceAmount: 0,
          alreadyPaid: 0,
          youRequest: 0,
          message: '',
          recipients: [],
        }

  const shouldHighlight =
    values.selectedIndex === props.index &&
    (errors.paymentLinks || []).length >= props.index &&
    !!(errors.paymentLinks || [])[props.index]

  const currentError =
    (errors.paymentLinks || []).length >= props.index
      ? (errors.paymentLinks || [])[props.index]
      : {}

  return (
    <Column gap="small">
      <Row
        y="center"
        gap="small"
        x="center"
        className="h-full !min-h-fit w-full min-w-[600px] max-w-[800px] px-20 sm:px-0"
      >
        <StepIndicator selected={shouldHighlight}>
          {props.index + 1}
        </StepIndicator>
        <Column grow gap="small" wGrow>
          <div
            className={
              '!p-0 border rounded bg-white h-fit w-full min-h-[256px] h-fit'
            }
            style={{
              borderColor: shouldHighlight ? '#eab308' : '#d1d5db',
            }}
          >
            <Row grow hGrow>
              <Column
                spacing="medium"
                gap="small"
                wGrow
                className="!w-full max-w-[256px]"
              >
                <Row y="center" gap="small" grow>
                  <DocumentArrowDownIcon
                    className={
                      '!fill-gray-300 w-4 min-w-4 h-4 min-h-4 cursor-pointer' +
                      (loading ? 'pointer-events-none opacity-50' : '')
                    }
                    {...{
                      onClick: downloadPDF,
                    }}
                  />
                  <div className="text-sm text-gray-800 whitespace-nowrap">
                    {currentPaymentLink.title}
                  </div>
                </Row>
                <Column gap="small" wGrow>
                  <div className="text-xs font-normal text-gray-500">
                    Add a message for the recipient
                  </div>
                  <Textarea
                    className="!text-xs text-black placeholder:text-gray-600 !p-2"
                    {...{
                      value: currentPaymentLink.message || '',
                      onFocus: () => {
                        setFieldValue('selectedIndex', props.index)
                      },
                      onBlur: () => {
                        setFieldValue('selectedIndex', undefined)
                      },
                      placeholder: 'Add a message',
                      onChange: (e) => {
                        setFieldValue(
                          `paymentLinks[${props.index}].message`,
                          e.target.value,
                        )
                      },
                    }}
                  />
                </Column>
                <Column wGrow gap="small">
                  <Row between y="center" grow>
                    <div className="text-xs font-normal text-gray-500">
                      Add a recipient
                    </div>
                    <PlusCircleIcon
                      className="w-4 h-4 stroke-[2px] stroke-gray-500 cursor-pointer"
                      {...{
                        onClick: () => {
                          setFieldValue('modalOpen', true)
                          setFieldValue('selectedIndex', props.index)
                        },
                      }}
                    />
                  </Row>
                  {(currentPaymentLink.recipients || [])?.length > 0 ? (
                    <Column gap="small" wGrow>
                      <Column
                        className="rounded border border-gray-200"
                        spacing="small"
                        wGrow
                      >
                        <Row grow y="center" between>
                          <div className="text-xs text-gray-800 font-semibold">
                            {(currentPaymentLink.recipients || [])[0].name}
                          </div>
                          <XMarkIcon
                            className="w-3 h-3 stroke-[1px] stroke-gray-500 relative bottom-[2px] cursor-pointer"
                            {...{
                              onClick: () => {
                                if (
                                  values.paymentLinks !== undefined &&
                                  (values.paymentLinks || [])?.length > 0
                                ) {
                                  setFieldValue(
                                    `paymentLinks[${props.index}].recipients`,
                                    (
                                      values.paymentLinks[props.index]
                                        .recipients || []
                                    ).filter((_, i) => i !== 0),
                                  )
                                }
                              },
                            }}
                          />
                        </Row>
                        <div className="text-xs text-gray-800 font-normal">
                          {currentPaymentLink.recipients?.at(0)?.email}
                          {currentPaymentLink.recipients?.at(0)?.phoneNumber
                            ? ' ·'
                            : ''}
                          {' ' +
                            currentPaymentLink.recipients?.at(0)?.phoneNumber}
                        </div>
                      </Column>
                      {(currentPaymentLink.recipients || [])?.length > 1 ? (
                        <div className="text-xs">
                          and{' '}
                          {(currentPaymentLink.recipients || [])?.length - 1}{' '}
                          more{' '}
                          {pluralize(
                            'recipient',
                            (currentPaymentLink.recipients || [])?.length - 1,
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </Column>
                  ) : (
                    <Column
                      className="rounded border border-gray-200"
                      spacing="small"
                      wGrow
                      grow
                      {...{
                        onClick: () => {
                          setFieldValue('selectedIndex', props.index)
                          setFieldValue('modalOpen', true)
                        },
                      }}
                    >
                      <Row
                        grow
                        gap="small"
                        y="center"
                        {...{
                          onClick: () => {
                            setFieldValue('selectedIndex', props.index)
                            setFieldValue('modalOpen', true)
                          },
                          className: 'cursor-pointer',
                        }}
                      >
                        <ExclamationTriangleIcon className="w-3 h-3 stroke-[1px] !stroke-yellow-500" />
                        <div className="text-xs text-gray-800 font-semibold">
                          No recipient found
                        </div>
                      </Row>
                      <Row grow>
                        <div className="text-xs text-gray-500">
                          Click to add a recipient
                        </div>
                      </Row>
                    </Column>
                  )}
                </Column>
              </Column>
              <Column
                wGrow
                between
                grow
                spacing="small"
                className="bg-gray-100 !h-full rounded-r !w-full border-l"
              >
                <Row grow y="center" x="right" className="h-fit">
                  <XMarkIcon
                    className={
                      'cursor-pointer w-4 h-4 stroke-[2px] stroke-gray-500' +
                      (values.paymentLinks?.length === 1 ? ' hidden' : '')
                    }
                    {...{
                      onClick: () => {
                        if (
                          values.paymentLinks !== undefined &&
                          (values.paymentLinks || [])?.length > 0
                        ) {
                          setPaymentLinks(
                            paymentLinks.filter((_, i) => i !== props.index),
                          )
                          setFieldValue(
                            'paymentLinks',
                            values.paymentLinks.filter(
                              (_, i) => i !== props.index,
                            ),
                          )
                        }
                      },
                    }}
                  />
                </Row>
                <Column grow wGrow y="center" x="left" gap="medium">
                  <div className="text-sm font-medium px-2">
                    Payment Summary
                  </div>
                  <Column
                    gap="small"
                    y="center"
                    x="left"
                    wGrow
                    className="px-2"
                  >
                    <Row grow y="center" between>
                      <div className="text-xs text-gray-500 font-normal">
                        Invoice Amount
                      </div>
                      <div className="text-xs text-gray-500 font-normal">
                        {currency(
                          currentPaymentLink?.invoiceAmount || 0,
                        ).format()}
                      </div>
                    </Row>
                    <Row grow y="center" between>
                      <div className="text-xs text-gray-500 font-normal">
                        Already Paid
                      </div>
                      <div className="text-xs text-gray-500 font-normal">
                        {currency(
                          currentPaymentLink?.alreadyPaid || 0,
                        ).format()}
                      </div>
                    </Row>
                    <Separator orientation="horizontal" />
                    <Row grow y="center" between>
                      <div className="text-xs text-black font-normal">
                        You are requesting
                      </div>
                      <div className="text-xs text-black font-semibold">
                        {currency(currentPaymentLink.youRequest || 0).format()}
                      </div>
                    </Row>
                  </Column>
                </Column>
              </Column>
            </Row>
          </div>
          <Row grow>
            {Object.keys(currentError || {}).length > 0 &&
            props.index === values.selectedIndex ? (
              <Row
                grow
                spacing="small"
                className="rounded border border-yellow-500 bg-yellow-100 text-xs !text-yellow-700"
              >
                {(currentError as Record<string, string>)['recipients']
                  ? 'Enter at least one recipient'
                  : ''}
              </Row>
            ) : (
              <Row className="min-h-[34px] h-[34px] w-full bg-transparent" grow>
                &nbsp;
              </Row>
            )}
          </Row>
        </Column>
      </Row>
    </Column>
  )
}

export default BulkPaymentLinkElement
