import ContainerProps from '../../types'
import Row from '../Layout/Row'
import Button from '../Button'
import { tw } from '@nickeltech/brise'
import Icon from '../Icons'
import Text from '../Typography/Text'

export type CardFooterProps = {
  onOptions?: (e: any) => void
  onTrash?: (e: any) => void
  onReceipt?: (e: any) => void
  onShare?: (e: any) => void
  onSubmit?: (e: any) => void
  onAction?: (e: any) => void
  onActionText?: string
  onSubmitText?: string
} & ContainerProps

export const CardFooterComponent = tw(Row)`
  border-t
  border-gray-200
`

export const CardFooter = (props: CardFooterProps) => {
  const shouldExist =
    props.onOptions ||
    props.onTrash ||
    props.onReceipt ||
    props.onShare ||
    props.onSubmit ||
    props.onAction ||
    props.onActionText

  return shouldExist ? (
    <CardFooterComponent
      around
      y="center"
      grow
      spacing={shouldExist ? 'baseNarrow' : 'none'}
      {...{
        initial: { height: 0 },
        animate: {
          height: shouldExist ? 'auto' : 0,
        },
        transition: {
          ease: 'easeInOut',
          duration: 0.5,
        },
      }}
    >
      {props.onSubmit && props.onSubmitText ? (
        <Button
          {...{ onClick: props.onSubmit ? props.onSubmit : undefined }}
          status="action"
          variant="primary"
          spacing="smallNarrow"
        >
          <Text variant="primary">{props.onSubmitText}</Text>
        </Button>
      ) : (
        <Row x="left" y="center" gap="small" grow>
          {props.onTrash ? (
            <Icon
              {...{
                onClick: props.onTrash,
                variant: 'primary',
                name: 'trash',
              }}
            />
          ) : (
            ''
          )}
          {props.onReceipt ? (
            <Icon
              {...{
                onClick: props.onReceipt,
                variant: 'secondary',
                name: 'arrowDownTray',
              }}
            />
          ) : (
            ''
          )}
          {props.onShare ? (
            <Icon
              {...{
                onClick: props.onShare,
                variant: 'secondary',
                name: 'link',
              }}
            />
          ) : (
            ''
          )}
          <Row x="center" y="center" grow></Row>
          <Row x="right" y="center" gap="small" grow>
            {props.onOptions ? (
              <Icon
                {...{
                  onClick: props.onOptions,
                  variant: 'secondary',
                  name: 'ellipsisVertical',
                }}
              />
            ) : (
              ''
            )}
            {props.onAction && props.onActionText ? (
              <Button
                {...{
                  onClick: props.onAction,
                  variant: 'primary',
                  status: 'action',
                }}
              >
                <Text variant="tertiary">{props.onActionText}</Text>
              </Button>
            ) : (
              ''
            )}
          </Row>
        </Row>
      )}
    </CardFooterComponent>
  ) : (
    <></>
  )
}

export default CardFooter
