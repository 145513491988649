import { Center, Tooltip, Text, BoxProps } from '@chakra-ui/react'
import { Icon, IconName } from 'ui'
import { Status } from '../../types'

export type FieldTooltipProps = {
  text?: string
  status?: Status
  customLabel?: any
  iconName?: IconName
} & BoxProps

export const FieldTooltip = (props: FieldTooltipProps) => {
  const { text, status, customLabel, iconName, ...other } = props
  return (
    <span>
      <Center {...other}>
        <Tooltip
          bgColor="gray.500"
          hasArrow
          label={
            <Text fontSize="sm" pb="1">
              {text}
            </Text>
          }
          shouldWrapChildren
          pt="2"
          borderRadius="base"
        >
          <Icon
            name={iconName || 'informationCircle'}
            status={status || 'info'}
          />
        </Tooltip>
      </Center>
    </span>
  )
}

export default FieldTooltip
