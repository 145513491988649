import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Header, Row, TabAndComponent, Tabs } from 'ui'
import {
  GetPaymentsQueryVariables,
  GetPaymentSummaryDocument,
  SortOrder,
} from '../../operations-types'
import { PaymentStatus } from '../../types'
import PaymentsTable from './PaymentsTable'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { reportErrorIfExists } from '../../lib/utils'
import { PageLayout } from '../layout/PageLayout'
import { DEPOSIT_CHECK_URL } from '../../lib/urls'

type TabState = 'All' | 'In Progress' | 'Completed' | 'Failed' | 'Refunded'
const TAB_TO_STATUSES: { [state in TabState]: PaymentStatus[] } = {
  All: ['PENDING', 'FAILED', 'REFUNDED', 'SUCCEEDED'],
  'In Progress': ['PENDING'],
  Completed: ['SUCCEEDED'],
  Failed: ['FAILED'],
  Refunded: ['REFUNDED'],
}

const RENDERED_TABS: TabState[] = [
  'All',
  'In Progress',
  'Completed',
  'Refunded',
  'Failed',
]

const LABEL_TO_SORT_BY: { [index: string]: SortOrder } = {
  'Date Ascending': SortOrder.DateAsc,
  'Date Descending': SortOrder.DateDesc,
  'Amount Ascending': SortOrder.AmountAsc,
  'Amount Descending': SortOrder.AmountDesc,
}

export default function Payments() {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [customer, setCustomer] = useState<string | null>(null)
  const depositCheckEnabled = useFeatureFlagEnabled('depositCheckEnabled')
  const navigate = useNavigate()

  const [queryOpts, setQueryOpts] = useState<
    NonNullable<GetPaymentsQueryVariables>
  >({
    status: TAB_TO_STATUSES['All'],
    page: 1,
    pageSize: 20,
    sortBy: SortOrder.DateDesc,
    startDateEpochMillis: null,
    endDateEpochMillis: null,
  })

  const summaryData = useQuery(GetPaymentSummaryDocument, {
    variables: {
      startDateEpochMillis: startDate?.getTime(),
      endDateEpochMillis: endDate?.getTime(),
      statuses: queryOpts.status,
      paymentId: paymentId,
      customer: customer,
    },
    pollInterval: 30000,
  })

  const summaries = summaryData.data?.paymentsSummary

  reportErrorIfExists(summaryData.error)

  const tabs: Array<TabAndComponent> = RENDERED_TABS.map((tab) => {
    return {
      tab: {
        name: tab,
        unreadStatus: 'warning',
        onClick: (name: string) => {
          setQueryOpts({
            ...queryOpts,
            page: 1,
            status: TAB_TO_STATUSES[name as TabState],
          })
        },
      },
      component: (
        <PaymentsTable
          queryOpts={queryOpts}
          onDropdownClick={(label) => {
            setQueryOpts({
              ...queryOpts,
              sortBy: LABEL_TO_SORT_BY[label],
            })
          }}
          onPageChange={(page) => {
            setQueryOpts({
              ...queryOpts,
              page: page,
            })
          }}
          onFilter={(startDate, endDate, id, customer) => {
            setQueryOpts({
              ...queryOpts,
              ...(startDate
                ? { startDateEpochMillis: startDate.getTime() }
                : { startDateEpochMillis: null }),
              ...(endDate
                ? { endDateEpochMillis: endDate.getTime() }
                : { endDateEpochMillis: null }),
              ...(id ? { paymentId: id } : { paymentId: null }),
              ...(customer ? { customer: customer } : { customer: null }),
              page: 1,
            })
            setStartDate(startDate ?? null)
            setEndDate(endDate ?? null)
            setPaymentId(id ?? null)
            setCustomer(customer ?? null)
          }}
          totalSubmittedAmountCents={summaries?.summary.totalSubmitted || 0}
        />
      ),
    }
  })

  return (
    <PageLayout>
      <>
        <Row className="pb-4" between>
          <Header variant="page">Payments</Header>
          {depositCheckEnabled && (
            <Button
              label="Deposit Check"
              onClick={() => navigate(DEPOSIT_CHECK_URL)}
            />
          )}
        </Row>
        <div>
          <Tabs tabs={tabs} header between />
        </div>
      </>
    </PageLayout>
  )
}
