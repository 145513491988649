import { useEffect } from 'react'

interface Props<T> {
  onChange: (value: T) => void
  value: T
}

export default function FormObserver<T>(props: Props<T>) {
  useEffect(() => {
    props.onChange(props.value)
  }, [JSON.stringify(props.value)])
  return null
}

FormObserver.defaultProps = {
  onChange: () => null,
}
