import {
  Formik,
  Form as FormikForm,
  FormikValues,
  FormikErrors,
  FormikProps,
  FormikHelpers,
} from 'formik'
import { ReactNode } from 'react'
import { BaseSchema } from 'yup'
import Lazy from 'yup/lib/Lazy'
import FormObserver from './FormObserver'

export type FormChildrenProps<T extends FormikValues> = {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  values: T
  errors: FormikErrors<T>
} & Omit<FormikProps<T>, 'handleSubmit' | 'values' | 'errors'>

export type FormProps<T extends FormikValues> = {
  initialValues: T
  validationSchema?: BaseSchema | Lazy<BaseSchema>
  validate?: (values: T) => void
  onSubmit?: (
    values: T,
    formikHelpers: FormikHelpers<T>,
  ) => Promise<void> | void
  children?: (props: FormChildrenProps<T>) => ReactNode | Array<ReactNode>
  onChange?: (e: any, formikValues: any) => void
  className?: string
}

export const Form = <Values extends FormikValues = FormikValues>(
  props: FormProps<Values>,
) => {
  return (
    <Formik<Values>
      {...{
        initialValues: props.initialValues,
        validationSchema: props.validationSchema,
        onSubmit: props.onSubmit ? props.onSubmit : () => {},
        validate: props.validate,
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true,
        enableReinitialize: true,
      }}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => {
        return (
          <FormikForm
            className={props.className}
            onSubmit={async () => {
              await handleSubmit()
            }}
          >
            {props.children &&
              props.children({
                handleSubmit,
                values,
                errors,
                ...formikProps,
              })}
            <FormObserver
              value={values}
              onChange={(values) => {
                props.onChange && props.onChange(values, formikProps)
              }}
            />
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default Form
