import { Body, Card, V2Column as Column, V2Row as Row } from 'ui'
import { Menu } from '@headlessui/react'
import { MouseEventHandler, useState } from 'react'
import { Flex } from '@chakra-ui/react'

export type TextFilterMenuItemProps = {
  label: string
  value: string
  onClick?: MouseEventHandler<HTMLDivElement>
  active?: boolean
}

export type TextFilterMenuProps = {
  items: TextFilterMenuItemProps[]
  selected?: number
  onClick?: (value: string) => void
}

export function TextFilterItem({
  label,
  onClick,
  active,
}: TextFilterMenuItemProps) {
  return (
    <Menu.Item>
      <Flex
        flexDirection="row"
        gap={2}
        p={2}
        borderRadius="md"
        background={active ? 'gray.200' : 'transparent'}
        _hover={{ background: 'gray.200' }}
        cursor="pointer"
        onClick={onClick}
      >
        <Body size="small" className="whitespace-nowrap">
          {label}
        </Body>
      </Flex>
    </Menu.Item>
  )
}

export function TextFilterMenu({
  items,
  selected,
  onClick,
}: TextFilterMenuProps) {
  const incomingSelection =
    (selected || 0) > items.length - 1 ? 0 : selected || 0

  const [selection, setSelection] = useState(incomingSelection)

  return (
    <Card className="w-fit h-fit shadow-sm">
      <Row hGrow>
        <Column spacingY="base" spacingX="base" gap="small" grow className="">
          {items.map((item, index) => {
            return (
              <TextFilterItem
                key={index}
                label={item.label}
                active={index == selection}
                value={item.value}
                onClick={(e) => {
                  e.preventDefault()
                  setSelection(index)
                  onClick && onClick(item.value)
                }}
              />
            )
          })}
        </Column>
      </Row>
    </Card>
  )
}

export default TextFilterMenu
