import { GetPaymentQuery } from '../../operations-types'
import currency from 'currency.js'

type RawPayment = NonNullable<
  Pick<NonNullable<GetPaymentQuery['payment']>, 'payment'>['payment']
>

type RawCharge = NonNullable<Pick<RawPayment, 'charges'>['charges']>[0]

type RawAchReturn = NonNullable<Pick<RawPayment, 'achReturn'>['achReturn']>

type RawAnswerForm = NonNullable<Pick<RawPayment, 'answerForm'>['answerForm']>

type QuestionForm = Pick<
  NonNullable<Pick<RawAnswerForm, 'questionForm'>['questionForm']>,
  'loans'
>

type Fee = Pick<
  NonNullable<Pick<RawCharge, 'fees'>['fees']>[0],
  'amountInCents'
>

type Refund = Pick<
  NonNullable<Pick<RawCharge, 'refunds'>['refunds']>[0],
  'amountCents' | 'id' | 'createdAt' | 'status' | 'voided'
>

type Charge = {
  fees?: Fee[] | null
  refunds?: Refund[] | null
  createdAt?: string | null
}

type AnswerForm = {
  questionForm?: QuestionForm | null
}

type Payment = Pick<
  RawPayment,
  'submittedAmountInCents' | 'amountInCents' | 'platformFeeInCents' | 'id'
> & {
  charges?: Charge[] | null
  achReturn?: RawAchReturn | null
  answerForm?: AnswerForm | null
}

export class PaymentHelper {
  payment: Payment

  constructor(payment: Payment) {
    this.payment = payment
  }

  submittedAmountCents(): number {
    return this.payment.submittedAmountInCents || 0
  }

  submittedAmountDollars(): number {
    return currency(this.submittedAmountCents(), { fromCents: true }).value
  }

  amountWithoutFeeCents(): number {
    return this.chargedAmountCents() - this.platformFeeCents()
  }

  amountWithoutFeeDollars(): number {
    return currency(this.amountWithoutFeeCents(), { fromCents: true }).value
  }

  chargedAmountCents(): number {
    return this.payment.amountInCents || 0
  }

  chargedAmountLessAdvanceCents(): number {
    return this.chargedAmountCents() - this.principalAmountCents()
  }

  chargedAmountLessAdvanceDollars(): number {
    return currency(this.chargedAmountLessAdvanceCents(), { fromCents: true })
      .value
  }

  chargedAmountDollars(): number {
    return currency(this.chargedAmountCents(), { fromCents: true }).value
  }

  platformFeeCents(): number {
    return this.payment.platformFeeInCents || 0
  }

  principalAmountCents(): number {
    return (
      this.payment.answerForm?.questionForm?.loans?.filter(
        (loan) => loan?.funded === true,
      )[0]?.principalAmountInCents || 0
    )
  }

  principalAmountDollars(): number {
    return currency(this.principalAmountCents(), { fromCents: true }).value
  }

  factoringFeeCents(): number {
    return (
      this.payment.answerForm?.questionForm?.loans?.filter(
        (loan) => loan?.funded === true,
      )[0]?.feeAmountInCents || 0
    )
  }

  factoringFeeDollars(): number {
    return currency(this.factoringFeeCents(), { fromCents: true }).value
  }

  platformFeeDollars(): number {
    return this.platformFeeCents() / 100
  }

  returnAmountCents(): number {
    return this.payment?.achReturn?.amountCents || 0
  }

  returnAmountDollars(): number {
    return currency(this.returnAmountCents(), { fromCents: true }).value
  }

  feeDollars() {
    return currency(this.feeCents(), { fromCents: true }).value
  }

  feeCents() {
    return this.fees().reduce((acc, x) => acc + (x?.amountInCents || 0), 0)
  }

  fees() {
    return this.charges().flatMap((x) => x.fees) || []
  }

  charges() {
    return this.payment.charges || []
  }

  refunds() {
    return this.charges().flatMap((x) => x.refunds || [])
  }

  loan() {
    return this.payment.answerForm?.questionForm?.loans?.filter(
      (loan) => loan?.funded === true,
    )[0]
  }

  remainingAmountWithoutFeeCents() {
    return this.amountWithoutFeeCents() - this.refundAmountCents()
  }

  remainingAmountWithoutFeeDollars() {
    return currency(this.remainingAmountWithoutFeeCents(), { fromCents: true })
      .value
  }

  remainingAmountWithFeeCents() {
    return this.chargedAmountCents() - this.refundAmountCents()
  }

  remainingAmountWithFeeDollars() {
    return currency(this.remainingAmountWithFeeCents(), { fromCents: true })
      .value
  }

  refundAmountCents() {
    return this.refunds()
      .map((x) => x.amountCents || 0)
      .reduce((acc, x) => acc + x, 0)
  }

  refundAmountDollars() {
    return currency(this.refundAmountCents(), { fromCents: true }).value
  }

  netAmountCents() {
    if (this.voided()) {
      return this.chargedAmountCents() - this.refundAmountCents()
    }

    return (
      this.chargedAmountCents() -
      this.refundAmountCents() -
      this.platformFeeCents() -
      this.returnAmountCents() -
      this.factoringFeeCents() -
      this.principalAmountCents()
    )
  }

  voided() {
    return this.refunds().length === 1 && this.refunds()[0].voided
  }

  netAmountDollars() {
    return currency(this.netAmountCents(), { fromCents: true }).value
  }
}
