import { Card, CardBody, HStack, VStack, Text } from '@chakra-ui/react'

export type OverlayCardItem = {
  itemName: string
  itemValue: string
}

export type OverlayCardProps = {
  boldedText?: string
  remainderText?: string
  subHeading?: string
  firstItem?: OverlayCardItem
  secondItem?: OverlayCardItem
  onClick?: () => void
}

export function OverlayCard({
  boldedText,
  remainderText,
  subHeading,
  firstItem,
  secondItem,
  onClick,
}: OverlayCardProps) {
  return (
    <Card
      variant="outline"
      w="100%"
      cursor={onClick ? 'pointer' : 'default'}
      _hover={onClick ? { bg: 'gray.50' } : {}}
      onClick={onClick}
    >
      <CardBody p={4}>
        <HStack justifyContent={'space-between'} alignItems={'flex-start'}>
          <VStack alignItems={'flex-start'}>
            <HStack>
              <Text fontSize={'sm'} fontWeight="medium">
                {boldedText}
              </Text>
              {remainderText && (
                <Text fontSize={'sm'} color={'gray.500'}>
                  {remainderText}
                </Text>
              )}
            </HStack>
            {subHeading && (
              <Text fontSize={'xs'} fontWeight={'medium'} color={'gray.500'}>
                {subHeading}
              </Text>
            )}
          </VStack>
          <VStack w={200}>
            {firstItem && (
              <HStack justifyContent={'space-between'} w={'100%'}>
                <Text fontSize={'sm'} color={'gray.500'}>
                  {firstItem.itemName}
                </Text>
                <Text fontSize={'sm'} color={'gray.500'}>
                  {firstItem.itemValue}
                </Text>
              </HStack>
            )}
            {secondItem && (
              <HStack justifyContent={'space-between'} w={'100%'}>
                <Text fontSize={'sm'} color={'gray.500'}>
                  {secondItem.itemName}
                </Text>
                <Text fontSize={'sm'} color={'gray.500'}>
                  {secondItem.itemValue}
                </Text>
              </HStack>
            )}
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  )
}
