import { styles, tw } from '@nickeltech/brise'
import classNames from 'classnames'
import Icon, { IconName } from '../Icons'
import StatusPill from '../Indicators/StatusPill'
import Column from '../Layout/Column'
import Row from '../Layout/Row'
import SidebarSubsection, { SidebarSubsectionProps } from './Subsection'
import { useToast } from '@chakra-ui/react'

export type SidebarSectionProps = {
  label: string
  url: string
  icon: IconName
  selected?: boolean
  currentUrl?: string
  collapsed?: boolean
  onClick?: (e: any) => void
  unreadCount?: number
  subsections?: Array<SidebarSubsectionProps>
}

export const OpacityHideAnimation = styles<
  Pick<SidebarSectionProps, 'collapsed'>
>`
  transition-opacity
  ${(props) => (props.collapsed ? 'opacity: 0' : 'opacity: 1')}
`

export const RoundedRow = tw(Row)`
  rounded
`

export const SidebarSection: React.FC<SidebarSectionProps> = (props) => {
  const toast = useToast()

  return (
    <Column selected={props.selected} wGrow className="bg-sidebar-purple">
      <Row
        y="center"
        x="left"
        selected={props.selected}
        onClick={() => {
          toast.closeAll()
          props.onClick && props.onClick(props.url)
        }}
        grow
        className={classNames(
          'p-3 py-2',
          { 'hover:rounded': !props.selected },
          { 'rounded-t': props.selected && props.subsections?.length !== 0 },
          {
            rounded:
              props.selected &&
              (!props.subsections || props.subsections?.length == 0),
          },
          { 'bg-sidebar-purple': !props.selected },
          { 'bg-sidebar-hover': props.selected },
          { 'hover:bg-sidebar-hover': !props.selected },
        )}
      >
        <Row
          y="center"
          selected={props.selected}
          between
          grow
          gap={props.collapsed ? 'none' : 'small'}
          className={classNames({ 'bg-sidebar-hover': props.selected })}
        >
          <Row y="center" className="gap-x-[14px]">
            <Row y="center" {...{ animate: { x: props.collapsed ? 6 : 0 } }}>
              <Icon
                name={props.icon}
                variant="primary"
                className="stroke-white"
              />
            </Row>
            {
              <div
                className={classNames(
                  'text-slate-200 font-medium text-sm',
                  { 'opacity-100': !props.collapsed },
                  { 'w-inherit': !props.collapsed },
                  { 'opacity-0': props.collapsed },
                  { 'w-0': props.collapsed },
                  { 'h-0': props.collapsed },
                )}
              >
                {props.label}
              </div>
            }
          </Row>
          <Row visible={props.collapsed === false}>
            {props.unreadCount && (
              <StatusPill
                status="action"
                visible={props.collapsed === false}
                spacing="xsmallNarrow"
              >
                {props.unreadCount}
              </StatusPill>
            )}
          </Row>
        </Row>
      </Row>
      {props.selected &&
      (props.subsections || []).length > 0 &&
      !props.collapsed ? (
        <Column
          x="left"
          gap="none"
          visible={!props.collapsed}
          wGrow
          className="bg-sidebar-hover rounded-b"
        >
          {(props.subsections || []).map((subsection, k) => {
            return (
              <SidebarSubsection
                {...subsection}
                key={k}
                {...{ collapsed: props.collapsed }}
                selected={props.currentUrl === subsection.url}
                className={classNames({
                  'rounded-none': k !== (props.subsections || []).length - 1,
                  'rounded-b': k === (props.subsections || []).length - 1,
                })}
              />
            )
          })}
        </Column>
      ) : (
        ''
      )}
    </Column>
  )
}

export default SidebarSection
