import { useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import { Column } from 'ui'
import {
  BankAccountsDocument,
  GetLoanAdvanceDocument,
  PaymentActivitySource,
} from '../../operations-types'
import {
  getTransactionStatusFromActivity,
  reportErrorIfExists,
} from '../../lib/utils'
import { OverlayHeaderV2 } from '../payments/OverlayHeader'
import AmountHeader from '../payments/PaymentAmountSubmitted'
import PaymentElements from '../payments/PaymentElements'
import { TransactionActivity } from './TransactionActivity'
import { payoutOrBankAccountIntoIdentifier } from './PaymentInnerOverlay'
import { ActivityNote, QueryOptsActivityNote } from '../ap/ActivityNoteModal'
import AdditionalPaymentDetail from '../payments/AdditionalPaymentDetail'
import { AdvanceDetails } from '../payments/PaymentDetails'
import currency from 'currency.js'

type LoanAdvanceInnerOverlayProps = {
  loanId: string
  exitOverlay: () => void
  onBack?: () => void
  queryOpts: QueryOptsActivityNote
}

export default function LoanAdvanceInnerOverlay({
  loanId,
  exitOverlay,
  onBack,
  queryOpts,
}: LoanAdvanceInnerOverlayProps) {
  const { loading, data, error } = useQuery(GetLoanAdvanceDocument, {
    variables: { loanId: loanId },
  })
  const { data: organizationInfo, loading: bankAccountLoading } =
    useQuery(BankAccountsDocument)

  const defaultBankAccount =
    organizationInfo?.organization?.organization?.accountInfo?.bankAccounts?.[0]

  reportErrorIfExists(data?.getLoanAdvance?.error?.message || error)

  if (loading || bankAccountLoading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const loan = data?.getLoanAdvance?.loan

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <OverlayHeaderV2
        onClose={exitOverlay}
        onBack={onBack}
        transactionStatus={
          getTransactionStatusFromActivity(
            loan?.activity?.status || '',
            loan?.activity?.flags || [],
          ).status
        }
      />
      <AmountHeader
        label="Advance Amount"
        amount={(
          ((loan?.principalAmountInCents || 0) * 1.0) /
          100
        ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      />
      <TransactionActivity
        paymentMethod={{
          type: 'bankAccount',
          identifier: 'Nickel Technology Inc',
          text: `${
            loan?.activity?.status === 'COMPLETED' ? 'Completed' : 'Pending'
          }`,
        }}
        payoutMethod={payoutOrBankAccountIntoIdentifier(
          loan?.activity?.payout?.payoutMethod || defaultBankAccount || null,
          loan?.activity?.payout?.paidAt || null,
          loan?.activity?.payout?.createdAt || null,
        )}
        completed={!!loan?.activity?.payout?.paidAt}
      />
      <PaymentElements
        {...{
          paymentNode: (
            <Column gap="large" wGrow className="w-full">
              <AdvanceDetails
                {...{
                  headerLabel: 'Order Reference',
                  headerValue:
                    data?.getLoanAdvance?.loan?.questionForm?.name || '',
                  advanceAmount: currency(loan?.principalAmountInCents || 0, {
                    fromCents: true,
                  }).format(),
                  borrowerName: loan?.borrower?.name || '',
                  paymentRequestId: loan?.questionForm?.id,
                }}
              />

              <ActivityNote
                {...{
                  activityId: loan?.activity?.id || '',
                  sourceType: PaymentActivitySource.Loan,
                  queryOpts,
                }}
              />
            </Column>
          ),
          detailNode: (
            <AdditionalPaymentDetail
              {...{
                reference: {
                  loanId: loan?.id || '',
                  reference: loan?.questionForm?.name || '',
                },
              }}
            />
          ),
        }}
      />
    </Flex>
  )
}
