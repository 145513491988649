import Column from '../Layout/Column'
import Separator from '../Layout/Separator'
import { Title } from '../v2/Typography'
import { Breakdown } from './OverlayBreakdown'

export type OverlayPayoutBreakdownProps = {
  title: string
  chargedAmount: number
  refundedAmount: number
  returnAmount: number
  feeAmount: number
  totalAmount: number
  adjustmentAmount?: number
}

export function OverlayPayoutBreakdown(props: OverlayPayoutBreakdownProps) {
  return (
    <Column spacing="largeLarge" gap="medium" wGrow>
      <Title>{props.title}</Title>
      <Column gap="medium" wGrow>
        <Breakdown
          breakdownType="credit"
          headerName="Total Charges"
          headerValue={props.chargedAmount}
        />
        <Breakdown
          breakdownType={props.feeAmount < 0 ? 'debit' : 'credit'}
          headerName="Fees Collected"
          headerValue={props.feeAmount}
        />
        <Breakdown
          breakdownType="debit"
          headerName="Refunds"
          headerValue={props.refundedAmount}
        />
        <Breakdown
          breakdownType="debit"
          headerName="Returns"
          headerValue={props.returnAmount}
        />
        {!!props.adjustmentAmount && (
          <Breakdown
            breakdownType={props.adjustmentAmount < 0 ? 'debit' : 'credit'}
            headerName="Adjustment"
            headerValue={props.adjustmentAmount}
          />
        )}
        <Separator orientation="horizontal" />
        <Breakdown
          breakdownType="total"
          headerName="Total Paid Out"
          headerValue={props.totalAmount}
        />
      </Column>
    </Column>
  )
}

export default OverlayPayoutBreakdown
