import { Card, Flex, HStack, Button } from '@chakra-ui/react'
import { Button as NickelButton, Form } from 'ui'
import { useState } from 'react'
import { InputField } from 'ui'
import * as yup from 'yup'

type OwnerInformationProps = {
  email: string
  onNextStep: (firstName: string, lastName: string, password: string) => void
}

const OwnerSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup
    .string()
    .min(7, 'Must be at least 7 characters')
    .required('Password is required'),
})

export function OwnerInformation({ email, onNextStep }: OwnerInformationProps) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Flex width="540px" flexDirection="column">
      <Form
        validationSchema={OwnerSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          password: '',
        }}
      >
        {(formik) => {
          const onClick = () => {
            formik.setTouched({
              firstName: true,
              lastName: true,
              password: true,
            })
            formik.validateForm()
            if (formik.isValid) {
              onNextStep(
                formik.values.firstName,
                formik.values.lastName,
                formik.values.password,
              )
            }
          }
          return (
            <>
              <Card py={8} px={8}>
                <Flex flexDirection="column" gap={6}>
                  <InputField
                    label="Email"
                    fontWeight="medium"
                    fontSize="sm"
                    value={email}
                    isDisabled
                    isReadOnly
                  />
                  <HStack gap={4}>
                    <InputField
                      id="firstName"
                      label="First Name"
                      fontWeight="medium"
                      fontSize="sm"
                      value={formik.values.firstName}
                      error={
                        formik.touched.firstName
                          ? formik.errors.firstName
                          : undefined
                      }
                      onChange={formik.handleChange}
                    />
                    <InputField
                      id="lastName"
                      label="Last Name"
                      fontWeight="medium"
                      fontSize="sm"
                      value={formik.values.lastName}
                      error={
                        formik.touched.lastName
                          ? formik.errors.lastName
                          : undefined
                      }
                      onChange={formik.handleChange}
                    />
                  </HStack>
                  <InputField
                    id="password"
                    label="Password"
                    fontWeight="medium"
                    fontSize="sm"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password
                        ? formik.errors.password
                        : undefined
                    }
                    rightElement={
                      <Button
                        h="1.5rem"
                        size="sm"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? 'Hide' : 'Show'}
                      </Button>
                    }
                  />
                </Flex>
              </Card>
              <NickelButton
                label="Next"
                width="100%"
                type="submit"
                iconPosition="right"
                iconName="chevronRight"
                mt={8}
                onClick={(e) => {
                  e.preventDefault()
                  onClick()
                }}
              />
            </>
          )
        }}
      </Form>
    </Flex>
  )
}
