import {
  Card,
  CardBody,
  Flex,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export type InformationCardProps = {
  icon?: any
  firstInfo?: string
  secondInfo?: string
  thirdInfo?: string
  fourthInfo?: string
  closeAction?: () => void
}

export const InformationCard = (props: InformationCardProps) => {
  return (
    <Card w="100%" borderRadius="md" bgColor="gray.100" border="0px">
      <CardBody w="100%">
        <Flex flexDirection="row" gap="3" alignItems="center">
          {props.icon}
          <VStack
            alignContent="start"
            justifyContent="start"
            alignItems="start"
            spacing="0"
            gap="0"
          >
            <Text fontSize="13px" fontWeight="500">
              {props.firstInfo}
            </Text>
            <Text
              fontSize="11px"
              color="gray.400"
            >{`${props.secondInfo} • ${props.thirdInfo} • ${props.fourthInfo}`}</Text>
          </VStack>
          <Spacer />
          {props.closeAction && (
            <IconButton
              aria-label="Remove Person"
              as={XMarkIcon}
              boxSize="5"
              variant="ghost"
              onClick={props.closeAction}
            />
          )}
        </Flex>
      </CardBody>
    </Card>
  )
}
