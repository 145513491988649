import { useFormikContext } from 'formik'
import ContainerProps from '../../../types'
import { LoadingProps } from '../../Button'
import { Button, BaseButtonProps } from 'ui/src/components/v3/button/Button'
import Row, { RowXType } from '../../Layout/Row'

type FormButtonProps = BaseButtonProps &
  ContainerProps &
  LoadingProps & {
    x?: RowXType
  }

export const FormButton = (props: FormButtonProps) => {
  const { submitForm, isSubmitting, setSubmitting, errors, isValid } =
    useFormikContext()

  const defaultOnClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setSubmitting(true)

    await submitForm()

    await setTimeout(async () => {
      setSubmitting(false)
    }, 3000)
  }

  return (
    <Row x={props.x || 'center'} y={'center'} className={props.className}>
      <Button
        data-cy={props.id}
        type="submit"
        variant={props.variant || 'solid'}
        status={props.status || 'primary'}
        size={props.size || 'md'}
        label={props.label}
        isLoading={isSubmitting || props.loading}
        className={props.className}
        iconName={props.iconName}
        iconPosition={props.iconPosition}
        isDisabled={
          props.disabled != undefined
            ? props.disabled
            : (Object.keys(errors).length > 0 && !isValid) || isSubmitting
        }
        onClick={props.onClick || defaultOnClick}
      >
        {props.children}
      </Button>
    </Row>
  )
}

export default FormButton
