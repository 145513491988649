import { useQuery } from '@apollo/client'
import { Flex, Grid, Text, useDisclosure } from '@chakra-ui/react'
import currency from 'currency.js'
import { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Button, Column } from 'ui'
import { OrganizationTradeAccountsDocument } from '../../operations-types'
import { TransactionTabsV2 } from '../ap/TransactionTabs'
import { PageLayout } from '../layout/PageLayout'
import { CustomerCard } from './tradeAccounts/CustomerCard'
import { NewCustomerModal } from './tradeAccounts/NewCustomerModal'
import { TradeAccountOutlet } from './tradeAccounts/TradeAccountOutlet'

export function TradeAccounts() {
  const [selectedTab, setSelectedTab] = useState('ACTIVE')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { subdomain } = useOutletContext() as {
    subdomain: string
  }

  const { data } = useQuery(OrganizationTradeAccountsDocument)

  const navigate = useNavigate()

  return (
    <PageLayout>
      <NewCustomerModal isOpen={isOpen} onClose={onClose} />
      <Column
        wGrow
        className="w-full bg-white rounded-md h-[calc(100vh-2rem)]"
        grow
      >
        <Flex flexDirection="row" justifyContent="space-between" p={4} w="100%">
          <Text fontSize="xl" fontWeight="semibold" color="gray.800">
            Trade Accounts
          </Text>
          <Button label="New Customer" size="sm" onClick={onOpen} />
        </Flex>
        <Flex flexDirection="column" gap={4} w="100%">
          <TransactionTabsV2
            {...{
              selected: selectedTab,
              onSelect: (tab: string) => {
                setSelectedTab(tab)
              },
              tabs: [
                {
                  label: 'Active',
                  value: 'ACTIVE',
                },
                {
                  label: 'Pending',
                  value: 'PENDING',
                },
                {
                  label: 'Archived',
                  value: 'ARCHIVED',
                },
              ],
            }}
          />
          <Flex flexDirection="column" gap={4} w="100%" px={4} pb={4}>
            <Grid gap={4} gridTemplateColumns={'repeat(auto-fill, 400px)'}>
              {data?.organization?.organization?.tradeAccounts?.map(
                (tradeAccount, index) => (
                  <CustomerCard
                    key={index}
                    name={tradeAccount?.name || ''}
                    address={
                      `${tradeAccount.address?.street1}, ${tradeAccount.address?.city} ${tradeAccount.address?.state}, ${tradeAccount.address?.zip}` ||
                      ''
                    }
                    sales={
                      currency(
                        tradeAccount?.transactionsSummary?.totalSalesInCents ||
                          0,
                        { fromCents: true },
                      ).value
                    }
                    balance={
                      currency(
                        tradeAccount?.transactionsSummary
                          ?.remainingBalanceInCents || 0,
                        { fromCents: true },
                      ).value
                    }
                    numContacts={tradeAccount.contacts?.length || 0}
                    numProjects={tradeAccount.projects?.length || 0}
                    status={'Extended Terms'}
                    logoUrl="https://placehold.co/32"
                    onClick={() =>
                      navigate(`/dashboard/trade-accounts/${tradeAccount.id}`)
                    }
                  />
                ),
              )}
            </Grid>
            <TradeAccountOutlet subdomain={subdomain || ''} />
          </Flex>
        </Flex>
      </Column>
    </PageLayout>
  )
}
