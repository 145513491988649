import {
  BarsArrowDownIcon,
  FunnelIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'

import ContainerProps from '../../types'
import Dropdown from '../DropdownV2'
import Row from '../v2/layout/Row'
import { ReffedPaymentsFilterDropdown } from './PaymentsFilterDropdown'
import { Flex, HStack } from '@chakra-ui/react'
import { ReffedBillsFilterDropdown } from '../Bills/BillsFilterDropdown'
import { ReffedPaymentLinksFilter } from '../PaymentLinks/PaymentLinksFilter'

export type Filter = {
  label: string
  value: string | Array<string>
  key?: string
}

export type AddFilterProps = {
  currentFilters: Array<Filter | null>
  funnel?: boolean
  type?: 'payments' | 'bills' | 'paymentLinks'
  onClick?: (
    value?: Date,
    valueTwo?: Date,
    valueThree?: string,
    valueFour?: string,
  ) => void | ((values: any) => void)
  formValues?: any
  onFormClick?: (values: any) => void
  onClose: (label: string, value: string | Array<string>, key: string) => void
}

const AddFilterFunnel = tw(FunnelIcon)`
    w-4
    h-4
    stroke-gray-600
    stroke-2
`

const BarsArrowDownIconStyled = tw(BarsArrowDownIcon)`
    w-4
    h-4
    stroke-gray-600
    stroke-2
`

const Text = tw.div<ContainerProps>`
    text-xs
    font-medium
    text-gray-600
`

const PlaceholderText = tw.div<ContainerProps>`
    text-xs
    font-normal
    text-gray-400
`

const Container = tw(Row)`
    rounded
    px-2
    py-1
`

type FilterItemProps = {
  label: string
  value: string | Array<string>
  onClose: () => void
}

const FilterContainer = tw(Container)`
  bg-gray-100
  border
  border-gray-200
  !p-2
  h-[26px]
`

export const FilterItem = (props: FilterItemProps) => {
  return (
    <FilterContainer y="center">
      <HStack gap={1}>
        <Text className="whitespace-nowrap">{props.label}: </Text>
        {Array.isArray(props.value) ? (
          props.value.map((val, index) => {
            return (
              <Text key={index} className="capitalize whitespace-nowrap">
                {val}
                {index !== (props.value || '').length - 1 ? ', ' : ''}
              </Text>
            )
          })
        ) : (
          <Text className="whitespace-nowrap">{props.value}</Text>
        )}
      </HStack>
      <XCircleIcon
        className="w-4 h-4 stroke-gray-600 stroke-2 mx-1 cursor-pointer"
        {...{
          onClick: props.onClose,
        }}
      />
    </FilterContainer>
  )
}

const AddFilter = ({
  type = 'payments',
  funnel = true,
  ...props
}: AddFilterProps) => {
  return (
    <Row gap="small" y="center">
      <Dropdown
        className="border !border-gray-300 h-[26px] relative"
        label={
          <Container y="center" gap="small">
            {funnel ? <AddFilterFunnel /> : <BarsArrowDownIconStyled />}
            <Text className="whitespace-nowrap">Add filter</Text>
          </Container>
        }
      >
        {type === 'payments' ? (
          <ReffedPaymentsFilterDropdown
            {...{
              onSubmit: (valOne, valTwo, valThree, valFour) => {
                if (props.onClick) {
                  props?.onClick(valOne, valTwo, valThree, valFour)
                }
              },
            }}
          />
        ) : type === 'paymentLinks' ? (
          <ReffedPaymentLinksFilter
            {...{
              onSubmit: (values) => {
                if (props.onFormClick) {
                  props?.onFormClick(values as any)
                }
              },
              formValues: props.formValues,
            }}
          />
        ) : (
          <ReffedBillsFilterDropdown
            {...{
              onSubmit: (values) => {
                if (props.onFormClick) {
                  props?.onFormClick(values as any)
                }
              },
              formValues: props.formValues,
            }}
          />
        )}
      </Dropdown>
      <Flex
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        className="overflow-y-scroll flex flex-row gap-2"
      >
        {(props.currentFilters || []).length === 0 ? (
          <PlaceholderText>No Filter applied</PlaceholderText>
        ) : (
          props.currentFilters.map((filter, index) => {
            return (
              <FilterItem
                key={index}
                label={filter?.label || ''}
                value={filter?.value || ''}
                onClose={() =>
                  props.onClose(
                    filter?.label || '',
                    filter?.value || '',
                    filter?.key || '',
                  )
                }
              />
            )
          })
        )}
      </Flex>
    </Row>
  )
}

export default AddFilter
