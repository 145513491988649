import { create } from 'zustand'
import { UserRole } from '../../operations-types'
import { User } from '../../types'
import { LoggedInUser } from './LoggedInUser'

type LoggedInState = {
  user: LoggedInUser
  setUser: (user: User) => void
  getUser: () => LoggedInUser
}

export const useLoggedInStore = create<LoggedInState>((set, get) => ({
  user: new LoggedInUser(
    {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      role: UserRole.User,
    },
    { id: '', name: '', approvalPolicies: [] },
  ),
  setUser: (user: User) =>
    set({ user: new LoggedInUser(user, user.organization!) }),
  getUser: () => get().user,
}))
