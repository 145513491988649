import { useMutation } from '@apollo/client'
import { Box, Divider, Fade, Flex, Spinner, useToast } from '@chakra-ui/react'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import currency from 'currency.js'
import { sum } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Column, Form, Row } from 'ui'
import 'popstate-direction'
import ContainerProps from 'ui/src/types'
import {
  ResendPaymentLinkToRecipientDocument,
  UpdateQuestionFormDocument,
} from '../../operations-types'

import { GoBack } from '../ap/components/send-money/SendMoneyHeader'
import { usePaymentLinkStore } from '../layout/PaymentLinkStore'
import BulkPaymentLinkElement, {
  AddRecipientDialog,
  BulkPaymentValidationSchema,
  BulkPaymentValidationSchemaType,
} from './BulkPaymentLinkElement'

export function BulkPaymentLayout(props: ContainerProps) {
  const navigate = useNavigate()
  const { setPaymentLinks, paymentLinks } = usePaymentLinkStore()

  const onExit = () => {
    navigate('/dashboard/get-paid')
    setPaymentLinks([])
  }

  const [updateQuestionForm] = useMutation(UpdateQuestionFormDocument)

  const [resendPaymentLinktoRecipient] = useMutation(
    ResendPaymentLinkToRecipientDocument,
    {
      onCompleted: () => {},
    },
  )

  const toast = useToast()

  window.addEventListener('back', () => {
    setPaymentLinks([])
  })

  const [loading, setLoading] = useState(false)
  const onPaymentRequests = async (values: BulkPaymentValidationSchemaType) => {
    setLoading(true)

    await Promise.all(
      (values.paymentLinks || []).map((pl) =>
        updateQuestionForm({
          variables: {
            questionFormId: pl.id || '',
            memo: pl.message || '',
            recipients: (pl.recipients || []).map((r) => {
              return {
                name: r.name,
                email: r.email,
                phoneNumber: r.phoneNumber,
              }
            }),
          },
        }),
      ),
    )
    await Promise.all(
      (values.paymentLinks || [])
        .filter((g) => (g.recipients || [])?.length > 0)
        .map((pl) => {
          return resendPaymentLinktoRecipient({
            variables: {
              questionFormId: pl.id!,
              recipient: {
                name: pl.recipients?.[0].name || '',
                email: pl.recipients?.[0].email || '',
                phoneNumber: pl.recipients?.[0].phoneNumber || '',
              },
            },
          })
        }),
    )

    setLoading(false)

    toast({
      title: 'Payment requests sent successfully!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })

    onExit()
  }

  const totalBeingPaid = sum(
    paymentLinks.map(
      (pl) =>
        currency(pl.requestedAmount || '$0.00').subtract(
          currency(pl.completedAmountInCents || '$0.00'),
        ).value,
    ),
  )

  return (
    <Form
      validationSchema={BulkPaymentValidationSchema}
      initialValues={
        {
          modalOpen: false,
          selectedIndex: undefined,
          paymentLinks: paymentLinks.map((pl) => ({
            id: pl.id,
            invoiceAmount: pl.requestedAmount || '$0.00',
            alreadyPaid: pl.completedAmountInCents || '$0.00',
            message: pl.memo,
            title: pl.name,
            recipients:
              pl.recipients?.length === 0 &&
              pl.invoice?.customerRef?.emailAddress
                ? [
                    {
                      name: pl.invoice?.customerRef?.customerName || '',
                      email: pl.invoice?.customerRef?.emailAddress || '',
                      phoneNumber: '',
                    },
                  ]
                : pl.recipients,
            youRequest: currency(pl.requestedAmount || '$0.00')
              .subtract(currency(pl.completedAmountInCents || '$0.00'))
              .format(),
          })),
          currentCount: paymentLinks.length,
          newContact: {
            name: '',
            email: '',
            phoneNumber: '',
          },
        } as BulkPaymentValidationSchemaType
      }
    >
      {(props) => (
        <Box className="h-screen">
          <AddRecipientDialog
            {...{
              isOpen: props.values.modalOpen,
              onCloseAction: () => {
                props.setFieldValue('modalOpen', false)
                props.setFieldValue('selectedIndex', null)
              },
            }}
          />
          <Box>
            <Box px={10} py={5}>
              <Flex justify="space-between" align="center" height="40px">
                <div className="text-sm font-medium">
                  Request payments in bulk
                </div>
                {onExit && <GoBack onClick={onExit} />}
              </Flex>
            </Box>{' '}
            <Divider orientation="horizontal" />
          </Box>
          <Fade
            key={'bulkpayment'}
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            className="!h-[calc(100%-85px)]"
          >
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              className="h-full"
            >
              <Flex
                flexDirection="column"
                className="h-full w-full"
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Column
                  wGrow
                  className="gap-[40px] !max-h-[calc(100vh-250px)] overflow-y-scroll"
                  x="center"
                >
                  {props.values.paymentLinks?.map((pL, idx) => (
                    <BulkPaymentLinkElement {...pL} key={idx} index={idx} />
                  ))}
                </Column>
              </Flex>
              <Row
                grow
                className="border-t px-10 py-5 relative"
                between
                y="center"
              >
                <Row grow between y="center" className="relative top-[5px]">
                  <Row y="center" gap="large">
                    <Column className="relative bottom-[1px]">
                      <div className="text-sm font-normal text-gray-600">
                        Payment Request Links
                      </div>
                      <div className="text-lg font-semibold">
                        {paymentLinks.length}
                      </div>
                    </Column>
                    <Column className="relative bottom-[1px]">
                      <div className="text-sm font-normal text-gray-600">
                        Total Amount Requested
                      </div>
                      <div className="text-lg font-semibold">
                        {currency(totalBeingPaid).format()}
                      </div>
                    </Column>
                  </Row>
                  <Button
                    className="!h-[30px] !w-[150px] !hover:bg-[#4d4351]"
                    isDisabled={
                      props.values.paymentLinks?.length === 0 || !props.isValid
                    }
                    onClick={async () => {
                      await onPaymentRequests(props.values)
                    }}
                    label={
                      loading ? (
                        <Row y="center" x="center">
                          <Spinner size={'xs'} />
                        </Row>
                      ) : (
                        <Row y="center" gap="small">
                          <EnvelopeIcon
                            className={`${
                              !props.isValid ||
                              (props.values.paymentLinks || []).length === 0
                                ? 'stroke-gray-500'
                                : 'stroke-bg-primary-500'
                            } w-4 h-4`}
                          />
                          <div className="text-md font-normal text-sm">
                            Send Requests
                          </div>
                        </Row>
                      )
                    }
                  />
                </Row>
              </Row>
            </Flex>
          </Fade>
        </Box>
      )}
    </Form>
  )
}
