import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'
import Row, { RowProps } from '../Layout/Row'

export type DownloadCSVProps = {
  isLoading?: boolean
} & ContainerProps

const DownloadCSVContainer = tw<RowProps & DownloadCSVProps>(Row)`
  rounded
  border
  border-gray-300
  px-2
  py-1
  cursor-pointer
  hover:bg-gray-200

  ${(props) => (props.isLoading ? 'opacity-50 pointer-events-none' : '')}
`

const Text = tw.div<ContainerProps>`
  text-gray-600
  text-xs
  font-medium
  uppercase
`

const ArrowDownTray = tw(ArrowDownTrayIcon)`
  w-4
  h-4
  stroke-gray-600
  stroke-2
`

export const DownloadCSV = (props: DownloadCSVProps) => {
  return (
    <DownloadCSVContainer
      {...props}
      gap="small"
      y="center"
      isLoading={props.isLoading}
    >
      <ArrowDownTray />
      <Text>CSV</Text>
    </DownloadCSVContainer>
  )
}

export default DownloadCSV
