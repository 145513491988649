import { OperationVariables } from '@apollo/client'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { GET_PAID_URL } from '../../lib/urls'
import { Overlay } from 'ui'

type PaymentLinkOutletProps = {
  queryOpts: OperationVariables
  subdomain: string
}

export function PaymentLinkOutlet({
  queryOpts,
  subdomain,
}: PaymentLinkOutletProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(GET_PAID_URL)

  const paidMatch = location.pathname.match(/\/dashboard\/get-paid\/(.*)/)

  return (
    <Overlay
      open={!!paidMatch}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet
        context={{
          queryOpts: queryOpts,
          subdomain: subdomain,
        }}
      />
    </Overlay>
  )
}
