import { FlowLayout } from '../../layout/FlowLayout'
import { AccountantHeader } from './AccountantHeader'
import { AccountantRegistrationStep } from './registrationFlowStore'

type RegistrationFlowProps = {
  currentPage: AccountantRegistrationStep
  title: string
  subtitle: string
  children: React.ReactNode
}

export function RegistrationFlowLayout({
  currentPage,
  title,
  subtitle,
  children,
}: RegistrationFlowProps) {
  return (
    <FlowLayout
      currentPage={currentPage}
      title={title}
      subtitle={subtitle}
      header={<AccountantHeader currentPage={currentPage} />}
    >
      {children}
    </FlowLayout>
  )
}
