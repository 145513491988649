import { Badge } from '@chakra-ui/react'

export type TransactionStatusValue =
  | 'Completed'
  | 'In Progress'
  | 'In Progress - Accepted'
  | 'In Progress - Authorizing'
  | 'Created'
  | 'Failed'
  | 'Declined'
  | 'Returned'
  | 'Failed - Chargeback'
  | 'Refunded'
  | 'Succeeded'
  | 'Paid'
  | 'Pending'
  | 'Canceled'
  | 'Submitted'
  | 'Confirmed'
  | 'Voided'

export const TransactionStatusColorScheme: Record<
  TransactionStatusValue,
  string
> = {
  Completed: 'green',
  'In Progress': 'gray',
  'In Progress - Accepted': 'gray',
  'In Progress - Authorizing': 'gray',
  Created: 'gray',
  Failed: 'red',
  Declined: 'red',
  Returned: 'red',
  Submitted: 'gray',
  Confirmed: 'gray',
  'Failed - Chargeback': 'red',
  Refunded: 'yellow',
  Succeeded: 'green',
  Paid: 'green',
  Pending: 'blue',
  Canceled: 'warning',
  Voided: 'yellow',
}

export type InProgressDetail = 'Authorizing' | 'Accepted'

export type FailedDetail = 'Declined' | 'Returned' | 'Chargeback'

type TransactionStatusProps = {
  transactionStatus: TransactionStatusValue
}

const TransactionStatus = (props: TransactionStatusProps) => {
  return (
    <Badge
      colorScheme={TransactionStatusColorScheme[props.transactionStatus]}
      fontWeight="medium"
      paddingX={2}
      paddingY={0.5}
    >
      {props.transactionStatus}
    </Badge>
  )
}

export default TransactionStatus
