import { useMutation } from '@apollo/client'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { HFlex, Icon, Overlay, VFlex, Button } from 'ui'
import {
  AddVendorDocument,
  Vendor,
  VendorsDocument,
} from '../../operations-types'
import { VendorPayoutDetails } from './components/VendorPayoutDetails'
import { SlimSelectVendor } from './VendorDetails'
import * as yup from 'yup'

export type NewBillOverlayProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  vendor?: Vendor | null
}
type OverlayHeaderProps = {
  onClose: () => void
}

function OverlayHeader({ onClose }: OverlayHeaderProps) {
  return (
    <Box width="100%" px={8} py={6}>
      <HFlex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="2xl" fontWeight="semibold" color="gray.800">
          New Vendor
        </Text>
        <Icon
          name="xMark"
          size="medium"
          hoverable
          className="stroke-gray-500"
          onClick={onClose}
        />
      </HFlex>
      <Text fontSize="sm" color="gray.500">
        Create a new vendor to track and pay in Nickel
      </Text>
    </Box>
  )
}

type VendorSelectProps = {
  onVendor: (vendor: Vendor | null) => void
}

function VendorSelect({ onVendor }: VendorSelectProps) {
  const [addVendor] = useMutation(AddVendorDocument, {
    onCompleted: (data) => {
      if (data.addVendor?.vendor) {
        onVendor(data.addVendor?.vendor)
      }
    },
    refetchQueries: [VendorsDocument],
  })

  return (
    <Box px={8} py={6} w="100%">
      <VFlex gap={4}>
        <Box>
          <Text fontSize="lg" fontWeight="semibold" color="gray.800">
            Who are you paying?
          </Text>
          <Text fontSize="sm" color="gray.500">
            Select your vendor
          </Text>
        </Box>
        <SlimSelectVendor
          onNewVendor={(newVendor) => {
            addVendor({
              variables: { name: newVendor },
            })
          }}
          onVendorSelect={(vendor) => onVendor(vendor)}
          label={false}
        />
      </VFlex>
    </Box>
  )
}

function SelectedVendor({
  selectedVendor,
  setSelectedVendor,
  onVendor,
}: {
  selectedVendor: Vendor
  setSelectedVendor: (vendor: Vendor | null) => void
  onVendor: (vendor: Vendor | null) => void
}) {
  return (
    <Box px={8} py={6} w="100%">
      <Box>
        <Text fontSize="lg" fontWeight="semibold" color="gray.800">
          Who are you paying?
        </Text>
        <Text fontSize="sm" color="gray.500">
          Select your vendor
        </Text>
      </Box>
      <VendorPayoutDetails
        label={false}
        vendorId={selectedVendor.id}
        vendorName={selectedVendor?.name || ''}
        vendorAchPayoutMethod={
          (selectedVendor?.vendorPayoutMethods || []).find(
            (x) => x?.type === 'ACH',
          ) ?? undefined
        }
        vendorCheckPayoutMethod={
          (selectedVendor?.vendorPayoutMethods || []).find(
            (x) => x?.type === 'CHECK',
          ) ?? undefined
        }
        setSelectedVendor={setSelectedVendor}
        onVendor={onVendor}
      />
    </Box>
  )
}

const NewVendorSchema = yup.object({
  vendorId: yup.string().required('Vendor is required'),
})

type NewVendorFormData = yup.InferType<typeof NewVendorSchema>

export function NewVendorOverlay({
  isOpen,
  setOpen,
  vendor,
}: NewBillOverlayProps) {
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(
    vendor || null,
  )

  useEffect(() => {
    if (vendor) {
      setSelectedVendor(vendor)
    }
  }, [vendor])

  return (
    <Overlay
      open={isOpen}
      setOpen={(boolean) => {
        setSelectedVendor(null)
        setOpen(boolean)
      }}
    >
      <OverlayHeader
        onClose={() => {
          setOpen(false)
          setSelectedVendor(null)
        }}
      />
      <Divider />
      <Formik<NewVendorFormData>
        initialValues={{
          vendorId: vendor?.id || '',
        }}
        validateOnMount={true}
        validationSchema={NewVendorSchema}
        onSubmit={(values) => {}}
      >
        {(props) => {
          return (
            <>
              {!selectedVendor && (
                <VendorSelect
                  onVendor={(vendor) => {
                    if (vendor) {
                      setSelectedVendor(vendor)
                      props.setFieldValue('vendorId', vendor.id)
                    }
                  }}
                />
              )}
              {selectedVendor && (
                <SelectedVendor
                  selectedVendor={selectedVendor}
                  setSelectedVendor={setSelectedVendor}
                  onVendor={(vendor) => {
                    setSelectedVendor(vendor)
                    props.setFieldValue('vendorId', vendor?.id || '')
                  }}
                />
              )}
              <Box w="100%" mt="auto">
                <Divider />
                <Flex justifyContent="flex-end" py={4} px={8} gap={4}>
                  <Button
                    label="Save and Close"
                    variant="outline"
                    isDisabled={!props.isValid}
                    onClick={async () => {
                      setOpen(false)
                      setSelectedVendor(null)
                    }}
                  />
                  <Button
                    label="Add another vendor"
                    isDisabled={!props.isValid}
                    onClick={async () => {
                      setSelectedVendor(null)
                      props.setFieldValue('vendorId', '')
                    }}
                  />
                </Flex>
              </Box>
            </>
          )
        }}
      </Formik>
    </Overlay>
  )
}
