import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Bill,
  BillsDocument,
  BillsQueryVariables,
  PayableStatus,
  SortOrder,
  UserRole,
} from '../../operations-types'
import { useQuery } from '@apollo/client'
import { PageLayout } from '../layout/PageLayout'
import {
  Button,
  Column,
  Header,
  Overlay,
  Row,
  TableCellProps,
  TableV2,
} from 'ui'
import { Box, VStack, Text } from '@chakra-ui/react'
import { TransactionTabsV2 } from '../ap/TransactionTabs'
import QueryString from 'qs'
import { FilterSchema } from 'ui/src/components/Bills/BillsFilterDropdown'
import { omit } from 'lodash'
import AddFilter from 'ui/src/components/Transactions/AddFilter'
import TableSearchInput from 'ui/src/components/TableSearchInput'
import BillPayOverlay from '../ap/BillPayOverlay'
import currency from 'currency.js'
import { NewBillOverlay } from '../ap/NewBillOverlay'
import { useLoggedInStore } from '../layout/LoggedInStore'

const SMALL_CELL_PX = 40

export function Bills() {
  const availableSpace = window.screen.availHeight - 420
  const pageSize = Math.floor(availableSpace / SMALL_CELL_PX)

  const [page, setPage] = useState(1)

  const [params, setParams] = useSearchParams()

  const [searchQuery, setSearchQuery] = useState(
    params.get('searchQuery') || '',
  )

  const [vendorName] = useState(params.get('vendorName') || '')
  const [billReferenceId] = useState(params.get('billReferenceId') || '')
  const [dueDateStartDate] = useState(params.get('dueDateStartDate') || '')
  const [dueDateEndDate] = useState(params.get('dueDateEndDate') || '')
  const [billAmountStartValue] = useState(
    params.get('billAmountStartValue') || '',
  )
  const [billAmountEndValue] = useState(params.get('billAmountEndValue') || '')
  const [paidAmountEndValue] = useState(params.get('paidAmountEndValue') || '')
  const [paidAmountStartValue] = useState(
    params.get('paidAmountStartValue') || undefined,
  )

  const [queryOpts, setQueryOpts] = useState<BillsQueryVariables>({
    page: page,
    pageSize: pageSize,
    payableQuery:
      !params.get('type') || params.get('type') === 'ALL'
        ? undefined
        : (params.get('type') as PayableStatus),
    searchQuery,
    vendorName,
    billReferenceId,
    dueDateStartDate,
    dueDateEndDate,
    billAmountStartValue,
    billAmountEndValue,
    paidAmountStartValue,
    issuedOnStartDate: params.get('issuedOnStartDate') || '',
    issuedOnEndDate: params.get('issuedOnEndDate') || '',
    paidAmountEndValue,
    orderBy: (params.get('orderBy') || 'dueDate') as string,
    sortOrder: params.get('sortBy') || 'asc',
  })

  const { data: billsData, loading: billsPayableLoading } = useQuery(
    BillsDocument,
    {
      variables: {
        ...queryOpts,
      },
      pollInterval: 3000,
    },
  )

  const totalPages = Math.round(
    (billsData?.bills?.totalResults || 0) / pageSize,
  )

  const statusToLabel = (status?: string | null) => {
    if (status === 'Paid') {
      return 'Paid in Full'
    } else if (status === 'Open') {
      return 'Outstanding'
    } else if (status === 'PartiallyPaid') {
      return 'Partially Paid'
    }
  }
  const billPayablesConverted = (billsData?.bills?.bills || []).map((g) => {
    const approvals =
      g.billPayables
        ?.flatMap((x) => x.approvals)
        .filter((x) => x?.status === 'PENDING') || []

    return {
      ...g,
      status: {
        label: statusToLabel(g.billData?.status),
        status:
          g.billData?.status === 'Paid'
            ? 'success'
            : g.billData?.status === 'PartiallyPaid'
            ? 'action'
            : 'info',
      },
      vendorName: g.billData?.supplierRef?.supplierName,
      reference: {
        source: g.billSource,
        label: g.billData?.reference,
      },
      pendingPayment:
        approvals?.length > 0
          ? {
              line1: currency(
                approvals.reduce(
                  (acc, x) => acc + (x?.submittedAmountInCents || 0),
                  0,
                ),
                { fromCents: true },
              ).format(),
              line2: `${approvals.length} pending payments`,
            }
          : {},
      dueDate: g.billData?.dueDate,
      balanceDue: {
        line1: currency(g.billData?.amountDue || 0).format(),
        line2:
          g.billData?.amountDue !== g.billData?.totalAmount
            ? `Bill Total: ${currency(g.billData?.totalAmount || 0).format()}`
            : '',
      },
      action: {
        label: 'Pay Bill',
        amountDue: g.billData?.amountDue || 0,
      },
    }
  })

  const [open, setOpen] = useState(false)
  const [newBillOpen, setNewBillOpen] = useState(false)
  const [currentInvoice, setCurrentInvoice] = useState<string | null>(null)

  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState<string>(
    params.get('type') || 'Open',
  )

  const { getUser } = useLoggedInStore()
  const IS_PERMITTED =
    getUser().isUserPermitted(UserRole.Manager) ||
    !!getUser()?.organization?.approvalPolicies?.length

  useEffect(() => {
    if (!params.get('type')) {
      setQueryOpts({
        ...queryOpts,
        payableQuery: ['Open', 'PartiallyPaid'] as PayableStatus[],
      })
      setParams({ type: 'Open', page: params.get('page') || '1' })
    }
  }, [])

  useEffect(() => {
    setParams({
      page: (queryOpts.page || 1).toString(),
      type: queryOpts.payableQuery || 'Open',
      ...(queryOpts.orderBy ? { orderBy: queryOpts.orderBy || '' } : {}),
      ...(queryOpts.issuedOnStartDate
        ? { issuedStartDate: queryOpts.issuedOnStartDate || '' }
        : {}),
      ...(queryOpts.issuedOnEndDate
        ? { issuedEndDate: queryOpts.issuedOnEndDate || '' }
        : {}),
      ...(queryOpts.paidAmountStartValue
        ? { paidAmountStartValue: queryOpts.paidAmountStartValue || '' }
        : {}),
      ...(queryOpts.paidAmountEndValue
        ? { paidAmountEndValue: queryOpts.paidAmountEndValue || '' }
        : {}),
      ...(queryOpts.billAmountStartValue
        ? { billAmountStartValue: queryOpts.billAmountStartValue || '' }
        : {}),
      ...(queryOpts.billAmountEndValue
        ? { billAmountEndValue: queryOpts.billAmountEndValue || '' }
        : {}),
      ...(queryOpts.vendorName
        ? { vendorName: queryOpts.vendorName || '' }
        : {}),
      ...(queryOpts.billReferenceId
        ? { billReferenceId: queryOpts.billReferenceId || '' }
        : {}),
      ...(queryOpts.searchQuery
        ? { searchQuery: queryOpts.searchQuery || '' }
        : {}),
      ...(queryOpts.dueDateStartDate
        ? { dueDateStartDate: queryOpts.dueDateStartDate || '' }
        : {}),
      ...(queryOpts.dueDateEndDate
        ? { dueDateEndDate: queryOpts.dueDateEndDate || '' }
        : {}),
      ...(queryOpts.sortOrder ? { sortBy: queryOpts.sortOrder || '' } : {}),
    })
  }, [queryOpts])

  const currentFilters = [
    queryOpts.billReferenceId
      ? {
          label: 'Bill Reference ID',
          value: queryOpts.billReferenceId,
          key: 'billReferenceId',
        }
      : null,
    queryOpts.vendorName
      ? {
          label: 'Vendor Name',
          value: queryOpts.vendorName,
          key: 'vendorName',
        }
      : null,
    queryOpts.issuedOnStartDate
      ? {
          label: 'Issued Start Date',
          value: queryOpts.issuedOnStartDate,
          key: 'issuedOnStartDate',
        }
      : null,
    queryOpts.issuedOnEndDate
      ? {
          label: 'Issued End Date',
          value: queryOpts.issuedOnEndDate,
          key: 'issuedOnEndDate',
        }
      : null,
    queryOpts.dueDateStartDate
      ? {
          label: 'Due Start Date',
          value: queryOpts.dueDateStartDate,
          key: 'dueDateStartDate',
        }
      : null,

    queryOpts.dueDateEndDate
      ? {
          label: 'Due End Date',
          value: queryOpts.dueDateEndDate,
          key: 'dueDateEndDate',
        }
      : null,
    queryOpts.billAmountStartValue
      ? {
          label: 'Bill Amount Start Value',
          value: queryOpts.billAmountStartValue,
          key: 'billAmountStartValue',
        }
      : null,
    queryOpts.billAmountEndValue
      ? {
          label: 'Bill Amount End Value',
          value: queryOpts.billAmountEndValue,
          key: 'billAmountEndValue',
        }
      : null,
    queryOpts.paidAmountStartValue
      ? {
          label: 'Paid Amount Start Value',
          value: queryOpts.paidAmountStartValue,
          key: 'paidAmountStartValue',
        }
      : null,
    queryOpts.paidAmountEndValue
      ? {
          label: 'Paid Amount End Value',
          value: queryOpts.paidAmountEndValue,
          key: 'paidAmountEndValue',
        }
      : null,
    queryOpts.searchQuery
      ? {
          label: 'Search Query',
          value: queryOpts.searchQuery,
          key: 'searchQuery',
        }
      : null,
  ].filter((e) => e !== null)

  const onCustomFilter = (values?: FilterSchema) => {
    setQueryOpts(Object.assign({}, queryOpts, values))
  }

  const onClose = (
    label: string,
    value: string | Array<string>,
    key: string,
  ) => {
    setQueryOpts(omit(queryOpts, key) as unknown as BillsQueryVariables)
  }

  const [localSearchQuery, setLocalSearchQuery] = useState('')

  return (
    <PageLayout>
      <Column
        wGrow
        className="w-full bg-white rounded-md max-h-[calc(100vh-2rem)] min-h-[calc(100vh-2rem)]"
      >
        <Row className="rounded-md" between grow>
          <VStack
            spacing="0"
            gap="0"
            alignItems="left"
            className="w-full h-full"
          >
            <Row className="p-4 w-full" between>
              <Row grow y="center" between>
                <Column>
                  <Header variant="page" className="text-[20px]">
                    Bills
                  </Header>
                  <Text color="gray.500" className="text-sm">
                    Track, manage and pay your bills in one place
                  </Text>
                </Column>
              </Row>
              <Button
                label="New Bill"
                iconName="plusIcon"
                className="!text-sm !h-[30px]"
                iconPosition="left"
                onClick={() => setNewBillOpen(true)}
              />
            </Row>
            <Column wGrow className="!pb-0" gap="small">
              <TransactionTabsV2
                tabs={[
                  {
                    label: 'All Bills',
                    value: 'ALL',
                  },
                  {
                    label: 'Outstanding',
                    value: 'Open',
                  },
                  {
                    label: 'Paid in Full',
                    value: 'Paid',
                  },
                ]}
                selected={selectedTab}
                onSelect={(tab) => {
                  setPage(1)
                  setParams({ tab })
                  setQueryOpts({
                    ...queryOpts,
                    page: 1,
                    payableQuery: (tab === 'ALL'
                      ? undefined
                      : tab === 'Open'
                      ? ['Open', 'PartiallyPaid']
                      : tab) as PayableStatus,
                  })
                  setSelectedTab(tab as string)
                  navigate(
                    `/dashboard/accounts-payable/bills?${QueryString.stringify({
                      type: tab,
                    })}`,
                  )
                }}
              />
              <Column className="h-full" wGrow grow>
                <Column wGrow>
                  <Row className="py-2 px-4" grow between>
                    <AddFilter
                      {...{
                        currentFilters: currentFilters,
                        onFormClick: onCustomFilter,
                        onClose: onClose,
                        formValues: queryOpts,
                        type: 'bills',
                        funnel: false,
                      }}
                    />
                    <TableSearchInput
                      placeholder="Search bills"
                      {...{
                        value: localSearchQuery,
                        onChange: (e) => {
                          setLocalSearchQuery(e.target.value)
                        },
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            setSearchQuery(localSearchQuery)
                            setQueryOpts({
                              ...queryOpts,
                              searchQuery: localSearchQuery,
                            })

                            setLocalSearchQuery('')
                          }
                        },
                      }}
                    />
                  </Row>
                  <Box w="100%" pt="4" py={2} className="!pb-0">
                    <TableV2
                      {...{
                        onClick: (inv) => {
                          setCurrentInvoice(inv.id)
                          setOpen(true)
                        },
                        loading: billsPayableLoading,
                        rowSize: 'large',
                        cellSize: 'large',
                        sortBy: queryOpts.orderBy || '',
                        sortOrder:
                          (queryOpts.sortOrder as any) || ('desc' as SortOrder),
                        onSort: (sortBy, sortOrder) => {
                          setQueryOpts({
                            ...queryOpts,
                            orderBy: sortBy as string,
                            sortOrder: sortOrder,
                          })
                        },
                        headers: [
                          {
                            type: 'description',
                            keyName: 'vendorName',
                            label: 'Vendor',
                            grow: 1,
                            width: 'large',
                          },
                          {
                            type: 'billId',
                            label: 'Bill / Reason for Payment',
                            keyName: 'reference',
                            width: 'large',
                            grow: 1,
                          },
                          {
                            type: 'status',
                            keyName: 'status',
                            label: 'Status',
                            width: 'medium',
                          },
                          {
                            type: 'date',
                            keyName: 'dueDate',
                            label: 'Due Date',
                            sortable: true,
                          },
                          {
                            type: 'twoLines',
                            keyName: 'pendingPayment',
                            label: 'Pending Payment',
                            width: 'large',
                            right: true,
                          },
                          {
                            type: 'twoLines',
                            label: 'Balance Due',
                            keyName: 'balanceDue',
                            width: 'large',
                            right: true,
                          },
                          IS_PERMITTED
                            ? {
                                type: 'buttonOptionsLink',
                                disabled: false,
                                keyName: 'action',
                                label: 'Actions',
                                right: true,
                                onClick: async (x: string, y: Bill) => {
                                  if (y.billData?.status !== 'Paid') {
                                    setCurrentInvoice(y.id)
                                    navigate(`/send-money/bill/${y.id}`)
                                  }
                                },
                              }
                            : {},
                        ].filter(Boolean) as TableCellProps<object>[],
                        data: billPayablesConverted,
                        page: queryOpts.page || 1,
                        perPage: queryOpts.pageSize || 10,
                        totalPages: totalPages || 1,
                        onChange: () => {},
                        onPage: async (page: number) => {
                          await setPage(page)
                          await setQueryOpts({
                            ...queryOpts,
                            page: page,
                          })
                        },
                      }}
                    />
                    <NewBillOverlay
                      setOpen={setNewBillOpen}
                      isOpen={newBillOpen}
                    />
                    <Overlay open={open} setOpen={setOpen}>
                      <BillPayOverlay
                        {...{
                          id: currentInvoice ?? '',
                          onClose: () => setOpen(false),
                        }}
                      />
                    </Overlay>
                  </Box>
                </Column>
              </Column>
            </Column>
          </VStack>
        </Row>
      </Column>
    </PageLayout>
  )
}
