import {
  Card,
  CardBody,
  VStack,
  Flex,
  useDisclosure,
  Icon,
  Text,
  Spacer,
  HStack,
  Collapse,
  Divider,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Button } from 'ui'

import { ReactNode } from 'react'

export type PaymentLinkSectionProps = {
  headerIcon: any
  label: string
  sublabel?: string
  children: ReactNode
  disabled?: boolean
  saveOnClick?: () => void
}

export function PaymentLinkSection({
  headerIcon,
  label,
  sublabel,
  children,
  disabled,
  saveOnClick,
}: PaymentLinkSectionProps) {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  })

  return (
    <Card w="100%">
      <CardBody w="600px">
        <VStack spacing="2" alignItems="left">
          <Flex
            flexDirection="row"
            onClick={onToggle}
            cursor="pointer"
            gap="2"
            py="2"
          >
            <Icon as={headerIcon} boxSize="5" mt="2px" />
            <VStack alignItems="left" spacing="0" gap="0">
              <Text fontWeight="semibold">{label}</Text>
              <Text fontWeight="sm" fontSize="sm" color="gray.500">
                {sublabel}
              </Text>
            </VStack>
            <Spacer />
            <Icon as={ChevronDownIcon} boxSize="5" color="gray.500" />
          </Flex>

          <Collapse in={isOpen}>
            <Divider />
            <VStack spacing="2" alignItems="left" p="2">
              {children}
            </VStack>
            <Flex flexDirection="row" alignItems="right">
              <Spacer />
              <HStack>
                <Button
                  label="Cancel"
                  variant="ghost"
                  size="sm"
                  onClick={onToggle}
                />
                <Button
                  label="Save"
                  variant="outline"
                  size="sm"
                  onClick={saveOnClick}
                  isDisabled={disabled}
                />
              </HStack>
            </Flex>
          </Collapse>
        </VStack>
      </CardBody>
    </Card>
  )
}

export default PaymentLinkSection
