import { styles, tw } from '@nickeltech/brise'
import React from 'react'
import Column from '../layout/Column'
import { TextInputLabel } from './TextInputLabel'
import styled from 'styled-components'

export type TextInputGroupVisibilityTransitionProps = {
  visible?: boolean
}

export const TextInputGroupVisibilityTransition = styles<TextInputGroupVisibilityTransitionProps>`
  transition-all
  ${({ visible = true }) =>
    visible ? 'opacity-100 w-inherit' : 'opacity-0 w-0'}
`

export type TextInputGroupDisabledTransitionProps = {
  disabled?: boolean
}

export const TextInputGroupDisabledTransition = styles<TextInputGroupDisabledTransitionProps>`
  transition-all
  ${({ disabled = false }) =>
    disabled
      ? '!opacity-50 w-inherit cursor-default pointer-events-none'
      : 'opacity-100 w-inherit'}
`

export type V2TextInputGroupProps = {
  label?: string
  children?: React.ReactNode | Array<React.ReactNode>
} & TextInputGroupDisabledTransitionProps &
  TextInputGroupVisibilityTransitionProps

export const TextInputGroupContainer = tw(Column)`
    rounded
    border-gray-300
    border
`

export const TextInputStyled = styled(Column)`
  input {
    border: none;
  }

  > div.flex-row {
    border-bottom: 1px solid rgb(209 213 219 / var(--tw-border-opacity));

    .flex-col:not(:last-child) {
      input {
        border-right: 1px solid rgb(209 213 219 / var(--tw-border-opacity)) !important;
        border-radius: 0;
      }
    }
  }

  > div:not(:first-child):not(:last-child) {
    border-top: none;
  }

  > div:last-child {
    border-bottom: none;

    .flex-row {
      border-bottom: none;
    }
  }

  > div.flex-row:last-child {
    > div.flex-col:first-child {
      .flex-row {
        input {
          border-bottom-left-radius: 5px;
        }
      }
    }
  }
`

export const TextInputGroup = (props: V2TextInputGroupProps) => {
  return (
    <Column gap="small" {...{ disabled: props.disabled }} wGrow>
      {props.label && props.label.length > 0 ? (
        <TextInputLabel>{props.label}</TextInputLabel>
      ) : (
        ''
      )}
      <TextInputGroupContainer grow wGrow>
        <TextInputStyled wGrow>{props.children}</TextInputStyled>
      </TextInputGroupContainer>
    </Column>
  )
}

export default TextInputGroup
