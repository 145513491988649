import { ValidatingInputProps } from 'ui'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  InputLeftAddon,
  Flex,
  Center,
  Text,
  Icon,
  FormHelperText,
  Spinner,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BuildingLibraryIcon, NoSymbolIcon } from '@heroicons/react/24/outline'
export type BankRoutingNumberFieldProps = {
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  id?: string
  placeholder?: string
  bankName: string | null
  loading: boolean
  smallLabel?: boolean
  previewMode?: boolean
} & ValidatingInputProps &
  InputProps

export type RoutingRightElementProps = {
  bankName?: string
  error?: string
  validated?: boolean
  isInvalid?: boolean
  isLoading?: boolean
  smallLabel?: boolean
  hasBlurred?: boolean
  loading?: boolean
} & InputProps

export function TriangleWarningIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4845 3.49512C9.15808 2.32845 10.842 2.32845 11.5156 3.49512L17.7943 14.3701C18.4678 15.5368 17.6259 16.9951 16.2787 16.9951H3.72136C2.37421 16.9951 1.53224 15.5368 2.20582 14.3701L8.4845 3.49512Z"
        fill="#FED7AA"
      />
      <path
        d="M10 7C10.4142 7 10.75 7.33579 10.75 7.75V11.25C10.75 11.6642 10.4142 12 10 12C9.58579 12 9.25 11.6642 9.25 11.25L9.25 7.75C9.25 7.33579 9.58579 7 10 7Z"
        fill="#EA580C"
      />
      <path
        d="M10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15Z"
        fill="#EA580C"
      />
    </svg>
  )
}

export function LoadingSpinner() {
  return <Spinner size="xs" />
}

export const RoutingRightElement = ({
  error,
  validated,
  isInvalid,
  bankName,
  hasBlurred,
  loading,
  smallLabel,
  ...other
}: RoutingRightElementProps) => {
  const isError =
    error &&
    (other.value || '').toString().length > 0 &&
    hasBlurred &&
    isInvalid &&
    validated

  const success =
    !error &&
    validated &&
    !isInvalid &&
    !!bankName &&
    (other.value || '').toString().length > 0

  const warning =
    !error &&
    validated &&
    !isInvalid &&
    !bankName &&
    !loading &&
    (other.value || '').toString().length > 0

  return (
    <>
      <InputRightElement justifyContent="right">
        <Flex
          flexDirection="row"
          alignItems="right"
          justifyContent="right"
          pe="2"
        >
          <Center>
            <Icon
              as={
                success
                  ? BuildingLibraryIcon
                  : loading
                  ? LoadingSpinner
                  : warning
                  ? TriangleWarningIcon
                  : isError
                  ? NoSymbolIcon
                  : BuildingLibraryIcon
              }
              color={warning ? 'orange.200' : isError ? 'red.600' : 'gray.500'}
              pe={warning ? '2' : '1'}
              boxSize={isError ? '5' : '6'}
              strokeWidth={isError ? '2' : '1.5'}
            />
            <Text
              whiteSpace={'nowrap'}
              fontSize={smallLabel ? '8px' : 'xs'}
              maxW="200px"
              textOverflow="ellipsis"
              overflow={'hidden'}
              color={
                success
                  ? 'green.600'
                  : isError
                  ? 'red.600'
                  : warning
                  ? 'yellow.600'
                  : 'red.600'
              }
            >
              {!!bankName ? bankName : ''}
            </Text>
          </Center>
        </Flex>
      </InputRightElement>
    </>
  )
}

export function BankRoutingNumberField({
  isInvalid,
  label,
  id,
  error,
  validated,
  isRequired,
  popover,
  smallLabel,
  leftAddon,
  bankName,
  value,
  loading,
  previewMode,
  ...other
}: BankRoutingNumberFieldProps) {
  const [hasBlurred, setHasBlurred] = useState(false)

  return (
    <FormControl
      isInvalid={isInvalid && validated && hasBlurred}
      id={id}
      isRequired={isRequired}
    >
      {!!label ? (
        <FormLabel verticalAlign={'center'}>
          <Flex flexDirection="row">
            {label}
            <Center ps="2">{popover}</Center>
          </Flex>
        </FormLabel>
      ) : (
        ''
      )}

      <InputGroup size="md" bgColor="white">
        {leftAddon ? (
          <InputLeftAddon pointerEvents="none" className="text-gray-500">
            {leftAddon}
          </InputLeftAddon>
        ) : (
          ''
        )}
        <Input
          {...other}
          color={isInvalid && hasBlurred ? 'red.600' : ''}
          value={value}
          style={{
            pointerEvents: previewMode ? 'none' : 'auto',
            opacity: previewMode ? 0.5 : 1,
          }}
          type="tel"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length === 0) {
              setHasBlurred(false)
            }
            other.onChange(e)
          }}
          onBlur={() => {
            setHasBlurred(true)
          }}
        />

        <RoutingRightElement
          bankName={bankName ?? undefined}
          isInvalid={isInvalid}
          validated={validated}
          error={error}
          value={value}
          hasBlurred={hasBlurred}
          loading={loading}
          smallLabel={smallLabel}
        />
      </InputGroup>
      {error && !loading ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
      {!error &&
        validated &&
        !isInvalid &&
        !bankName &&
        !loading &&
        (value || '').toString().length > 0 && (
          <FormHelperText color="yellow.600" mt="6px">
            Unable to find bank, please confirm that this routing number is
            correct.
          </FormHelperText>
        )}
    </FormControl>
  )
}

export default BankRoutingNumberField
