import { styles, tw } from '@nickeltech/brise'
import Row, { RowProps } from './Row'

export type V2DividerFunction = 'section' | 'subsection'
export type V2DividerVariant = 'light' | 'dark'

export const V2DividerFunctionArray: Array<V2DividerFunction> = [
  'section',
  'subsection',
]

export const V2DividerVariantArray: Array<V2DividerVariant> = ['light', 'dark']

export type V2DividerProps = {
  function: V2DividerFunction
  variant: V2DividerVariant
} & RowProps

export const V2DividerStyle = styles<V2DividerProps>`
    h-[1px]

    ${(props) => (props.function === 'section' ? '' : '')}
    ${(props) =>
      props.variant === 'dark'
        ? 'bg-dark-600'
        : props.function === 'section'
        ? 'bg-gray-300'
        : 'bg-gray-200'}

`

export const V2Divider = tw((props: V2DividerProps) => <Row grow {...props} />)`
    ${V2DividerStyle}
`

export default V2Divider
