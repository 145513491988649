import { property, styles, tw } from '@nickeltech/brise'
import { motion, MotionProps } from 'framer-motion'
import { Ref } from 'react'

import ContainerProps from '../../../types'
import { Gap, GapProps, SpacingProps, SpacingX, SpacingY } from './Spacing'

export type ColumnVisibilityTransitionProps = {
  visible?: boolean
}

export const VisibilityTransition = styles<ColumnVisibilityTransitionProps>`
  ${({ visible }) => {
    if (visible != undefined) {
      return visible
        ? 'opacity-100 w-inherit transition-all'
        : 'opacity-0 w-0 transition-all'
    }
    return ''
  }}
`

export type ColumnDisabledTransitionProps = {
  disabled?: boolean
}

export const DisabledTransition = styles<ColumnDisabledTransitionProps>`
  ${({ disabled }) => {
    if (disabled != undefined) {
      return disabled
        ? '!opacity-50 w-inherit cursor-default pointer-events-none transition-all'
        : 'opacity-100 w-inherit transition-all'
    }
    return ''
  }}
`

export type ColumnXType = 'left' | 'center' | 'right'
export type ColumnYType = 'top' | 'center' | 'bottom'

export const ColumnXPosition: Array<ColumnXType> = ['left', 'center', 'right']
export const ColumnYPosition: Array<ColumnYType> = ['top', 'center', 'bottom']

export type ColumnProps = {
  x?: ColumnXType
  y?: ColumnYType
  between?: boolean
  around?: boolean
  grow?: boolean
  wGrow?: boolean
  forwadedRef?: Ref<HTMLDivElement>
} & ContainerProps &
  SpacingProps &
  GapProps &
  MotionProps &
  ColumnDisabledTransitionProps &
  ColumnVisibilityTransitionProps

export const ColumnXPositionStyle = property(
  'x',
  {
    left: 'items-start',
    center: 'items-center',
    right: 'items-end',
    default: '',
  },
  'default',
)

export const ColumnYPositionStyle = property(
  'y',
  {
    top: 'justify-start',
    center: 'justify-center',
    bottom: 'justify-end',
    default: '',
  },
  'default',
)

export const ColumnAdditionalStyles = styles`
  ${(props: ColumnProps) => (props.between ? 'justify-between' : '')}
  ${(props: ColumnProps) => (props.around ? 'justify-around' : '')}
  ${(props: ColumnProps) => (props.onClick ? 'cursor-pointer' : '')}
  ${(props: ColumnProps) => (props.wGrow ? 'w-full' : 'w-fit')}
  ${(props: ColumnProps) => (props.grow ? 'grow h-full' : '')}
`

export const Column = tw(
  ({
    className,
    style,
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    animate,
    initial,
    forwadedRef,
    onAnimationComplete,
  }: ColumnProps) => {
    return (
      <motion.div
        {...{
          style,
          onClick,
          onMouseEnter,
          onMouseLeave,
          animate: animate,
          initial: initial || false,
          ref: forwadedRef,
          onAnimationComplete,
          className,
        }}
      >
        {children}
      </motion.div>
    )
  },
)`
  flex
  flex-col
  ${ColumnXPositionStyle}
  ${ColumnYPositionStyle}
  ${ColumnAdditionalStyles}
  ${Gap}
  ${SpacingX}
  ${SpacingY}
  ${DisabledTransition}
  ${VisibilityTransition}
`

export default Column
