import { tw } from '@nickeltech/brise'
import { NickelFillProps } from '../../types'
import { IconCombinedProps } from '.'

export type NickelProps = IconCombinedProps & NickelFillProps

export const Nickel = tw((props: NickelProps) => {
  const { hoverable, ...rest } = props

  return (
    <svg
      width="80"
      height="28"
      viewBox="0 0 80 28"
      xmlns="http://www.w3.org/2000/svg"
      {...{ ...rest, fill: 'none' }}
    >
      <path
        d="M28.4619 8.55099C29.6602 8.55099 30.6465 8.95284 31.421 9.75654C32.2101 10.5602 32.6046 11.6708 32.6046 13.0882V19.8174H29.7771V13.4389C29.7771 12.7083 29.5798 12.153 29.1852 11.7731C28.7907 11.3785 28.2646 11.1813 27.6071 11.1813C26.8764 11.1813 26.2919 11.4078 25.8535 11.8608C25.4152 12.3138 25.196 12.9933 25.196 13.8992V19.8174H22.3684V8.85786H25.196V10.0853C25.8828 9.06243 26.9714 8.55099 28.4619 8.55099Z"
        fill={rest.fill}
      />
      <path
        d="M36.1926 7.54271C35.725 7.54271 35.3159 7.37467 34.9652 7.03857C34.6291 6.68787 34.461 6.27871 34.461 5.81111C34.461 5.3435 34.6291 4.93434 34.9652 4.58364C35.3159 4.23293 35.725 4.05758 36.1926 4.05758C36.6748 4.05758 37.084 4.23293 37.4201 4.58364C37.7708 4.93434 37.9462 5.3435 37.9462 5.81111C37.9462 6.27871 37.7708 6.68787 37.4201 7.03857C37.084 7.37467 36.6748 7.54271 36.1926 7.54271ZM34.7898 19.8174V8.85786H37.6174V19.8174H34.7898Z"
        fill={rest.fill}
      />
      <path
        d="M45.2922 19.7939C43.641 19.7939 42.2601 19.2386 41.1495 18.1281C40.0535 17.0175 39.5056 15.6439 39.5056 14.0073C39.5056 12.3707 40.0535 10.9971 41.1495 9.88651C42.2601 8.77595 43.641 8.22067 45.2922 8.22067C46.3589 8.22067 47.3307 8.47639 48.2074 8.98783C49.0842 9.49928 49.7491 10.1861 50.2021 11.0482L47.769 12.473C47.5498 12.02 47.2138 11.662 46.7608 11.3989C46.3224 11.1359 45.8255 11.0044 45.2703 11.0044C44.4227 11.0044 43.7213 11.2893 43.166 11.8592C42.6108 12.4145 42.3331 13.1305 42.3331 14.0073C42.3331 14.8694 42.6108 15.5855 43.166 16.1554C43.7213 16.7106 44.4227 16.9883 45.2703 16.9883C45.8402 16.9883 46.3443 16.8641 46.7827 16.6157C47.2357 16.3526 47.5718 15.9946 47.791 15.5416L50.2459 16.9445C49.7637 17.8066 49.0842 18.5007 48.2074 19.0268C47.3307 19.5382 46.3589 19.7939 45.2922 19.7939Z"
        fill={rest.fill}
      />
      <path
        d="M61.6767 19.8174H58.3889L54.3996 14.8418V19.8174H51.572V4.47404H54.3996V13.68L58.1697 8.85786H61.5452L57.1395 14.2719L61.6767 19.8174Z"
        fill={rest.fill}
      />
      <path
        d="M65.26 15.169C65.64 16.5426 66.6702 17.2294 68.3506 17.2294C69.432 17.2294 70.2503 16.8641 70.8056 16.1334L73.0851 17.4486C72.0038 19.0121 70.411 19.7939 68.3068 19.7939C66.4948 19.7939 65.0408 19.246 63.9449 18.15C62.8489 17.054 62.301 15.6731 62.301 14.0073C62.301 12.3561 62.8416 10.9825 63.923 9.88651C65.0043 8.77595 66.3925 8.22067 68.0876 8.22067C69.695 8.22067 71.0174 8.77595 72.0549 9.88651C73.1071 10.9971 73.6331 12.3707 73.6331 14.0073C73.6331 14.3726 73.5966 14.7599 73.5235 15.169H65.26ZM65.2162 12.9771H70.8056C70.6448 12.2319 70.3087 11.6766 69.7973 11.3113C69.3005 10.9459 68.7306 10.7633 68.0876 10.7633C67.3277 10.7633 66.6994 10.9605 66.2026 11.3551C65.7057 11.735 65.3769 12.2757 65.2162 12.9771Z"
        fill={rest.fill}
      />
      <path
        d="M75.3587 19.8174V3.81647H78.1862V19.8174H75.3587Z"
        fill={rest.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78936 5.7831C8.15236 5.51924 7.43664 5.51924 6.79964 5.78309L3.12636 7.30462C2.48936 7.56847 1.98327 8.07456 1.71941 8.71156L0.19789 12.3848C-0.0659631 13.0218 -0.0659634 13.7376 0.19789 14.3746L1.71941 18.0478C1.98327 18.6848 2.48936 19.1909 3.12635 19.4548L6.79964 20.9763C7.43664 21.2402 8.15236 21.2402 8.78936 20.9763L12.4626 19.4548C13.0996 19.1909 13.6057 18.6848 13.8696 18.0478L15.3911 14.3746C15.655 13.7376 15.655 13.0218 15.3911 12.3848L13.8696 8.71156C13.6057 8.07456 13.0996 7.56847 12.4626 7.30462L8.78936 5.7831ZM11.2548 11.3379C11.6424 10.4951 10.7721 9.62506 9.92916 10.0126C8.60405 10.6218 7.07887 10.6218 5.75376 10.0126C4.9108 9.62506 4.04056 10.4951 4.42816 11.3379C5.03748 12.6628 5.03748 14.1877 4.42816 15.5125C4.04056 16.3553 4.9108 17.2254 5.75376 16.8379C7.07887 16.2287 8.60405 16.2287 9.92916 16.8379C10.7721 17.2254 11.6424 16.3553 11.2548 15.5125C10.6454 14.1877 10.6454 12.6628 11.2548 11.3379Z"
        fill={rest.fill}
      />
      <rect
        x="51.5566"
        y="4.58362"
        width="2.79554"
        height="0.46251"
        fill={rest.fill}
      />
    </svg>
  )
})``

export default Nickel
