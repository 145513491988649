import { create } from 'zustand'
export type AccountantRegistrationStep = 'owner' | 'business' | 'newClient'

type Owner = {
  email: string
  firstName: string
  lastName: string
  password: string
}

type AccountantRegistrationFlowState = {
  step: AccountantRegistrationStep
  setOwner: (owner: Owner) => void
  setStep: (step: AccountantRegistrationStep) => void
  owner?: Owner
}

const initialState: Pick<AccountantRegistrationFlowState, 'step'> = {
  step: 'owner',
}

export const useAccountantRegistrationFlow =
  create<AccountantRegistrationFlowState>((set) => ({
    ...initialState,
    setStep: (step: AccountantRegistrationStep) => set({ step }),
    setOwner: (owner: Owner) => set({ owner }),
  }))
