import { UsaStates } from 'usa-states'
import * as yup from 'yup'

export const US_STATES = new UsaStates()

export const PaymentMethodOptions = {
  ACH_DEBIT: 'achDebit',
  CREDIT_CARD: 'creditCard',
  SAVED_PAYMENT_METHOD: 'savedPaymentMethod',
} as const

export type PaymentMethodOption =
  (typeof PaymentMethodOptions)[keyof typeof PaymentMethodOptions]

export const PaymentFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email cannot be blank')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Invalid email format'),
  paymentMethod: yup.mixed<PaymentMethodOption>().required(),
  paymentMethodDetails: yup
    .object()
    .when('paymentMethod', {
      is: PaymentMethodOptions.CREDIT_CARD,
      then: yup
        .object({
          creditCardNumber: yup
            .boolean()
            .oneOf([true], 'Must be valid CC number')
            .required(),
          creditCardExpirationDate: yup
            .boolean()
            .oneOf([true], 'Must be valid EXP date')
            .required(),
          creditCardCVV: yup
            .boolean()
            .oneOf([true], 'Must be valid CVV number')
            .required(),
        })
        .required(),
      otherwise: undefined,
    })
    .when('paymentMethod', {
      is: PaymentMethodOptions.ACH_DEBIT,
      then: yup
        .object({
          bankAccountNumber: yup.number().required(),
          bankRoutingNumber: yup.number().required(),
          confirmedBankAccountNumber: yup
            .number()
            .oneOf(
              [yup.ref('bankAccountNumber'), null],
              'Does not match with field!',
            )
            .required('Required'),
          accountType: yup.string().oneOf(['checking', 'savings']).required(),
        })
        .required(),
      otherwise: undefined,
    }),
  billingAddress: yup.object({
    fullName: yup.string().required(),
    company: yup.string(),
    zipCode: yup
      .string()
      .required()
      .length(5)
      .matches(/^[0-9]{5}/)
      .required(),
    state: yup
      .string()
      .required()
      .oneOf(US_STATES.states.map((x) => x.name)),
    streetAddress: yup.string().required(),
    city: yup.string().required(),
  }),
})

export const MerchantPaymentFormSchema = yup.object().shape({
  paymentMethod: yup.mixed<PaymentMethodOption>().required(),
  paymentMethodDetails: yup
    .object()
    .when('paymentMethod', {
      is: PaymentMethodOptions.CREDIT_CARD,
      then: yup
        .object({
          creditCardNumber: yup
            .boolean()
            .oneOf([true], 'Must be valid CC number')
            .required(),
          creditCardExpirationDate: yup
            .boolean()
            .oneOf([true], 'Must be valid EXP date')
            .required(),
          creditCardCVV: yup
            .boolean()
            .oneOf([true], 'Must be valid CVV number')
            .required(),
        })
        .required(),
      otherwise: undefined,
    })
    .when('paymentMethod', {
      is: PaymentMethodOptions.ACH_DEBIT,
      then: yup
        .object({
          bankAccountNumber: yup.number().required(),
          bankRoutingNumber: yup.number().required(),
          confirmedBankAccountNumber: yup
            .number()
            .oneOf(
              [yup.ref('bankAccountNumber'), null],
              'Does not match with field!',
            )
            .required('Required'),
          accountType: yup.string().oneOf(['checking', 'savings']).required(),
        })
        .required(),
      otherwise: undefined,
    }),
  billingAddress: yup.object().when('paymentMethod', {
    is: PaymentMethodOptions.CREDIT_CARD,
    then: yup.object({
      fullName: yup.string().required(),
      company: yup.string(),
      zipCode: yup
        .string()
        .required()
        .length(5)
        .matches(/^[0-9]{5}/)
        .required(),
      state: yup
        .string()
        .required()
        .oneOf(US_STATES.states.map((x) => x.name)),
      streetAddress: yup.string().required(),
      city: yup.string().required(),
    }),
    otherwise: undefined,
  }),
})

export type MerchantFormData = yup.InferType<typeof MerchantPaymentFormSchema>
export type PaymentFormData = yup.InferType<typeof PaymentFormSchema>

export type CreditCardType =
  | 'mastercard'
  | 'visa'
  | 'visa_debit'
  | 'amex'
  | 'diners'
  | 'maestro'
  | 'visa_electron'
  | 'unknown'
  | 'discover'
  | 'american express'
export type CreditCardIconType =
  | 'masterCard'
  | 'visa'
  | 'visaDebit'
  | 'amex'
  | 'diners'
  | 'maestro'
  | 'visaElectron'
  | 'unknownCard'
  | 'discover'

export const CreditCardMap: Record<CreditCardType, CreditCardIconType> = {
  mastercard: 'masterCard',
  visa: 'visa',
  visa_debit: 'visaDebit',
  amex: 'amex',
  diners: 'diners',
  maestro: 'maestro',
  visa_electron: 'visaElectron',
  unknown: 'unknownCard',
  discover: 'discover',
  'american express': 'amex',
}
