import { useMutation } from '@apollo/client'
import { Box, Divider, Flex, HStack, Text } from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import {
  CardInput,
  DatePickerCardInput,
  HFlex,
  Icon,
  Overlay,
  PriceSelector,
  VFlex,
  Button,
} from 'ui'
import {
  AddBillDocument,
  AddVendorDocument,
  UserRole,
  Vendor,
} from '../../operations-types'
import { VendorPayoutDetails } from './components/VendorPayoutDetails'
import { SlimSelectVendor } from './VendorDetails'
import * as yup from 'yup'
import currency from 'currency.js'
import { useNavigate } from 'react-router-dom'
import { useLoggedInStore } from '../layout/LoggedInStore'

export type NewBillOverlayProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  vendor?: Vendor | null
}
type OverlayHeaderProps = {
  onClose: () => void
}

function OverlayHeader({ onClose }: OverlayHeaderProps) {
  return (
    <Box width="100%" px={8} py={6}>
      <HFlex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="2xl" fontWeight="semibold" color="gray.800">
          New Bill
        </Text>
        <Icon
          name="xMark"
          size="medium"
          hoverable
          className="stroke-gray-500"
          onClick={onClose}
        />
      </HFlex>
      <Text fontSize="sm" color="gray.500">
        Create a new bill to track and pay in Nickel
      </Text>
    </Box>
  )
}

type VendorSelectProps = {
  onVendor: (vendor: Vendor | null) => void
}

function VendorSelect({ onVendor }: VendorSelectProps) {
  const [addVendor] = useMutation(AddVendorDocument, {
    onCompleted: (data) => {
      if (data.addVendor?.vendor) {
        onVendor(data.addVendor?.vendor)
      }
    },
  })

  return (
    <Box px={8} py={6} w="100%">
      <VFlex gap={4}>
        <Box>
          <Text fontSize="lg" fontWeight="semibold" color="gray.800">
            Who are you paying?
          </Text>
          <Text fontSize="sm" color="gray.500">
            Select your vendor
          </Text>
        </Box>
        <SlimSelectVendor
          onNewVendor={(newVendor) => {
            addVendor({
              variables: { name: newVendor },
            })
          }}
          onVendorSelect={(vendor) => onVendor(vendor)}
          label={false}
        />
      </VFlex>
    </Box>
  )
}

function SelectedVendor({
  selectedVendor,
  setSelectedVendor,
  onVendor,
}: {
  selectedVendor: Vendor
  setSelectedVendor: (vendor: Vendor | null) => void
  onVendor: (vendor: Vendor | null) => void
}) {
  return (
    <Box px={8} py={6} w="100%">
      <Box>
        <Text fontSize="lg" fontWeight="semibold" color="gray.800">
          Who are you paying?
        </Text>
        <Text fontSize="sm" color="gray.500">
          Select your vendor
        </Text>
      </Box>
      <VendorPayoutDetails
        label={false}
        vendorId={selectedVendor.id}
        vendorName={selectedVendor?.name || ''}
        vendorAchPayoutMethod={
          (selectedVendor?.vendorPayoutMethods || []).find(
            (x) => x?.type === 'ACH',
          ) ?? undefined
        }
        vendorCheckPayoutMethod={
          (selectedVendor?.vendorPayoutMethods || []).find(
            (x) => x?.type === 'CHECK',
          ) ?? undefined
        }
        setSelectedVendor={setSelectedVendor}
        onVendor={onVendor}
      />
    </Box>
  )
}

function BillInformation() {
  const [amount, setAmount] = useState('')

  const { setFieldValue, values, handleChange } =
    useFormikContext<BillFormData>()

  return (
    <VFlex px={8} pb={6} w="100%" gap={4}>
      <Box>
        <Text fontSize="lg" fontWeight="semibold" color="gray.800">
          Bill Information
        </Text>
        <Text fontSize="sm" color="gray.500">
          Enter your bill details
        </Text>
      </Box>
      <VFlex gap={4}>
        <PriceSelector
          name="amount"
          value={amount}
          onChange={(e) => {
            setAmount(e)
            setFieldValue('amountCents', currency(e).intValue)
          }}
          bgColor="white"
          labelColor="gray.800"
          placeholder="$0"
          fontSize="xl"
        />
        <HStack>
          <CardInput
            label="Reason for Payment"
            name="reason"
            value={values.reason}
            onChange={handleChange}
            inputGroupSize="sm"
            fontSize="md"
            bgColor="white"
            labelColor="gray.800"
            placeholder="Deposit or invoice number"
          />
          <DatePickerCardInput
            label="Due Date"
            className="text-sm"
            selected={values.dueDate}
            onChange={(date) => setFieldValue('dueDate', date)}
          />
        </HStack>
      </VFlex>
    </VFlex>
  )
}

const NewBillSchema = yup.object({
  amountCents: yup.number().min(1).required('Approval amount is required'),
  dueDate: yup.date().required('Due date is required'),
  vendorId: yup.string().required('Vendor is required'),
  reason: yup.string().required('Reason is required'),
})

type BillFormData = yup.InferType<typeof NewBillSchema>

export function NewBillOverlay({
  isOpen,
  setOpen,
  vendor,
}: NewBillOverlayProps) {
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(
    vendor || null,
  )
  const [addBill] = useMutation(AddBillDocument, {
    refetchQueries: ['bills'],
  })
  const navigate = useNavigate()

  const { getUser } = useLoggedInStore()
  const IS_PERMITTED =
    getUser().isUserPermitted(UserRole.Manager) ||
    !!getUser()?.organization?.approvalPolicies?.length

  useEffect(() => {
    if (vendor) {
      setSelectedVendor(vendor)
    }
  }, [vendor])

  return (
    <Overlay
      open={isOpen}
      setOpen={(boolean) => {
        setSelectedVendor(null)
        setOpen(boolean)
      }}
    >
      <OverlayHeader
        onClose={() => {
          setOpen(false)
          setSelectedVendor(null)
        }}
      />
      <Divider />
      <Formik<BillFormData>
        initialValues={{
          amountCents: 0,
          dueDate: new Date(),
          vendorId: vendor?.id || '',
          reason: '',
        }}
        validateOnMount={true}
        validationSchema={NewBillSchema}
        onSubmit={(values) => {}}
      >
        {(props) => {
          return (
            <>
              {!selectedVendor && (
                <VendorSelect
                  onVendor={(vendor) => {
                    if (vendor) {
                      setSelectedVendor(vendor)
                      props.setFieldValue('vendorId', vendor.id)
                    }
                  }}
                />
              )}
              {selectedVendor && (
                <SelectedVendor
                  selectedVendor={selectedVendor}
                  setSelectedVendor={setSelectedVendor}
                  onVendor={(vendor) => {
                    setSelectedVendor(vendor)
                    props.setFieldValue('vendorId', vendor?.id || '')
                  }}
                />
              )}
              {selectedVendor && <BillInformation />}
              <Box w="100%" mt="auto">
                <Divider />
                <Flex justifyContent="flex-end" py={4} px={8} gap={4}>
                  <Button
                    label="Save and Close"
                    variant="outline"
                    isDisabled={!props.isValid}
                    onClick={async () => {
                      await addBill({
                        variables: {
                          amountCents: props.values.amountCents,
                          dueDateEpochMillis: props.values.dueDate.valueOf(),
                          vendorId: props.values.vendorId,
                          reason: props.values.reason,
                        },
                      })
                      setOpen(false)
                      setSelectedVendor(null)
                    }}
                  />
                  {IS_PERMITTED && (
                    <Button
                      label="Make a payment"
                      isDisabled={!props.isValid}
                      onClick={async () => {
                        const { data } = await addBill({
                          variables: {
                            amountCents: props.values.amountCents,
                            dueDateEpochMillis: props.values.dueDate.valueOf(),
                            vendorId: props.values.vendorId,
                            reason: props.values.reason,
                          },
                        })
                        setOpen(false)
                        setSelectedVendor(null)
                        navigate(`/send-money/bill/${data?.addBill?.bill?.id}`)
                      }}
                    />
                  )}
                </Flex>
              </Box>
            </>
          )
        }}
      </Formik>
    </Overlay>
  )
}
