import { Switch } from '@chakra-ui/react'
import { Entity, EntityField } from 'ui'
import { NotificationFooter } from './NotificationFooter'

type NotificationEntityProps = {
  title: string
  description: string
  onAddClick: () => void
  onEmailClick: (email: string) => void
  onSwitchClick: () => void
  disabled: boolean
  emails: string[]
}

export function NotificationEntity({
  onAddClick,
  onEmailClick,
  emails,
  title,
  description,
  onSwitchClick,
  disabled,
}: NotificationEntityProps) {
  const action = (
    <Switch size="lg" onChange={onSwitchClick} isChecked={!disabled} />
  )

  return (
    <Entity
      disabled={disabled}
      actions={action}
      footer={
        <NotificationFooter
          onAddEmail={onAddClick}
          onRemoveEmail={onEmailClick}
          emails={emails}
        />
      }
    >
      <EntityField title={title} description={description} />
    </Entity>
  )
}
