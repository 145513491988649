import { Flex } from '@chakra-ui/react'
import { Button } from 'ui'

export const PaymentMethodOptions = {
  ACH_DEBIT: 'achDebit',
  CREDIT_CARD: 'creditCard',
  SAVED_PAYMENT_METHOD: 'savedPaymentMethod',
} as const

type PaymentMethodButtonProps = {
  onCancelClick: () => void
  isSaveDisabled: boolean
  onSaveClick: () => void
  isLoading: boolean
}
export function PaymentMethodButtons({
  onCancelClick,
  isSaveDisabled,
  onSaveClick,
  isLoading,
}: PaymentMethodButtonProps) {
  return (
    <Flex gap="2" flexDirection="row" justifyContent="end" pt="6">
      <Button label="Cancel" variant="outline" onClick={onCancelClick} />
      <Button
        label="Next"
        isDisabled={isSaveDisabled}
        onClick={onSaveClick}
        isLoading={isLoading}
      />
    </Flex>
  )
}
