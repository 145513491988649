import { Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Column, Icon, Row } from 'ui'

type PaymentDetailsProps = {
  billPaymentAmount: string
  headerLabel: string
  linkText?: string
  headerValue?: string
  vendorName?: string
  onLinkClick?: () => void
  paymentMethod: ReactNode
  deliveredTo?: string
  email?: string
}

const BillPaymentDetails = (_props: PaymentDetailsProps) => {
  return (
    <Column gap="medium" wGrow>
      <Row grow between rounded y="center">
        <Text fontWeight={'semibold'} color={'dark.800'} fontSize="xl">
          {_props.headerLabel}
        </Text>
        {_props.headerValue && (
          <Text color={'gray.500'}>{_props.headerValue}</Text>
        )}
        {_props.linkText && _props.onLinkClick && (
          <Row gap="small" y="center">
            <Text
              color={'purple.500'}
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              fontSize={'sm'}
              onClick={_props.onLinkClick}
            >
              {_props.linkText}
            </Text>
            <Icon
              name="arrowTopRightOnSquare"
              className="stroke-purple-600"
              onClick={_props.onLinkClick}
            />
          </Row>
        )}
      </Row>
      <Column
        className="border rounded border-gray-300 py-4 px-3 bg-gray-100"
        gap="medium"
        wGrow
      >
        <Row grow between y="center">
          <Text className="text-sm text-gray-800">Recipient Name</Text>
          <Text className="text-sm text-gray-800">{_props.vendorName}</Text>
        </Row>
        <Row grow between y="center">
          <Text className="text-sm text-gray-800">Receiving Payment Via</Text>
          {_props.paymentMethod}
        </Row>
        <Row grow between y="top">
          <Text className="text-sm text-gray-800">Delivered To</Text>
          <Text className="text-sm text-gray-800">{_props.deliveredTo}</Text>
        </Row>
        <Row grow between y="top">
          <Text className="text-sm text-gray-800">Amount</Text>
          <Text className="text-sm text-gray-800">
            {_props.billPaymentAmount}
          </Text>
        </Row>
        <Row grow between y="top">
          <Text className="text-sm text-gray-800">Email</Text>
          <Text className="text-sm text-gray-800">{_props.email}</Text>
        </Row>
      </Column>
    </Column>
  )
}

export default BillPaymentDetails
