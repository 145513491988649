import { Button, Card, Flex, useToast } from '@chakra-ui/react'
import { Form, FormikValidatingInput, ValidatingInput } from 'ui'
import { BlankLayout } from '../layout/Layout'
import * as yup from 'yup'
import jwt_decode from 'jwt-decode'
import { useNavigate, useParams } from 'react-router-dom'
import { NewPasswordDocument } from '../../operations-types'
import { useMutation } from '@apollo/client'

const OwnerInviteSchema = yup
  .object({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be 8 characters minimum'),
    confirmPassword: yup
      .string()
      .required('Please confirm your password')
      .min(8, 'Password must be 8 characters minimum')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required()

export function OwnerInvite() {
  const { token = '' } = useParams()
  const toast = useToast()
  const nagivate = useNavigate()
  const { email, expiry }: { email: string; expiry: number } = jwt_decode(token)
  const [newPassword, { loading }] = useMutation(NewPasswordDocument, {
    onCompleted: () => {
      toast({
        title: 'Password updated',
        description: 'Login with your new password',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      nagivate('/login')
    },
  })

  if (Date.now() > expiry) {
    return (
      <BlankLayout>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Link Expired
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            The link you clicked has expired. Please request a new link to reset
            your password.
          </p>
        </div>
      </BlankLayout>
    )
  }

  return (
    <BlankLayout>
      <Form
        validationSchema={OwnerInviteSchema}
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
      >
        {(formik) => {
          const onClick = () => {
            formik.setFieldTouched('password')
            formik.setFieldTouched('confirmPassword')
            formik.validateForm()

            if (formik.isValid) {
              newPassword({
                variables: {
                  token,
                  newPassword: formik.values.password,
                },
              })
            }
          }

          return (
            <Flex w="640px" flexDirection="column" gap={8}>
              <div className="mt-20">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Registration
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Let&apos;s get your login information so that you can join
                  your company on Nickel
                </p>
              </div>
              <Card py={8} px={8}>
                <Flex flexDirection="column" gap={6}>
                  <ValidatingInput
                    isInvalid={false}
                    label="Email"
                    isDisabled
                    value={email}
                    fontSize="sm"
                  />
                  <FormikValidatingInput
                    label="Password"
                    type="password"
                    fieldName="password"
                  />
                  <FormikValidatingInput
                    label="Confirm Password"
                    type="password"
                    fieldName="confirmPassword"
                  />
                </Flex>
              </Card>
              <Button onClick={onClick} isLoading={loading}>
                Register
              </Button>
            </Flex>
          )
        }}
      </Form>
    </BlankLayout>
  )
}
