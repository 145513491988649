import { tw } from '@nickeltech/brise'
import React from 'react'
import ContainerProps from '../../types'

type TableSearchInputProps = {} & ContainerProps &
  React.InputHTMLAttributes<HTMLInputElement>

const TableSearchInput = tw((props: TableSearchInputProps) => {
  return <input {...props} />
})`
  placeholder:text-sm
  bg-gray-100
  rounded
  focus:outline-1
  focus:outline-gray-200
  focus:ring-0
  focus:border-none
  !pl-[10px]
  py-1
  text-sm
`

export default TableSearchInput
