import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'
import styled from 'styled-components'

export const FullPage = tw.div<ContainerProps>`
  h-screen
  w-screen
`

export const StorybookPage = tw(FullPage)`
  w-[calc(100%-2rem)]
  h-[calc(100vh-2rem)]
`

export const CenterPage = tw.div<
  ContainerProps & {
    mobile?: boolean
  }
>`
  flex
  flex-col
  ${(props) => (props.mobile ? '' : 'justify-center')}
  items-center
  max-sm:max-w-[100%]
  max-sm:mx-auto
  h-full
  sm:h-screen
`

export const SnappedCenterPage = styled(CenterPage)``

export default FullPage
