import { useQuery } from '@apollo/client'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import currency from 'currency.js'
import { useParams } from 'react-router-dom'

import { PublicBillPaymentDocument } from '../operations-types'
import { TransactionDetails } from './ap/components/ReviewSendPayment'
import ReceiptCTA from './ReceiptCTA'

type Props = {}

const Receipt = (props: Props) => {
  const { id } = useParams()

  const { data, loading } = useQuery(PublicBillPaymentDocument, {
    variables: {
      billPaymentId: id || '',
    },
  })

  let sendMoneyAmountResult = {
    submittedAmountCents: data?.billPayment?.billPayment?.amountInCents || 0,
    reason: data?.billPayment?.billPayment?.billPayable.invoiceNumber || '',
  }

  const totalAmount = currency(
    sendMoneyAmountResult?.submittedAmountCents || 0,
    {
      fromCents: true,
    },
  ).format()

  return (
    <Flex
      flexDirection="column"
      gap={8}
      width={'100%'}
      alignItems="center"
      height={'100vh'}
      justifyContent="center"
      maxW={540}
      margin="auto"
      paddingX={8}
    >
      {loading ? (
        <Spinner size="xl" />
      ) : data?.billPayment?.billPayment ? (
        <>
          <Text
            fontWeight={'semibold'}
            fontSize={'xl'}
            textAlign="left"
            w={'100%'}
          >
            {data?.billPayment.billPayment.paymentMethod?.name} sent you{' '}
            {totalAmount}
          </Text>
          <TransactionDetails
            {...{
              recipientDetails: {
                vendorName:
                  data?.billPayment.billPayment.billPayable.vendor.name || '',
                paymentVia:
                  data?.billPayment?.billPayment?.vendorPayoutMethod?.type ===
                  'CHECK'
                    ? 'Check'
                    : 'ACH',
                achDetails: {
                  bankName:
                    data?.billPayment?.billPayment?.vendorPayoutMethod
                      ?.bankName,
                  accountNumber:
                    data?.billPayment?.billPayment?.vendorPayoutMethod
                      ?.accountNumber,
                  routingNumber:
                    data?.billPayment?.billPayment?.vendorPayoutMethod
                      ?.routingNumber,
                  confirmedAccountNumber:
                    data?.billPayment?.billPayment?.vendorPayoutMethod
                      ?.accountNumber,
                  recipientAddress: {
                    city:
                      data?.billPayment?.billPayment?.vendorPayoutMethod
                        ?.city || '',
                    state:
                      data?.billPayment?.billPayment?.vendorPayoutMethod
                        ?.state || '',
                    streetAddress:
                      data?.billPayment?.billPayment?.vendorPayoutMethod
                        ?.street +
                      ' ' +
                      data?.billPayment?.billPayment?.vendorPayoutMethod
                        ?.street2,
                    zipCode:
                      data?.billPayment?.billPayment?.vendorPayoutMethod?.zip ||
                      '',
                  },
                },
              },
              name: data?.billPayment.billPayment.paymentMethod?.name || '',
              selectedSavedPaymentMethod:
                data?.billPayment?.billPayment?.paymentMethod,
              sendMoneyAmountResult: {
                submittedAmountCents:
                  data?.billPayment?.billPayment?.amountInCents || 0,
                reason:
                  data?.billPayment?.billPayment?.billPayable.invoiceNumber ||
                  '',
              },
            }}
          />
          <ReceiptCTA />
        </>
      ) : (
        ''
      )}
    </Flex>
  )
}

export default Receipt
