import { tw } from '@nickeltech/brise'
import Row from '../Row'
import { CellContainer, SortOrder, TableCellProps } from './Cell'
import classNames from 'classnames'
import Icon from '../../Icons'
import Caption from '../../Typography/Caption'
import { Checkbox } from '@chakra-ui/react'

export type TableDataProps<T> = {
  headers: Array<TableCellProps<T>>
}

export type TableHeaderProps<T> = {
  sortBy?: string
  sortOrder?: SortOrder
  onSort?: (sortBy: string, sortOrder: SortOrder) => unknown
} & TableDataProps<T>

export const TableHeaderContainer = tw(Row)`
  border-b
  border-gray-200
  bg-gray-50
`

export const TableHeader = <T extends object>(props: TableHeaderProps<T>) => {
  return (
    <TableHeaderContainer grow className={`py-1`}>
      {props.headers.map((header, i) => (
        <CellContainer
          key={i}
          type={header.type}
          grow={header.grow}
          fit={header.fit}
        >
          <Row
            x={header.headerCenter ? 'center' : header.right ? 'right' : 'left'}
            className={header.right ? 'px-2' : 'px-2'}
            y="center"
            gap="small"
            grow
            onClick={
              header.sortable
                ? () => {
                    if (props.sortBy === header.keyName && props.onSort) {
                      if (props.sortOrder === 'asc') {
                        props.onSort(props.sortBy || '', 'desc')
                      } else {
                        props.onSort(props.sortBy || '', 'asc')
                      }
                    } else {
                      props.onSort && props.onSort(header.keyName || '', 'asc')
                    }
                  }
                : () => {}
            }
            style={header.style}
          >
            <Caption clamped variant="secondary" className="text-gray-500">
              {header.label}
            </Caption>
            {props.sortBy === header.keyName && header.sortable ? (
              <Icon
                variant="secondary"
                name="chevronUp"
                className={classNames({
                  'transition-transform': true,
                  hidden: props.onSort !== undefined,
                  'rotate-0': props.sortOrder === 'asc',
                  'rotate-180': props.sortOrder === 'desc',
                })}
              />
            ) : (
              ''
            )}
          </Row>
        </CellContainer>
      ))}
    </TableHeaderContainer>
  )
}

export const TableHeaderV2 = <T extends object>(props: TableHeaderProps<T>) => {
  return (
    <TableHeaderContainer grow className="py-0 !bg-white border-t">
      {props.headers.map((header, i) => (
        <CellContainer
          key={i}
          fit={header.fit}
          type={header.type}
          grow={header.grow}
          width={header.width}
          className={`relative ${
            !header.sortable && header.type !== 'multiSelect'
              ? 'pointer-events-none cursor-default'
              : ''
          }`}
        >
          <Row
            x={header.headerCenter ? 'center' : header.right ? 'right' : 'left'}
            className={`px-2 ${
              !header.sortable && header.type !== 'multiSelect'
                ? 'pointer-events-none !opacity-100 cursor-default'
                : ''
            }`}
            y="center"
            grow
            onClick={() => {
              if (props.sortBy === header.keyName && props.onSort) {
                if (props.sortOrder === 'asc') {
                  props.onSort(props.sortBy || '', 'desc')
                } else {
                  props.onSort(props.sortBy || '', 'asc')
                }
              } else {
                props.onSort && props.onSort(header.keyName || '', 'asc')
              }
            }}
            style={header.style}
          >
            <Caption
              clamped
              variant="secondary"
              className={`!text-[11px] relative !text-gray-500 ${
                !header.sortable
                  ? 'pointer-events-none !text-gray-500 cursor-default'
                  : ''
              }`}
            >
              {header.label}
            </Caption>
            {header.type === 'multiSelect' && (
              <Checkbox
                onChange={(e) => {
                  header.headerOnCheck && header.headerOnCheck(e.target.checked)
                  console.log(header, e.target.checked)
                }}
                colorScheme="gray"
                isChecked={header.headerSelected}
              />
            )}
            {props.sortBy === header.keyName && header.sortable ? (
              <Icon
                variant="secondary"
                name="arrowUp"
                className={classNames({
                  'transition-transform': true,
                  '!w-[10px] !h-[10px]': true,
                  hidden: props.onSort === undefined,
                  'rotate-0': props.sortOrder === 'asc',
                  'rotate-180': props.sortOrder === 'desc',
                })}
              />
            ) : (
              ''
            )}
          </Row>
        </CellContainer>
      ))}
    </TableHeaderContainer>
  )
}
