import { property } from '@nickeltech/brise'
import ContainerProps, { Size as TypeSize, TwoDimensionSize } from '../../types'

export type SpacingProps = {
  spacing?: TwoDimensionSize
} & ContainerProps

export type GapProps = {
  gap?: TypeSize
} & ContainerProps

export type SizeProps = {
  size?: TypeSize
} & ContainerProps

export type SpacingDimension = 'spacingX' | 'spacingY'

export const SizeMap: Record<TypeSize, string> = {
  header: 'px-6',
  tiny: 'w-[2px] h-[2px]',
  xs: 'w-1 h-1',
  xsmall: 'w-1 h-1',
  small: 'w-2 h-2',
  base: 'w-3 h-3',
  basic: 'w-4 h-4',
  medium: 'w-4 h-4',
  large: 'w-8 h-8',
  xlarge: 'w-10 h-10',
  special: 'w-20 h-20',
  none: '',
}

export const GapMap: Record<TypeSize, string> = {
  header: 'px-6',
  tiny: 'gap-[2px]',
  xs: 'gap-1',
  xsmall: 'gap-1',
  small: 'gap-2',
  base: 'gap-3',
  basic: 'gap-4',
  medium: 'gap-4',
  large: 'gap-8',
  xlarge: 'gap-10',
  special: 'gap-20',
  none: '',
}

export const SpacingXMap: Record<TypeSize, string> = {
  tiny: 'gap-[2px]',
  xs: 'px-1',
  xsmall: 'px-1',
  small: 'px-2',
  base: 'px-3',
  basic: 'px-4',
  medium: 'px-5',
  large: 'px-8',
  xlarge: 'px-10',
  special: 'px-20',
  none: '',
  header: 'px-6',
}

export const SpacingYMap: Record<TypeSize, string> = {
  header: 'py-8',
  tiny: 'gap-[2px]',
  xs: 'py-1',
  xsmall: 'py-1',
  small: 'py-2',
  base: 'py-3',
  basic: 'py-4',
  medium: 'py-5',
  large: 'py-8',
  xlarge: 'py-10',
  special: 'py-20',
  none: '',
}

export const SpacingMap: Record<TwoDimensionSize, string> = {
  tiny: 'p-[2px]',
  xs: 'p-1',
  xsmall: 'p-1',
  small: 'p-2',
  base: 'p-3',
  basic: 'p-4',
  medium: 'p-5',
  large: 'p-8',
  xlarge: 'p-10',
  special: 'p-20',
  none: '',
  tinyNarrow: `${SpacingXMap.xsmall} ${SpacingYMap.tiny}`,
  tinyThick: `${SpacingXMap.tiny} ${SpacingYMap.xsmall}`,
  baseNarrow: `${SpacingXMap.medium} ${SpacingYMap.base}`,
  baseThick: `${SpacingXMap.base} ${SpacingYMap.medium}`,
  xsmallNarrow: `${SpacingXMap.small} ${SpacingYMap.xsmall}`,
  xsmallThick: `${SpacingXMap.xsmall} ${SpacingYMap.small}`,
  smallNarrow: `${SpacingXMap.base} ${SpacingYMap.small}`,
  smallThick: `${SpacingXMap.small} ${SpacingYMap.base}`,
  mediumNarrow: `${SpacingXMap.large} ${SpacingYMap.medium}`,
  mediumThick: `${SpacingXMap.medium} ${SpacingYMap.large}`,
  largeNarrow: `${SpacingXMap.large} ${SpacingYMap.xlarge}`,
  largeLarge: `${SpacingXMap.large} ${SpacingYMap.large}`,
  largeThick: `${SpacingXMap.large} ${SpacingYMap.xlarge}`,
  xlargeNarrow: `${SpacingXMap.xlarge} ${SpacingYMap.large}`,
  xlargeThick: `${SpacingXMap.xlarge} ${SpacingYMap.xlarge}`,
  specialNarrow: `${SpacingXMap.special} ${SpacingYMap.xlarge}`,
  specialThick: `${SpacingXMap.xlarge} ${SpacingYMap.special}`,
  header: `${SpacingXMap.header} ${SpacingYMap.header}`,
}

export const Spacing = property('spacing', SpacingMap, 'none')
export const Size = property('size', SizeMap, 'none')
export const Gap = property('gap', GapMap, 'none')

export default Spacing
