import { Flex, Spacer, Icon, Box, HStack } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Field, FieldArray } from 'formik'
import {
  Column,
  Form,
  Row,
  AddQuestionMenu,
  RemoveKebabMenu,
  NewQuestionInput,
  InputPreview,
} from 'ui'
import * as yup from 'yup'
import { QueryParam, QuestionFormSchema } from '../../../operations-types'

type NewAdditionalInformation = {
  question: string
  required: boolean
  label: string
  questionType: string
}

type QueryParameter = Omit<QueryParam, '__typename'>

export type QuestionValidationPayload = {
  steps: {
    additional_information?: {
      questions: NewAdditionalInformation[]
      step: number
    }
  }
  query_parameters: QueryParameter[]
  new_additional_information: NewAdditionalInformation
  valid: {
    additional_information: boolean
  }
}

type AdditionalInformationProps = {
  validationPayload?: QuestionValidationPayload
  setValidationPayload?: (e: QuestionValidationPayload) => void
  currentSchema?: QuestionFormSchema
}

const RearrangeArrowIcons = (props: {
  upArrowOnClick: () => void
  downArrowOnClick: () => void
  index: number
  lastIndex: number
}) => {
  return (
    <Flex flexDirection="column" gap="lg" height="100%">
      <Box h="100%">
        {props.index !== 0 && (
          <Icon
            color="transparent"
            borderRadius="base"
            _groupHover={{
              color: 'gray.500',
            }}
            _hover={{
              bgColor: 'gray.200',
            }}
            strokeWidth={2}
            as={ChevronUpIcon}
            boxSize="5"
            onClick={props.upArrowOnClick}
          />
        )}
      </Box>
      <Spacer />
      <Box h="100%">
        {props.index !== props.lastIndex && (
          <Icon
            color="transparent"
            _groupHover={{
              color: 'gray.500',
            }}
            _hover={{
              bgColor: 'gray.200',
            }}
            strokeWidth={2}
            as={ChevronDownIcon}
            boxSize="5"
            onClick={props.downArrowOnClick}
          />
        )}
      </Box>
    </Flex>
  )
}

export const AdditionalInformation = (props: AdditionalInformationProps) => {
  const schema = yup.object().shape({
    additionalInformation: yup.array().of(
      yup.object().shape({
        question: yup.string().required(),
        required: yup.boolean(),
        label: yup.string().required(),
        questionType: yup.string().required(),
      }),
    ),
    newAdditionalInformation: yup.object().shape({
      question: yup.string().required(),
      required: yup.boolean(),
      label: yup.string().required(),
      questionType: yup.string().required(),
    }),
  })

  type Schema = {
    additionalInformation: NewAdditionalInformation[]
    newAdditionalInformation: NewAdditionalInformation
  }

  return (
    <Flex flexDirection={'column'} py="2" gap="4" w="100%">
      <Column gap="medium" wGrow y="center">
        <Form<Schema>
          {...{
            validationSchema: schema,
            initialValues: {
              additionalInformation:
                props?.validationPayload?.steps?.additional_information
                  ?.questions || [],
              newAdditionalInformation: props?.validationPayload
                ?.new_additional_information || {
                question: '',
                required: false,
                label: '',
                questionType: '',
              },
            },
            onChange: async (values) => {
              if (props.setValidationPayload) {
                props.setValidationPayload({
                  ...props.currentSchema,
                  steps: {
                    ...(!!props.currentSchema?.steps?.terms_of_service
                      ?.fileBuffer && {
                      terms_of_service: {
                        ...props.currentSchema?.steps?.terms_of_service,
                      },
                    }),
                    ...(values.additionalInformation &&
                      values.additionalInformation.length > 0 && {
                        additional_information: {
                          questions: values.additionalInformation,
                          step: 0,
                        },
                      }),
                  },
                  query_parameters:
                    props.validationPayload?.query_parameters || [],
                  new_additional_information: values?.newAdditionalInformation,
                  valid: {
                    ...props.validationPayload?.valid,
                    additional_information:
                      values.additionalInformation.length > 0
                        ? !values.additionalInformation.find(
                            (e: NewAdditionalInformation) =>
                              e.label === '' || e.questionType === '',
                          )
                        : true,
                  },
                })
              }
            },
          }}
          className="w-full"
        >
          {(formik) => {
            return (
              <Column gap="medium" wGrow>
                <FieldArray
                  name="additionalInformation"
                  render={(arrayHelpers) => {
                    return (
                      <Column wGrow gap="medium">
                        <>
                          {formik?.values?.additionalInformation?.map(
                            (_: NewAdditionalInformation, index: number) => {
                              return (
                                <Column gap="medium" wGrow key={index}>
                                  <Row grow y="center" between gap="small">
                                    <HStack gap="medium" role="group" w="100%">
                                      <Column className="w-full" grow>
                                        <Flex
                                          flexDirection="row"
                                          gap=""
                                          w="100%"
                                          pb="1"
                                        >
                                          <Field
                                            w="100%"
                                            as={NewQuestionInput}
                                            name={`additionalInformation.${index}.label`}
                                            placeholder={_.label || 'Question'}
                                            onChange={formik.handleChange}
                                            inputValue={`${formik.values.additionalInformation[index].label}`}
                                          />
                                          <Spacer />
                                          <RemoveKebabMenu
                                            offset={[-70, -30]}
                                            onClick={async () => {
                                              await arrayHelpers.remove(index)
                                            }}
                                          />
                                        </Flex>
                                        <HStack w="100%" h="100%" m="1">
                                          <Field
                                            as={InputPreview}
                                            inputType={
                                              formik.values
                                                .additionalInformation[index]
                                                .questionType
                                            }
                                            sx={{
                                              ':focus': {
                                                boxShadow: 'none',
                                                borderColor: 'gray.300',
                                              },
                                            }}
                                            name={`additionalInformation.${index}.question`}
                                            placeholder="Their written response."
                                            onChange={formik.handleChange}
                                            disabled={true}
                                          />
                                          <RearrangeArrowIcons
                                            index={index}
                                            lastIndex={
                                              formik.values
                                                .additionalInformation.length -
                                              1
                                            }
                                            upArrowOnClick={() => {
                                              arrayHelpers.swap(
                                                index,
                                                index - 1,
                                              )
                                            }}
                                            downArrowOnClick={() => {
                                              arrayHelpers.swap(
                                                index,
                                                index + 1,
                                              )
                                            }}
                                          />
                                        </HStack>
                                      </Column>
                                    </HStack>
                                  </Row>
                                </Column>
                              )
                            },
                          )}

                          <AddQuestionMenu
                            {...{
                              formik: formik,
                              countIndex:
                                formik.values.additionalInformation.length,
                              onSelect: async () => {
                                await arrayHelpers.insert(
                                  formik.values.additionalInformation.length,
                                  {
                                    label:
                                      formik.values.newAdditionalInformation
                                        .label,
                                    question: '',
                                    required: true,
                                    questionType:
                                      formik.values.newAdditionalInformation
                                        .questionType,
                                  },
                                )

                                await formik.setFieldValue(
                                  'newAdditionalInformation.label',
                                  '',
                                )

                                await formik.setFieldValue(
                                  'newAdditionalInformation.question',
                                  '',
                                )
                                await formik.setFieldValue(
                                  'newAdditionalInformation.required',
                                  true,
                                )

                                await formik.setFieldValue(
                                  'newAdditionalInformation.questionType',
                                  '',
                                )
                              },
                            }}
                          />
                        </>
                      </Column>
                    )
                  }}
                />
              </Column>
            )
          }}
        </Form>
      </Column>
    </Flex>
  )
}
