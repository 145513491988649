import Button from 'ui/src/components/v3/button/Button'

export function TableCellButton({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}) {
  // This needs to be standardized into it's own component
  return (
    <Button
      variant="outline"
      status="secondary"
      size="xs"
      label={`${children}`}
      onClick={onClick}
      isDisabled={disabled}
    >
      {children}
    </Button>
  )
}
