import { styles } from '@nickeltech/brise'

export type VisibilityTransitionProps = {
  visible?: boolean
}

export const VisibilityTransition = styles<VisibilityTransitionProps>`
  ${({ visible }) => {
    if (visible === undefined) {
      return ''
    }

    return visible ? 'opacity-100 w-inherit' : 'opacity-0 w-0 h-0'
  }}
`

export type DisabledTransitionProps = {
  disabled?: boolean
}

export const DisabledTransition = styles<DisabledTransitionProps>`
  ${({ disabled }) => {
    if (disabled === undefined) {
      return ''
    }

    return disabled
      ? '!opacity-50 w-inherit cursor-default pointer-events-none'
      : 'opacity-100 w-inherit'
  }}
`

export type PaginationDisabledTransitionProps = {
  disabled?: boolean
}

export const PaginationDisabledTransition = styles<PaginationDisabledTransitionProps>`
  ${({ disabled }) => {
    if (disabled === undefined) {
      return ''
    }

    return disabled
      ? '!opacity-100 w-inherit cursor-default pointer-events-none'
      : 'opacity-50 w-inherit'
  }}
`
