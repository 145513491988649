import { useMutation } from '@apollo/client'
import { Breadcrumb, BreadcrumbItem, Button, Flex } from '@chakra-ui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbLinkItem } from 'ui'
import { useAuth } from '../../lib/auth'
import {
  RevertToAccountingAccountDocument,
  SelfDocument,
} from '../../operations-types'
import { FlowLayout, FlowLayoutProps } from '../layout/FlowLayout'
import { Header } from '../layout/Header'
import { AccountingSync } from './AccountingSync'
import { AddClientStates, useAddClientStore } from './addClientStore'
import { AddOwner } from './AddOwner'
import { BankAccount } from './BankAccount'
import { BusinessInformation } from './BusinessInformation'

type ClientHeaderProps = {
  currentPage: AddClientStates
  setPage: (page: AddClientStates) => void
}

function ClientHeader({ currentPage, setPage }: ClientHeaderProps) {
  const { reset } = useAddClientStore((state) => ({
    reset: state.reset,
  }))
  const navigate = useNavigate()

  return (
    <Header
      rightIcon={{
        iconName: 'xMark',
        iconText: 'Exit',
        onClick: () => {
          navigate('/dashboard/clients')
          reset()
        },
      }}
    >
      <Breadcrumb
        separator={
          <ChevronRightIcon
            className="stroke-gray-400"
            height="14px"
            width="14px"
          />
        }
      >
        <BreadcrumbItem>
          <BreadcrumbLinkItem
            isCurrentPage={currentPage === 'company'}
            isDisabled={
              currentPage === 'accounting' || currentPage === 'bankAccount'
            }
            text="Company Information"
            onClick={() => setPage('company')}
          />
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLinkItem
            isCurrentPage={currentPage === 'accounting'}
            isDisabled={
              currentPage === 'company' || currentPage === 'bankAccount'
            }
            text="Accounting Sync"
          />
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLinkItem
            isCurrentPage={currentPage === 'bankAccount'}
            isDisabled={
              currentPage === 'company' || currentPage === 'accounting'
            }
            text="Bank Account"
          />
        </BreadcrumbItem>
      </Breadcrumb>
    </Header>
  )
}

type AddClientFlowProps = Omit<FlowLayoutProps, 'header' | 'currentPage'> &
  ClientHeaderProps

export function AddClientLayout({
  children,
  title,
  subtitle,
  currentPage,
  setPage,
}: AddClientFlowProps) {
  return (
    <FlowLayout
      header={<ClientHeader currentPage={currentPage} setPage={setPage} />}
      title={title}
      subtitle={subtitle}
      currentPage={currentPage}
    >
      {children}
    </FlowLayout>
  )
}

// TODO: Gate this to only show for users who are accounting firms
export function AddClientFlow() {
  const { step, setStep, clientOrg, setClientOrg, reset } = useAddClientStore(
    (state) => ({
      setStep: state.setStep,
      step: state.step,
      clientOrg: state.clientOrg,
      setClientOrg: state.setClientOrg,
      reset: state.reset,
    }),
  )
  const navigate = useNavigate()
  const { setAuth } = useAuth()
  const [revertToAccountingAccount] = useMutation(
    RevertToAccountingAccountDocument,
    {
      onCompleted: (data) => {
        const token = data?.revertToAccountingAccount?.token

        setAuth(token)
      },
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    },
  )

  const statesToComponent: Record<AddClientStates, React.ReactNode> = {
    company: (
      <AddClientLayout
        title="Add a new client"
        subtitle="Tell us some basic information about the client you're onboarding"
        currentPage="company"
        setPage={setStep}
      >
        <BusinessInformation
          onClick={(org) => {
            setClientOrg(org)
            setStep('addOwner')
          }}
        />
      </AddClientLayout>
    ),
    addOwner: (
      <AddClientLayout
        title={`Want to invite ${clientOrg?.ownerFirstName} onto Nickel?`}
        subtitle={`This will allow ${clientOrg?.ownerFirstName} to manage their Nickel account.`}
        currentPage="company"
        setPage={setStep}
      >
        {clientOrg && <AddOwner onFinish={() => setStep('accounting')} />}
      </AddClientLayout>
    ),
    accounting: (
      <>
        {clientOrg && (
          <AccountingSync clientOrg={clientOrg} setCurrentPage={setStep} />
        )}
      </>
    ),
    bankAccount: (
      <AddClientLayout
        title="Business Bank Account"
        subtitle="Provide a bank account to receive and send payments."
        currentPage="bankAccount"
        setPage={setStep}
      >
        {clientOrg && (
          <BankAccount clientOrg={clientOrg} onFinish={() => setStep('done')} />
        )}
      </AddClientLayout>
    ),
    done: (
      <AddClientLayout
        title="Success!"
        subtitle={`You've successfully added ${
          clientOrg?.name || 'this business'
        } as a client.`}
        currentPage="bankAccount"
        setPage={setStep}
      >
        <Flex width="540px" flexDirection="column" gap={6}>
          <Button
            onClick={() => {
              navigate('/dashboard')
              reset()
            }}
          >
            Switch to {clientOrg?.name}
          </Button>
          <Button
            variant="outline"
            onClick={async () => {
              await revertToAccountingAccount()
              navigate('/dashboard/clients')
              reset()
            }}
          >
            Return to Dashboard
          </Button>
        </Flex>
      </AddClientLayout>
    ),
  }
  return <>{statesToComponent[step]}</>
}
