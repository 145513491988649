import { Text } from '@chakra-ui/react'
import classNames from 'classnames'
import _ from 'lodash'
import usdFormatter from '../../formatter'
import Icon from '../Icons'
import Column, { UnstandardizedColumn } from '../Layout/Column'
import Row from '../Layout/Row'
import Separator from '../Layout/Separator'
import { UnstandardizedText } from '../Typography/Text'
import OverlayTabs from './OverlayTabs'

export type Transaction = {
  amountDollars: number
  sublabel: string
  label: string
  transactionId: string
  type: 'credit' | 'debit'
  methodType: 'card' | 'bank'
  methodText: string
}

export type OverlayTransactionsProps = {
  charges: Transaction[]
  refunds: Transaction[]
  fees: Transaction[]
  returns: Transaction[]
  onClick?: (transaction: Transaction) => void
}

function OverlayTransaction(transaction: Transaction) {
  return (
    <UnstandardizedColumn wGrow className="gap-y-1 px-4 py-3">
      <Row grow between y="center">
        <UnstandardizedText className="text-small font-medium text-gray-800">
          {transaction.label}
        </UnstandardizedText>
        <UnstandardizedText
          className={classNames(
            'text-small font-medium',
            { 'text-green-600': transaction.type == 'credit' },
            { 'text-red-600': transaction.type == 'debit' },
          )}
        >
          {`${transaction.type === 'credit' ? '+' : '-'} ${usdFormatter.format(
            transaction.amountDollars,
          )}`}
        </UnstandardizedText>
      </Row>
      <Row grow between y="center">
        <UnstandardizedText className="text-xs font-normal text-gray-500">
          {transaction.sublabel}
        </UnstandardizedText>
        <Row gap="small" y="center">
          <Icon
            name={
              transaction.methodType === 'card'
                ? 'creditCard'
                : 'buildingLibrary'
            }
            size="small"
            className="stroke-gray-500"
          />

          <UnstandardizedText className="text-xs font-normal text-gray-500">
            {transaction.methodText}
          </UnstandardizedText>
        </Row>
      </Row>
    </UnstandardizedColumn>
  )
}

export type TransactionListProps = {
  transactions: Transaction[]
  onClick?: (transaction: Transaction) => void
}

export function TransactionList({
  transactions,
  onClick,
}: TransactionListProps) {
  return (
    <UnstandardizedColumn
      wGrow
      className={classNames(
        { border: transactions.length > 0 },
        'border-gray-300',
        'rounded',
      )}
    >
      {transactions.length === 0 && (
        <UnstandardizedColumn
          wGrow
          className="gap-y-1 px-4 py-3 rounded border"
        >
          <Row grow between y="center">
            <Text>No transactions</Text>
          </Row>
        </UnstandardizedColumn>
      )}
      {transactions.map((transaction, index) => (
        <Column
          wGrow
          key={index}
          onClick={() => onClick && onClick(transaction)}
          className="hover:bg-gray-100"
        >
          <OverlayTransaction {...transaction} />
          {index < transactions.length - 1 && (
            <Separator orientation="horizontal" />
          )}
        </Column>
      ))}
    </UnstandardizedColumn>
  )
}

export function OverlayTransactions({
  charges,
  refunds,
  fees,
  returns,
  onClick,
}: OverlayTransactionsProps) {
  return (
    <OverlayTabs
      tabs={[
        {
          name: 'Charges',
          component: (
            <UnstandardizedColumn wGrow gap="medium">
              <Separator orientation="horizontal" />
              <TransactionList transactions={charges} onClick={onClick} />
            </UnstandardizedColumn>
          ),
        },
        {
          name: 'Fees',
          component: (
            <UnstandardizedColumn wGrow gap="medium">
              <Separator orientation="horizontal" />
              <TransactionList transactions={fees} onClick={onClick} />
            </UnstandardizedColumn>
          ),
        },
        {
          name: 'Refunds',
          component: (
            <UnstandardizedColumn wGrow gap="medium">
              <Separator orientation="horizontal" />
              <TransactionList transactions={refunds} onClick={onClick} />
            </UnstandardizedColumn>
          ),
        },
        {
          name: 'Returns',
          component: (
            <UnstandardizedColumn wGrow gap="medium">
              <Separator orientation="horizontal" />
              <TransactionList transactions={returns} onClick={onClick} />
            </UnstandardizedColumn>
          ),
        },
      ]}
    />
  )
}
