import { property, tw } from '@nickeltech/brise'
import ContainerProps, { VariantProps } from '../../types'
import { HTMLProps } from 'react'
import { ClampedProps, ClampedStyle } from '.'
import { SizeProps } from '../Layout/Spacing'

export type LinkProps = {} & ContainerProps &
  HTMLProps<HTMLAnchorElement> &
  VariantProps &
  ClampedProps &
  SizeProps

export const VariantStyle = property(
  'variant',
  {
    primary: 'text-blue-400 text-sm font-normal cursor-pointer no-underline',
    secondary: 'text-gray-400 text-xs font-normal cursor-pointer no-underline',
  },
  'primary',
)

export const Link = tw.a<LinkProps>`
  ${VariantStyle}
  ${ClampedStyle}
`

export default Link
