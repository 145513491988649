const CREDIT_CARD_FEE = 0.029

function calculateFeePercentage() {
  const feePercent = CREDIT_CARD_FEE

  return (feePercent * 100).toFixed(2)
}

type GetFeeStringProps = {
  noCardFee: boolean | undefined
}

export function getFeeString(
  { noCardFee = false }: GetFeeStringProps = {
    noCardFee: false,
  },
) {
  if (noCardFee) {
    return 'Free'
  }

  const feePercent = calculateFeePercentage()
  if (feePercent == '0.00') {
    return 'Free'
  }

  // Replaces any trailing zeroes with an empty string
  // See https://stackoverflow.com/questions/26299160/using-regex-how-do-i-remove-the-trailing-zeros-from-a-decimal-number
  return `${calculateFeePercentage().replace(
    /^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/,
    '$1$2$3',
  )}% Fee`
}
