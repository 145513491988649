import { Account } from './Account'
import { BankAccount } from './BankAccount'
import { Business } from './Business'
import { Owner } from './Owner'
import {
  RegistrationPage,
  useSelfServiceRegistrationStore,
} from './selfServiceRegistrationStore'

export function SelfServiceRegistration() {
  const { setStep, step } = useSelfServiceRegistrationStore((state) => ({
    setStep: state.setStep,
    step: state.step,
  }))

  const components: Record<RegistrationPage, React.ReactNode> = {
    email: <Account onNextStep={() => setStep('owner')} />,
    owner: <Owner onNextStep={() => setStep('business')} />,
    business: <Business onNextStep={() => setStep('bankAccount')} />,
    bankAccount: <BankAccount />,
  }

  return <>{components[step]}</>
}
