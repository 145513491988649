import { Badge, Box, HStack, Text } from '@chakra-ui/react'
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

type AddBageProps = {
  onClick?: () => void
}

function AddBadge({ onClick }: AddBageProps) {
  // For some reason some Chakra CSS adds a margin to the left of the icon
  // We explicitly set it to 0 here to override that
  return (
    <Box cursor={'pointer'} onClick={onClick}>
      <Badge
        variant="dotted"
        _hover={{ border: '1px solid #78716c', bg: 'gray.100' }}
      >
        <HStack gap="1" alignContent={'center'} justifyContent="center">
          <PlusCircleIcon width="14px" />
          <Text style={{ marginInlineStart: 0 }}>Add</Text>
        </HStack>
      </Badge>
    </Box>
  )
}

type EmailBadgeProps = {
  email: string
  onClick?: () => void
}

function EmailBadge({ email, onClick }: EmailBadgeProps) {
  return (
    <Box cursor={'pointer'} onClick={onClick}>
      <Badge _hover={{ bg: 'gray.200' }} border="1px solid #78716c">
        <HStack gap="1" alignContent={'center'} justifyContent="center">
          <Text style={{ marginInlineStart: 0 }} fontWeight="medium">
            {email}
          </Text>
          <XMarkIcon style={{ marginInlineStart: 0 }} width="14px" />
        </HStack>
      </Badge>
    </Box>
  )
}
type NotificationFooterProps = {
  onAddEmail: () => void
  onRemoveEmail: (email: string) => void
  emails: string[]
}

export function NotificationFooter({
  onAddEmail,
  onRemoveEmail,
  emails,
}: NotificationFooterProps) {
  return (
    <HStack alignContent="center" gap="2" maxWidth="600px" flexWrap="wrap">
      <AddBadge onClick={onAddEmail} />
      {emails.map((email) => (
        <EmailBadge
          key={email}
          email={email}
          onClick={() => onRemoveEmail(email)}
        />
      ))}
    </HStack>
  )
}
