import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import { useState } from 'react'
import { UsaStates } from 'usa-states'
import { Select } from 'chakra-react-select'

type FormikStateFieldProps = {
  fieldName: string
}

export function FormikStateField<T extends FormikValues>({
  fieldName,
}: FormikStateFieldProps) {
  const usStates = new UsaStates()
  const formik = useFormikContext<T>()

  const [stateLabel, setStateLabel] = useState('')

  return (
    <FormControl
      isInvalid={!!formik.errors[fieldName] && !!formik.touched[fieldName]}
    >
      <FormLabel>State of Incorporation</FormLabel>
      <Select
        size="sm"
        selectedOptionStyle="check"
        placeholder="State"
        name={fieldName}
        onMenuClose={() => formik.setFieldTouched(fieldName, true)}
        options={usStates.states.map((states) => ({
          value: states.abbreviation,
          label: states.name,
        }))}
        onChange={(newValue) => {
          if (!newValue) {
            return
          }
          const selectedState = newValue.value
          formik.setFieldValue(fieldName, selectedState)
          setStateLabel(newValue.label)
        }}
        value={{
          value: usStates.states.find(
            (states) => states.abbreviation === formik.values[fieldName],
          )?.abbreviation,
          label: stateLabel,
        }}
        chakraStyles={{
          control: (provided, _) => ({
            ...provided,
            borderRadius: 'md',
            height: '40px',
          }),
        }}
      />
      {!!formik.errors[fieldName] && !!formik.touched[fieldName] && (
        <FormErrorMessage m="3px">
          {formik.errors[fieldName] as string}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
