import { Button, ValidatingInput, Form, Separator } from 'ui'

import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'

import * as yup from 'yup'

import RegistrationBreadcrumbNavigator from './RegistrationBreadcrumbNavigator'

import OnboardingHeader from '../onboarding/OnboardingHeader'
import React, { useState } from 'react'

type LoginInfoProps = {
  firstName: string
  lastName: string
  password: string
  email: string
  onPasswordChange: React.ChangeEventHandler<HTMLInputElement>
  onContinue: () => void
  onFirst: React.ChangeEventHandler<HTMLInputElement>
  onLast: React.ChangeEventHandler<HTMLInputElement>
  setManualEmail: (email: string) => void
  loginErrors: { password: string; firstName: string; lastName: string }
  vendor?: boolean
}

const LoginInfoFormSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup.string().required('Password is required'),
})

function LoginInfo({
  firstName,
  lastName,
  password,
  email,
  onPasswordChange,
  onContinue,
  onFirst,
  onLast,
  setManualEmail,
  loginErrors,
  vendor,
}: LoginInfoProps) {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Box mt="32px">
      <Card mx="auto" maxW="2xl">
        <Flex flexDirection="column">
          <>
            <OnboardingHeader />
            <HStack
              pt="1"
              ps="6"
              borderBottomWidth="1px"
              borderBottomStyle="solid"
              borderBottomColor="gray.200"
              pb="1"
            >
              <RegistrationBreadcrumbNavigator currentStep={0} />
            </HStack>
            <Form
              className="w-full"
              {...{
                validationSchema: LoginInfoFormSchema,
                initialValues: {
                  firstName: firstName,
                  lastName: lastName,
                  password: password,
                },
                onSubmit: async () => onContinue(),
              }}
            >
              {(formik) => {
                return (
                  <>
                    <CardBody p="6" boxShadow="0">
                      <VStack
                        spacing="0"
                        gap="0"
                        w="100%"
                        alignItems="left"
                        pb="4"
                      >
                        <Heading
                          fontSize="xl"
                          fontWeight="semibold"
                          w="100%"
                          py="4"
                        >
                          Registration
                        </Heading>
                        <Text>Let's get your login information first.</Text>
                      </VStack>
                      <VStack spacing="2" gap="2">
                        <ValidatingInput
                          id="username"
                          fontSize="sm"
                          autoComplete="off"
                          label="Email"
                          placeholder={email}
                          isInvalid={false}
                          isDisabled={vendor ? false : true}
                          onChange={(e) => {
                            vendor && setManualEmail(e.target.value)
                          }}
                          onBlur={formik.handleBlur}
                        />

                        <VStack w="100%" spacing="0" gap="1" alignItems="end">
                          <ValidatingInput
                            id="password"
                            fontSize="sm"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            value={password}
                            validated={!!password}
                            isInvalid={!!loginErrors.password}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              formik.handleChange(e)
                              onPasswordChange(e)
                            }}
                            error={loginErrors.password}
                            onBlur={formik.handleBlur}
                          />
                          {formik.values.password && (
                            <Text
                              fontSize="xs"
                              color="purple.600"
                              pe="2"
                              cursor="pointer"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? 'Hide Password' : 'Show Password'}
                            </Text>
                          )}
                        </VStack>

                        <ValidatingInput
                          id="firstName"
                          fontSize="sm"
                          autoComplete="first-name"
                          label="First Name"
                          value={firstName}
                          validated={!!firstName}
                          isInvalid={!!loginErrors.firstName}
                          onChange={(e) => {
                            formik.handleChange(e)
                            onFirst(e)
                          }}
                          error={loginErrors.firstName}
                          onBlur={formik.handleBlur}
                        />

                        <ValidatingInput
                          id="lastName"
                          fontSize="sm"
                          autoComplete="family-name"
                          label="Last Name"
                          value={lastName}
                          isInvalid={!!loginErrors.lastName}
                          validated={!!lastName}
                          onChange={(e) => {
                            formik.handleChange(e)
                            onLast(e)
                          }}
                          error={loginErrors.lastName}
                          onBlur={formik.handleBlur}
                        />
                      </VStack>
                    </CardBody>
                    <Separator orientation="horizontal" />
                    <VStack m="5" alignItems="center" spacing="medium" gap="0">
                      <Button
                        type="submit"
                        status="primary"
                        variant="solid"
                        size="lg"
                        width="100%"
                        label="Continue"
                        iconName="chevronRight"
                        iconPosition="right"
                        onClick={onContinue}
                        isDisabled={Object.keys(formik.errors).length !== 0}
                      />
                    </VStack>
                  </>
                )
              }}
            </Form>
          </>
        </Flex>
      </Card>
    </Box>
  )
}

export default LoginInfo
