import ContainerProps from '../../../types'
import { property, tw } from '@nickeltech/brise'
import Row from '../layout/Row'
import Icon, { IconName } from '../../Icons'
import { Variant } from '../types'
import { SizeValue } from '../types'
import { LabelLinkButton, TextSize, TextWeight } from '../Typography'

export type IconPosition = 'left' | 'right'

export type LinkButtonSize = Extract<SizeValue, 'small' | 'base' | 'large'>
export type LinkButtonVariant = Extract<Variant, 'primary' | 'default'>

export const LinkButtonSizeArray: Array<LinkButtonSize> = [
  'small',
  'base',
  'large',
]
export const LinkButtonVariantArray: Array<LinkButtonVariant> = [
  'default',
  'primary',
]
export const LinkButtonIconPosition: Array<IconPosition> = ['left', 'right']

export type BaseLinkButtonProps = {
  size?: LinkButtonSize
  variant?: LinkButtonVariant
  iconPosition?: IconPosition
  iconName?: IconName
  label?: string
  disabled?: boolean
} & ContainerProps

export const ButtonDisabled = property<BaseLinkButtonProps>(
  'disabled',
  {
    true: 'pointer-events-none',
    false: '',
  },
  'false',
)

export const BaseLinkButton = tw.button<BaseLinkButtonProps>`
  ${TextSize}
  ${TextWeight}
  ${ButtonDisabled}
`

export const LinkButton = tw((props: BaseLinkButtonProps) => {
  const LinkButtonBaseLabel = (
    <LabelLinkButton
      size={props.size}
      variant={props.disabled ? 'secondary' : props.variant}
    >
      {props.label}
    </LabelLinkButton>
  )

  return (
    <BaseLinkButton
      {...{
        className: props.className,
        ...props,
      }}
    >
      <Row y="center" between grow hGrow spacingX="none" spacingY="none">
        {props.iconPosition === 'left' &&
        props.iconName &&
        props.iconName?.length > 0 ? (
          <Icon name={props.iconName} />
        ) : (
          ''
        )}
        {LinkButtonBaseLabel}
        {props.iconPosition === 'right' &&
        props.iconName &&
        props.iconName?.length > 0 ? (
          <Icon name={props.iconName} />
        ) : (
          ''
        )}
      </Row>
    </BaseLinkButton>
  )
})`
`
