import { Link } from 'react-router-dom'
import { Button } from 'ui'

export const Header: React.FC = () => {
  return (
    <div className="flex justify-center">
      <div className="flex justify-between w-full max-w-screen-xl">
        <Link to="/">
          <img alt="nickel logo" src="/white-logo-500.png" className="w-32" />
        </Link>

        <Button
          label="Open Account"
          onClick={() => {
            window.location.href =
              'https://app.getnickel.com/register?ref=login'
          }}
          status="accent"
          color="dark.600"
          variant="solid"
          _hover={{ bg: 'accent.500' }}
        />
      </div>
    </div>
  )
}

export const LoggedInHeader: React.FC = () => {
  return (
    <div className="flex justify-center">
      <div className="flex justify-between w-full max-w-screen-xl">
        <Link to="/dashboard">
          <img alt="nickel logo" src="/logo500.png" className="w-32" />
        </Link>
        <Link
          to="/dashboard"
          className="no-underline font-light hover:underline text-nickel-purple"
        >
          Dashboard
        </Link>
      </div>
    </div>
  )
}
