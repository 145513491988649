import { Form, Formik } from 'formik'
import { forwardRef, Ref } from 'react'
import * as yup from 'yup'
import BillsFilterDropdownComponent from './BillsFilterDropdownComponent'

type BillsFilterDropdownProps = {
  onSubmit: (values: FilterSchema) => void
  formValues: FilterSchema
}

const Schema = yup.object().shape({
  filterItem: yup
    .string()
    .oneOf([
      'issuedOnStartDate',
      'issuedOnEndDate',
      'dueDateStartDate',
      'dueDateEndDate',
      'billAmountStartValue',
      'billAmountEndValue',
      'amountDueStartValue',
      'amountDueEndValue',
      'vendorName',
      'billReferenceId',
    ])
    .required(),
  issuedOnStartDate: yup.date().when('filterItem', {
    is: 'issuedOnStartDate',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  issuedOnEndDate: yup.date().when('filterItem', {
    is: 'issuedOnEndDate',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  dueDateStartDate: yup.date().when('filterItem', {
    is: 'dueDateStartDate',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  dueDateEndDate: yup.date().when('filterItem', {
    is: 'dueDateEndDate',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  billAmountStartValue: yup.string().when('filterItem', {
    is: 'billAmountStartValue',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  billAmountEndValue: yup.string().when('filterItem', {
    is: 'billAmountEndValue',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  amountDueStartValue: yup.string().when('filterItem', {
    is: 'amountDueStartValue',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  amountDueEndValue: yup.string().when('filterItem', {
    is: 'amountDueEndValue',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  vendorName: yup.string().when('filterItem', {
    is: 'vendorName',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  billReferenceId: yup.string().when('filterItem', {
    is: 'billReferenceId',
    then: yup.string().min(1).required(),
    otherwise: undefined,
  }),
})

export type FilterSchema = yup.InferType<typeof Schema>

export function BillsFilterDropdown({ onSubmit }: BillsFilterDropdownProps) {
  const to = new Date()
  const from = new Date()

  from.setHours(0)
  from.setSeconds(0)
  from.setMinutes(0)
  to.setHours(23)
  to.setMinutes(59)
  to.setSeconds(59)

  return (
    <Formik<FilterSchema>
      {...{
        initialValues: {
          issuedOnStartDate: undefined,
          issuedOnEndDate: undefined,
          dueDateStartDate: undefined,
          dueDateEndDate: undefined,
          billAmountStartValue: '',
          billAmountEndValue: '',
          amountDueStartValue: '',
          amountDueEndValue: '',
          vendorName: '',
          billReferenceId: '',
          filterItem: 'billReferenceId',
        } as FilterSchema,
        validationSchema: Schema,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values, actions) => {
          onSubmit(values)

          actions.setSubmitting(false)
        },
      }}
    >
      {({ handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <BillsFilterDropdownComponent
              label="Filter"
              onClick={(value) => setFieldValue('filterItem', value)}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

const ReffedBillsFilterDropdownComponentCore = (
  { onSubmit, formValues }: BillsFilterDropdownProps,
  ref: Ref<HTMLFormElement>,
) => {
  const to = new Date()
  const from = new Date()

  from.setHours(0)
  from.setSeconds(0)
  from.setMinutes(0)
  to.setHours(23)
  to.setMinutes(59)
  to.setSeconds(59)

  return (
    <Formik<FilterSchema>
      {...{
        initialValues: {
          issuedOnStartDate: formValues.issuedOnStartDate || undefined,
          issuedOnEndDate: formValues.issuedOnEndDate || undefined,
          dueDateStartDate: formValues.dueDateStartDate || undefined,
          dueDateEndDate: formValues.dueDateEndDate || undefined,
          billAmountStartValue: formValues.billAmountStartValue || undefined,
          billAmountEndValue: formValues.billAmountEndValue || undefined,
          amountDueStartValue: formValues.amountDueStartValue || undefined,
          amountDueEndValue: formValues.amountDueEndValue || undefined,
          vendorName: formValues.vendorName || undefined,
          billReferenceId: formValues.billReferenceId || undefined,
          filterItem: 'billReferenceId',
        } as FilterSchema,
        validationSchema: Schema,
        onSubmit: (values, actions) => {
          actions.setSubmitting(true)

          onSubmit(values)
          actions.setSubmitting(false)
        },
      }}
    >
      {({ handleSubmit, setFieldValue }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            ref={ref as Ref<HTMLFormElement>}
            className="absolute z-50"
          >
            <BillsFilterDropdownComponent
              label="Filter"
              onClick={(value) => setFieldValue('filterItem', value)}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export const ReffedBillsFilterDropdown = forwardRef(
  ReffedBillsFilterDropdownComponentCore,
)
