import { Center, Flex, Image } from '@chakra-ui/react'

export const STATIC_MERCHANT_LOGO = '../../../logo500.png'
const OnboardingHeader = () => {
  return (
    <Center height="48px" borderBottom="1px solid #E7E5E4">
      <Flex>
        <Image
          src={STATIC_MERCHANT_LOGO}
          alt="Organization Logo"
          style={{ objectFit: 'contain' }}
          height={100}
          width={100}
        />
      </Flex>
    </Center>
  )
}

export default OnboardingHeader
