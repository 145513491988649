import { tw } from '@nickeltech/brise'
import { Field, FieldProps, useFormikContext } from 'formik'
import { HTMLProps } from 'react'
import { TextInputLabel } from './TextInputLabel'
import { ContainerProps } from '../types'
import Column from '../layout/Column'
import Row from '../layout/Row'
import Icon from '../../Icons'
import get from 'lodash/get'

const MAX_CHARACTERS = 99999

export type V2TextInputProps = {
  label?: string
  onChange?: (e: any) => void
  hasError?: boolean
  hasSuccess?: boolean
  maxCharacters?: number
} & HTMLProps<HTMLInputElement> &
  Partial<FieldProps>

const Input = tw.input<V2TextInputProps>`

  outline-none
  border
  border-gray-300
  rounded
  w-full
  focus:border-gray-400
  focus:outline-none
  active:outline-none
  py-2
  px-4

  text-gray-800
  text-base
  placeholder:text-gray-500
  placeholder:text-base

  max-sm:placeholder:text-base

  ${(props) =>
    props.hasError ? 'text-[#e02c1f] !border-[#e02c1f] border' : ''}
  ${(props) =>
    props.hasSuccess ? 'text-[#35a62d] !border-[#35a62d] border' : ''}
`

export const TextInputComponent = tw(
  ({ field, form, ...props }: FieldProps & ContainerProps & any) => {
    return (
      <Row grow className="w-full relative">
        <Input
          {...field}
          {...{
            className: props.className,
            placeholder: props.placeholder,
            onKeyDown: props.onKeyDown,
            hasError: props.hasError,
            hasSuccess: props.hasSuccess,
            onChange: (e) => {
              if (
                e.target.value.length <= (props.maxCharacters || MAX_CHARACTERS)
              ) {
                if (props.type && props.type === 'text') {
                  form.setFieldValue(
                    field.name,
                    e.target.value.replace(/[^a-zA-Z ]/gi, ''),
                  )
                } else if (props.type && props.type === 'number') {
                  form.setFieldValue(
                    field.name,
                    e.target.value.replace(/[^0-9]+/g, ''),
                  )
                } else {
                  form.setFieldValue(field.name, e.target.value)
                }
              }
            },
          }}
          spacing="small"
        />
        <Row grow x="right" className="absolute right-[12px] top-[14px]">
          <Row
            gap="small"
            y="center"
            x="right"
            {...{
              initial: {
                opacity: 0,
                width: 0,
              },
              animate: {
                opacity: props.hasSuccess ? '100' : '0',
                width: props.hasSuccess ? '45px' : '0',
              },
            }}
          >
            <Icon name="check" status="success" className="relative" />
          </Row>
          <Row
            gap="small"
            y="center"
            x="right"
            {...{
              initial: {
                opacity: 0,
                width: 0,
              },
              animate: {
                opacity: props.hasError ? '100' : '0',
                width: props.hasError ? '45px' : '0',
              },
            }}
          >
            <Icon name="noSymbol" status="error" className="relative" />
          </Row>
        </Row>
      </Row>
    )
  },
)``

export const TextInput = (props: V2TextInputProps) => {
  const { values } = useFormikContext()

  const currentValue = get(values, props.name || '', '')

  return (
    <Column gap="small" {...{ disabled: props.disabled }} wGrow>
      {props.label && props.label.length > 0 ? (
        <TextInputLabel>{props.label}</TextInputLabel>
      ) : (
        ''
      )}
      <Field
        type={props.type}
        onKeyDown={props.onKeyDown}
        maxCharacters={props.maxCharacters}
        name={props.name}
        placeholder={props.placeholder}
        className={props.className}
        component={TextInputComponent}
        hasError={props.hasError && currentValue.length > 0}
        hasSuccess={props.hasSuccess && currentValue.length > 0}
        id={props.id}
      />
    </Column>
  )
}

export default TextInput
