import {
  Children,
  cloneElement,
  createRef,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPopper, VariationPlacement } from '@popperjs/core'
import classNames from 'classnames'
import Column from '../Layout/Column'
import Text from '../Typography/Text'
import Row from '../Layout/Row'

type DropdownOption = {
  label: string
  onClick: MouseEventHandler<HTMLDivElement>
}

type DropdownProps = {
  children: ReactNode
  position: VariationPlacement
  options: Array<DropdownOption>
  onVisible: (visible: boolean) => void
  visible?: boolean
  className?: string
  fullWidth?: boolean
  offset?: {
    x: number
    y: number
  }
}

type DropdownMenuProps = {
  options: Array<DropdownOption>
  visible: boolean
  onVisible: (visible: boolean) => void
  fullWidth?: boolean
  className?: string
}

const DropdownMenu = forwardRef(function Menu(
  { options, visible, onVisible, fullWidth, className }: DropdownMenuProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const anchorRef = createRef<HTMLDivElement>()

  return (
    <Row
      grow={fullWidth ? true : false}
      {...{ forwadedRef: ref }}
      spacing={visible ? 'small' : 'none'}
      className={className}
    >
      <Column
        className={classNames(`rounded-md drop-shadow-sm bg-white border`, {
          block: visible,
          hidden: !visible,
        })}
        wGrow
        gap="none"
      >
        {options.map((op, index) => (
          <div
            ref={anchorRef}
            {...{
              onClick: (e) => {
                op.onClick(e)
                onVisible(false)
              },
            }}
            key={op.label}
            className={classNames({
              'rounded-t-md': index === 0,
              'rounded-b-md': index === options.length - 1,
              'w-full cursor-pointer hover:bg-gray-200 p-2': true,
            })}
          >
            <Text variant="tertiary">{op.label}</Text>
          </div>
        ))}
      </Column>
    </Row>
  )
})

export const Dropdown = (props: DropdownProps) => {
  const [visible, setVisible] = useState(props.visible)
  const targetRef = useRef<HTMLDivElement>()
  const popoverRef = useRef<HTMLDivElement>()

  let target = Children.toArray(props.children).at(0) as ReactElement

  target = cloneElement(target, {
    forwadedRef: targetRef,
    onClick: (e: MouseEvent) => (visible ? onClose() : onOpen(e)),
    value: 'target',
  })

  useEffect(() => {
    const onScroll = () => {
      if (popoverRef.current) {
        ;(popoverRef.current as HTMLDivElement).style.display = 'none'
        props.onVisible(false)
        setVisible(false)
      }
    }

    const onClick = (e: MouseEvent) => {
      if (popoverRef.current) {
        ;(popoverRef.current as HTMLDivElement).style.display = 'none'
        props.onVisible(false)
        setVisible(false)
      }
    }

    window.addEventListener('scroll', onScroll)
    window.addEventListener('click', onClick)

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('click', onClick)
    }
  }, [props, popoverRef])

  const onOpen = (e: MouseEvent) => {
    e.stopPropagation()

    if ((popoverRef.current as HTMLElement).style) {
      ;(popoverRef.current as HTMLElement).style.display = 'block'
    }

    createPopper(
      targetRef.current as HTMLElement,
      popoverRef.current as HTMLElement,
      {
        placement: props.position,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [props.offset?.x || 0, props.offset?.y || 0],
            },
          },
        ],
      },
    )

    setVisible(true)
    props.onVisible(true)
  }

  const onClose = () => {
    setVisible(false)
    props.onVisible(false)
  }

  return (
    <>
      {target}
      <DropdownMenu
        {...{
          onVisible: (e) => setVisible(e),
          fullWidth: props.fullWidth,
          visible: props.visible || false,
          className: props.className,
          options: props.options,
          ref: popoverRef as RefObject<HTMLDivElement>,
        }}
      />
    </>
  )
}

export default Dropdown
