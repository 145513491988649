import Column from '../Layout/Column'
import Row from '../Layout/Row'
import Separator from '../Layout/Separator'
import Text from '../Typography/Text'

export type Detail = {
  label: string
  value: string
}
export type OverlayDetailsProps = {
  details: Detail[]
}
export default function OverlayDetails({ details }: OverlayDetailsProps) {
  return (
    <Column gap="small" wGrow>
      {details.map((detail, index) => (
        <Column gap="small" wGrow key={index}>
          <Row between grow key={index}>
            <Text>{detail.label}</Text>
            <Text>{detail.value}</Text>
          </Row>
          {index != details.length - 1 ? (
            <Separator orientation="horizontal" />
          ) : (
            ''
          )}
        </Column>
      ))}
    </Column>
  )
}
