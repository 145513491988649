import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import currency from 'currency.js'
import { Icon } from 'ui'
import { NickelPlansPage } from './types'

export type NickelPlansHeaderProps = {
  onGoBackClick: () => void
  breakdown?: BreakdownProps
} & NickelPlansBreadcrumbsProps

type GoBackProps = {
  onClick: () => void
}

type NickelPlansBreadcrumbsProps = {
  currentPage: NickelPlansPage
  onPageChange: (page: NickelPlansPage) => void
}

function GoBack({ onClick }: GoBackProps) {
  return (
    <HStack onClick={onClick} cursor="pointer">
      <Icon name="xMark" className="stroke-gray-500 stroke-2 !cursor-pointer" />
      <Text color="gray.500" fontSize="md" fontWeight="400">
        Exit
      </Text>
    </HStack>
  )
}

type NickelPlansBreadcrumbProps = {
  text: string
  isCurrentPage: boolean
  onClick: () => void
  isDisabled?: boolean
}

function NickelPlansBreadcrumb({
  text,
  isCurrentPage,
  onClick,
  isDisabled,
}: NickelPlansBreadcrumbProps) {
  return (
    <BreadcrumbLink
      fontSize="md"
      isCurrentPage={isDisabled}
      color={isCurrentPage && !isDisabled ? 'gray.900' : 'gray.400'}
      fontWeight={isCurrentPage && !isDisabled ? 'medium' : 'normal'}
      onClick={onClick}
      cursor="!none"
    >
      {text}
    </BreadcrumbLink>
  )
}

function NickelPlansBreadcrumbs(props: NickelPlansBreadcrumbsProps) {
  return (
    <Breadcrumb
      separator={
        <ChevronRightIcon
          className="stroke-gray-400"
          height="14px"
          width="14px"
        />
      }
    >
      <BreadcrumbItem isCurrentPage={props.currentPage === 'plans'}>
        <NickelPlansBreadcrumb
          isCurrentPage={props.currentPage === 'plans'}
          text="Amount"
          onClick={() => props.onPageChange('plans')}
        />
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage={props.currentPage === 'paymentMethod'}>
        <NickelPlansBreadcrumb
          isCurrentPage={props.currentPage === 'paymentMethod'}
          text="Payment Method"
          onClick={() => {
            if (
              props.currentPage !== 'plans' &&
              props.currentPage !== 'receipt'
            ) {
              props.onPageChange('paymentMethod')
            }
          }}
          isDisabled={
            props.currentPage === 'plans' || props.currentPage === 'receipt'
          }
        />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={props.currentPage === 'review'}>
        <NickelPlansBreadcrumb
          isCurrentPage={props.currentPage === 'review'}
          text="Review"
          onClick={() => {
            if (
              props.currentPage !== 'plans' &&
              props.currentPage !== 'paymentMethod' &&
              props.currentPage !== 'receipt'
            ) {
              props.onPageChange('review')
            }
          }}
          isDisabled={
            props.currentPage === 'plans' ||
            props.currentPage === 'paymentMethod'
          }
        />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={props.currentPage === 'receipt'}>
        <NickelPlansBreadcrumb
          isCurrentPage={props.currentPage === 'review'}
          text="Receipt"
          onClick={() => {
            if (
              props.currentPage !== 'plans' &&
              props.currentPage !== 'paymentMethod' &&
              props.currentPage !== 'review'
            ) {
              props.onPageChange('review')
            }
          }}
          isDisabled={
            props.currentPage === 'plans' ||
            props.currentPage === 'paymentMethod' ||
            props.currentPage === 'review'
          }
        />
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

type BreakdownProps = {
  amountCents: number
  feeCents?: number
}

function Breakdown({ amountCents, feeCents }: BreakdownProps) {
  return (
    <Flex flexDirection="column" gap={0}>
      <Text fontWeight="medium" fontSize="gray.800" whiteSpace="nowrap">
        {currency(amountCents, { fromCents: true }).format()}
      </Text>
      {feeCents !== undefined && (
        <Text fontSize="sm" color="gray.500" whiteSpace="nowrap">
          +{currency(feeCents, { fromCents: true }).format()} Fees
        </Text>
      )}
    </Flex>
  )
}

export function NickelPlansHeader(props: NickelPlansHeaderProps) {
  return (
    <Box>
      <Box px={10} py={5}>
        <Flex justify="space-between" align="center" height="40px">
          <GoBack onClick={props.onGoBackClick} />
          <NickelPlansBreadcrumbs
            currentPage={props.currentPage}
            onPageChange={props.onPageChange}
          />
          <Box width="100px">
            {props.breakdown ? (
              <Breakdown
                amountCents={props.breakdown.amountCents}
                feeCents={props.breakdown.feeCents}
              />
            ) : (
              <Box />
            )}
          </Box>
        </Flex>
      </Box>
      <Divider orientation="horizontal" />
    </Box>
  )
}
