import { property, tw } from '@nickeltech/brise'
import ContainerProps from '../../types'

export type SeparatorProps = {
  orientation?: string
} & ContainerProps

export const SeparatorStyle = property(
  'orientation',
  {
    vertical: 'h-full w-[1px] min-w-[1px]',
    horizontal: 'w-full h-[1px] min-h-[1px]',
  },
  'vertical',
)

export const FixedUnitSeparatorStyle = property(
  'orientation',
  {
    vertical: 'w-[1px] min-w-[1px]',
    horizontal: 'h-[1px] min-h-[1px]',
  },
  'vertical',
)

export const FixedUnitSeparator = tw.div<SeparatorProps>`
  bg-gray-200

  ${FixedUnitSeparatorStyle}
`
export const Separator = tw.div<SeparatorProps>`
  bg-gray-200
  
  ${SeparatorStyle}
`

export default Separator
