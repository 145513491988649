import ContainerProps from '../../types'

const VisaDebit = (props: ContainerProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 780 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2958_26408)">
        <path
          d="M40 0H740C762.092 0 780 17.909 780 40V460C780 482.092 762.092 500 740 500H40C17.909 500 0 482.092 0 460V40C0 17.909 17.909 0 40 0Z"
          fill="#0E4595"
        />
        <path
          d="M293.2 348.73L326.561 152.97H379.921L346.536 348.73H293.2ZM539.31 157.19C528.74 153.224 512.173 148.968 491.488 148.968C438.763 148.968 401.623 175.518 401.308 213.571C401.009 241.701 427.822 257.393 448.06 266.757C468.831 276.352 475.812 282.471 475.714 291.04C475.583 304.161 459.128 310.156 443.792 310.156C422.435 310.156 411.089 307.189 393.565 299.88L386.689 296.77L379.2 340.593C391.663 346.057 414.71 350.791 438.638 351.036C494.728 351.036 531.138 324.79 531.554 284.154C531.753 261.885 517.538 244.938 486.753 230.966C468.103 221.911 456.681 215.867 456.802 206.698C456.802 198.561 466.47 189.859 487.359 189.859C504.808 189.589 517.449 193.394 527.297 197.359L532.078 199.619L539.31 157.19ZM676.62 152.967H635.388C622.615 152.967 613.056 156.454 607.447 169.201L528.203 348.601H584.234C584.234 348.601 593.394 324.478 595.466 319.183C601.591 319.183 656.021 319.267 663.804 319.267C665.4 326.12 670.294 348.601 670.294 348.601H719.808L676.62 152.961V152.967ZM611.202 279.377C615.614 268.098 632.462 224.654 632.462 224.654C632.146 225.176 636.841 213.32 639.536 205.97L643.141 222.849C643.141 222.849 653.36 269.578 655.495 279.377H611.202ZM247.902 152.967L195.662 286.467L190.095 259.337C180.37 228.064 150.07 194.182 116.197 177.219L163.963 348.419L220.419 348.355L304.423 152.965H247.902"
          fill="white"
        />
        <path
          d="M146.92 152.96H60.879L60.198 157.033C127.136 173.237 171.428 212.396 189.818 259.443L171.108 169.483C167.878 157.088 158.511 153.389 146.922 152.956"
          fill="#F2AE14"
        />
      </g>
      <defs>
        <clipPath id="clip0_2958_26408">
          <rect width="780" height="500" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VisaDebit
