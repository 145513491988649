import { Grid, GridItem, Spacer, Text, useMediaQuery } from '@chakra-ui/react'
import { Button, Column } from 'ui'
import ctareceipt from '../../public/ctareceipt.png'
import NickelLogo from './NickelLogo'

function DesktopReceiptCTA() {
  return (
    <Grid
      templateColumns="repeat(5, 1fr)"
      gap="4"
      w="100%"
      justifyContent="center"
      alignItems="center"
      bgImage={`url(${ctareceipt})`}
      bgPosition="center"
      borderBottomLeftRadius={4}
      borderBottomRightRadius={4}
      bgColor="#E6E6E6"
      borderRadius={5}
    >
      <GridItem colSpan={3}>
        <Column wGrow>
          <div className="relative left-[20px] top-[10px]">
            <NickelLogo />
          </div>
          <Text
            bgColor="transparent"
            color="brand.800"
            fontSize="xs"
            fontWeight="semibold"
            w="100%"
            py="3"
            px="6"
          >
            Ready to use Nickel for huge savings on ACH and credit card
            processing? It{"'"}s free!
          </Text>
        </Column>
      </GridItem>
      <Spacer />
      <GridItem colSpan={1} justifyContent="center" alignItems="center" pe="6">
        <Button
          size="xs"
          w="90%"
          variant="outline"
          bgColor="transparent"
          label="Schedule a call"
          color="brand.800"
          iconName="calendar"
          iconPosition="left"
          iconBoxSize="4"
          status="primary"
          borderColor="dark.200"
          lineHeight="1"
          onClick={() => {
            window.open('https://calendly.com/nickelhq/nickel-discovery-call')
          }}
        />
      </GridItem>
    </Grid>
  )
}

function MobileReceiptCTA() {
  return (
    <Grid
      templateColumns="repeat(7, 1fr)"
      gap="4"
      w="100%"
      justifyContent="center"
      alignItems="center"
      bgImage={`url(${ctareceipt})`}
      bgPosition="center"
      borderBottomLeftRadius={4}
      borderBottomRightRadius={4}
      bgColor="#E6E6E6"
      p="4"
    >
      <GridItem colSpan={5}>
        <Text
          bgColor="transparent"
          color="brand.800"
          fontSize="10px"
          fontWeight="semibold"
          w="100%"
          lineHeight="1.2"
        >
          Ready to use Nickel for huge savings on ACH and credit card
          processing? It{"'"}s free!
        </Text>
      </GridItem>
      <Spacer />
      <GridItem colSpan={1} justifyContent="center" alignItems="center">
        <Button
          size="xs"
          w="90%"
          variant="outline"
          bgColor="transparent"
          label="Schedule a call"
          color="brand.800"
          iconName="calendar"
          iconPosition="left"
          iconBoxSize="4"
          status="primary"
          borderColor="dark.200"
          lineHeight="1"
          onClick={() => {
            window.open('https://calendly.com/nickelhq/nickel-discovery-call')
          }}
        />
      </GridItem>
    </Grid>
  )
}

function ReceiptCTA() {
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  return <>{isMobile ? <MobileReceiptCTA /> : <DesktopReceiptCTA />}</>
}

export default ReceiptCTA
