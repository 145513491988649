import Icon from '../Icons'
import Row from '../Layout/Row'

export type OverlayHeaderProps = {
  onBack?: () => void
  onClose: () => void
}
export function OverlayHeader({ onBack, onClose }: OverlayHeaderProps) {
  return (
    <Row
      y="center"
      className="px-8 h-[80px] w-full min-h-[80px]"
      between={onBack != undefined}
      x={onBack != undefined ? 'center' : 'right'}
    >
      {onBack != undefined && (
        <Icon
          name="leftArrow"
          size="medium"
          hoverable
          className="stroke-gray-500"
          onClick={onBack}
        />
      )}
      <Icon
        name="xMark"
        size="medium"
        hoverable
        className="stroke-gray-500"
        onClick={onClose}
      />
    </Row>
  )
}
