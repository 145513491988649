import { Flex, Card, Skeleton } from '@chakra-ui/react'
import { InputField, Button } from 'ui'
import { useSendPaymentStore } from '../SendPaymentRouter'
import { useCallback, useEffect, useState } from 'react'
import currency from 'currency.js'
import { PriceInput } from 'ui'

type PaymentAmountProps = {
  loading: boolean
}
export function PaymentAmount({ loading }: PaymentAmountProps) {
  const { setSendMoneyAmountResult, sendMoneyAmountResult, setPage, bill } =
    useSendPaymentStore((state) => ({
      sendMoneyAmountResult: state.sendMoneyAmountResult,
      setSendMoneyAmountResult: state.setSendMoneyAmountResult,
      setPage: state.setPage,
      bill: state.bill,
    }))

  const [amountToSend, setAmountToSend] = useState(
    bill?.billData?.amountDue
      ? currency(bill.billData?.amountDue || 0).value.toString()
      : sendMoneyAmountResult?.submittedAmountCents
      ? currency(sendMoneyAmountResult?.submittedAmountCents, {
          fromCents: true,
        }).value.toString()
      : '',
  )

  const isDisabled = !bill
    ? !sendMoneyAmountResult?.reason || isNaN(parseFloat(amountToSend))
    : isNaN(parseFloat(amountToSend))

  const onClick = useCallback(() => {
    setSendMoneyAmountResult({
      submittedAmountCents: currency(amountToSend || '0').intValue,
      reason: sendMoneyAmountResult?.reason || bill?.billData?.reference || '',
    })
    setPage('paymentMethod')
  }, [amountToSend, sendMoneyAmountResult, setSendMoneyAmountResult, setPage])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !isDisabled) {
        onClick()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [onClick, isDisabled])

  useEffect(() => {
    if (bill?.billData?.amountDue) {
      setAmountToSend(currency(bill.billData?.amountDue).value.toString())
    }
  }, [bill])

  return (
    <Flex width="540px" gap={8} flexDirection="column">
      <Card py={8} px={6}>
        <Flex flexDirection="column" gap={6}>
          {loading ? (
            <Skeleton height="40px" />
          ) : (
            <PriceInput
              label="Amount to send"
              placeholder="$100.00"
              value={amountToSend}
              fontWeight="medium"
              fontSize="sm"
              onPriceChange={(price) => setAmountToSend(price)}
            />
          )}
          {loading ? (
            <Skeleton height="40px" />
          ) : (
            <InputField
              label="Reason for payment"
              placeholder="Invoice #12345"
              fontWeight="medium"
              fontSize="sm"
              value={
                bill?.billData?.reference
                  ? bill?.billData?.reference
                  : sendMoneyAmountResult?.reason || ''
              }
              onChange={(e) =>
                setSendMoneyAmountResult({
                  submittedAmountCents: currency(amountToSend || '0').intValue,
                  reason: e.target.value.replace(/\0/g, ''),
                })
              }
            />
          )}
        </Flex>
      </Card>
      <Button
        label="Next"
        width="100%"
        iconName="wallet"
        iconPosition="right"
        type="submit"
        isDisabled={isDisabled}
        onClick={onClick}
      />
    </Flex>
  )
}
