import Layout from './layout/Layout'
import Login from './Login'

export default function Homepage() {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}
