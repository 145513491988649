import { useMutation, useQuery } from '@apollo/client'
import { Box, useToast } from '@chakra-ui/react'
import currency from 'currency.js'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Row, TableCellProps, TableV2 } from 'ui'
import {
  QuestionForm,
  QuestionFormsDocument,
  QuestionFormSortOrder,
  QuestionFormStatus,
  UpdateQuestionFormDocument,
  User,
} from '../../operations-types'
import { fetchPortalURL } from '../../lib/urls'
import { PaymentLinkOutlet } from './PaymentLinkOutlet'
import { Datum } from 'ui/src/types'
import {
  CheckCircleIcon,
  EnvelopeIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { usePaymentLinkStore } from '../layout/PaymentLinkStore'
import moment from 'moment'
import { useFeatureFlagEnabled } from 'posthog-js/react'

type PaymentLinkTableProps = {
  queryOpts: any
  onPageChange: (page: number) => void
  onSortBy(sortOrder: QuestionFormSortOrder): void
  pageSize: number
}

const getSortBy = (sortOrder: QuestionFormSortOrder) => {
  switch (sortOrder) {
    case QuestionFormSortOrder.CreatedDateAsc:
    case QuestionFormSortOrder.CreatedDateDesc:
      return 'createdAt'
    case QuestionFormSortOrder.DueDateAsc:
    case QuestionFormSortOrder.DueDateDesc:
      return 'dueDate'
    case QuestionFormSortOrder.CompletedAtAsc:
    case QuestionFormSortOrder.CompletedAtDesc:
      return 'completedAt'
  }
}

const getSortOrder = (sortOrder: QuestionFormSortOrder) => {
  switch (sortOrder) {
    case QuestionFormSortOrder.CreatedDateAsc:
    case QuestionFormSortOrder.DueDateAsc:
    case QuestionFormSortOrder.CompletedAtAsc:
      return 'asc'
    case QuestionFormSortOrder.CreatedDateDesc:
    case QuestionFormSortOrder.DueDateDesc:
    case QuestionFormSortOrder.CompletedAtDesc:
      return 'desc'
  }
}

function PaymentLinkTable({
  queryOpts,
  onPageChange,
  onSortBy,
  pageSize,
}: PaymentLinkTableProps) {
  const navigate = useNavigate()
  const { organization } = useOutletContext() as User
  const { loading, data } = useQuery(QuestionFormsDocument, {
    variables: queryOpts,
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  })

  const isArchive = false
  const subdomain = organization?.accountInfo?.subdomain
  const hasQuickbooks = !!organization?.accountInfo?.quickbooksCompanyId
  const status = queryOpts.status

  const toast = useToast()

  function convertUtcToLocal(epochSeconds: number) {
    // Show the date in UTC
    const date = moment(epochSeconds).utc().format('YYYY-MM-DD')
    // Parse the date as a local date
    return moment(date).valueOf()
  }

  const getPaymentLinksData = (questionForms: QuestionForm[]) => {
    return questionForms
      .filter((qF) => qF.status !== 'PERSISTENT')
      .map((qF) => ({
        id: qF.id,
        documents: qF.documents,
        externalId: qF.invoice?.externalId,
        name: qF.name,
        checkoutPath: {
          url: `${fetchPortalURL(subdomain || 'nickel')}/pay/${
            qF.checkoutPath
          }`,
          value: `pay/${qF.checkoutPath}`,
        },
        memo: qF.memo,
        createdAt:
          hasQuickbooks && qF.issueDate
            ? convertUtcToLocal(qF.issueDate)
            : parseInt(qF.createdAt),
        completedAt: qF.completedAt,
        dueDate: qF.dueDate,
        status: {
          label: qF.status,
          color: 'red',
          completedCount: qF.completedCount,
          checkoutActive: qF.checkoutActive,
        },
        requestedAmount: currency(qF?.requestedAmount!, {
          fromCents: true,
        }).format(),
        completedAmountInCents: currency(qF?.completedAmountInCents!, {
          fromCents: true,
        }).format(),
        invoice: qF.invoice,
        loan: qF.loans?.find((loan) => loan?.funded === true),
        recipients: qF.recipients?.map((r) => ({
          name: r.name,
          email: r.email,
          phoneNumber: r.phoneNumber,
          sentAt: r.sentAt,
        })),
      }))
  }

  const totalResults = data?.questionForms?.totalResults || 0
  const totalPages = Math.ceil(totalResults / pageSize)

  const [currentToast, setCurrentToast] = useState('')
  const [headerSelected, setHeaderSelected] = useState(false)
  const {
    setPaymentLinks,
    paymentLinks: storePaymentLinks,
    reset,
  } = usePaymentLinkStore()

  const [updateQuestion, { error }] = useMutation(UpdateQuestionFormDocument, {
    refetchQueries: [
      {
        query: QuestionFormsDocument,
        variables: queryOpts,
      },
    ],
  })
  const [selectedLinksUpdating, setSelectedLinksUpdating] = useState(false)

  const toastOptions = {
    isClosable: false,
    duration: 9999999,
    containerStyle: {
      pb: 8,
    },
    render: () => {
      return (
        <Row gap="small" y="center" className="border p-2 bg-white rounded">
          <Row
            className="px-2 py-1 rounded border border-gray-200 cursor-pointer hover:bg-gray-100"
            y="center"
            x="center"
            gap="small"
            {...{
              onClick: () => {
                toast.closeAll()
                setPaymentLinks([])
                setHeaderSelected(false)
              },
            }}
          >
            <XMarkIcon className="w-3 h-3 stroke-gray-400 relative" />
            <div className="!text-xs text-gray-900">Unselect</div>
          </Row>
          <Row
            className="px-2 py-1 rounded border border-gray-200 cursor-pointer hover:bg-gray-100"
            y="center"
            x="center"
            gap="small"
            disabled={selectedLinksUpdating}
            {...{
              onClick: async () => {
                setSelectedLinksUpdating(true)
                await Promise.all(
                  storePaymentLinks.map(async (qId) =>
                    updateQuestion({
                      variables: {
                        questionFormId: qId.id,
                        checkoutActive: true,
                        status: QuestionFormStatus.Completed,
                      },
                    }),
                  ),
                )

                setPaymentLinks([])
                setSelectedLinksUpdating(false)
              },
            }}
          >
            <CheckCircleIcon className="min-w-3  min-h-3 w-3 h-3 stroke-gray-400" />
            <div className="text-xs text-gray-900">
              Mark {storePaymentLinks.length} as Paid
            </div>
          </Row>
          <Row
            className="px-2 py-1 rounded border bg-sidebar-purple border-sidebar-purple cursor-pointer hover:bg-gray-100 hover:bg-sidebar-purple"
            y="center"
            x="center"
            gap="small"
            {...{
              onClick: () => {
                toast.closeAll()
                navigate(`/dashboard/payment-links/bulk`)
              },
            }}
          >
            <EnvelopeIcon className="!min-w-3 !min-h-3 w-3 h-3 stroke-white" />
            <div className="text-xs text-white">Send Payment Requests</div>
          </Row>
        </Row>
      )
    },
  }

  useEffect(() => {
    if (storePaymentLinks.length === 0) {
      toast.close(currentToast)
    } else {
      if (!toast.isActive(currentToast)) {
        let t = toast(toastOptions)

        setCurrentToast(t as string)
      } else {
        toast.update(currentToast, toastOptions)
      }
    }
  }, [storePaymentLinks, selectedLinksUpdating])

  useEffect(() => {
    if (error) {
      toast({
        status: 'error',
        title: error.message,
      })
    }
  }, [error])

  useEffect(() => {
    reset()
    toast.closeAll()
  }, [])

  const nickelCreditEnabled = useFeatureFlagEnabled('nickelCreditEnabled')

  const table = (
    <TableV2
      {...{
        rowSize: 'large',
        cellSize: 'large',
        className: totalResults === 0 ? 'min-h-[400px]' : '',
        sortBy: getSortBy(queryOpts.sortOrder),
        sortOrder: getSortOrder(queryOpts.sortOrder),
        onSort: (sortBy, sortOrder) => {
          if (sortBy === 'createdAt') {
            if (sortOrder === 'asc') {
              onSortBy(QuestionFormSortOrder.CreatedDateAsc)
            } else {
              onSortBy(QuestionFormSortOrder.CreatedDateDesc)
            }
          }

          if (sortBy === 'dueDate') {
            if (sortOrder === 'asc') {
              onSortBy(QuestionFormSortOrder.DueDateAsc)
            } else {
              onSortBy(QuestionFormSortOrder.DueDateDesc)
            }
          }

          if (sortBy === 'completedAt') {
            if (sortOrder === 'asc') {
              onSortBy(QuestionFormSortOrder.CompletedAtAsc)
            } else {
              onSortBy(QuestionFormSortOrder.CompletedAtDesc)
            }
          }
        },
        headers: [
          status === 'ACTIVE'
            ? {
                type: 'multiSelect',
                keyName: 'id',
                width: 'icon',
                headerCenter: true,
                center: true,
                selected: storePaymentLinks.map((qF) => qF.id),
                headerSelected: headerSelected,
                headerOnCheck: (e: boolean) => {
                  setHeaderSelected(e)
                  setPaymentLinks(
                    e
                      ? getPaymentLinksData(
                          data?.questionForms?.questionForms || [],
                        )
                      : [],
                  )
                },
                onCheck: (data: Datum, e: boolean) => {
                  if (e) {
                    setPaymentLinks([...storePaymentLinks, data])
                  } else {
                    setPaymentLinks(
                      storePaymentLinks.filter((qF) => qF.id !== data.id),
                    )
                  }
                },
              }
            : '',
          {
            type: 'syncedDescription',
            keyName: 'name',
            label: 'Name',
            grow: 1,
          },
          {
            type: 'paymentLinkStatus',
            keyName: 'status',
            label: 'Status',
            width: 'small',
          },
          {
            type: 'date',
            keyName: 'createdAt',
            label: hasQuickbooks ? 'Invoice Date' : 'Created On',
            width: 'base',
            sortable: true,
          },
          {
            type: 'date',
            keyName: 'dueDate',
            label: 'Due Date',
            width: 'base',
            sortable: true,
          },
          status === 'COMPLETED'
            ? {
                type: 'date',
                keyName: 'completedAt',
                label: 'Completed',
                width: 'base',
                sortable: true,
              }
            : null,
          {
            type: 'paymentRequest',
            keyName: 'recipients',
            width: 'large',
            label: 'Requested Payment From',
            onClick: (x: any, y: QuestionForm) => {
              navigate(`/dashboard/get-paid/${y.id}?tab=notifications`)
            },
          },
          {
            type: 'number',
            keyName: 'requestedAmount',
            label: 'Requested',
            width: 'base',
          },
          {
            type: 'number',
            keyName: 'completedAmountInCents',
            label: 'Received',
            width: 'base',
          },
          nickelCreditEnabled
            ? {
                type: 'factorInvoice',
                keyName: 'loan',
                label: 'Financing',
                onClick: (x: any, y: QuestionForm) => {
                  navigate(`/dashboard/get-paid/${y.id}?tab=notifications`)
                },
              }
            : null,
          status !== 'COMPLETED'
            ? {
                type: 'copyLink',
                keyName: 'checkoutPath',
                label: 'Payment Link',
                subdomain: subdomain,
                fit: true,
              }
            : null,

          isArchive ? '' : '',
        ].filter(Boolean) as TableCellProps<object>[],
        data: getPaymentLinksData(data?.questionForms?.questionForms ?? []),
        loading: loading,
        page: queryOpts.page || 1,
        perPage: pageSize,
        onPage: onPageChange,
        totalPages: totalPages || 1,
        onClick: (qF) => {
          navigate(`/dashboard/get-paid/${qF.id}`)
        },
      }}
    />
  )

  return (
    <Box w="100%" h={'100%'}>
      <PaymentLinkOutlet queryOpts={queryOpts} subdomain={subdomain || ''} />
      {table}
    </Box>
  )
}

export default PaymentLinkTable
