import { property, tw } from '@nickeltech/brise'
import ContainerProps, { StatusProps, VariantProps } from '../../types'
import { HTMLProps } from 'react'
import StatusTextColor from '../Indicators'
import { VisibilityTransition, VisibilityTransitionProps } from '../Transitions'

export type LabelProps = {} & ContainerProps &
  HTMLProps<HTMLLabelElement> &
  VariantProps &
  StatusProps &
  VisibilityTransitionProps

export const VariantLabelStyle = property(
  'variant',
  {
    quinary: 'text-xs text-gray-500 font-normal',
    quarternary: 'text-sm font-normal text-gray-500',
    tertiary: 'text-sm font-normal text-gray-500',
    secondary: 'text-sm text-gray-800 font-medium',
    primary: 'text-sm text-gray-800 font-medium',
  },
  'primary',
)

export const Label = tw.label<LabelProps>`
  ${VariantLabelStyle}
  ${StatusTextColor}
  ${VisibilityTransition}
`

export default Label
