import { property, tw } from '@nickeltech/brise'
import ContainerProps from '../../types'
import Row from '../Layout/Row'

export type NotificationVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quarternary'

export type NotificationSize = 'small' | 'base'

export type NotificationProps = {
  variant?: NotificationVariant
  size?: NotificationSize
} & ContainerProps

const VariantMap = {
  primary: 'bg-dark-500 text-white',
  secondary: 'bg-gray-300 text-gray-800',
  tertiary: 'bg-purple-700 text-white',
  quarternary: 'bg-accent-400 text-accent-900',
}

const SizeMap = {
  small: 'w-5 h-5',
  base: 'w-6 h-6',
}

export const NotificationVariant = property('variant', VariantMap, 'primary')
export const NotificationSize = property('size', SizeMap, 'small')

const NotificationContainer = tw<NotificationProps>(Row)`
    rounded-sm
    font-semibold
    text-xs
    ${NotificationVariant}
    ${NotificationSize}
`

export const Notification = ({ children, ...props }: NotificationProps) => {
  return (
    <NotificationContainer {...{ ...props, x: 'center', y: 'center' }}>
      {children}
    </NotificationContainer>
  )
}

export default Notification
