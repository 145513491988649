import { useMutation } from '@apollo/client'
import { Button, Flex, useToast } from '@chakra-ui/react'
import { InviteOwnerDocument } from '../../operations-types'

type AddOwnerProps = {
  onFinish: () => void
}

export function AddOwner({ onFinish }: AddOwnerProps) {
  const [inviteOwner, { loading }] = useMutation(InviteOwnerDocument, {
    onCompleted: () => {
      toast({
        title: "Their inbox has an email to join Nickel 🎉'",
        status: 'success',
        duration: 5000,
      })
      onFinish()
    },
  })
  const toast = useToast()

  return (
    <Flex width="540px" flexDirection="column" gap={6}>
      <Button onClick={() => inviteOwner()} isLoading={loading}>
        Yes
      </Button>
      <Button variant="outline" onClick={onFinish}>
        Not Now
      </Button>
    </Flex>
  )
}
