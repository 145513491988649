import { Box, Fade, Flex, Text } from '@chakra-ui/react'

export type FlowLayoutProps = {
  title: string
  subtitle: string
  currentPage: string
  header: React.ReactNode
  children: React.ReactNode
}

export function FlowLayout({
  currentPage,
  header,
  title,
  subtitle,
  children,
}: FlowLayoutProps) {
  return (
    <Box>
      {header}
      <Fade
        key={currentPage}
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          my={10}
        >
          <Flex flexDirection="column" gap={8}>
            <Flex flexDirection="column" gap={2} width="550px">
              <Text fontSize="2xl" fontWeight="semibold">
                {title}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {subtitle}
              </Text>
            </Flex>
            {children}
          </Flex>
        </Flex>
      </Fade>
    </Box>
  )
}
