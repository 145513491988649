import { useMutation, useQuery } from '@apollo/client'
import { Button, Table } from 'ui'
import {
  GetPaymentsDocument,
  GetPaymentsQueryVariables,
  GetPaymentSummaryDocument,
  TogglePaymentStateDocument,
} from '../../operations-types'
import formatter from '../../lib/formatter'
import { useEffect, useState, useMemo } from 'react'
import PaymentsOverlay from './PaymentsOverlay'
import NotificationArea from '../utils/NotificationArea'
import Notification from '../utils/Notification'
import ErrorNotification from '../utils/ErrorNotification'
import { PaymentHelper } from './PaymentHelper'
import { useNavigate } from 'react-router-dom'
import {
  getPaymentMethodIdentifier,
  reportErrorIfExists,
} from '../../lib/utils'
import { PaymentsTableDropdown } from './PaymentsTableDropdown'
import { Charge, Payment, Payments } from '../../types'
import { Status, StatusCell, StatusMap, getStatus } from './utils'
import { PAYMENTS_URL } from '../../lib/urls'

type PaymentsTableProps = {
  queryOpts: GetPaymentsQueryVariables
  totalSubmittedAmountCents: number
  onPageChange: (page: number) => void
  onDropdownClick: (label: string) => void
  onFilter: (to?: Date, from?: Date, id?: string, customer?: string) => void
}

type PaymentDatum = {
  createdAt: string
  status: StatusCell
  name: string
  notes: string
  amount: number
  reconciliation: { label: string; disabled: boolean }
  availableOn?: { availableOn?: string | null; status?: string | null } | null
  method: { type: string; identifier: string }
  dateSeen: { createdAt: number; seen: boolean }
}

type PaymentData = PaymentDatum & {
  amountInCents: number
  reconciled: boolean
  id: string
}

function getPayoutEstimateOrDate(charge: Charge, paymentMethodType?: string) {
  const feePayout = (charge.fees || []).find(
    (x) => x?.description === 'Charge failure refund',
  )?.payout

  const achReturn = charge.achReturn?.payout

  if (achReturn) {
    return {
      availableOn: achReturn.paidAt,
      status: 'Returned',
      payoutId: achReturn.id,
    }
  }

  if (feePayout) {
    return {
      availableOn: feePayout?.paidAt,
      status: 'Returned',
      payoutId: feePayout?.id,
    }
  }

  if (charge.payout?.paidAt) {
    return {
      availableOn: charge.payout?.paidAt,
      payoutId: charge.payout?.id,
      status: 'Sent',
    }
  }

  if (charge.refunds?.some((x) => x.voided)) {
    return null
  }

  if (paymentMethodType !== 'card') {
    return {
      status: 'Processing',
    }
  }

  if (charge.status === 'succeeded') {
    return {
      status: 'Processing',
    }
  }

  return null
}

function getReconciliationLabel(payment: Payment) {
  return payment.reconciled ? 'Done' : 'Not Started'
}

function isProperStatus(status: string): status is Status {
  return Object.keys(StatusMap).includes(status)
}

function getStatusCell(status: Payment['status']): StatusCell {
  const convertedStatus = getStatus(status)
  if (isProperStatus(convertedStatus)) {
    return StatusMap[convertedStatus]
  }

  return {
    label: convertedStatus,
    status: 'info',
    tooltipText: 'The payment status is unknown.',
  }
}

function getPaymentsData(payments: Payments) {
  const mappedPayments = [...payments].map((payment: Payment) => {
    const paymentHelper = new PaymentHelper(payment)
    return {
      ...payment,
      createdAt: payment.createdAt || '',
      notes: payment.notes || '',
      date: parseInt(payment.createdAt || ''),
      name: payment.paymentMethod?.billingDetails?.name || '',
      amount: paymentHelper.submittedAmountDollars(),
      reconciliation: {
        label: getReconciliationLabel(payment),
        disabled: !!payment.reconciled,
      },
      reconciled: !!payment.reconciled,
      dateSeen: {
        createdAt: parseInt(payment.createdAt || ''),
        seen: payment.seen,
      },
      status: getStatusCell(payment.status),
      method: getPaymentMethodIdentifier(
        payment.paymentMethod,
        !!payment.check,
      ),
      availableOn: (payment.charges || [])
        .map((x) => getPayoutEstimateOrDate(x, payment.paymentMethod?.type))
        .find((x) => x != null),
    }
  })

  return mappedPayments
}

function convertPaymentToCSV(payments: Payments) {
  const mappedPayments = getPaymentsData(payments)
  const csv = [
    'Id,Reference,Created At,Status,Name,Company Name,Address,Notes,Amount Submitted,Amount Charged,Fees,Reconciliation,Payout Available On,Method,Identifier,Paid Out',
  ]

  mappedPayments.forEach((payment) => {
    const paymentHelper = new PaymentHelper(payment)

    const getAddress = (paymentMethod: Payment['paymentMethod']) => {
      if (!paymentMethod) {
        return ''
      }

      const address = paymentMethod.billingDetails?.address

      if (!address) {
        return ''
      }

      return `${address.street1}, ${address.city}, ${address.state}, ${address.zip}`
    }
    csv.push(
      [
        payment.id,
        payment.externalPaymentIntentId,
        `"${new Date(payment.date).toLocaleString()}"`,
        payment.status.label,
        `"${payment.name}"`,
        payment.companyName || '',
        `"${getAddress(payment.paymentMethod)}"`,
        `"${payment.notes}"`,
        payment.amount,
        paymentHelper.chargedAmountDollars(),
        paymentHelper.platformFeeDollars(),
        payment.reconciliation.label,
        payment.availableOn?.availableOn || '',
        payment.method.type,
        payment.method.identifier,
        (payment.charges || []).some((x) => x.payout?.paidAt).toString(),
      ].join(','),
    )
  })
  return csv.join('\n')
}

function downloadDataAsCSV(payments: Payments) {
  const a = document.createElement('a')
  a.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
    convertPaymentToCSV(payments),
  )}`
  a.target = '_blank'
  a.download = 'transactions.csv'
  a.click()
  a.remove()
}

export default function PaymentsTable({
  queryOpts,
  totalSubmittedAmountCents,
  onPageChange,
  onDropdownClick,
  onFilter,
}: PaymentsTableProps) {
  const { loading, data, error } = useQuery(GetPaymentsDocument, {
    variables: queryOpts,
    pollInterval: 30000,
  })

  reportErrorIfExists(data?.payments?.error?.message || error)

  const [paymentsData, setPaymentsData] = useState<PaymentData[]>([])
  const [showNotif, setShowNotif] = useState(false)
  const [notifMessage, setNotifMessage] = useState({
    message: '',
    header: '',
  })
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const navigate = useNavigate()
  const payments = useMemo(() => data?.payments?.payments || [], [data])

  const [toggleState] = useMutation(TogglePaymentStateDocument, {
    onCompleted: () => {},
    refetchQueries: [
      {
        query: GetPaymentSummaryDocument,
      },
    ],
  })

  useEffect(() => {
    const data = getPaymentsData(payments)
    setPaymentsData(data)
  }, [payments])

  const totalResults = data?.payments?.totalResults || 0
  const totalPages = Math.ceil(totalResults / (queryOpts.pageSize || 20))
  const totalString = formatter.format(totalSubmittedAmountCents / 100)

  const table = (
    <Table
      {...{
        title: `Total of ${totalString}`,
        filterComponent: <PaymentsTableDropdown onSubmit={onFilter} />,
        downloadComponent: (
          <Button
            label="Download"
            size="xs"
            variant="outline"
            status="secondary"
            onClick={() => downloadDataAsCSV(payments)}
          />
        ),
        dropdownOnClick: onDropdownClick,
        dropdownOptions: [
          {
            label: 'Date Ascending',
          },
          {
            label: 'Date Descending',
          },
          {
            label: 'Amount Ascending',
          },
          {
            label: 'Amount Descending',
          },
        ],
        headers: [
          {
            type: 'dateSeen',
            keyName: 'dateSeen',
            label: 'Date',
            onClick: (x, y) => {
              toggleState({
                variables: {
                  paymentId: y.id,
                  reconciled: y.reconciled,
                  seen: true,
                },
              })
            },
          },
          {
            type: 'status',
            keyName: 'status',
            label: 'Status',
          },
          {
            type: 'description',
            keyName: 'name',
            label: 'Customer',
            grow: 2,
          },
          {
            type: 'description',
            keyName: 'notes',
            label: 'Order Reference',
            className: 'break-all',
          },
          {
            type: 'paymentMethod',
            keyName: 'method',
            label: 'Method',
          },
          {
            type: 'currency',
            keyName: 'amount',
            label: 'Submitted',
            style: {
              width: '180px',
              minWidth: '180px',
              maxWidth: '180px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              paddingRight: '5rem',
            },
          },
          {
            type: 'button',
            keyName: 'reconciliation',
            label: 'Reconciliation',
            onClick: (x, y) => {
              toggleState({
                variables: {
                  paymentId: y.id,
                  reconciled: !y.reconciled,
                  seen: true,
                },
              })
            },
          },
          {
            type: 'payout',
            keyName: 'availableOn',
            label: 'Payout',
            grow: 2,
          },
        ],
        roundingStyle: 'bottom',
        data: paymentsData,
        page: queryOpts.page || 1,
        perPage: queryOpts.pageSize || 20,
        totalPages: totalPages,
        loading: loading,
        onClick: (e: any) => {
          if (!e.seen) {
            toggleState({
              variables: {
                paymentId: e.id,
                reconciled: e.reconciled,
                seen: true,
              },
            })
          }

          navigate(`${PAYMENTS_URL}/${e.id}`)
        },
        onPage: onPageChange,
      }}
    />
  )

  return (
    <div className="w-full">
      {table}
      <PaymentsOverlay
        onRefunded={(paymentId, amount) => {
          setShowNotif(true)
          setNotifMessage({
            header: 'Success!',
            message: `${paymentId} refunded for ${formatter.format(
              amount,
            )} to customer.`,
          })
        }}
        onError={(paymentId, message) => {
          setErrorMessage(`Couldn't refund payment ${paymentId}: ${message}`)
          setShowError(true)
        }}
        queryOpts={queryOpts}
      />
      <NotificationArea>
        <Notification
          show={showNotif}
          onClose={() => setShowNotif(false)}
          header={notifMessage['header']}
          message={notifMessage['message']}
        />
        <ErrorNotification
          show={showError}
          onClose={() => setShowError(false)}
          errorMessage={errorMessage}
        />
      </NotificationArea>
    </div>
  )
}
