import classNames from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import Column, { UnstandardizedColumn } from '../Layout/Column'
import Row from '../Layout/Row'
import { Body } from '../v2/Typography'

export type OverlayTab = {
  name: string
  component: ReactNode
}

export type OverlayTabsProps = {
  tabs: Array<OverlayTab>
  selected?: number
}

export const OverlayTabs = (props: OverlayTabsProps) => {
  const incomingSelection =
    (props.selected || 0) > props.tabs.length - 1 ? 0 : props.selected || 0

  const [selected, setSelected] = useState(incomingSelection)

  useEffect(() => {
    setSelected(
      (props.selected || 0) > props.tabs.length - 1 ? 0 : props.selected || 0,
    )
  }, [incomingSelection])

  const TabStyle = (index: number) =>
    classNames('py-4', {
      'border-b-2': true,
      'border-l-0': index % 2 !== 0,
      'border-b-black': index === selected,
      'border-b-transparent': index !== selected,
    })

  return (
    <Column wGrow>
      <Row y="center" gap="medium">
        {props.tabs.map((tab, index) => {
          return (
            <UnstandardizedColumn
              onClick={() => setSelected(index)}
              key={index}
              className={TabStyle(index)}
            >
              <Body
                strong={true}
                size="base"
                status={index === selected ? 'default' : 'inactive'}
              >
                {tab.name}
              </Body>
            </UnstandardizedColumn>
          )
        })}
      </Row>
      <Row grow>{props.tabs[selected].component}</Row>
    </Column>
  )
}

export default OverlayTabs
