import { Checkbox, Flex, HStack, Input, VStack } from '@chakra-ui/react'
import { Field, FieldArray } from 'formik'
import { Button, Form, Label, RemoveKebabMenu } from 'ui'
import * as yup from 'yup'
import { QuestionFormSchema } from '../../../operations-types'

type SectionComponentProps = {
  onClick?: (e: any) => void
  setValidationPayload?: (e: any) => void
  validationPayload?: any
  currentSchema?: QuestionFormSchema
}

export function QueryParameters(props: SectionComponentProps) {
  const schema = yup.object().shape({
    query_parameters: yup.array().of(
      yup.object().shape({
        paramName: yup.string().required(),
        display: yup.boolean(),
        label: yup.string(),
      }),
    ),
    newQueryParams: yup.object().shape({
      paramName: yup.string().required(),
      display: yup.boolean(),
      label: yup.string(),
    }),
  })

  return (
    <Flex flexDirection={'column'} gap="4" w="100%">
      <VStack gap="4" w="100%" alignItems="center">
        <Form
          {...{
            validationSchema: schema,
            initialValues: {
              queryParams: props.validationPayload?.query_parameters || [],
              newQueryParams: props.validationPayload?.newQueryParams || {
                paramName: '',
                display: false,
                label: '',
              },
            },
            onChange: async (values) => {
              if (props.setValidationPayload) {
                props.setValidationPayload({
                  ...props.currentSchema,
                  steps: {
                    ...(!!props.currentSchema?.steps?.terms_of_service
                      ?.fileBuffer && {
                      terms_of_service: {
                        ...props.currentSchema?.steps?.terms_of_service,
                      },
                    }),
                    ...(!!props.currentSchema?.steps?.additional_information
                      ?.questions && {
                      additional_information: {
                        ...props.currentSchema?.steps?.additional_information,
                      },
                    }),
                  },
                  query_parameters: values.queryParams,
                  valid: {
                    ...props.validationPayload.valid,
                    query_parameters: true,
                  },
                })
              }
            },
          }}
          className="w-full"
        >
          {(formik) => {
            return (
              <VStack gap="4" w="100%">
                <FieldArray
                  name="queryParams"
                  render={(arrayHelpers) => {
                    return (
                      <VStack gap="4" w="100%">
                        {formik.values.queryParams &&
                          formik.values.queryParams.map(
                            (_: any, index: number) => (
                              <Flex
                                flexDirection={'column'}
                                gap="4"
                                w="100%"
                                key={index}
                              >
                                <HStack
                                  justifyContent={'space-between'}
                                  w="100%"
                                >
                                  <Field
                                    as={Input}
                                    name={`queryParams.${index}.paramName`}
                                    placeholder="Query Parameter"
                                    onChange={formik.handleChange}
                                  />
                                  <Field
                                    as={Input}
                                    name={`queryParams.${index}.label`}
                                    placeholder="Label"
                                    onChange={formik.handleChange}
                                  />
                                  <RemoveKebabMenu
                                    offset={[-70, -30]}
                                    onClick={async () => {
                                      await arrayHelpers.remove(index)
                                    }}
                                  />
                                </HStack>
                                <Flex flexDirection={'row'} gap="2">
                                  <Field
                                    as={Checkbox}
                                    isChecked={
                                      formik.values.queryParams[index]
                                        .display === true
                                    }
                                    name={`queryParams.${index}.display`}
                                    label={'Display'}
                                    onChange={formik.handleChange}
                                  />
                                  <Label>Display</Label>
                                </Flex>
                              </Flex>
                            ),
                          )}

                        <Flex flexDirection={'column'} gap="4" w="100%">
                          <Button
                            iconName="plusCircle"
                            variant="ghost"
                            iconPosition="left"
                            size="xs"
                            label="Add Query Parameter"
                            width={40}
                            cursor={
                              formik.errors.newQueryParams
                                ? 'default'
                                : 'pointer'
                            }
                            {...{
                              onClick: async () => {
                                arrayHelpers.push({
                                  paramName:
                                    formik.values.newQueryParams.paramName,
                                  display: formik.values.newQueryParams.display,
                                  label: formik.values.newQueryParams.label,
                                })
                                await formik.setFieldValue(
                                  'newQueryParams.paramName',
                                  '',
                                )
                                await formik.setFieldValue(
                                  'newQueryParams.label',
                                  '',
                                )
                                await formik.setFieldValue(
                                  'newQueryParams.display',
                                  false,
                                )
                              },
                            }}
                          />
                        </Flex>
                      </VStack>
                    )
                  }}
                />
              </VStack>
            )
          }}
        </Form>
      </VStack>
    </Flex>
  )
}
