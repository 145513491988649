import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '../v3/button/Button'

type RemoveModalProps = {
  removeString: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  removeOnClick?: (_e: any) => void
}
export const RemoveModal = ({
  removeString,
  isOpen,
  onClose,
  onOpen,
  removeOnClick,
}: RemoveModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w="362px"
        containerProps={{
          paddingTop: '6rem',
        }}
        p="4"
      >
        <ModalBody w="100%" borderRadius="sm">
          <Box>
            <VStack w="100%" alignContent="center">
              <Center>
                <Text fontSize="lg">
                  Are you sure you want to remove{' '}
                  <b>
                    {removeString}
                    {'?'}
                  </b>
                </Text>
              </Center>
              <Flex flexDirection="row" w="100%" gap="2" pt="2">
                <Button
                  variant="outline"
                  label="Close"
                  onClick={onClose}
                  w="100%"
                  size="lg"
                />
                <Button
                  onClick={(event) => {
                    event.stopPropagation()
                    !!removeOnClick && removeOnClick(event)
                    onClose()
                  }}
                  label="Remove"
                  w="100%"
                  size="lg"
                />
              </Flex>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default RemoveModal
