import {
  Card,
  CardBody,
  Radio,
  RadioGroup,
  RadioGroupProps,
  VStack,
  Icon,
  Box,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Column, Row } from 'ui'
import { UserRole } from '../../../../operations-types'
import { isActionPermitted } from '../../../layout/LoggedInUser'

import { ROLE_OPTIONS } from './RoleSelectDropdown'

type RoleSelectRadioGroupProps = {
  selectedRole: string
  currentRole: UserRole
  onChange: (_e: any) => void
} & RadioGroupProps

const RoleSelectRadioGroup = (props: RoleSelectRadioGroupProps) => {
  const [value, setValue] = useState(props.selectedRole)

  return (
    <RadioGroup value={value}>
      <VStack w="100%" spacing="2">
        {ROLE_OPTIONS.filter((role) =>
          isActionPermitted(role.value, props.currentRole),
        ).map((role, index) => {
          return (
            <Card
              key={index}
              w="100%"
              p="0"
              m="0"
              onClick={() => {
                setValue(role.value)
                props.onChange(role.value)
              }}
              borderColor="gray.300"
            >
              <CardBody p="0" w="100%" m="0">
                <Row
                  spacing="medium"
                  grow
                  y="center"
                  between
                  className="cursor-pointer hover:bg-gray-100 rounded-md"
                >
                  <Row y="center" gap="medium" className="pe-4">
                    <Box bgColor="gray.100" p="2" borderRadius="6px">
                      <Icon as={role.icon} boxSize="6" />
                    </Box>
                    <VStack alignItems="start" pe="2">
                      <div className="text-sm font-medium text-gray-700">
                        {role.label}
                      </div>
                      <div className="text-xs font-normal text-gray-400">
                        {role.additionalDescription}
                      </div>
                    </VStack>
                  </Row>
                  <Column y="center" x="center">
                    <Radio value={role.value} variant="primary" />
                  </Column>
                </Row>
              </CardBody>
            </Card>
          )
        })}
      </VStack>
    </RadioGroup>
  )
}

export default RoleSelectRadioGroup
