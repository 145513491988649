import { useFormikContext } from 'formik'
import {
  DatePicker,
  SelectInput,
  V2Column as Column,
  V2Row as Row,
  FormButton,
} from 'ui'
import moment from 'moment'
import { Flex } from '@chakra-ui/react'

type DateOptionValue = '30' | '7' | 'today' | 'week' | 'month' | 'year'

type DateOption = {
  label: string
  value: DateOptionValue
}

const SELECT_OPTS: Array<DateOption> = [
  {
    label: 'Last 30 Days',
    value: '30',
  },
  {
    label: 'Last 7 Days',
    value: '7',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'This week',
    value: 'week',
  },
  {
    label: 'This month',
    value: 'month',
  },
  {
    label: 'This year',
    value: 'year',
  },
]

function getDatesForOption(option: DateOptionValue): { to: Date; from: Date } {
  const today = new Date()
  const to = new Date()
  const from = new Date()

  from.setHours(0)
  from.setSeconds(0)
  from.setMinutes(0)
  to.setHours(23)
  to.setMinutes(59)
  to.setSeconds(59)

  switch (option) {
    case '30':
      from.setDate(today.getDate() - 30)
      break
    case '7':
      from.setDate(today.getDate() - 7)
      break
    case 'today':
      break
    case 'week':
      return {
        from: moment().startOf('week').toDate(),
        to: moment().endOf('week').toDate(),
      }
    case 'month':
      return {
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate(),
      }
    case 'year':
      return {
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate(),
      }
  }

  return {
    from,
    to,
  }
}

export function FilterDateComponent() {
  const { values }: { values: { to: Date; from: Date } } = useFormikContext()
  const { setFieldValue } = useFormikContext()

  return (
    <Flex
      flexDirection="column"
      grow={1}
      py={8}
      px={6}
      justifyContent="space-between"
      maxWidth={'100%'}
    >
      <Column gap="medium">
        <SelectInput
          options={SELECT_OPTS}
          hasSuccess={true}
          label="Show Transactions For"
          onChange={(val) => {
            const { from, to } = getDatesForOption(val.value as DateOptionValue)
            setFieldValue('from', from)
            setFieldValue('to', to)
          }}
        />
        <Row gap="small">
          <DatePicker
            label="From"
            value={values.from}
            onChange={(c: Date) => setFieldValue('from', c)}
          />
          <DatePicker
            label="To"
            value={values.to}
            onChange={(c: Date) => setFieldValue('to', c)}
          />
        </Row>
      </Column>
      <FormButton x="right" status="primary" label="Apply" />
    </Flex>
  )
}

export default FilterDateComponent
