import { tw } from '@nickeltech/brise'
import {
  Button,
  Column,
  CopyInput,
  Form,
  OverlayHeaderWithStatus,
  OverlayTitle,
  Row,
  Separator,
  TextInput,
  UserIcon,
} from 'ui'
import ContainerProps from 'ui/src/types'
import {
  HStack,
  Text,
  VStack,
  Icon,
  InputGroup,
  useToast,
  Flex,
  Spacer,
  Box,
} from '@chakra-ui/react'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import {
  QuestionFormDocument,
  RecipientType,
  ResendPaymentLinkToRecipientDocument,
  UpdateRecipientsDocument,
} from '../../operations-types'
import { useMutation, useQuery } from '@apollo/client'
import currency from 'currency.js'
import { FieldArray } from 'formik'
import * as yup from 'yup'
import { fetchPortalURL } from '../../lib/urls'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

type PaymentLinkSuccessOverlayProps = {
  id: string
  organizationSubdomain: string | null | undefined
  onClose?: () => void
  onBack?: () => void
}

const OverlayHeader = tw.div<ContainerProps>`
  text-lg
  font-bold
  text-gray-800
`

type RecipientRowProps = {
  recipient: RecipientType
  onDelete?: () => void
  onResend: () => void
}

type ContactInputFieldsProps = {
  recipientInputName: string
  emailInputName: string
  phoneNumberInputName: string
}

export const SlimmedContactInputFields = (props: ContactInputFieldsProps) => {
  return (
    <Column gap="small" wGrow y="center">
      <Row y="center" gap="small" grow>
        <TextInput
          name={`${props.emailInputName}`}
          placeholder="Enter email of recipient"
          className="placeholder:text-sm text-sm"
        />
        <Text className="text-xs text-gray-400">or</Text>
        <TextInput
          name={`${props.phoneNumberInputName}`}
          placeholder="Phone Number eg: (949) 111-1234"
          type="phone"
          className="placeholder:text-sm text-sm"
        />
      </Row>
    </Column>
  )
}

export const ContactInputFields = (props: ContactInputFieldsProps) => {
  return (
    <Column gap="small" wGrow y="center">
      <TextInput
        name={`${props.recipientInputName}`}
        placeholder="Enter name of recipient"
        className="placeholder:text-sm text-sm"
      />
      <Row y="center" gap="small" grow>
        <TextInput
          name={`${props.emailInputName}`}
          placeholder="Enter email of recipient"
          className="placeholder:text-sm text-sm"
        />
        <Text className="text-xs text-gray-400">or</Text>
        <TextInput
          name={`${props.phoneNumberInputName}`}
          placeholder="Phone Number eg: (949) 111-1234"
          type="phone"
          className="placeholder:text-sm text-sm"
        />
      </Row>
    </Column>
  )
}

export const RecipientRow = (props: RecipientRowProps) => {
  const cleanedPhoneNumber = props.recipient.phoneNumber?.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '($1) $2-$3',
  )

  return (
    <Box
      border="1px solid"
      px="4"
      py="2"
      borderRadius="4px"
      borderColor="gray.200"
      w="100%"
    >
      <HStack>
        <Icon as={UserIcon} boxSize="5" />

        <Flex flexDirection="column" w="100%" py="3px">
          <Flex flexDirection="row" alignItems="start" gap="0" w="100%">
            <Text fontSize="sm" color="gray.800">
              {props.recipient.name}
            </Text>
            <Spacer />
            <Row
              gap="small"
              y="center"
              className="cursor-pointer"
              onClick={() => {
                props.onResend()
              }}
            >
              <div className="text-xs whitespace-nowrap text-purple-500 font-medium">
                Resend
              </div>
              <ArrowRightIcon className="h-4 w-4 text-purple-500 font-medium" />
            </Row>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between">
            <Text fontSize="sm" color="gray.500">
              {props.recipient.email} {props.recipient.phoneNumber && ' • '}
              {cleanedPhoneNumber}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {moment(parseInt(props.recipient.sentAt || '')).format(
                'MM/DD/YYYY hh:mm A',
              )}
            </Text>
          </Flex>
        </Flex>
      </HStack>
    </Box>
  )
}

function PaymentLinkSuccessOverlay(props: PaymentLinkSuccessOverlayProps) {
  const { data } = useQuery(QuestionFormDocument, {
    variables: {
      questionFormId: props.id,
    },
  })

  const toaster = useToast()

  const [addRecipient] = useMutation(UpdateRecipientsDocument, {
    onCompleted: () => {
      toaster({
        status: 'success',
        title: `Payment link sent!`,
      })
    },
    refetchQueries: [
      {
        query: QuestionFormDocument,
        variables: {
          questionFormId: props.id,
        },
      },
    ],
  })

  const [resendPaymentLinktoRecipient] = useMutation(
    ResendPaymentLinkToRecipientDocument,
    {
      onCompleted: () => {
        toaster({
          title: 'Payment link sent!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    },
  )

  return (
    <>
      <Form
        {...{
          className: 'w-full',
          initialValues: {
            contact: data?.questionForm?.questionForm?.recipients || [],
            newContact: {
              name: '',
              phoneNumber: '',
              email: '',
            },
          },
          validationSchema: yup.object().shape({
            newContact: yup.object().shape({
              name: yup.string().required(),
              phoneNumber: yup.string().optional().length(12),
              email: yup.string().optional(),
            }),
            contact: yup.array().of(
              yup.object().shape({
                name: yup.string().required(),
                phoneNumber: yup.string().optional().length(12),
                email: yup.string().optional(),
                semtAt: yup.string().optional(),
              }),
            ),
          }),
        }}
      >
        {(formik) => (
          <>
            <OverlayHeaderWithStatus
              onBack={props.onBack}
              onClose={props.onClose}
            />
            <Separator orientation="horizontal" />
            <Column className="w-full h-full pt-5 overflow-scroll">
              <Row className="w-full" y="center" between>
                <VStack alignItems="left" w="100%" spacing="0" gap="2">
                  <Icon
                    as={CheckBadgeIcon}
                    boxSize="12"
                    mx="8"
                    color="green.500"
                  />
                  <OverlayTitle
                    title="Payment link created!"
                    subtitle={`Successfully created a payment link for ${currency(
                      data?.questionForm?.questionForm?.requestedAmount!,
                      {
                        fromCents: true,
                      },
                    ).format()}`}
                    className="mx-8 pb-4"
                  />
                </VStack>
              </Row>
              <VStack
                w="100%"
                pt="4"
                pb="10"
                gap="0"
                spacing="0"
                alignItems="left"
              >
                <HStack w="100%" gap="0" spacing="0" px="8">
                  <VStack spacing="0" gap="0" alignItems="left" w="100%">
                    <OverlayHeader>
                      Use your payment link to start accepting payments{' '}
                    </OverlayHeader>
                    <Text fontSize="sm" color="gray.500">
                      Copy, paste and share this secure payment link with your
                      customers to start accepting payments
                    </Text>
                  </VStack>
                </HStack>
                <CopyInput
                  px={8}
                  pt={2}
                  clipboardValue={`${fetchPortalURL(
                    props.organizationSubdomain || 'nickel',
                  )}/pay/${data?.questionForm?.questionForm?.checkoutPath}`}
                />
              </VStack>
              <VStack w="100%" gap="0" spacing="0" alignItems="left">
                <HStack w="100%" gap="0" spacing="0" px="8">
                  <VStack spacing="0" gap="0" alignItems="left" w="100%">
                    <OverlayHeader>Send the payment link</OverlayHeader>
                    <Text fontSize="sm" color="gray.500">
                      Add the recipients and we'll send them your payment link
                      via email or text message
                    </Text>
                  </VStack>
                </HStack>
                <FieldArray
                  name="contact"
                  render={(vals) => (
                    <Column wGrow gap="small" className="px-8 py-5">
                      <ContactInputFields
                        recipientInputName="newContact.name"
                        emailInputName="newContact.email"
                        phoneNumberInputName="newContact.phoneNumber"
                      />
                      <InputGroup pt="2" className="w-full">
                        <Button
                          onClick={async () => {
                            await vals.push({
                              name: formik.values.newContact.name,
                              phoneNumber: formik.values.newContact.phoneNumber,
                              email: formik.values.newContact.email,
                              sentAt: new Date().getTime().toString(),
                            })

                            await formik.setFieldValue('newContact', {
                              name: '',
                              phoneNumber: '',
                              email: '',
                            })

                            await addRecipient({
                              variables: {
                                questionFormId: props.id,
                                recipients: formik.values.newContact,
                              },
                            })
                          }}
                          _hover={
                            !formik.values.newContact.name ||
                            (!formik.values.newContact.phoneNumber &&
                              !formik.values.newContact.email)
                              ? {}
                              : {
                                  cursor: 'pointer',
                                  bgColor: 'dark.700',
                                }
                          }
                          className="w-full"
                          label="Send Link"
                          isDisabled={
                            !formik.values.newContact.name ||
                            (!formik.values.newContact.phoneNumber &&
                              !formik.values.newContact.email)
                          }
                        />
                      </InputGroup>
                      <Column spacing="small" wGrow gap="small">
                        {formik.values.contact.map(
                          (contact: RecipientType, index) => (
                            <RecipientRow
                              {...{
                                key: index,
                                recipient: contact,
                                onDelete: async () => {
                                  await vals.remove(index)
                                },
                                onResend: async () => {
                                  await resendPaymentLinktoRecipient({
                                    variables: {
                                      questionFormId: props.id,
                                      recipient: {
                                        name: contact.name || '',
                                        email: contact.email || '',
                                        phoneNumber: contact.phoneNumber || '',
                                      },
                                    },
                                  })
                                },
                              }}
                            />
                          ),
                        )}
                      </Column>
                    </Column>
                  )}
                />
              </VStack>
            </Column>
          </>
        )}
      </Form>
    </>
  )
}

export default PaymentLinkSuccessOverlay
