import { Column } from 'ui'

type Props = {}

const PageNotFound = (_: Props) => {
  return (
    <Column className="h-screen w-full" y="center" x="center">
      <img alt="nickel 404" src="/404.png" className="w-[18rem]" />
    </Column>
  )
}

export default PageNotFound
