import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { BreadcrumbLinkItem } from 'ui'
import { Header } from '../../layout/Header'
import {
  AccountantRegistrationStep,
  useAccountantRegistrationFlow,
} from './registrationFlowStore'

type AccountantBreadcrumbsProps = {
  currentPage: AccountantRegistrationStep
}

function AccountantBreadcrumbs(props: AccountantBreadcrumbsProps) {
  const { setStep } = useAccountantRegistrationFlow((state) => ({
    setStep: state.setStep,
  }))

  return (
    <Breadcrumb
      separator={
        <ChevronRightIcon
          className="stroke-gray-400"
          height="14px"
          width="14px"
        />
      }
    >
      <BreadcrumbItem>
        <BreadcrumbLinkItem
          isCurrentPage={props.currentPage === 'owner'}
          text="Owner Information"
          onClick={() => setStep('owner')}
        />
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLinkItem
          isCurrentPage={props.currentPage === 'business'}
          text="Business Information"
          onClick={() => setStep('business')}
          isDisabled={props.currentPage === 'owner'}
        />
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLinkItem
          isCurrentPage={props.currentPage === 'newClient'}
          text="Add Client"
          onClick={() => {}}
          isDisabled={
            props.currentPage === 'owner' || props.currentPage === 'business'
          }
        />
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

type AccountantHeaderProps = {
  currentPage: AccountantRegistrationStep
}

export function AccountantHeader(props: AccountantHeaderProps) {
  return (
    <Header>
      <AccountantBreadcrumbs currentPage={props.currentPage} />
    </Header>
  )
}
