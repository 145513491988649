import _ from 'lodash'

import { Outlet } from 'react-router-dom'
import { SelfQuery } from '../operations-types'

type User = NonNullable<NonNullable<SelfQuery['user']>['user']>

type LoggedInLayoutProps = {
  loggedInUser: User
}

export function FullscreenLoggedInLayout({
  loggedInUser,
}: LoggedInLayoutProps) {
  return <Outlet context={loggedInUser} />
}
