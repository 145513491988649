import { useEffect, useMemo, useState } from 'react'
import Select, { Option } from 'rc-select'
import debounce from 'lodash/debounce'
import qs from 'qs'
import { tw } from '@nickeltech/brise'
import { useFormikContext } from 'formik'
import Row from '../layout/Row'
import classNames from 'classnames'
import Icon from '../../Icons'
import { UsaStates } from 'usa-states'
import styled from 'styled-components'
import { useToast } from '@chakra-ui/react'
import get from 'lodash/get'

type Props = {
  apiKey: string
  name: string
  autocomplete?: boolean
  setAutocomplete?: (e: boolean) => void
  valueKey?: string
  popupContainerTarget?: string | undefined
  flattenedValues?: boolean
  onChange?: (address: string) => void
  abbreviateStates?: boolean
}

const RemoveHiddenCross = styled.div`
  #search-clear {
    display: none !important;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`

const SMARTY_URL = 'https://us-autocomplete-pro.api.smartystreets.com/lookup'

const SmartyStreetsLookup = (address: string, apiKey: string) =>
  fetch(
    `${SMARTY_URL}?${qs.stringify({
      search: address,
      key: apiKey,
      max_results: 5,
    })}`,
    {
      method: 'GET',
    },
  )

const StyledAddressAutocomplete = tw(Select)`
  outline-none
  w-full
  relative
  text-gray-800
  placeholder:text-gray-500
  max-sm:placeholder:text-base
`

type SmartyStreetsLookupResult = {
  street_line?: string
  city?: string
  state?: string
  zipcode?: string
}

const UNSUPPORTED_EVENTS = ['Unidentified', 'Backspace', 'Delete']

export const AddressAutocomplete = (props: Props) => {
  const [addressResults, setAddressResults] = useState([])

  const {
    errors,
    values,
    setFieldValue,
    setFieldTouched,
    validateForm,
    setFieldError,
  } = useFormikContext()
  const addressValue = get(values, props.name, null)

  const toast = useToast({
    position: 'top',
  })

  const isSuccess = (errors as any)[props.name] && addressValue

  const searchBillingAddress = useMemo(
    () =>
      debounce(async (address) => {
        if (address.length > 0) {
          const results = await SmartyStreetsLookup(address, props.apiKey)
            .then((e) => e.json())
            .then((e) => e)
            .catch((e) => {
              toast({
                status: 'error',
                description: JSON.stringify(e),
              })
            })

          if ((results.suggestions || []).length > 0) {
            let suggestions = results.suggestions

            setAddressResults(suggestions)
          } else {
            setAddressResults([])
          }
        }
      }, 400),
    [],
  )

  useEffect(() => {
    const execute = async () => {
      if (props.autocomplete) {
        await setFieldTouched(props.name, true)
        await setFieldError(props.name, undefined)
        setAddressResults([])
      }
    }

    execute()
  }, [props.autocomplete])

  const getPopupContainer = (triggerNode: any) => {
    return triggerNode.closest(`${props.popupContainerTarget}`)
  }

  return (
    <div className="w-full relative">
      <RemoveHiddenCross>
        <StyledAddressAutocomplete
          {...{
            value: addressValue,
            onKeyDown: (e) => {
              if (!UNSUPPORTED_EVENTS.includes(e.key)) {
                props.setAutocomplete && props.setAutocomplete(false)
                searchBillingAddress(`${addressValue}${e.key}`)
              }
            },
            onChange: (e) => {
              setFieldValue(props.name, e)

              if ((e as string).length === 0) {
                setAddressResults([])
              }
              props.onChange && props.onChange(e as string)
            },
            onBlur: () => {
              setAddressResults([])
            },
            onFocus: () => {
              setAddressResults([])
            },
            getPopupContainer: !!props.popupContainerTarget
              ? getPopupContainer
              : undefined,
            dropdownStyle: {
              border:
                addressResults.length > 0 ? '1px solid rgb(214 211 209)' : '',
              borderRadius: 5,
              cursor: 'pointer',
              fontSize: '14px',
              display: addressResults.length === 0 ? 'none' : 'block',
              position: 'absolute',
              background: 'white',
              margin: '40px auto',
              zIndex: 999,
            },
            onSelect: async (e: any) => {
              let selectedValue = JSON.parse(e)

              const states = new UsaStates()

              setAddressResults([])

              if (props.flattenedValues) {
                await setFieldValue(
                  `${props.valueKey}_streetAddress`,
                  selectedValue.street_line,
                )
                await setFieldTouched(`${props.valueKey}_streetAddress`, true)
                await setFieldError(
                  `${props.valueKey}_streetAddress`,
                  undefined,
                )
                await setFieldValue(
                  `${props.valueKey}_city`,
                  selectedValue.city,
                )
                await setFieldError(`${props.valueKey}_city`, undefined)
                await setFieldTouched(`${props.valueKey}_city`, true)
                await setFieldError(`${props.valueKey}_city`, undefined)
                await setFieldValue(
                  `${props.valueKey}_zipCode`,
                  selectedValue.zipcode,
                )
                await setFieldError(`${props.valueKey}_zipCode`, undefined)
                await setFieldTouched(`${props.valueKey}_zipCode`, true)
                await setFieldValue(
                  `${props.valueKey}_state`,
                  props.abbreviateStates
                    ? states.states.find(
                        (e) => e.abbreviation === selectedValue.state,
                      )?.abbreviation
                    : states.states.find(
                        (e) => e.abbreviation === selectedValue.state,
                      )?.name,
                )
                await setFieldTouched(`${props.valueKey}_state`, true)
                await setFieldError(`${props.valueKey}_state`, undefined)
              } else {
                await setFieldValue(
                  `${props.valueKey}.streetAddress`,
                  selectedValue.street_line,
                )
                await setFieldTouched(`${props.valueKey}.streetAddress`, true)
                await setFieldError(
                  `${props.valueKey}.streetAddress`,
                  undefined,
                )
                await setFieldValue(
                  `${props.valueKey}.city`,
                  selectedValue.city,
                )
                await setFieldError(`${props.valueKey}.city`, undefined)
                await setFieldTouched(`${props.valueKey}.city`, true)
                await setFieldError(`${props.valueKey}.city`, undefined)
                await setFieldValue(
                  `${props.valueKey}.zipCode`,
                  selectedValue.zipcode,
                )
                await setFieldError(`${props.valueKey}.zipCode`, undefined)
                await setFieldTouched(`${props.valueKey}.zipCode`, true)
                await setFieldValue(
                  `${props.valueKey}.state`,
                  props.abbreviateStates
                    ? states.states.find(
                        (e) => e.abbreviation === selectedValue.state,
                      )?.abbreviation
                    : states.states.find(
                        (e) => e.abbreviation === selectedValue.state,
                      )?.name,
                )
                await setFieldTouched(`${props.valueKey}.state`, true)
                await setFieldError(`${props.valueKey}.state`, undefined)
              }

              await validateForm()
            },
            mode: 'combobox',
            allowClear: true,
            placement: 'bottomRight',
            clearIcon: () => null,
            removeIcon: () => null,
            menuItemSelectedIcon: () => null,
            getInputElement: () => (
              <input
                style={{
                  outlineOffset: 0,
                  padding: '8px 12px',
                  paddingLeft: '15px',
                  background: 'transparent',
                }}
                placeholder="Enter street address"
                onAnimationStart={(e) => {
                  if (e.animationName === 'onAutoFillStart') {
                    setAddressResults([])
                  }
                }}
                onAnimationEnd={(e) => {
                  if (e.animationName === 'onAutoFillStart') {
                    setAddressResults([])
                  }
                }}
                className={classNames(
                  'focus:outline-none w-full placeholder:text-gray-400 px-0 pl-1',
                  {
                    'text-[#292524]': isSuccess && addressValue,
                  },
                )}
              />
            ),
            notFoundContent: null,
          }}
        >
          {!props.autocomplete &&
            addressResults.map((aR: SmartyStreetsLookupResult) => (
              <Option
                value={JSON.stringify(aR)}
                className="p-2 hover:bg-gray-200 relative active:bg-gray-200"
                key={JSON.stringify(aR)}
              >
                <Row grow hGrow>
                  {aR.street_line}, {aR.city}, {aR.state} {aR.zipcode}
                </Row>
              </Option>
            ))}
        </StyledAddressAutocomplete>
        <Row grow x="right" className="absolute right-[10px] top-[12px]">
          <Row gap="small" y="center" x="right">
            <Icon name="check" status="success" className="relative" />
          </Row>
          <Row gap="small" y="center" x="right">
            <Icon name="magnifyingGlass" className="relative stroke-gray-500" />
          </Row>
        </Row>
      </RemoveHiddenCross>
    </div>
  )
}

export default AddressAutocomplete
