import { tw } from '@nickeltech/brise'
import { StatusProps, VariantProps } from '../../types'
import { StatusBackgroundStyle } from '.'
import { Size, SizeProps } from '../Layout/Spacing'
import { ColumnProps } from '../Layout/Column'

export type IndicatorProps = ColumnProps &
  SizeProps &
  StatusProps &
  VariantProps

export const Indicator = tw.div<IndicatorProps>`
  flex
  flex-col
  rounded-full
  items-center
  justify-center
  ${StatusBackgroundStyle}
  ${Size}
`

// Background style not standardized
export const UnstandardizedIndicator = tw.div<IndicatorProps>`
  flex
  flex-col
  rounded-full
  items-center
  justify-center
  ${Size}
 `

Indicator.defaultProps = {
  variant: 'primary',
  spacing: 'small',
}

export default Indicator
