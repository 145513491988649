import { Form, Formik } from 'formik'
import moment from 'moment'
import { forwardRef, Ref } from 'react'
import * as yup from 'yup'
import { PaymentLinksFilterDropdown } from './PaymentLinksFilterComponent'

type PaymentLinksFilterDropdownProps = {
  onSubmit: (values: unknown) => void
  formValues: PaymentLinksFilterSchema
}

const Schema = yup.object().shape({
  filterItem: yup
    .string()
    .oneOf([
      'minRequestedAmount',
      'maxRequestedAmount',
      'minCreatedDate',
      'maxCreatedDate',
      'name',
    ])
    .required(),
  minRequestedAmount: yup.string().when('filterItem', {
    is: 'minRequestedAmount',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  maxRequestedAmount: yup.string().when('filterItem', {
    is: 'maxRequestedAmount',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  minCreatedDate: yup.date().when('filterItem', {
    is: 'minCreatedDate',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  maxCreatedDate: yup.date().when('filterItem', {
    is: 'maxCreatedDate',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  name: yup.string().when('filterItem', {
    is: 'name',
    then: yup.string().required(),
    otherwise: undefined,
  }),
})

export type PaymentLinksFilterSchema = yup.InferType<typeof Schema>

const ReffedPaymentLinksFilterDropdownComponentCore = (
  { onSubmit, formValues }: PaymentLinksFilterDropdownProps,
  ref: Ref<HTMLFormElement>,
) => {
  const to = new Date()
  const from = new Date()

  from.setHours(0)
  from.setSeconds(0)
  from.setMinutes(0)
  to.setHours(23)
  to.setMinutes(59)
  to.setSeconds(59)

  return (
    <Formik<PaymentLinksFilterSchema>
      {...{
        initialValues: {
          minRequestedAmount: formValues.minRequestedAmount || undefined,
          maxRequestedAmount: formValues.maxRequestedAmount || undefined,
          minCreatedDate: formValues.minCreatedDate || undefined,
          maxCreatedDate: formValues.maxCreatedDate || undefined,
          name: formValues.name || undefined,
          filterItem: 'name',
        } as PaymentLinksFilterSchema,
        validationSchema: Schema,
        onSubmit: (values, actions) => {
          actions.setSubmitting(true)

          onSubmit({
            ...values,
            minRequestedAmount:
              parseFloat(values.minRequestedAmount || '0') * 100,
            maxRequestedAmount:
              parseFloat(values.maxRequestedAmount || '0') * 100,
          })
          actions.setSubmitting(false)
        },
      }}
    >
      {({ handleSubmit, setFieldValue, setFieldTouched }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            ref={ref as Ref<HTMLFormElement>}
            className="absolute z-50"
          >
            <PaymentLinksFilterDropdown
              label="Filter"
              onClick={(value) => {
                setFieldValue('minCreatedDate', moment().format('MM/DD/YYYY'))
                setFieldValue('maxCreatedDate', moment().format('MM/DD/YYYY'))
                // For some reason I have to do this to prevent stale validation...
                setTimeout(() => setFieldTouched('minCreatedDate'))
                setFieldValue('filterItem', value)
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export const ReffedPaymentLinksFilter = forwardRef(
  ReffedPaymentLinksFilterDropdownComponentCore,
)
