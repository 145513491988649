import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Overlay } from 'ui'
import { REFUNDS_URL } from '../../../lib/urls'

export default function RefundsOverlay() {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(REFUNDS_URL)

  const match = location.pathname.match(
    /\/dashboard\/transactions\/refunds\/(.*)/,
  )

  return (
    <Overlay
      open={match != null}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet />
    </Overlay>
  )
}
