import { Menu } from '@headlessui/react'
import { useFormikContext } from 'formik'

import { FilterBillStringComponent } from '../FilterDropdown/FilterComponent'
import FilterMenu from '../FilterDropdown/FilterMenu'
import { IconName } from '../Icons'
import Label from '../Typography/Label'
import Column from '../v2/layout/Column'
import FormButton from '../v3/button/FormButton'

export type PaymentLinksFilterDropdownProps = {
  label: string
  onClick?: (value: string) => void
}

export type FilterItem = {
  label: string
  value: string
  iconName: IconName
  component: React.ReactNode
}

export function PaymentLinksFilterDropdown({
  label,
  onClick,
}: PaymentLinksFilterDropdownProps) {
  const { submitForm, setSubmitting } = useFormikContext()

  const applyOnClick = async () => {
    await setSubmitting(true)

    await submitForm()

    await setTimeout(async () => {
      setSubmitting(false)
    }, 3000)
  }

  const items: FilterItem[] = [
    {
      label: 'Name',
      value: 'name',
      iconName: 'magnifyingGlass',
      component: (
        <Column wGrow gap="medium">
          <FilterBillStringComponent
            fieldName="name"
            label="Payment Link Name"
            placeholder="Enter Payment Link Name"
          />
        </Column>
      ),
    },
    {
      label: 'Amount Requested Value',
      value: 'maxRequestedAmount',
      iconName: 'currencyDollar',
      component: (
        <Column gap="medium" wGrow spacingX="medium" spacingY="medium">
          <Column wGrow gap="small">
            <Label>Minimum Requested Amount</Label>
            <FilterBillStringComponent
              fieldName="minRequestedAmount"
              label="Minimum Requested Amount"
              type="currency"
              placeholder="Enter Minimum Requested Amount"
            />
          </Column>
          <Column wGrow gap="small">
            <Label>Maximum Requested Amount</Label>
            <FilterBillStringComponent
              fieldName="maxRequestedAmount"
              label="Maximum Requested Amount"
              type="currency"
              placeholder="Enter Maximum Requested Amount"
            />
          </Column>
          <Column grow wGrow y="bottom">
            <FormButton
              x="right"
              status="primary"
              label="Apply"
              onClick={applyOnClick}
            />
          </Column>
        </Column>
      ),
    },
    {
      label: 'Created On Date',
      value: 'minCreatedDate',
      iconName: 'calendar',
      component: (
        <Column gap="medium" wGrow spacingX="medium" spacingY="medium">
          <Column gap="small" wGrow>
            <Label>Created on Start Date</Label>
            <FilterBillStringComponent
              fieldName="minCreatedDate"
              label="Created on Start Date"
              type="date"
            />
          </Column>
          <Column gap="small" wGrow>
            <Label>Created on End Date</Label>
            <FilterBillStringComponent
              fieldName="maxCreatedDate"
              label="Created On End Date"
              type="date"
            />
          </Column>
          <Column grow wGrow y="bottom">
            <FormButton
              x="right"
              status="primary"
              label="Apply"
              onClick={applyOnClick}
            />
          </Column>
        </Column>
      ),
    },
  ]

  return (
    <Menu.Items className="absolute left-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 focus:outline-none min-w-fit">
      <FilterMenu items={items} selected={0} onClick={onClick} />
    </Menu.Items>
  )
}
