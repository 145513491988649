import { Alert, AlertIcon, Flex, Spacer, Text, Divider } from '@chakra-ui/react'

import { Button } from 'ui'

export type DashboardBannerProps = {
  text?: string
  buttonLabel?: string
  buttonOnClick?: () => void
  status?: 'info' | 'warning' | 'success' | 'error'
}

const statusColorMap = {
  info: 'blue',
  warning: 'yellow',
  success: 'green',
  error: 'red',
}
export function DashboardBanner({
  text,
  buttonLabel,
  buttonOnClick,
  status = 'warning',
}: DashboardBannerProps) {
  return (
    <>
      <Flex flexDirection="row" display="inline-flex">
        <Alert status={status} colorScheme={statusColorMap[status]}>
          <AlertIcon />
          <Text fontSize="xs">{text}</Text>
          <Spacer />
          {buttonLabel && (
            <Button label={buttonLabel} size="xs" onClick={buttonOnClick} />
          )}
        </Alert>
      </Flex>
      <Divider borderWidth="1px" />
    </>
  )
}
