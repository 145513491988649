import { styles, tw } from '@nickeltech/brise'
import StatusTextColor, { StatusBackgroundStyle } from '.'
import { VariantProps } from '../../types'
import Icon, { IconName } from '../Icons'
import Row, { RowProps } from '../Layout/Row'
import Spacing, { SizeProps } from '../Layout/Spacing'
import { VisibilityTransition, VisibilityTransitionProps } from '../Transitions'
import Caption from '../Typography/Caption'

export type OutlineProps = {
  outline?: boolean
}

export type RoundedProps = {
  rounded?: boolean
}

export type StatusPillProps = {
  onClose?: (e: any) => void
  icon?: IconName
  iconPosition?: 'left' | 'right'
} & RowProps &
  VisibilityTransitionProps &
  OutlineProps &
  VariantProps &
  SizeProps

export const OutlineStyle = styles`
  ${({ outline = false }: OutlineProps) => (outline ? '!bg-white' : '')}`

export const RoundedStyle = styles`
  ${({ rounded = false }: RoundedProps) =>
    rounded ? 'rounded-full' : 'rounded'}
`

const StatusPillContainer = tw<StatusPillProps>(Row)`
  w-fit
  justify-center
  ${RoundedStyle}
  ${OutlineStyle}
  ${VisibilityTransition}
  ${StatusBackgroundStyle}
  ${StatusTextColor}
  ${Spacing}
`

export const StatusPillSizeMap = {
  small: {
    container: {
      spacing: 'smallNarrow',
    },
    icon: {
      size: 'xSmall',
    },
    caption: {},
  },
}

export const StatusPill = (props: StatusPillProps) => {
  return (
    <StatusPillContainer
      x="center"
      y="center"
      gap="xsmall"
      spacing="smallNarrow"
      {...props}
    >
      {props.onClose ? (
        <Icon name="xMark" variant="secondary" status={props.status} />
      ) : null}
      {props.icon && props.iconPosition === 'left' ? (
        <Icon variant="secondary" name={props.icon} status={props.status} />
      ) : null}
      <Caption status={props.status} variant="secondary">
        {props.children}
      </Caption>
      {props.icon && props.iconPosition === 'right' ? (
        <Icon variant="secondary" name={props.icon} status={props.status} />
      ) : null}
    </StatusPillContainer>
  )
}

export default StatusPill
