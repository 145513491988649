import { useMutation } from '@apollo/client'
import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { CopyInput, FormButton, FormikValidatingInput } from 'ui'
import * as yup from 'yup'
import { InviteCustomerDocument } from '../../../operations-types'

type NewCustomerModalProps = {
  isOpen: boolean
  onClose: () => void
}

const InviteSchema = yup.object().shape({
  companyName: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
})

type ModalStates = 'invite' | 'success'

export function NewCustomerModal({ isOpen, onClose }: NewCustomerModalProps) {
  const toast = useToast()

  const [modalState, setModalState] = useState<ModalStates>('invite')
  const [inviteUrl, setInviteUrl] = useState<string | null>(null)
  const [invitedEmail, setInvitedEmail] = useState<string | null>(null)

  const [inviteCustomer] = useMutation(InviteCustomerDocument, {
    onCompleted: (data, options) => {
      if (data.inviteCustomer?.url) {
        setModalState('success')
        setInviteUrl(data.inviteCustomer.url)
        setInvitedEmail(options?.variables?.email ?? null)
      } else {
        toast({
          title: 'Error',
          description: 'There was an error inviting the customer',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
  })

  const modalStateToComponent: Record<ModalStates, JSX.Element> = {
    invite: (
      <>
        <Text fontSize="xl" fontWeight="semibold" color="gray.800" pt={4}>
          Invite your Customer
        </Text>
        <Text fontSize="medium" color="gray.500">
          Invite your customer to fill out a credit application.
        </Text>
        <Formik
          initialValues={{
            companyName: '',
            email: '',
          }}
          validationSchema={InviteSchema}
          onSubmit={(values) => {
            inviteCustomer({
              variables: {
                organizationName: values.companyName,
                email: values.email,
              },
            })
          }}
        >
          <Form>
            <Box py={4}>
              <Flex flexDirection={'column'} gap={4}>
                <FormikValidatingInput
                  label="Company Name"
                  fieldName="companyName"
                  placeholder="Enter Company Name"
                />
                <FormikValidatingInput
                  label="Applicant Email"
                  fieldName="email"
                  placeholder="Enter Email"
                />
                <FormButton label="Invite" className="w-full" />
              </Flex>
            </Box>
          </Form>
        </Formik>
      </>
    ),
    success: (
      <Flex flexDirection="column" gap={2} py={4}>
        <ModalCloseButton />
        <Text fontSize="xl" fontWeight="semibold" color="gray.800">
          Success
        </Text>
        <Text fontSize="medium" color="gray.800">
          An invite to fill out the credit application was sent to{' '}
          <b>{invitedEmail}</b>. You can also share the link below with your
          customer.
        </Text>
        <CopyInput clipboardValue={inviteUrl!} size="sm" />
      </Flex>
    ),
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setModalState('invite')
      }}
    >
      <ModalOverlay />
      <ModalContent px={4}>{modalStateToComponent[modalState]}</ModalContent>
    </Modal>
  )
}
