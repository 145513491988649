import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'

export type PaperPlaneIconProps = ContainerProps & { success?: boolean }

export const PaperPlane = tw((props: PaperPlaneIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_369_1014)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62847 0.852559C9.49451 0.858231 9.36109 0.882114 9.23003 0.925801C7.19003 1.60605 4.03418 2.48124 1.84868 3.20949C1.28993 3.39624 0.897517 3.90177 0.855517 4.48977C0.813517 5.07777 1.131 5.63348 1.65825 5.89748L4.62163 7.37844L6.10259 10.3418C6.36659 10.8691 6.9223 11.1866 7.5103 11.1446C8.0983 11.1026 8.60383 10.7101 8.79058 10.1514C9.51883 7.96589 10.394 4.81004 11.0743 2.77004C11.249 2.24579 11.112 1.66883 10.7212 1.27883C10.4287 0.985766 10.0303 0.835543 9.62847 0.852559ZM9.76763 1.98488C9.82669 1.99876 9.8812 2.02961 9.92583 2.07424C10.0151 2.16349 10.0461 2.29483 10.0064 2.41408C9.32615 4.45483 8.45095 7.60993 7.7227 9.79543C7.67996 9.92368 7.56474 10.0127 7.42974 10.0225C7.29549 10.0315 7.16894 9.96013 7.10894 9.83938L5.72466 7.07082L7.6729 5.12258C7.8919 4.90283 7.8919 4.54692 7.6729 4.32717C7.45315 4.10817 7.09724 4.10817 6.87749 4.32717L4.92925 6.27541L2.16069 4.89113C2.03994 4.83113 1.96859 4.70458 1.97759 4.57033C1.98734 4.43533 2.07639 4.32011 2.20464 4.27736C4.39014 3.54911 7.54524 2.67392 9.58599 1.99367C9.64561 1.9738 9.70856 1.97101 9.76763 1.98488Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_369_1014">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
))`
`
export default PaperPlane
