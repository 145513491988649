import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'
import { Column, Row } from 'ui'
import ContainerProps from 'ui/src/types'
import Banner from './Banner'

type Props = {
  employeeCount: number
  isAdmin?: boolean
} & ContainerProps

const BannerContainer = tw.div<ContainerProps>`
  w-full
  relative
  min-h-[100px]
  border-none
  flex
  flex-col
  items-center
  justify-center
`

const UpgradeDescriptionText = tw.div<ContainerProps>`
  text-sm
  font-bold
  text-accent-400
`

const UpgradeTitleText = tw.div<ContainerProps>`
  text-sm
  font-medium
  text-white
  whitespace-nowrap
`

const UpgradeButton = tw((props: ContainerProps) => {
  return (
    <Row
      y="center"
      x="center"
      gap="medium"
      {...props}
      spacing="small"
      className="hover:bg-gray-100 bg-gray-200 border rounded-md"
    >
      <div className="text-gray-900 text-xs font-semibold">Upgrade</div>
      <ArrowRightIcon className="stroke-gray-900 w-4 stroke-2" />
    </Row>
  )
})`
  cursor-pointer
  border
  border-gray-500
  bg-white
  rounded
`

const UpgradeBanner = (props: Props) => {
  return (
    <BannerContainer>
      <Banner className="absolute rounded-md min-w-full max-h-full" />
      <Row grow className="z-10 absolute p-4" y="center">
        <Column y="center" wGrow>
          <UpgradeTitleText>
            You're using {props.employeeCount} of 3 seats included in your free
            account.
          </UpgradeTitleText>
          <UpgradeDescriptionText>
            Want unlimited seats? Upgrade to Nickel Pro
          </UpgradeDescriptionText>
        </Column>
        {props.isAdmin && <UpgradeButton {...props} />}
      </Row>
    </BannerContainer>
  )
}

export default UpgradeBanner
