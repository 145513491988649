import React from 'react'
import { Header, LoggedInHeader } from '../Header'

export default function Layout({
  children,
  loggedIn,
}: {
  children: React.ReactNode
  loggedIn?: boolean
}) {
  return (
    <div
      className="md:px-8"
      style={{
        backgroundImage: `url('/nickel-hero-bg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        zIndex: -1,
      }}
    >
      <main className="min-h-screen">
        <div className="py-6 px-4 md:py-12 md:px-6">
          {loggedIn ? <LoggedInHeader /> : <Header />}
          <div className="flex justify-center">{children}</div>
        </div>
      </main>
    </div>
  )
}

export function BlankLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="md:px-8">
      <main className="min-h-screen">
        <div className="py-6 px-4 md:py-12 md:px-6">
          <LoggedInHeader />
          <div className="flex justify-center">{children}</div>
        </div>
      </main>
    </div>
  )
}
