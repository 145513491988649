import { tw } from '@nickeltech/brise'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import * as useHooks from 'usehooks-ts'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import omit from 'lodash/omit'

import ContainerProps from '../../types'
import Form from '../Form'
import Column from '../Layout/Column'
import Row from '../Layout/Row'
import max from 'lodash/max'
import currency from 'currency.js'

export type SavingsCalculatorPostParams = {
  annualVolume: number
  averageTransactionValue: number
  paymentType: string
}

export type SavingsCalculatorResponse = {
  data: Record<string, number>
}

export type SavingsCalculatorProps = {
  onSubmit: (
    params: SavingsCalculatorPostParams,
  ) => Promise<SavingsCalculatorResponse>
  initialParams: SavingsCalculatorPostParams
}

export type PricingBarProps = {
  value?: number
  label?: string
  name?: string
  maxValue?: number
  maxWidth?: number
  key?: string
  paymentType?: string
} & ContainerProps

export const DescriptionText = tw.div<ContainerProps>`
  text-gray-600
  text-sm
  text-center
`

export const PricingBar = tw((props: PricingBarProps) => {
  let maxValueToSubtract =
    props.paymentType === 'ach_debit'
      ? 0
      : (props.maxValue || 0) * ((props.maxValue || 0) < 4000 ? 0.9 : 0.5)
  let currentValue =
    (props.value || 0) - (props.name === 'nickel' ? 90 : 0) || 0

  let calculatedWidth = Math.round(
    ((currentValue - maxValueToSubtract) /
      ((props.maxValue || 0) - maxValueToSubtract || 1)) *
      (props.maxWidth || 1),
  )

  return (
    <Row y="center" gap="small" className="max-w-[100%]">
      <motion.div
        {...props}
        animate={{
          width: `${calculatedWidth < 0 ? 10 : calculatedWidth}px`,
        }}
      />
      <PricingDetail
        {...{
          label: props.label || '',
          value: currency(props.value || 0)
            .format({
              precision: 0,
              fromCents: false,
            })
            .split('.')[0],
        }}
      />
    </Row>
  )
})`
  h-[60px]
  ${(props) => {
    if (props.name === 'nickel') {
      return `
        bg-[#E2FB70]
      `
    } else {
      return `
        bg-[#E3E1E0]
      `
    }
  }}
`

export type PricingDetailProps = {
  label: string
  value: string
}

export const PricingDetailText = tw.div<ContainerProps>`
  font-normal
  text-sm
  md:text-xl
  text-black
`

export const SmallPricingDetailText = tw(PricingDetailText)`
  font-normal
  text-[10px]
  md:text-xs
  text-black
  whitespace-nowrap
`

export const PricingDetail = (props: PricingDetailProps) => {
  return (
    <Column>
      <PricingDetailText>{props.value}</PricingDetailText>
      <SmallPricingDetailText>{props.label}</SmallPricingDetailText>
    </Column>
  )
}

export type PricingInputProps = {
  placeholder: string
  value: number
  onChange: (value: string) => Promise<void>
  label: string
} & ContainerProps

export type ArrowUpProps = {
  onClick: (value: number) => Promise<void>
  disabled?: boolean
} & ContainerProps

export type ArrowProps = ContainerProps & {
  disabled?: boolean
}

export const ArrowUpComponent = tw.div<ArrowProps>`
  w-[0px]
  h-[0px]
  border-l-[5px]
  border-r-[5px]
  border-b-[5px]
  border-l-transparent
  border-r-transparent
  border-b-gray-300

  ${(props) => {
    if (props.disabled) {
      return `opacity-50`
    } else {
      return ``
    }
  }}
`

export const ArrowUp = tw((props: ArrowUpProps) => {
  return (
    <Column
      {...{
        className: props.className,
        onClick: props.onClick,
      }}
    >
      <ArrowUpComponent disabled={props.disabled} />
    </Column>
  )
})`
  p-2
  border-l
  cursor-pointer
  hover:bg-gray-200
`

export type ArrowDownProps = {
  disabled?: boolean
  onClick: (value: number) => Promise<void>
} & ContainerProps

export const ArrowDownComponent = tw.div<ArrowProps>`
  w-[0px]
  h-[0px]
  border-l-[5px]
  border-r-[5px]
  border-t-[5px]
  border-l-transparent
  border-r-transparent
  border-t-gray-300

  ${(props) => {
    if (props.disabled) {
      return `opacity-50`
    } else {
      return ``
    }
  }}
`

export const ArrowDown = tw((props: ArrowDownProps) => {
  return (
    <div {...props}>
      <ArrowDownComponent disabled={props.disabled} />
    </div>
  )
})`
  p-2
  border-l
  border-t
  cursor-pointer
  hover:bg-gray-200

  ${(props) => {
    if (props.disabled) {
      return `pointer-events-none`
    } else {
      return ``
    }
  }}
`

export const PricingLabel = tw.div<ContainerProps>`
  text-sm
  text-black
  font-medium
  text-uppercase
`

export const PricingInputComponent = tw(
  (props: CurrencyInputProps & ContainerProps & { label: string }) => {
    return (
      <CurrencyInput
        {...omit(props, 'ref')}
        defaultValue={props.value as string}
        step={1}
        min={0}
        prefix="$"
        max={100000000}
      />
    )
  },
)`
  border
  focus:outline-none
  !placeholder:text-gray-200
  text-sm
  p-2
  w-full
  !pl-4
`

const ProductText = tw.div<ContainerProps>`
  text-gray-900
  text-md
`

export const PricingInput = tw((props: PricingInputProps) => {
  return (
    <Column gap="small" className="w-full">
      <PricingLabel>{props.label}</PricingLabel>
      <div
        {...{
          className: props.className,
          style: {
            height: '43px',
          },
        }}
      >
        <PricingInputComponent
          {...{
            onValueChange: async (e) => {
              await props.onChange(e || '')
            },
            style: {
              height: '43px',
            },
            value: props.value,
            placeholder: props.placeholder,
            label: props.placeholder,
          }}
        />
        <Column className="absolute top-0 right-0">
          <ArrowUp
            {...{
              onClick: async () => {
                await props.onChange(
                  (
                    props.value +
                    (props.label === 'Annual Payments Volume' ? 50000 : 1000)
                  ).toString(),
                )
              },
            }}
          />
          <ArrowDown
            {...{
              disabled: props.value === 0,
              onClick: async () => {
                if (
                  props.value >=
                  (props.label === 'Annual Payments Volume' ? 50000 : 1000)
                ) {
                  await props.onChange(
                    (
                      props.value -
                      (props.label === 'Annual Payments Volume' ? 50000 : 1000)
                    ).toString(),
                  )
                }
              },
            }}
          />
        </Column>
      </div>
    </Column>
  )
})`
  w-full
  relative
  !min-h-[43px]
`

export type PricingTab = {
  label: string
  value: string
  subtitle?: string
  selected?: boolean
} & ContainerProps

export const PricingTabFields: PricingTab[] = [
  {
    label: 'ACH & Bank Transfers',
    value: 'ach_debit',
    subtitle: 'With Nickel, you can receive instant payouts',
  },
  {
    label: 'Credit Card',
    value: 'credit_card',
    subtitle:
      'With Nickel, you can pass on the credit card fee to your customer',
  },
]

export type PricingTabsProps = {
  selectedTab: string
  tabs: PricingTab[]
  setSelectedTab: (value: string) => void
}

export const PricingTabComponent = tw.div<PricingTab>`
  md:text-md
  text-gray-800
  ${(props) =>
    props.selected
      ? 'border-b-gray-800 bg-white'
      : 'border-b-transparent bg-gray-100 text-gray-500'}
  text-center
  cursor-pointer
  flex
  flex-grow
  flex-row
  p-4
  border-b-2
  pb-3
  pl-6
  font-semibold
  rounded-t-lg
  border-l
  border-r
  border-t
  text-xs
`

export const PricingTabs = (props: PricingTabsProps) => {
  return (
    <Row y="center" grow gap="small">
      {props.tabs.map((tab) => (
        <PricingTabComponent
          key={tab.value}
          selected={tab.value === props.selectedTab}
          label={tab.label}
          value={tab.value}
          onClick={() => props.setSelectedTab(tab.value)}
        >
          {tab.label}
        </PricingTabComponent>
      ))}
    </Row>
  )
}

export const SavingsCalculatorContainer = tw(Column)`
  w-full
  bg-gray-100
  border
  border-gray-200
  rounded-b-lg
`

export const Product: PricingBarProps[] = [
  {
    label: 'Nickel',
    name: 'nickel',
  },
  {
    label: 'Quickbooks Pay',
    name: 'quickbooks_pay',
  },
  {
    label: 'Industry Average',
    name: 'industry_average',
  },
]

export const SavingsCalculator = (props: SavingsCalculatorProps) => {
  const [calculatedValue, setCalculatedValue] =
    useState<SavingsCalculatorResponse>()

  const barContainerRef = useRef<HTMLDivElement>(null)

  const [barContainerWidth, setBarContainerWidth] = useState(0)

  const { width } = useHooks.useWindowSize()

  useEffect(() => {
    if (barContainerRef.current) {
      setBarContainerWidth(barContainerRef.current.clientWidth)
    }
  }, [barContainerRef.current, width])

  return (
    <Form
      {...{
        initialValues: {
          annualVolume: props.initialParams?.annualVolume,
          averageTransactionValue: props.initialParams?.averageTransactionValue,
          paymentType: props.initialParams?.paymentType,
        },
        validationSchema: yup.object().shape({
          annualVolume: yup.number().required(),
          averageTransactionValue: yup.number().required(),
          paymentType: yup.string().required(),
        }),
        onChange: async (values) => {
          if (values.annualVolume > 0 && values.averageTransactionValue > 0) {
            const data = await props.onSubmit(
              values as SavingsCalculatorPostParams,
            )

            setCalculatedValue(data)
          } else {
            setCalculatedValue({
              data: Object.fromEntries(Product.map((e) => [e.name, 0])),
            })
          }
        },
      }}
    >
      {(props) => (
        <Column x="center">
          <PricingTabs
            {...{
              selectedTab: props.values.paymentType || 'ach_debit',
              tabs: PricingTabFields,
              setSelectedTab: (e) => props.setFieldValue('paymentType', e),
            }}
          />
          <SavingsCalculatorContainer>
            <Row grow className="flex-col">
              <Row spacing="medium" className="border-b w-full" grow>
                <PricingInput
                  {...{
                    onChange: async (e) => {
                      if (e === '') {
                        await props.setFieldValue('annualVolume', 0)
                      } else {
                        await props.setFieldValue(
                          'annualVolume',
                          parseInt(e) || 0,
                        )
                      }
                    },
                    placeholder: 'Annual Payments Volume',
                    label: 'Annual Payments Volume',
                    value: props.values.annualVolume,
                  }}
                />
              </Row>
              <Row grow spacing="medium" className="border-b">
                <PricingInput
                  {...{
                    onChange: async (e: string) => {
                      if (e === '') {
                        await props.setFieldValue('averageTransactionValue', 0)
                      } else {
                        await props.setFieldValue(
                          'averageTransactionValue',
                          parseInt(e) || 0,
                        )
                      }
                    },
                    placeholder: 'Average Transaction Value',
                    value: props.values.averageTransactionValue,
                    label: 'Average Transaction Value',
                  }}
                />
              </Row>
            </Row>
            <Column
              gap="medium"
              className="p-4"
              wGrow
              forwadedRef={barContainerRef}
            >
              {props.values.annualVolume > 0 &&
              props.values.averageTransactionValue > 0 ? (
                <>
                  <ProductText>
                    Calculate your payment processing fees on Nickel vs.
                    alternatives
                  </ProductText>
                  {Product.map((prod) => (
                    <PricingBar
                      {...{
                        value:
                          (calculatedValue &&
                            calculatedValue.data[prod.name || '']) ||
                          0,
                        label: prod.label,
                        name: prod.name,
                        maxValue:
                          (max(Object.values(calculatedValue?.data || {})) ||
                            0) +
                          (max(Object.values(calculatedValue?.data || {})) ||
                            0) *
                            0.1,
                        maxWidth: barContainerWidth,
                        paymentType: props.values.paymentType,
                      }}
                      key={prod.name}
                    />
                  ))}
                </>
              ) : (
                <Column
                  y="center"
                  x="center"
                  className="w-full !h-[185px]"
                  grow
                >
                  <DescriptionText>
                    Enter your annual payments volume and average transaction
                  </DescriptionText>
                </Column>
              )}
            </Column>
          </SavingsCalculatorContainer>
        </Column>
      )}
    </Form>
  )
}

export default SavingsCalculator
