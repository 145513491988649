import { Divider, Flex, Text, Box } from '@chakra-ui/react'
import { CheckCircleIcon, MapPinIcon } from '@heroicons/react/24/solid'
import { MapPinIcon as MapPinIconOutline } from '@heroicons/react/24/outline'
import { Card, Column, Icon, IconName, Row } from 'ui'

type PaymentMethodProps = {
  type: string
  identifier: string
  text?: string
}

type PayoutMethodProps = {
  type?: string | null | undefined
  identifier?: string | null | undefined
  text?: string | null | undefined
}

type PaymentTimelineProps = {
  paymentMethod: PaymentMethodProps
  payoutMethod: PayoutMethodProps
  completed?: boolean
}

const iconToTypeMap: Record<string, IconName> = {
  creditCard: 'creditCard',
  bankAccount: 'buildingLibrary',
  checkDeposit: 'moneyCheck',
}

function PaymentMethodRow({ type, identifier, text }: PaymentMethodProps) {
  return (
    <Row y="center" className="gap-3">
      <CheckCircleIcon className="h-4 w-4" />
      <Row gap="small" y="center">
        <Icon name={iconToTypeMap[type]} />
        <Text color="gray.800" fontSize="sm">
          {identifier}
        </Text>

        {text && (
          <Box>
            <Text color="gray.500" fontSize="xs">
              {text}
            </Text>
          </Box>
        )}
      </Row>
    </Row>
  )
}

function PayoutMethodRow({
  type,
  identifier,
  text,
  completed,
}: PayoutMethodProps & { completed: boolean }) {
  return (
    <Row y="center" className="gap-3">
      {completed ? (
        <MapPinIcon className={'h-4 w-4'} />
      ) : (
        <MapPinIconOutline className="h-4 w-4 stroke-gray-500" />
      )}
      <Row gap="small" y="center">
        <Icon name={iconToTypeMap[type || '']} />
        <Text color="gray.800" fontSize="sm">
          {identifier}
        </Text>
        {text && (
          <Text color="gray.500" fontSize="xs">
            {text}
          </Text>
        )}
      </Row>
    </Row>
  )
}

function PaymentTimeline({
  paymentMethod,
  payoutMethod,
  completed,
}: PaymentTimelineProps) {
  return (
    <Column className="gap-0.5">
      <PaymentMethodRow {...paymentMethod} />
      <Box ml="7.5px" height="11px" width="100%">
        <Divider
          orientation="vertical"
          borderColor={completed ? 'gray.800' : 'gray.400'}
        />
      </Box>
      <PayoutMethodRow {...{ ...payoutMethod, completed: !!completed }} />
    </Column>
  )
}

export function TransactionActivity({
  paymentMethod,
  payoutMethod,
  completed,
}: PaymentTimelineProps) {
  return (
    <Flex
      px={8}
      py={4}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'top'}
      w="100%"
    >
      <Card className="w-full px-4 py-2">
        <Row gap="medium">
          <PaymentTimeline
            paymentMethod={paymentMethod}
            payoutMethod={payoutMethod}
            completed={completed}
          />
        </Row>
      </Card>
    </Flex>
  )
}
