import { property, styles, tw } from '@nickeltech/brise'
import React from 'react'
import ContainerProps, { RoundedCardProps } from '../../types'
import CardFooter, { CardFooterProps } from './Footer'
import CardHeader, { CardHeaderProps } from './Header'
import Spacing, { SpacingProps } from '../Layout/Spacing'
import { MotionProps, motion } from 'framer-motion'

export type EmbeddedProps = {
  $embedded?: boolean
}

export type CardProps = CardHeaderProps &
  ContainerProps &
  CardFooterProps &
  EmbeddedProps &
  SpacingProps &
  RoundedCardProps &
  MotionProps & {
    forwadedRef?: React.Ref<HTMLDivElement>
  }

export const RoundedCardStyle = property(
  'roundingStyle',
  {
    top: 'rounded-t-md',
    bottom: 'rounded-b-md',
    full: 'rounded-md',
  },
  'full',
)

export const EmbeddedStyle = styles`
  ${(props: EmbeddedProps) =>
    props.$embedded
      ? `
    border
    border-gray-300
    bg-gray-100
  `
      : `
        bg-white
        border-gray-200
      `}
`

export const CardContainer = tw(
  ({ className, children, roundingStyle, $embedded, ...props }: CardProps) => {
    return (
      <motion.div className={className} {...props} ref={props.forwadedRef}>
        {children}
      </motion.div>
    )
  },
)`
  ${RoundedCardStyle}
  border
  border-gray-200
  ${EmbeddedStyle}
  ${Spacing}

`

export const Card = tw((totalProps: CardProps) => {
  const { className, roundingStyle, $embedded, ...props } = totalProps
  return (
    <CardContainer
      {...{
        className,
        roundingStyle,
        spacing: props.spacing,
        ...props,
      }}
      $embedded={$embedded}
    >
      <CardHeader
        {...(props as CardHeaderProps)}
        sectioned={
          props.children ||
          props.onOptions ||
          props.onTrash ||
          props.onReceipt ||
          props.onShare ||
          props.onSubmit
        }
      />
      {props.children}
      <CardFooter {...(props as CardFooterProps)} />
    </CardContainer>
  )
})``

export default Card
