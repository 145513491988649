import { OperationVariables } from '@apollo/client'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Overlay } from 'ui'
import { PAYMENTS_URL } from '../../lib/urls'

type PaymentsOverlayProps = {
  onRefunded: (paymentId: string, amount: number) => void
  onError: (paymentId: string, error: string) => void
  queryOpts: OperationVariables
}

export default function PaymentsOverlay({
  onRefunded,
  onError,
  queryOpts,
}: PaymentsOverlayProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(PAYMENTS_URL)

  // You can only render one overlay, so we check if both the deposit check
  // and the payments overlay apply
  const match = location.pathname.match(
    /\/dashboard\/transactions\/payments\/(.*)/,
  )

  const checkMatch = location.pathname.match(
    /\/dashboard\/transactions\/payments\/deposit/,
  )

  return (
    <Overlay
      open={[match, checkMatch].some((x) => x != null)}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet
        context={{
          exitOverlay: exitOverlay,
          onRefunded: (paymentId: string, amount: number) => {
            exitOverlay()
            onRefunded(paymentId, amount)
          },
          onError: (paymentId: string, message: string) => {
            exitOverlay()
            onError(paymentId, message)
          },
          queryOpts: queryOpts,
        }}
      />
    </Overlay>
  )
}
