import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const variants = {
  primary: definePartsStyle({
    control: {
      border: '1px solid',
      borderColor: 'primary.700',
      boxShadow: '0 0 0 1px primary.700 !important',
      _checked: {
        background: 'primary.700',
        borderColor: 'primary.700',
        _hover: {
          borderColor: 'primary.700',
          background: 'primary.700',
        },
      },
      _hover: {
        borderColor: 'primary.700',
      },
    },
  }),
  dark: definePartsStyle({
    control: {
      colorScheme: 'dark',
      border: '1px solid',
      borderColor: 'dark.500',
      boxShadow: '0 0 0 1px dark.500 !important',
      _checked: {
        background: 'dark.500',
        borderColor: 'dark.500',
        _hover: {
          borderColor: 'dark.500',
          background: 'dark.500',
        },
      },
      _hover: {
        borderColor: 'dark.500',
      },
    },
  }),
}

export const radioTheme = defineMultiStyleConfig({ variants })
