export const variantClassNames = {
  solid: {
    primary: 'bg-dark-600 text-white border border-dark-600',
    secondary: 'bg-gray-200 text-gray-500 border border-gray-200',
    accent: 'bg-purple-600 text-white border border-purple-600',
    info: 'bg-blue-600 text-white border border-blue-600',
    success: 'bg-green-600 text-white border border-green-600',
    warning: 'bg-yellow-600 text-white border border-yellow-600',
    error: 'bg-red-600 text-white border border-red-600',
  },
  ghost: {
    primary: 'bg-transparent text-dark-600 border border-transparent',
    secondary: 'bg-transparent text-gray-500 border border-transparent',
    accent: 'bg-transparent text-purple-600 border border-transparent',
    info: 'bg-transparent text-blue-600 border border-transparent',
    success: 'bg-transparent text-green-600 border border-transparent',
    warning: 'bg-transparent text-yellow-600 border border-transparent',
    error: 'bg-transparent text-red-600 border border-transparent',
  },
  outline: {
    primary: 'bg-white text-dark-600 border border-dark-600',
    secondary: 'bg-white text-gray-500 border border-gray-500',
    accent: 'bg-white text-purple-600 border border-purple-600',
    info: 'bg-white text-blue-600 border border-blue-600',
    success: 'bg-white text-green-600 border border-green-600',
    warning: 'bg-white text-yellow-600 border border-yellow-600',
    error: 'bg-white text-red-600 border border-red-600',
  },
  soft: {
    primary: 'bg-dark-100 text-dark-600 border border-dark-100',
    secondary: 'bg-gray-100 text-gray-500 border border-gray-100',
    accent: 'bg-purple-100 text-purple-600 border border-purple-100',
    info: 'bg-blue-100 text-blue-600 border border-blue-100',
    success: 'bg-green-100 text-green-600 border border-green-100',
    warning: 'bg-yellow-100 text-yellow-600 border border-yellow-100',
    error: 'bg-red-100 text-red-600 border border-red-100',
  },
}

export const hoverClassNames = {
  solid: {
    primary: 'hover:bg-white hover:text-dark-600 hover:border-dark-600',
    secondary: 'hover:bg-white hover:text-gray-500 hover:border-gray-500',
    accent: 'hover:bg-white hover:text-purple-600 hover:border-purple-600',
    info: 'hover:bg-white hover:text-blue-600 hover:border-blue-600',
    success: 'hover:bg-white hover:text-green-600 hover:border-green-600',
    warning: 'hover:bg-white hover:text-yellow-600 hover:border-yellow-600',
    error: 'hover:bg-white hover:text-red-600 hover:border-red-600',
  },
  ghost: {
    primary: 'hover:bg-dark-100 hover:text-dark-600 hover:border-dark-100',
    secondary: 'hover:bg-gray-100 hover:text-gray-500 hover:border-gray-100',
    accent: 'hover:bg-purple-100 hover:text-purple-600 hover:border-purple-100',
    info: 'hover:bg-blue-100 hover:text-blue-600 hover:border-blue-100',
    success: 'hover:bg-green-100 hover:text-green-600 hover:border-green-100',
    warning:
      'hover:bg-yellow-100 hover:text-yellow-600 hover:border-yellow-100',
    error: 'hover:bg-red-100 hover:text-red-600 hover:border-red-100',
  },
  soft: {
    primary: 'hover:bg-dark-600 hover:text-white hover:border-dark-600',
    secondary: 'hover:bg-gray-200 hover:text-gray-500 hover:border-gray-200',
    accent: 'hover:bg-purple-600 hover:text-white hover:border-purple-600',
    info: 'hover:bg-blue-600 hover:text-white hover:border-blue-600',
    success: 'hover:bg-green-600 hover:text-white hover:border-green-600',
    warning: 'hover:bg-yellow-600 hover:text-white hover:border-yellow-600',
    error: 'hover:bg-red-600 hover:text-white hover:border-red-600',
  },
}
