import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ACHDetails } from './ACHDetails'
import { Form, FormButton, ValidatingInput } from 'ui'
import * as yup from 'yup'
import { Flex } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import {
  BankAccount,
  OnboardingCreatePayoutMethodDocument,
  OrganizationBankAccountsDocument,
  UpdateBankAccountDocument,
} from '../../../operations-types'

type UpdateBankAccountProps = {
  open: boolean
  gqlToken: string
  noToken?: boolean
  onCompleteAction: (account: BankAccount) => void
  setOpen: (open: boolean) => void
  setShowNotif: (showNotif: {
    message: string
    show: boolean
    header: string
  }) => void
  setShowError: (showError: { message: string; show: boolean }) => void
}

const BankAccountSchema = yup.object({
  holderName: yup.string().required('Account holder name is required'),
  accountType: yup.string().oneOf(['checking', 'savings']).required(),
  bankAccountNumber: yup.string().min(5).max(17).required(),
  bankRoutingNumber: yup.string().length(9).required(),
  confirmedBankAccountNumber: yup
    .string()
    .min(5)
    .max(17)
    .oneOf([yup.ref('bankAccountNumber'), null], 'Account numbers must match')
    .required('Please confirm your account number'),
})

type BankAccountFormData = yup.InferType<typeof BankAccountSchema>

export default function UpdateBankAccount({
  open,
  setOpen,
  setShowNotif,
  setShowError,
  gqlToken,
  onCompleteAction,
  noToken,
}: UpdateBankAccountProps) {
  const [updateBankAccount] = useMutation(UpdateBankAccountDocument, {
    onCompleted: (data) => {
      if (data.updateBankAccount?.error?.message) {
        setShowError({
          message: data.updateBankAccount.error.message,
          show: true,
        })
      } else {
        setShowNotif({
          message: `Successfully updated your bank account.`,
          show: true,
          header: 'Success!',
        })

        if (data.updateBankAccount?.bankAccount) {
          onCompleteAction(data?.updateBankAccount?.bankAccount)
        }
      }
    },
    refetchQueries: [
      {
        query: OrganizationBankAccountsDocument,
      },
    ],
    onError: (error) => {
      setShowError({ message: error.message, show: true })
    },
  })

  const [createOnboardingPayoutMethod] = useMutation(
    OnboardingCreatePayoutMethodDocument,
    {
      onCompleted: (data) => {
        if (data.onboardingCreatePayoutMethod?.error?.message) {
          setShowError({
            message: data.onboardingCreatePayoutMethod.error.message,
            show: true,
          })
        } else {
          setShowNotif({
            message: `Successfully added your bank account.`,
            show: true,
            header: 'Success!',
          })

          if (data.onboardingCreatePayoutMethod?.payoutMethod) {
            onCompleteAction(data.onboardingCreatePayoutMethod.payoutMethod)
          }
        }
      },
    },
  )

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <Form<BankAccountFormData>
                  validationSchema={BankAccountSchema}
                  initialValues={{
                    holderName: '',
                    bankAccountNumber: '',
                    bankRoutingNumber: '',
                    confirmedBankAccountNumber: '',
                    accountType: 'checking',
                  }}
                  onSubmit={async (values) => {
                    if (noToken) {
                      await createOnboardingPayoutMethod({
                        variables: {
                          accountHolder: values.holderName,
                          routingNumber: values.bankRoutingNumber,
                          accountNumber: values.bankAccountNumber,
                          accountType: values.accountType,
                        },
                      })
                    } else {
                      await updateBankAccount({
                        variables: {
                          holderName: values.holderName,
                          token: gqlToken,
                          type: values.accountType,
                          routingNumber: values.bankRoutingNumber,
                          accountNumber: values.bankAccountNumber,
                        },
                      })
                    }
                    setOpen(false)
                  }}
                >
                  {(formik) => {
                    return (
                      <Flex flexDirection="column" gap="2">
                        <ValidatingInput
                          id="holderName"
                          onChange={formik.handleChange}
                          value={formik.values.holderName}
                          onBlur={formik.handleBlur}
                          isInvalid={
                            !!formik.errors.holderName &&
                            !!formik.touched.holderName
                          }
                          label="Account Holder Name"
                          placeholder="Full Name"
                        />
                        <ACHDetails
                          routingNumber={formik.values.bankRoutingNumber}
                          accountNumber={formik.values.bankAccountNumber}
                          confirmAccountNumber={
                            formik.values.confirmedBankAccountNumber
                          }
                          onChangeAccountNumber={(accountNumber) =>
                            formik.setFieldValue(
                              'bankAccountNumber',
                              accountNumber,
                            )
                          }
                          onChangeConfirmAccountNumber={(
                            confirmAccountNumber,
                          ) =>
                            formik.setFieldValue(
                              'confirmedBankAccountNumber',
                              confirmAccountNumber,
                            )
                          }
                          onChangeRoutingNumber={(routingNumber) =>
                            formik.setFieldValue(
                              'bankRoutingNumber',
                              routingNumber,
                            )
                          }
                        />
                        <FormButton className="w-full mt-2" label="Update" />
                      </Flex>
                    )
                  }}
                </Form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
