import { useMutation } from '@apollo/client'
import {
  Card,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Field } from 'formik'
import {
  AddressInput,
  FieldTooltip,
  Form,
  StateInput,
  US_STATES,
  ValidatingInput,
  Button,
  FormikValidatingInput,
} from 'ui'
import * as yup from 'yup'
import { useAuth } from '../../../lib/auth'
import {
  SelfDocument,
  SignupAccountingFirmDocument,
  UpdateOnboardingInformationDocument,
} from '../../../operations-types'
import { useAccountantRegistrationFlow } from './registrationFlowStore'

const OnboardingGeneralFormSchema = yup.object({
  businessName: yup.string().required('Business name is required'),
  taxIdNumber: yup
    .string()
    .length(9, 'Tax ID must be 9 digits')
    .required('Business identifier is required'),
  stateIncorporated: yup.string().required('State is required'),
  paymentUrl: yup.string().required('Payment URL is required'),
  termsAccepted: yup
    .boolean()
    .oneOf([true], 'Terms of service must be accepted')
    .required(),
  companyAddress: yup
    .object({
      zipCode: yup
        .string()
        .required('Business address is required')
        .length(5)
        .matches(/^[0-9]{5}/)
        .required(),
      state: yup
        .string()
        .required('State is required')
        .oneOf(US_STATES.states.map((x) => x.name)),
      streetAddress: yup.string().required('Business address is required'),
      city: yup.string().required('City is required'),
    })
    .required(),
})

type AccountingOnboardingInfo = yup.InferType<
  typeof OnboardingGeneralFormSchema
>

type BusinessInformationProps = {
  token: string
  onNextStep: (info: AccountingOnboardingInfo) => void
}

export function BusinessInformation({
  onNextStep,
  token,
}: BusinessInformationProps) {
  const toaster = useToast()
  const [signupAccountant, { loading }] = useMutation(
    SignupAccountingFirmDocument,
    {
      onError: (error) => {
        toaster({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    },
  )

  const [updateOnboardingInfomation, { loading: onboardingLoading }] =
    useMutation(UpdateOnboardingInformationDocument, {
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    })

  const { setAuth } = useAuth()

  const { owner } = useAccountantRegistrationFlow((state) => ({
    owner: state.owner,
  }))

  if (!owner) {
    return null
  }

  return (
    <Flex width="540px" flexDirection="column">
      <Form
        validationSchema={OnboardingGeneralFormSchema}
        initialValues={{
          businessName: '',
          paymentUrl: '',
          taxIdNumber: '',
          stateIncorporated: 'Alabama',
          companyAddress: {
            zipCode: '',
            state: '',
            streetAddress: '',
            city: '',
          },
          termsAccepted: false,
        }}
      >
        {(formik) => {
          const onClick = async () => {
            formik.validateForm()
            formik.setTouched({
              businessName: true,
              taxIdNumber: true,
              stateIncorporated: true,
              paymentUrl: true,
              companyAddress: {
                zipCode: true,
                state: true,
                streetAddress: true,
                city: true,
              },
              termsAccepted: true,
            })

            if (formik.isValid) {
              const data = await signupAccountant({
                variables: {
                  password: owner.password,
                  email: owner.email,
                  firstName: owner.firstName,
                  lastName: owner.lastName,
                  name: formik.values.businessName,
                  token: token,
                  subdomain: formik.values.paymentUrl,
                },
              })

              if (data.data?.signup?.error?.message) {
                toaster({
                  title: 'Error',
                  description: data.data.signup.error.message,
                  status: 'error',
                  duration: 4000,
                  isClosable: true,
                })
                return
              }

              if (data.data?.signup?.token) {
                setAuth(data.data.signup.token)

                const { data: onboardingData } =
                  await updateOnboardingInfomation({
                    variables: {
                      onboardingInfo: JSON.stringify({
                        ...formik.values,
                        street: formik.values.companyAddress.streetAddress,
                        city: formik.values.companyAddress.city,
                        state: formik.values.companyAddress.state,
                        zip: formik.values.companyAddress.zipCode,
                        legalName: formik.values.businessName,
                        applicationSubmitted: true,
                      }),
                    },
                    // Specifically pass in the token for the just signed up accountant
                    context: {
                      headers: {
                        Authorization: `Bearer ${data.data.signup.token}`,
                      },
                    },
                  })

                if (!onboardingData?.updateOnboardingInformation?.error) {
                  onNextStep(formik.values)
                } else {
                  toaster({
                    title: 'Error',
                    description:
                      onboardingData?.updateOnboardingInformation?.error
                        .message,
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                  })
                }
              } else {
                toaster({
                  title: 'Error',
                  description: data.errors?.[0].message,
                  status: 'error',
                  duration: 4000,
                  isClosable: true,
                })
              }
            }
          }

          return (
            <>
              <Card py={8} px={8}>
                <Flex flexDirection="column" gap={6}>
                  <ValidatingInput
                    id="businessName"
                    fontSize="sm"
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (!formik.touched.paymentUrl) {
                        formik.setFieldValue(
                          'paymentUrl',
                          e.target.value
                            .replace(/[^a-z0-9]/gi, '')
                            .toLowerCase(),
                        )
                      }
                    }}
                    isInvalid={!!formik.errors.businessName}
                    validated={formik.touched.businessName}
                    label="Business Name"
                    placeholder="Your Business Name"
                    value={formik.values.businessName}
                    error={formik.errors.businessName}
                  />
                  <FormikValidatingInput
                    fieldName="paymentUrl"
                    fontSize="sm"
                    label="Payment Portal URL"
                    value={formik.values.paymentUrl}
                    helperText="Your unique URL where payors will be directed to make payments."
                    placeholder="Your subdomain (e.g. businessname)"
                    rightAddon=".nickelpayments.com"
                  />
                  <HStack gap={4}>
                    <StateInput
                      value={{
                        value: US_STATES.states.find(
                          (states) =>
                            states.abbreviation ===
                            formik.values.stateIncorporated,
                        )?.abbreviation,
                        label: formik.values.stateIncorporated,
                      }}
                      onChange={(value) => {
                        if (!value) return
                        formik.setFieldValue('stateIncorporated', value.label)
                      }}
                    />
                    <FormikValidatingInput
                      fieldName="taxIdNumber"
                      label="Business Identifier"
                      placeholder="EIN or Tax ID"
                      popover={<FieldTooltip text="ie: Tax ID# or EIN." />}
                      fontSize="sm"
                    />
                  </HStack>
                  <VStack>
                    <AddressInput
                      label="Business Address"
                      apiKey={import.meta.env.VITE_SMARTY_STREETS_KEY || ''}
                      valueKey="companyAddress"
                    />
                    {formik.touched.companyAddress &&
                      formik.errors.companyAddress && (
                        <Text size="sm" color="red.600">
                          {formik.errors.companyAddress.streetAddress ||
                            formik.errors.companyAddress.city ||
                            formik.errors.companyAddress.zipCode ||
                            formik.errors.companyAddress.state}
                        </Text>
                      )}
                  </VStack>
                  <FormLabel htmlFor="termsAccepted">
                    <HStack>
                      <Field
                        as={Checkbox}
                        name="termsAccepted"
                        id="termsAccepted"
                        value="true"
                        checked={formik.values.termsAccepted}
                        onChange={formik.handleChange}
                      />
                      <Text fontSize="md" fontWeight="md">
                        I agree to the
                        <Link
                          href="https://www.getnickel.com/terms-of-use"
                          fontSize="md"
                          fontWeight="md"
                          color="purple.700"
                          isExternal
                        >
                          {' '}
                          Terms of Service
                        </Link>
                      </Text>
                    </HStack>
                    {formik.touched.termsAccepted &&
                      formik.errors.termsAccepted && (
                        <Text size="sm" color="red.600">
                          {formik.errors.termsAccepted}
                        </Text>
                      )}
                  </FormLabel>
                </Flex>
              </Card>
              <Button
                label="Register"
                width="100%"
                type="submit"
                mt={8}
                iconPosition="right"
                iconName="chevronRight"
                isLoading={loading || onboardingLoading}
                onClick={(e) => {
                  e.preventDefault()
                  onClick()
                }}
              />
            </>
          )
        }}
      </Form>
    </Flex>
  )
}
