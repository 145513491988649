import classNames from 'classnames'
import { ReactNode, useEffect, useState } from 'react'

import { Status } from '../../types'
import Header from '../Typography/Header'
import Column from './Column'
import Row, { UnstandardizedRow } from './Row'
import Unread from '../Indicators/Unread'

export type TabProps = {
  tab: Tab
  className?: string
  between?: boolean
  indicator?: boolean
  onClick?: (name: string) => void
}

export type Tab = {
  name: string
  unreadCount?: number
  unreadStatus?: Status
  onClick?: (name: string) => void
}

export type TabAndComponent = {
  tab: Tab
  component: ReactNode
}

export type TabsProps = {
  tabs: Array<TabAndComponent>
  selected?: number
  header?: boolean
  between?: boolean
  columnWidth?: string
}

export const Tab = (tabProps: TabProps) => {
  const tab = tabProps.tab
  const hasUnreads =
    tab.unreadStatus !== undefined && (tab.unreadCount || 0) > 0
  return (
    <UnstandardizedRow
      x="center"
      y="center"
      gap="none"
      grow
      onClick={tabProps.onClick}
      className={tabProps.className}
      between={tabProps.between}
    >
      <Header variant="quinary">{tab.name}</Header>
      {hasUnreads ? (
        <Unread
          {...{
            count: tab.unreadCount || 0,
            status: tab.unreadStatus as Status,
            indicator: tabProps.indicator,
          }}
        />
      ) : (
        ''
      )}
    </UnstandardizedRow>
  )
}

export const Tabs = (props: TabsProps) => {
  const incomingSelection =
    (props.selected || 0) > props.tabs.length - 1 ? 0 : props.selected || 0

  const [selected, setSelected] = useState(incomingSelection)

  useEffect(() => {
    setSelected(
      (props.selected || 0) > props.tabs.length - 1 ? 0 : props.selected || 0,
    )
  }, [incomingSelection])

  const TabStyle = (index: number) =>
    classNames('py-3 px-5', {
      'border-b-2': true,
      'border-l-0': index % 2 !== 0,
      'hover:bg-gray-50': index !== selected,
      'border-b-black': index === selected,
      'bg-white': index === selected,
      'bg-gray-200': index !== selected,
      'border-r border-t border-l rounded-tl rounded-tr': props.header,
    })

  return (
    <Column className={props.columnWidth}>
      <Row grow y="center" gap={props.header ? 'small' : 'none'}>
        {props.tabs.map((tabAndComponent, index) => {
          const tab = tabAndComponent.tab
          return (
            <Tab
              key={index}
              {...{
                tab: tab,
                className: TabStyle(index),
                between: props.between,
                indicator: index === selected,
                onClick: () => {
                  tab.onClick && tab.onClick(tab.name)
                  setSelected(index)
                },
              }}
            />
          )
        })}
      </Row>
      <Row grow>{props.tabs[selected].component}</Row>
    </Column>
  )
}

export default Tabs
