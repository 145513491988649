import { Card, CardBody } from '@chakra-ui/react'
import { useState } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { User } from '../../operations-types'
import CreatePaymentLinkOverlay from './CreatePaymentLinkOverlay'
import PaymentLinkSuccessOverlay from './PaymentLinkSuccessOverlay'

export function HubSpotCreatePaymentLink() {
  const [success, setSuccess] = useState(false)
  const [newPaymentLinkId, setNewPaymentLinkId] = useState('')
  const { organization } = useOutletContext() as User
  console.log(success)

  const [searchParams] = useSearchParams()
  const dealName = searchParams.get('dealname')
  const amount = searchParams.get('amount')
  const memo = searchParams.get('description')

  const hubSpotInitialValues = {
    dealName: dealName ? dealName : '',
    amount: amount ? amount : '',
    memo: memo ? memo : '',
  }
  return (
    <Card w="700px">
      <CardBody>
        {success ? (
          <PaymentLinkSuccessOverlay
            id={newPaymentLinkId}
            organizationSubdomain={organization?.accountInfo?.subdomain}
            onClose={() => {
              setSuccess(false)
            }}
          />
        ) : (
          <CreatePaymentLinkOverlay
            hubSpotInitialValues={hubSpotInitialValues}
            setSuccess={setSuccess}
            setNewPaymentLinkId={setNewPaymentLinkId}
            onClose={() => setSuccess(false)}
          />
        )}
      </CardBody>
    </Card>
  )
}
