import ContainerProps from '../../types'
import Row from '../Layout/Row'
import { tw } from '@nickeltech/brise'

export type ItemIconProps = ContainerProps
const ItemIconContainer = tw<ItemIconProps>(Row)`
    border-gray-200
    border
    rounded-sm
    bg-white
    h-6
    w-6
    text-gray-400
    text-xs
    font-medium
    shadow-sm
`

export function ItemIcon({ children, ...props }: ItemIconProps) {
  return (
    <ItemIconContainer {...{ ...props, x: 'center', y: 'center' }}>
      {children}
    </ItemIconContainer>
  )
}

export default ItemIcon
