import { useLazyQuery, useMutation } from '@apollo/client'
import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  AirtableAuthenticateDocument,
  AirtableTokenCreateDocument,
} from '../../operations-types'
import Loading from '../utils/Loading'

function AirtableAuthentication() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [getAuthUrl] = useLazyQuery(AirtableAuthenticateDocument, {
    onCompleted: (data) => {
      if (data.airtableAuthenticate) {
        window.location.replace(data.airtableAuthenticate)
      } else {
        console.error(data)
      }
    },
  })

  const [createToken] = useMutation(AirtableTokenCreateDocument, {
    onCompleted: () => {
      navigate('/dashboard')
    },
  })

  const code = searchParams.get('code')

  useEffect(() => {
    if (!code) {
      getAuthUrl()
    }

    if (code) {
      createToken({
        variables: {
          code: code!,
        },
      })
    }
  }, [code, createToken, getAuthUrl])

  return (
    <Box margin="8">
      <Loading />
    </Box>
  )
}

export default AirtableAuthentication
