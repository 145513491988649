import Row, { UnstandardizedRow } from '../Row'
import { TableDataProps } from './Header'
import TableCell, { CellContainer, CellSize } from './Cell'
import { Datum } from '../../../types'
import SkeletonLoader from '../../Loaders/SkeletonLoader'
import Separator from '../Separator'
import Column from '../Column'
import classNames from 'classnames'

type RowSize = 'medium' | 'large'
export type TableBodyProps<T> = {
  data: Array<T>
  onClick?: (args: unknown) => void
  loading?: boolean
  rowSize?: RowSize
  cellSize?: CellSize
} & TableDataProps<T>

export const TableBody = <T extends Record<string, Datum>>(
  props: TableBodyProps<T>,
) => {
  if (props.loading) {
    return (
      <UnstandardizedRow sectioned hoverable className="py-1">
        <div className="w-full px-1">
          <SkeletonLoader className="h-[18px]" count={5} />
        </div>
      </UnstandardizedRow>
    )
  }

  return (
    <>
      {props.data.map((item, index) => (
        <Row
          key={index}
          spacing={props.rowSize === 'large' ? 'basic' : 'base'}
          sectioned={index !== props.data.length - 1}
          hoverable={props.onClick ? true : false}
          onClick={
            props.onClick
              ? () => props.onClick && props.onClick(item)
              : undefined
          }
        >
          {props.headers.map((header, headerIndex) => (
            <CellContainer
              key={headerIndex}
              fit={header.fit}
              grow={header.grow}
              center={header.center}
              right={header.right}
              type={header.type}
              cellSize={props.cellSize}
            >
              <TableCell
                {...{
                  headers: props.headers,
                  key: `${index}_${header.keyName}`,
                  value: item[header.keyName as string],
                  data: item,
                  optionClickMap: header.optionClickMap?.map((g) => ({
                    ...g,
                    onClick: () => {
                      g.onClick && g.onClick(header.keyName, item)
                    },
                    condition: () =>
                      (g.condition && g.condition(header.keyName, item)) ||
                      false,
                  })),
                  loading: item.loading,
                  type: header.type,
                  className: header.className,
                  style: header.style,
                  onClick: header.onClick
                    ? (___, __: T, args) => {
                        header.onClick &&
                          header.onClick(header.keyName, item as T, args)
                      }
                    : () => props.onClick && props.onClick(item),
                  cursorDefault: header.cursorDefault,
                  grow: header.grow,
                  center: header.center,
                  right: header.right,
                  subdomain: header.subdomain,
                }}
              />
            </CellContainer>
          ))}
        </Row>
      ))}
    </>
  )
}

export const TableBodyV2 = <T extends Record<string, Datum>>(
  props: TableBodyProps<T>,
) => {
  if (props.loading) {
    return (
      <UnstandardizedRow sectioned hoverable className="py-1">
        <div className="w-full px-1">
          <SkeletonLoader className="h-[18px]" count={5} />
        </div>
      </UnstandardizedRow>
    )
  }

  return (
    <Column
      wGrow
      className="w-full !max-h-[calc(100vh-18rem)] overflow-y-scroll"
    >
      {props.data.map((item, index) => (
        <Row
          grow
          key={index}
          sectioned={index !== props.data.length - 1}
          className={classNames('hover:bg-gray-100 border-b', {
            'cursor-pointer': !!props.onClick,
          })}
        >
          {props.headers.map((header, headerIndex) => (
            <CellContainer
              key={headerIndex}
              type={header.type}
              grow={header.grow}
              center={header.center}
              right={header.right}
              cellSize={props.cellSize}
              fit={header.fit}
              width={header.width}
              onClick={
                props.onClick && header.type !== 'multiSelect'
                  ? () => props.onClick && props.onClick(item)
                  : undefined
              }
            >
              <TableCell
                {...{
                  fit: header.fit,
                  headers: props.headers,
                  selected: header.selected,
                  key: `${index}_${header.keyName}`,
                  value: item[header.keyName as string],
                  data: item,
                  optionClickMap: header.optionClickMap?.map((g) => ({
                    ...g,
                    onClick: () => {
                      g.onClick && g.onClick(header.keyName, item)
                    },
                    condition: () =>
                      (g.condition && g.condition(header.keyName, item)) ||
                      false,
                  })),
                  loading: item.loading,
                  type: header.type,
                  className: header.className,
                  style: header.style,
                  onClick: header.onClick
                    ? (___, __: T, args) => {
                        header.onClick &&
                          header.onClick(header.keyName, item as T, args)
                      }
                    : () => props.onClick && props.onClick(item),
                  cursorDefault: header.cursorDefault,
                  grow: header.grow,
                  center: header.center,
                  right: header.right,
                  subdomain: header.subdomain,
                  onCheck: header.onCheck,
                }}
              />
              {headerIndex !== props.headers.length - 1 && <Separator />}
            </CellContainer>
          ))}
        </Row>
      ))}
    </Column>
  )
}
