import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  useDisclosure,
} from '@chakra-ui/react'
import NotificationArea from '../utils/NotificationArea'
import { InfoCardItem } from './InfoCardItem'
import Notification from '../utils/Notification'
import { useState } from 'react'
import { Button, Form, FormButton } from 'ui'
import * as yup from 'yup'
import { useOutletContext } from 'react-router-dom'
import {
  CreateWebhookDocument,
  SelfDocument,
  User,
} from '../../operations-types'
import { useMutation } from '@apollo/client'

type ManageWebhooksProps = {
  webhookUrl: string
  notify: (message: string, header: string) => void
}

const Schema = yup.object({
  webhookUrl: yup.string().url().required(),
})

type WebhookFormData = yup.InferType<typeof Schema>

type WebhookModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (url: string) => void
}

function WebhookModal({ isOpen, onClose, onSubmit }: WebhookModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Form<WebhookFormData>
          className="w-full"
          validationSchema={Schema}
          initialValues={{
            webhookUrl: '',
          }}
          onSubmit={async (values) => onSubmit(values.webhookUrl)}
        >
          {(formik) => (
            <Stack>
              <FormControl>
                <ModalHeader>New Webhook</ModalHeader>
                <ModalCloseButton />
                <Stack px={6} pb={4}>
                  <FormLabel variant="small">Webhook URL</FormLabel>
                  <Input
                    name="webhookUrl"
                    value={formik.values.webhookUrl}
                    onChange={formik.handleChange}
                  />
                  <FormButton
                    label="Save"
                    x="right"
                    onClick={async (e) => {
                      e.preventDefault()
                      formik.setSubmitting(true)

                      await formik.submitForm()
                      onClose()
                    }}
                  />
                </Stack>
              </FormControl>
            </Stack>
          )}
        </Form>
      </ModalContent>
    </Modal>
  )
}

function ManageWebhooks({ webhookUrl, notify }: ManageWebhooksProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [createWebhook] = useMutation(CreateWebhookDocument, {
    refetchQueries: [{ query: SelfDocument }],
  })

  return (
    <Stack spacing="4" w="100%">
      <WebhookModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={(url) => {
          createWebhook({
            variables: {
              url,
            },
          })
          notify('Webhook created!', 'Success')
        }}
      />
      <HStack spacing="4" alignItems={'flex-end'}>
        <FormControl>
          <FormLabel variant="small">Webhook URL</FormLabel>
          <Input isDisabled value={webhookUrl} />
        </FormControl>
        <Button
          label="Delete"
          onClick={() => {
            createWebhook({
              variables: {
                url: '',
              },
            })
            notify('Webhook deleted!', 'Success')
          }}
        />
      </HStack>
      <Button label="Create New Webhoook" onClick={onOpen} />
    </Stack>
  )
}

export default function Webhooks() {
  const data = useOutletContext() as User

  const webhook = data?.organization?.accountInfo?.webhookUrl || ''

  const [showNotif, setShowNotif] = useState({
    message: '',
    show: false,
    header: '',
  })
  const onClose = () => setShowNotif({ message: '', show: false, header: '' })

  return (
    <Box py={{ base: '4', md: '8' }} px={{ md: '4' }}>
      <NotificationArea>
        <Notification
          show={showNotif['show']}
          onClose={onClose}
          header={showNotif['header']}
          message={showNotif['message']}
        />
      </NotificationArea>
      <Stack spacing="8" divider={<StackDivider />}>
        <InfoCardItem
          label="Webhooks"
          sublabel="Get alerted whenever a payment is made"
        >
          <ManageWebhooks
            webhookUrl={webhook}
            notify={(message, header) =>
              setShowNotif({ message, header, show: true })
            }
          />
        </InfoCardItem>
      </Stack>
    </Box>
  )
}
