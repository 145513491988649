import React, { useState, FormEventHandler, ChangeEventHandler } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Loading from '../utils/Loading'
import { useAuth } from '../../lib/auth'
import {
  BecomeUserDocument,
  CreateDemoDocument,
  InviteDocument,
  OnboardingFormInfo,
  OrganizationPagesDocument,
  UnapprovedAccountsDocument,
  UnapprovedAccountsQuery,
  UpdateOrganizationStatusDocument,
  ApprovalStatus,
  OnboardingInfoDocument,
} from '../../operations-types'
import { Select } from 'chakra-react-select'
import {
  Card,
  Checkbox,
  Input,
  VStack,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import {
  Button,
  HFlex,
  TableCellProps,
  TableV2,
  ValidatingInput,
  VFlex,
} from 'ui'
import { TransactionTabsV2 } from '../ap/TransactionTabs'
import TableSearchInput from 'ui/src/components/TableSearchInput'

type InviteBarProps = {
  onSubmit: FormEventHandler<HTMLFormElement>
  onEmail: ChangeEventHandler<HTMLInputElement>
  onAccountingFirm: (checked: boolean) => void
  loading: boolean
}

function InviteBar({
  onSubmit,
  onEmail,
  loading,
  onAccountingFirm,
}: InviteBarProps) {
  const inputStyle =
    'py-2 px-4 rounded-md border-2 text-md border-slate-400 focus:border-slate-500 focus:ring-slate-500'
  const buttonStyle = `
    self-center bg-white text-xl text-nickel-purple rounded-md
    border-2 py-2 px-6 hover:text-white hover:bg-nickel-purple border-slate-200"
  `

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-6">
        <div className="font-medium text-2xl text-nickel-purple leading-10 tracking-wide">
          Send registration link
        </div>
        {loading && <Loading />}
        {!loading && (
          <div className="flex flex-col gap-6">
            <input
              className={inputStyle}
              onChange={onEmail}
              type="text"
              id="email"
              name="email"
              placeholder="Email"
            />
            <Checkbox onChange={(e) => onAccountingFirm(e.target.checked)}>
              User is an accounting firm
            </Checkbox>
            <button className={buttonStyle} type="submit" value="Log In">
              Send Link
            </button>
          </div>
        )}
      </div>
    </form>
  )
}

type Option = {
  value: string
  label: string
}
type BecomeUserBarProps = {
  onSubmit: FormEventHandler<HTMLFormElement>
  loading: boolean
  options: Option[] | undefined
  onId: (value: string) => void
}

function BecomeUserId() {
  const [becomeUserId, setBecomeUserId] = useState('')
  const toaster = useToast()
  const { setAuth } = useAuth()
  const [becomeUser, { loading: becomeUserLoading }] = useMutation(
    BecomeUserDocument,
    {
      onCompleted: (data) => {
        const token = data?.becomeUser?.token
        if (token) {
          setAuth(token)
        } else {
          toaster({
            title: 'Error',
            description:
              data?.becomeUser?.error?.message || 'An error occurred',
            status: 'error',
            duration: 5000,
          })
        }
      },
    },
  )

  return (
    <VFlex gap={6}>
      <ValidatingInput
        isInvalid={false}
        placeholder="User Id"
        label="User Id"
        onChange={(e) => setBecomeUserId(e.target.value)}
      />
      <Button
        isLoading={becomeUserLoading}
        label="Become user"
        isDisabled={becomeUserId.length === 0}
        onClick={() =>
          becomeUser({
            variables: {
              becomeUserId: becomeUserId,
            },
          })
        }
      />
    </VFlex>
  )
}

function BecomeUserBar({
  loading,
  onSubmit,
  options,
  onId,
}: BecomeUserBarProps) {
  const buttonStyle = `
    self-center bg-white text-xl text-nickel-purple rounded-md
    border-2 py-2 px-6 hover:text-white hover:bg-nickel-purple border-slate-200"
  `

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-6">
        <div className="font-medium text-2xl justify-center text-nickel-purple leading-10 tracking-wide">
          Become User
        </div>
        {loading && <Loading />}
        <Select
          menuPortalTarget={document.body}
          className="w-64"
          size="lg"
          onChange={(options) => {
            if (!options) return
            onId(options.value || '')
          }}
          options={options}
        />
        {!loading && (
          <div className="flex flex-col gap-6">
            <button className={buttonStyle} type="submit" value="Become user">
              Become user
            </button>
          </div>
        )}
      </div>
    </form>
  )
}

function CreateDemoAccount({
  onSubmit,
}: {
  onSubmit: (email: string, subdomain: string, name: string) => void
}) {
  const [email, setEmail] = useState('')
  const [subdomain, setSubdomain] = useState('')
  const [name, setName] = useState('')

  return (
    <>
      <div className="font-medium text-2xl justify-center text-nickel-purple leading-10 tracking-wide">
        Create Demo
      </div>
      <Card>
        <VStack>
          <Input
            placeholder="Company Name"
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
          <Input
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
          <Input
            placeholder="Subdomain"
            onChange={(e) => {
              setSubdomain(e.target.value)
            }}
          />
          <Button
            label="Create Demo"
            onClick={() => {
              onSubmit(email, subdomain, name)
            }}
            type="submit"
          />
        </VStack>
      </Card>
    </>
  )
}

type OrganizationApproval = {
  id: string
  businessName: string
  contactName: string
  address: string
  website: string
  email: string
  showAction: string
}

type UnapprovedOrg = NonNullable<
  NonNullable<
    NonNullable<
      UnapprovedAccountsQuery['unapprovedAccounts']
    >['unapprovedAccounts']
  >[0]
>

type OrganizationModalProps = {
  organizationId: string | null
  isOpen: boolean
  onClose: () => void
}

function OrganizationModal({
  isOpen,
  onClose,
  organizationId,
}: OrganizationModalProps) {
  const { data } = useQuery(OnboardingInfoDocument, {
    variables: {
      organizationId: organizationId!,
    },
    skip: !organizationId,
  })

  const plaidInfo = data?.onboardingInfo?.plaidAccountInfo
  const plaidTransactions = data?.onboardingInfo?.plaidTransactions || []

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent px={6}>
        <VFlex gap={2} py={4}>
          <Text fontSize="2xl" fontWeight="bold">
            Name: {data?.onboardingInfo?.name}
          </Text>
          <Text fontSize="2xl">Id: {organizationId}</Text>
        </VFlex>
        <ModalCloseButton />
        <VFlex gap={4}>
          <HFlex gap={4}>
            <Card px={6} py={8}>
              <Text fontSize="xl" fontWeight="semibold">
                Onboarding Info
              </Text>
              <pre>
                {JSON.stringify(data?.onboardingInfo?.onboardingInfo, null, 2)}
              </pre>
            </Card>
            <Card px={6} py={8}>
              <Text fontSize="xl" fontWeight="semibold">
                Plaid Bank Data
              </Text>
              <HStack gap={2}>
                <Text>Name</Text>
                <Text>Balance</Text>
                <Text>Owners</Text>
              </HStack>
              {plaidInfo?.map((plaid, idx) => (
                <HStack gap={2} key={idx}>
                  <Text>{plaid.name}</Text>
                  <Text>{plaid.balance}</Text>
                  {plaid.owners?.map((owner, i) => <pre key={i}>{owner}</pre>)}
                </HStack>
              ))}
            </Card>
          </HFlex>
          <Card px={6} py={8}>
            <Text fontSize="xl" fontWeight="semibold">
              Last 30 Days Plaid Transaction Data
            </Text>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Amount</Th>
                    <Th>Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {plaidTransactions.map((plaid, idx) => (
                    <Tr key={idx}>
                      <Td>{plaid.name}</Td>
                      <Td>{plaid.amount}</Td>
                      <Td>{plaid.date}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
        </VFlex>
      </ModalContent>
    </Modal>
  )
}

function AdminApprovalsTable() {
  const [page, setPage] = useState(1)
  const pageSize = 20
  const [name, setName] = useState<string | undefined>(undefined)
  const [queryOpts, setQueryOpts] = useState({
    page: 1,
    pageSize: pageSize,
    status: 'PENDING' as ApprovalStatus,
    name: name,
  })

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [organizationId, setOrganizationId] = useState<string | null>(null)
  const [selectedTab, setSelectedTab] = useState('PENDING')

  const { data, loading } = useQuery(UnapprovedAccountsDocument, {
    variables: queryOpts,
  })
  const toaster = useToast()

  const [updateStatus] = useMutation(UpdateOrganizationStatusDocument, {
    onCompleted: (data) => {
      if (data?.updateOrganizationStatus?.error?.message) {
        toaster({
          title: 'Error',
          description:
            data?.updateOrganizationStatus?.error?.message ||
            'An error occurred',
          status: 'error',
          duration: 5000,
        })
        return
      }

      toaster({
        title: 'Organization status updated',
        status: 'success',
        duration: 5000,
      })
    },
    refetchQueries: ['unapprovedAccounts'],
  })

  const totalPages = Math.ceil(
    (data?.unapprovedAccounts?.totalResults || 0) / pageSize,
  )

  const transformOrgIntoTableData = (
    orgs: UnapprovedOrg[],
  ): OrganizationApproval[] => {
    return orgs.map((org) => {
      const contact = org.employees?.at(0)
      const onboardingInfo = JSON.parse(
        org.accountInfo?.onboardingInfo || '{}',
      ) as OnboardingFormInfo

      return {
        id: org.id,
        businessName: org.name!,
        contactName: `${contact?.firstName} ${contact?.lastName}`,
        address: `${onboardingInfo.street}, ${onboardingInfo.city}, ${onboardingInfo.state}, ${onboardingInfo.zip}`,
        website: `${onboardingInfo.website}`,
        email: `${contact?.email}`,
        showAction: selectedTab === 'PENDING' ? 'ACTION' : '',
      }
    })
  }

  const table = (
    <TableV2
      loading={loading}
      cellSize="large"
      rowSize="large"
      onClick={(data) => {
        setOrganizationId(data.id)
        onOpen()
      }}
      headers={
        [
          {
            label: 'Business Name',
            keyName: 'businessName',
            type: 'description',
            width: 'large',
            grow: 1,
          },
          {
            label: 'Contact Name',
            keyName: 'contactName',
            type: 'description',
            width: 'large',
          },
          {
            label: 'Address',
            keyName: 'address',
            type: 'description',
            width: 'large',
            grow: 1,
          },
          {
            label: 'Website',
            keyName: 'website',
            type: 'description',
            width: 'large',
            grow: 1,
          },
          {
            label: 'Email',
            keyName: 'email',
            type: 'description',
            width: 'large',
            grow: 1,
          },
          selectedTab === 'PENDING'
            ? {
                type: 'approveDeny',
                label: 'Action',
                keyName: 'showAction',
                width: 'large',
                onClick: (
                  keyName: string,
                  approval: OrganizationApproval,
                  newStatus: string,
                ) => {
                  if (newStatus === 'APPROVED') {
                    updateStatus({
                      variables: {
                        organizationId: approval.id,
                        status: ApprovalStatus.Approved,
                      },
                    })
                  } else if (newStatus === 'REJECTED') {
                    updateStatus({
                      variables: {
                        organizationId: approval.id,
                        status: ApprovalStatus.Rejected,
                      },
                    })
                  }
                },
              }
            : null,
        ].filter(Boolean) as TableCellProps<object>[]
      }
      data={transformOrgIntoTableData(
        data?.unapprovedAccounts?.unapprovedAccounts || [],
      )}
      page={page}
      perPage={pageSize}
      totalPages={totalPages}
      onPage={(page) => {
        setPage(page)
        setQueryOpts({
          ...queryOpts,
          page: page,
        })
      }}
    />
  )

  return (
    <VFlex px={4} py={4} gap={4}>
      <OrganizationModal
        isOpen={isOpen}
        onClose={onClose}
        organizationId={organizationId}
      />
      <Text fontSize="xl" fontWeight="semibold">
        Accounts
      </Text>
      <TransactionTabsV2
        selected={selectedTab}
        onSelect={(tab) => {
          setSelectedTab(tab)
          setQueryOpts({
            ...queryOpts,
            status: tab as ApprovalStatus,
          })
        }}
        tabs={[
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'Approved',
            value: 'APPROVED',
          },
        ]}
      />
      <TableSearchInput
        placeholder="Search business name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setQueryOpts({
              ...queryOpts,
              name: name,
            })
          }
        }}
      />
      {table}
    </VFlex>
  )
}

export default function Admin() {
  const [email, setEmail] = useState('')
  const [becomeUserId, setBecomeUserId] = useState('')
  const [isAccountingFirm, setIsAccountingFirm] = useState(false)
  const [invite, { loading: inviteLoading }] = useMutation(InviteDocument)
  const { data: organizationPages } = useQuery(OrganizationPagesDocument)
  const options = organizationPages?.organizationPages?.organizationPages?.map(
    (organization) => ({
      value:
        organization?.employees?.find(
          (employee) => employee?.role === 'USER_ADMIN',
        )?.id || '',
      label:
        organization?.organizationName +
          ': ' +
          organization?.employees?.find(
            (employee) => employee?.role === 'USER_ADMIN',
          )?.email || '',
    }),
  )

  const { setAuth } = useAuth()

  const [becomeUser, { loading: becomeUserLoading }] = useMutation(
    BecomeUserDocument,
    {
      onCompleted: (data) => {
        const token = data?.becomeUser?.token

        setAuth(token)
      },
    },
  )

  const [createDemo] = useMutation(CreateDemoDocument, {
    onCompleted: (data) => {
      const token = data?.createDemo?.token

      setAuth(token)
    },
  })

  const onSubmitCreateDemo = (
    email: string,
    subdomain: string,
    name: string,
  ) => {
    createDemo({
      variables: {
        email: email,
        subdomain: subdomain,
        name: name,
      },
    })
  }

  const onSubmitInvite = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    invite({ variables: { email: email, isAccountingFirm: isAccountingFirm } })
  }

  const onSubmitBecomeUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    becomeUser({ variables: { becomeUserId: becomeUserId } })
  }

  const onId = (value: string) => {
    if (!value) return
    setBecomeUserId(value)
  }

  return (
    <div className="mt-4 w-full overflow-scroll flex flex-col gap-6">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <Card mx={4}>
            <div className="flex flex-col py-12 px-16">
              <InviteBar
                onSubmit={onSubmitInvite}
                onEmail={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                onAccountingFirm={() => setIsAccountingFirm(!isAccountingFirm)}
                loading={inviteLoading}
              />
            </div>
          </Card>
          <Card mx={4}>
            <div className="flex flex-col py-12 px-16">
              <BecomeUserBar
                onSubmit={onSubmitBecomeUser}
                options={options}
                onId={onId}
                loading={becomeUserLoading}
              />
            </div>
          </Card>
          <Card mx={4}>
            <div className="flex flex-col py-12 px-16">
              <BecomeUserId />
            </div>
          </Card>
          {import.meta.env.VITE_NODE_ENV === 'demo' ? (
            <Card mx={4}>
              <div className="flex flex-col py-12 px-16">
                <CreateDemoAccount onSubmit={onSubmitCreateDemo} />
              </div>
            </Card>
          ) : (
            ''
          )}
        </div>
        <AdminApprovalsTable />
      </div>
    </div>
  )
}
