import _ from 'lodash'
import formatter from '../../formatter'
import Icon from '../Icons'
import ItemIcon from '../Items/ItemIcon'
import Column, { UnstandardizedColumn } from '../Layout/Column'
import Row from '../Layout/Row'
import { UnstandardizedText } from '../Typography/Text'

export type Customer = {
  id: string
  name: string
  amount: number
  caption?: string
}

export type CustomerItemProps = {
  customer: Customer
  onClick?: () => void
}

export function CustomerItem({
  customer: { name, amount, caption },
  onClick,
}: CustomerItemProps) {
  return (
    <UnstandardizedColumn
      className="p-3 shadow-xs border border-gray-300 rounded w-[280px] min-w-[280px] h-[88px] min-h-[88px]"
      gap="small"
      hoverable
      onClick={onClick}
    >
      <Row between y="center" grow>
        <ItemIcon>{name[0]}</ItemIcon>
        <Row gap="xsmall" y="center">
          <Icon name="chartBar" size="xsmall" className="stroke-gray-400" />
          <UnstandardizedText className="text-gray-500 text-xs font-medium">
            {formatter.format(amount)}
          </UnstandardizedText>
        </Row>
      </Row>
      <Column gap="xsmall">
        <Row gap="xsmall">
          <UnstandardizedText className="text-gray-800 text-base font-medium">
            {name}
          </UnstandardizedText>
        </Row>
        {caption && (
          <div className="h-[16px] overflow-hidden text-ellipsis">
            <UnstandardizedText className="text-gray-500 font-normal text-xs">
              {caption}
            </UnstandardizedText>
          </div>
        )}
      </Column>
    </UnstandardizedColumn>
  )
}

export default CustomerItem
