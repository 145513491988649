import { create } from 'zustand'
export type AddClientStates =
  | 'company'
  | 'accounting'
  | 'bankAccount'
  | 'done'
  | 'addOwner'

export type ClientOrg = {
  id: string
  name: string
  ownerUserId: string
  ownerFirstName: string
  companyAddresss: {
    streetAddress: string
    city: string
    state: string
    zipCode: string
  }
}

type AddClientState = {
  step: AddClientStates
  clientOrg: ClientOrg | null
  setStep: (step: AddClientStates) => void
  setClientOrg: (clientOrg: ClientOrg) => void
  reset: () => void
}

const initialState: Pick<AddClientState, 'step' | 'clientOrg'> = {
  step: 'company',
  clientOrg: null,
}

export const useAddClientStore = create<AddClientState>((set) => ({
  ...initialState,
  setStep: (step: AddClientStates) => set({ step }),
  setClientOrg: (clientOrg: ClientOrg) => set({ clientOrg }),
  reset: () => set(initialState),
}))
