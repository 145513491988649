import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { CustomersPage, Header, Row } from 'ui'
import { CustomersDocument } from '../../operations-types'
import Loading from '../utils/Loading'
import CustomerOverlay from './CustomerOverlay'
import { reportErrorIfExists } from '../../lib/utils'
import { PageLayout } from '../layout/PageLayout'

export default function Customers() {
  const [open, setOpen] = useState(false)
  const [customerId, setCustomerId] = useState<string | null>(null)
  const { data, loading, error } = useQuery(CustomersDocument)

  reportErrorIfExists(data?.customers?.error?.message || error)

  const customers = data?.customers?.customers || []

  const customerPresenters = customers.map((customer) => {
    return {
      id: customer.id || '',
      name: `${customer.firstName} ${customer.lastName}`,
      amount: (customer.amountCents || 0) / 100,
      caption: customer.email || '',
    }
  })

  return (
    <PageLayout>
      <Row className="pb-8">
        <Header variant="page">Customers</Header>
      </Row>
      {loading && <Loading additionalClasses={{ 'mt-8': true }} />}
      {!loading && (
        <div className="max-h-[calc(100vh-_14rem)] overflow-auto">
          <CustomersPage
            customers={customerPresenters}
            onClick={(c) => {
              setCustomerId(c.id)
              setOpen(true)
            }}
          />
        </div>
      )}
      <CustomerOverlay open={open} setOpen={setOpen} customerId={customerId} />
    </PageLayout>
  )
}
