import { tw } from '@nickeltech/brise'
import {
  PickEnum,
  StatusProps,
  TwoDimensionSize,
  Variant,
  VariantProps,
} from '../../types'
import StatusTextColor, { StatusBackgroundStyle } from '../Indicators'
import {
  OutlineProps,
  OutlineStyle,
  RoundedProps,
  RoundedStyle,
} from '../Indicators/StatusPill'
import Row, { RowProps } from '../Layout/Row'
import Spacing, { SpacingProps } from '../Layout/Spacing'
import Loader from '../Loader'
import { DisabledTransition, DisabledTransitionProps } from '../Transitions'
import Label from '../Typography/Label'

export type ButtonSize = PickEnum<
  TwoDimensionSize,
  'smallNarrow' | 'mediumNarrow'
>

export type LoadingProps = {
  loading?: boolean
}

export type ButtonProps = RowProps &
  DisabledTransitionProps &
  OutlineProps &
  RoundedProps &
  VariantProps &
  StatusProps &
  SpacingProps & {
    type?: string
  } & LoadingProps

export const ButtonContainer = tw<ButtonProps>(Row)`
  cursor-pointer
  ${StatusBackgroundStyle}
  ${StatusTextColor}
  ${RoundedStyle}
  ${OutlineStyle}
  ${Spacing}
  ${DisabledTransition}
`

export type ButtonVariant = PickEnum<Variant, 'primary' | 'secondary'>

export const Button = ({
  children,
  onClick,
  spacing,
  variant,
  status,
  disabled,
  loading,
  grow,
  type,
}: ButtonProps) => {
  return (
    <ButtonContainer
      x="center"
      y="center"
      {...{
        onClick,
        disabled: disabled || loading,
        spacing: 'smallNarrow',
        variant,
        status,
        grow,
        type,
      }}
    >
      <Label
        {...{
          variant,
          status,
          className: 'w-full text-center cursor-pointer min-w-[60px]',
        }}
      >
        {loading ? <Loader /> : children}
      </Label>
    </ButtonContainer>
  )
}

export type UnstandardizedButtonProps = {
  buttonText: string
  onClick?: () => void
}

export const UnstandardizedButton = tw.div<ButtonProps>`
  border
  border-gray-300
  shadow-xs
  p-4
  rounded-md
  cursor-pointer
  hover:bg-gray-50
  text-gray-800
  font-medium
`

export default Button
