import {
  Flex,
  Switch,
  Spacer,
  Box,
  Text,
  VStack,
  Divider,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'

const CREDIT_CARD_FEE = 0.029

type EnablePaymentMethodsProps = {
  creditCardEnabled: boolean
  setCreditCardEnabled: (enabled: boolean) => void
  achEnabled: boolean
  setAchEnabled: (enabled: boolean) => void
  feePassthroughPercent: string
  setFeePassthroughPercent: (percentage: string) => void
}

type PaymentMethodEntityProps = {
  paymentMethod?: string
  setPaymentMethodEnabled: (enabled: boolean) => void
  paymentMethodEnabled: boolean
  feePassthroughPercent?: string
  setFeePassthroughPercent?: (percentage: string) => void
  disabled?: boolean
  onChangeSwitch?: (_e: any) => void
  onChangeInput?: (_e: any) => void
}

function PaymentMethodEntity(props: PaymentMethodEntityProps) {
  function calculateFee(feePassthroughPercent: number) {
    const feePercentage = CREDIT_CARD_FEE * (feePassthroughPercent / 100)
    const increasedFeePercentage = 1 / (1 - feePercentage) - 1
    return Number(increasedFeePercentage * 100).toFixed(2)
  }
  return (
    <Flex flexDirection="row" alignItems="center" justifyItems="center" p="4">
      <Box>
        <VStack gap="0" spacing="0" alignItems="left">
          <Text>
            {props.paymentMethod === 'card' ? 'Credit Card' : 'ACH / eCheck'}
          </Text>

          <Text fontSize="sm" color="gray.500">
            Your customer pays{' '}
            {props.paymentMethod === 'card'
              ? `${calculateFee(
                  parseFloat(props.feePassthroughPercent || '100'),
                )}%`
              : '0%'}
          </Text>
        </VStack>
      </Box>
      <Spacer />
      {!!props.setFeePassthroughPercent && (
        <>
          <VStack gap="1" spacing="0">
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              Fee Passthrough
            </Text>
            <InputGroup size="sm">
              <Input
                w="60px"
                isDisabled={!props.paymentMethodEnabled}
                id="feePassthroughPercentage"
                value={props.feePassthroughPercent}
                onChange={props.onChangeInput}
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
          </VStack>

          <Spacer />
        </>
      )}
      <Box>
        <Switch
          size="lg"
          isChecked={props.paymentMethodEnabled}
          onChange={props.onChangeSwitch}
        />
      </Box>
    </Flex>
  )
}

function EnablePaymentMethods(props: EnablePaymentMethodsProps) {
  return (
    <Flex flexDirection={'column'} py="2" gap="4" w="100%">
      <PaymentMethodEntity
        paymentMethod="card"
        paymentMethodEnabled={props.creditCardEnabled}
        setPaymentMethodEnabled={props.setCreditCardEnabled}
        feePassthroughPercent={props.feePassthroughPercent}
        setFeePassthroughPercent={props.setFeePassthroughPercent}
        onChangeSwitch={() => {
          if (!props.achEnabled && props.creditCardEnabled) {
            props.setAchEnabled(!props.achEnabled)
          }
          props.setCreditCardEnabled(!props.creditCardEnabled)
        }}
        onChangeInput={async (e: ChangeEvent<HTMLInputElement>) => {
          if (parseInt(e.target.value) > 100) {
            await props.setFeePassthroughPercent('100')
          } else if (parseInt(e.target.value) < 0) {
            await props.setFeePassthroughPercent('0')
          } else {
            await props.setFeePassthroughPercent(
              e.target.value.replace(/[^0-9]/g, ''),
            )
          }
        }}
      />
      <Divider />
      <PaymentMethodEntity
        paymentMethod="ach"
        paymentMethodEnabled={props.achEnabled}
        setPaymentMethodEnabled={props.setAchEnabled}
        onChangeSwitch={() => {
          if (!props.creditCardEnabled && props.achEnabled) {
            props.setCreditCardEnabled(!props.creditCardEnabled)
          }
          props.setAchEnabled(!props.achEnabled)
        }}
        onChangeInput={(e: ChangeEvent<HTMLInputElement>) => {
          props.setFeePassthroughPercent(e.target.value.replace(/[^0-9]/g, ''))
        }}
      />
    </Flex>
  )
}

export default EnablePaymentMethods
