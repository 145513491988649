import Row from '../Layout/Row'
import CustomerItem, { Customer } from './CustomerItem'

export type CustomerRowProps = {
  customers: Customer[]
  onClick?: (customer: Customer) => void
}

export function CustomerRow({ customers, onClick }: CustomerRowProps) {
  return (
    <Row grow gap="medium" className="flex flex-wrap">
      {customers.map((customer, index) => (
        <CustomerItem
          key={index}
          customer={customer}
          onClick={() => onClick && onClick(customer)}
        />
      ))}
    </Row>
  )
}

export default CustomerRow
