import Layout from './layout/Layout'
import { useState, FormEventHandler, ChangeEventHandler } from 'react'
import { useMutation } from '@apollo/client'
import Loading from './utils/Loading'
import { ForgotPasswordDocument } from '../operations-types'
import {
  Card,
  CardBody,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from 'ui'

type ForgotPasswordFormProps = {
  onSubmit: FormEventHandler<HTMLFormElement>
  onChange: ChangeEventHandler<HTMLInputElement>
}

function ForgotPasswordForm({ onSubmit, onChange }: ForgotPasswordFormProps) {
  const inputStyle =
    'py-4 px-6 rounded-md border-2 text-lg border-slate-400 focus:border-slate-500 focus:ring-slate-500'
  const buttonStyle = `
    self-center bg-white text-xl text-nickel-purple rounded-md
    border-2 py-2 px-6 hover:text-white hover:bg-nickel-purple border-slate-200"
  `

  return (
    <form onSubmit={onSubmit} className="w-full">
      <Card h="100%" w="100%" bgColor="#ECEAE3">
        <CardBody h="100%" w="100%" p="8">
          <VStack w="100%" spacing="0" gap="6">
            <VStack w="100%" spacing="0" gap="6">
              <Text
                fontSize="xl"
                color="dark.600"
                alignSelf="center"
                fontWeight="medium"
              >
                Reset Password
              </Text>

              <Text color="gray.500">
                Enter the email associated with your account and we'll send you
                a link to reset your password.
              </Text>
            </VStack>

            <VStack w="100%" spacing="0" gap="0" alignItems="left">
              <FormLabel>Email</FormLabel>
              <Input
                className={inputStyle}
                onChange={onChange}
                type="text"
                id="email"
                name="email"
                placeholder="name@business.com"
                autoFocus
                bgColor="white"
              />
            </VStack>
            <Button
              w="100%"
              className={buttonStyle}
              type="submit"
              label="Send reset link"
              variant="solid"
              status="accent"
              color={'dark.600'}
              _hover={{
                bg: 'accent.500',
              }}
            />
          </VStack>
        </CardBody>
      </Card>
    </form>
  )
}

export default function PasswordReset() {
  const [email, setEmail] = useState('')

  const [forgotPassword, { loading, data }] = useMutation(
    ForgotPasswordDocument,
  )

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    forgotPassword({ variables: { email: email } })
  }

  return (
    <Layout>
      <div className="w-full max-w-sm">
        <div className="md:mt-24 mt-16">
          {data && (
            <Card h="100%" w="100%" bgColor="#ECEAE3">
              <CardBody h="100%" w="100%" p="8">
                <VStack w="100%" spacing="0" gap="6">
                  <VStack w="100%" spacing="0" gap="6">
                    <Text
                      fontSize="xl"
                      color="dark.600"
                      alignSelf="center"
                      fontWeight="medium"
                    >
                      Reset Password
                    </Text>

                    <Text color="gray.500">
                      If an email exists with that password, a link to reset
                      that account has been sent.
                    </Text>
                  </VStack>
                </VStack>
              </CardBody>
            </Card>
          )}
          {loading && <Loading />}
          {!loading && !data && (
            <ForgotPasswordForm
              onSubmit={onSubmit}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}
