import { Column, Header, Row } from 'ui'
import DashboardChart from './DashboardChart'
import DashboardTable from './DashboardTable'
import { PageLayout } from '../layout/PageLayout'

export default function DashboardHome() {
  return (
    <PageLayout>
      <Column gap="large" wGrow>
        <Header variant="page">Summary</Header>
        <Row gap="medium" className="items-stretch" grow>
          <DashboardChart />
        </Row>
        <DashboardTable />
      </Column>
    </PageLayout>
  )
}
