import React from 'react'

export default function NotificationArea({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end py-6 pointer-events-none  z-50"
    >
      {children}
    </div>
  )
}
