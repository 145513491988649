import { tw } from '@nickeltech/brise'
import { Field, FieldProps } from 'formik'
import { HTMLProps, MouseEventHandler } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Textarea } from '@chakra-ui/react'

import ContainerProps from '../../types'
import Column from '../Layout/Column'
import Spacing, { SpacingProps } from '../Layout/Spacing'
import { DisabledTransition, DisabledTransitionProps } from '../Transitions'
import Label from '../Typography/Label'
import some from 'lodash/some'
import pickBy from 'lodash/pickBy'
import includes from 'lodash/includes'
import PhoneInputs from './lib/PhoneInputValidations'
import upperCase from 'lodash/upperCase'

const MAX_CHARACTERS = 9999

export type TextInputProps = {
  label?: string
  onChange?: (e: any) => void
  hasError?: boolean
  hasSuccess?: boolean
  maxCharacters?: number
  type?: string
  onClick?: MouseEventHandler<HTMLInputElement>
  setValidatedField?: (valid: boolean) => void
  dataCy?: string
} & DisabledTransitionProps &
  SpacingProps &
  HTMLProps<HTMLInputElement> &
  Partial<FieldProps> &
  DisabledTransitionProps

const Input = tw.input<TextInputProps>`
  ${Spacing}
  ${DisabledTransition}

  outline-none
  border
  border-gray-300
  rounded
  w-full
  focus:outline-none
  active:outline-none
  px-3

  placeholder-gray-400

  ${(props) => (props.hasError ? 'text-[#e02c1f]' : '')}
  ${(props) => (props.hasSuccess ? 'text-[#292524]' : '')}
`

export const TextInputComponent = tw(
  ({ field, form, ...props }: FieldProps & ContainerProps & any) => {
    if (props.type === 'phone') {
      return (
        <PhoneInput
          {...field}
          {...props}
          country={'us'}
          onlyCountries={['us']}
          disableCountryCode
          disableDropdown
          containerClass="w-full"
          name={props.componentName}
          inputClass={`w-full border border-gray-300 placeholder-gray-400 px-3 py-2 rounded focus:outline-none focus:ring-0
           ${props.hasError ? 'text-[#e02c1f]' : ''} ${props.className}`}
          specialLabel=""
          onChange={(e) => {
            form.setFieldValue(field.name, e)
          }}
          isValid={(value, country: { iso2: string }) => {
            const validation = Object.values(
              pickBy(PhoneInputs, (value, key) =>
                some([upperCase(country.iso2)], (str) => includes(key, str)),
              ),
            ).at(0)

            if (props.setValidatedField && validation) {
              props.setValidatedField(new RegExp(validation).test(value))
            }
          }}
        />
      )
    } else if (props.type === 'multi') {
      return (
        <Textarea
          {...field}
          {...{
            className: props.className,
            placeholder: props.placeholder,
            onKeyDown: props.onKeyDown,
            hasError: props.hasError,
            hasSuccess: props.hasSuccess,
            onFocus: props.onFocus,
            onChange: (e) => {
              if (
                e.target.value.length <= (props.maxCharacters || MAX_CHARACTERS)
              ) {
                if (props.type && props.type === 'text') {
                  form.setFieldValue(
                    field.name,
                    e.target.value.replace(/[^a-zA-Z ]/gi, ''),
                  )
                } else if (props.type && props.type === 'number') {
                  form.setFieldValue(
                    field.name,
                    e.target.value.replace(/[^0-9]+/g, ''),
                  )
                } else {
                  form.setFieldValue(field.name, e.target.value)
                }
              }
            },
          }}
          spacing="small"
        />
      )
    } else {
      return (
        <Input
          {...field}
          {...{
            className: props.className,
            placeholder: props.placeholder,
            onKeyDown: props.onKeyDown,
            hasError: props.hasError,
            hasSuccess: props.hasSuccess,
            onFocus: props.onFocus,
            type: props.type,
            onChange: (e) => {
              if (
                e.target.value.length <= (props.maxCharacters || MAX_CHARACTERS)
              ) {
                if (props.type && props.type === 'text') {
                  form.setFieldValue(
                    field.name,
                    e.target.value.replace(/[^a-zA-Z ]/gi, ''),
                  )
                } else if (props.type && props.type === 'number') {
                  form.setFieldValue(
                    field.name,
                    e.target.value.replace(/[^0-9]+/g, ''),
                  )
                } else {
                  form.setFieldValue(field.name, e.target.value)
                }
              }
            },
          }}
          spacing="small"
        />
      )
    }
  },
)``

export const TextInput = (props: TextInputProps) => {
  return (
    <Column
      gap="small"
      {...{ disabled: props.disabled }}
      wGrow
      {...{
        onClick: props.onClick,
      }}
    >
      {props.label && props.label.length > 0 ? (
        <Label variant="secondary">{props.label}</Label>
      ) : (
        ''
      )}
      <Field
        type={props.type}
        setValidatedField={props.setValidatedField}
        onKeyDown={props.onKeyDown}
        maxCharacters={props.maxCharacters}
        name={props.name}
        componentName={props.name}
        placeholder={props.placeholder}
        className={props.className}
        component={TextInputComponent}
        hasError={props.hasError}
        hasSuccess={props.hasSuccess}
        onFocus={props.onFocus}
        id={props.id}
        data-cy={props['dataCy']}
      />
    </Column>
  )
}

export default TextInput
