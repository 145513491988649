import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'

export const NickelGray = tw((props: ContainerProps) => (
  <svg width="80" height="17" viewBox="0 0 80 17" fill="none" {...props}>
    <mask
      id="mask0_62_3949"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="80"
      height="17"
    >
      <rect x="0.203613" width="79.7966" height="16.8136" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_62_3949)">
      <path
        d="M29.3255 4.70559C30.5411 4.70559 31.5418 5.11328 32.3275 5.92864C33.1281 6.74401 33.5284 7.8707 33.5284 9.30871V16.1356H30.6597V9.66451C30.6597 8.92327 30.4596 8.35992 30.0593 7.97447C29.6591 7.5742 29.1254 7.37407 28.4583 7.37407C27.717 7.37407 27.124 7.60385 26.6793 8.06342C26.2345 8.52299 26.0122 9.21235 26.0122 10.1315V16.1356H23.1435V5.01692H26.0122V6.2622C26.7089 5.22446 27.8134 4.70559 29.3255 4.70559Z"
        fill="#6B7280"
      />
      <path
        d="M37.3118 3.68268C36.8375 3.68268 36.4224 3.51219 36.0666 3.17122C35.7256 2.81542 35.5551 2.40033 35.5551 1.92593C35.5551 1.45154 35.7256 1.03644 36.0666 0.680643C36.4224 0.324847 36.8375 0.146948 37.3118 0.146948C37.8011 0.146948 38.2162 0.324847 38.5571 0.680643C38.9129 1.03644 39.0908 1.45154 39.0908 1.92593C39.0908 2.40033 38.9129 2.81542 38.5571 3.17122C38.2162 3.51219 37.8011 3.68268 37.3118 3.68268ZM35.8887 16.1356V5.01692H38.7573V16.1356H35.8887Z"
        fill="#6B7280"
      />
      <path
        d="M46.6045 16.1079C44.9293 16.1079 43.5283 15.5445 42.4016 14.4179C41.2898 13.2912 40.7338 11.8976 40.7338 10.2372C40.7338 8.57686 41.2898 7.18333 42.4016 6.05664C43.5283 4.92995 44.9293 4.3666 46.6045 4.3666C47.6867 4.3666 48.6725 4.62604 49.562 5.14491C50.4515 5.66378 51.1261 6.36055 51.5856 7.23521L49.1173 8.68064C48.8949 8.22107 48.5539 7.85786 48.0944 7.59101C47.6496 7.32416 47.1456 7.19074 46.5822 7.19074C45.7224 7.19074 45.0108 7.47982 44.4475 8.05799C43.8841 8.62134 43.6024 9.34776 43.6024 10.2372C43.6024 11.1119 43.8841 11.8383 44.4475 12.4165C45.0108 12.9798 45.7224 13.2615 46.5822 13.2615C47.1604 13.2615 47.6719 13.1355 48.1166 12.8835C48.5762 12.6166 48.9172 12.2534 49.1395 11.7939L51.6301 13.217C51.1409 14.0917 50.4515 14.7959 49.562 15.3296C48.6725 15.8485 47.6867 16.1079 46.6045 16.1079Z"
        fill="#6B7280"
      />
      <path
        d="M63.3603 16.1356H60.0247L55.9775 11.0877V16.1356H53.1089V0.569457H55.9775V9.90912L59.8023 5.01692H63.2268L58.7571 10.5095L63.3603 16.1356Z"
        fill="#6B7280"
      />
      <path
        d="M67.126 11.4158C67.5114 12.8094 68.5566 13.5061 70.2615 13.5061C71.3585 13.5061 72.1887 13.1355 72.752 12.3943L75.0647 13.7285C73.9677 15.3148 72.3518 16.1079 70.217 16.1079C68.3787 16.1079 66.9036 15.552 65.7918 14.4401C64.6799 13.3282 64.124 11.9273 64.124 10.2372C64.124 8.56204 64.6725 7.1685 65.7695 6.05664C66.8666 4.92995 68.2749 4.3666 69.9946 4.3666C71.6253 4.3666 72.967 4.92995 74.0196 6.05664C75.087 7.18333 75.6206 8.57686 75.6206 10.2372C75.6206 10.6079 75.5836 11.0007 75.5095 11.4158H67.126ZM67.0815 9.19209H72.752C72.589 8.43603 72.248 7.87268 71.7291 7.50206C71.2251 7.13144 70.6469 6.94613 69.9946 6.94613C69.2237 6.94613 68.5862 7.14626 68.0822 7.54654C67.5782 7.93198 67.2446 8.4805 67.0815 9.19209Z"
        fill="#6B7280"
      />
      <path
        d="M77.5156 16.1356V-0.0976612H80.3842V16.1356H77.5156Z"
        fill="#6B7280"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21324 0.200091C8.56699 -0.0675926 7.84087 -0.0675921 7.19463 0.200092L3.4025 1.77084C2.75625 2.03853 2.24281 2.55197 1.97513 3.19821L0.404376 6.99035C0.136692 7.63659 0.136693 8.36271 0.404376 9.00895L1.97513 12.8011C2.24281 13.4473 2.75625 13.9608 3.4025 14.2285L7.19463 15.7992C7.84088 16.0669 8.56699 16.0669 9.21324 15.7992L13.0054 14.2285C13.6516 13.9608 14.1651 13.4473 14.4327 12.8011L16.0035 9.00895C16.2712 8.36271 16.2712 7.63659 16.0035 6.99034L14.4327 3.19821C14.1651 2.55197 13.6516 2.03853 13.0054 1.77084L9.21324 0.200091ZM11.7545 5.90455C12.1522 5.03977 11.2593 4.14699 10.3943 4.54464C9.03464 5.16972 7.46968 5.16972 6.11 4.54464C5.24505 4.14699 4.3521 5.03977 4.74982 5.90455C5.37503 7.26398 5.37503 8.82865 4.74982 10.1881C4.3521 11.0529 5.24505 11.9456 6.11 11.548C7.46968 10.9229 9.03464 10.9229 10.3943 11.548C11.2593 11.9456 12.1522 11.0529 11.7545 10.1881C11.1293 8.82865 11.1293 7.26398 11.7545 5.90455Z"
        fill="#6B7280"
      />
      <rect
        x="53.1079"
        y="-3.05176e-05"
        width="2.86847"
        height="0.474576"
        fill="#6B7280"
      />
    </g>
  </svg>
))``

export default NickelGray
