import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'

export type CheckmarkBoxIconProps = ContainerProps & { success?: boolean }

export const CheckmarkBoxIcon = tw((props: CheckmarkBoxIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect
      width="16"
      height="16"
      rx="4"
      fill={props.success ? '#31C48D' : '#D1D5DB'}
    />
    <path
      d="M4.5 8.5L6.5 10.5L11.5 5.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))`
`

export default CheckmarkBoxIcon
