export const formLabelTheme = {
  baseStyle: {
    color: 'gray.800',
    fontSize: 'sm',
  },
  variants: {
    small: {
      fontSize: 'xs',
      fontWeight: 'medium',
      color: 'gray.500',
    },
  },
}
