import { SelfQuery, UserRole } from '../../operations-types'
import { User } from '../../types'

const RoleValueMap: Record<UserRole, number> = {
  [UserRole.Admin]: 4,
  [UserRole.UserAdmin]: 3,
  [UserRole.Manager]: 2,
  [UserRole.User]: 1,
}

type Organization = NonNullable<
  NonNullable<NonNullable<SelfQuery['user']>['user']>['organization']
>

export class LoggedInUser {
  user: User
  organization: Organization

  constructor(user: User, organization: Organization) {
    this.user = user
    this.organization = organization
  }

  isUserPermitted(permissionLevel: UserRole) {
    return RoleValueMap[permissionLevel] <= RoleValueMap[this.user.role!]
  }

  getRole() {
    return this.user.role
  }
}

export function isActionPermitted(
  permissionLevel: UserRole,
  loggedInRole: UserRole,
) {
  return RoleValueMap[permissionLevel] <= RoleValueMap[loggedInRole]
}
