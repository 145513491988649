import { Box, Divider, Flex, Hide, HStack, Image, Text } from '@chakra-ui/react'
import { Icon, IconName } from 'ui'
import { STATIC_MERCHANT_LOGO } from '../onboarding/OnboardingHeader'

type HeaderProps = {
  children: React.ReactNode
  imageOnClick?: () => void
  rightIcon?: {
    iconName: IconName
    iconText: string
    onClick: () => void
  }
}

export function Header({ children, imageOnClick, rightIcon }: HeaderProps) {
  return (
    <Box>
      <Box px={10} py={5}>
        <Flex justifyContent="space-between" align="center" height="40px">
          <Hide below="md">
            <Image
              src={STATIC_MERCHANT_LOGO}
              alt="Organization Logo"
              style={{ objectFit: 'contain' }}
              height={100}
              width={100}
              cursor={imageOnClick ? 'pointer' : 'default'}
              onClick={imageOnClick}
            />
          </Hide>
          {children}
          {rightIcon ? (
            <HStack onClick={rightIcon.onClick} cursor="pointer">
              <Text color="gray.500" fontSize="md" fontWeight="400">
                {rightIcon.iconText}
              </Text>
              <Icon
                name={rightIcon.iconName}
                className="stroke-gray-500 stroke-2 !cursor-pointer"
              />
            </HStack>
          ) : (
            <div></div>
          )}
        </Flex>
      </Box>
      <Divider orientation="horizontal" />
    </Box>
  )
}
