import { FlexProps, Flex } from '@chakra-ui/react'

type FlexUtilProps = Omit<FlexProps, 'flexDirection'>

export const HFlex = (props: FlexUtilProps) => (
  <Flex {...props} flexDirection="row" />
)

export const VFlex = (props: FlexUtilProps) => (
  <Flex {...props} flexDirection="column" />
)
