import classNames from 'classnames'
import React from 'react'

type LoadingProps = {
  additionalClasses?: Object
}

const Loading: React.FC<LoadingProps> = ({ additionalClasses }) => {
  return (
    <div>
      <div
        className={classNames(
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          {
            ...additionalClasses,
          },
        )}
      >
        <img src="/3-dots-fade.svg" alt="spinner" />
      </div>
    </div>
  )
}

export default Loading
