import { Badge } from '@chakra-ui/react'
import { Column, Icon, Row, Separator, StatusBadge } from 'ui'
import TransactionStatus, {
  TransactionStatusValue,
} from 'ui/src/components/v3/TransactionStatus'
import { TransactionStatus as BadgeStatus } from 'ui/src/components/Transactions/StatusBadge'

type OverlayHeaderProps = {
  onBack?: () => void
  onClose: () => void
  transactionStatus: TransactionStatusValue
  check?: boolean
}

type InnerHeaderProps = {
  onBack?: () => void
  onClose: () => void
  badge: React.ReactNode
  check?: boolean
}

type OverlayHeaderV2Props = Omit<OverlayHeaderProps, 'transactionStatus'> & {
  transactionStatus: BadgeStatus
}

function InnerHeader({ onBack, onClose, badge, check }: InnerHeaderProps) {
  return (
    <Column wGrow>
      <Row
        y="center"
        className="px-8 h-[80px] w-full min-h-[80px]"
        between
        x="center"
      >
        <Row y="center" gap="medium">
          {badge}
          {check && (
            <Badge
              colorScheme="green"
              fontWeight="medium"
              paddingX={2}
              paddingY={0.5}
            >
              Check
            </Badge>
          )}
        </Row>
        <Row gap="medium">
          {onBack !== undefined && (
            <Icon
              name="leftArrow"
              size="medium"
              hoverable
              className="stroke-gray-500"
              onClick={onBack}
            />
          )}
          <Icon
            name="xMark"
            size="medium"
            hoverable
            className="stroke-gray-500"
            onClick={onClose}
          />
        </Row>
      </Row>
      <Separator className="w-full" orientation="horizontal" />
    </Column>
  )
}

export function OverlayHeaderV2({
  onBack,
  onClose,
  transactionStatus,
  check,
}: OverlayHeaderV2Props) {
  return (
    <InnerHeader
      {...{
        onBack,
        onClose,
        badge: <StatusBadge status={transactionStatus} />,
        check,
      }}
    />
  )
}

export function OverlayHeader({
  onBack,
  onClose,
  transactionStatus,
  check,
}: OverlayHeaderProps) {
  return (
    <InnerHeader
      {...{
        onBack,
        onClose,
        badge: <TransactionStatus {...{ transactionStatus }} />,
        check,
      }}
    />
  )
}
