import { Box, Fade, Flex, Icon, Text } from '@chakra-ui/react'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { NickelPlansHeader, NickelPlansHeaderProps } from './NickelPlansHeader'

type NixckelPlansLayoutProps = {
  title: string
  subtitle: string
  children: React.ReactNode
} & NickelPlansHeaderProps

export function NickelPlansLayout({
  title,
  subtitle,
  children,
  onGoBackClick,
  currentPage,
  onPageChange,
  breakdown,
}: NixckelPlansLayoutProps) {
  return (
    <Box>
      <NickelPlansHeader
        {...{ onGoBackClick, currentPage, onPageChange, breakdown }}
      />
      <Fade
        key={currentPage}
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          my={10}
        >
          <Flex flexDirection="column" gap={8}>
            <Flex flexDirection="column" gap={2}>
              {currentPage === 'receipt' && (
                <Icon as={CheckBadgeIcon} color="green.500" boxSize="12" />
              )}
              <Text fontSize="2xl" fontWeight="semibold">
                {title}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {subtitle}
              </Text>
            </Flex>
            {children}
          </Flex>
        </Flex>
      </Fade>
    </Box>
  )
}
