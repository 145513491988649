import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

export type RemoveKebabMenuProps = {
  onClick?: () => void
  offset?: [number, number]
}

export function RemoveKebabMenu(props: RemoveKebabMenuProps) {
  return (
    <Menu offset={props.offset}>
      <MenuButton
        pe="4"
        cursor="pointer"
        as={IconButton}
        aria-label="Options"
        style={{ width: '10px' }}
        icon={<Icon as={EllipsisVerticalIcon} boxSize="5" />}
        variant="unstyled"
        color="gray.500"
        onClick={(event) => {
          event.stopPropagation()
        }}
      />
      <MenuList minW="0" w="85px" zIndex={999} position="absolute">
        <Center>
          <MenuItem
            cursor="pointer"
            color="red.500"
            fontSize="sm"
            alignSelf="center"
            {...{
              onClick: props.onClick,
            }}
          >
            Remove
          </MenuItem>
        </Center>
      </MenuList>
    </Menu>
  )
}

export default RemoveKebabMenu
