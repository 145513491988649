import SimpleDropdown from '../../DropdownV2/SimpleDropdown'
import SkeletonLoader from '../../Loaders/SkeletonLoader'
import Text, { UnstandardizedText } from '../../Typography/Text'
import Row, { UnstandardizedRow } from '../Row'

export type TableTitleProps = {
  title: string
  loading?: boolean
  dropdownOptions?: Array<{ label: string }>
  dropdownOnClick?: (label: string) => void
  linkText?: string
  linkOnClick?: () => void
  filterComponent?: React.ReactNode
  downloadComponent?: React.ReactNode
}

export function TableTitle(props: TableTitleProps) {
  const optionsElements = (props.dropdownOptions || []).map((o) => {
    return {
      label: o.label,
      onClick: () => {
        props.dropdownOnClick && props.dropdownOnClick(o.label)
      },
    }
  })

  return (
    <UnstandardizedRow
      grow
      between
      y="center"
      className="p-5 border-gray-200 border-b"
      gap="small"
    >
      {props.loading ? (
        <SkeletonLoader className="h-5" count={1} />
      ) : (
        <>
          <Text className="text-lg">{props.title}</Text>
          <Row gap="small" y="center">
            {props.filterComponent && props.filterComponent}
            {props.dropdownOptions && (
              <SimpleDropdown options={optionsElements} label="Sort By" />
            )}
            {props.linkText && (
              <UnstandardizedText
                className="font-medium text-base text-primary-500 hover:underline cursor-pointer"
                onClick={props.linkOnClick}
              >
                {props.linkText}
              </UnstandardizedText>
            )}
            {props.downloadComponent && props.downloadComponent}
          </Row>
        </>
      )}
    </UnstandardizedRow>
  )
}
