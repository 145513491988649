import { tw } from '@nickeltech/brise'
import Row, { RowProps } from '../Layout/Row'
import capitalize from 'lodash/capitalize'
import {
  ExclamationTriangleIcon,
  ReceiptRefundIcon,
} from '@heroicons/react/24/solid'
import ContainerProps from '../../types'

export type TransactionStatus =
  | 'pending'
  | 'scheduled'
  | 'sent'
  | 'failed'
  | 'completed'
  | 'rejected'
  | 'cancelled'
  | 'completed_with_refund'
  | 'completed_with_return'
  | 'cancelled_with_refund'
  | 'cancelled_with_return_or_chargeback'
  | 'ACTIVE'

export const TransactionStatusValues = [
  'pending',
  'scheduled',
  'sent',
  'failed',
  'completed',
  'rejected',
  'cancelled',
  'completed_with_refund',
  'completed_with_return',
  'cancelled_with_return_or_chargeback',
  'ACTIVE',
]

export type StatusBadgeProps = {
  status: TransactionStatus
} & ContainerProps

export type StatusBadgeContainerProps = {
  badgeStatus?: TransactionStatus | null
} & RowProps

const StatusBadgeContainer = tw<StatusBadgeContainerProps>(Row)`
  border
  rounded
  px-2
  py-1
  w-fit
  text-xs
  font-medium
  border-none
  
  ${({ badgeStatus }) => {
    if (
      ['pending', 'scheduled', 'sent', 'processing'].includes(badgeStatus || '')
    ) {
      return `bg-gray-200 text-gray-700`
    } else if (badgeStatus === 'failed') {
      return `bg-red-200 text-red-500`
    } else if (
      badgeStatus === 'completed' ||
      badgeStatus?.includes('completed') ||
      badgeStatus === 'ACTIVE'
    ) {
      return `bg-green-200 text-green-500`
    } else if (
      badgeStatus === 'cancelled' ||
      badgeStatus?.includes('cancelled')
    ) {
      return `bg-gray-500 text-white`
    } else {
      return `bg-white text-black`
    }
  }}
`

const WhiteReceiptReturn = tw(ReceiptRefundIcon)`
  w-4
  h-4
`

const WhiteBackspace = tw(ExclamationTriangleIcon)`
  w-4
  h-4
`

export const StatusIconMap = {
  pending: false,
  scheduled: false,
  sent: false,
  rejected: false,
  failed: false,
  completed: false,
  cancelled: false,
  ACTIVE: false,
  completed_with_refund: <WhiteReceiptReturn />,
  completed_with_return: <WhiteBackspace />,
  cancelled_with_return_or_chargeback: <WhiteReceiptReturn />,
  cancelled_with_refund: <WhiteReceiptReturn />,
}

export const StatusBadge = (props: StatusBadgeProps) => {
  return (
    <StatusBadgeContainer y="center" gap="small" badgeStatus={props.status}>
      {capitalize(props.status.split('_')[0])}
      {StatusIconMap['completed_with_return']
        ? StatusIconMap[props.status]
        : ''}
    </StatusBadgeContainer>
  )
}

export default StatusBadge
