import { Menu } from '@headlessui/react'
import { ArrowsUpDownIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'
import Dropdown from '../DropdownV2'
import TextFilterMenu, {
  TextFilterMenuItemProps,
} from '../FilterDropdown/TextFilterMenu'
import Row from '../v2/layout/Row'

export type Filter = {
  label: string
  value: string
}

export type SortByProps = {
  items: Array<TextFilterMenuItemProps>
  selectedSort: string
  onClick: (value: string) => void
}

const ArrowUpAndDown = tw(ArrowsUpDownIcon)`
    w-4
    h-4
    stroke-gray-600
    stroke-2
`

const Text = tw.div<ContainerProps>`
    text-xs
    font-medium
    text-gray-600
    px-1
`

const Container = tw(Row)`

`

const ChevronDown = tw(ChevronDownIcon)`
    stroke-gray-400
    w-4
    stroke-2
`

const SortBy = (props: SortByProps) => {
  return (
    <Row gap="small" y="center">
      <Dropdown
        className="border !border-gray-300 h-[26px] relative top-[1px]"
        label={
          <Container y="center">
            <ArrowUpAndDown />
            <Text>Sort By</Text>
            <ChevronDown />
          </Container>
        }
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 focus:outline-none">
          <TextFilterMenu
            items={props.items}
            selected={
              props.selectedSort
                ? props.items.findIndex(
                    (item) => item.value === props.selectedSort,
                  )
                : 0
            }
            onClick={props.onClick}
          />
        </Menu.Items>
      </Dropdown>
    </Row>
  )
}

export default SortBy
