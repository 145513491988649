import { tw } from '@nickeltech/brise'
import Row from '../../Layout/Row'
import ContainerProps from '../../../types'
import Icon from '../../Icons'

export type DocumentEyeLayer = ContainerProps

export const DocumentEyeLayer = tw((props: DocumentEyeLayer) => {
  return (
    <Row {...props} x="center" y="center" grow>
      <Icon variant="primary" name="eye" />
    </Row>
  )
})`
  opacity-50
  absolute
  bg-white
  h-[124px]
`

export default DocumentEyeLayer
