import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { Row } from 'ui'

type PaymentElementsProps = {
  paymentNode?: ReactNode | ReactNode[]
  detailNode?: ReactNode | ReactNode[]
  onClick?: (idx: number) => void
}

const PaymentElements = (_props: PaymentElementsProps) => {
  const [selected, setSelected] = useState(0)

  return (
    <Row between className="w-full px-8 py-2">
      <Tabs
        className="w-full"
        onChange={(e) => {
          setSelected(e)
          _props.onClick && _props.onClick(e)
        }}
      >
        <TabList>
          <Tab
            color={selected === 0 ? 'dark.800' : 'dark.300'}
            paddingX={0}
            marginRight={5}
          >
            Transaction
          </Tab>
          <Tab
            color={selected === 1 ? 'dark.800' : 'dark.300'}
            paddingX={0}
            marginRight={5}
          >
            Detail
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel paddingX={0}>{_props.paymentNode}</TabPanel>
          <TabPanel paddingX={0}>{_props.detailNode}</TabPanel>
        </TabPanels>
      </Tabs>
    </Row>
  )
}

export default PaymentElements
