import { useQuery } from '@apollo/client'
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useState } from 'react'
import {
  BankAccountsDocument,
  GetAllMerchantPaymentMethodsDocument,
} from '../../operations-types'
import { QBOChartOfAccounts } from './QBOChartofAccounts'
import { QuickbooksAuthentication } from './QuickbooksAuthentication'

type QBOAuthFlowProps = {
  onFinish?: () => void
  onClick?: () => void
  alreadyConnected?: boolean
}

export function QBOAuthFlow({
  onFinish,
  onClick,
  alreadyConnected,
}: QBOAuthFlowProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const { data: user } = useQuery(BankAccountsDocument)

  const { data: paymentMethodData } = useQuery(
    GetAllMerchantPaymentMethodsDocument,
  )

  const bankAccount =
    user?.organization?.organization?.accountInfo?.bankAccounts?.at(0)
  const paymentMethod =
    paymentMethodData?.getAllMerchantPaymentMethods?.paymentMethods?.at(0)

  const accountString = `your current bank account at ${bankAccount?.bankName} ·· ${bankAccount?.lastTwo}`

  return (
    <>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent w="100%">
          <div className="modal-wrapper">
            <QBOChartOfAccounts
              type="bankAccount"
              accountString={accountString}
              payoutMethodId={bankAccount?.id}
              paymentMethodId={paymentMethod?.id}
              onCompleted={() => {
                setModalOpen(false)
                onFinish && onFinish()
              }}
            />
          </div>
        </ModalContent>
      </Modal>
      <QuickbooksAuthentication
        onClick={onClick}
        onCompleted={() => {
          if (bankAccount) {
            setModalOpen(true)
          } else {
            onFinish && onFinish()
          }
        }}
        alreadyConnected={alreadyConnected}
      />
    </>
  )
}
