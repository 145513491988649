import { Menu } from '@headlessui/react'
import { useFormikContext } from 'formik'

import { FilterBillStringComponent } from '../FilterDropdown/FilterComponent'
import FilterMenu from '../FilterDropdown/FilterMenu'
import { FilterItem } from '../PaymentLinks/PaymentLinksFilterComponent'
import Label from '../Typography/Label'
import Column from '../v2/layout/Column'
import FormButton from '../v3/button/FormButton'

export type BillsFilterDropdownProps = {
  label: string
  onClick?: (value: string) => void
}

export function BillsFilterDropdown({
  label,
  onClick,
}: BillsFilterDropdownProps) {
  const { submitForm, setSubmitting } = useFormikContext()

  const applyOnClick = async () => {
    await setSubmitting(true)

    await submitForm()

    await setTimeout(async () => {
      setSubmitting(false)
    }, 3000)
  }

  const items: FilterItem[] = [
    {
      label: 'Bill Reference Id',
      value: 'billReferenceId',
      iconName: 'magnifyingGlass',
      component: (
        <Column wGrow gap="medium">
          <FilterBillStringComponent
            fieldName="billReferenceId"
            label="Bill Reference Id"
            placeholder="Enter Bill Reference Id"
          />
        </Column>
      ),
    },
    {
      label: 'Vendor Name',
      value: 'vendorName',
      iconName: 'userCircle',
      component: (
        <Column wGrow gap="medium">
          <FilterBillStringComponent
            fieldName="vendorName"
            label="Vendor Name"
            placeholder="Enter Vendor Name"
          />
        </Column>
      ),
    },
    {
      label: 'Amount Due Value',
      value: 'amountDueEndValue',
      iconName: 'currencyDollar',
      component: (
        <Column gap="medium" wGrow spacingX="medium" spacingY="medium">
          <Column wGrow gap="small">
            <Label>Amount Due Start Value</Label>
            <FilterBillStringComponent
              fieldName="amountDueStartValue"
              label="Amount Due Start Value"
              type="currency"
              placeholder="Enter Amount Due Start Value"
            />
          </Column>
          <Column wGrow gap="small">
            <Label> Amount Due End Value</Label>
            <FilterBillStringComponent
              fieldName="amountDueEndValue"
              label=" Amount Due End Value"
              type="currency"
              placeholder="Enter Amount Due End Value"
            />
          </Column>
          <Column grow wGrow y="bottom">
            <FormButton
              x="right"
              status="primary"
              label="Apply"
              onClick={applyOnClick}
            />
          </Column>
        </Column>
      ),
    },
    {
      label: 'Bill Amount Value',
      value: 'billAmountEndValue',
      iconName: 'currencyDollar',
      component: (
        <Column gap="medium" wGrow spacingX="medium" spacingY="medium">
          <Column wGrow gap="small">
            <Label>Bill Amount Start Value</Label>
            <FilterBillStringComponent
              fieldName="billAmountStartValue"
              label="Bill Amount Start Value"
              type="currency"
              placeholder="Enter Bill Amount Start Value"
            />
          </Column>
          <Column wGrow gap="small">
            <Label>Bill Amount End Value</Label>
            <FilterBillStringComponent
              fieldName="billAmountEndValue"
              label="Bill Amount End Value"
              type="currency"
              placeholder="Enter Bill Amount End Value"
            />
          </Column>
          <Column grow wGrow y="bottom">
            <FormButton
              x="right"
              status="primary"
              label="Apply"
              onClick={applyOnClick}
            />
          </Column>
        </Column>
      ),
    },
    {
      label: 'Issued On Date',
      value: 'issuedOnStartDate',
      iconName: 'calendar',
      component: (
        <Column gap="medium" wGrow spacingX="medium" spacingY="medium">
          <Column gap="small" wGrow>
            <Label>Issued on Start Date</Label>
            <FilterBillStringComponent
              fieldName="issuedOnStartDate"
              label="Issued on Start Date"
              type="date"
            />
          </Column>
          <Column gap="small" wGrow>
            <Label>Issued on End Date</Label>
            <FilterBillStringComponent
              fieldName="issuedOnEndDate"
              label="Issued On End Date"
              type="date"
            />
          </Column>
          <Column grow wGrow y="bottom">
            <FormButton
              x="right"
              status="primary"
              label="Apply"
              onClick={applyOnClick}
            />
          </Column>
        </Column>
      ),
    },
    {
      label: 'Due Date',
      value: 'dueDateStartDate',
      iconName: 'calendar',
      component: (
        <Column wGrow spacingX="medium" spacingY="medium" gap="medium">
          <Column gap="small" wGrow>
            <Label>Due Date Start Date</Label>
            <FilterBillStringComponent
              fieldName="dueDateStartDate"
              label="Due Date Start Date"
              type="date"
            />
          </Column>
          <Column gap="small" wGrow>
            <Label>Due Date End Date</Label>
            <FilterBillStringComponent
              fieldName="dueDateEndDate"
              label="Due Date End Date"
              type="date"
            />
          </Column>
          <Column grow wGrow y="bottom">
            <FormButton
              x="right"
              status="primary"
              label="Apply"
              onClick={applyOnClick}
            />
          </Column>
        </Column>
      ),
    },
  ]

  return (
    <Menu.Items className="absolute left-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 focus:outline-none min-w-fit">
      <FilterMenu items={items} selected={0} onClick={onClick} />
    </Menu.Items>
  )
}

export default BillsFilterDropdown
