import { useState } from 'react'
import Row from '../Layout/Row'
import Separator from '../Layout/Separator'
import AddFilter from './AddFilter'
import DownloadCSV from './DownloadCSV'
import SortBy from './SortBy'
import StatusFilter from './StatusFilter'

export type TransactionsToolbarProps = {
  selectedSort: string
  setSelectedSort: (sort: string) => void
  sortItems: Array<{ label: string; value: string }>
  setStatusFilter: (e: string) => void
  statusFilter: string
  downloadCSV: () => Promise<void>
  onCustomFilter: (
    value?: Date,
    valueTwo?: Date,
    valueThree?: string,
    valueFour?: string,
  ) => void
  currentFilters: Array<{ label: string; value: string | Array<string> } | null>
  onClose: (label: string, value: string | Array<string>) => void
}

export const TransactionsToolbar = (props: TransactionsToolbarProps) => {
  const STATUS_FILTER_ITEMS = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Pending',
      value: 'PENDING',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Failed',
      value: 'FAILED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
  ]

  const [isLoading, setIsLoading] = useState(false)

  return (
    <Row y="center" between grow className="h-[26px]">
      <Row gap="small" className="h-[26px]">
        <StatusFilter
          {...{
            items: STATUS_FILTER_ITEMS,
            onClick: (e) => props.setStatusFilter(e),
            value: props.statusFilter,
          }}
        />
        <Separator orientation="vertical" className="relative top-[1px]" />
        <AddFilter
          {...{
            currentFilters: props.currentFilters,
            onClick: props.onCustomFilter,
            onClose: props.onClose,
          }}
        />
      </Row>
      <Row gap="small" y="center">
        <SortBy
          {...{
            items: props.sortItems,
            onClick: (e) => props.setSelectedSort(e),
            selectedSort: props.selectedSort,
          }}
        />
        <DownloadCSV
          {...{
            isLoading,
            onClick: async () => {
              setIsLoading(true)
              await props.downloadCSV()
              setIsLoading(false)
            },
          }}
        />
      </Row>
    </Row>
  )
}

export default TransactionsToolbar
