import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { useOutletContext } from 'react-router-dom'
import { ACHForm, Button, Form, OverlayHeader, Separator } from 'ui'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  DepositCheckDocument,
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  GetPaymentsDocument,
} from '../../operations-types'
import Sentry from '../../lib/sentry'
import currency from 'currency.js'

const ACHFormSchema = yup.object({
  checkAmount: yup
    .number()
    .typeError('Check number must be a number')
    .min(1, 'Check amount is required')
    .required('Check amount is required'),
  orderReference: yup.string().required('Order reference is required'),
  routingNumber: yup.string().length(9).required('Routing number is required'),
  accountNumber: yup
    .string()
    .min(5)
    .max(17)
    .required('Account number is required'),
  confirmedAccountNumber: yup
    .string()
    .min(5)
    .max(17)
    .oneOf([yup.ref('accountNumber'), null], 'Does not match with field!')
    .required('Required'),
  checkNumber: yup.string().required('Check number is required'),
  fullName: yup.string().required('Full name is required'),
})

type ACHFormData = yup.InferType<typeof ACHFormSchema>

type DepositCheckFieldProps = {
  label: string
  name: string
  value: string
  onChange?: React.ChangeEventHandler
  placeholder?: string
  error?: string
  dollar?: boolean
}

type DepositCheckOverlayContext = {
  exitOverlay: () => void
}

function DepositCheckField({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  dollar,
}: DepositCheckFieldProps) {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel verticalAlign="center">{label}</FormLabel>
      <InputGroup size="md">
        {dollar && (
          <InputLeftElement
            pointerEvents="none"
            color="gray.700"
            children="$"
          />
        )}
        <Input
          {...{ name, onChange, placeholder, value }}
          color={error ? 'red.600' : ''}
          _invalid={{
            boxShadow: 'none',
            borderColor: 'red.600',
          }}
          _focus={{
            boxShadow: 'none',
            outline: 'none',
            borderColor: 'gray.300',
          }}
        />
      </InputGroup>
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
    </FormControl>
  )
}

type CheckFooterProps = {
  onCancelClick: () => void
  onDepositClick: () => void
  loading?: boolean
  disabled?: boolean
}

function CheckFooter({
  onCancelClick,
  onDepositClick,
  loading,
  disabled,
}: CheckFooterProps) {
  return (
    <Box mt="auto">
      <Flex
        flexDirection="row"
        px={8}
        py={4}
        gap={4}
        justifyContent="flex-end"
        grow={1}
      >
        <Button
          variant="outline"
          status="primary"
          label="Cancel"
          size="lg"
          onClick={onCancelClick}
        />
        <Button
          status="primary"
          label="Deposit Check"
          size="lg"
          isDisabled={disabled}
          isLoading={loading}
          onClick={onDepositClick}
        />
      </Flex>
    </Box>
  )
}

export default function CheckOverlay() {
  const context = useOutletContext() as DepositCheckOverlayContext
  const [search, { data, loading }] = useLazyQuery<GetBankRoutingNumberQuery>(
    GetBankRoutingNumberDocument,
  )

  const toaster = useToast({ position: 'bottom-left' })

  const [depositCheck, { loading: depositCheckLoading }] = useMutation(
    DepositCheckDocument,
    {
      refetchQueries: [
        {
          query: GetPaymentsDocument,
        },
      ],
    },
  )

  return (
    <Form<ACHFormData>
      className="w-full h-full flex flex-col grow"
      validationSchema={ACHFormSchema}
      initialValues={{
        checkAmount: 0,
        orderReference: '',
        routingNumber: '',
        accountNumber: '',
        confirmedAccountNumber: '',
        checkNumber: '',
        fullName: '',
      }}
      onSubmit={async (values, helpers) => {
        const result = await depositCheck({
          variables: {
            amountCents: currency(values.checkAmount).intValue,
            orderReference: values.orderReference,
            routingNumber: values.routingNumber,
            accountNumber: values.accountNumber,
            checkNumber: values.checkNumber,
            fullName: values.fullName,
          },
        })

        if (
          result.data?.depositCheck?.error ||
          (result.errors || []).length > 0
        ) {
          Sentry.captureException(
            result.data?.depositCheck?.error?.message || result.errors?.at(0),
          )
          toaster({
            title: 'Error',
            description: result.data?.depositCheck?.error?.message,
            status: 'error',
            isClosable: true,
          })
        } else {
          toaster({
            title: 'Success',
            description: 'Your check has been submitted.',
            status: 'success',
            isClosable: true,
          })
          helpers.resetForm()
        }
      }}
    >
      {(formik) => {
        return (
          <Flex flexDirection="column" w="100%" h="100%" grow={1}>
            <OverlayHeader onClose={context.exitOverlay} />
            <Separator className="w-full" orientation="horizontal" />
            <Box p={8}>
              <Flex flexDirection="column" gap={6}>
                <Text color="gray.800" fontSize="xl" fontWeight="semibold">
                  Deposit Check
                </Text>
                <Flex flexDirection="column" gap={4}>
                  <DepositCheckField
                    name="checkAmount"
                    label="Check Amount"
                    placeholder="Enter the check amount"
                    value={
                      formik.values.checkAmount > 0
                        ? formik.values.checkAmount.toString()
                        : ''
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.checkAmount
                        ? formik.errors.checkAmount
                        : undefined
                    }
                    dollar
                  />
                  <DepositCheckField
                    name="orderReference"
                    label="Order Reference"
                    placeholder="Enter an order reference #"
                    onChange={formik.handleChange}
                    value={formik.values.orderReference}
                    error={
                      formik.touched.orderReference
                        ? formik.errors.orderReference
                        : undefined
                    }
                  />
                </Flex>
              </Flex>
            </Box>
            <Separator className="w-full" orientation="horizontal" />
            <Box p={8}>
              <Flex flexDirection="column" gap={4}>
                <DepositCheckField
                  label="Check Account Owner's Full Name"
                  name="fullName"
                  placeholder="Enter account owner's full name"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  error={
                    formik.touched.fullName ? formik.errors.fullName : undefined
                  }
                />
                <ACHForm
                  loading={loading}
                  bankName={
                    data?.bankRoutingNumber?.bankRoutingNumber?.bankName
                  }
                  routingNumber={formik.values.routingNumber}
                  routingNumberLabel="Check Routing Number"
                  accountNumberLabel="Check Account Number"
                  accountNumber={formik.values.accountNumber}
                  confirmedAccountNumber={formik.values.confirmedAccountNumber}
                  onChangeRoutingNumber={(routingNumber: string) => {
                    if (routingNumber.length === 9) {
                      search({
                        variables: {
                          bankRoutingNumber: routingNumber,
                        },
                      })
                    }
                    formik.setFieldValue('routingNumber', routingNumber)
                  }}
                  onChangeAccountNumber={(accountNumber: string) =>
                    formik.setFieldValue('accountNumber', accountNumber)
                  }
                  onChangeConfirmAccountNumber={(
                    confirmAccountNumber: string,
                  ) =>
                    formik.setFieldValue(
                      'confirmedAccountNumber',
                      confirmAccountNumber,
                    )
                  }
                />
                <DepositCheckField
                  label="Check Number"
                  name="checkNumber"
                  placeholder="Enter check number"
                  onChange={formik.handleChange}
                  value={formik.values.checkNumber}
                  error={
                    formik.touched.checkNumber
                      ? formik.errors.checkNumber
                      : undefined
                  }
                />
              </Flex>
            </Box>
            <CheckFooter
              onCancelClick={context.exitOverlay}
              onDepositClick={() => formik.submitForm()}
              loading={depositCheckLoading}
              disabled={Object.entries(formik.errors).length > 0}
            />
          </Flex>
        )
      }}
    </Form>
  )
}
