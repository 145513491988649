import { HStack, VStack, Icon, Text } from '@chakra-ui/react'
import { BuildingLibraryIcon } from '@heroicons/react/24/outline'

type PayoutMethodEntityProps = {
  bankName: string
  routingNumber: string
  holderName: string
  lastTwo: string
  type: string
}

export const PayoutMethodEntity = ({
  bankName,
  routingNumber,
  holderName,
  lastTwo,
  type,
}: PayoutMethodEntityProps) => {
  return (
    <>
      <VStack gap="0" spacing="0" alignItems="left">
        <HStack>
          <Icon as={BuildingLibraryIcon} color="gray.500" boxSize="5" />
          <Text fontWeight="medium">{bankName}</Text>
          <Text fontWeight="medium">{lastTwo ? `•••${lastTwo}` : ''}</Text>
        </HStack>
        <Text color="gray.500">
          {routingNumber ? `Routing: ${routingNumber}` : ''}
        </Text>
        <HStack>
          <Text color="gray.500">
            {type ? type.split('')[0].toUpperCase() + type.slice(1) + ' •' : ''}
          </Text>
          <Text color="gray.500">{!!holderName ? holderName : ''}</Text>
        </HStack>
      </VStack>
    </>
  )
}
