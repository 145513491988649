import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Overlay } from 'ui'
import { TRADE_ACCOUNTS_URL } from '../../../lib/urls'

type TradeAccountOutletProps = {
  subdomain: string
}
export function TradeAccountOutlet(props: TradeAccountOutletProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(TRADE_ACCOUNTS_URL)

  const tradeAccountMatch = location.pathname.match(
    /\/dashboard\/trade-accounts\/(.*)/,
  )

  return (
    <Overlay
      open={!!tradeAccountMatch}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet
        context={{
          subdomain: props.subdomain,
        }}
      />
    </Overlay>
  )
}
