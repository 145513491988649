import ContainerProps from 'ui/src/types'

type Props = {} & ContainerProps

const NickelLogo = (props: Props) => {
  return (
    <svg
      width="50"
      height="25"
      viewBox="0 0 121 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_965_13251"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="121"
        height="27"
      >
        <rect y="0.738281" width="121" height="25.4954" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_965_13251)">
        <path
          d="M44.1589 7.5465C46.0023 7.5465 47.5197 8.1647 48.7111 9.40109C49.925 10.6375 50.532 12.3459 50.532 14.5265V24.8784H46.1821V15.066C46.1821 13.942 45.8786 13.0878 45.2717 12.5033C44.6647 11.8963 43.8555 11.5929 42.8439 11.5929C41.7199 11.5929 40.8207 11.9413 40.1463 12.6382C39.4719 13.335 39.1347 14.3804 39.1347 15.7741V24.8784H34.7849V8.01858H39.1347V9.90688C40.1913 8.3333 41.866 7.5465 44.1589 7.5465Z"
          fill="#501C3F"
        />
        <path
          d="M56.2691 5.9954C55.5497 5.9954 54.9203 5.73688 54.3808 5.21985C53.8638 4.68033 53.6052 4.0509 53.6052 3.33154C53.6052 2.61219 53.8638 1.98276 54.3808 1.44324C54.9203 0.903726 55.5497 0.633969 56.2691 0.633969C57.0109 0.633969 57.6404 0.903726 58.1574 1.44324C58.6969 1.98276 58.9667 2.61219 58.9667 3.33154C58.9667 4.0509 58.6969 4.68033 58.1574 5.21985C57.6404 5.73688 57.0109 5.9954 56.2691 5.9954ZM54.111 24.8784V8.01858H58.4609V24.8784H54.111Z"
          fill="#501C3F"
        />
        <path
          d="M70.3604 24.8366C67.8202 24.8366 65.6959 23.9823 63.9874 22.2739C62.3014 20.5654 61.4585 18.4523 61.4585 15.9346C61.4585 13.4168 62.3014 11.3037 63.9874 9.59528C65.6959 7.88682 67.8202 7.03259 70.3604 7.03259C72.0015 7.03259 73.4964 7.42598 74.8452 8.21278C76.194 8.99957 77.2168 10.0561 77.9137 11.3824L74.1708 13.5742C73.8336 12.8773 73.3165 12.3266 72.6197 11.9219C71.9453 11.5173 71.181 11.315 70.3267 11.315C69.0229 11.315 67.9439 11.7533 67.0896 12.6301C66.2354 13.4843 65.8083 14.5858 65.8083 15.9346C65.8083 17.2609 66.2354 18.3624 67.0896 19.2391C67.9439 20.0933 69.0229 20.5205 70.3267 20.5205C71.2034 20.5205 71.979 20.3294 72.6534 19.9472C73.3503 19.5426 73.8673 18.9918 74.2045 18.295L77.9811 20.453C77.2393 21.7793 76.194 22.8471 74.8452 23.6564C73.4964 24.4432 72.0015 24.8366 70.3604 24.8366Z"
          fill="#501C3F"
        />
        <path
          d="M95.7681 24.8784H90.7102L84.5732 17.2241V24.8784H80.2233V1.27464H84.5732V15.4369L90.373 8.01858H95.5658L88.7881 16.3473L95.7681 24.8784Z"
          fill="#501C3F"
        />
        <path
          d="M101.478 17.7217C102.063 19.8348 103.648 20.8914 106.233 20.8914C107.896 20.8914 109.155 20.3294 110.009 19.2054L113.516 21.2286C111.853 23.6339 109.403 24.8366 106.165 24.8366C103.378 24.8366 101.141 23.9936 99.4552 22.3076C97.7692 20.6216 96.9262 18.4973 96.9262 15.9346C96.9262 13.3944 97.758 11.2813 99.4215 9.59528C101.085 7.88682 103.221 7.03259 105.828 7.03259C108.301 7.03259 110.335 7.88682 111.931 9.59528C113.55 11.3037 114.359 13.4168 114.359 15.9346C114.359 16.4966 114.303 17.0923 114.191 17.7217H101.478ZM101.411 14.3498H110.009C109.762 13.2033 109.245 12.3491 108.458 11.7871C107.694 11.2251 106.817 10.9441 105.828 10.9441C104.659 10.9441 103.693 11.2475 102.928 11.8545C102.164 12.439 101.658 13.2707 101.411 14.3498Z"
          fill="#501C3F"
        />
        <path
          d="M117.233 24.8784V0.263053H121.582V24.8784H117.233Z"
          fill="#501C3F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6618 1.04173C12.6819 0.635828 11.5808 0.635829 10.6009 1.04173L4.85065 3.42355C3.87071 3.82946 3.09215 4.60802 2.68625 5.58796L0.304428 11.3382C-0.101477 12.3181 -0.101476 13.4192 0.304429 14.3991L2.68625 20.1493C3.09215 21.1293 3.87071 21.9078 4.85065 22.3137L10.6009 24.6956C11.5808 25.1015 12.6819 25.1015 13.6618 24.6956L19.412 22.3137C20.392 21.9078 21.1705 21.1293 21.5764 20.1493L23.9583 14.3991C24.3642 13.4192 24.3642 12.3181 23.9583 11.3382L21.5764 5.58796C21.1705 4.60802 20.392 3.82946 19.412 3.42355L13.6618 1.04173ZM17.5153 9.6917C18.1184 8.38038 16.7643 7.02661 15.4528 7.62958C13.391 8.57744 11.018 8.57744 8.9562 7.62958C7.64462 7.02661 6.2906 8.38038 6.89368 9.6917C7.84172 11.7531 7.84172 14.1257 6.89368 16.187C6.2906 17.4984 7.64462 18.8521 8.9562 18.2492C11.018 17.3013 13.391 17.3013 15.4528 18.2492C16.7643 18.8521 18.1184 17.4984 17.5153 16.187C16.5673 14.1257 16.5673 11.7531 17.5153 9.6917Z"
          fill="#501C3F"
        />
        <rect
          x="80.2222"
          y="0.738281"
          width="4.34963"
          height="0.719627"
          fill="#501C3F"
        />
      </g>
    </svg>
  )
}

export default NickelLogo
