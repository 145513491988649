import { GetPayoutQuery } from '../../operations-types'

export type Payout = NonNullable<
  NonNullable<GetPayoutQuery['payout']>
>['payout']

export class PayoutHelper {
  payout: Payout

  constructor(payout: Payout) {
    this.payout = payout
  }

  amountChargedCents(): number {
    return (this.payout.charges || [])
      .map((x) => x.amountCapturedInCents || 0)
      .reduce((a, b) => a + b, 0)
  }

  amountSubmittedDollars(): number {
    return this.amountSubmittedCents() / 100
  }

  // Fees are negative if they're a debit
  feeCents(): number {
    return (this.payout.fees || [])
      .map((x) => x.amountInCents || 0)
      .reduce((a, b) => a + b, 0)
  }

  feeDollars(): number {
    return this.feeCents() / 100
  }

  amountSubmittedCents(): number {
    return this.amountChargedCents() + this.feeCents()
  }

  amountChargedDollars(): number {
    return this.amountChargedCents() / 100
  }

  refundAmountCents(): number {
    return (this.payout.refunds || [])
      .map((x) => x.amountCents || 0)
      .reduce((a, b) => a + b, 0)
  }

  returnAmountCents(): number {
    return (this.payout.returns || [])
      .map((x) => x.amountCents || 0)
      .reduce((a, b) => a + b, 0)
  }

  returnAmountDollars(): number {
    return this.returnAmountCents() / 100
  }

  refundAmountDollars(): number {
    return this.refundAmountCents() / 100
  }

  netAmountCents(): number {
    return (
      this.amountChargedCents() -
      this.refundAmountCents() -
      this.returnAmountCents() +
      this.feeCents()
    )
  }

  netAmountDollars(): number {
    return this.netAmountCents() / 100
  }

  hasDiscrepancy(): boolean {
    return this.netAmountCents() !== this.payout.amountInCents
  }

  discrepancyAmountCents(): number {
    return this.payout.amountInCents - this.netAmountCents()
  }
}
