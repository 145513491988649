import { VStack } from '@chakra-ui/react'
import { NotificationEntity } from './NotificationEntity'

type MerchantNotificationItemProps = {
  title: string
  description: string
  emails: string[]
  disabled: boolean
  onSwitchClick: () => void
  onAddClick: () => void
  onRemoveEmailClick: (email: string) => void
}

export function MerchantNotificationItem(props: MerchantNotificationItemProps) {
  return (
    <NotificationEntity
      title={props.title}
      description={props.description}
      disabled={props.disabled}
      onSwitchClick={props.onSwitchClick}
      onAddClick={props.onAddClick}
      onEmailClick={props.onRemoveEmailClick}
      emails={props.emails}
    />
  )
}

type MerchantNotificationProps = {
  children: React.ReactNode
}

export function MerchantNotifications({ children }: MerchantNotificationProps) {
  return (
    <VStack
      spacing="0"
      width="100%"
      maxWidth={['700px', '700px', '100%']}
      gap={4}
      pt={4}
    >
      {children}
    </VStack>
  )
}
