import { tw } from '@nickeltech/brise'

export type DocumentImageProps = {
  src: string
}

export const DocumentImage = tw.img<DocumentImageProps>`
  rounded
  w-10
  h-10
`

export default DocumentImage
