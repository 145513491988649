import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetPayoutsDocument, PayoutMode } from '../../operations-types'
import { Payout, Payouts } from '../../types'
import { StatusCell } from '../payments/utils'
import moment from 'moment'
import formatter from 'ui/src/formatter'
import { Button, Header, Row, Table } from 'ui'
import { PageLayout } from '../layout/PageLayout'
import { SettlementsOverlay } from '../payouts/PayoutsOverlay'
import { SETTLEMENTS_URL } from '../../lib/urls'
import {
  getPaymentMethodPayoutIdentifier,
  getPayoutMethodIdentifier,
  reportErrorIfExists,
} from '../../lib/utils'
import { capitalize } from 'lodash'
import currency from 'currency.js'

type PayoutPresenter = {
  id: string
  createdAt: number
  paidAt?: string | null
  status: StatusCell
  amountInCents: number
  amount: number
}

const statusMap: Record<string, StatusCell> = {
  Paid: {
    label: 'Paid',
    status: 'success',
  },
  Pending: {
    label: 'Pending',
    status: 'action',
  },
  Failed: {
    label: 'Failed',
    status: 'error',
  },
  Canceled: {
    label: 'Canceled',
    status: 'error',
  },
  '': {
    label: 'Unknown',
    status: 'warning',
  },
}

const typeMap: Record<string, StatusCell> = {
  Charge: {
    label: 'Payment',
    status: 'success',
  },
  Refund: {
    label: 'Refund',
    status: 'action',
  },
  Return: {
    label: 'ACH Return',
    status: 'error',
  },
  '': {
    label: 'Unknown',
    status: 'warning',
  },
}

const SORTBY_TO_SORT_FUNC: {
  [index: string]: (a: PayoutPresenter, b: PayoutPresenter) => number
} = {
  createdAt: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.createdAt - b.createdAt,
  status: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.status.label.localeCompare(b.status.label),
  id: (a: PayoutPresenter, b: PayoutPresenter) => a.id.localeCompare(b.id),
  amount: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.amountInCents - b.amountInCents,
  paidAt: (a: PayoutPresenter, b: PayoutPresenter) =>
    moment(a.paidAt).diff(moment(b.paidAt)),
}

function transformQueryData(
  payout: Payouts,
  sortBy: string,
  sortDirection: string,
) {
  const mappedPayouts: PayoutPresenter[] = [...payout].map((payout: Payout) => {
    const charge = payout.charges?.[0]
    const refund = payout.refunds?.[0]
    const achReturn = payout.returns?.[0]

    if (!charge && !refund && !achReturn) {
      console.error('No charge or refund found for payout')

      return {
        ...payout,
        createdAt: parseInt(payout.createdAt),
        direction: payout.amountInCents > 0 ? 'left' : 'right',
        amount: currency(Math.abs(payout.amountInCents), { fromCents: true })
          .value,
        payoutMethod: getPayoutMethodIdentifier(payout.payoutMethod || null),
        paymentMethod: {},
        status: statusMap[capitalize(payout.status || '')],
        type: '',
        reference: {
          type: 'Unknown',
          for: '',
        },
      }
    }

    return {
      ...payout,
      createdAt: parseInt(payout.createdAt),
      amount: currency(Math.abs(payout.amountInCents), { fromCents: true })
        .value,
      direction: payout.amountInCents > 0 ? 'left' : 'right',
      payoutMethod: getPayoutMethodIdentifier(payout.payoutMethod || null),
      paymentMethod: getPaymentMethodPayoutIdentifier(
        charge?.paymentMethod ||
          refund?.charge.paymentMethod ||
          achReturn?.charge.paymentMethod ||
          null,
      ),
      status: statusMap[capitalize(payout.status || '')],
      type: typeMap[
        charge?.__typename || refund?.__typename || achReturn?.__typename || ''
      ],
      reference:
        charge?.payment?.notes ||
        refund?.charge?.payment?.notes ||
        achReturn?.charge?.payment?.notes ||
        '',
    }
  })

  mappedPayouts.sort((a, b) => {
    return (
      SORTBY_TO_SORT_FUNC[sortBy](a, b) * (sortDirection === 'desc' ? -1 : 1)
    )
  })

  return mappedPayouts
}

function convertPayoutToCsv(payouts: Payouts) {
  const mappedPayouts = transformQueryData(payouts, 'paidAt', 'desc')
  const csv = [['ID', 'Created At', 'Paid At', 'Amount', 'Status'].join(',')]

  mappedPayouts.forEach((payout) => {
    csv.push(
      [
        payout.id,
        `"${new Date(payout.createdAt).toLocaleString()}"`,
        payout.paidAt || 'N/A',
        `"${formatter.format(payout.amount)}"`,
        payout.status.label,
      ].join(','),
    )
  })

  return csv.join('\n')
}

function downloadDataAsCSV(payments: Payouts) {
  const a = document.createElement('a')
  a.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
    convertPayoutToCsv(payments),
  )}`
  a.target = '_blank'
  a.download = 'payouts.csv'
  a.click()
  a.remove()
}

export function Settlements() {
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState('paidAt')
  const [sortDirection, setSortDirection] = useState('desc')

  const pageSize = 20

  const queryOpts = {
    page: page,
    pageSize: pageSize,
    payoutMode: PayoutMode.Gross,
  }

  const { loading, data, error } = useQuery(GetPayoutsDocument, {
    variables: {
      ...queryOpts,
    },
  })

  reportErrorIfExists(data?.payouts?.error?.message || error)

  const payouts = data?.payouts?.payouts || []

  const totalResults = data?.payouts?.totalResults || 0
  const totalPages = Math.ceil(totalResults / pageSize)
  const tableData = transformQueryData(payouts, sortBy, sortDirection)

  const table = (
    <Table
      {...{
        loading: loading,
        rowSize: 'large',
        cellSize: 'large',
        downloadComponent: (
          <Button
            label="Download"
            size="xs"
            variant="outline"
            status="secondary"
            onClick={() => downloadDataAsCSV(payouts)}
          />
        ),
        headers: [
          {
            type: 'date',
            keyName: 'createdAt',
            label: 'Date',
          },
          {
            type: 'currency',
            keyName: 'amount',
            label: 'Amount',
          },
          {
            type: 'methodWithName',
            keyName: 'payoutMethod',
            label: 'Your Account',
            grow: 1.5,
          },
          {
            type: 'direction',
            keyName: 'direction',
            label: '',
            grow: 0.5,
          },
          {
            type: 'methodWithName',
            keyName: 'paymentMethod',
            label: 'To / From',
            grow: 1.5,
          },
          {
            type: 'status',
            keyName: 'type',
            label: 'Type',
          },
          {
            type: 'reference',
            keyName: 'reference',
            label: 'Order Reference',
            grow: 1.5,
          },
          {
            type: 'status',
            keyName: 'status',
            label: 'Settlement Status',
          },
        ],
        data: tableData,
        page: page,
        perPage: pageSize,
        totalPages: totalPages,
        onChange: () => {},
        onSort: (sortBy, sortOrder) => {
          setSortBy(sortBy)
          setSortDirection(sortOrder)
        },
        onClick: (x: any) => navigate(`${SETTLEMENTS_URL}/${x.id}`),
        onPage: (page: number) => {
          setPage(page)
        },
      }}
    />
  )

  return (
    <PageLayout>
      <Row className="pb-4">
        <Header variant="page">Settlements</Header>
      </Row>
      {table}
      <SettlementsOverlay />
    </PageLayout>
  )
}
