import {
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import OnboardingBusinessInfo from './OnboardingBusinessInfo'
import { OnboardingDocuments } from './OnboardingDocuments'
import OnboardingSuccess from './OnboardingSuccess'
import PlaidLink from './PlaidLink'

type OnboardingProps = {
  isOpen: boolean
  onClose: () => void
}

type OnboardingDrawerProps = {
  element: ReactElement
  isOpen: boolean
  onClose: () => void
}
function OnboardingDrawer(props: OnboardingDrawerProps) {
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        placement="right"
        onClose={props.onClose}
        size="lg"
        trapFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Onboarding</DrawerHeader>

          <DrawerBody>{props.element}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function Onboarding({ isOpen, onClose }: OnboardingProps) {
  return (
    <OnboardingDrawer
      isOpen={isOpen}
      onClose={onClose}
      element={
        <VStack>
          <Tabs colorScheme="dark">
            <TabList>
              <Tab>Business Info</Tab>
              {import.meta.env.MODE === 'development' && (
                <Tab>Required Documents</Tab>
              )}
              {import.meta.env.MODE === 'development' && <Tab>Plaid</Tab>}
            </TabList>

            <TabPanels>
              <TabPanel w="100%">
                <OnboardingBusinessInfo drawer={true} />
              </TabPanel>
              <TabPanel w="100%">
                <OnboardingDocuments />
              </TabPanel>
              <TabPanel>
                <PlaidLink methodType="both" onboarding={true} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      }
    />
  )
}

type OnboardingRegistrationProps = {
  setStep: (value: string) => void
}

export function OnboardingRegistration(props: OnboardingRegistrationProps) {
  return <OnboardingBusinessInfo drawer={false} setStep={props.setStep} />
}

export function OnboardingRegistrationSuccess() {
  return (
    <Card
      alignContent="center"
      justifyContent="center"
      justifyItems="center"
      alignItems="center"
      p="4"
    >
      <CardBody>
        <OnboardingSuccess />
      </CardBody>
    </Card>
  )
}
export default Onboarding
