import { useMutation } from '@apollo/client'
import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { CopyInput, FormButton, FormikValidatingInput } from 'ui'
import * as yup from 'yup'
import { CreateProjectSheetDocument } from '../../../operations-types'

type NewProjectModalProps = {
  isOpen: boolean
  onClose: () => void
  tradeAccountId: string
}

const InviteSchema = yup.object().shape({
  projectName: yup.string().required('Required'),
})

type ModalStates = 'invite' | 'success'

export function NewProjectModal({
  isOpen,
  onClose,
  tradeAccountId,
}: NewProjectModalProps) {
  const toast = useToast()

  const [modalState, setModalState] = useState<ModalStates>('invite')
  const [inviteUrl, setInviteUrl] = useState<string | null>(null)

  const [createProject] = useMutation(CreateProjectSheetDocument, {
    onCompleted: (data, options) => {
      if (data.createProjectSheet?.url) {
        setModalState('success')
        setInviteUrl(data.createProjectSheet.url)
      } else {
        toast({
          title: 'Error',
          description: 'There was an error inviting the customer',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
  })

  const modalStateToComponent: Record<ModalStates, JSX.Element> = {
    invite: (
      <>
        <Text fontSize="xl" fontWeight="semibold" color="gray.800" pt={4}>
          Add a project
        </Text>

        <Formik
          initialValues={{
            projectName: '',
          }}
          validationSchema={InviteSchema}
          onSubmit={(values) => {
            createProject({
              variables: {
                projectName: values.projectName,
                tradeAccountId: tradeAccountId,
              },
            })
          }}
        >
          <Form>
            <Box py={4}>
              <Flex flexDirection={'column'} gap={4}>
                <FormikValidatingInput
                  label="Project Name"
                  fieldName="projectName"
                  placeholder="Enter Project Name"
                />

                <FormButton label="Create Project Sheet" className="w-full" />
              </Flex>
            </Box>
          </Form>
        </Formik>
      </>
    ),
    success: (
      <Flex flexDirection="column" gap={2} py={4}>
        <ModalCloseButton />
        <Text fontSize="xl" fontWeight="semibold" color="gray.800">
          Success
        </Text>
        <Text fontSize="medium" color="gray.800">
          You can share the link below with your customer, or complete the
          information yourself.
        </Text>
        <CopyInput clipboardValue={inviteUrl!} size="sm" />
      </Flex>
    ),
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setModalState('invite')
      }}
    >
      <ModalOverlay />
      <ModalContent px={4}>{modalStateToComponent[modalState]}</ModalContent>
    </Modal>
  )
}
