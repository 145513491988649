import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  Column,
  Label,
  Row,
  Separator,
  StatusBadge,
  TypeTransactionStatus,
} from 'ui'
import { ReactNode, useState } from 'react'
import {
  RemoveSubscriptionDocument,
  SubscriptionPaymentsDocument,
} from '../../../operations-types'
import { useMutation, useQuery } from '@apollo/client'
import currency from 'currency.js'
import moment from 'moment'

type SubscriptionCardProps = {
  onAutoRenewChange: (renew: boolean) => void
  lastFour?: string
  lastTwo?: string
  cardBrand?: string
  cardBrandIcon?: ReactNode
  cardExpiry?: string
  planName: string
  cycleNumber?: number
  activated?: boolean
  numberOfBillingCycles?: number
  billingFrequency?: number
  onUpdatePaymentMethod: () => void
  createSubscription: (token: string) => void
  autoRenew: boolean
  nextPayment: {
    price: string
    date: string
    initiated: boolean
  }
}

const LabelStyle = 'text-sm font-semibold text-gray-500 !w-[200px]'
const SubLabelStyle = 'text-xs font-medium text-gray-400'
const LinkStyle = 'cursor-pointer hover:underline text-blue-600'

type BillingHistoryTransactionProps = {
  amountInCents: number
  completedAt: string
  status: string
}

function BillingHistoryTransaction(props: BillingHistoryTransactionProps) {
  return (
    <Card w="100%" boxShadow="0">
      <CardBody
        w="100%"
        border="2px solid"
        borderRadius="4"
        borderColor="gray.200"
        bgColor="gray.100"
        role="group"
        px="5"
        py="0"
      >
        <HStack w="100%">
          <VStack w="100%" alignItems="left">
            <Text fontSize="lg" fontWeight="semibold">
              {currency(props.amountInCents, {
                fromCents: true,
              }).format()}
            </Text>
            <Text color="gray.500">
              Paid on {moment(parseInt(props.completedAt)).format('MM/DD/YYYY')}
            </Text>
          </VStack>
          <VStack w="100%" alignItems="end" p="4" gap="0" spacing="0">
            <Tooltip
              hasArrow
              label={'Tooltip text on status'}
              bgColor="gray.500"
              p={3}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  left: '8px',
                  justifyContent: 'end',
                  flexGrow: 1,
                  maxWidth: 'fit-content',
                }}
              >
                <StatusBadge
                  {...{
                    status: props.status as TypeTransactionStatus,
                  }}
                />
              </Box>
            </Tooltip>
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  )
}

const CancelPlanModal = (props: {
  isOpen: boolean
  onClose: () => void
  onCancelClick: () => void
}) => {
  const [cancelled, setCancelled] = useState(false)
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalContent>
        <ModalBody>
          {!cancelled ? (
            <VStack alignItems="start" p="4" w="100%">
              <Text fontSize="lg" fontWeight="lg">
                Cancel Plan
              </Text>
              <Text>
                Are you sure you want to cancel your subscription? You will lose
                access to all premium features.
              </Text>
              <Button
                onClick={async () => {
                  setCancelled(true)
                  props.onCancelClick()
                }}
              >
                Cancel Subscription
              </Button>
            </VStack>
          ) : (
            <VStack alignItems="start" p="4" w="100%">
              <Text fontSize="lg" fontWeight="lg">
                Your plan has been cancelled
              </Text>
              <Text>
                Please wait while we process your request, this may take up to
                two business days.
              </Text>
              <Button
                onClick={async () => {
                  props.onClose()
                }}
              >
                Confirm
              </Button>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { data } = useQuery(SubscriptionPaymentsDocument)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure()

  const [removeSubscription] = useMutation(RemoveSubscriptionDocument)

  return (
    <Card
      bgColor="white"
      p="6"
      w="100%"
      boxShadow="0"
      border="1px solid"
      borderColor="gray.300"
    >
      <Column wGrow gap="large">
        <Row between grow y="top">
          <Label className={LabelStyle}>Current Plan</Label>
          <Column gap="none" wGrow className="pl-10">
            <div className="text-md font-medium !text-black">
              {props.planName || 'Annual'}{' '}
              {props.activated ? (
                <></>
              ) : (
                <span className="font-regular text-gray-400 text-sm">
                  Plan has not started
                </span>
              )}
            </div>
            <div className={SubLabelStyle}>
              <a
                target="_blank"
                className={LinkStyle}
                rel="noreferrer"
                onClick={() => {
                  if (props.nextPayment.initiated) {
                    onCancelOpen()
                  } else {
                    window.open('/dashboard/nickel-plans')
                  }
                }}
              >
                {props.nextPayment.initiated
                  ? 'Cancel Plan'
                  : 'This plan is set to expire. Resubscribe to a plan here.'}
              </a>
            </div>
          </Column>
          <CancelPlanModal
            isOpen={isCancelOpen}
            onClose={onCancelClose}
            onCancelClick={() => {
              removeSubscription()
            }}
          />
        </Row>
        {props.nextPayment.initiated ? (
          <Row between grow y="top">
            <Label className={LabelStyle}>Next Payment</Label>
            <Column wGrow className="pl-10">
              <div className="text-lg font-semibold">
                {parseInt(props.nextPayment.price).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
                <span className="text-xs font-medium">
                  {' '}
                  on {props.nextPayment.date}
                </span>
              </div>
              {props.activated ? (
                <>
                  <Text
                    className={LinkStyle + ' text-sm'}
                    onClick={() => {
                      onOpen()
                    }}
                  >
                    View billing history
                  </Text>
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalContent>
                      <ModalBody>
                        <VStack alignItems="start" p="4" w="100%">
                          <Text fontSize="lg" fontWeight="lg">
                            Billing History
                          </Text>
                          {data?.subscriptionPayments?.subscriptionPayments?.map(
                            (subscriptionPayment) =>
                              subscriptionPayment?.amountInCents &&
                              subscriptionPayment?.createdAt &&
                              subscriptionPayment?.status && (
                                <BillingHistoryTransaction
                                  amountInCents={
                                    subscriptionPayment.amountInCents
                                  }
                                  completedAt={subscriptionPayment.createdAt}
                                  status={subscriptionPayment.status}
                                />
                              ),
                          )}
                        </VStack>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </>
              ) : (
                ''
              )}
            </Column>
          </Row>
        ) : (
          <>
            <Text
              className={LinkStyle + ' text-sm'}
              onClick={() => {
                onOpen()
              }}
            >
              View billing history
            </Text>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalContent>
                <ModalBody>
                  <VStack alignItems="start" p="4" w="100%">
                    <Text fontSize="lg" fontWeight="lg">
                      Billing History
                    </Text>
                    {data?.subscriptionPayments?.subscriptionPayments?.map(
                      (subscriptionPayment) =>
                        subscriptionPayment?.amountInCents &&
                        subscriptionPayment?.createdAt &&
                        subscriptionPayment?.status && (
                          <BillingHistoryTransaction
                            amountInCents={subscriptionPayment.amountInCents}
                            completedAt={subscriptionPayment.createdAt}
                            status={subscriptionPayment.status}
                          />
                        ),
                    )}
                  </VStack>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}

        <Row between grow y="top">
          {props.activated ? (
            <Label className={LabelStyle}>Payment Method</Label>
          ) : (
            ''
          )}
          {props.activated ? (
            <Column gap="small" wGrow className="pl-10">
              <Row gap="small">
                <div>
                  {props.cardBrand} •••
                  {props.lastFour}
                </div>
              </Row>
              <Row>
                <div className="text-sm text-gray-500">
                  Expires {props.cardExpiry}
                </div>
              </Row>
            </Column>
          ) : (
            <Column gap="small" x="right" className="w-full">
              <Separator orientation="horizontal" className="h-[1px]" />
              <Button
                {...{
                  className: '!text-sm !font-medium !h-fit !p-1 !px-2 w-full',
                  onClick: props.onUpdatePaymentMethod,
                }}
              >
                {props.activated
                  ? 'Update Payment Method'
                  : 'Activate Nickel Subscription'}
              </Button>
            </Column>
          )}
        </Row>
      </Column>
    </Card>
  )
}

export default SubscriptionCard
