import { useQuery, useMutation } from '@apollo/client'
import { Button } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  QuickbooksAuthenticateDocument,
  QuickbooksTokenCreateDocument,
} from '../../operations-types'

type QuickbooksAuthenticationProps = {
  onClick?: () => void
  onCompleted?: () => void
  alreadyConnected?: boolean
}

export function QuickbooksAuthentication({
  onClick,
  onCompleted,
  alreadyConnected,
}: QuickbooksAuthenticationProps) {
  const [authenticateLoading, setAuthenticateLoading] = useState(false)

  const { data, loading } = useQuery(QuickbooksAuthenticateDocument, {})
  const [createToken] = useMutation(QuickbooksTokenCreateDocument, {
    onCompleted: () => {
      if (onCompleted) {
        onCompleted()
        setAuthenticateLoading(false)
      }
    },
  })
  const authUrl = data?.quickbooksAuthenticate
  const [redirectUri, setRedirectUri] = useState<string | null>(null)

  useEffect(() => {
    if (redirectUri) {
      // The library handles parsing the code out of the URL so we pass the entire uri
      createToken({
        variables: {
          code: redirectUri,
        },
      })
    }
  }, [redirectUri, createToken])

  return (
    <Button
      isLoading={loading || authenticateLoading}
      onClick={() => {
        if (!authUrl) return
        onClick && onClick()
        setAuthenticateLoading(true)
        const popup = window.open(authUrl, 'popup', 'popup=true')

        const checkPopup = setInterval(() => {
          if (popup?.window.location.href.includes('code=')) {
            setRedirectUri(popup?.window.location.href)
            clearInterval(checkPopup)
            popup?.close()
          }
          if (!popup || !popup.closed) {
            setAuthenticateLoading(false)
            return
          }
          clearInterval(checkPopup)
        }, 1000)
      }}
    >
      {alreadyConnected
        ? 'Change QuickBooks Connection'
        : 'Authenticate with QuickBooks'}
    </Button>
  )
}
