import { useMutation } from '@apollo/client'
import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Modal,
  CloseButton,
  Text,
} from '@chakra-ui/react'
import { CheckIcon, FlagIcon } from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'
import { useEffect, useState } from 'react'
import { Button, Column, Form, Row } from 'ui'
import ContainerProps from 'ui/src/types'
import * as yup from 'yup'
import {
  ActivityStatus,
  AddActivityNoteDocument,
  InputMaybe,
  PaymentActivitiesDocument,
  PaymentActivitySource,
  SortOrder,
} from '../../operations-types'

export type QueryOptsActivityNote = {
  page: number
  pageSize: number
  sortBy: InputMaybe<SortOrder>
  source: PaymentActivitySource | undefined
  status: ActivityStatus | undefined
  startDate: string | undefined
  endDate: string | undefined
  transactionId: string | undefined
  customerName: string | undefined
}

type ActivityNoteProps = {
  initialNote?: string
  activityId?: string
  open?: boolean
  setOpen?: (e: boolean) => void
  queryOpts?: QueryOptsActivityNote
  sourceType: PaymentActivitySource
  reconciled?: boolean
  flagged?: boolean
}

type ActivityNoteComponentProps = ActivityNoteProps & {
  onNoteChange: (e: string) => void
  onReconciledChange: (e: boolean) => void
  onFlaggedChange: (e: boolean) => void
  initialNote?: string
  flagged?: boolean
  reconciled?: boolean
  saveButton?: JSX.Element
}

const activityNoteSchema = yup.object().shape({
  note: yup.string().optional(),
  reconciled: yup.boolean(),
  flagged: yup.boolean(),
})

const ButtonText = tw.div<SelectButtonProps>`
    text-xs
    font-medium

    ${({ selected }) =>
      selected
        ? `
      text-white
    `
        : 'text-gray-500'}
`

const ModalTitle = tw.div<ContainerProps>`
    text-lg
    font-semibold
    text-gray-800
    !pl-[5px]
`

type SelectButtonProps = ContainerProps & {
  selected?: boolean
}

const ReconciledButton = tw((props: SelectButtonProps) => (
  <div {...props}>
    <Row y="center" gap="small">
      <CheckIcon
        className={`w-[14px] h-[14px] stroke-1 ${
          props.selected ? 'stroke-white' : 'stroke-gray-500'
        }`}
      />
      <ButtonText selected={props.selected}>Reconciled</ButtonText>
    </Row>
  </div>
))`
    rounded
    px-2
    py-1
    cursor-pointer
    hover:bg-[#E0D9E3]

    ${({ selected }) => (selected ? 'bg-[#8F8394]' : 'bg-gray-100')}
`

const FlaggedButton = tw((props: SelectButtonProps) => (
  <div {...props}>
    <Row y="center" gap="small">
      <FlagIcon
        className={`w-[14px] h-[14px] stroke-1 ${
          props.selected ? 'stroke-white' : 'stroke-gray-500'
        }`}
      />
      <ButtonText selected={props.selected}>Flagged</ButtonText>
    </Row>
  </div>
))`
    rounded
    px-2
    py-1
    cursor-pointer
    hover:bg-[#E0D9E3]


    ${({ selected }) => (selected ? 'bg-[#8F8394]' : 'bg-gray-100')}
    `

const OptionalText = tw.div<ContainerProps>`
    text-xs
    font-normal
    text-gray-800
    whitespace-nowrap
`

const OptionalSpan = tw(OptionalText)`
    text-gray-400
    inline
`

type ActivityNoteSchemaType = yup.InferType<typeof activityNoteSchema>

const ActivityNoteInputComponent = (props: ActivityNoteComponentProps) => {
  return (
    <Column gap="small" wGrow>
      <Textarea
        {...{
          onChange: (e) => props.onNoteChange(e.target.value),
          className:
            'rounded border border-gray-300 !text-sm !placeholder:text-sm !py-2',
          defaultValue: props.initialNote,
          placeholder: 'Add a note...',
          resize: 'none',
        }}
      />
      <Row y="center" grow between>
        <Row y="center" gap="small">
          <OptionalText>
            Mark as <OptionalSpan>(optional)</OptionalSpan>:
          </OptionalText>
          <ReconciledButton
            {...{
              onClick: () => props.onReconciledChange(!props.reconciled),
              selected: props.reconciled,
            }}
          />
          <FlaggedButton
            {...{
              onClick: () => props.onFlaggedChange(!props.flagged),
              selected: props.flagged,
            }}
          />
        </Row>
        {props.saveButton}
      </Row>
    </Column>
  )
}

const ActivityNoteModal = (_props: ActivityNoteProps) => {
  const [addActivityNote, { loading }] = useMutation(AddActivityNoteDocument, {
    refetchQueries: [
      {
        query: PaymentActivitiesDocument,
        variables: {
          ..._props.queryOpts,
        },
      },
    ],
  })

  const [initialNote, setInitialNote] = useState(_props.initialNote)
  const [reconciled, setReconciled] = useState(_props.reconciled)
  const [flagged, setFlagged] = useState(_props.flagged)

  useEffect(() => {
    setInitialNote(_props.initialNote)
    setReconciled(_props.reconciled)
    setFlagged(_props.flagged)
  }, [_props.flagged, _props.initialNote, _props.reconciled])

  return (
    <Form
      {...{
        initialValues: {
          note: initialNote,
          reconciled: reconciled,
          flagged: flagged,
        } as ActivityNoteSchemaType,
        validationSchema: activityNoteSchema,
      }}
    >
      {(props) => (
        <>
          <Modal
            isOpen={_props.open || false}
            onClose={async () => {
              _props.setOpen && _props.setOpen(false)
              await props.resetForm()
            }}
            size="2xl"
            isCentered
            variant="input"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Row between grow y="center">
                  <ModalTitle>Add a note</ModalTitle>
                  <CloseButton
                    {...{
                      onClick: async () => {
                        _props.setOpen && _props.setOpen(false)
                        await props.resetForm()
                      },
                    }}
                  />
                </Row>
              </ModalHeader>
              <ModalBody className="my-4">
                <ActivityNoteInputComponent
                  {...{
                    onFlaggedChange: async (_) => {
                      await props.setFieldValue(
                        'flagged',
                        !props.values.flagged,
                      )
                    },
                    onNoteChange: async (e) => {
                      await props.setFieldValue('note', e)
                    },
                    onReconciledChange: async (_) => {
                      await props.setFieldValue(
                        'reconciled',
                        !props.values.reconciled,
                      )
                    },
                    initialNote: _props.initialNote,
                    flagged: props.values.flagged,
                    reconciled: props.values.reconciled,
                    sourceType: _props.sourceType,
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Row y="center" x="right" gap="small">
                  <Button
                    label="Cancel"
                    variant={'outline'}
                    {...{
                      onClick: () => _props.setOpen && _props.setOpen(false),
                    }}
                  />
                  <Button
                    label="Save"
                    disabled={loading}
                    {...{
                      onClick: async () => {
                        await addActivityNote({
                          variables: {
                            activityId: _props.activityId || '',
                            note: props.values.note,
                            flagged: props.values.flagged,
                            reconciled: props.values.reconciled,
                            activitySource:
                              _props.sourceType as PaymentActivitySource,
                          },
                        })

                        _props.setOpen && _props.setOpen(false)

                        await props.resetForm()
                      },
                    }}
                  />
                </Row>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </Form>
  )
}

export const ActivityNote = (_props: ActivityNoteProps) => {
  const [saving, setSaving] = useState(false)

  const [addActivityNote] = useMutation(AddActivityNoteDocument, {
    refetchQueries: [
      {
        query: PaymentActivitiesDocument,
        variables: {
          ..._props.queryOpts,
        },
      },
    ],
    onCompleted: () => {
      setSaving(false)
    },
  })

  const [active, setActive] = useState(false)

  return (
    <Form
      {...{
        initialValues: {
          note: _props.initialNote || '',
          reconciled: _props.reconciled || false,
          flagged: _props.flagged || false,
        } as ActivityNoteSchemaType,
        validationSchema: activityNoteSchema,
        className: 'w-full',
        onChange: (e) => {
          setActive(
            e.note !== _props.initialNote ||
              e.flagged !== _props.flagged ||
              e.reconciled !== _props.reconciled,
          )
        },
      }}
    >
      {(props) => (
        <>
          <Column wGrow gap="medium" className="w-full">
            <Text fontWeight="semibold" color="dark.800" fontSize="xl">
              Note
            </Text>
            <ActivityNoteInputComponent
              {...{
                onFlaggedChange: (_) =>
                  props.setFieldValue('flagged', !props.values.flagged),
                onNoteChange: (e) => props.setFieldValue('note', e),
                onReconciledChange: (_) =>
                  props.setFieldValue('reconciled', !props.values.reconciled),
                initialNote: _props.initialNote,
                flagged: props.values.flagged,
                reconciled: props.values.reconciled,
                sourceType: _props.sourceType,
                saveButton: active ? (
                  <Row y="center" x="right" gap="small" grow>
                    <div
                      className={`text-xs py-1 px-3 rounded-md h-[24px] ${
                        active
                          ? 'bg-[#4d4351] text-white cursor-pointer'
                          : 'bg-gray-200 cursor-not-allowed'
                      }`}
                      {...{
                        onClick: async () => {
                          if (!active) return
                          setSaving(true)
                          await addActivityNote({
                            variables: {
                              activityId: _props.activityId || '',
                              note: props.values.note,
                              flagged: props.values.flagged,
                              reconciled: props.values.reconciled,
                              activitySource:
                                _props.sourceType as PaymentActivitySource,
                            },
                          })

                          _props.setOpen && _props.setOpen(false)
                        },
                      }}
                    >
                      {saving ? 'Saving...' : 'Save'}
                    </div>
                  </Row>
                ) : (
                  <div />
                ),
              }}
            />
          </Column>
        </>
      )}
    </Form>
  )
}

export default ActivityNoteModal
