import { useQuery } from '@apollo/client'
import moment from 'moment'
import {
  Breakdown,
  Column,
  OverlayHeader,
  OverlayTabs,
  OverlayTitle,
  Separator,
  Title,
  Transaction,
  TransactionList,
  UnstandardizedColumn,
} from 'ui'
import { getPaymentMethodIdentifier } from '../../lib/utils'
import { CustomerDocument } from '../../operations-types'

type CustomerInnerOverlayProps = {
  customerId: string
  setOpen: (open: boolean) => void
}

type PaidSummaryProps = {
  allTimeAmount: number
}

function PaidSummary(props: PaidSummaryProps) {
  return (
    <Column gap="medium" wGrow>
      <Separator orientation="horizontal" />
      <Title variant="secondary">Payment Totals</Title>
      <Column gap="medium" wGrow>
        <Breakdown
          breakdownType="credit"
          headerName="All Time"
          headerValue={props.allTimeAmount}
        />
      </Column>
    </Column>
  )
}

function PaymentsList({ transactions }: { transactions: Transaction[] }) {
  return (
    <Column wGrow>
      <Separator orientation="horizontal" />
      <UnstandardizedColumn wGrow className="py-4">
        <TransactionList transactions={transactions} />
      </UnstandardizedColumn>
    </Column>
  )
}

export default function CustomerInnerOverlay({
  customerId,
  setOpen,
}: CustomerInnerOverlayProps) {
  const { loading, data } = useQuery(CustomerDocument, {
    variables: { customerId: customerId },
  })

  if (loading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const customer = data?.customer?.customer

  return (
    <Column className="w-full h-full">
      <OverlayHeader
        onClose={() => setOpen(false)}
        onBack={() => setOpen(false)}
      />
      <OverlayTitle
        title={`${customer?.firstName} ${customer?.lastName}`}
        subtitle={customer?.email || ''}
      />
      <UnstandardizedColumn wGrow className="px-8">
        <OverlayTabs
          tabs={[
            {
              name: 'Summary',
              component: (
                <PaidSummary
                  allTimeAmount={(customer?.amountCents || 0) / 100}
                />
              ),
            },
            {
              name: 'Payments',
              component: (
                <PaymentsList
                  transactions={(customer?.payments || []).map((x) => {
                    return {
                      amountDollars: (x.submittedAmountInCents || 0) / 100,
                      sublabel: moment(parseInt(x.createdAt || '')).format(
                        'MMM DD YYYY',
                      ),
                      transactionId: x.id,
                      type: 'credit',
                      label: x.id,
                      methodType:
                        x.paymentMethod?.type === 'card' ? 'card' : 'bank',
                      methodText: getPaymentMethodIdentifier(x.paymentMethod)
                        .identifier,
                    }
                  })}
                />
              ),
            },
          ]}
        />
      </UnstandardizedColumn>
    </Column>
  )
}
