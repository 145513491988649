import { useQuery } from '@apollo/client'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ArrowDownIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import {
  Card,
  Column,
  OverlayFooter,
  OverlaySectionReturn,
  OverlaySectionRefund,
  UnstandardizedColumn,
  OverlaySectionLoan,
} from 'ui'
import {
  getTransactionStatusFromActivity,
  reportErrorIfExists,
} from '../../lib/utils'
import {
  BankAccount,
  BankAccountsDocument,
  GetPaymentDocument,
  GetPaymentQuery,
  PaymentActivitySource,
  PayoutMethod,
  UserRole,
  VendorPayoutMethod,
} from '../../operations-types'
import { OverlayHeaderV2 } from '../payments/OverlayHeader'
import AmountHeader from '../payments/PaymentAmountSubmitted'
import PaymentElements from '../payments/PaymentElements'
import PaymentDetails, {
  BillingAddress,
  ContactInfo,
  PaymentMethod,
} from '../payments/PaymentDetails'
import AdditionalPaymentDetail from '../payments/AdditionalPaymentDetail'
import { PaymentHelper } from '../payments/PaymentHelper'
import { TransactionActivity } from './TransactionActivity'
import currency from 'currency.js'
import { ActivityNote, QueryOptsActivityNote } from '../ap/ActivityNoteModal'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { useFeatureFlagEnabled } from 'posthog-js/react'

type PaymentInnerOverlayProps = {
  paymentId: string
  exitOverlay: () => void
  onRefund?: () => void
  onRefundItem?: (refundId: string) => void
  onReturnItem?: (returnId: string) => void
  onLoanItem?: (loanId: string) => void
  onChargebackItem?: (chargebackId: string) => void
  onBack?: () => void
  queryOpts?: QueryOptsActivityNote
}

type PaymentMethodType = NonNullable<
  NonNullable<GetPaymentQuery['payment']>['payment']
>['paymentMethod']

type PayoutMethodIdentifier = Pick<
  PayoutMethod,
  'accountNumberLastTwo' | 'bankName'
> &
  Pick<BankAccount, 'lastTwo'>

type VendorPayout = Pick<
  VendorPayoutMethod,
  | 'type'
  | 'street'
  | 'bankName'
  | 'city'
  | 'state'
  | 'zip'
  | 'routingNumber'
  | 'accountNumber'
>

export function vendorPayoutOrBankAccountIntoIdentifier(
  account: VendorPayout | null,
  paidAt: string | null,
  verb = 'Sent',
) {
  return {
    type:
      account?.type === 'CHECK'
        ? 'checkDeposit'
        : account?.type === 'ACH'
        ? 'bankAccount'
        : '',
    identifier:
      account?.type === 'CHECK'
        ? `${account.street}, ${account.city}, ${account.state} ${account.zip}`
        : `${
            account?.bankName || account?.routingNumber
          } ·· ${account?.accountNumber?.slice(-2)}`,
    text: paidAt
      ? `${verb} on ${moment(paidAt).format('MM/DD/YYYY')}`
      : 'Pending',
  }
}

export function payoutOrBankAccountIntoIdentifier(
  bankAccount: PayoutMethodIdentifier | null,
  paidAt: string | null,
  createdAt: string | null,
  verb = 'Sent',
) {
  return {
    type: 'bankAccount',
    identifier: `${bankAccount?.bankName} ·· ${
      bankAccount?.accountNumberLastTwo || bankAccount?.lastTwo
    }`,
    text: paidAt
      ? `Paid on ${moment(paidAt).format('MM/DD/YYYY')}`
      : createdAt
      ? `${verb} on ${moment(parseInt(createdAt)).format('MM/DD/YYYY')}`
      : 'Pending',
  }
}

export function paymentMethodIntoIdentifier(
  paymentMethod: PaymentMethodType | null,
  check = false,
  paymentCreatedAt: string,
  verb = 'Initiated',
) {
  if (!paymentMethod) {
    return {
      type: 'unknown',
      identifier: 'N/A',
    }
  }

  const text = paymentCreatedAt
    ? `${verb} on ${moment(parseInt(paymentCreatedAt || '')).format(
        'MM/DD/YYYY',
      )}`
    : undefined

  if (paymentMethod?.type === 'card') {
    return {
      type: 'creditCard',
      identifier: `${paymentMethod?.card
        ?.brand} ·· ${paymentMethod?.card?.last4?.slice(2, 4)}`,
      text: text,
    }
  }

  return {
    type: check ? 'checkDeposit' : 'bankAccount',
    identifier: check
      ? ``
      : `${paymentMethod.achDebit?.bankName} ·· ${paymentMethod?.achDebit?.last2}`,
    text: text,
  }
}

export default function PaymentInnerOverlay({
  paymentId,
  exitOverlay,
  onRefund,
  onRefundItem,
  onReturnItem,
  onChargebackItem,
  onLoanItem,
  onBack,
  queryOpts,
}: PaymentInnerOverlayProps) {
  const { getUser } = useLoggedInStore()

  const IS_PERMITTED = getUser().isUserPermitted(UserRole.Manager)

  const nickelCreditEnabled = useFeatureFlagEnabled('nickelCreditEnabled')

  const { loading, data, error } = useQuery(GetPaymentDocument, {
    variables: { paymentId: paymentId },
  })
  const { data: organizationInfo, loading: bankAccountLoading } =
    useQuery(BankAccountsDocument)

  const defaultBankAccount =
    organizationInfo?.organization?.organization?.accountInfo?.bankAccounts?.[0]

  const [downloadLoading, setDownloadLoading] = useState(false)

  reportErrorIfExists(data?.payment?.error?.message || error)

  if (loading || bankAccountLoading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const paymentData = data?.payment?.payment
  const chargeback = paymentData?.charges?.at(0)?.chargeback

  const paymentHelper = new PaymentHelper(paymentData!)

  const refundPresenters = paymentHelper.refunds().map((x) => {
    return {
      id: x?.id || '',
      amount: (x?.amountCents || 0) / 100,
      dateNumber: parseInt(x?.createdAt || ''),
      date: moment(parseInt(x?.createdAt || '')).format('MM/DD/YYYY'),
      status: _.capitalize(x?.status || ''),
    }
  })

  const loanPresenter =
    {
      id: paymentHelper.loan()?.id || '',
      principalAmount:
        currency(paymentHelper.loan()?.principalAmountInCents || 0, {
          fromCents: true,
        }).value || 0,
      issueDate: moment(parseInt(paymentHelper.loan()?.issueDate || '')).format(
        'MM/DD/YYYY',
      ),
      status: _.capitalize(paymentHelper.loan()?.activity?.status || ''),
    } || null

  refundPresenters.sort((a, b) => b.dateNumber - a.dateNumber)

  const allRefunds = [...refundPresenters]
  const allLoans = loanPresenter ? [loanPresenter] : []
  allRefunds.sort((a, b) => b.dateNumber - a.dateNumber)

  const paymentMethod = data?.payment?.payment?.paymentMethod
  const address = paymentMethod?.billingDetails?.address

  const payout =
    (data?.payment?.payment?.charges || []).find((x) => x.payout)?.payout ||
    null

  const downloadOnClick = async () => {
    setDownloadLoading(true)

    await fetch(
      `/api/pdf?fileName=invoice&paymentId=${paymentData?.id}` || '',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((e) => e.arrayBuffer())
      .then((e) => {
        const a = document.createElement('a')
        const uintArr = new Uint8Array(e)
        const blob = new Blob([uintArr], { type: 'application/pdf' })

        a.href = URL.createObjectURL(blob)

        a.download = `${moment.now().toString()}.pdf`

        a.click()

        setDownloadLoading(false)
      })
      .finally(() => {
        setDownloadLoading(false)
      })
  }

  return (
    <UnstandardizedColumn className="w-full h-full">
      <OverlayHeaderV2
        onClose={exitOverlay}
        onBack={onBack}
        transactionStatus={
          getTransactionStatusFromActivity(
            paymentData?.activity?.status || '',
            paymentData?.activity?.flags || [],
          ).status
        }
        check={!!paymentData?.check}
      />
      <AmountHeader
        label="Payment Amount"
        amount={currency(paymentHelper.netAmountDollars()).format()}
      />
      <TransactionActivity
        paymentMethod={paymentMethodIntoIdentifier(
          paymentMethod ?? null,
          !!paymentData?.check,
          paymentData?.createdAt || '',
        )}
        payoutMethod={payoutOrBankAccountIntoIdentifier(
          payout?.payoutMethod || defaultBankAccount || null,
          payout?.paidAt || null,
          payout?.createdAt || null,
        )}
        completed={!!payout?.paidAt}
      />
      <PaymentElements
        {...{
          paymentNode: (
            <Column gap="large" wGrow className="w-full">
              <PaymentDetails
                {...{
                  headerLabel: 'Payment',
                  feesCollected:
                    paymentData?.activity?.status === 'CANCELLED'
                      ? currency(0).format()
                      : currency(paymentHelper.platformFeeDollars()).format(),
                  chargedAmount: currency(
                    paymentHelper.chargedAmountDollars(),
                  ).format(),
                  netPayment: currency(
                    paymentHelper.netAmountDollars(),
                  ).format(),
                  returnedAmount:
                    paymentHelper.returnAmountDollars() > 0
                      ? currency(paymentHelper.returnAmountDollars()).format()
                      : undefined,
                  refundedAmount:
                    paymentHelper.refundAmountDollars() > 0
                      ? currency(paymentHelper.refundAmountDollars()).format()
                      : undefined,
                  factoringFee:
                    paymentHelper.factoringFeeDollars() > 0
                      ? currency(paymentHelper.factoringFeeDollars()).format()
                      : undefined,
                  advancedAmount:
                    paymentHelper.principalAmountDollars() > 0
                      ? currency(
                          paymentHelper.principalAmountDollars(),
                        ).format()
                      : undefined,
                  paymentMethod: paymentMethod?.card?.last4 ? (
                    <PaymentMethod
                      card={{
                        last4: paymentMethod?.card?.last4,
                        brand: paymentMethod?.card?.brand || '',
                      }}
                    />
                  ) : (
                    <PaymentMethod
                      achDebit={{
                        routingNumber: paymentMethod?.achDebit?.routingNumber!,
                      }}
                    />
                  ),
                  billingAddress: (
                    <BillingAddress
                      companyName={paymentData?.companyName}
                      address={address!}
                    />
                  ),
                  contactInfo: (
                    <ContactInfo
                      name={paymentMethod?.billingDetails?.name}
                      email={paymentMethod?.billingDetails?.email}
                    />
                  ),
                }}
              />
              <Flex w="100%" flexDirection={'column'} gap={2}>
                <Text fontWeight="semibold" color="dark.800" fontSize="xl">
                  Purpose
                </Text>
                <Card className="w-full py-2 px-4">
                  {paymentData?.notes ||
                    paymentData?.answerForm?.questionForm?.name ||
                    ''}
                </Card>
              </Flex>
              {paymentData?.achReturn && (
                <OverlaySectionReturn
                  achReturn={{
                    id: paymentData.achReturn.id,
                    amount: currency(paymentData.achReturn.amountCents, {
                      fromCents: true,
                    }).value,
                    paidAt: paymentData?.achReturn.payout?.paidAt
                      ? moment(paymentData?.achReturn?.payout?.paidAt).format(
                          'MM/DD/YYYY',
                        )
                      : undefined,
                    status: paymentData.achReturn.payout?.paidAt
                      ? 'Completed'
                      : 'Pending',
                  }}
                  onReturnClick={onReturnItem}
                />
              )}
              {chargeback && (
                <OverlaySectionReturn
                  headerText="Chargeback"
                  amountText="Chargeback Amount"
                  achReturn={{
                    id: chargeback.id,
                    amount: currency(chargeback.amountCents || 0, {
                      fromCents: true,
                    }).value,
                    paidAt: chargeback?.payout?.paidAt
                      ? moment(chargeback.payout?.paidAt).format('MM/DD/YYYY')
                      : undefined,
                    status: chargeback.payout?.paidAt ? 'Completed' : 'Pending',
                  }}
                  onReturnClick={onChargebackItem}
                />
              )}
              {nickelCreditEnabled && (
                <OverlaySectionLoan
                  title="Advances"
                  loans={allLoans}
                  onLoanClick={onLoanItem}
                />
              )}
              <OverlaySectionRefund
                title="Refunds"
                refunds={allRefunds}
                onRefundClick={onRefundItem}
              />
              <ActivityNote
                {...{
                  activityId: paymentData?.activity?.id || '',
                  initialNote: paymentData?.activity?.note?.note || '',
                  sourceType: PaymentActivitySource.Refund,
                  reconciled: paymentData?.activity?.note?.reconciled || false,
                  flagged: paymentData?.activity?.note?.flagged || false,
                  queryOpts: queryOpts,
                }}
              />
            </Column>
          ),
          detailNode: (
            <AdditionalPaymentDetail
              {...{
                answerForm: paymentData?.answerForm,
                reference: {
                  paymentId: paymentData?.id || '',
                  reference: paymentData?.externalPaymentIntentId || '',
                  failureReason:
                    paymentData?.charges?.find((x) => x.failureMessage != null)
                      ?.failureMessage || undefined,
                  shortIdentifier: paymentData?.shortIdentifier ?? undefined,
                  checkNumber: paymentData?.check?.checkNumber ?? undefined,
                },
              }}
            />
          ),
        }}
      />
      <Box w={'100%'} mt={'auto'}>
        {IS_PERMITTED && (
          <OverlayFooter
            buttonText="Refund Payment"
            disabled={downloadLoading}
            options={[
              {
                icon: <ArrowDownIcon />,
                onClick: downloadOnClick,
                children: 'Download Receipt',
                isDisabled: downloadLoading,
              },
            ]}
            onButtonClick={onRefund}
          />
        )}
      </Box>
    </UnstandardizedColumn>
  )
}
