import {
  Badge,
  Box,
  Center,
  FormLabel,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { FormButton, HFlex, PriceInput, VFlex } from 'ui'
import {
  ApprovalPoliciesDocument,
  CreateApprovalPolicyDocument,
  UserRole,
} from '../../operations-types'
import * as yup from 'yup'
import { useMutation, useQuery } from '@apollo/client'
import currency from 'currency.js'
import Loading from '../utils/Loading'

const WorkflowSchema = yup.object({
  approvalAmountCents: yup
    .string()
    .min(1)
    .required('Approval amount is required'),
  approverRole: yup
    .string()
    .oneOf(Object.values(UserRole))
    .required('Approver role is required'),
})

type WorkflowFormProps = {
  approvalAmountCents: number
  approverRole: UserRole
  policyActive: boolean
}

function WorkflowForm({
  approvalAmountCents,
  approverRole,
  policyActive,
}: WorkflowFormProps) {
  const toast = useToast()
  const [addWorkflow] = useMutation(CreateApprovalPolicyDocument, {
    onCompleted: (data) => {
      if (data.createApprovalPolicy?.error?.message) {
        toast({
          title: 'Error',
          description:
            data.createApprovalPolicy.error.message || 'Something went wrong.',
          status: 'error',
          duration: 5000,
        })
        return
      }

      toast({
        title: 'Approval workflow created',
        status: 'success',
        duration: 5000,
      })
    },
    refetchQueries: ['approvalPolicies'],
  })

  return (
    <Formik
      initialValues={{
        approvalAmountCents: currency(approvalAmountCents, {
          fromCents: true,
        }).value.toString(),
        approverRole: approverRole,
      }}
      validateOnMount={true}
      validationSchema={WorkflowSchema}
      onSubmit={(values) =>
        addWorkflow({
          variables: {
            approvalSteps: {
              role: values.approverRole as UserRole,
            },
            approvalConditions: {
              paymentAmountCents: currency(values.approvalAmountCents).intValue,
            },
          },
        })
      }
    >
      {(props) => {
        return (
          <Form>
            <VFlex gap={6}>
              <PriceInput
                label="If the payment amount is over"
                placeholder="$100.00"
                value={props.values.approvalAmountCents}
                fontWeight="medium"
                fontSize="sm"
                onPriceChange={(price) =>
                  props.setFieldValue('approvalAmountCents', price)
                }
              />
              <Box>
                <FormLabel>Require approval from any</FormLabel>
                <Select
                  value={props.values.approverRole}
                  onChange={(e) =>
                    props.setFieldValue('approverRole', e.target.value)
                  }
                >
                  <option value={UserRole.UserAdmin}>Administrator</option>
                  <option value={UserRole.Manager}>Manager</option>
                </Select>
              </Box>
              <HFlex justifyContent="space-between">
                <VStack spacing="0">
                  <Text fontSize="sm" fontWeight="semibold">
                    Workflow Status
                  </Text>
                  <Center>
                    <Badge
                      borderRadius="4px"
                      colorScheme={policyActive ? 'green' : 'gray'}
                      textAlign="center"
                      p="2"
                    >
                      {policyActive ? 'ACTIVE' : 'INACTIVE'}
                    </Badge>
                  </Center>
                </VStack>
                <FormButton label="Save" x="right" />
              </HFlex>
            </VFlex>
          </Form>
        )
      }}
    </Formik>
  )
}

export function Workflow() {
  const { data, loading } = useQuery(ApprovalPoliciesDocument)
  const approvalPolicy =
    data?.organization?.organization?.approvalPolicies?.at(0)
  const approvalStep = approvalPolicy?.approvalSteps?.at(0)
  const approvalCondition = approvalPolicy?.approvalConditions?.at(0)

  return (
    <VFlex w="100%" alignItems="center" pt={6}>
      <Box w="580px">
        <VFlex gap={6}>
          <Box>
            <Text fontSize="xl" color="gray.800" fontWeight="medium">
              Set up your approval workflow
            </Text>
            <Text fontSize="sm" color="gray.500">
              Any payments that meet the following conditions need to be
              approved before they can be sent out
            </Text>
          </Box>
          {loading && <Loading />}
          {!loading && (
            <WorkflowForm
              approvalAmountCents={approvalCondition?.paymentAmountCents || 0}
              approverRole={approvalStep?.approverRole || UserRole.UserAdmin}
              policyActive={!!approvalPolicy}
            />
          )}
        </VFlex>
      </Box>
    </VFlex>
  )
}
