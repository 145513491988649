import {
  Box,
  Card,
  CardBody,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Button, Column, Header, RemoveModal, Row, Separator } from 'ui'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { PageLayout } from '../layout/PageLayout'
import {
  NEW_CLIENT_URL,
  fetchPortalURL,
  PAY_VENDORS_BILLS_URL,
} from '../../../src/lib/urls'
import { useMutation } from '@apollo/client'
import {
  BecomeUserDocument,
  RevertToAccountingAccountDocument,
  SelfDocument,
  UnlinkOrganizationFromAccountantDocument,
} from '../../operations-types'
import { useAuth } from '../../lib/auth'
import { useDashboardOutletContext } from '../../lib/outletContext'

const AWS_BUCKET_ROUTE = `https://s3.amazonaws.com/${
  import.meta.env.VITE_AWS_BUCKET
}/`

type Client = {
  id: string
  name?: string | null
  employees?:
    | {
        id: string
        email?: string | null
      }[]
    | null
  accountInfo?: {
    codatCompanyId?: string | null
    subdomain?: string | null
    logoKey?: string | null
    bannerKey?: string | null
  } | null
}

type AccountingClientCardProps = {
  client: Client
}

type ClientBannerAndLogoProps = {
  logoKey: string | null
  bannerKey: string | null
}

const ClientBannerAndLogo = (props: ClientBannerAndLogoProps) => {
  const STATIC_BANNER_LOGO =
    import.meta.env.MODE === 'development'
      ? '../../../public/nickel_upgrade_permissions_banner.png'
      : import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production'
      ? 'prodtest3-banner-d1eeb0bf-6f71-4732-ad6a-883b337ba6a9.png'
      : 'numerouno-banner-63b2a942-7f60-4923-aa65-8ce4c81a5a6b.png'
  const STATIC_LOGO =
    import.meta.env.MODE === 'development'
      ? '../../../public/logo-only.png'
      : import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production'
      ? 'prodtest3-logo-d1eeb0bf-6f71-4732-ad6a-883b337ba6a9.png'
      : 'numerouno-logo-63b2a942-7f60-4923-aa65-8ce4c81a5a6b.png'

  const logoKeySrc = props.logoKey
    ? `${AWS_BUCKET_ROUTE}${props.logoKey}`
    : import.meta.env.MODE === 'development'
    ? STATIC_LOGO
    : `${AWS_BUCKET_ROUTE}${STATIC_LOGO}`
  const bannerKeySrc = props.bannerKey
    ? `${AWS_BUCKET_ROUTE}${props.bannerKey}`
    : import.meta.env.MODE === 'development'
    ? STATIC_BANNER_LOGO
    : `${AWS_BUCKET_ROUTE}${STATIC_BANNER_LOGO}`
  return (
    <Box borderRadius="4">
      <Image
        src={bannerKeySrc || STATIC_BANNER_LOGO}
        alt="Banner Image"
        height="80px"
        width="296px"
        alignSelf={'center'}
        style={{ position: 'relative', objectFit: 'cover' }}
        boxShadow="0"
        borderRadius="4"
      />
      <Image
        src={logoKeySrc || STATIC_LOGO}
        alt="Logo Image"
        height="50px"
        width="50"
        boxShadow="0"
        borderRadius="4"
        backgroundColor="white"
        borderColor="gray.200"
        border="2px solid #d6d3d1"
        p="2"
        style={{
          position: 'absolute',
          bottom: '110px',
          right: '225px',
        }}
      />
    </Box>
  )
}

const AccountingClientCard = (props: AccountingClientCardProps) => {
  const { setAuth } = useAuth()

  const [becomeUser] = useMutation(BecomeUserDocument, {
    onCompleted: (data) => {
      const token = data?.becomeUser?.token

      setAuth(token)
    },
    refetchQueries: [
      {
        query: SelfDocument,
      },
    ],
  })

  const toaster = useToast()

  const [removeClient] = useMutation(UnlinkOrganizationFromAccountantDocument, {
    onCompleted: async () => {
      toaster({
        status: 'success',
        title: 'Client removed successfully',
      })
    },
    refetchQueries: [
      {
        query: SelfDocument,
      },
    ],
  })

  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Card
      w="296px"
      h="220px"
      borderRadius="5"
      border="0"
      boxShadow="0px 1px 2px 0px rgba(77, 67, 81, 0.06), 0px 1px 3px 0px rgba(77, 67, 81, 0.10)"
    >
      <ClientBannerAndLogo
        logoKey={props.client?.accountInfo?.logoKey || null}
        bannerKey={props.client?.accountInfo?.bannerKey || null}
      />
      <CardBody>
        <VStack spacing="4" gap="4" alignItems="left" pt="4">
          <Box>
            <Text fontSize="md" fontWeight="bold" noOfLines={1}>
              {props.client.name}
            </Text>
            <Text
              fontSize="xs"
              color="purple.500"
              cursor="pointer"
              noOfLines={1}
              onClick={() => {
                window.open(
                  `${fetchPortalURL(
                    props.client.accountInfo?.subdomain || 'nickel',
                  )}/`,
                )
              }}
            >
              {fetchPortalURL(
                props?.client?.accountInfo?.subdomain || 'nickel',
              )}
            </Text>
          </Box>
          <RemoveModal
            removeString={props.client.name || 'this client'}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            removeOnClick={async () => {
              await removeClient({
                variables: {
                  clientOrgId: props.client.id,
                },
              })
            }}
          />
          <HStack w="100%">
            <Button
              size="sm"
              label="Remove"
              variant="outline"
              iconName="trash"
              iconPosition="left"
              onClick={async () => {
                await onOpen()
              }}
            />
            <Button
              size="sm"
              label="Go to Account"
              variant="outline"
              iconName="arrowRightOnRectangle"
              iconPosition="left"
              onClick={async () => {
                await becomeUser({
                  variables: {
                    becomeUserId: props.client.employees?.[0]?.id || '',
                  },
                })
                navigate(PAY_VENDORS_BILLS_URL)
              }}
            />
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  )
}

function AccountingClients() {
  const loggedInUser = useLoggedInStore((state) => state.user)

  const { organization } = useDashboardOutletContext()

  const clients = organization?.clients

  const navigate = useNavigate()

  const { setAuth } = useAuth()

  const [revertToAccountingAccount] = useMutation(
    RevertToAccountingAccountDocument,
    {
      onCompleted: (data) => {
        const token = data?.revertToAccountingAccount?.token

        setAuth(token)
      },
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    },
  )

  const isLoggedIntoAccountingFirm =
    loggedInUser.organization?.role === 'ACCOUNTING_FIRM'

  return (
    <PageLayout>
      <Column
        wGrow
        className="w-full bg-white rounded-md max-h-[calc(100vh-2rem)] overflow-scroll"
      >
        <Row className="pb-4 rounded-md" between grow>
          <VStack
            spacing="0"
            gap="0"
            alignItems="left"
            className="w-full h-full"
            p="4"
          >
            <Header variant="page" className="text-[20px]">
              Clients
            </Header>
            <HStack gap={1.5} alignItems={'center'}>
              <Text color="gray.500">
                Manage your clients and navigate to their Nickel account
              </Text>
            </HStack>
          </VStack>
          <HStack spacing="4" p="4">
            <Button
              label="New Client"
              iconName="plusIcon"
              iconPosition="left"
              className="!text-sm !h-[30px]"
              onClick={async () => {
                if (!isLoggedIntoAccountingFirm) {
                  await revertToAccountingAccount()
                }
                navigate(`${NEW_CLIENT_URL}`)
              }}
            />
          </HStack>
        </Row>
        <Separator orientation="horizontal" />
        <Box px="4" py="6" w="100%">
          <SimpleGrid
            columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
            w={
              clients?.length === 2
                ? '50%'
                : clients?.length === 3
                ? '75%'
                : '100%'
            }
            spacing="20px"
            justifyItems="left"
            alignItems="left"
            minChildWidth="296px"
          >
            {clients?.map((client: Client) => (
              <AccountingClientCard key={client.id} client={client} />
            ))}
          </SimpleGrid>
        </Box>
      </Column>
    </PageLayout>
  )
}

export default AccountingClients
