import { property, tw } from '@nickeltech/brise'
import { VisibilityTransition, VisibilityTransitionProps } from '../Transitions'
import { SizeProps } from '../Layout/Spacing'
import StatusTextColor from '../Indicators'
import { StatusProps, VariantProps } from '../../types'

export type TextProps = SizeProps &
  VisibilityTransitionProps &
  StatusProps &
  VariantProps

export const TextVariantStyle = property(
  'variant',
  {
    quarternary: 'text-gray-500 font-normal text-xs',
    tertiary: 'text-gray-800 font-normal text-sm',
    secondary: 'text-gray-500 font-normal text-base',
    primary: 'text-gray-800 font-normal text-base',
  },
  'primary',
)

export const Text = tw.p<TextProps>`
  leading-none
  ${TextVariantStyle}
  ${VisibilityTransition}
  ${StatusTextColor}
`

// TODO Fix all the comoonents that use this
export const UnstandardizedText = tw.p<TextProps>`
  leading-none
  ${VisibilityTransition}
`

export default Text
