import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
} from '@chakra-ui/react'

export type EntityInputProps = InputProps &
  FormControlProps & {
    label: string
    rightAddon?: string
    leftAddon?: string
    error?: string
  }

export function EntityInput({
  label,
  rightAddon,
  error,
  ...props
}: EntityInputProps) {
  return (
    <Flex flex="1">
      <Box>
        <FormControl id={props.id} isInvalid={!!error}>
          <FormLabel>{label}</FormLabel>
          <InputGroup>
            <Input
              isDisabled={props.isDisabled}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder}
              type={props.type}
              onChange={props.onChange}
              value={props.value}
            />
            {rightAddon ? <InputRightAddon>{rightAddon}</InputRightAddon> : ''}
          </InputGroup>

          {error ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
        </FormControl>
      </Box>
    </Flex>
  )
}

export default EntityInput
