import { tw } from '@nickeltech/brise'
import React from 'react'
import { Column, Row } from 'ui'
import ContainerProps from 'ui/src/types'

export type Tab = {
  label: string
  value: string
}

type TransactionTabsProps = {
  tabs: Array<Tab>
  selected?: string
  onSelect?: (tab: string) => void
}

const TabComponent = tw.div<{ active: boolean } & ContainerProps>`
  text-sm
  text-gray-400
  font-medium
  cursor-pointer
  border-b-2
  whitespace-nowrap
  flex
  pb-[10px]

  ${({ active }) => (active ? `!text-gray-800 !border-b-gray-800` : '')}
`

const Filler = tw.div<ContainerProps>`
  h-[32px]
  border-b-2
  border-gray-200
  w-[40px]
`

const TransactionTabs = (props: TransactionTabsProps) => {
  return (
    <Row grow>
      <Column gap="small">
        <Row grow>
          {props.tabs.map((tab, index) => (
            <React.Fragment key={index}>
              <TabComponent
                key={tab.value}
                active={props.selected === tab.value}
                onClick={() => props.onSelect && props.onSelect(tab.value)}
              >
                {tab.label}
              </TabComponent>
              <Filler />
            </React.Fragment>
          ))}
        </Row>
      </Column>
      <Filler className="!w-full" />
    </Row>
  )
}

export const TransactionTabsV2 = (props: TransactionTabsProps) => {
  return (
    <Row grow>
      <Column gap="small">
        <Row grow y="bottom">
          <div className="h-[2px] !w-[20px] !bg-gray-200" />
          {props.tabs.map((tab, index) => (
            <React.Fragment key={index}>
              <TabComponent
                key={tab.value}
                active={props.selected === tab.value}
                onClick={() => props.onSelect && props.onSelect(tab.value)}
              >
                {tab.label}
              </TabComponent>
              <Filler />
            </React.Fragment>
          ))}
        </Row>
      </Column>
      <Filler className="!w-full" />
    </Row>
  )
}

export default TransactionTabs
