import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import useScript from 'react-script-hook'
import {
  LinkPaymentMethodDocument,
  OrganizationBankAccountsDocument,
} from '../../../operations-types'
import Loading from '../../utils/Loading'
import { ModalInput, ModalSelect } from '../../utils/Modal'

type CreditCardInputProps = {
  labelText: string
  id: string
}

type CreditCardFormProps = {
  setOpen: (open: boolean) => void
  tilled: any
  tilledForm?: any
  setShowNotif: (showNotif: {
    message: string
    show: boolean
    header: string
  }) => void
}

function CreditCardForm({
  tilled,
  setOpen,
  setShowNotif,
}: CreditCardFormProps) {
  const form = useRef<any>(null)
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('US')
  const [cardholderName, setCardHolderName] = useState('')
  const [linkPaymentMethod, { loading }] = useMutation(
    LinkPaymentMethodDocument,
    {
      onCompleted: () => {
        setShowNotif({
          message: 'Updated credit card.',
          show: true,
          header: 'Success!',
        })
      },
      refetchQueries: [
        {
          query: OrganizationBankAccountsDocument,
        },
      ],
    },
  )

  useEffect(() => {
    const fieldOptions = {
      styles: {
        base: {
          fontFamily: 'Inter, Arial, sans-serif',
        },
        invalid: {
          ':hover': {
            textDecoration: 'underline dotted red',
          },
        },
      },
    }

    const createForm = async () => {
      form.current = await tilled.form({
        payment_method_type: 'card',
      })
      if (!form.current) {
        return
      }

      form.current
        .createField('cardNumber', fieldOptions)
        .inject('#card-number')
      form.current
        .createField('cardExpiry', fieldOptions)
        .inject('#card-expiry')
      form.current.createField('cardCvv', fieldOptions).inject('#card-cvv')
      await form.current.build()
    }

    createForm()

    return () => {
      if (!form.current) {
        return
      }

      form.current.teardown()
    }
  }, [tilled])

  const savePaymentMethod = () => {
    tilled
      .createPaymentMethod({
        type: 'card',
        billing_details: {
          name: cardholderName,
          address: {
            zip: zip,
            country: country,
          },
        },
      })
      .then(
        (response: any) => {
          linkPaymentMethod({ variables: { paymentMethodId: response.id } })
          setOpen(false)
        },
        (error: any) => {
          console.error(error)
          setOpen(false)
        },
      )
  }

  return (
    <div>
      {!loading && (
        <>
          <div id="credit-card-form" className="space-y-2">
            <CreditCardInput id="card-number" labelText="Card Number" />
            <div className="sm:grid sm:grid-cols-2 sm:gap-3">
              <CreditCardInput id="card-expiry" labelText="Expiry" />
              <CreditCardInput id="card-cvv" labelText="CVV" />
            </div>
            <ModalInput
              title="Cardholder Name"
              onChange={(str) => setCardHolderName(str)}
              autocomplete="name"
            />
            <ModalInput
              title="Zip Code"
              onChange={(str) => setZip(str)}
              autocomplete="zip"
            />
            <ModalSelect
              title="Country / Region"
              onChange={(str) => setCountry(str)}
              options={['US']}
            />
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-nickel-purple text-base font-medium text-white hover:bg-nickel-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-nickel-purple sm:col-start-2 sm:text-sm"
              onClick={() => {
                savePaymentMethod()
              }}
            >
              Update
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {loading && <Loading />}
    </div>
  )
}

function CreditCardInput({ labelText, id }: CreditCardInputProps) {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {labelText}
      </label>
      <div className="mt-1">
        <div
          id={id}
          className="border rounded-md border-gray-300 shadow-sm h-10 pl-2"
        ></div>
      </div>
    </div>
  )
}

type CreditCardModalProps = {
  publicKey: string
  nickelMerchantId: string
  open: boolean
  setOpen: (open: boolean) => void
  setShowNotif: (showNotif: {
    message: string
    show: boolean
    header: string
  }) => void
}

export default function CreditCardModal({
  publicKey,
  nickelMerchantId,
  open,
  setOpen,
  setShowNotif,
}: CreditCardModalProps) {
  const [loading, error] = useScript({
    src: 'https://js.tilled.com/v2',
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div className="px-6 space-y-4 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg leading-6 font-medium text-gray-900"
                  >
                    Update your payment method
                  </Dialog.Title>
                  {!loading && !error && (
                    <CreditCardForm
                      tilled={
                        new (window as any).Tilled(
                          publicKey,
                          nickelMerchantId,
                          {
                            sandbox: import.meta.env.MODE === 'development',
                          },
                        )
                      }
                      setOpen={setOpen}
                      setShowNotif={setShowNotif}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
