export function validateLogin(
  password: string,
  firstName: string,
  lastName: string,
  existingUser: boolean,
  email: string,
) {
  const errors: {
    password: string
    firstName: string
    lastName: string
    existingUser: string
    email: string
  } = {
    password: '',
    firstName: '',
    lastName: '',
    existingUser: '',
    email: '',
  }

  if (!password || password.length < 8) {
    errors['password'] = 'Password length must be greater than 8 characters.'
  } else {
    errors['password'] = ''
  }

  if (!firstName) {
    errors['firstName'] = 'You must provide a first name.'
  } else {
    errors['firstName'] = ''
  }

  if (!lastName) {
    errors['lastName'] = 'You must provide a last name.'
  } else {
    errors['lastName'] = ''
  }

  if (existingUser === true) {
    errors['existingUser'] =
      'This email is already in use. Please use another email.'
  }

  const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
    email,
  )

  if (!validEmail) {
    errors['email'] = 'You must provide a valid email.'
  }
  return {
    errors,
    hasErrors:
      errors['password'] ||
      errors['firstName'] ||
      errors['lastName'] ||
      errors['existingUser'] ||
      errors['email'],
  }
}

export function validateBusiness(name: string, paymentUrl: string) {
  const errors: {
    name: string
    paymentUrl: string
  } = {
    name: '',
    paymentUrl: '',
  }

  if (!name) {
    errors['name'] = 'You must provide a name for your business.'
  } else {
    errors['name'] = ''
  }

  if (
    !paymentUrl ||
    paymentUrl.indexOf(' ') >= 0 ||
    (paymentUrl.match(/\./g) || []).length > 2
  ) {
    errors['paymentUrl'] = 'You must provide a payment url without spaces.'
  } else {
    errors['paymentUrl'] = ''
  }

  return {
    errors,
    hasErrors: errors['name'] || errors['paymentUrl'],
  }
}
