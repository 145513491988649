import { useQuery } from '@apollo/client'
import {
  Vendor,
  VendorsDocument,
  VendorsQueryVariables,
} from '../../operations-types'
import { PageLayout } from '../layout/PageLayout'
import { TableV2, Column, Header, Row, Button, HFlex } from 'ui'
import { FilterItem } from 'ui/src/components/Transactions/AddFilter'
import { VStack, Text, Box, Divider } from '@chakra-ui/react'
import currency from 'currency.js'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { VendorOverlay } from '../ap/VendorOverlay'
import { useState } from 'react'
import { NewBillOverlay } from '../ap/NewBillOverlay'
import { NewVendorOverlay } from '../ap/NewVendorOverlay'
import TableSearchInput from 'ui/src/components/TableSearchInput'

const SMALL_CELL_PX = 40
export function Vendors() {
  const availableSpace = window.screen.availHeight - 420
  const pageSize = Math.floor(availableSpace / SMALL_CELL_PX)

  const [localSearchQuery, setLocalSearchQuery] = useState('')
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const [selectedVendorId, setSelectedVendorId] = useState<string | null>(null)

  const [newBillOpen, setNewBillOpen] = useState(false)

  const [overlayVendor, setOverlayVendor] = useState<Vendor | null>(null)

  const [newVendorOpen, setNewVendorOpen] = useState(false)

  const [vendorName, setVendorName] = useState('')

  const [page, setPage] = useState(1)

  const [queryOpts, setQueryOpts] = useState<VendorsQueryVariables>({
    page: page,
    pageSize: pageSize,
    vendorName: vendorName,
  })

  const { data, loading } = useQuery(VendorsDocument, {
    variables: {
      ...queryOpts,
    },
  })

  const vendors = data?.vendors?.vendors?.map((vendor) => ({
    id: vendor.id || '',
    name: vendor.name || '',
    lastPaidDate: vendor?.lastPaidDate
      ? moment(parseInt(vendor.lastPaidDate, 10)).format('YYYY-MM-DD')
      : '',
    remainingBalance: currency(
      vendor.transactionsSummary?.remainingBalanceInCents || 0,
      { fromCents: true },
    ).format(),
    totalPaid: currency(vendor.transactionsSummary?.totalPaidInCents || 0, {
      fromCents: true,
    }).format(),
    unpaidBills: vendor.unpaidBills?.length || 0,
    action: {
      label: 'See Bills',
      value: vendor.name,
    },
    vendorPayoutMethods: vendor.vendorPayoutMethods,
  }))

  const [searchQuery, setSearchQuery] = useState('')

  const totalPages = Math.ceil((data?.vendors?.totalResults || 0) / pageSize)

  return (
    <PageLayout>
      <Column
        wGrow
        className="w-full bg-white rounded-md max-h-[calc(100vh-2rem)] min-h-[calc(100vh-2rem)]"
      >
        <Row className="rounded-md" between grow>
          <VStack
            spacing="0"
            gap="0"
            alignItems="left"
            className="w-full h-full"
          >
            <Row className="p-4 w-full" between>
              <Row grow y="center" between>
                <Column>
                  <Header variant="page" className="text-[20px]">
                    Vendors
                  </Header>
                  <Text color="gray.500" className="text-sm">
                    View and manage your vendors
                  </Text>
                </Column>
              </Row>
              <Button
                label="New Vendor"
                iconName="plusIcon"
                className="!text-sm !h-[30px]"
                iconPosition="left"
                onClick={() => setNewVendorOpen(true)}
              />
            </Row>
            <Column wGrow className="!pb-0" gap="small">
              <Column className="h-full" wGrow grow>
                <Divider my="1" />
                <Column wGrow>
                  <HFlex
                    w="100%"
                    justifyContent="start"
                    alignContent="start"
                    px="4"
                    py="2"
                    gap="4"
                  >
                    <TableSearchInput
                      placeholder="Search vendor name"
                      {...{
                        value: localSearchQuery,
                        onChange: (e) => {
                          setLocalSearchQuery(e.target.value)
                        },
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            setSearchQuery(localSearchQuery)
                            setVendorName(localSearchQuery)
                            setQueryOpts({
                              ...queryOpts,
                              vendorName: localSearchQuery,
                            })

                            setLocalSearchQuery('')
                          }
                        },
                      }}
                    />
                    {searchQuery && (
                      <FilterItem
                        label="Name"
                        value={searchQuery}
                        onClose={() => {
                          setSearchQuery('')
                          setVendorName('')
                          setQueryOpts({
                            ...queryOpts,
                            vendorName: '',
                          })
                        }}
                      />
                    )}
                  </HFlex>
                  <Box w="100%" pt="4" py={2} className="!pb-0">
                    <TableV2
                      data={vendors || []}
                      headers={[
                        {
                          type: 'description',
                          keyName: 'name',
                          label: 'Vendor Name',
                          grow: 1,
                        },
                        {
                          type: 'description',
                          keyName: 'lastPaidDate',
                          label: 'Last Paid Date',
                        },
                        {
                          type: 'description',
                          keyName: 'remainingBalance',
                          label: 'Remaining Balance',
                          grow: 1,
                        },
                        {
                          type: 'description',
                          keyName: 'totalPaid',
                          label: 'Total Paid',
                          grow: 1,
                        },
                        {
                          type: 'description',
                          keyName: 'unpaidBills',
                          label: 'Unpaid Bils',
                        },
                        {
                          type: 'buttonOptionsLink',
                          keyName: 'action',
                          label: 'Actions',
                          right: true,
                          onClick: async (x: string, y) => {
                            console.log(x, y)
                            navigate(
                              `/dashboard/accounts-payable/bills?vendorName=${y.name}`,
                            )
                          },
                        },
                      ]}
                      loading={loading}
                      rowSize="large"
                      cellSize="large"
                      onClick={(args) => {
                        setSelectedVendorId(args.id)
                        setOpen(true)
                      }}
                      {...{
                        page: queryOpts.page || 1,
                        perPage: queryOpts.pageSize || 10,
                        totalPages: totalPages || 1,
                        onPage: async (page: number) => {
                          await setPage(page)
                          await setQueryOpts({
                            ...queryOpts,
                            page: page,
                          })
                        },
                      }}
                    />
                    <VendorOverlay
                      {...{
                        isOpen: open,
                        setOpen: setOpen,
                        vendorId: selectedVendorId || '',
                        setNewBillOpen: setNewBillOpen,
                        setOverlayVendor: setOverlayVendor,
                      }}
                    />
                    <NewBillOverlay
                      setOpen={setNewBillOpen}
                      isOpen={newBillOpen}
                      vendor={overlayVendor}
                    />
                    <NewVendorOverlay
                      isOpen={newVendorOpen}
                      setOpen={setNewVendorOpen}
                    />
                  </Box>
                </Column>
              </Column>
            </Column>
          </VStack>
        </Row>
      </Column>
    </PageLayout>
  )
}

export default Vendors
