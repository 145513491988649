import { Outlet, useLocation, useNavigate, Location } from 'react-router-dom'
import { TRANSACTIONS_URL } from '../../lib/urls'
import { Overlay } from 'ui'
import { OperationVariables } from '@apollo/client'

const getType = (location: Location) => {
  const paymentMatch = location.pathname.match(
    /\/dashboard\/transactions\/receivable\/(.*)/,
  )

  const refundMatch = location.pathname.match(
    /\/dashboard\/transactions\/refund\/(.*)/,
  )

  const returnMatch = location.pathname.match(
    /\/dashboard\/transactions\/return\/(.*)/,
  )

  const payableMatch = location.pathname.match(
    /\/dashboard\/transactions\/payable\/(.*)/,
  )

  const vendorReturnMatch = location.pathname.match(
    /\/dashboard\/transactions\/vendorReturn\/(.*)/,
  )

  const chargebackMatch = location.pathname.match(
    /\/dashboard\/transactions\/chargeback\/(.*)/,
  )

  const advanceMatch = location.pathname.match(
    /\/dashboard\/transactions\/advance\/(.*)/,
  )

  if (paymentMatch) {
    return 'receivable'
  } else if (refundMatch) {
    return 'refund'
  } else if (returnMatch) {
    return 'return'
  } else if (payableMatch) {
    return 'payable'
  } else if (vendorReturnMatch) {
    return 'vendorReturn'
  } else if (chargebackMatch) {
    return 'chargeback'
  } else if (advanceMatch) {
    return 'advance'
  }
}

type TransactionsOverlayProps = {
  queryOpts: OperationVariables
}

export function TransactionsOverlay({ queryOpts }: TransactionsOverlayProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(TRANSACTIONS_URL)

  const type = getType(location)

  const hasMatch = type !== undefined

  return (
    <Overlay
      open={hasMatch}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet
        context={{
          type: type,
          queryOpts: queryOpts,
        }}
      />
    </Overlay>
  )
}
