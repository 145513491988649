import Layout from './layout/Layout'
import { useState } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Loading from './utils/Loading'
import { FormEventHandler, ChangeEventHandler } from 'react'
import { NewPasswordDocument } from '../operations-types'
import {
  Card,
  CardBody,
  Text,
  VStack,
  Button as ChakraButton,
} from '@chakra-ui/react'
import { Button, InputField } from 'ui'

type ResetPasswordFormProps = {
  onSubmit: FormEventHandler<HTMLFormElement>
  onChange: ChangeEventHandler<HTMLInputElement>
}

function ResetPasswordForm({ onSubmit, onChange }: ResetPasswordFormProps) {
  const buttonStyle = `
    self-center bg-white text-xl text-nickel-purple rounded-md
    border-2 py-2 px-6 hover:text-white hover:bg-nickel-purple border-slate-200"
  `
  const [showPassword, setShowPassword] = useState(false)
  return (
    <form onSubmit={onSubmit}>
      <Card h="100%" w="100%" bgColor="#ECEAE3">
        <CardBody h="100%" w="100%" p="8">
          <VStack w="100%" spacing="0" gap="6">
            <VStack w="100%" spacing="0" gap="6">
              <Text
                fontSize="xl"
                color="dark.600"
                alignSelf="center"
                fontWeight="medium"
              >
                Enter your new password
              </Text>
            </VStack>

            <VStack w="100%" spacing="0" gap="0" alignItems="left">
              <InputField
                id="password"
                fontWeight="medium"
                size="lg"
                onChange={onChange}
                type={showPassword ? 'text' : 'password'}
                bgColor="white"
                autoFocus
                placeholder="Password"
                rightElement={
                  <ChakraButton
                    h="1.5rem"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </ChakraButton>
                }
              />
            </VStack>
            <Button
              w="100%"
              className={buttonStyle}
              type="submit"
              label="Change Password"
              variant="solid"
              status="accent"
              color={'dark.600'}
              _hover={{
                bg: 'accent.500',
                color: 'dark.600',
              }}
            />
          </VStack>
        </CardBody>
      </Card>
    </form>
  )
}

function Expiry() {
  return (
    <Layout>
      <div className="w-full max-w-2xl mt-20">
        <div className="font-medium text-4xl leading-10 tracking-wide">
          Unfortunately, that link is expired. Please request to reset your
          password again.
        </div>
      </div>
    </Layout>
  )
}

export default function ResetPassword() {
  const { token = '' } = useParams()
  const { expiry }: { expiry: number } = jwt_decode(token)
  const [newPassword, setNewPassword] = useState('')

  const [requestNewPassword, { loading, data }] =
    useMutation(NewPasswordDocument)

  const expired = Date.now() > expiry

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    requestNewPassword({
      variables: { newPassword: newPassword, token: token },
    })
  }

  const navigate = useNavigate()

  if (expired) {
    return <Expiry />
  }

  return (
    <Layout>
      <div className="w-full max-w-sm">
        <div className="md:mt-24 mt-16">
          {data && (
            <Card h="100%" w="100%" bgColor="#ECEAE3">
              <CardBody h="100%" w="100%" p="8">
                <VStack w="100%" spacing="0" gap="6">
                  <VStack w="100%" spacing="0" gap="6">
                    <Text
                      fontSize="xl"
                      color="dark.600"
                      alignSelf="center"
                      fontWeight="medium"
                    >
                      Reset Password
                    </Text>

                    <Text color="gray.500">
                      Your password was succesfully changed. Go back to the
                      Nickel Dashboard to login to your account.
                    </Text>
                  </VStack>
                  <Button
                    label="Go to my account"
                    onClick={() => {
                      navigate('/login')
                    }}
                    status="accent"
                    color="dark.600"
                    variant="solid"
                    _hover={{ bg: 'accent.500' }}
                  />
                </VStack>
              </CardBody>
            </Card>
          )}
          {loading && <Loading />}
          {!loading && !data && (
            <ResetPasswordForm
              onChange={(e) => setNewPassword(e.target.value)}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}
