import { Flex, Text } from '@chakra-ui/react'
import { Column } from 'ui'

type PaymentAmountSubmittedProps = {
  paymentSubmitted?: string
  amount: string
  label: string
}

const AmountSubmittedLabel = ({
  amount,
  label,
}: {
  amount: string
  label: string
}) => {
  return (
    <Column gap={'small'}>
      <Text className="text-gray-500 font-normal text-sm">{label}</Text>
      <Text className="text-dark-800 text-3xl font-semibold">{amount}</Text>
    </Column>
  )
}

const AmountHeader = (props: PaymentAmountSubmittedProps) => {
  return (
    <Flex
      px={8}
      py={4}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'top'}
      w={'100%'}
    >
      <AmountSubmittedLabel {...props} />
      {props.paymentSubmitted && (
        <Text className="text-gray-500 text-sm">{props.paymentSubmitted}</Text>
      )}
    </Flex>
  )
}

export default AmountHeader
