import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PAYOUTS_URL } from '../../lib/urls'
import PaymentInnerOverlay from '../payments/PaymentInnerOverlay'
import PayoutInnerOverlay from './PayoutInnerOverlay'

type OVERLAY_STATES = 'payment' | 'payout'

export default function PayoutOverlay() {
  const { payoutId } = useParams() as { payoutId: string }
  const [paymentId, setPaymentId] = useState<string | null>(null)

  const [state, setState] = useState<OVERLAY_STATES>('payout')
  const navigate = useNavigate()
  const exitPayoutOverlay = () => navigate(PAYOUTS_URL)
  const onBack = () => setState('payout')

  const STATE_TO_ELEMENT: { [state in OVERLAY_STATES]: JSX.Element } = {
    payment: (
      <>
        {paymentId && (
          <PaymentInnerOverlay
            paymentId={paymentId}
            exitOverlay={exitPayoutOverlay}
            onBack={onBack}
          />
        )}
      </>
    ),
    payout: (
      <PayoutInnerOverlay
        payoutId={payoutId}
        onExit={exitPayoutOverlay}
        onTransactionClick={(transaction) => {
          setState('payment')
          setPaymentId(transaction.transactionId)
        }}
      />
    ),
  }

  return <>{STATE_TO_ELEMENT[state]}</>
}
