import Icon, { IconName } from '../Icons'
import Column from '../Layout/Column'
import Row from '../Layout/Row'
import Separator from '../Layout/Separator'
import Text, { UnstandardizedText } from '../Typography/Text'
import usdFormatter from '../../formatter'
import Badge from '../v2/badge/Badge'
import startCase from 'lodash/startCase'

export type OverlayBreakdownProps = {
  title: string
  submittedAmount: number
  chargedAmount: number
  feesAmount: number
  totalAmount: number
}

export type QueryParam = {
  label: string
  answer: string
}

export type CheckoutLinkBreakdownProps = {
  title: string
  steps: Record<string, Record<string, Array<any> | string | boolean | number>>
  queryParams: Array<QueryParam>
}

type BreakdownType =
  | 'credit'
  | 'debit'
  | 'total'
  | 'number'
  | 'email'
  | 'address'

type BreakdownProps = {
  breakdownType: BreakdownType
  headerName: string
  headerValue?: number
  required?: boolean
}

const TYPE_TO_ICON: Record<string, IconName> = {
  credit: 'currencyDollar',
  debit: 'receiptPercent',
  total: 'calculator',
  number: 'clipboard',
  email: 'atSymbol',
  address: 'mapPin',
}

function getHeaderValue(headerValue: number, breakdownType: BreakdownType) {
  if (breakdownType == 'debit') {
    return `(${usdFormatter.format(Math.abs(headerValue))})`
  }

  return usdFormatter.format(headerValue)
}

export function Breakdown(props: BreakdownProps) {
  return (
    <Row between grow y="center">
      <Row y="center" gap="small">
        <Icon name={TYPE_TO_ICON[props.breakdownType]} size="small" />
        {props.breakdownType == 'total' ? (
          <UnstandardizedText className="text-gray-800 text-base font-medium">
            {props.headerName}
          </UnstandardizedText>
        ) : (
          <Text>{props.headerName}</Text>
        )}
      </Row>
      <Text>
        {props.headerValue != undefined &&
          getHeaderValue(props.headerValue, props.breakdownType)}
      </Text>
      {props.required ? <Badge variant="error">Required</Badge> : ''}
    </Row>
  )
}

export function OverlayBreakdown(props: OverlayBreakdownProps) {
  return (
    <Column spacing="largeLarge" gap="medium" wGrow>
      <UnstandardizedText className="font-large font-semibold text-gray-800">
        {props.title}
      </UnstandardizedText>
      <Column gap="medium" wGrow>
        <Breakdown
          breakdownType="credit"
          headerName="Amount Submitted"
          headerValue={props.submittedAmount}
        />
        <Breakdown
          breakdownType="credit"
          headerName="Amount Charged"
          headerValue={props.chargedAmount}
        />
        <Separator orientation="horizontal" />
        <Breakdown
          breakdownType="debit"
          headerName="Fees"
          headerValue={props.feesAmount}
        />
        <Separator orientation="horizontal" />
        <Breakdown
          breakdownType="total"
          headerName="Net Payment"
          headerValue={props.totalAmount}
        />
      </Column>
    </Column>
  )
}

export function CheckoutLinkBreakdown(props: CheckoutLinkBreakdownProps) {
  return (
    <Column spacing="largeLarge" gap="medium" wGrow>
      <UnstandardizedText className="font-large font-semibold text-gray-800">
        Checkout Link: {props.title}
      </UnstandardizedText>
      <Column gap="medium" wGrow>
        {props.queryParams.length > 0 ? (
          <UnstandardizedText className="font-large font-semibold text-gray-800">
            Query Parameters
          </UnstandardizedText>
        ) : (
          ''
        )}

        {props.queryParams.map((param, key) => (
          <Column key={key} wGrow gap="small">
            <Row between grow y="center">
              <div className="text-sm text-gray-600">{param.label}</div>
              <div className="text-sm font-semibold text-gray-800">
                {param.answer}
              </div>
            </Row>
          </Column>
        ))}
      </Column>

      <Column gap="medium" wGrow>
        {Object.keys(props.steps).map((sT, key) => (
          <Column key={key} wGrow>
            <Row between grow y="center" className="py-2">
              <div className="font-semibold">{startCase(sT)}</div>
              <div>Step {(props.steps[sT].step as number) + 1}</div>
            </Row>
            <Column wGrow gap="medium">
              {sT === 'additional_information' ? (
                (props.steps[sT].questions as Array<any>).map((q: any, i) => (
                  <Column key={i} wGrow gap="small">
                    <Row between grow y="center">
                      <div className="text-sm text-gray-400">{q.question}</div>
                      {q.questionType === 'address' ? (
                        <div className="text-sm font-semibold text-gray-800">
                          {q.answer.streetAddress} {q.answer.city},{' '}
                          {q.answer.state} {q.answer.zipCode}
                        </div>
                      ) : (
                        <div className="text-sm font-semibold text-gray-800">
                          {q.answer}
                        </div>
                      )}
                    </Row>
                    <Separator orientation="horizontal" />
                  </Column>
                ))
              ) : sT === 'terms_of_service' ? (
                <Row between grow y="center">
                  <div className="text-sm text-gray-400">Terms Accepted</div>
                  <div className="text-sm font-semibold text-gray-800">
                    {props.steps[sT].answer ? 'Yes' : 'No'}
                  </div>
                </Row>
              ) : (
                ''
              )}
            </Column>
          </Column>
        ))}
      </Column>
    </Column>
  )
}

export default OverlayBreakdown
