import * as yup from 'yup'
import {
  Column,
  OverlayHeader,
  OverlayTitle,
  Separator,
  Form,
  Text,
  Body,
} from 'ui'
import { RefundButton } from './RefundButton'
import formatter from 'ui/src/formatter'
import {
  InternalRefetchQueriesInclude,
  useMutation,
  useQuery,
} from '@apollo/client'
import {
  GetPaymentDocument,
  RefundPaymentDocument,
} from '../../operations-types'
import RadioInput from 'ui/src/components/Inputs/RadioInput'
import {
  Select,
  FormLabel,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react'
import { PaymentHelper } from './PaymentHelper'
import { useState } from 'react'
import classNames from 'classnames'
import currency from 'currency.js'

type PaymentOverlayRefundProps = {
  onBack: () => void
  exitOverlay: () => void
  onRefunded: (paymentId: string, amount: number) => void
  onError: (paymentId: string, error: string) => void
  paymentId: string
  refetchQueries: InternalRefetchQueriesInclude
}

export default function PaymentOverlayRefund({
  onBack,
  exitOverlay,
  onRefunded,
  onError,
  paymentId,
  refetchQueries,
}: PaymentOverlayRefundProps) {
  const [error, setErrorMessage] = useState<string | null>(null)
  const [refundProcessing, setRefundProcessing] = useState(false)

  const { loading, data } = useQuery(GetPaymentDocument, {
    variables: { paymentId: paymentId },
  })

  const paymentData = data?.payment?.payment

  const [refundPayment] = useMutation(RefundPaymentDocument, {
    onCompleted: (data, options) => {
      if (data.refundPayment?.error) {
        onError(
          options?.variables?.paymentId,
          data.refundPayment.error?.message || '',
        )
      } else {
        if (options?.variables?.refundPlatformFee) {
          onRefunded(
            options?.variables?.paymentId,
            paymentHelper.remainingAmountWithoutFeeDollars(),
          )
        } else {
          onRefunded(
            options?.variables?.paymentId,
            options?.variables?.amount / 100,
          )
        }
      }
    },
    refetchQueries: refetchQueries,
  })

  if (loading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const paymentHelper = new PaymentHelper(paymentData!)

  if (paymentHelper.remainingAmountWithFeeCents() <= 0) {
    return (
      <Column wGrow grow>
        <OverlayHeader onBack={onBack} onClose={() => exitOverlay()} />
        <OverlayTitle title="Refund" subtitle={formatter.format(0)} />
        <Separator orientation="horizontal" />
        <Column spacing="medium">
          <Text>This payment is fully refunded.</Text>
        </Column>
      </Column>
    )
  }

  const RefundFormSchema = yup.object().shape({
    refundType: yup.string().required(),
    amount: yup.number().when('refundType', {
      is: 'partial',
      then: yup
        .number()
        .min(0.01)
        .max(paymentHelper.remainingAmountWithFeeDollars())
        .required(),
      otherwise: undefined,
    }),
    reason: yup.string().required(),
  })

  const validateAmount = (amount: string | undefined) => {
    if (amount === undefined || amount === '') return
    const amountDollars = parseFloat(amount)

    if (Number.isNaN(amountDollars)) {
      setErrorMessage('Please enter a valid amount')
      return
    }

    if (amountDollars > paymentHelper.remainingAmountWithFeeDollars()) {
      setErrorMessage(
        `You may only perform a custom refund up to ${formatter.format(
          paymentHelper.remainingAmountWithFeeDollars(),
        )}.`,
      )
      return
    }

    setErrorMessage(null)
  }

  return (
    <Form
      {...{
        validationSchema: RefundFormSchema,
        initialValues: {
          refundType: '',
          amount: '',
          reason: '',
        },
        onSubmit: async (values) => {
          setRefundProcessing(true)
          if (values.refundType === 'full') {
            refundPayment({
              variables: {
                paymentId: paymentId,
                amount: paymentHelper.remainingAmountWithFeeCents(),
                reason: values.reason,
              },
            })
          }

          if (values.refundType === 'partial') {
            refundPayment({
              variables: {
                paymentId: paymentId,
                amount: currency(values.amount).intValue,
                reason: values.reason,
              },
            })
          }
        },
        onChange: (values) => validateAmount(values.amount),
        className: 'w-full h-full',
      }}
    >
      {(formik) => (
        <Column wGrow grow>
          <OverlayHeader onBack={onBack} onClose={() => exitOverlay()} />
          <OverlayTitle
            title="Refund"
            subtitle={formatter.format(
              paymentHelper.remainingAmountWithFeeCents() / 100,
            )}
          />
          <Separator orientation="horizontal" />
          <div className="p-8 w-full">
            <Column gap="medium">
              <RadioInput
                name="refundType"
                label="How much to refund?"
                options={[
                  {
                    label: `Full Refund of ${formatter.format(
                      paymentHelper.remainingAmountWithFeeDollars(),
                    )}`,
                    value: 'full',
                  },
                  {
                    label: 'Custom Amount',
                    value: 'partial',
                    subOption:
                      formik.values.refundType === 'partial' ? (
                        <Column spacing="medium" wGrow>
                          <InputGroup size="md">
                            <InputLeftElement
                              pointerEvents="none"
                              color="gray.700"
                              children="$"
                            />
                            <Input
                              name="amount"
                              size="md"
                              onChange={formik.handleChange}
                              placeholder="Enter amount"
                            />
                          </InputGroup>
                        </Column>
                      ) : (
                        ''
                      ),
                  },
                ]}
              />
              <Box w="100%">
                <FormLabel>Reason</FormLabel>
                <Select
                  placeholder="Select Reason"
                  name="reason"
                  onChange={formik.handleChange}
                  variant="outline"
                >
                  <option value="duplicate">Duplicate</option>
                  <option value="fraudulent">Fraudulent</option>
                  <option value="requested_by_customer">
                    Requested by Customer
                  </option>
                </Select>
              </Box>

              <Body
                size="small"
                status="error"
                strong
                className={classNames('h-0', { visible: error != null })}
              >
                {error}
              </Body>
            </Column>
          </div>

          <div className="mt-auto w-full">
            <RefundButton loading={refundProcessing} />
          </div>
        </Column>
      )}
    </Form>
  )
}
