import { create } from 'zustand'
import { QuestionForm } from '../../operations-types'

type PaymentLink = Omit<
  QuestionForm,
  | 'checkoutActive'
  | 'completedCount'
  | 'schema'
  | 'updatedAt'
  | 'status'
  | 'checkoutPath'
  | 'completedAmountInCents'
  | 'createdAt'
  | 'requestedAmount'
> & {
  completedAmountInCents: string
  requestedAmount: string
}

type PaymentLinkState = {
  paymentLinks: PaymentLink[]
  setPaymentLinks: (paymentLinks: PaymentLink[]) => void
  reset: () => void
}

export const usePaymentLinkStore = create<PaymentLinkState>((set, _) => ({
  paymentLinks: [],
  setPaymentLinks: (paymentLinks: PaymentLink[]) => set({ paymentLinks }),
  reset: () => set({ paymentLinks: [] }),
}))
