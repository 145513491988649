import { Text, Icon } from '@chakra-ui/react'
import { Row } from 'ui'
import { ChevronRightIcon } from '@chakra-ui/icons'
import classNames from 'classnames'
import { Flex } from '@chakra-ui/react'

type Props = {
  currentStep: number
}
type PreviousProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const RegistrationBreadcrumbNavigator = (_props: Props) => {
  return (
    <Flex height="32px">
      <Row y="center" className="gap-[2px] z-20">
        <Text
          color={_props.currentStep === 0 ? 'black' : 'purple.700'}
          fontSize="sm"
          fontWeight={_props.currentStep === 0 ? 'medium' : 'normal'}
        >
          Account
        </Text>
        <Icon as={ChevronRightIcon} boxSize={4} color="gray.400" />
        <Text
          color={
            _props.currentStep === 1
              ? 'black.400'
              : _props.currentStep < 1
              ? 'gray.400'
              : 'purple.700'
          }
          fontSize="sm"
          fontWeight={_props.currentStep === 1 ? 'medium' : 'normal'}
        >
          Company & Brand
        </Text>
        <Icon as={ChevronRightIcon} boxSize={4} color="gray.400" />
        <Text
          color={
            _props.currentStep === 2
              ? 'black.400'
              : _props.currentStep < 2
              ? 'gray.400'
              : 'purple.700'
          }
          fontSize="sm"
          fontWeight={_props.currentStep === 1 ? 'medium' : 'normal'}
        >
          Onboarding
        </Text>
      </Row>
    </Flex>
  )
}

export const PreviousStepLink = (_props: PreviousProps) => {
  return (
    <Text
      p="13px"
      fontSize="md"
      color="purple.700"
      className={classNames({
        'text-[12px] !cursor-pointer': true,
      })}
      {...{
        onClick: (e: any) => {
          _props.onClick(e)
        },
      }}
    >
      Return to Previous Step
    </Text>
  )
}

export default RegistrationBreadcrumbNavigator
