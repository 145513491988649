import classNames from 'classnames'
import { useState } from 'react'

import Icon from '../Icons'
import Text from '../Typography/Text'
import Column, { ColumnProps } from '../Layout/Column'
import Dropdown from '../Dropdown'
import Row from '../Layout/Row'
import Label from '../Typography/Label'
import { Card, CardBody, Text as ChakraText } from '@chakra-ui/react'
import Separator from '../Layout/Separator'
import { Image } from '@chakra-ui/react'

const MENU_POSITION = 'top-start'

export type SidebarFooterOption = {
  label: string
  url?: string
  onClick?: () => void
}

export type FooterColumnProps = ColumnProps

export type SidebarFooterProps = {
  label: string
  accountName: string
  footerOptions: Array<SidebarFooterOption>
  setSelected?: (selected: string) => void
  organizationTier?: string
  logoUrl?: string
  planUrl?: string
}

export const SidebarFooterUpgradeCTA = ({ url }: { url: string }) => {
  return (
    <Card
      bgColor="dark.800"
      mb="4"
      mx="2"
      borderColor="purple.300"
      _hover={{ borderColor: 'accent.300', bgColor: 'dark.700' }}
      onClick={() => {
        window.open(url)
      }}
      cursor="pointer"
    >
      <CardBody p="4">
        <ChakraText color="white" fontSize="sm">
          Upgrade to{' '}
          <ChakraText as="span" fontWeight="semibold" color="accent.300">
            Nickel Pro{' '}
          </ChakraText>
          and accelerate your business!
        </ChakraText>
      </CardBody>
    </Card>
  )
}

export const SidebarFooter = ({
  label,
  accountName,
  footerOptions,
  logoUrl,
}: SidebarFooterProps) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false)

  const options = footerOptions.map((fO) => ({
    label: fO.label,
    onClick: fO.onClick
      ? () => {
          fO.onClick && fO.onClick()
        }
      : () => {
          setVisibleDropdown(false)
        },
  }))

  return (
    <>
      <Separator orientation="horizontal" className="bg-sidebar-separator" />
      <Dropdown
        {...{
          position: MENU_POSITION,
          options,
          visible: visibleDropdown,
          onVisible: (e) => setVisibleDropdown(e),
          fullWidth: true,
          className: !visibleDropdown ? '!hidden' : '',
        }}
      >
        <Row grow spacing="small" className="cursor-default">
          <Row
            rounded
            grow
            y="center"
            gap="medium"
            spacing="xsmall"
            className="hover:bg-sidebar-hover cursor-pointer"
          >
            <Image
              height="24px"
              width="24px"
              src={
                logoUrl ||
                `https://s3.amazonaws.com/nickel-user-uploads/nickel-logo.png`
              }
              alt="logo"
            />

            <Column
              gap="none"
              x="left"
              y="center"
              wGrow
              className="overflow-hidden"
            >
              <Label className={`text-slate-200`}>{accountName}</Label>
              <Text
                {...{ variant: 'primary' }}
                className="text-slate-200 whitespace-nowrap !text-xs"
              >
                {label}
              </Text>
            </Column>

            <Icon
              size="medium"
              className={classNames({
                'transition-all': true,
                'rotate-180': visibleDropdown,
                'stroke-white': true,
              })}
              name="chevronDown"
            />
          </Row>
        </Row>
      </Dropdown>
    </>
  )
}

export default SidebarFooter
