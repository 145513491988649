import { Menu } from '@headlessui/react'
import Dropdown from '../DropdownV2'
import FilterDateComponent from './FilterDateComponent'
import FilterMenu from './FilterMenu'
import { FilterCustomerComponent, FilterIdComponent } from './FilterComponent'
import { FilterItem } from '../PaymentLinks/PaymentLinksFilterComponent'

export type FilterDropdownProps = {
  label: string
  onClick?: (value: string) => void
}

export function FilterDropdown({ label, onClick }: FilterDropdownProps) {
  const items: FilterItem[] = [
    {
      label: 'Id',
      value: 'id',
      iconName: 'magnifyingGlass',
      component: <FilterIdComponent />,
    },
    {
      label: 'Customer',
      value: 'customer',
      iconName: 'userCircle',
      component: <FilterCustomerComponent />,
    },
    {
      label: 'Date',
      value: 'date',
      iconName: 'calendar',
      component: <FilterDateComponent />,
    },
  ]

  return (
    <Dropdown label={label}>
      <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 focus:outline-none">
        <FilterMenu items={items} selected={0} onClick={onClick} />
      </Menu.Items>
    </Dropdown>
  )
}

export default FilterDropdown
