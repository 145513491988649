import { Status } from '../../types'
import Row from '../Layout/Row'
import Label from '../Typography/Label'
import { UnstandardizedIndicator } from './Indicator'

export type UnreadProps = {
  count: number
  status: Status
  indicator?: boolean
}

export const Unread = (props: UnreadProps) => {
  return (
    <Row x="left" y="center" gap="small">
      {props.indicator && (
        <UnstandardizedIndicator
          {...{
            status: props.status,
            variant: 'primary',
            size: 'small',
            className: 'bg-yellow-300',
          }}
        />
      )}
      <Label>{props.count}</Label>
    </Row>
  )
}

export default Unread
