import { UnstandardizedColumn } from '../Layout/Column'

export type OverlayTitleProps = {
  title: string
  subtitle: string
  className?: string
  quickbooksLink?: string
}
export function OverlayTitle({
  title,
  subtitle,
  className,
  quickbooksLink,
}: OverlayTitleProps) {
  return (
    <UnstandardizedColumn className={className || 'mx-8 py-4'}>
      <div className="text-gray-800 text-2xl font-semibold">{title}</div>
      <div className="text-gray-500">{subtitle}</div>
      {quickbooksLink && (
        <a
          className="text-purple-600 text-xs cursor-pointer"
          target="_blank"
          rel="noreferrer"
          href={quickbooksLink}
        >
          View in Quickbooks
        </a>
      )}
    </UnstandardizedColumn>
  )
}

export default OverlayTitle
