import Icon from '../Icons'
import Row from '../Layout/Row'
import { Body } from '../v2/Typography'

export type CreditCardDetailsProps = {
  cardLastFour: string
}

export const CreditCardDetails = (props: CreditCardDetailsProps) => {
  return (
    <Row gap="xsmall" y="center">
      <Icon name="blackCreditCard" size="small" />
      <Body size="base" variant="primary" strong={true}>
        ••{props.cardLastFour}
      </Body>
    </Row>
  )
}

export default CreditCardDetails
