import { Button, ValidatingInput, Form, Separator, FieldTooltip } from 'ui'
import { useRef, useState } from 'react'
import * as yup from 'yup'

import '@pqina/pintura/pintura.css'
import { PinturaEditorModal } from '@pqina/react-pintura'
import { getEditorDefaults } from '@pqina/pintura'

import {
  Flex,
  Card,
  VStack,
  Box,
  HStack,
  Heading,
  Text,
  FormLabel,
  CardBody,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react'

import RegistrationBreadcrumbNavigator, {
  PreviousStepLink,
} from './RegistrationBreadcrumbNavigator'

import OnboardingHeader from '../onboarding/OnboardingHeader'

const DEFAULT_BANNER_KEY_URL =
  'https://nickel-user-uploads.s3.amazonaws.com/defaultbanner.jpeg'

type BusinessInfoProps = {
  onBack: React.MouseEventHandler<HTMLButtonElement>
  onRegister: React.MouseEventHandler<HTMLButtonElement>
  name: string
  onName: React.ChangeEventHandler<HTMLInputElement>
  paymentUrl: string
  setPaymentUrl: (url: string) => void
  businessErrors: { name: string; paymentUrl: string }
  image: string
  setImage: (img: string) => void
  banner: string
  setBanner: (img: string) => void
}

const BusinessInfoFormSchema = yup.object().shape({
  name: yup.string().required('Business name is required'),
  paymentUrl: yup.string().required('Payment URL is required'),
})

const editorDefaults = getEditorDefaults({
  imageCropAspectRatio: 1,
  enableCanvasAlpha: true,
  enableUtils: false,
  imageWriter: {
    targetSize: {
      width: 100,
      height: 100,
    },
  },
})

const bannerEditorDefaults = getEditorDefaults({
  imageCropAspectRatio: 4.55,
  enableCanvasAlpha: true,
  enableUtils: false,
})

function UploadImage({
  title,
  forLabel,
  setImage,
}: {
  title: string
  forLabel: string
  setImage: (arg: string) => void
}) {
  const [editorEnabled, setEditorEnabled] = useState(false)

  const [editorSrc, setEditorSrc] = useState<File | undefined>(undefined)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = () => {
    // Exit if no files selected or fileInputRef is null
    if (!fileInputRef.current?.files?.length) return

    // Edit the selected file
    setEditorEnabled(true)
    setEditorSrc(fileInputRef.current.files[0])
  }

  const handleEditorProcess = async (imageState: any) => {
    // Exit if fileInputRef is null
    if (!fileInputRef.current) return

    // Create a files list
    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(imageState.dest)

    // Assign new files
    fileInputRef.current.files = dataTransfer.files

    const convertBase64 = (file: File) => {
      return new Promise<string | null>((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result as string)
        }
        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    }
    setResult(URL.createObjectURL(imageState.dest))
    const base64 = await convertBase64(imageState.dest)
    setImage(base64 as string)
  }

  const handleEditorHide = () => setEditorEnabled(false)

  const [result, setResult] = useState(
    `${forLabel === 'logo-upload' ? '' : `${DEFAULT_BANNER_KEY_URL}`}`,
  )

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 w-full">
      <div>
        <div className="flex flex-col space-y-8">
          <VStack alignItems="left" justifyItems="left" spacing="0" gap="0">
            <FormLabel>{title}</FormLabel>
            <FormLabel fontSize="xs">(Optional)</FormLabel>
          </VStack>

          {result && <img className="w-32" src={result} alt="Company logo" />}
        </div>
      </div>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor={forLabel}
                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload an image</span>
                <input
                  id={forLabel}
                  className="sr-only"
                  name={forLabel}
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                {editorEnabled && (
                  <PinturaEditorModal
                    {...(forLabel === 'logo-upload'
                      ? editorDefaults
                      : bannerEditorDefaults)}
                    src={editorSrc}
                    onHide={handleEditorHide}
                    onProcess={handleEditorProcess}
                  />
                )}
              </label>
            </div>
            {forLabel === 'logo-upload' && (
              <p className="text-xs text-gray-500">At least 50x50 pixels</p>
            )}
            {forLabel === 'banner-upload' && (
              <p className="text-xs text-gray-500">At least 640x140 pixels</p>
            )}
            <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function BusinessInfo({
  onBack,
  onRegister,
  name,
  onName,
  paymentUrl,
  businessErrors,
  setImage,
  setBanner,
  setPaymentUrl,
}: BusinessInfoProps) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleMoveCursor = () => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, 0)
      inputRef.current.focus()
    }
  }
  return (
    <Box mt="32px">
      <Card mx="auto" maxW="2xl">
        <Flex flexDirection="column">
          <>
            <OnboardingHeader />
            <HStack
              pt="1"
              ps="6"
              borderBottomWidth="1px"
              borderBottomStyle="solid"
              borderBottomColor="gray.200"
              pb="1"
            >
              <RegistrationBreadcrumbNavigator currentStep={1} />
            </HStack>
            <Form
              className="w-full"
              {...{
                validationSchema: BusinessInfoFormSchema,
                initialValues: {
                  name: name,
                  paymentUrl: paymentUrl,
                },
              }}
            >
              {(formik) => {
                return (
                  <>
                    <CardBody p="6" boxShadow="0">
                      <VStack
                        spacing="0"
                        gap="0"
                        w="100%"
                        alignItems="left"
                        pb="4"
                      >
                        <Heading
                          fontSize="xl"
                          fontWeight="semibold"
                          w="100%"
                          py="4"
                        >
                          Registration
                        </Heading>
                        <Text>
                          Next, let's get some information about your business.
                        </Text>
                      </VStack>
                      <VStack spacing="2" gap="2">
                        <ValidatingInput
                          id="business-name"
                          fontSize="sm"
                          label="Business Name"
                          isInvalid={!!businessErrors.name}
                          onChange={(e) => {
                            onName(e)
                            formik.handleChange(e)
                            setPaymentUrl(
                              e.target.value
                                .replace(/[^a-z0-9]/gi, '')
                                .toLowerCase(),
                            )
                            formik.handleChange(e)
                          }}
                          value={name}
                          error={businessErrors.name}
                        />

                        <FormControl>
                          <ValidatingInput
                            id="payment-url"
                            fontSize="sm"
                            label="Payment Portal Subdomain"
                            value={paymentUrl}
                            isInvalid={!!businessErrors.paymentUrl}
                            onClick={handleMoveCursor}
                            onChange={(e) => {
                              setPaymentUrl(
                                e.target.value
                                  .replace(/[^a-z0-9]/gi, '')
                                  .toLowerCase(),
                              )
                              formik.handleChange(e)
                            }}
                            error={businessErrors.paymentUrl}
                            placeholder="Your subdomain (e.g. businessname)"
                            rightAddon=".nickelpayments.com"
                            popover={
                              <FieldTooltip
                                fontWeight="bold"
                                color="blue.600"
                                text="This is the unique subdomain where your customers will be directed to make payments. Please enter just your subdomain (e.g. Your business name)"
                              />
                            }
                          />
                          {paymentUrl && (
                            <HStack w="100%" pt="2">
                              <FormHelperText
                                color="gray.800"
                                fontWeight="medium"
                              >
                                Your customers will pay you at:
                              </FormHelperText>
                              <FormHelperText
                                color="purple.600"
                                fontWeight="semibold"
                              >
                                https://{paymentUrl}
                                .nickelpayments.com
                              </FormHelperText>
                            </HStack>
                          )}
                        </FormControl>

                        <UploadImage
                          setImage={setImage}
                          title="Company Logo"
                          forLabel="logo-upload"
                        />
                        <UploadImage
                          setImage={setBanner}
                          title="Company Banner"
                          forLabel="banner-upload"
                        />
                      </VStack>
                    </CardBody>
                    <Separator orientation="horizontal" />

                    <VStack m="5" alignItems="center" spacing="medium" gap="0">
                      <Button
                        type="submit"
                        status="primary"
                        variant="solid"
                        size="lg"
                        width="100%"
                        label="Register"
                        onClick={onRegister}
                        isDisabled={Object.keys(formik.errors).length !== 0}
                      />
                      <PreviousStepLink onClick={onBack} />
                    </VStack>
                  </>
                )
              }}
            </Form>
          </>
        </Flex>
      </Card>
    </Box>
  )
}

export default BusinessInfo
