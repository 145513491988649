import ContainerProps from '../../types'

export const Jcb = (props: ContainerProps) => {
  return (
    <svg
      enableBackground="new 0 0 780 500"
      viewBox="0 0 780 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <linearGradient
        id="a"
        gradientTransform="matrix(132.87 0 0 -323.02 -10686 208760)"
        gradientUnits="userSpaceOnUse"
        x1="83.977"
        x2="84.977"
        y1="645.5"
        y2="645.5"
      >
        <stop offset="0" stopColor="#007b40" />
        <stop offset="1" stopColor="#55b330" />
      </linearGradient>
      <linearGradient
        id="b"
        gradientTransform="matrix(133.43 0 0 -323.02 -11031 208760)"
        gradientUnits="userSpaceOnUse"
        x1="83.984"
        x2="84.979"
        y1="645.5"
        y2="645.5"
      >
        <stop offset="0" stopColor="#1d2970" />
        <stop offset="1" stopColor="#006dba" />
      </linearGradient>
      <linearGradient
        id="c"
        gradientTransform="matrix(132.96 0 0 -323.03 -10842 208770)"
        gradientUnits="userSpaceOnUse"
        x1="83.978"
        x2="84.977"
        y1="645.5"
        y2="645.5"
      >
        <stop offset="0" stopColor="#6e2b2f" />
        <stop offset="1" stopColor="#e30138" />
      </linearGradient>
      <path
        d="m40 0h700c22.092 0 40 17.909 40 40v420c0 22.092-17.908 40-40 40h-700c-22.091 0-40-17.908-40-40v-420c0-22.091 17.909-40 40-40z"
        fill="#0e4c96"
      />
      <path
        d="m632.24 361.27c0 41.615-33.729 75.36-75.36 75.36h-409.13v-297.88c0-41.626 33.733-75.37 75.365-75.37l409.12-.001.001 297.89z"
        fill="#fff"
      />
      <path
        d="m498.86 256.54c11.684.253 23.437-.516 35.076.4 11.787 2.199 14.629 20.043 4.156 25.888-7.141 3.851-15.633 1.433-23.379 2.113h-15.852zm41.833-32.145c2.596 9.164-6.238 17.392-15.066 16.13h-26.767c.185-8.642-.368-18.021.271-26.208 10.725.301 21.549-.616 32.21.479 4.581 1.151 8.414 4.917 9.352 9.599zm64.428-135.9c.498 17.501.071 35.927.214 53.783-.035 72.596.072 145.19-.055 217.79-.47 27.207-24.582 50.844-51.601 51.387-27.046.111-54.095.016-81.142.047v-109.75c29.47-.154 58.959.307 88.417-.232 13.667-.859 28.632-9.875 29.27-24.914 1.61-15.103-12.632-25.551-26.152-27.201-5.198-.135-5.044-1.516 0-2.117 12.892-2.787 23.02-16.133 19.226-29.499-3.236-14.058-18.772-19.499-31.697-19.472-26.351-.18-52.709-.026-79.062-.077.172-20.489-.354-41 .286-61.474 2.087-26.716 26.806-48.747 53.447-48.27h78.849z"
        fill="url(#a)"
      />
      <path
        d="m174.74 139.54c.674-27.163 24.889-50.611 51.875-51.007 26.944-.083 53.891-.012 80.837-.036-.074 90.885.148 181.78-.112 272.66-1.038 26.835-24.99 49.835-51.679 50.308-26.996.099-53.995.014-80.992.042v-113.45c26.223 6.194 53.722 8.832 80.473 4.721 15.993-2.574 33.488-10.424 38.902-27.014 3.986-14.191 1.742-29.126 2.334-43.691v-33.824h-46.297c-.208 22.369.426 44.779-.335 67.125-1.248 13.734-14.846 22.46-27.8 21.994-16.066.17-47.898-11.639-47.898-11.639-.08-41.918.466-94.409.692-136.18z"
        fill="url(#b)"
      />
      <path
        d="m324.72 211.89c-2.434.517-.489-8.301-1.113-11.646.165-21.15-.347-42.323.283-63.458 2.083-26.829 26.991-48.916 53.739-48.288h78.766c-.073 90.884.147 181.78-.111 272.66-1.039 26.834-24.992 49.833-51.681 50.308-26.997.1-53.997.015-80.997.043v-124.3c18.44 15.128 43.5 17.483 66.473 17.524 17.316-.006 34.534-2.674 51.35-6.67v-22.772c-18.953 9.446-41.232 15.446-62.243 10.019-14.655-3.65-25.294-17.812-25.056-32.937-1.699-15.728 7.524-32.335 22.981-37.011 19.189-6.008 40.107-1.413 58.096 6.397 3.854 2.019 7.765 4.521 6.222-1.921v-17.9c-30.084-7.156-62.101-9.792-92.329-2.004-8.749 2.469-17.271 6.212-24.38 11.958z"
        fill="url(#c)"
      />
    </svg>
  )
}

export default Jcb
