import { tw } from '@nickeltech/brise'
import Skeleton from 'react-loading-skeleton'
import ContainerProps from '../../types'

export type SkeletonLoaderProps = {
  absolute?: boolean
} & any &
  ContainerProps

export const SkeletonAbsolutePositionStyle = tw.style<SkeletonLoaderProps>`
  ${(props) => (props.absolute ? 'absolute' : '')}
`

const SkeletonLoader = tw((props: any & ContainerProps) => {
  return <Skeleton {...props} containerClassName={props.className} />
})`
  w-full

  ${(props) => (props.absolute ? 'absolute' : '')}
`

export default SkeletonLoader
