import { useEffect, useState } from 'react'

import { Datum, RoundedCardProps } from '../../../types'
import Card from '../../Card'
import Column from '../Column'
import { TableBodyProps, TableBodyV2 } from './Body'
import { SortOrder, TableCellProps } from './Cell'
import { TableFooter, TableFooterProps } from './Footer'
import { TableHeaderProps, TableHeaderV2 } from './Header'
import { TableTitle, TableTitleProps } from './Title'

export type TableParams = {
  page?: number
  sortBy?: string
  sortOrder?: SortOrder
  perPage?: number
  loading?: boolean
}

export type TableProps<T> = TableBodyProps<T> &
  TableFooterProps &
  TableHeaderProps<T> &
  Pick<
    TableTitleProps,
    | 'dropdownOnClick'
    | 'dropdownOptions'
    | 'linkText'
    | 'linkOnClick'
    | 'filterComponent'
    | 'downloadComponent'
  > &
  TableCellProps<T> & {
    onChange?: (params: TableParams) => unknown
  } & RoundedCardProps & {
    title?: string
    noPagination?: boolean
    height?: string
    className?: string
  }

export const TableV2 = <T extends Record<string, Datum>>(
  props: TableProps<T>,
) => {
  const [params, setParams] = useState({
    page: props.page,
    sortBy: props.sortBy,
    sortOrder: props.sortOrder,
    perPage: props.perPage,
  })

  useEffect(() => {
    setParams({
      page: props.page,
      sortBy: props.sortBy,
      sortOrder: props.sortOrder,
      perPage: props.perPage,
    })
  }, [props])

  useEffect(() => {
    const onChange = async () => {
      props.onChange && (await props.onChange(params))
    }

    onChange()
  }, [params])

  return (
    <Column wGrow className="items-stretch !h-[calc(100%-2rem)] bg-gray-50">
      <Card
        roundingStyle={props.roundingStyle}
        className={`grow ${props.height ? props.height : `overflow-auto`} ${
          props.className ? props.className : ``
        } border-none rounded-none !pb-0`}
      >
        {props.title && (
          <TableTitle
            title={props.title}
            loading={props.loading}
            dropdownOptions={props.dropdownOptions}
            dropdownOnClick={props.dropdownOnClick}
            linkText={props.linkText}
            linkOnClick={props.linkOnClick}
            filterComponent={props.filterComponent}
            downloadComponent={props.downloadComponent}
          />
        )}
        <TableHeaderV2
          {...{
            sortBy: params.sortBy,
            sortOrder: params.sortOrder,
            onSort: (sortBy, sortDirection) => {
              props.onSort && props.onSort(sortBy, sortDirection)
              setParams({
                ...params,
                sortBy,
                sortOrder: sortDirection,
              })
            },
            headers: props.headers,
          }}
        />
        <TableBodyV2
          {...{
            data: props.data,
            headers: props.headers,
            loading: props.loading,
            onClick: props.onClick,
            rowSize: props.rowSize,
            cellSize: props.cellSize,
          }}
        />
        {props.noPagination ? null : (
          <TableFooter
            {...{
              className: '!py-2 !pb-0',
              page: params.page,
              perPage: params.perPage,
              onPage: (page: number) => {
                props.onPage && props.onPage(page)
                setParams({
                  ...params,
                  page,
                })
              },
              totalPages: props.totalPages,
            }}
          />
        )}
      </Card>
    </Column>
  )
}

export default TableV2
