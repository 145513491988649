import { Box } from '@chakra-ui/react'
import { DashboardBanner } from 'ui'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { ApprovalStatus } from '../../operations-types'
import OnboardingDashboardReminder from '../onboarding/OnboardingDashboardReminder'
import ScrollableColumn from '../utils/ScrollableColumn'

type PageLayoutProps = {
  children: React.ReactNode
}

export function BaseLayout({ children }: PageLayoutProps) {
  const { organization } = useDashboardOutletContext()

  const onboardingInfo = JSON.parse(
    organization?.accountInfo?.onboardingInfo || '{}',
  )

  const approvalStatus = organization?.accountInfo?.approvalStatus

  return (
    <>
      {!onboardingInfo.applicationSubmitted ? (
        <OnboardingDashboardReminder />
      ) : approvalStatus !== ApprovalStatus.Approved ? (
        <DashboardBanner
          status="info"
          text="Your account is pending approval. Once approved, this banner will disappear and you can process payments!"
        />
      ) : null}

      {children}
    </>
  )
}

export function PageLayout({ children }: PageLayoutProps) {
  return (
    <BaseLayout>
      <ScrollableColumn
        grow
        className="max-h-screen bg-gray-100"
        overflowScrollable
      >
        <Box w="100%" className="min-h-screen" padding={'14px'}>
          {children}
        </Box>
      </ScrollableColumn>
    </BaseLayout>
  )
}
