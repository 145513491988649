import { ButtonVariants, StatusValue } from '../../v2/types'
import ContainerProps, { Size } from '../../../types'
import { property, styles, tw } from '@nickeltech/brise'
import { LabelButton, TextSize, TextWeight } from '../Typography'
import classNames from 'classnames'
import { Row, Column } from 'ui'
import Icon, { IconName, IconProps } from '../Icon'
import { ButtonHTMLAttributes } from 'react'
import { variantClassNames, hoverClassNames } from './ButtonStyling'

export type IconPosition = 'left' | 'right'
export type IconSize = Extract<Size, 'xs' | 'small' | 'base' | 'large'>

export type ButtonSize = Extract<Size, 'xs' | 'small' | 'base' | 'large'>
export type ButtonVariant = Extract<ButtonVariants, 'solid' | 'ghost' | 'soft'>
export type ButtonStatus = Extract<
  StatusValue,
  'primary' | 'secondary' | 'accent' | 'info' | 'success' | 'warning' | 'error'
>

export const IconSizeArray: Array<IconSize> = ['xs', 'small', 'base', 'large']

export const ButtonStatusArray: Array<ButtonStatus> = [
  'primary',
  'secondary',
  'accent',
  'info',
  'success',
  'warning',
  'error',
]
export const ButtonSizeArray: Array<ButtonSize> = [
  'xs',
  'small',
  'base',
  'large',
]
export const ButtonVariantArray: Array<ButtonVariant> = [
  'solid',
  'ghost',
  'soft',
]

export const ButtonIconPosition: Array<IconPosition> = ['left', 'right']

export type BaseButtonProps = {
  size?: ButtonSize
  variant?: ButtonVariant
  status?: ButtonStatus
  iconPosition?: IconPosition
  iconName?: IconName
  label?: string
  outline?: boolean
  disabled?: boolean
  isLoading?: boolean
  noHover?: boolean
  iconSize?: IconSize
  fullWidth?: boolean
} & ContainerProps &
  ButtonHTMLAttributes<HTMLButtonElement>

export const ButtonSizing = styles<BaseButtonProps>`
  ${(props) => {
    return props.label !== ''
      ? props.size === 'xs'
        ? 'h-[24px] text-xs px-2'
        : props.size === 'small'
        ? 'h-[30px] text-sm px-3'
        : props.size === 'base'
        ? 'h-[40px] text-sm px-3'
        : props.size === 'large'
        ? 'h-[48px] text-base px-3'
        : ''
      : props.size === 'xs'
      ? 'h-[24px] px-[4px] text-xs'
      : props.size === 'small'
      ? 'h-[30px] px-[6px] text-sm'
      : props.size === 'base'
      ? 'h-[40px] px-[10px] text-sm'
      : props.size === 'large'
      ? 'h-[48px] px-[13px] text-base'
      : ''
  }}
`
export const ButtonFullWidth = styles<BaseButtonProps>`
  ${(props) => (props.fullWidth ? 'w-full' : '')}
`

export const ButtonDisabled = property<BaseButtonProps>(
  'disabled',
  {
    true: (props) =>
      classNames('pointer-events-none', {
        'border bg-gray-200 text-gray-500': props.outline,
        'border border-gray-200 bg-gray-200 text-gray-500': !props.outline,
      }),
    false: '',
  },
  'false',
)

export const ButtonIsLoading = property<BaseButtonProps>(
  'isLoading',
  {
    true: (props) =>
      classNames('pointer-events-none', {
        'border bg-gray-200 text-gray-500': props.outline,
        'border border-gray-200 bg-gray-200 text-gray-500': !props.outline,
      }),
    false: '',
  },
  'false',
)

export const ButtonOutline = property<BaseButtonProps>(
  'outline',
  {
    true: (props) =>
      !props.disabled && !props.isLoading && props.noHover
        ? classNames({
            'border border-dark-600 bg-white text-dark-600':
              props.status === 'primary',
            'border border-gray-500 bg-white text-gray-500':
              props.status === 'secondary',
            'border border-purple-600 bg-white text-purple-600':
              props.status === 'accent',
            'border border-blue-600 bg-white text-blue-600':
              props.status === 'info',
            'border border-green-600 bg-white text-green-600':
              props.status === 'success',
            'border border-yellow-600 bg-white text-yellow-600':
              props.status === 'warning',
            'border border-red-600 bg-white text-red-600':
              props.status === 'error',
          })
        : !props.disabled && !props.isLoading
        ? classNames({
            'border border-dark-600 bg-white text-dark-600 hover:bg-dark-600 hover:text-white':
              props.status === 'primary',
            'border border-gray-500 bg-white text-gray-500 hover:bg-gray-500 hover:text-white':
              props.status === 'secondary',
            'border border-purple-600 bg-white text-purple-600 hover:bg-purple-600 hover:text-white':
              props.status === 'accent',
            'border border-blue-600 bg-white text-blue-600 hover:bg-blue-600 hover:text-white':
              props.status === 'info',
            'border border-green-600 bg-white text-green-600 hover:bg-green-600 hover:text-white':
              props.status === 'success',
            'border border-yellow-600 bg-white text-yellow-600 hover:bg-yellow-600 hover:text-white':
              props.status === 'warning',
            'border border-red-600 bg-white text-red-600 hover:bg-red-600 hover:text-white':
              props.status === 'error',
          })
        : '',
    false: '',
  },
  'false',
)

export const ButtonVariant = styles<BaseButtonProps>`
  ${(props) => {
    return !props.outline &&
      !props.disabled &&
      !props.isLoading &&
      props.noHover &&
      props.variant &&
      props.status
      ? `${variantClassNames[props.variant][props.status]}`
      : !props.outline &&
        !props.disabled &&
        !props.isLoading &&
        props.variant &&
        props.status
      ? `${variantClassNames[props.variant][props.status]} ${
          hoverClassNames[props.variant][props.status]
        }`
      : ''
  }}
`

export const BaseButton = tw.button<BaseButtonProps>`
  ${TextSize}
  ${TextWeight}
  ${ButtonIsLoading}
  ${ButtonVariant}
  ${ButtonOutline}
  ${ButtonDisabled}
  ${ButtonSizing}
  ${ButtonFullWidth}
s
  rounded
`

export const ButtonIconFill = styles<
  IconProps & Pick<BaseButtonProps, 'variant' | 'status'>
>`
  ${(props) => {
    return props.disabled
      ? 'stroke-gray-500'
      : props.variant === 'solid'
      ? props.additionalProps && props.additionalProps.outline
        ? classNames({
            'stroke-dark-600': props.status === 'primary',
            'stroke-gray-500': props.status === 'secondary',
            'stroke-purple-600': props.status === 'accent',
            'stroke-blue-600': props.status === 'info',
            'stroke-green-600': props.status === 'success',
            'stroke-yellow-600': props.status === 'warning',
            'stroke-red-600': props.status === 'error',
          })
        : classNames({
            'stroke-gray-500': props.status === 'secondary',
          })
      : classNames({
          'stroke-dark-600': props.status === 'primary',
          'stroke-gray-500': props.status === 'secondary',
          'stroke-purple-600': props.status === 'accent',
          'stroke-blue-600': props.status === 'info',
          'stroke-green-600': props.status === 'success',
          'stroke-yellow-600': props.status === 'warning',
          'stroke-red-600': props.status === 'error',
        })
  }}
`

export const ButtonIconSize = styles<
  IconProps & Pick<BaseButtonProps, 'status' | 'label'>
>`
  ${(props) => {
    return props.size === 'xs'
      ? 'w-[14px] h-[14px]'
      : props.size === 'small'
      ? '!w-[16px] !h-[16px]'
      : props.size === 'base'
      ? 'w-[18px] h-[18px]'
      : props.size === 'large'
      ? 'w-[20px] h-[20px]'
      : ''
  }}
`

export const ButtonIcon = tw<IconProps & Pick<BaseButtonProps, 'variant'>>(
  Icon,
)`
    ${ButtonIconFill}
    ${ButtonIconSize}
`

export const Button = tw((props: BaseButtonProps) => {
  const ButtonBaseLabel = (
    <LabelButton
      size={props.size}
      variant={props.disabled ? 'secondary' : props.variant}
      outline={props.outline}
      disabled={props.disabled}
    >
      {!props.isLoading ? props.label : 'Loading'}
    </LabelButton>
  )

  const ButtonIconBase = !props.isLoading ? (
    <ButtonIcon
      name={props.iconName || 'chevronDown'}
      outline
      additionalProps={{
        outline: props.outline,
      }}
      size={props.iconSize || props.size}
      disabled={props.disabled}
      variant={props.variant}
    />
  ) : (
    <ButtonIcon
      className="animate-spin"
      name="arrowPath"
      outline
      additionalProps={{
        outline: props.outline,
      }}
      size={props.iconSize || props.size}
      disabled={props.disabled}
      variant={props.variant}
    />
  )

  const ButtonBase = (
    <>
      {props.fullWidth ? (
        <Column
          className="max-sm:h-fit max-sm:justify-center items-center rounded"
          wGrow
        >
          <Row gap={props.size === 'xs' ? 'xs' : 'small'} x="center" y="center">
            {props.iconPosition === 'left' &&
            props.iconName &&
            props.iconName?.length > 0
              ? ButtonIconBase
              : ''}

            {ButtonBaseLabel}

            {props.iconPosition === 'right' &&
            props.iconName &&
            props.iconName?.length > 0
              ? ButtonIconBase
              : ''}
          </Row>
        </Column>
      ) : (
        <>
          {props.iconPosition === 'left' &&
          props.iconName &&
          props.iconName?.length > 0
            ? ButtonIconBase
            : ''}

          {ButtonBaseLabel}

          {props.iconPosition === 'right' &&
          props.iconName &&
          props.iconName?.length > 0
            ? ButtonIconBase
            : ''}
        </>
      )}
    </>
  )

  return (
    <BaseButton
      {...{
        className: props.className,
        ...props,
      }}
    >
      {props.label !== '' ? (
        <Row
          y="center"
          x="center"
          between
          gap={props.size === 'xs' ? 'xs' : 'small'}
          grow
          hGrow
        >
          {ButtonBase}
        </Row>
      ) : (
        <Row y="center" x="center" between grow hGrow>
          {ButtonBase}
        </Row>
      )}
    </BaseButton>
  )
})`
`
