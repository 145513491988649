import { Menu } from '@headlessui/react'
import {
  FilterCustomerComponent,
  FilterTransactionIdComponent,
} from '../FilterDropdown/FilterComponent'
import FilterDateComponent from '../FilterDropdown/FilterDateComponent'
import FilterMenu from '../FilterDropdown/FilterMenu'
import { FilterItem } from '../PaymentLinks/PaymentLinksFilterComponent'

export type PaymentsFilterDropdownComponentProps = {
  label: string
  onClick?: (value: string) => void
}

export function PaymentsFilterDropdownComponent({
  label,
  onClick,
}: PaymentsFilterDropdownComponentProps) {
  const items: FilterItem[] = [
    {
      label: 'Id',
      value: 'id',
      iconName: 'magnifyingGlass',
      component: <FilterTransactionIdComponent />,
    },
    {
      label: 'Customer',
      value: 'customer',
      iconName: 'userCircle',
      component: <FilterCustomerComponent />,
    },
    {
      label: 'Date',
      value: 'date',
      iconName: 'calendar',
      component: <FilterDateComponent />,
    },
  ]

  return (
    <Menu.Items className="absolute left-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 focus:outline-none">
      <FilterMenu items={items} selected={0} onClick={onClick} />
    </Menu.Items>
  )
}

export default PaymentsFilterDropdownComponent
