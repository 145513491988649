import { property } from '@nickeltech/brise'
import ContainerProps from '../../../types'

export type SpacingDimension = 'spacingX' | 'spacingY'

export type SpacingSize =
  | 'xxs'
  | 'small'
  | 'base'
  | 'basePlus'
  | 'medium'
  | 'large'
  | 'none'

export const SpacingSizeArray: Array<SpacingSize> = [
  'xxs',
  'small',
  'base',
  'basePlus',
  'medium',
  'large',
  'none',
]

export type GapProps = {
  gap?: SpacingSize
} & ContainerProps

export const GapMap: Record<SpacingSize, string> = {
  none: 'gap-0',
  xxs: 'gap-1',
  small: 'gap-2',
  base: 'gap-4',
  basePlus: 'gap-6',
  medium: 'gap-8',
  large: 'gap-16',
}

export const SpacingXMap: Record<SpacingSize, string> = {
  none: 'px-0',
  xxs: 'px-1',
  small: 'px-2',
  base: 'px-4',
  basePlus: 'px-6',
  medium: 'px-8',
  large: 'px-16',
}

export const SpacingYMap: Record<SpacingSize, string> = {
  none: 'py-0',
  xxs: 'py-1',
  small: 'py-2',
  base: 'py-4',
  basePlus: 'py-6',
  medium: 'py-8',
  large: 'py-16',
}

export const SpacingX = property('spacingX', SpacingXMap, 'none')
export const SpacingY = property('spacingY', SpacingYMap, 'none')
export const Gap = property('gap', GapMap, 'none')

export type SpacingProps = {
  spacingX?: SpacingSize
  spacingY?: SpacingSize
}

export type ButtonHeightProps = {
  buttonHeight?: SpacingSize
}

export default {
  Gap,
  SpacingX,
  SpacingY,
  SpacingSizeArray,
}
