import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  Icon,
  useClipboard,
  InputGroupProps,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'

import { FiCopy } from 'react-icons/fi'
export type CopyInputProps = {
  clipboardValue: string
  formLabel?: string
} & InputGroupProps

export function CopyInput({
  clipboardValue,
  formLabel,
  ...other
}: CopyInputProps) {
  const { onCopy, value, hasCopied } = useClipboard(clipboardValue)

  return (
    <HStack w="100%">
      <FormControl>
        {formLabel && <FormLabel>{formLabel}</FormLabel>}
        <InputGroup {...other} borderRadius="4px">
          <Input
            value={value}
            isReadOnly
            color="gray.600"
            cursor="pointer"
            bgColor="gray.100"
            onClick={onCopy}
            _hover={{}}
            _focus={{}}
          />
          <InputRightAddon
            ps="4"
            color="gray.500"
            bgColor="gray.100"
            fontSize="10px"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onCopy()
            }}
            _hover={{
              cursor: 'pointer',
              bgColor: 'gray.200',
            }}
          >
            <Box pe="1">
              <Icon as={FiCopy} boxSize="4" me="1" color="gray.500" />
            </Box>
            <Text color="gray.500" fontSize="13px">
              {hasCopied ? 'Copied!' : 'COPY'}
            </Text>
          </InputRightAddon>
        </InputGroup>
      </FormControl>
    </HStack>
  )
}
