import { tw } from '@nickeltech/brise'
import { Column } from 'ui'
import ContainerProps from 'ui/src/types'

const FormSectionHeader = tw.div<ContainerProps>`
  text-sm
  font-medium
  text-gray-800
`

export const FormSection = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => {
  return (
    <Column gap="small" wGrow>
      <FormSectionHeader>{title}</FormSectionHeader>
      {children}
    </Column>
  )
}
