import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'

type InvoiceFieldProps = InputProps & {
  label?: string
  error?: string
  helperText?: string
  leftElementText?: string
  rightElement?: React.ReactNode
}

export function InputField({
  label,
  error,
  helperText,
  ...props
}: InvoiceFieldProps) {
  const { leftElementText, fontWeight, rightElement, ...rest } = props

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel fontSize={props.fontSize || 'md'} fontWeight={fontWeight}>
          {label}
        </FormLabel>
      )}
      <InputGroup size={props.size || 'md'}>
        {leftElementText && (
          <InputLeftElement pointerEvents="none" color="gray.400">
            {leftElementText}
          </InputLeftElement>
        )}
        <Input
          fontSize={props.fontSize || 'md'}
          {...rest}
          color={error ? 'red.600' : ''}
          _invalid={{
            boxShadow: 'none',
            borderColor: 'red.600',
          }}
        />
        {rightElement && (
          <InputRightElement width="5rem">{rightElement}</InputRightElement>
        )}
      </InputGroup>
      {error ? <FormErrorMessage fontSize="xs">{error}</FormErrorMessage> : ''}
      {helperText && !error ? (
        <FormHelperText fontSize="xs">{helperText}</FormHelperText>
      ) : (
        ''
      )}
    </FormControl>
  )
}
