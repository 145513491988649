import Layout, { BlankLayout } from '../layout/Layout'
import jwt_decode from 'jwt-decode'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useAuth } from '../../lib/auth'
import LoginInfo from './RegisterLoginInfo'
import BusinessInfo from './RegisterBusinessInfo'
import Loading from '../utils/Loading'
import { validateBusiness, validateLogin } from './utils'
import CodatLink from './CodatLink'
import {
  ExistingUserFromEmailDocument,
  SignupDocument,
} from '../../operations-types'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import {
  OnboardingRegistration,
  OnboardingRegistrationSuccess,
} from '../onboardingV2/Onboarding'
import { useToast } from '@chakra-ui/react'

function Expiry() {
  return (
    <Layout>
      <div className="w-full max-w-2xl mt-20">
        <div className="font-medium text-4xl leading-10 tracking-wide">
          Unfortunately, that link is expired. Please request a new signup link.
        </div>
      </div>
    </Layout>
  )
}

type RegistrationProps = {
  vendor?: boolean
}

export default function Registration(props: RegistrationProps) {
  const twoFactorFlag = useFeatureFlagEnabled('two-factor-authentication')
  const { token = '' } = useParams()
  const { email, expiry }: { email: string; expiry: number } = jwt_decode(token)
  const { setAuth } = useAuth()

  const expired = Date.now() > expiry

  const [password, setPassword] = useState('')
  const [firstName, setFirst] = useState('')
  const [lastName, setLast] = useState('')
  const [manualEmail, setManualEmail] = useState('')
  const [loginErrors, setLoginErrors] = useState({
    password: '',
    firstName: '',
    lastName: '',
    existingUser: '',
  })

  const [name, setName] = useState('')
  const [paymentUrl, setPaymentUrl] = useState('')
  const [image, setImage] = useState('')
  const [banner, setBanner] = useState('')
  const [businessErrors, setBusinessErrors] = useState({
    name: '',
    paymentUrl: '',
  })

  const [linkRedirectUrl, setLinkRedirect] = useState('')
  const [currentStep, setStep] = useState('login')

  const [signup, { loading }] = useMutation(SignupDocument, {
    onCompleted: (data) => {
      const signupData = data.signup

      if (!signupData) {
        console.error(`Signup mutation returned no data. ${data}}`)
        return
      }

      const { token, error } = signupData

      if (error) {
        setBusinessErrors({
          ...businessErrors,
          paymentUrl: error.message || '',
        })
      } else {
        setAuth(token)
        setLinkRedirect('')
        twoFactorFlag ? setStep('onboard') : setStep('onboard')
      }
    },
  })

  const toast = useToast()

  const { data, refetch } = useQuery(ExistingUserFromEmailDocument, {
    variables: { email: manualEmail || email },
  })

  const onLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.location.replace(linkRedirectUrl)
  }

  const onContinue = () => {
    refetch()
    const { errors, hasErrors } = validateLogin(
      password,
      firstName,
      lastName,
      data?.existingUserFromEmail?.existingUser === true,
      manualEmail || email,
    )

    setLoginErrors({
      ...loginErrors,
      ...errors,
    })

    if (errors.existingUser) {
      toast({
        title: 'Error',
        description: `${errors.existingUser}`,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }

    if (errors.email) {
      toast({
        title: 'Error',
        description: `${errors.email}`,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }

    if (!hasErrors) {
      setStep('account')
    }
  }

  const onRegister = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const { errors, hasErrors } = validateBusiness(name, paymentUrl)

    setBusinessErrors({
      ...businessErrors,
      ...errors,
    })

    if (!hasErrors) {
      signup({
        variables: {
          token: token,
          firstName: firstName,
          lastName: lastName,
          name: name,
          password: password,
          paymentUrl: paymentUrl + '.nickelpayments.com',
          companyLogoImage: image,
          companyBannerImage: banner,
          email: manualEmail || email,
        },
      })
    }
  }

  const states: { [state: string]: JSX.Element } = {
    login: (
      <LoginInfo
        email={email}
        onPasswordChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
        onContinue={onContinue}
        onFirst={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFirst(e.target.value)
        }
        onLast={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLast(e.target.value)
        }
        firstName={firstName}
        lastName={lastName}
        password={password}
        loginErrors={loginErrors}
        setManualEmail={setManualEmail}
        vendor={props.vendor}
      />
    ),
    account: (
      <BusinessInfo
        onBack={() => setStep('login')}
        onRegister={onRegister}
        onName={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
        setPaymentUrl={setPaymentUrl}
        paymentUrl={paymentUrl}
        businessErrors={businessErrors}
        name={name}
        image={image}
        setImage={setImage}
        banner={banner}
        setBanner={setBanner}
      />
    ),
    onboard: <OnboardingRegistration setStep={setStep} />,
    success: <OnboardingRegistrationSuccess />,
    link: <CodatLink onLinkClick={onLinkClick}></CodatLink>,
  }

  if (expired) {
    return <Expiry />
  }

  return (
    <BlankLayout>
      <div className="w-full max-w-2xl mt-20">
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            {loading && <Loading additionalClasses={{ 'h-screen': true }} />}
            {!loading && states[currentStep]}
          </div>
        </form>
      </div>
    </BlankLayout>
  )
}
