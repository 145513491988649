import { Flex, Box, Spacer, Center, Text, Icon } from '@chakra-ui/react'
import { Column, Form, RemoveKebabMenu } from 'ui'
import * as yup from 'yup'
import { Upload } from 'antd/lib/index'
import { useState } from 'react'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline'
import { QuestionFormSchema } from '../../../operations-types'
import dynamic from 'next/dynamic'

export type TermsValidationPayload = {
  steps: {
    terms_of_service: {
      url?: string
      requireAcceptance?: boolean
      fileBuffer?: string
      step?: number
    }
  }
  valid: {
    terms_of_service: boolean
  }
}

type TermsOfServiceProps = {
  validationPayload?: TermsValidationPayload
  setValidationPayload?: (e: TermsValidationPayload) => void
  currentSchema?: QuestionFormSchema
  setCurrentSchema?: (e: QuestionFormSchema) => void
  setSuccessfulUpload: (e: boolean) => void
}

type SectionTermProps = {
  url?: string
  fileBuffer?: string
  requireAcceptance?: boolean
} & TermsOfServiceProps

const { Dragger } = Upload

type UploadedFile = {
  data: string | ArrayBuffer | null
  name: string
}

type FileUploadDraggerProps = {
  onFile: (e: UploadedFile) => void
  accept?: string
}

const FileUploadDragger = (props: FileUploadDraggerProps) => {
  return (
    <Box w="100%" bgColor="white">
      <Dragger
        {...props}
        customRequest={() => {}}
        beforeUpload={async (file) =>
          await new Promise(() => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              props.onFile({
                data: reader.result,
                name: file.name,
              })
            }
          })
        }
      >
        <Box p="4">
          <Icon as={DocumentArrowUpIcon} boxSize="8" />

          <Text color="purple.600" fontSize="lg" fontWeight="medium">
            Upload Document
          </Text>
          <p className="ant-upload-hint">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">PDF up to 10MB</p>
        </Box>
      </Dragger>
    </Box>
  )
}

const TermsOfService = (props: SectionTermProps) => {
  const tosData = props?.validationPayload?.steps?.terms_of_service

  const [filename, setFilename] = useState(tosData?.fileBuffer || '')

  const PDFViewer = dynamic(() => import('../../../lib/PDFViewer'), {
    ssr: false,
  })

  const schema = yup.object().shape(
    {
      url: yup
        .string()
        .url()
        .when('fileBuffer', {
          is: (e: string) => e === '',
          then: yup
            .string()
            .matches(
              /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
              'Must be a valid URL',
            )
            .required(),
          otherwise: yup.string().url(),
        }),
      requireAcceptance: yup.boolean(),
      fileBuffer: yup.string().when('url', {
        is: (e: string) => e === '',
        then: yup.string().required('Must upload a file'),
        otherwise: yup.string(),
      }),
    },
    [['fileBuffer', 'url']],
  )

  return (
    <Flex flexDirection={'column'} py="8" gap="4" w="100%">
      <Column gap="medium" wGrow y="center">
        <Form
          {...{
            validationSchema: schema,
            initialValues: {
              url: tosData?.url,
              requireAcceptance: true,
              fileBuffer: tosData?.fileBuffer,
            },
            onChange: async (values) => {
              const urlRegex = new RegExp(
                /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi,
              )

              if (props.setValidationPayload) {
                props.setValidationPayload({
                  ...props.currentSchema,
                  steps: {
                    ...(props.currentSchema?.steps?.additional_information && {
                      additional_information: {
                        questions:
                          props.currentSchema?.steps?.additional_information
                            ?.questions,
                        step: 0,
                      },
                    }),
                    ...(values &&
                      values.fileBuffer !== '' && {
                        terms_of_service: {
                          ...values,
                          step: 1,
                        },
                      }),
                  },
                  valid: {
                    ...props?.validationPayload?.valid,
                    terms_of_service:
                      (values.url !== undefined &&
                        values.url !== '' &&
                        values.url.match(urlRegex)) ||
                      (values.fileBuffer !== '' &&
                        values.fileBuffer !== undefined),
                  },
                })
              }
            },
          }}
          className="w-full"
        >
          {(formik) => {
            return (
              <Column wGrow>
                {filename || formik.values.fileBuffer ? (
                  <Flex w="100%" flexDirection="row">
                    <Center w="100%">
                      <Icon
                        as={DocumentTextIcon}
                        boxSize="5"
                        color="gray.500"
                      />

                      <Text color="gray.800" ps="2">
                        {filename || 'Current File'}
                      </Text>
                      <Spacer />
                      <Box
                        alignContent="right"
                        justifyContent="right"
                        alignItems="right"
                        justifyItems="right"
                      >
                        <RemoveKebabMenu
                          {...{
                            offset: [-70, -30],
                            onClick: async () => {
                              await formik.setFieldValue('fileBuffer', '')
                              setFilename('')
                            },
                          }}
                        />
                      </Box>
                    </Center>
                  </Flex>
                ) : (
                  <FileUploadDragger
                    {...{
                      onFile: async (e) => {
                        await formik.setFieldValue('fileBuffer', e.data)
                        setFilename(e.name)
                      },
                      accept: '.pdf',
                    }}
                  />
                )}
              </Column>
            )
          }}
        </Form>
        {tosData?.fileBuffer && (
          <Center w="100%">
            <PDFViewer
              file={tosData?.fileBuffer || ''}
              width={500}
              maxHeight="500px"
              setSuccessfulUpload={props.setSuccessfulUpload}
            />
          </Center>
        )}
      </Column>
    </Flex>
  )
}

export default TermsOfService
