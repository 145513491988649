import {
  Box,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  HStack,
  Icon as ChakraIcon,
  VStack,
} from '@chakra-ui/react'

import { IconMap } from '../Icons'
import type { IconName } from '../Icons'
import { ReactNode } from 'react'
import ContainerProps from '../../../types'

export type ButtonStatus =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'

export const ButtonStatusArray: Array<ButtonStatus> = [
  'primary',
  'secondary',
  'accent',
  'info',
  'success',
  'warning',
  'error',
]

const statusColors = {
  primary: 'dark',
  secondary: 'buttonGray',
  accent: 'accent',
  info: 'blue',
  success: 'green',
  warning: 'yellow',
  error: 'red',
}

export type BaseButtonProps = ChakraButtonProps & {
  status?: ButtonStatus
  iconPosition?: 'left' | 'right'
  iconName?: IconName
  iconBoxSize?: string
  iconElement?: any
  label?: string | ReactNode
} & ContainerProps

export const Button = (props: BaseButtonProps) => {
  const {
    colorScheme,
    status,
    iconName,
    iconPosition,
    iconElement,
    iconBoxSize,
    label,
    ...other
  } = props

  return (
    <>
      {status ? (
        <ChakraButton colorScheme={statusColors[status]} {...other}>
          <VStack justifyContent="center" alignItems="center">
            <HStack gap={2} justifyContent="center" alignItems="center">
              {props.iconPosition === 'left' && props.iconName ? (
                <ChakraIcon
                  boxSize={iconBoxSize}
                  as={IconMap[props.iconName]}
                />
              ) : (
                ''
              )}
              <Box>{(props as any).label}</Box>
              {props.iconPosition === 'right' && props.iconName ? (
                <ChakraIcon
                  boxSize={iconBoxSize}
                  as={IconMap[props.iconName]}
                />
              ) : (
                ''
              )}
            </HStack>
          </VStack>
        </ChakraButton>
      ) : (
        <ChakraButton colorScheme="dark" {...other}>
          <VStack justifyContent="center" alignItems="center">
            <HStack gap={2} justifyContent="center" alignItems="center">
              {props.iconPosition === 'left' && props.iconName ? (
                <ChakraIcon
                  boxSize={iconBoxSize}
                  as={IconMap[props.iconName]}
                />
              ) : (
                ''
              )}
              <Box>{(props as any).label}</Box>
              {props.iconPosition === 'right' && props.iconName ? (
                <ChakraIcon
                  boxSize={iconBoxSize}
                  as={IconMap[props.iconName]}
                />
              ) : (
                ''
              )}
            </HStack>
          </VStack>
        </ChakraButton>
      )}
    </>
  )
}

export default Button
