import ContainerProps from '../../types'

const Lock = (props: ContainerProps) => {
  return (
    <svg
      width="43"
      height="44"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.723114" width="36" height="36" rx="4" fill="#E7E5E4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 17.7231V15.7231C13 14.397 13.5268 13.1253 14.4645 12.1876C15.4021 11.2499 16.6739 10.7231 18 10.7231C19.3261 10.7231 20.5979 11.2499 21.5355 12.1876C22.4732 13.1253 23 14.397 23 15.7231V17.7231C23.5304 17.7231 24.0391 17.9338 24.4142 18.3089C24.7893 18.684 25 19.1927 25 19.7231V24.7231C25 25.2535 24.7893 25.7623 24.4142 26.1373C24.0391 26.5124 23.5304 26.7231 23 26.7231H13C12.4696 26.7231 11.9609 26.5124 11.5858 26.1373C11.2107 25.7623 11 25.2535 11 24.7231V19.7231C11 19.1927 11.2107 18.684 11.5858 18.3089C11.9609 17.9338 12.4696 17.7231 13 17.7231V17.7231ZM21 15.7231V17.7231H15V15.7231C15 14.9275 15.3161 14.1644 15.8787 13.6018C16.4413 13.0392 17.2044 12.7231 18 12.7231C18.7956 12.7231 19.5587 13.0392 20.1213 13.6018C20.6839 14.1644 21 14.9275 21 15.7231V15.7231Z"
        fill="#A8A29E"
      />
    </svg>
  )
}

export default Lock
