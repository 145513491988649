import { Separator, UnstandardizedRow, FormButton } from 'ui'

export const RefundButton = ({ loading }: { loading: boolean }) => {
  return (
    <div>
      <Separator orientation="horizontal" />
      <UnstandardizedRow className="px-8 py-4" x="right">
        <FormButton
          x="right"
          status="primary"
          variant="solid"
          label="Send Refund"
          loading={loading}
        />
      </UnstandardizedRow>
    </div>
  )
}
