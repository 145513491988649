import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetAllMerchantPaymentMethodsDocument } from '../../../../operations-types'
import { DashboardChoosePaymentMethod } from '../../../ap/components/DashboardChoosePaymentMethod'
import { NickelPlansLayout } from './NickelPlansLayout'
import NickelPlansReview from './NickelPlansReview'
import { useNickelPlansStore } from './NickelPlansStore'
import { NickelPricing } from './NickelPricing'
import { NickelPlansPage } from './types'

export function NickelPlans() {
  const navigate = useNavigate()

  const {
    savedPaymentMethods,
    sendMoneyAmountResult,
    selectedSavedPaymentMethod,
    paymentSummary,
    setPaymentMethods,
    setSelectedSavedPaymentMethod,
    setPaymentSummary,
    setPage,
    getPage,
    reset,
  } = useNickelPlansStore((state) => ({
    sendMoneyAmountResult: state.amountResult!,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    savedPaymentMethods: state.savedPaymentMethods,
    paymentSummary: state.paymentSummary,
    setPaymentMethods: state.setPaymentMethods,
    setSelectedSavedPaymentMethod: state.setSelectedSavedPaymentMethod,
    setPaymentSummary: state.setPaymentSummary,
    clearSelectedSavedPaymentMethod: state.clearSelectedSavedPaymentMethod,
    setPage: state.setPage,
    getPage: state.getPage,
    reset: state.reset,
  }))

  const { data: merchantPaymentMethodsData } = useQuery(
    GetAllMerchantPaymentMethodsDocument,
  )

  useEffect(() => {
    if (
      merchantPaymentMethodsData?.getAllMerchantPaymentMethods?.paymentMethods
    ) {
      setPaymentMethods(
        merchantPaymentMethodsData.getAllMerchantPaymentMethods.paymentMethods.filter(
          (paymentMethod) => !paymentMethod?.achDebit,
        ),
      )
    }
  }, [merchantPaymentMethodsData, setPaymentMethods])

  const onGoBackClick = () => {
    navigate('/dashboard/settings/billing')
    reset()
  }

  const components: Record<NickelPlansPage, React.ReactNode> = {
    plans: (
      <NickelPlansLayout
        title="Nickel Pricing Plans"
        subtitle="Choose the best plan for your business."
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <NickelPricing
          setPage={setPage}
          setPaymentSummary={setPaymentSummary}
          sendMoneyAmountResult={sendMoneyAmountResult}
        />
      </NickelPlansLayout>
    ),
    paymentMethod: (
      <NickelPlansLayout
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
        breakdown={{
          feeCents: 0,
          amountCents: paymentSummary?.amountWithoutFeeCents() || 0,
        }}
        title="Choose a payment method"
        subtitle="Choose the payment method that you will use to fund this payment.
        "
      >
        <DashboardChoosePaymentMethod
          setPage={() => {
            setPage('review')
          }}
          savedPaymentMethods={savedPaymentMethods}
          setPaymentMethods={setPaymentMethods}
          sendMoneyAmountResult={sendMoneyAmountResult}
          selectedSavedPaymentMethod={selectedSavedPaymentMethod}
          setSelectedSavedPaymentMethod={setSelectedSavedPaymentMethod}
          paymentSummary={paymentSummary}
          setPaymentSummary={setPaymentSummary}
          achEnabled={false}
          creditCardEnabled={true}
          noCardFee={true}
        />
      </NickelPlansLayout>
    ),

    review: (
      <NickelPlansLayout
        title={`Review your payment of ${paymentSummary?.subtotalString()}`}
        subtitle={`Please review the details of your payment.`}
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <NickelPlansReview />
      </NickelPlansLayout>
    ),
    receipt: (
      <NickelPlansLayout
        title="Your payment was sent!"
        subtitle="You will receive a receipt in your email shortly."
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <></>
      </NickelPlansLayout>
    ),
  }
  return <>{components[getPage()]}</>
}
