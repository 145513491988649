import { tw } from '@nickeltech/brise'
import ContainerProps, { Datum } from '../../../types'
import Icon from '../../Icons'
import {
  DisabledTransition,
  DisabledTransitionProps,
  PaginationDisabledTransition,
  PaginationDisabledTransitionProps,
} from '../../Transitions'
import Row from '../Row'
import _ from 'lodash'
import { Box } from '@chakra-ui/react'

export type TableFooterProps = {
  page?: number
  perPage?: number
  totalPages?: number
  onPage?: (page: Datum) => unknown
} & ContainerProps

export const TableFooterItem = tw.div<ContainerProps & DisabledTransitionProps>`
  px-2
  py-1
  flex
  flex-row
  items-center
  justify-center
  cursor-pointer
  ${PaginationDisabledTransition}
`

export const TablePage = tw(TableFooterItem)`
    text-xs
    ${PaginationDisabledTransition}
`
export const TablePageCollection = tw(Row)`
    rounded
    border
`

export const PaginationElement = tw.div<
  ContainerProps & PaginationDisabledTransitionProps
>`
  border
  rounded-full
  p-1
  cursor-pointer
  hover:bg-gray-100
  ${DisabledTransition}
`

export const TableNextPage = tw(PaginationElement)`
`
export const TableBackPage = tw(PaginationElement)``

export const TableFooter = <T extends Record<string, Datum>>(
  props: TableFooterProps,
) => {
  const { page = 1, onPage, totalPages } = props

  const CHUNK_SIZE = 5

  return (
    <Row
      x="right"
      y="center"
      spacing="small"
      gap="small"
      className={props.className}
    >
      <TableBackPage
        {...{
          onClick: page > 1 ? () => onPage && onPage(page - 1) : undefined,
          disabled: page === 1,
        }}
      >
        <Icon name="chevronLeft" size="xsmall" />
      </TableBackPage>
      <TablePageCollection>
        {Array.from(Array(totalPages).keys()).map((index) => {
          const backIndices = _.range(1, page - 1 - (CHUNK_SIZE - 1) / 2)
          const isBackIndex =
            page - 1 - (CHUNK_SIZE - 1) / 2 > 0 &&
            backIndices.indexOf(index) === 0
          const frontIndices = _.range(page + (CHUNK_SIZE - 1) / 2, totalPages)
          const isFrontIndex = frontIndices.indexOf(index) === 0

          const isPaginatedNumber =
            index === 0 ||
            index === Array.from(Array(totalPages)).length - 1 ||
            _.range(
              page - 1 - (CHUNK_SIZE - 1) / 2,
              page + (CHUNK_SIZE - 1) / 2,
            ).includes(index)
          return (
            <Box key={index}>
              {isPaginatedNumber || isBackIndex || isFrontIndex ? (
                <TablePage
                  key={index}
                  {...{
                    onClick:
                      page !== index + 1
                        ? () =>
                            onPage && isBackIndex
                              ? onPage(
                                  (backIndices[backIndices.length - 1] || 0) +
                                    1,
                                )
                              : isFrontIndex
                              ? onPage && onPage((frontIndices[0] || 0) + 1)
                              : isPaginatedNumber && onPage
                              ? onPage(index + 1)
                              : ''
                        : undefined,
                    disabled: isPaginatedNumber && page === index + 1,
                  }}
                >
                  {isPaginatedNumber
                    ? index + 1
                    : isBackIndex || isFrontIndex
                    ? '...'
                    : ''}
                </TablePage>
              ) : (
                ''
              )}
            </Box>
          )
        })}
      </TablePageCollection>
      <TableNextPage
        {...{
          onClick:
            page < (totalPages || 1)
              ? () => onPage && onPage(page + 1)
              : undefined,
          disabled: totalPages === page,
        }}
      >
        <Icon name="chevronRight" size="xsmall" />
      </TableNextPage>
    </Row>
  )
}
