import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useState } from 'react'
import { Button, Header, Row, Table } from 'ui'
import formatter from 'ui/src/formatter'
import { GetPayoutsDocument } from '../../operations-types'
import { Payouts, Payout } from '../../types'

import { useNavigate } from 'react-router-dom'
import PayoutsOverlay from './PayoutsOverlay'
import { PAYOUTS_URL } from '../../lib/urls'
import { reportErrorIfExists } from '../../lib/utils'
import { StatusCell } from '../payments/utils'
import moment from 'moment'
import { PageLayout } from '../layout/PageLayout'

type PayoutPresenter = {
  id: string
  createdAt: number
  paidAt?: string | null
  status: StatusCell
  reference: string
  amountInCents: number
  transactionCount: number
  amount: number
}

const statusMap: Record<string, StatusCell> = {
  Paid: {
    label: 'Paid',
    status: 'success',
  },
  Pending: {
    label: 'Pending',
    status: 'action',
  },
  Failed: {
    label: 'Failed',
    status: 'error',
  },
  Canceled: {
    label: 'Canceled',
    status: 'error',
  },
  '': {
    label: 'Unknown',
    status: 'warning',
  },
}

const SORTBY_TO_SORT_FUNC: {
  [index: string]: (a: PayoutPresenter, b: PayoutPresenter) => number
} = {
  createdAt: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.createdAt - b.createdAt,
  status: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.status.label.localeCompare(b.status.label),
  id: (a: PayoutPresenter, b: PayoutPresenter) => a.id.localeCompare(b.id),
  reference: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.reference.localeCompare(b.reference),
  amount: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.amountInCents - b.amountInCents,
  transactionCount: (a: PayoutPresenter, b: PayoutPresenter) =>
    a.transactionCount - b.transactionCount,
  paidAt: (a: PayoutPresenter, b: PayoutPresenter) =>
    moment(a.paidAt).diff(moment(b.paidAt)),
}

function transformQueryData(
  payout: Payouts,
  sortBy: string,
  sortDirection: string,
) {
  const mappedPayouts: PayoutPresenter[] = [...payout].map((payout: Payout) => {
    return {
      ...payout,
      createdAt: parseInt(payout.createdAt),
      amount: payout.amountInCents / 100,
      transactionCount:
        (payout.charges?.length || 0) +
        (payout.refunds?.length || 0) +
        (payout.fees?.length || 0),
      status: statusMap[_.capitalize(payout.status || '')],
      reference: payout.externalPayoutId || 'N/A',
    }
  })

  mappedPayouts.sort((a, b) => {
    return (
      SORTBY_TO_SORT_FUNC[sortBy](a, b) * (sortDirection === 'desc' ? -1 : 1)
    )
  })

  return mappedPayouts
}

function convertPayoutToCsv(payouts: Payouts) {
  const mappedPayouts = transformQueryData(payouts, 'paidAt', 'desc')
  const csv = [
    [
      'ID',
      'Reference',
      'Created At',
      'Paid At',
      'Amount',
      'Transaction Count',
      'Status',
    ].join(','),
  ]

  mappedPayouts.forEach((payout) => {
    csv.push(
      [
        payout.id,
        payout.reference,
        `"${new Date(payout.createdAt).toLocaleString()}"`,
        payout.paidAt || 'N/A',
        `"${formatter.format(payout.amount)}"`,
        payout.transactionCount,
        payout.status.label,
      ].join(','),
    )
  })

  return csv.join('\n')
}

function downloadDataAsCSV(payments: Payouts) {
  const a = document.createElement('a')
  a.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
    convertPayoutToCsv(payments),
  )}`
  a.target = '_blank'
  a.download = 'payouts.csv'
  a.click()
  a.remove()
}

export default function PayoutsTable() {
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState('paidAt')
  const [sortDirection, setSortDirection] = useState('desc')

  const pageSize = 20

  const queryOpts = {
    page: page,
    pageSize: pageSize,
  }

  const { loading, data, error } = useQuery(GetPayoutsDocument, {
    variables: {
      ...queryOpts,
    },
  })

  reportErrorIfExists(data?.payouts?.error?.message || error)

  const payouts = data?.payouts?.payouts || []

  const totalResults = data?.payouts?.totalResults || 0
  const totalPages = Math.ceil(totalResults / pageSize)
  const tableData = transformQueryData(payouts, sortBy, sortDirection)

  const totalAmountInCents = tableData
    .map((x: any) => x.amountInCents)
    .reduce((a: number, b: number) => a + b, 0)
  const totalString = `Total of ${formatter.format(totalAmountInCents / 100)}`

  const table = (
    <Table
      {...{
        title: totalString,
        loading: loading,
        downloadComponent: (
          <Button
            label="Download"
            size="xs"
            variant="outline"
            status="secondary"
            onClick={() => downloadDataAsCSV(payouts)}
          />
        ),
        headers: [
          {
            type: 'date',
            keyName: 'createdAt',
            label: 'Date',
          },
          {
            type: 'date',
            keyName: 'paidAt',
            label: 'Paid At',
          },
          {
            type: 'description',
            keyName: 'id',
            label: 'ID',
            grow: 2,
          },
          {
            type: 'description',
            keyName: 'reference',
            label: 'Reference',
            grow: 2,
          },
          {
            type: 'status',
            keyName: 'status',
            label: 'Status',
          },
          {
            type: 'currency',
            keyName: 'amount',
            label: 'Net Amount',
            style: {
              width: '200px',
              minWidth: '200px',
              maxWidth: '200px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              paddingRight: '5rem',
            },
          },
          {
            type: 'notification',
            keyName: 'transactionCount',
            label: 'Transactions',
            center: true,
          },
        ],
        data: tableData,
        page: page,
        perPage: pageSize,
        totalPages: totalPages,
        onChange: () => {},
        onSort: (sortBy, sortOrder) => {
          setSortBy(sortBy)
          setSortDirection(sortOrder)
        },
        onClick: (x: any) => navigate(`${PAYOUTS_URL}/${x.id}`),
        onPage: (page: number) => {
          setPage(page)
        },
      }}
    />
  )

  return (
    <PageLayout>
      <Row className="pb-4">
        <Header variant="page">Payouts</Header>
      </Row>
      {table}
      <PayoutsOverlay />
    </PageLayout>
  )
}
