export const formatOrderRef = (val: string, hasClicked: boolean) => {
  if (val && val.length >= 1) {
    return val
  } else if (hasClicked) {
    return '#'
  }
}

export const parseOrderRef = (val: string) => {
  val = val.replace(/,/g, '').replace(/^\#/g, '')
  return val
}

export const websiteRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const STAGING_ORG_IDS = [
  'cm2xf0c7k0001u8ztqitzrwi8',
  'cm1jpz8ca0001t9021wjddo3k',
  'cm1jqheed000bt902ak0aidsv',
]
