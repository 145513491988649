import classNames from 'classnames'
import ContainerProps from '../../types'
import Row from '../Layout/Row'
import { UnstandardizedText } from '../Typography/Text'

export type SidebarSubsectionProps = {
  label: string
  url: string
  selected?: boolean
  onClick?: (e: string) => void
  collapsed?: boolean
} & ContainerProps

export const SidebarSubsection: React.FC<SidebarSubsectionProps> = (props) => {
  return (
    <Row
      onClick={() => props.onClick && props.onClick(props.url)}
      visible={!props.collapsed}
      grow
      {...{ className: props.className }}
    >
      <Row
        className={classNames('mx-1 mb-1 rounded', {
          'bg-dark-700': props.selected,
          'hover:bg-dark-700': !props.selected,
        })}
        grow
      >
        <Row between className="px-6 py-[6px]" y="center" grow>
          <Row between y="center" x="center" visible={!props.collapsed} grow>
            <UnstandardizedText
              visible={!props.collapsed}
              className="text-slate-200 font-medium text-sm"
            >
              {props.label}
            </UnstandardizedText>
          </Row>
        </Row>
      </Row>
    </Row>
  )
}

export default SidebarSubsection
