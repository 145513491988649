import { ReactElement, ReactNode } from 'react'
import Icon from '../Icons'
import Row from '../Layout/Row'
import { Badge, HStack } from '@chakra-ui/react'

export type SidebarHeaderProps = {
  collapsed?: boolean
  setCollapsed?: (collapsed: boolean) => void
  homeButton?: ReactElement | ReactNode
  onHeaderClick?: () => void
  isDemo?: boolean
}

export const SidebarHeader = (props: SidebarHeaderProps) => {
  return (
    <Row between y="center" grow spacing={props.collapsed ? 'small' : 'header'}>
      <div onClick={props.onHeaderClick} className="cursor-pointer">
        <HStack flexDirection="row" w="100%">
          <Icon name="nickelWhite" variant="primary" size="xsmall" hoverable />

          {props.isDemo && (
            <Badge
              border="1px solid"
              color="accent.400"
              borderColor="accent.400"
              bgColor="transparent"
            >
              DEMO
            </Badge>
          )}
        </HStack>
      </div>
    </Row>
  )
}

export default SidebarHeader
