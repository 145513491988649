import { ButtonProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

export const disabledStyles = {
  bgColor: 'buttonGray.200',
  bg: 'buttonGray.200',
  color: 'buttonGray.500',
  opacity: 1,
  cursor: 'not-allowed',
  boxShadow: 'none',
  borderColor: 'buttonGray.200',
  bgImage: 'none',
}

export const textStyle = (props: ButtonProps) => {
  const textColor =
    props.colorScheme === 'buttonGray'
      ? 'gray.500'
      : props.variant === 'solid'
      ? 'white'
      : `${props.colorScheme}.600`

  return mode(textColor, textColor)(props)
}

export const hoverTextStyle = (props: ButtonProps) => {
  const textColor =
    props.colorScheme === 'buttonGray'
      ? 'gray.500'
      : props.variant === 'solid' || props.variant === 'ghost'
      ? `${props.colorScheme}.600`
      : `white`

  return mode(textColor, textColor)(props)
}

export const outlineHoverStyle = (props: ButtonProps) => {
  const textColor = props.colorScheme === 'buttonGray' ? 'gray.500' : 'white'
  return mode(textColor, textColor)(props)
}

export const outlineHoverBgStyle = (props: ButtonProps) => {
  const bgColor =
    props.colorScheme === 'buttonGray' ? 'gray.200' : `${props.colorScheme}.600`
  return mode(bgColor, bgColor)(props)
}

export const softStyle = (props: ButtonProps) => {
  const bgColor =
    props.colorScheme === 'buttonGray' ? 'gray.200' : `${props.colorScheme}.100`
  return mode(bgColor, bgColor)(props)
}

export const ghostHoverBgStyle = (props: ButtonProps) => {
  const bgColor =
    props.colorScheme === 'buttonGray' ? 'gray.200' : `${props.colorScheme}.200`
  return mode(bgColor, bgColor)(props)
}

const ButtonSizes = {
  lg: defineStyle({
    h: '12',
    minW: '12',
    fontSize: 'lg',
    px: '12px',
    borderRadius: '4px',
  }),
  md: defineStyle({
    h: '10',
    minW: '10',
    fontSize: 'md',
    px: '12px',
    borderRadius: '4px',
  }),
  sm: defineStyle({
    h: '8',
    minW: '8',
    fontSize: 'sm',
    px: '12px',
    borderRadius: '4px',
  }),
  xs: defineStyle({
    h: '6',
    minW: '6',
    fontSize: 'xs',
    px: '12px',
    borderRadius: '4px',
  }),
}

const ButtonVariants = {
  solid: (props: ButtonProps) => ({
    border: '1px solid',
    borderColor: `${props.colorScheme}.600`,
    bgColor: mode(
      `${props.colorScheme}.600`,
      `${props.colorScheme}.600`,
    )(props),
    _active: {
      opacity: 1,
    },
    _focus: {
      boxShadow: 'none',
    },
    _disabled: {
      ...disabledStyles,
    },
    _hover: {
      bgColor: mode(`white`, `white`)(props),
      color: hoverTextStyle(props),
      border: '1px solid',
      borderColor: hoverTextStyle(props),
      _disabled: {
        ...disabledStyles,
      },
    },
    _loading: {
      _hover: { ...disabledStyles! },
    },
    color: textStyle(props),
  }),

  outline: (props: ButtonProps) => ({
    border: '1px solid',
    borderColor: textStyle(props),
    bgColor: mode(`white`, `white`)(props),
    _active: {
      opacity: 1,
    },
    _focus: {
      boxShadow: 'none',
    },
    _disabled: {
      ...disabledStyles,
    },
    _hover: {
      bgColor: outlineHoverBgStyle(props),
      color: outlineHoverStyle(props),
      borderColor: outlineHoverBgStyle(props),
      _disabled: {
        ...disabledStyles,
      },
    },
    _loading: {
      _hover: { ...disabledStyles! },
    },
    color: textStyle(props),
  }),

  ghost: (props: ButtonProps) => ({
    bgColor: mode(`transparent`, `transparent`)(props),
    border: '1px solid',
    borderColor: `transparent`,

    _active: {
      opacity: 1,
    },
    _focus: {
      boxShadow: 'none',
    },
    _disabled: {
      ...disabledStyles,
    },
    _loading: {
      _hover: { ...disabledStyles! },
    },
    _hover: {
      bgColor: ghostHoverBgStyle(props),
      color: hoverTextStyle(props),
      borderColor: mode(
        `${props.colorScheme}.100`,
        `${props.colorScheme}.200`,
      )(props),
      _disabled: {
        ...disabledStyles,
      },
    },
    color: textStyle(props),
  }),

  soft: (props: ButtonProps) => ({
    bgColor: softStyle(props),
    border: '1px solid',
    borderColor: softStyle(props),

    _active: {
      opacity: 1,
    },
    _focus: {
      boxShadow: 'none',
    },
    _disabled: {
      ...disabledStyles,
    },
    _loading: {
      _hover: { ...disabledStyles! },
    },
    _hover: {
      bgColor: mode(
        `${props.colorScheme}.600`,
        `${props.colorScheme}.600`,
      )(props),
      color: hoverTextStyle(props),
      borderColor: mode(
        `${props.colorScheme}.600`,
        `${props.colorScheme}.600`,
      )(props),
      _disabled: {
        ...disabledStyles,
      },
    },
    color: textStyle(props),
  }),
}

export const buttonTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'dark',
  },
  variants: ButtonVariants,
  sizes: ButtonSizes,
})

const savedPaymentIcon = defineStyle({
  fontSize: 'xl',
  px: '0',
  h: '16',
  w: '10',
  borderRadius: 'md',
})

export const iconButtonTheme = defineStyleConfig({
  sizes: { savedPaymentIcon },
})
