import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import FormButton from '../v3/button/FormButton'
import { useFormikContext } from 'formik'
import DatePicker from '../DatePicker'
import CurrencyInput from 'react-currency-input-field'
import moment from 'moment'
type IdType = {
  id: string
}

type BillReferenceIdType = {
  billReferenceId?: string
  issuedOnStartDate?: string
  issuedOnEndDate?: string
  dueDateStartDate?: string
  dueDateEndDate?: string
  billAmountStartValue?: string
  billAmountEndValue?: string
  paidAmountStartValue?: string
  paidAmountEndValue?: string
  vendorName?: string
}

type CustomerType = {
  customer: string
}

type FilterInputComponentProps = {
  label: string
  value: string
  placeholder: string
  name: string
}

export function FilterInputComponent({
  label,
  value,
  placeholder,
  name,
}: FilterInputComponentProps) {
  const { submitForm, setSubmitting, handleChange } = useFormikContext()

  const applyOnClick = async () => {
    await setSubmitting(true)

    await submitForm()

    await setTimeout(async () => {
      setSubmitting(false)
    }, 3000)
  }

  return (
    <Flex
      flexDirection="column"
      grow={1}
      py={8}
      px={6}
      justifyContent={'space-between'}
    >
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Input
          name={name}
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              applyOnClick()
            } else {
              e.stopPropagation()
            }
          }}
        />
      </FormControl>
      <FormButton
        x="right"
        status="primary"
        label="Apply"
        onClick={applyOnClick}
      />
    </Flex>
  )
}

export function FilterTransactionIdComponent() {
  const { values } = useFormikContext<IdType>()
  const { id } = values

  return (
    <FilterInputComponent
      placeholder="Enter Transaction ID"
      name="id"
      value={id}
      label="Transaction Id"
    />
  )
}

export function FilterBillStringComponent(props: {
  fieldName: string
  label: string
  type?: string
  placeholder?: string
}) {
  const {
    values,
    setFieldValue,
  }: {
    values: Record<string, string>
    setFieldValue: (fieldName: string, value: string) => void
  } = useFormikContext<BillReferenceIdType>()

  if (props.type === 'date') {
    return (
      <DatePicker
        {...{
          value: moment(values[props.fieldName]).toDate() as Date,
          onFocus: () => {
            setFieldValue('filterItem', props.fieldName)
            setFieldValue(
              props.fieldName,
              moment(values[props.fieldName]).format('MM/DD/YYYY'),
            )
          },
          onChange: (date: Date) => {
            setFieldValue('filterItem', props.fieldName)
            setFieldValue(
              props.fieldName,
              moment(date.toString()).format('MM/DD/YYYY'),
            )
          },
        }}
      />
    )
  } else if (props.type === 'currency') {
    return (
      <CurrencyInput
        {...{
          className: 'border px-2 py-2 rounded text-sm',
          value: values[props.fieldName] || '',
          onFocus: () => {
            setFieldValue('filterItem', props.fieldName)
          },
          onValueChange: (value) => {
            setFieldValue('filterItem', props.fieldName)
            setFieldValue(props.fieldName, value || '')
          },
          placeholder: props.placeholder || '',
        }}
      />
    )
  }

  return (
    <FilterInputComponent
      name={props.fieldName}
      value={(values[props.fieldName as string] as string) || ''}
      label={props.label}
      placeholder={props.placeholder || ''}
    />
  )
}

export function FilterIdComponent() {
  const { values } = useFormikContext<IdType>()
  const { id } = values

  return (
    <FilterInputComponent
      placeholder="Enter Payment ID"
      name="id"
      value={id}
      label="Payment Id"
    />
  )
}

export function FilterCustomerComponent() {
  const { values } = useFormikContext<CustomerType>()
  const { customer } = values

  return (
    <FilterInputComponent
      name="customer"
      value={customer}
      label="Customer or Business Name"
      placeholder="Enter Customer or Business Name"
    />
  )
}
