import { styles, tw } from '@nickeltech/brise'
import Column, { ColumnProps } from '../Layout/Column'
import SidebarFooter, {
  SidebarFooterProps,
  SidebarFooterUpgradeCTA,
} from './Footer'
import SidebarHeader, { SidebarHeaderProps } from './Header'
import SidebarSection, { SidebarSectionProps } from './Section'
import ContainerProps from '../../types'
import { Switcher, SwitcherProps } from './Switcher'
import { useToast } from '@chakra-ui/react'

export type SidebarContainerProps = ContainerProps & ColumnProps

export type SidebarProps = {} & SidebarHeaderProps &
  SidebarFooterProps &
  ColumnProps & {
    onRouteChange?: (route: string) => void
    sections: Array<SidebarSectionProps>
    currentUrl: string
    accountName: string
    switcher?: SwitcherProps
    planUrl?: string
    footerSections: Array<SidebarSectionProps>
  }

export const SidebarContainerPosition = styles`
  absolute
  top-0
  left-0
  bottom-0
  h-screen
  h-full
  min-h-full 
  flex
  w-[240px]
`

export const SidebarContainer = tw<SidebarContainerProps>(Column as any)`
  ${SidebarContainerPosition}
  border-r 
  bg-sidebar-purple
  `
export const Sidebar = (props: SidebarProps) => {
  const toast = useToast()

  return (
    <SidebarContainer between>
      <Column wGrow>
        {props.switcher ? (
          <Switcher {...props.switcher} />
        ) : (
          <SidebarHeader
            {...{
              isDemo: props.isDemo,
              onHeaderClick: () => {
                toast.closeAll()
                if (props.onHeaderClick) {
                  props.onHeaderClick && props.onHeaderClick()
                }
              },
              homeButton: props.homeButton,
            }}
          />
        )}
        <Column wGrow className="px-3 gap-y-1">
          {props.sections.map((section, index) => (
            <SidebarSection
              {...section}
              key={index}
              currentUrl={props.currentUrl}
              selected={
                props.currentUrl === section.url ||
                section.subsections?.some(
                  (subsection) => subsection.url === props.currentUrl,
                )
              }
            />
          ))}
        </Column>
      </Column>
      <Column wGrow>
        {props.organizationTier === 'FREE_TIER' && (
          <SidebarFooterUpgradeCTA
            url={
              props.planUrl ||
              'https://app.getnickel.com/dashboard/nickel-plans'
            }
          />
        )}
        <Column wGrow className="px-3 py-2 gap-y-1">
          {props.footerSections.map((section, index) => (
            <SidebarSection
              key={index}
              {...section}
              currentUrl={props.currentUrl}
              selected={
                props.currentUrl === section.url ||
                section.subsections?.some(
                  (subsection) => subsection.url === props.currentUrl,
                )
              }
            />
          ))}
        </Column>
        <SidebarFooter
          {...{
            label: props.label,
            accountName: props.accountName,
            footerOptions: props.footerOptions,
            organizationTier: props.organizationTier,
            logoUrl: props.logoUrl,
            planUrl: props.planUrl,
          }}
        />
      </Column>
    </SidebarContainer>
  )
}

export default Sidebar
