import { useMutation, useQuery } from '@apollo/client'
import {
  Badge,
  Card,
  CardBody,
  HStack,
  Text,
  VStack,
  Icon,
  Flex,
  Spacer,
  useToast,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useOutletContext } from 'react-router-dom'
import {
  Button,
  calculatePaymentSummaryForMerchant,
  PaymentSummary,
  Separator,
} from 'ui'
import {
  AddStagedSubscriptionDocument,
  OrganizationBillingDocument,
} from '../../../../operations-types'
import { User } from '../../../../types'
import { NickelPlansPage } from './types'
import currency from 'currency.js'
import { SendMoneyAmountResult } from '../../../ap/components/SendPaymentRouter'
import { AmountResult } from './NickelPlansStore'
import { useEffect } from 'react'

const NickelFeatures = {
  NICKEL_FREE: [
    'Unlimited free ACH transfers',
    '2.9% flat fee for credit card processing',
    '3 user seats',
    'Basic user permissions',
    '2 business days settlement speed',
  ],
  NICKEL_PRO: [
    'Everything in Nickel Free',
    'Next day credit card settlement',
    'Unlimited user seats',
    'Advanced permissions',
    'Customizable Checkout Components',
  ],
  NICKEL_ENTERPRISE: [
    'Everything in Nickel Plus',
    'Up to Net 60 financing for your customers',
    'Digital Credit Application',
    'Contractor and project verification tools',
    'Send unlimited preliminary notices and NOIs',
  ],
}

type NickelPlanCardProps = {
  title: string
  price: number
  description: string
  cta: string
  includedFeatures: string[]
  badgeLabel?: string
  isDisabled?: boolean
  onClick: () => void
}

const NickelPlanCard = (props: NickelPlanCardProps) => {
  return (
    <Card maxW="350px" h="650px">
      <CardBody>
        <VStack w="100%" py="6" px="4">
          <Flex flexDirection="row" w="100%" alignItems="center">
            <Text fontWeight="semibold" color="gray.800">
              {props.title}
            </Text>
            <Spacer />
            {props.badgeLabel && (
              <Badge
                colorScheme="purple" //TODO: Change colorScheme according to current plan
                fontWeight="medium"
                fontSize="2xs"
                p="1"
              >
                {props.badgeLabel}
              </Badge>
            )}
          </Flex>
          <Text fontSize="sm" color="gray.500" alignSelf="start">
            {props.description}
          </Text>
          <VStack
            w="100%"
            alignItems="start"
            py="2"
            spacing="0"
            gap="0"
            h="70px"
          >
            {props.price > 0 && (
              <>
                <Text fontSize="xl" fontWeight="medium">
                  {props.title === 'Nickel Enterprise' && 'Starting at '}
                  {currency(props.price, { precision: 0 }).format()} / month
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {currency(props.price * 12, { precision: 0 }).format()}{' '}
                  {props.title === 'Nickel Enterprise' && 'or more'} billed
                  annually
                </Text>
              </>
            )}
          </VStack>
          <Button
            w="100%"
            label={props.cta}
            isDisabled={props.isDisabled}
            onClick={props.onClick}
          />
          <Separator orientation="horizontal" />

          <Text alignSelf="start" fontSize="md" fontWeight="medium" py="2">
            What's Included
          </Text>
          {props.includedFeatures.map((feature, index) => (
            <HStack
              key={index}
              w="100%"
              alignItems="center"
              justifyItems="start"
            >
              <Icon as={CheckCircleIcon} color="accent.700" />
              <Text key={index}>{feature}</Text>
            </HStack>
          ))}
        </VStack>
      </CardBody>
    </Card>
  )
}

type NickelPricingProps = {
  setPage: (page: NickelPlansPage) => void
  setPaymentSummary: (_: PaymentSummary) => void
  sendMoneyAmountResult: SendMoneyAmountResult | AmountResult
}

export function NickelPricing(props: NickelPricingProps) {
  const { data: organizationBillingData } = useQuery(
    OrganizationBillingDocument,
  )

  useEffect(() => {
    const executePrevPayment = async () => {
      const paymentSummary = calculatePaymentSummaryForMerchant(
        props.sendMoneyAmountResult?.submittedAmountCents,
        'Credit Card',
        'ACH',
      )
      props.setPaymentSummary(paymentSummary)
    }
    executePrevPayment()
  }, [])

  const activated =
    organizationBillingData?.organization?.organization?.accountInfo
      ?.stagedSubscription?.stagedSubscription?.initiated || false

  const toaster = useToast()
  const [addStagedSubscription] = useMutation(AddStagedSubscriptionDocument, {
    onCompleted: () => {
      props.setPage('paymentMethod')
    },
    onError: (error) => {
      toaster({
        title: 'Error',
        description: `Failed to add subscription. ${error.message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })
  const { organization } = useOutletContext() as User
  const NICKEL_PLUS_PLAN_ID = import.meta.env.VITE_NICKEL_PLUS_PLAN_ID

  return (
    <HStack spacing="8" gap="8" w="100%">
      <NickelPlanCard
        title="Nickel"
        price={0}
        description="Our basic plan to send and receive payments" //TODO: Change description accdng to design
        cta={activated ? 'Included' : 'Current Plan'} //TODO: Change CTA according to current plan
        includedFeatures={NickelFeatures.NICKEL_FREE}
        isDisabled={true}
        onClick={() => {
          console.log('Add staged subscription')
        }}
      />
      <NickelPlanCard
        title="Nickel Plus"
        price={35}
        description="Everything you need to upgrade your payment system" //TODO: Change description accdng to design
        cta={activated ? `Current Plan` : `Upgrade to Plus`}
        includedFeatures={NickelFeatures.NICKEL_PRO}
        badgeLabel="Most Popular"
        isDisabled={activated}
        onClick={() => {
          addStagedSubscription({
            variables: {
              organizationId: organization?.id || '',
              planId: NICKEL_PLUS_PLAN_ID,
              firstBillingDate: new Date().toString(),
            },
          })
        }}
      />
      <NickelPlanCard
        title="Nickel Pro"
        price={200} //TODO: Change price according to current plan
        description="For ambitious companies looking to grow quickly" //TODO: Change description accdng to design
        cta="Contact Us" //TODO: Change CTA according to current plan
        includedFeatures={NickelFeatures.NICKEL_ENTERPRISE}
        onClick={() => {
          window.open('https://calendly.com/nickelhq/nickel-discovery-call')
        }}
      />
    </HStack>
  )
}
