import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const dotted = defineStyle({
  border: '1px dashed #78716c',
})

export const badgeTheme = defineStyleConfig({
  baseStyle: {
    textTransform: 'none',
  },
  variants: { dotted },
})
