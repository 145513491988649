import { useState } from 'react'
import Icon from '../Icons'
import Column, { UnstandardizedColumn } from '../Layout/Column'
import Row from '../Layout/Row'
import OverlayDetails, { Detail } from './OverlayDetails'
import { OverlayEmptyLoan, OverlayEmptyRefund } from './OverlayEmpty'
import OverlayMemo from './OverlayMemo'
import { Loan, OverlayLoans, OverlayRefundsV2, Refund } from './OverlayRefunds'
import {
  OverlayTransactionsProps,
  OverlayTransactions,
} from './OverlayTransactions'
import { Notification } from '../Notification'
import { OverlayEmptyPayout, OverlayPayouts, Payout } from './OverlayPayouts'
import { Text } from '@chakra-ui/react'
import { OverlayCard } from './OverlayCard'
import formatter from '../../formatter'

export type SectionProps = {
  title: string
  element: React.ReactElement
  counter?: number
  unpadded?: boolean
  initialExpanded?: boolean
}

export type OverlaySectionRefundProps = {
  title: string
  refunds: Refund[]
  onRefundClick?: (refundId: string) => void
}

export type OverlaySectionLoanProps = {
  title: string
  loans: Loan[]
  onLoanClick?: (loanId: string) => void
}

export type Return = {
  id: string
  amount: number
  paidAt?: string
  status: string
}

export type OverlaySectionReturnProps = {
  achReturn: Return
  onReturnClick?: (returnId: string) => void
  headerText?: string
  amountText?: string
}

export type OverlaySectionPayoutsProps = {
  title: string
  payouts: Payout[]
  initialExpanded?: boolean
  onPayoutClick?: (payoutId: string) => void
}

export type OverlaySectionTransactionProps = {
  title: string
} & OverlayTransactionsProps

export type OverlaySectionMemoProps = {
  notes: string
}

export type OverlaySectionDetailsProps = {
  details: Detail[]
}

function Section({
  title,
  element,
  counter,
  unpadded,
  initialExpanded,
}: SectionProps) {
  const [expanded, setExpanded] = useState(initialExpanded || false)

  return (
    <UnstandardizedColumn
      wGrow
      className={unpadded ? 'gap-y-4' : 'p-8 gap-y-4'}
    >
      <Row gap="medium" between grow onClick={() => setExpanded(!expanded)}>
        <Row gap="small" y="center">
          <Text fontWeight="semibold" color="dark.800" fontSize="xl">
            {title}
          </Text>
          {counter != undefined && <Notification>{counter}</Notification>}
        </Row>
        {expanded ? (
          <Icon
            name="chevronUp"
            size="large"
            hoverable
            className="stroke-gray-400"
            onClick={() => setExpanded(false)}
          ></Icon>
        ) : (
          <Icon
            name="chevronRight"
            size="large"
            hoverable
            className="stroke-gray-400"
            onClick={() => setExpanded(true)}
          ></Icon>
        )}
      </Row>
      {expanded ? <div className="w-full">{element}</div> : ''}
    </UnstandardizedColumn>
  )
}

export function OverlaySectionTransactions({
  title,
  charges,
  refunds,
  fees,
  returns,
  onClick,
}: OverlaySectionTransactionProps) {
  const element = (
    <OverlayTransactions
      charges={charges}
      refunds={refunds}
      fees={fees}
      returns={returns}
      onClick={onClick}
    />
  )

  return <Section title={title} element={element} />
}

export function OverlaySectionMemo({ notes }: OverlaySectionMemoProps) {
  const element = <OverlayMemo notes={notes} />

  return <Section title="Order Reference" element={element} />
}

export function OverlaySectionDetails({ details }: OverlaySectionDetailsProps) {
  const element = <OverlayDetails details={details} />

  return <Section title="Details" element={element} />
}

export function OverlaySectionPayouts({
  title,
  payouts,
  initialExpanded,
  onPayoutClick,
}: OverlaySectionPayoutsProps) {
  const element =
    payouts.length > 0 ? (
      <OverlayPayouts payouts={payouts} onPayoutClick={onPayoutClick} />
    ) : (
      <OverlayEmptyPayout />
    )

  return (
    <Section
      title={title}
      element={element}
      counter={payouts.length}
      initialExpanded={initialExpanded}
      unpadded
    />
  )
}

export function OverlaySectionRefund({
  title,
  refunds,
  onRefundClick,
}: OverlaySectionRefundProps) {
  const element =
    refunds.length > 0 ? (
      <OverlayRefundsV2 refunds={refunds} onRefundClick={onRefundClick} />
    ) : (
      <OverlayEmptyRefund />
    )

  return (
    <Section
      title={title}
      element={element}
      counter={refunds.length}
      unpadded
    />
  )
}

export function OverlaySectionLoan({
  title,
  loans,
  onLoanClick,
}: OverlaySectionLoanProps) {
  const element =
    loans.length > 0 ? (
      <OverlayLoans loans={loans} onLoanClick={onLoanClick} />
    ) : (
      <OverlayEmptyLoan />
    )
  return (
    <Section title={title} element={element} counter={loans.length} unpadded />
  )
}

export function OverlaySectionReturn({
  achReturn,
  onReturnClick,
  headerText,
  amountText,
}: OverlaySectionReturnProps) {
  return (
    <UnstandardizedColumn wGrow className="gap-y-4">
      <Row gap="medium" between grow>
        <Row gap="small" y="center">
          <Text fontWeight="semibold" color="dark.800" fontSize="xl">
            {headerText || 'Return '}
          </Text>
        </Row>
      </Row>
      <div className="w-full">
        <UnstandardizedColumn wGrow className="rounded gap-y-2">
          <Column wGrow gap="small">
            <OverlayCard
              boldedText={achReturn.status}
              remainderText={achReturn.paidAt ? `on ${achReturn.paidAt}` : ''}
              onClick={
                onReturnClick && achReturn.id
                  ? () => onReturnClick(achReturn.id)
                  : undefined
              }
              subHeading={achReturn.id}
              firstItem={{
                itemName: amountText || 'Return Amount',
                itemValue: `(${formatter.format(achReturn.amount)})`,
              }}
            />
          </Column>
        </UnstandardizedColumn>
      </div>
    </UnstandardizedColumn>
  )
}
