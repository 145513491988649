import styled from 'styled-components'
import ContainerProps from '../../types'
import Icon from '../Icons'
import Row from '../Layout/Row'

type CreditCardProvidersProps = {} & ContainerProps

const CreditCardProviders = styled((props: CreditCardProvidersProps) => {
  return (
    <Row gap="small" y="center" x="right" {...props}>
      <Icon name="visa" />
      <Icon name="masterCard" />
      <Icon name="amex" />
      <Icon name="discover" />
    </Row>
  )
})`
  svg {
    height: 15px;
    width: fit-content;
  }

  width: 30%;
`

export default CreditCardProviders
