import { property, tw } from '@nickeltech/brise'
import ContainerProps, { StatusProps, VariantProps } from '../../types'
import StatusTextColor from '../Indicators'
import { VisibilityTransition, VisibilityTransitionProps } from '../Transitions'
import { ClampedProps, ClampedStyle } from '.'

export type CaptionProps = ContainerProps &
  VariantProps &
  StatusProps &
  VisibilityTransitionProps &
  ClampedProps

export const CaptionVariantStyle = property(
  'variant',
  {
    tertiary: 'text-xs font-medium text-gray-400',
    secondary: 'text-xs font-medium text-gray-800',
    primary: 'text-xs font-semibold text-gray-800',
  },
  'primary',
)

export const Caption = tw.h6<CaptionProps>`
  ${CaptionVariantStyle}
  ${StatusTextColor}
  ${VisibilityTransition}
  ${ClampedStyle}
`

export default Caption
