import { property, tw } from '@nickeltech/brise'
import Row from '../layout/Row'
import { ContainerProps } from '../types'
import { Size } from '../../../types'

export type NotificationVariant = 'default' | 'inactive' | 'primary' | 'accent'

export const NotificationVariantValues: Array<NotificationVariant> = [
  'accent',
  'default',
  'inactive',
  'primary',
]

export type NotificationSize = Extract<Size, ['small', 'base']>

export type NotificationProps = {
  variant?: NotificationVariant
  size?: NotificationSize
} & ContainerProps

const VariantMap = {
  default: 'bg-dark-500 text-white',
  inactive: 'bg-gray-300 text-gray-800',
  primary: 'bg-purple-700 text-white',
  accent: 'bg-accent-400 text-accent-900',
}

const SizeMap = {
  small: 'w-5 h-5',
  base: 'w-6 h-6',
}

export const NotificationVariant = property('variant', VariantMap, 'primary')
export const NotificationSize = property('size', SizeMap, 'small')

const NotificationContainer = tw<NotificationProps>(Row)`
    rounded-sm
    font-semibold
    text-xs
    ${NotificationVariant}
    ${NotificationSize}
`

export const Notification = ({ children, ...props }: NotificationProps) => {
  return (
    <NotificationContainer {...{ ...props, x: 'center', y: 'center' }}>
      {children}
    </NotificationContainer>
  )
}

export default Notification
