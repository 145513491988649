import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PAYMENTS_URL, REFUNDS_URL } from '../../../lib/urls'
import PayoutInnerOverlay from '../../payouts/PayoutInnerOverlay'
import RefundInnerOverlay from './RefundInnerOverlay'
import PaymentInnerOverlay from '../PaymentInnerOverlay'

type OVERLAY_STATES = 'payment' | 'refund' | 'payout'

export default function RefundOverlay() {
  const { refundId } = useParams() as { refundId: string }
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [payoutId, setPayoutId] = useState<string | null>(null)

  const [state, setState] = useState<OVERLAY_STATES>('refund')
  const onBack = () => setState('refund')

  const navigate = useNavigate()
  const exitRefundOverlay = () => navigate(REFUNDS_URL)

  const STATE_TO_ELEMENT: { [state in OVERLAY_STATES]: JSX.Element } = {
    payment: (
      <>
        {paymentId && (
          <PaymentInnerOverlay
            exitOverlay={exitRefundOverlay}
            paymentId={paymentId}
            onRefund={() => setState('refund')}
            onPayout={(payoutId) => {
              setPayoutId(payoutId)
              setState('payout')
            }}
            onBack={onBack}
          />
        )}
      </>
    ),
    refund: (
      <>
        {refundId && (
          <RefundInnerOverlay
            refundId={refundId}
            onPayoutClick={(id) => {
              setPayoutId(id)
              setState('payout')
            }}
            onPaymentClick={(id) => {
              setPaymentId(id)
              setState('payment')
            }}
            exitOverlay={exitRefundOverlay}
          />
        )}
      </>
    ),
    payout: (
      <>
        {payoutId && (
          <PayoutInnerOverlay
            payoutId={payoutId}
            onExit={exitRefundOverlay}
            onBack={onBack}
            onTransactionClick={(transaction) => {
              navigate(`${PAYMENTS_URL}/${transaction.transactionId}`)
            }}
          />
        )}
      </>
    ),
  }

  const element = STATE_TO_ELEMENT[state]
  return <>{element}</>
}
