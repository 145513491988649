import { Box, Stack, StackDivider } from '@chakra-ui/react'
import { useOutletContext } from 'react-router-dom'
import { InfoCardItem } from '../InfoCardItem'
import { Branding } from './Branding'
import { Domain } from './Domain'
import { useState } from 'react'
import NotificationArea from '../../utils/NotificationArea'
import Notification from '../../utils/Notification'
import PaymentMethods from './PaymentMethods'
import { PaymentSettings, User } from '../../../types'

export default function NickelPay() {
  const data = useOutletContext() as User

  const [showNotif, setShowNotif] = useState({
    message: '',
    show: false,
    header: '',
  })
  const onClose = () => setShowNotif({ message: '', show: false, header: '' })
  const { organization } = data

  const { subdomain, logoKey, bannerKey, paymentSettings } =
    organization?.accountInfo || {}

  return (
    <Box py={{ base: '4', md: '8' }}>
      <NotificationArea>
        <Notification
          show={showNotif['show']}
          onClose={onClose}
          header={showNotif['header']}
          message={showNotif['message']}
        />
      </NotificationArea>
      <Stack spacing="8" divider={<StackDivider />}>
        <InfoCardItem
          label="Branding"
          sublabel="The look and feel of your Nickel Payments Portal"
        >
          <Branding
            orgName={organization?.name || ''}
            logoKey={logoKey || ''}
            bannerKey={bannerKey || ''}
            onSuccess={() =>
              setShowNotif({
                message: 'Branding updated!',
                show: true,
                header: 'Success!',
              })
            }
          />
        </InfoCardItem>
        <InfoCardItem
          label="Payment Methods"
          sublabel="Configure payment settings for your customers"
        >
          <PaymentMethods
            paymentSettings={paymentSettings as PaymentSettings}
            onCompleted={() =>
              setShowNotif({
                message: 'Payment settings updated!',
                show: true,
                header: 'Success!',
              })
            }
          />
        </InfoCardItem>
        <InfoCardItem
          label="Domain"
          sublabel="Your dedicated Nickel Pay subdomain"
        >
          <Domain subdomain={subdomain || 'mysite'} />
        </InfoCardItem>
      </Stack>
    </Box>
  )
}
