import { Box, Card, Flex, Text, Image, Divider, HStack } from '@chakra-ui/react'
import currency from 'currency.js'
import { Icon, StatusPill } from 'ui'

type CustomerCardProps = {
  name: string
  address: string
  sales: number
  balance: number
  numContacts: number
  numProjects: number
  logoUrl?: string
  status: String
  onClick?: () => void
}

type CustomerStatsProps = {
  title: string
  value: string
  onClick?: () => void
}

function CustomerStats({ title, value }: CustomerStatsProps) {
  return (
    <Flex flexDirection="column">
      <Text fontSize="xs" color="gray.500">
        {title}
      </Text>
      <Text fontWeight="medium" color="gray.800" fontSize="13px">
        {value}
      </Text>
    </Flex>
  )
}

function CustomerNumber({ title, value, onClick }: CustomerStatsProps) {
  return (
    <Flex flexDirection="column">
      <Text fontSize="xs" color="gray.500">
        {title}
      </Text>
      <HStack gap={0.5}>
        <Text
          fontWeight="medium"
          color="purple.600"
          fontSize="13px"
          cursor="pointer"
          onClick={onClick}
        >
          {value}
        </Text>
        <Icon
          name="rightArrow"
          className="stroke-purple-600 stroke-2 cursor-pointer"
          size="xsmall"
          onClick={onClick}
        />
      </HStack>
    </Flex>
  )
}
export function CustomerCard({
  name,
  address,
  sales,
  balance,
  numContacts,
  numProjects,
  logoUrl,
  status,
  onClick,
}: CustomerCardProps) {
  return (
    <Card
      py={4}
      px={6}
      cursor="pointer"
      _hover={{ bg: 'gray.100' }}
      onClick={onClick}
    >
      <Flex flexDirection="column" gap={3}>
        <Flex flexDirection="row" justifyContent="space-between">
          <Image src={logoUrl} alt={name} w="32px" h="32px" />
          <Box>
            <StatusPill
              icon="checkCircle"
              status="success"
              spacing="xsmallNarrow"
              size="small"
              iconPosition="left"
            >
              {status}
            </StatusPill>
          </Box>
        </Flex>
        <Box>
          <Text color="black" fontWeight="medium">
            {name}
          </Text>
          <Text fontSize="11px" color="gray.500">
            {address}
          </Text>
        </Box>
        <Divider />
        <Flex flexDirection="row" gap={6}>
          <CustomerStats title="Total Sales" value={currency(sales).format()} />
          <CustomerStats title="Balance" value={currency(balance).format()} />
          <CustomerNumber title="Contacts" value={numContacts.toString()} />
          <CustomerNumber title="Contacts" value={numProjects.toString()} />
        </Flex>
      </Flex>
    </Card>
  )
}
