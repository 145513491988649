import { useMutation, useQuery } from '@apollo/client'
import copy from 'copy-to-clipboard'
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  VStack,
  Text,
  Link,
  HStack,
  Badge,
  Grid,
  GridItem,
  Spacer,
  Flex,
  Divider,
  Center,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  useDisclosure,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  IconButton,
  Switch,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react'
import { Button, Header, Row } from 'ui'

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

import {
  SelfDocument,
  QuestionFormsDocument,
  RemoveQuestionFormDocument,
  QuestionFormDocument,
  QuestionFormSchema,
  SetPaymentLinkPreviewDocument,
  QuestionForm,
} from '../../operations-types'

import {
  useAddQuestionFormDocument,
  useUpdateQuestionFormDocument,
} from './questionFormMutation'
import { useNavigate } from 'react-router-dom'
import { fetchPortalURL } from '../../lib/urls'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  onClick: () => void
  linkName?: string
  setLinkName: (e: string) => void
}
function NewPaymentLinkModal({
  isOpen,
  onClose,
  onClick,
  linkName,
  setLinkName,
}: ModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w="400px"
        containerProps={{
          paddingTop: '6rem',
        }}
      >
        <ModalBody w="100%" boxShadow="lg" borderRadius="sm" p="6">
          <VStack w="100%" alignContent="center" gap="5">
            <Text fontSize="xl" fontWeight="semibold">
              Give your new payment link a name
            </Text>
            <Input
              placeholder="Payment Link Name"
              value={linkName}
              onChange={(e) => {
                setLinkName(e.target.value)
              }}
            />

            <Flex
              flexDirection="row"
              w="100%"
              gap="2"
              pt="2"
              justifyContent="end"
            >
              <HStack gap="3">
                <Button
                  variant="outline"
                  label="Close"
                  onClick={onClose}
                  w="100%"
                  size="md"
                />
                <Button
                  onClick={async () => {
                    await onClick()
                    onClose()
                  }}
                  label="Continue"
                  w="100%"
                  size="md"
                />
              </HStack>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function PaymentLinkCreationButton({
  onClick,
  linkName,
  setLinkName,
}: {
  onClick: () => void
  linkName?: string
  setLinkName: (e: string) => void
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex
      w="100%"
      display={'flex'}
      flexDirection={'row'}
      justifyContent="flex-end"
    >
      <Button
        variant={'solid'}
        label="Create Payment Link"
        className="!text-sm !h-[30px]"
        {...{
          onClick: onOpen,
        }}
      />
      <NewPaymentLinkModal
        isOpen={isOpen}
        onClose={onClose}
        linkName={linkName}
        setLinkName={setLinkName}
        onClick={onClick}
      />
    </Flex>
  )
}

type PaymentLinkOptionsProps = {
  onClick?: () => void
  offset?: [number, number]
  activated: boolean
  handleCheckoutActive: () => void
}

function PaymentLinkOptions(props: PaymentLinkOptionsProps) {
  return (
    <Menu offset={props.offset}>
      <MenuButton
        pe="4"
        cursor="pointer"
        as={IconButton}
        aria-label="Options"
        style={{ width: '10px' }}
        icon={<Icon as={EllipsisVerticalIcon} boxSize="5" />}
        variant="unstyled"
        color="gray.500"
      />
      <MenuList minW="0" w="85px" zIndex={999} position="absolute">
        <Center flexDirection="column">
          <MenuItem
            cursor="pointer"
            color="red.500"
            fontSize="sm"
            alignSelf="center"
            {...{
              onClick: props.onClick,
            }}
          >
            Remove
          </MenuItem>
          <MenuItem closeOnSelect={false}>
            <Flex
              w="100%"
              flexDirection="row"
              onClick={() => {
                props.handleCheckoutActive()
              }}
            >
              <Text fontSize="sm">{props.activated ? 'On' : 'Off'}</Text>
              <Spacer />
              <Switch isChecked={props.activated} pointerEvents="none" />
            </Flex>
          </MenuItem>
        </Center>
      </MenuList>
    </Menu>
  )
}

type PaymentLinkCardProps = {
  questionForm: QuestionForm
  onClick: () => void
  removeOnClick?: () => void
  subdomain: string | undefined
}

function PaymentLinkCard({
  questionForm,
  onClick,
  removeOnClick,
  subdomain,
}: PaymentLinkCardProps) {
  const dateUpdated = new Date(parseInt(questionForm.updatedAt))
  const toaster = useToast()

  const { data, refetch } = useQuery(QuestionFormDocument, {
    variables: {
      questionFormId: questionForm.id || '',
    },
  })

  const [updateQuestionFormDocument] = useUpdateQuestionFormDocument({
    onCompleted: async () => {
      toaster({
        status: 'success',
        title: `Updated Question Form`,
      })
      refetch()
    },
    onError: (e) => {
      toaster({
        status: 'error',
        title: e.message,
      })
    },
  })

  const [currentSchema, setCurrentSchema] = useState<QuestionFormSchema>({})
  const dataSchema = data?.questionForm?.questionForm?.schema || '{}'

  const [currentQuestionForm, setCurrentQuestionForm] = useState(
    data?.questionForm?.questionForm,
  )

  const [activated, setActivated] = useState(false)

  useEffect(() => {
    if (data?.questionForm?.questionForm) {
      setCurrentQuestionForm(data?.questionForm?.questionForm)
      setCurrentSchema(JSON.parse(dataSchema) as QuestionFormSchema)
      setActivated(!!data?.questionForm?.questionForm?.checkoutActive)
    }
  }, [data])

  const [setPaymentLinkPreview] = useMutation(SetPaymentLinkPreviewDocument)

  const completedAmountInCents =
    currentQuestionForm?.completedAmountInCents || 0

  const formattedAmount = (completedAmountInCents / 100).toLocaleString(
    'en-US',
    {
      style: 'currency',
      currency: 'USD',
    },
  )

  return (
    <>
      <Card w="100%" boxShadow="lg" border="2px solid" borderColor="gray.200">
        <CardHeader cursor="pointer" onClick={onClick} pt="6" px="6" pb="3">
          <VStack alignItems="left">
            <Flex flexDirection="row">
              <Center>
                <Badge p="1" colorScheme={activated ? 'green' : 'gray'}>
                  {activated ? 'Active' : 'Inactive'}
                </Badge>
              </Center>
              <Spacer />
              <Text color="gray.500" fontSize="sm">
                Updated on {dateUpdated.toLocaleDateString()}
              </Text>
            </Flex>
            <HStack alignContent="left">
              <Text fontSize="2xl" fontWeight="semibold" noOfLines={1}>
                {questionForm.name}
              </Text>
            </HStack>
          </VStack>
        </CardHeader>
        <CardBody onClick={onClick} cursor="pointer" p="0">
          <HStack>
            <VStack alignItems="left" py="6" px="6">
              <Text fontSize="sm" color="gray.500">
                Transactions
              </Text>
              <Text fontSize="md" fontWeight="semibold">
                {questionForm.completedCount > 0
                  ? questionForm.completedCount
                  : '--'}
              </Text>
            </VStack>
            <VStack alignItems="left" py="6" px="6">
              <Text fontSize="sm" color="gray.500">
                Total Completed
              </Text>
              <Text fontSize="md" fontWeight="semibold">
                {completedAmountInCents > 0 ? formattedAmount : '$--'}
              </Text>
            </VStack>
          </HStack>
        </CardBody>
        <Divider w="100%" color="gray.300" />
        <CardFooter p="0">
          <Flex flexDirection="row" px="6" py="3" w="100%" alignItems="center">
            <PaymentLinkOptions
              offset={[-70, -30]}
              onClick={removeOnClick}
              activated={activated}
              handleCheckoutActive={async () => {
                await setActivated(!activated)
                await updateQuestionFormDocument({
                  variables: {
                    questionFormId: currentQuestionForm?.id || '',
                    name: currentQuestionForm?.name || '',
                    checkoutPath: currentQuestionForm?.checkoutPath || '',
                    creditCardEnabled: currentQuestionForm?.creditCardEnabled,
                    achEnabled: currentQuestionForm?.achEnabled,
                    feePassthroughPercent:
                      currentQuestionForm?.feePassthroughPercent,
                    checkoutActive: !activated,
                    schema: currentSchema,
                  },
                })
              }}
            />
            <Spacer />
            <Center>
              <HStack spacing="2" gap="4" w="100%">
                <Link
                  color="purple.500"
                  fontSize="md"
                  isExternal
                  onClick={async () => {
                    setPaymentLinkPreview({
                      variables: {
                        checkoutPath: currentQuestionForm?.checkoutPath || '',
                        subdomain: subdomain || '',
                        previewSchema: JSON.stringify({
                          schema: currentSchema,
                          checkoutPath: currentQuestionForm?.checkoutPath || '',
                          creditCardEnabled:
                            currentQuestionForm?.creditCardEnabled,
                          achEnabled: currentQuestionForm?.achEnabled,
                          feePassthroughPercent:
                            currentQuestionForm?.feePassthroughPercent || 100,
                          name: currentQuestionForm?.name || '',
                        }),
                      },
                    })
                    window.open(
                      `${fetchPortalURL(subdomain)}?preview=${btoa(
                        JSON.stringify({
                          checkoutPath: currentQuestionForm?.checkoutPath || '',
                        }),
                      )}`,
                      '_blank',
                    )
                  }}
                >
                  Preview Link
                  <ExternalLinkIcon m="1" mb="2" />
                </Link>
                <Button
                  label="Copy Link"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    copy(
                      `${fetchPortalURL(subdomain)}/
                        ${questionForm.checkoutPath}`,
                    )
                    toaster({
                      status: 'success',
                      description: 'Payment Link URL copied to clipboard',
                    })
                  }}
                />
              </HStack>
            </Center>
          </Flex>
        </CardFooter>
      </Card>
    </>
  )
}
type PaymentLinksProps = {
  questionForms: QuestionForm[]
  setQuestionFormId: (e: string) => void
  subdomain: string | undefined
  refetchForms: () => void
}
function PaymentLinks(props: PaymentLinksProps) {
  const toaster = useToast()

  const [removeQuestionFormDocument] = useMutation(RemoveQuestionFormDocument, {
    onCompleted: () => {
      toaster({
        status: 'error',
        title: `Deleted Payment Link`,
      })
      props.refetchForms()
    },
    onError: (e) => {
      toaster({
        status: 'error',
        title: e.message,
      })
    },
  })
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      {props.questionForms
        .filter(
          (questionForm: QuestionForm) => questionForm.status === 'PERSISTENT',
        )
        .map((questionForm: QuestionForm) => (
          <GridItem key={questionForm.id}>
            <PaymentLinkCard
              subdomain={props.subdomain}
              questionForm={questionForm}
              onClick={() => {
                props.setQuestionFormId(questionForm.id)
              }}
              removeOnClick={async () => {
                await removeQuestionFormDocument({
                  variables: {
                    questionFormId: questionForm.id || '',
                  },
                })
              }}
            />
          </GridItem>
        ))}
    </Grid>
  )
}

export default function QuestionFormsTable() {
  const [page] = useState(1)
  const [questionFormId, setQuestionFormId] = useState<null | string>(null)

  const pageSize = 20

  const queryOpts = {
    page: page,
    pageSize: pageSize,
  }

  const { data, refetch: refetchForms } = useQuery(QuestionFormsDocument, {
    variables: {
      ...queryOpts,
    },
    onCompleted: () => {},
  })

  const questionForms = data?.questionForms?.questionForms || []

  const push = useNavigate()

  useEffect(() => {
    if (questionFormId) {
      push(`/payment-links/${questionFormId}`)
    }
  }, [questionFormId])

  const [linkName, setLinkName] = useState('')

  const toaster = useToast()

  const { refetch: refetchAddQuestion } = useQuery(QuestionFormsDocument)

  const [addQuestionFormDocument] = useAddQuestionFormDocument({
    onCompleted: async () => {
      toaster({
        status: 'success',
        title: `Created Question Form`,
      })

      const refetchedData = await refetchAddQuestion()

      if (!!refetchedData?.data?.questionForms?.questionForms?.length) {
        refetchedData?.data?.questionForms?.questionForms?.forEach(
          (questionForm: QuestionForm, index: number) => {
            if (
              !!refetchedData?.data?.questionForms?.totalResults &&
              refetchedData?.data?.questionForms?.totalResults - 1 === index
            ) {
              setQuestionFormId(questionForm?.id)
            }
          },
        )
      }
      refetchForms()
    },
    onError: (e) => {
      toaster({
        status: 'error',
        title: e.message,
      })
    },
  })

  const { data: userData } = useQuery(SelfDocument, {})
  const subdomain = userData?.user?.user?.organization?.accountInfo?.subdomain

  return (
    <Flex flexDirection="column" overflow="auto" h="100vh">
      <div className="mx-10">
        <Row spacing="mediumThick" />
        <Row className="pb-8 w-full" grow between>
          <Header variant="page" className="w-full">
            Payment Links
          </Header>
          <PaymentLinkCreationButton
            linkName={linkName}
            setLinkName={setLinkName}
            onClick={async () => {
              await addQuestionFormDocument({
                variables: {
                  name: linkName,
                  schema: { query_parameters: [] },
                  checkoutPath: linkName.replace(/[^\w]/g, '').toLowerCase(),
                  creditCardEnabled: true,
                  achEnabled: true,
                  feePassthroughPercent: 100,
                },
              })
            }}
          />
        </Row>
        <PaymentLinks
          refetchForms={refetchForms}
          questionForms={questionForms}
          setQuestionFormId={setQuestionFormId}
          subdomain={subdomain || ''}
        />
      </div>
    </Flex>
  )
}
