import { property, styles, tw } from '@nickeltech/brise'
import { MotionProps, motion } from 'framer-motion'
import { Ref } from 'react'
import SectionedStyle, { OverflowHiddenProps, SectionedProps } from '.'
import ContainerProps, { StatusProps } from '../../types'
import { StatusBackgroundStyle } from '../Indicators'
import {
  DisabledTransition,
  DisabledTransitionProps,
  VisibilityTransition,
  VisibilityTransitionProps,
} from '../Transitions'
import Spacing, { Gap, GapProps, SpacingProps } from './Spacing'

export type RowXType = 'left' | 'center' | 'right'
export type RowYType = 'top' | 'center' | 'bottom'

export type VisibilityRowProps = {
  visible?: boolean
}

export type RowProps = {
  x?: RowXType
  y?: RowYType
  between?: boolean
  hoverable?: boolean
  around?: boolean
  selected?: boolean
  rounded?: boolean
  grow?: boolean
  hGrow?: boolean
  sectioned?: boolean
  forwadedRef?: Ref<HTMLDivElement>
  cursorDefault?: boolean
} & ContainerProps &
  SpacingProps &
  GapProps &
  StatusProps &
  SectionedProps &
  VisibilityTransitionProps &
  MotionProps &
  OverflowHiddenProps &
  DisabledTransitionProps

export const RowXPositionStyle = property(
  'x',
  {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  },
  'left',
)

export const RowYPositionStyle = property(
  'y',
  {
    top: 'items-start',
    center: 'items-center',
    bottom: 'items-end',
  },
  'top',
)

export const RowAdditionalStyles = styles`
  ${(props: RowProps) => (props.between ? 'justify-between' : '')}
  ${(props: RowProps) => (props.around ? 'justify-around' : '')}
  ${(props: RowProps) =>
    props.onClick && !props.selected && props.hoverable
      ? 'hover:bg-gray-200'
      : ''} 
  ${(props: RowProps) => (props.selected ? 'bg-gray-50' : '')}
  ${(props: RowProps) => (props.rounded ? 'rounded' : '')}
  ${(props: RowProps) => (props.grow ? 'w-full grow' : '')}
  ${(props: RowProps) => (props.hGrow ? 'h-full' : '')}
  ${(props: RowProps) =>
    props.onClick && !props.cursorDefault ? 'cursor-pointer' : ''}
  ${(props: RowProps) => (props.overflowHidden ? 'overflow-hidden' : '')}
`

export const Row = tw(
  ({
    className,
    style,
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    animate,
    initial,
    forwadedRef,
    onAnimationComplete,
  }: RowProps) => {
    return (
      <motion.div
        {...{
          style,
          className,
          onClick,
          onMouseEnter,
          onMouseLeave,
          animate: animate,
          initial: initial || false,
          ref: forwadedRef,
          onAnimationComplete,
        }}
      >
        {children}
      </motion.div>
    )
  },
)`
  flex
  flex-row
  ${RowXPositionStyle}
  ${RowYPositionStyle}
  ${RowAdditionalStyles}
  ${Gap}
  ${Spacing}
  ${VisibilityTransition}
  ${StatusBackgroundStyle}
  ${SectionedStyle}
  ${DisabledTransition}
`

// Spacing here not standardized
export const UnstandardizedRow = tw(
  ({
    className,
    style,
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    animate,
    initial,
    forwadedRef,
    onAnimationComplete,
  }: RowProps) => {
    return (
      <motion.div
        {...{
          style,
          className,
          onClick,
          onMouseEnter,
          onMouseLeave,
          animate: animate,
          initial: initial || false,
          ref: forwadedRef,
          onAnimationComplete,
        }}
      >
        {children}
      </motion.div>
    )
  },
)`
  flex
  flex-row
  ${RowXPositionStyle}
  ${RowYPositionStyle}
  ${RowAdditionalStyles}
  ${Gap}
  ${VisibilityTransition}
  ${StatusBackgroundStyle}
  ${SectionedStyle}
  ${DisabledTransition}
`

export default Row
