import { useMutation } from '@apollo/client'
import { Center, Text, useToast, VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { HandleHubSpotAuthRequestDocument } from '../../operations-types'

function HubSpotAuth() {
  const toaster = useToast()
  const [handleHubSpotAuth] = useMutation(HandleHubSpotAuthRequestDocument, {
    onError: (error) => {
      toaster({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
    onCompleted: () => {
      toaster({
        title: 'Success',
        description: 'Successfully linked HubSpot',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    },
  })
  const [searchParams] = useSearchParams('code')
  const code = searchParams.get('code')

  useEffect(() => {
    if (code) {
      handleHubSpotAuth({
        variables: {
          code,
        },
      })
    }
  }, [])
  return (
    <Center verticalAlign="center" h="100%" w="100%" pt="20">
      <VStack>
        <Text fontSize="2xl" fontWeight="bold">
          Successfully linked Hubspot
        </Text>
        <Text fontSize="xl" fontWeight="semibold">
          Please return to a HubSpot deal and refresh the page.
        </Text>
        <Text fontSize="xl" fontWeight="semibold">
          You may exit this page.
        </Text>
      </VStack>
    </Center>
  )
}

export default HubSpotAuth
