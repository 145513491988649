import { RadioGroup } from '@headlessui/react'
import { tw } from '@nickeltech/brise'
import { useFormikContext } from 'formik'
import React, { createRef, ReactNode } from 'react'
import styled from 'styled-components'
import { CheckCircleIcon } from '@chakra-ui/icons'
import get from 'lodash/get'
import ContainerProps from '../../types'
import Indicator from '../Indicators/Indicator'
import Column from '../Layout/Column'
import Row, { RowProps } from '../Layout/Row'
import { DisabledTransitionProps } from '../Transitions'
import Label from '../Typography/Label'

export type CheckedProps = {
  checked?: boolean
} & ContainerProps

export type RadioOptionProps = {
  value: string | number
  label: string | number | React.ReactNode
  className?: string
  subOption?: ReactNode
  disabled?: boolean
} & CheckedProps

export type RadioInputProps = {
  options: Array<RadioOptionProps>
  label?: string
  name: string
  onChange?: (...args: any) => void
} & ContainerProps &
  DisabledTransitionProps

export const RadioCheck = tw(({ className, checked }: CheckedProps) => {
  return (
    <Indicator
      {...{ className }}
      status={checked ? 'action' : 'none'}
      size={'medium'}
    >
      {checked && <CheckCircleIcon color="gray.300" />}
    </Indicator>
  )
})`
  bg-gray-100
  aspect-square
`

const RadioGroupContainer = tw(RadioGroup)`
  w-full
  bg-white
  rounded
  border
  rounded
  border-gray-200
`

const OptionRow = styled((props: RowProps) => {
  return (
    <Row
      {...{
        forwadedRef: props.forwadedRef,
        ...props,
      }}
    >
      {props.children}
    </Row>
  )
})`
  ${(props) => (props.disabled ? 'border-b !border-b-gray-900' : '')}
`

const RadioInput = styled((props: RadioInputProps) => {
  const { values, setFieldValue } = useFormikContext()

  const subOptionRef = createRef<HTMLDivElement>()

  return (
    <Column gap="small" {...{ disabled: props.disabled }} wGrow={true}>
      {props.label && props.label.length > 0 ? (
        <Label variant="secondary">{props.label}</Label>
      ) : (
        ''
      )}
      <RadioGroupContainer
        name={props.name}
        onChange={(e: string | number) => {
          setFieldValue(props.name, e, true)
        }}
        value={get(values, props.name, '')}
      >
        <Column
          wGrow={true}
          {...{ className: props.className, disabled: props.disabled }}
        >
          {props.options.map((option, index) => (
            <Column wGrow={true} key={index}>
              <RadioGroup.Option
                value={option.value || ''}
                disabled={option.disabled}
                className={`w-full ${
                  option.disabled ? 'pointer-events-none' : 'cursor-pointer'
                }`}
              >
                {({ checked }) => (
                  <OptionRow
                    grow
                    x="left"
                    gap="small"
                    y="center"
                    className="px-3 py-4"
                    sectioned
                    {...{ disabled: props.disabled }}
                  >
                    <RadioCheck {...{ checked }} />
                    {option.label}
                  </OptionRow>
                )}
              </RadioGroup.Option>
              <Column wGrow={true} forwadedRef={subOptionRef}>
                {option.subOption}
              </Column>
            </Column>
          ))}
        </Column>
      </RadioGroupContainer>
    </Column>
  )
})`
  > div:last-child {
    ${OptionRow} {
      border-bottom: 0px;
    }
  }
`

export default RadioInput
