import { Form, Formik } from 'formik'
import { forwardRef, Ref } from 'react'
import * as yup from 'yup'
import PaymentsFilterDropdownComponent from './PaymentsFilterDropdownComponent'

type PaymentsFilterDropdownProps = {
  onSubmit: (from?: Date, to?: Date, id?: string, customer?: string) => void
}

const Schema = yup.object().shape({
  filterItem: yup.string().oneOf(['date', 'id', 'customer']).required(),
  to: yup.date().when('filterItem', {
    is: 'date',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  from: yup.date().when('filterItem', {
    is: 'date',
    then: yup.date().required(),
    otherwise: undefined,
  }),
  id: yup.string().when('filterItem', {
    is: 'id',
    then: yup.string().required(),
    otherwise: undefined,
  }),
  customer: yup.string().when('filterItem', {
    is: 'customer',
    then: yup.string().required(),
    otherwise: undefined,
  }),
})

export type FilterSchema = yup.InferType<typeof Schema>

export function PaymentsFilterDropdown({
  onSubmit,
}: PaymentsFilterDropdownProps) {
  const to = new Date()
  const from = new Date()

  from.setHours(0)
  from.setSeconds(0)
  from.setMinutes(0)
  to.setHours(23)
  to.setMinutes(59)
  to.setSeconds(59)

  return (
    <Formik<FilterSchema>
      {...{
        initialValues: {
          to: to,
          from: from,
          id: '',
          customer: '',
          filterItem: 'id',
        } as FilterSchema,
        validationSchema: Schema,
        onSubmit: (values, actions) => {
          if (values.filterItem === 'id') {
            onSubmit(undefined, undefined, values.id)
          }

          if (values.filterItem === 'date') {
            onSubmit(values.from, values.to)
          }

          if (values.filterItem === 'customer') {
            onSubmit(undefined, undefined, undefined, values.customer)
          }

          actions.setSubmitting(false)
        },
      }}
    >
      {({ handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <PaymentsFilterDropdownComponent
              label="Filter"
              onClick={(value) => setFieldValue('filterItem', value)}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

const ReffedPaymentsFilterDropdownCore = (
  { onSubmit }: PaymentsFilterDropdownProps,
  ref: Ref<HTMLFormElement>,
) => {
  const to = new Date()
  const from = new Date()

  from.setHours(0)
  from.setSeconds(0)
  from.setMinutes(0)
  to.setHours(23)
  to.setMinutes(59)
  to.setSeconds(59)

  return (
    <Formik<FilterSchema>
      {...{
        initialValues: {
          to: to,
          from: from,
          id: '',
          customer: '',
          filterItem: 'id',
        } as FilterSchema,
        validationSchema: Schema,
        onSubmit: (values, actions) => {
          if (values.filterItem === 'id') {
            onSubmit(undefined, undefined, values.id)
          }

          if (values.filterItem === 'date') {
            onSubmit(values.from, values.to)
          }

          if (values.filterItem === 'customer') {
            onSubmit(undefined, undefined, undefined, values.customer)
          }

          actions.setSubmitting(false)
        },
      }}
    >
      {({ handleSubmit, setFieldValue }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            ref={ref as Ref<HTMLFormElement>}
            className="absolute z-50"
          >
            <PaymentsFilterDropdownComponent
              label="Filter"
              onClick={(value) => setFieldValue('filterItem', value)}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export const ReffedPaymentsFilterDropdown = forwardRef(
  ReffedPaymentsFilterDropdownCore,
)
