import { Flex, Card } from '@chakra-ui/react'
import { Form, Button, FormikValidatingInput } from 'ui'
import { ACHDetails } from '../settings/billing/ACHDetails'

import * as yup from 'yup'
import { useMutation, useQuery } from '@apollo/client'
import {
  CreateMerchantAchPaymentMethodDocument,
  OnboardingCreatePayoutMethodDocument,
  SelfDocument,
} from '../../operations-types'
import { useState } from 'react'
import { PaymentMethod } from '../ap/components/SendPaymentRouter'
import { ClientOrg } from './addClientStore'
import { QBOChartOfAccountsModal } from '../erp/QBOChartofAccounts'

const ClientCompanyInformation = yup.object({
  routingNumber: yup.string().required('Routing number is required'),
  holderName: yup.string().required('Holder name is required'),
  accountNumber: yup.string().required('Account number is required'),
  confirmAccountNumber: yup.string().required('Account number is required'),
})

type BankAccountProps = {
  clientOrg: ClientOrg
  onFinish: () => void
}

export function BankAccount({ clientOrg, onFinish }: BankAccountProps) {
  const { data } = useQuery(SelfDocument)
  const isDirectQbo =
    data?.user?.user?.organization?.accountInfo?.quickbooksCompanyId
  const [qboChartOpen, setQboChartOpen] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>(
    undefined,
  )
  const [payoutMethodId, setPayoutMethodId] = useState<string | undefined>(
    undefined,
  )
  const [createMerchantACHPaymentMethod, { loading }] = useMutation(
    CreateMerchantAchPaymentMethodDocument,
    {
      onCompleted: (data) => {
        if (data.createMerchantACHPaymentMethod?.paymentMethod) {
          setPaymentMethod(data.createMerchantACHPaymentMethod.paymentMethod)
        }
      },
    },
  )

  const [createOnboardingPayoutMethod, { loading: payoutLoading }] =
    useMutation(OnboardingCreatePayoutMethodDocument, {
      onCompleted: (data) => {
        if (data.onboardingCreatePayoutMethod?.payoutMethod?.id) {
          setPayoutMethodId(data.onboardingCreatePayoutMethod.payoutMethod.id)
        }
      },
    })

  return (
    <Flex width="540px" flexDirection="column">
      <QBOChartOfAccountsModal
        isOpen={qboChartOpen}
        setModalOpen={setQboChartOpen}
        type="bankAccount"
        accountString="your bank account."
        onCompleted={onFinish}
        paymentMethodId={paymentMethod?.id}
        payoutMethodId={payoutMethodId}
      />
      <Form
        validationSchema={ClientCompanyInformation}
        initialValues={{
          holderName: '',
          routingNumber: '',
          accountNumber: '',
          confirmAccountNumber: '',
        }}
      >
        {(formik) => {
          const onClick = async () => {
            formik.validateForm()
            formik.setFieldTouched('holderName', true)
            formik.setFieldTouched('routingNumber', true)
            formik.setFieldTouched('accountNumber', true)
            formik.setFieldTouched('confirmAccountNumber', true)

            if (formik.isValid) {
              await createMerchantACHPaymentMethod({
                variables: {
                  accountType: 'checking',
                  routingNumber: formik.values.routingNumber,
                  accountNumber: formik.values.accountNumber,
                },
              })

              await createOnboardingPayoutMethod({
                variables: {
                  accountHolder: formik.values.holderName,
                  routingNumber: formik.values.routingNumber,
                  accountNumber: formik.values.accountNumber,
                  accountType: 'checking',
                },
              })

              if (isDirectQbo) {
                setQboChartOpen(true)
              } else {
                onFinish()
              }
            }
          }
          return (
            <>
              <Card py={8} px={8}>
                <Flex flexDirection="column" gap={4}>
                  <FormikValidatingInput
                    fieldName="holderName"
                    label="Account Holder Name"
                    placeholder="Enter Account Holder Name"
                  />
                  <ACHDetails
                    routingNumber={formik.values.routingNumber}
                    accountNumber={formik.values.accountNumber}
                    confirmAccountNumber={formik.values.confirmAccountNumber}
                    onChangeAccountNumber={(accountNumber: string) =>
                      formik.setFieldValue('accountNumber', accountNumber)
                    }
                    onChangeConfirmAccountNumber={(
                      confirmAccountNumber: string,
                    ) =>
                      formik.setFieldValue(
                        'confirmAccountNumber',
                        confirmAccountNumber,
                      )
                    }
                    onChangeRoutingNumber={(routingNumber: string) =>
                      formik.setFieldValue('routingNumber', routingNumber)
                    }
                  />
                </Flex>
              </Card>
              <Button
                label="Create"
                width="100%"
                type="submit"
                mt={8}
                isLoading={loading || payoutLoading}
                onClick={(e) => {
                  e.preventDefault()
                  onClick()
                }}
              />
            </>
          )
        }}
      </Form>
    </Flex>
  )
}
