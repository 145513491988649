import ContainerProps from '../../types'

const DollarMap = (props: ContainerProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M2.40039 5.55654C2.40039 5.21565 2.59299 4.90401 2.8979 4.75156L6.7979 2.80156C7.05127 2.67487 7.34951 2.67487 7.60288 2.80156L11.5979 4.79907C11.8513 4.92575 12.1495 4.92575 12.4029 4.79907L15.4979 3.25156C16.0963 2.95235 16.8004 3.3875 16.8004 4.05654V14.0441C16.8004 14.385 16.6078 14.6966 16.3029 14.8491L12.4029 16.7991C12.1495 16.9258 11.8513 16.9258 11.5979 16.7991L7.60288 14.8016C7.34951 14.6749 7.05127 14.6749 6.7979 14.8016L3.70288 16.3491C3.10447 16.6483 2.40039 16.2131 2.40039 15.5441V5.55654Z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6L9.99993 10M10 14L9.99993 10M8 12.1212L8.58592 12.5607C9.36693 13.1464 10.6332 13.1464 11.4142 12.5607C12.1953 11.9749 12.1953 11.0251 11.4142 10.4393C11.0237 10.1464 10.5118 9.99997 9.99993 10M9.99993 10C9.51664 10 9.03346 9.8536 8.66473 9.56068C7.92733 8.97489 7.92733 8.02514 8.66473 7.43936C9.40213 6.85357 10.5977 6.85357 11.3351 7.43936L11.6117 7.65908"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DollarMap
