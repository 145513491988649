import Entity, { EntityField } from '../Entity'
import { UnstandardizedColumn } from '../Layout/Column'
import Column from '../v2/layout/Column'
import { OverlayCard } from './OverlayCard'

export type Payout = {
  id?: string
  paidAt?: string | null
  scheduledAt?: string
  chargedAmount?: string
  refundAmount?: string
  feeAmount?: string
}

export type OverlayPayoutsProps = {
  payouts: Payout[]
  onPayoutClick?: (payoutId: string) => void
}

function getPayoutString(payout: Payout) {
  if (payout.paidAt) {
    return {
      title: 'Settled',
      subtitle: `on ${payout.paidAt}`,
    }
  }

  if (payout.scheduledAt) {
    return {
      title: 'Scheduled',
      subtitle: `for ${payout.scheduledAt}`,
    }
  }

  return {
    title: 'Processing',
  }
}

export function OverlayPayout({
  payout,
  onClick,
}: {
  payout: Payout
  onClick?: (payoutId: string) => void
}) {
  const payoutString = getPayoutString(payout)

  const getItem = (chargedAmount?: string, refundAmount?: string) => {
    if (chargedAmount) {
      return {
        itemName: 'Charged',
        itemValue: chargedAmount,
      }
    }

    if (refundAmount) {
      return {
        itemName: 'Refund',
        itemValue: `(${refundAmount})`,
      }
    }

    return undefined
  }

  const getOnClick = (
    payoutId?: string,
    onClick?: (payoutId: string) => void,
  ) => {
    if (payoutId && onClick) {
      return () => onClick(payoutId)
    }

    return undefined
  }

  return (
    <OverlayCard
      boldedText={payoutString.title}
      remainderText={payoutString.subtitle}
      onClick={getOnClick(payout.id, onClick)}
      firstItem={getItem(payout.chargedAmount, payout.refundAmount)}
      secondItem={
        payout.feeAmount
          ? {
              itemName: 'Nickel Fees',
              itemValue: `(${payout.feeAmount})`,
            }
          : undefined
      }
    />
  )
}

export function OverlayEmptyPayout() {
  return (
    <Entity>
      <EntityField title="You don't have any payouts" />
    </Entity>
  )
}

export function OverlayPayouts({
  payouts,
  onPayoutClick,
}: OverlayPayoutsProps) {
  return (
    <UnstandardizedColumn wGrow className="rounded gap-y-2">
      {payouts.map((payout, index) => (
        <Column wGrow gap="small" key={index}>
          <OverlayPayout payout={payout} onClick={onPayoutClick} />
        </Column>
      ))}
    </UnstandardizedColumn>
  )
}
