import { tw } from '@nickeltech/brise'
import {
  Button,
  Column,
  Form,
  OverlayHeaderWithStatus,
  Separator,
  EyeIcon,
  Row,
  OverlayTitle,
  StatusPill,
  PencilSquareIcon,
  HFlex,
  STAGING_ORG_IDS,
} from 'ui'
import ContainerProps from 'ui/src/types'
import {
  Box,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
  Icon,
  Card,
  CardBody,
  useToast,
  Flex,
  Spacer,
  Badge,
  IconButton,
  InputGroup,
  InputRightAddon,
  useClipboard,
  Switch,
  FormLabel,
} from '@chakra-ui/react'
import { createRef, useEffect, useState } from 'react'
import {
  ArrowRightIcon,
  DocumentTextIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import * as yup from 'yup'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  AnswerFormsDocument,
  DownloadInvoiceDocument,
  QuestionFormDocument,
  QuestionFormStatus,
  RecipientType,
  RemoveDocumentFromQuestionFormDocument,
  RemoveQuestionFormDocument,
  ResendPaymentLinkToRecipientDocument,
  UpdateQuestionFormDocument,
  UpdateRecipientsDocument,
} from '../../operations-types'
import currency from 'currency.js'
import moment from 'moment'
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { FieldArray } from 'formik'
import { ContactInputFields, RecipientRow } from './PaymentLinkSuccessOverlay'
import { PaymentLinkElements } from './PaymentLinkElements'
import { fetchPortalURL, GET_PAID_URL } from '../../lib/urls'
import {
  FeePassThroughSettings,
  FileUploadDragger,
} from './CreatePaymentLinkOverlay'
import { Document, Page, pdfjs } from 'react-pdf'
import SkeletonLoader from 'ui/src/components/Loaders/SkeletonLoader'
import classNames from 'classnames'
import { lowerCase, capitalize } from 'lodash'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid'
import { downloadInvoiceFile } from '../../lib/utils'
import { usePaymentLinkStore } from '../layout/PaymentLinkStore'
import CurrencyInput from 'react-currency-input-field'

const OverlayHeader = tw.div<ContainerProps>`
  text-lg
  font-bold
  text-gray-800
`

const CreatePaymentLinkSchema = yup.object({
  name: yup.string().required(),
  amount: yup.string().required(),
  amountEditable: yup.boolean(),
  memo: yup.string(),
  fileBuffer: yup.array().of(yup.string()),
  feePassthroughPercent: yup.number().min(0).max(100).optional(),
  contact: yup.array().of(
    yup.object({
      name: yup.string().required(),
      phoneNumber: yup.string().length(12),
      email: yup.string(),
      sentAt: yup.string(),
    }),
  ),
  newContact: yup.object({
    name: yup.string().required(),
    phoneNumber: yup.string().length(12),
    email: yup.string(),
  }),
})

type TabType = 'overview' | 'notifications' | 'advanced'
type CreatePaymentLinkSchemaType = yup.InferType<typeof CreatePaymentLinkSchema>

type PaymentLinkTransactionCardProps = {
  paymentId: string
  submittedAmountInCents: number
  completedAt: string
  status: string
  advance?: boolean
  advanceId?: string
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const getInvoiceLink = (invoiceId: string) => {
  if (import.meta.env.MODE === 'production') {
    return `https://app.qbo.intuit.com/app/invoice?txnId=${invoiceId}`
  } else {
    return `https://app.sandbox.qbo.intuit.com/app/invoice?txnId=${invoiceId}`
  }
}

export const PaymentLinkTransactionCard = ({
  paymentId,
  submittedAmountInCents,
  completedAt,
  status,
  advance = false,
  advanceId,
}: PaymentLinkTransactionCardProps) => {
  const navigate = useNavigate()

  return (
    <Card w="100%" borderColor="gray.300">
      <CardBody w="100%" p="14px">
        <Flex flexDirection="row" w="100%">
          <Flex flexDirection="row" w="60%">
            <Column gap="small">
              <div className="text-xs text-gray-500">Amount</div>
              <div className="text-sm text-gray-900 text-semibold">
                {currency(submittedAmountInCents || 0, {
                  fromCents: true,
                }).format()}
              </div>
            </Column>
            <Spacer />
            <Column gap="small">
              <div className="text-xs text-gray-500">Initiated On</div>
              <div className="text-sm text-gray-900 text-semibold">
                {moment(parseInt(completedAt)).format('MM/DD/YYYY') ?? ''}
              </div>
            </Column>
            <Spacer />
            <Column gap="small">
              <div className="text-xs text-gray-500">Status</div>
              <StatusPill
                status={
                  status === 'SUCCESS'
                    ? 'success'
                    : status === 'FAILED'
                    ? 'error'
                    : 'info'
                }
                spacing="xsmallNarrow"
              >
                {status === 'PENDING' ? 'PROCESSING' : status}
              </StatusPill>
            </Column>
          </Flex>
          <Spacer />
          <Row
            gap="small"
            y="center"
            className="cursor-pointer"
            onClick={() => {
              if (advance) {
                navigate(`/dashboard/transactions/advance/${advanceId}`)
              } else {
                navigate(`/dashboard/transactions/receivable/${paymentId}`)
              }
            }}
          >
            <div className="text-sm whitespace-nowrap">{`${
              advance ? 'View Advance' : 'View Payment'
            }`}</div>
            <ArrowRightIcon className="h-4 w-4 text-gray-800" />
          </Row>
        </Flex>
      </CardBody>
    </Card>
  )
}
const PaymentLinkCaption = tw.div<ContainerProps>`
  text-gray-500
  text-xs
  font-normal
  !w-[300px]
  whitespace-nowrap
`

const PaymentLinkValue = tw.div<ContainerProps>`
  text-[13px]
  text-gray-800
  font-medium
  whitespace-nowrap
`

function PaymentLinkOverlay() {
  const [searchParams] = useSearchParams()

  const navigatedTab = searchParams.get('tab') as TabType

  const { questionFormId } = useParams() as { questionFormId: string }
  const { data, loading: questionLoading } = useQuery(QuestionFormDocument, {
    variables: { questionFormId: questionFormId },
  })
  const [tab, setTab] = useState<TabType>(navigatedTab || 'overview')
  const ref = createRef<HTMLCanvasElement>()
  const [file, setFile] = useState<string | ArrayBuffer | null>(null)
  const [documentBuffer, setDocumentBuffer] = useState<string[]>([])

  const navigate = useNavigate()
  const currentQuestionForm = data?.questionForm?.questionForm

  const currentSchema = JSON.parse(currentQuestionForm?.schema || '{}')

  const { data: answerFormData } = useQuery(AnswerFormsDocument, {
    variables: {
      questionFormId: questionFormId,
    },
  })

  const successfulAnswerForms =
    answerFormData?.answerForms?.answerForms?.filter(
      (answerForm) =>
        answerForm?.payment?.activity?.status === 'PENDING' ||
        answerForm.payment?.activity?.status === 'COMPLETED' ||
        answerForm.payment?.activity?.status === 'FAILED',
    )

  const toaster = useToast()

  const [updateQuestion] = useMutation(UpdateQuestionFormDocument, {
    onCompleted: () => {
      toaster({
        status: 'success',
        title: `Updated Payment Link`,
      })
    },
    onError: () => {
      toaster({
        status: 'error',
        title: `Error Updating Payment Link`,
      })
    },
    refetchQueries: [
      'questionForms',
      {
        query: QuestionFormDocument,
        variables: { questionFormId: questionFormId },
      },
    ],
  })

  const [removeQuestion] = useMutation(RemoveQuestionFormDocument, {
    onCompleted: () => {
      toaster({
        status: 'success',
        title: `Payment link deleted`,
      })
    },
    onError: () => {
      toaster({
        status: 'error',
        title: `Error Removing Payment Link`,
      })
    },
    refetchQueries: ['questionForms'],
  })

  const [addRecipient] = useMutation(UpdateRecipientsDocument, {
    onCompleted: () => {
      toaster({
        status: 'success',
        title: `Payment link sent!`,
      })
    },
    refetchQueries: [
      {
        query: QuestionFormDocument,
        variables: {
          questionFormId: questionFormId,
        },
      },
    ],
  })

  const [resendPaymentLinktoRecipient] = useMutation(
    ResendPaymentLinkToRecipientDocument,
    {
      onCompleted: () => {
        toaster({
          title: 'Payment link sent!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    },
  )

  const { subdomain } = useOutletContext() as {
    subdomain: string
  }

  const organizationSubdomain = subdomain

  const { onCopy, value, hasCopied } = useClipboard(
    `${fetchPortalURL(organizationSubdomain || 'nickel')}/pay/${data
      ?.questionForm?.questionForm?.checkoutPath}`,
  )

  const [editingAmount, setEditingAmount] = useState(false)
  const [editingName, setEditingName] = useState(false)
  const [editingMemo, setEditingMemo] = useState(false)
  const [editingFee, setEditingFee] = useState(false)
  const [editingAmountEditable, setEditingAmountEditable] = useState(false)
  const [passthroughActive, setPassthroughActive] = useState(
    currentQuestionForm?.feePassthroughPercent !== null &&
      currentQuestionForm?.feePassthroughPercent !== undefined,
  )

  useEffect(() => {
    setPassthroughActive(
      currentQuestionForm?.feePassthroughPercent !== null &&
        currentQuestionForm?.feePassthroughPercent !== undefined,
    )
  }, [currentQuestionForm?.feePassthroughPercent])

  const [removeDocument] = useMutation(RemoveDocumentFromQuestionFormDocument, {
    onCompleted: () => {
      toaster({
        status: 'success',
        title: `Document removed`,
      })
    },
    refetchQueries: [
      {
        query: QuestionFormDocument,
        variables: {
          questionFormId: questionFormId,
        },
      },
    ],
  })

  const { user } = useLoggedInStore()

  const [downloadInvoice, { loading }] = useLazyQuery(DownloadInvoiceDocument, {
    variables: {
      questionFormId: currentQuestionForm?.id || '',
    },
  })

  const downloadPDF = async () => {
    await downloadInvoiceFile(
      downloadInvoice,
      () => {
        toaster({
          status: 'success',
          title: 'Invoice downloaded',
        })
      },
      () => {
        toaster({
          status: 'error',
          title: 'Error downloading invoice',
        })
      },
    )
  }

  const { setPaymentLinks } = usePaymentLinkStore()

  const toast = useToast()

  useEffect(() => {
    toast.closeAll()
    setPaymentLinks([])
  }, [])

  const activeLoan = currentQuestionForm?.loans?.find(
    (loan) => loan.funded === true,
  )

  return (
    <>
      <Form<CreatePaymentLinkSchemaType>
        {...{
          className: 'w-full h-full flex flex-col',
          initialValues: {
            amount: currency(currentQuestionForm?.requestedAmount || 0, {
              fromCents: true,
            }).toString(),
            amountEditable: currentSchema?.amountEditable || false,
            name: currentQuestionForm?.name || '',
            memo: currentQuestionForm?.memo || '',
            fileBuffer: [],
            feePassthroughPercent:
              currentQuestionForm?.feePassthroughPercent ?? 100,
            contact:
              currentQuestionForm?.recipients?.map((r) => ({
                name: r.name || '',
                phoneNumber: r.phoneNumber || '',
                email: r.email || '',
                sentAt: r.sentAt || '',
              })) || [],
            newContact: {
              name:
                currentQuestionForm?.invoice?.customerRef?.customerName || '',
              phoneNumber: '',
              email:
                currentQuestionForm?.invoice?.customerRef?.emailAddress || '',
            },
          },
        }}
      >
        {(formik) => {
          const paymentNode = (
            <>
              {questionLoading ? (
                <Column wGrow gap="small" spacing="large">
                  <SkeletonLoader className="!w-full" />
                  <SkeletonLoader className="!w-[300px]" />
                  <SkeletonLoader className="!w-[300px]" />
                  <SkeletonLoader className="!w-[300px]" />
                  <SkeletonLoader className="!w-[200px]" />
                </Column>
              ) : (
                <Column wGrow>
                  <Column wGrow className="pt-2 pb-4 gap-3">
                    <div className="text-xl font-semibold">Request Details</div>

                    {editingAmount && (
                      <VStack w="100%" gap="0" spacing="0" alignItems="left">
                        <HStack>
                          <CurrencyInput
                            prefix="$"
                            style={{
                              width: '100%',
                              background: 'transparent',
                              fontSize: 'var(--chakra-fontSizes-3xl)',
                              outline: 'none',
                              fontWeight: '500',
                            }}
                            value={formik.values.amount || undefined}
                            placeholder="$0"
                            onValueChange={(value) =>
                              formik.setFieldValue('amount', value)
                            }
                          />
                          {editingAmount ? (
                            <Row gap="small" y="center">
                              <Button
                                variant={'ghost'}
                                label="Cancel"
                                className="!text-sm !py-1 !h-[30px]"
                                onClick={() => setEditingAmount(!editingAmount)}
                              />
                              <Button
                                variant={'solid'}
                                label="Save"
                                {...{
                                  onClick: async () => {
                                    const data = await updateQuestion({
                                      variables: {
                                        questionFormId: questionFormId,

                                        requestedAmount: currency(
                                          formik.values.amount,
                                        ).intValue,
                                      },
                                    })

                                    if (!data.data?.updateQuestionForm?.error) {
                                      setEditingAmount(!editingAmount)
                                    }
                                  },
                                }}
                                className="!text-sm !py-1 !h-[30px]"
                              />
                            </Row>
                          ) : (
                            ''
                          )}
                        </HStack>
                      </VStack>
                    )}
                    {!editingAmount && (
                      <Row grow y="top">
                        <PaymentLinkCaption>
                          Amount Requested
                        </PaymentLinkCaption>
                        <Column wGrow>
                          <HStack>
                            <PaymentLinkValue>
                              {currency(
                                currentQuestionForm?.requestedAmount || 0,
                                {
                                  fromCents: true,
                                },
                              ).format()}
                            </PaymentLinkValue>
                            {!currentQuestionForm?.invoice && !activeLoan && (
                              <PencilSquareIcon
                                {...{
                                  name: 'pencilSquare',
                                  className: 'cursor-pointer w-5 h-5',
                                  onClick: () =>
                                    setEditingAmount(!editingAmount),
                                }}
                              />
                            )}
                          </HStack>
                        </Column>
                      </Row>
                    )}

                    <Row grow y="top">
                      <PaymentLinkCaption>Amount Received</PaymentLinkCaption>
                      <Column wGrow>
                        <PaymentLinkValue>
                          {currency(
                            currentQuestionForm?.completedAmountInCents || 0,
                            {
                              fromCents: true,
                            },
                          ).format()}
                        </PaymentLinkValue>
                      </Column>
                    </Row>
                    <Row grow y="top">
                      <PaymentLinkCaption>Created On</PaymentLinkCaption>
                      <Column wGrow>
                        <PaymentLinkValue>
                          {moment(
                            parseInt(currentQuestionForm?.createdAt || ''),
                          ).format('MM/DD/YYYY') ?? ''}
                        </PaymentLinkValue>
                      </Column>
                    </Row>
                    {currentQuestionForm?.dueDate && (
                      <Row grow y="top">
                        <PaymentLinkCaption>Due On</PaymentLinkCaption>
                        <Column wGrow>
                          <PaymentLinkValue>
                            {moment(currentQuestionForm?.dueDate || '').format(
                              'MM/DD/YYYY',
                            ) ?? ''}
                          </PaymentLinkValue>
                        </Column>
                      </Row>
                    )}
                  </Column>
                </Column>
              )}

              <VStack w="100%" alignItems="start" gap="8" spacing="8" pt="4">
                <VStack alignItems="start" spacing="0" gap="0" w="100%">
                  <div className="text-xl font-semibold">Customer Memo</div>
                  <div className="text-xs pb-4">
                    Add a custom message on the payments page and emails that we
                    send
                  </div>
                  <Input
                    as={Textarea}
                    name="memo"
                    placeholder="Descriptive memo or note for the payment"
                    onChange={async (e: any) => {
                      await formik.handleChange(e)
                      await setEditingMemo(true)
                    }}
                    value={formik.values.memo}
                  />
                  {editingMemo ? (
                    <Row gap="small" y="center" className="pt-2">
                      <Button
                        variant={'ghost'}
                        label="Cancel"
                        className="!text-sm !py-1 !h-[30px]"
                        onClick={async () => {
                          setEditingMemo(!editingMemo)
                          await formik.setFieldValue(
                            'memo',
                            currentQuestionForm?.memo,
                          )
                          await formik.setFieldTouched('memo', false)
                        }}
                      />
                      <Button
                        variant={'solid'}
                        label="Save"
                        {...{
                          onClick: async () => {
                            const data = await updateQuestion({
                              variables: {
                                questionFormId: questionFormId,
                                memo: formik.values.memo,
                              },
                            })

                            if (!data.data?.updateQuestionForm?.error) {
                              setEditingMemo(!editingMemo)
                            }
                          },
                        }}
                        className="!text-sm !py-1 !h-[30px]"
                      />
                    </Row>
                  ) : (
                    ''
                  )}
                </VStack>
                <VStack alignItems="left" spacing="0" gap="0" w="100%">
                  <div className="text-xl font-semibold">Attach File</div>
                  <div className="text-xs pb-4">
                    Add files to the payments page and emails that we send
                  </div>

                  {currentQuestionForm?.documents?.map((buffer, index) => (
                    <Flex
                      key={index}
                      flexDirection="row"
                      gap="0"
                      w="100%"
                      p="2"
                    >
                      <Icon as={DocumentTextIcon} boxSize="5" />
                      <Text ps="2">{`File ${index + 1}`}</Text>
                      <Spacer />
                      <Badge
                        p="1"
                        colorScheme="green"
                        color="green.500"
                        fontWeight="medium"
                      >
                        Uploaded
                      </Badge>
                      <IconButton
                        as={EyeIcon}
                        boxSize="5"
                        variant="ghost"
                        aria-label="View Document"
                        alignSelf="center"
                        justifySelf="center"
                        onClick={() => {
                          window.open(
                            currentQuestionForm?.documents![index],
                            '_blank',
                          )
                        }}
                      />
                      <IconButton
                        as={TrashIcon}
                        boxSize="5"
                        variant="ghost"
                        aria-label="Remove Document"
                        display={
                          index === 0 &&
                          STAGING_ORG_IDS.includes(user.organization.id)
                            ? 'none'
                            : 'block'
                        }
                        alignSelf="center"
                        justifySelf="center"
                        onClick={() => {
                          removeDocument({
                            variables: {
                              questionFormId: questionFormId,
                              documentIndex: index,
                            },
                          })
                        }}
                      />
                    </Flex>
                  ))}
                  <Box pt="2">
                    <FieldArray
                      name="fileBuffer"
                      render={() => (
                        <>
                          <FileUploadDragger
                            {...{
                              accept:
                                '.pdf,.doc,.docx,.xls,.xlsx,.csv,.png,.jpg,.jpeg',
                              onFile: async (e) => {
                                await setFile(e.data)
                                await setDocumentBuffer(
                                  [e.data].filter(
                                    (buffer): buffer is string =>
                                      typeof buffer === 'string' &&
                                      buffer.trim() !== '',
                                  ) ?? [],
                                )
                              },
                            }}
                          />
                        </>
                      )}
                    />
                  </Box>
                  <Box display="none">
                    <Document file={file}>
                      <Page
                        pageNumber={1}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        width={80}
                        height={100}
                        canvasRef={ref}
                        onRenderSuccess={async () => {
                          await updateQuestion({
                            variables: {
                              questionFormId: questionFormId,
                              documentBuffers: documentBuffer,
                              thumbnail:
                                ref.current?.toDataURL('image/png') ?? '',
                            },
                          })
                        }}
                      />
                    </Document>
                  </Box>
                </VStack>
              </VStack>
            </>
          )

          const advancedNode = (
            <VStack spacing="4" gap="4" alignItems="left" w="100%" py="4">
              <VStack spacing="0" gap="0" alignItems="left" w="100%">
                <OverlayHeader>Payment Settings</OverlayHeader>
                <Text fontSize="sm" color="gray.500">
                  Customers will see this information on the payments page and
                  in corresponding emails that we send.
                </Text>
              </VStack>

              <Card m="0" p="0">
                <CardBody p="4">
                  <HStack spacing="0" gap="0" w="100%">
                    <VStack alignItems="start" spacing="0" gap="0" w="100%">
                      <Text fontSize="sm" fontWeight="medium">
                        Allow customers to edit the payment amount
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        Enable this option to allow customers to make multiple
                        payments or short pay
                      </Text>
                    </VStack>
                    <Switch
                      name="amountEditable"
                      isChecked={formik.values.amountEditable}
                      onChange={() => {
                        formik.setFieldTouched('amountEditable', true)
                        setEditingAmountEditable(!editingAmountEditable)
                        formik.setFieldValue(
                          'amountEditable',
                          !formik.values.amountEditable,
                        )
                      }}
                    />
                  </HStack>
                </CardBody>
              </Card>

              <FeePassThroughSettings
                {...{
                  inputId: 'feePassthroughPercent',
                  setEditingFee,
                  updateQuestion,
                  questionFormId,
                  passthroughActive,
                  setPassthroughActive,
                  error: formik.errors.feePassthroughPercent,
                  onSwitchClick: () => {
                    formik.setFieldTouched('feePassthroughPercent', true)
                    setEditingFee(true)
                  },
                  handleFeeChange: (e) => {
                    formik.setFieldValue(
                      'feePassthroughPercent',
                      parseInt(e.target.value),
                    )
                    formik.setFieldTouched('feePassthroughPercent', true)
                    setEditingFee(true)
                  },
                  feePassthroughPercent: formik.values.feePassthroughPercent,
                }}
              />
              <HStack
                justifyContent="end"
                w="100%"
                display={editingAmountEditable || editingFee ? 'flex' : 'none'}
              >
                <Button
                  variant={'ghost'}
                  label="Cancel"
                  className="!text-sm !py-1 !h-[30px]"
                  onClick={() => {
                    formik.setFieldValue(
                      'amountEditable',
                      currentSchema.amountEditable,
                    )
                    formik.setFieldValue(
                      'feePassthroughPercent',
                      currentQuestionForm?.feePassthroughPercent,
                    )
                    setEditingFee(false)
                    setEditingAmountEditable(false)
                    setPassthroughActive(
                      currentQuestionForm?.feePassthroughPercent !== null &&
                        currentQuestionForm?.feePassthroughPercent !==
                          undefined,
                    )
                  }}
                />
                <Button
                  variant={'solid'}
                  label="Save"
                  {...{
                    onClick: async () => {
                      const data = await updateQuestion({
                        variables: {
                          questionFormId: questionFormId,
                          schema: JSON.stringify({
                            amountEditable: formik.values.amountEditable,
                          }),
                          feePassthroughPercent: passthroughActive
                            ? formik.values.feePassthroughPercent
                            : null,
                        },
                      })

                      if (!data.data?.updateQuestionForm?.error) {
                        setEditingFee(false)
                        setEditingAmountEditable(false)
                      }
                    },
                  }}
                  className="!text-sm !py-1 !h-[30px]"
                />
              </HStack>
              {STAGING_ORG_IDS.includes(user.organization.id) && (
                <VStack spacing="0" gap="0" alignItems="left" w="100%">
                  <OverlayHeader>Factor Invoice</OverlayHeader>
                  <Text fontSize="sm" color="gray.500">
                    Factor this invoice and get 95% of the invoice amount
                    advanced to you. The fee will be deducted from the final
                    payment from your customer.
                  </Text>
                  <Card my="4">
                    <CardBody>
                      <HFlex alignItems="center">
                        <HFlex gap={6}>
                          <VStack gap="0" spacing="0" alignItems="Start" p="0">
                            <FormLabel
                              color="gray.800"
                              p="0"
                              m="0"
                              fontSize="xs"
                            >
                              Fee
                            </FormLabel>
                            <Text p="0" color="gray.800" fontWeight="500">
                              {currency(activeLoan?.feeAmountInCents || 0, {
                                fromCents: true,
                              }).format()}
                            </Text>
                          </VStack>
                          <Spacer />
                          <VStack gap="0" spacing="0" alignItems="Start" p="0">
                            <FormLabel
                              color="gray.800"
                              p="0"
                              m="0"
                              fontSize="xs"
                            >
                              Due Date
                            </FormLabel>
                            <Text p="0" color="gray.800" fontWeight="500">
                              {moment(
                                parseInt(activeLoan?.dueDate || ''),
                              ).format('MMM DD, YYYY')}
                            </Text>
                          </VStack>
                        </HFlex>
                        <Spacer />
                        {activeLoan?.status ? (
                          <StatusPill status={'success'} spacing="xsmallNarrow">
                            {capitalize(lowerCase(activeLoan?.status || ''))}
                          </StatusPill>
                        ) : (
                          ''
                        )}
                      </HFlex>
                    </CardBody>
                  </Card>
                </VStack>
              )}
            </VStack>
          )

          const notificationsNode = (
            <VStack w="100%" gap="0" spacing="0" alignItems="left">
              <HStack w="100%" gap="0" spacing="0">
                <VStack spacing="0" gap="0" alignItems="left" w="100%">
                  <OverlayHeader>Add your contacts</OverlayHeader>
                  <Text fontSize="sm" color="gray.500">
                    Add as many emails and phone numbers as you'd like, and
                    we'll automatically send them this payment link
                  </Text>
                </VStack>
              </HStack>
              <FieldArray
                name="contact"
                render={(vals) => (
                  <Column wGrow gap="small" className="py-5">
                    <ContactInputFields
                      recipientInputName="newContact.name"
                      emailInputName="newContact.email"
                      phoneNumberInputName="newContact.phoneNumber"
                    />
                    <InputGroup pt="2" className="w-full">
                      <Button
                        onClick={async () => {
                          vals.push({
                            name: formik.values.newContact.name,
                            email: formik.values.newContact.email,
                            phoneNumber: formik.values.newContact.phoneNumber,
                            sentAt: new Date().getTime().toString(),
                          })

                          formik.setFieldValue('newContact', {
                            name: '',
                            phoneNumber: '',
                            email: '',
                          })

                          await addRecipient({
                            variables: {
                              questionFormId: questionFormId,
                              recipients: formik.values.newContact,
                            },
                          })
                        }}
                        _hover={
                          !formik.values.newContact.name ||
                          (!formik.values.newContact.phoneNumber &&
                            !formik.values.newContact.email)
                            ? {}
                            : {
                                cursor: 'pointer',
                                bgColor: 'dark.700',
                              }
                        }
                        className="w-full"
                        label="Send Link"
                        isDisabled={
                          !formik.values.newContact.name ||
                          (!formik.values.newContact.phoneNumber &&
                            !formik.values.newContact.email)
                        }
                      />
                    </InputGroup>
                    <Column spacing="none" wGrow gap="small">
                      {(formik.values.contact || []).map(
                        (contact: RecipientType, index) => (
                          <RecipientRow
                            {...{
                              key: index,
                              recipient: contact,
                              onResend: async () => {
                                await resendPaymentLinktoRecipient({
                                  variables: {
                                    questionFormId: questionFormId,
                                    recipient: {
                                      name: contact.name || '',
                                      email: contact.email || '',
                                      phoneNumber: contact.phoneNumber || '',
                                    },
                                  },
                                })
                              },
                            }}
                          />
                        ),
                      )}
                    </Column>
                  </Column>
                )}
              />
            </VStack>
          )

          const transactionsNode = (
            <VStack>
              <Column className="w-full h-full overflow-scroll">
                <Box w="100%">
                  <div className="text-xl font-semibold pb-2">Transactions</div>
                  {successfulAnswerForms?.length === 0 && (
                    <Box bgColor="gray.100">
                      <Text w="100%" p="2" fontSize="xs">
                        You haven't received any payments
                      </Text>
                    </Box>
                  )}
                  {successfulAnswerForms &&
                    successfulAnswerForms.map((answerFormData, key) => {
                      return (
                        <Box py={2} key={key}>
                          <PaymentLinkTransactionCard
                            {...{
                              paymentId: answerFormData?.payment?.id!,
                              submittedAmountInCents:
                                answerFormData?.payment
                                  ?.submittedAmountInCents! -
                                (activeLoan?.feeAmountInCents || 0) -
                                (activeLoan?.principalAmountInCents || 0),
                              completedAt: answerFormData?.payment?.createdAt!,
                              status: answerFormData.payment?.activity?.status!,
                            }}
                          />
                        </Box>
                      )
                    })}
                </Box>
              </Column>
              {activeLoan && (
                <Column className="w-full h-full overflow-scroll">
                  <Box w="100%">
                    <div className="text-xl font-semibold pb-2">Advances</div>
                    {successfulAnswerForms?.length === 0 && (
                      <Box bgColor="gray.100">
                        <Text w="100%" p="2" fontSize="xs">
                          You haven't received any advances
                        </Text>
                      </Box>
                    )}

                    <Box py={2}>
                      <PaymentLinkTransactionCard
                        {...{
                          paymentId: activeLoan.id,
                          submittedAmountInCents:
                            activeLoan?.principalAmountInCents || 0,
                          completedAt: activeLoan.issueDate || '',
                          status: activeLoan.payout?.paidAt
                            ? 'SUCCESS'
                            : 'PENDING',
                          advance: true,
                          advanceId: activeLoan.id,
                        }}
                      />
                    </Box>
                  </Box>
                </Column>
              )}
            </VStack>
          )

          return (
            <>
              <OverlayHeaderWithStatus onClose={() => navigate(GET_PAID_URL)}>
                {questionLoading ? (
                  <SkeletonLoader className="!w-[200px]" />
                ) : (
                  <StatusPill
                    status={
                      ['COMPLETED', 'ACTIVE'].includes(
                        currentQuestionForm?.status || '',
                      )
                        ? 'success'
                        : currentQuestionForm?.status === 'ARCHIVED'
                        ? 'error'
                        : 'action'
                    }
                    spacing="xsmallNarrow"
                  >
                    {capitalize(lowerCase(currentQuestionForm?.status || ''))}
                  </StatusPill>
                )}
              </OverlayHeaderWithStatus>
              <Separator orientation="horizontal" />
              <Column wGrow grow overflowHidden>
                <Column className="w-full overflow-y-scroll">
                  <Row
                    grow
                    y="center"
                    spacing="large"
                    className={classNames('py-4 !h-fit', {
                      hidden: !currentQuestionForm?.invoice,
                    })}
                  >
                    {questionLoading ? (
                      <SkeletonLoader className="!w-[200px]" />
                    ) : (
                      <Column wGrow>
                        <div className="text-sm font-medium text-black">
                          Synced with Quickbooks Online
                        </div>
                        <Row gap="xsmall">
                          <a
                            className="text-purple-600 text-xs cursor-pointer"
                            target={'_blank'}
                            rel="noreferrer"
                            href={getInvoiceLink(
                              currentQuestionForm?.invoice?.externalId || '',
                            )}
                          >
                            View in Quickbooks
                          </a>
                          <ArrowRightIcon className="h-4 w-4 text-purple-500" />
                        </Row>
                      </Column>
                    )}
                  </Row>
                  <Separator orientation="horizontal" />
                  <Row className="w-full" y="center" between>
                    {questionLoading ? (
                      <Row spacing="large">
                        <SkeletonLoader className="!w-[200px]" />
                      </Row>
                    ) : (
                      <VStack
                        w="100%"
                        alignItems="start"
                        spacing="0"
                        gap="0"
                        py="4"
                      >
                        <HStack w="100%">
                          {editingName ? (
                            <VStack
                              alignItems="left"
                              spacing="0"
                              gap="0"
                              mx="8"
                              w="100%"
                            >
                              <Flex flexDirection="row" w="100%" py="2" px="0">
                                <Input
                                  name="name"
                                  placeholder="Unique name or reference number"
                                  onChange={formik.handleChange}
                                  value={formik.values.name}
                                />
                                <Spacer pe="2" />
                                <Button
                                  variant={'ghost'}
                                  label="Cancel"
                                  className="!text-sm !py-1 !h-[30px]"
                                  onClick={() => setEditingName(!editingName)}
                                />
                                <Button
                                  variant={'solid'}
                                  label="Save"
                                  {...{
                                    onClick: async () => {
                                      const data = await updateQuestion({
                                        variables: {
                                          questionFormId: questionFormId,
                                          name: formik.values.name,
                                        },
                                      })

                                      if (
                                        !data.data?.updateQuestionForm?.error
                                      ) {
                                        setEditingName(!editingName)
                                      }
                                    },
                                  }}
                                  className="!text-sm !py-1 !h-[30px]"
                                />
                              </Flex>
                            </VStack>
                          ) : (
                            <Row grow between y="center">
                              <Row grow gap="small" y="center">
                                <OverlayTitle
                                  className="py-0 ml-8"
                                  title={currentQuestionForm?.name || ''}
                                  subtitle={''}
                                />
                                <PencilSquareIcon
                                  name="pencilSquare"
                                  className="cursor-pointer w-5 h-5 "
                                  onClick={() => setEditingName(!editingName)}
                                />
                              </Row>

                              {user.organization.accountInfo
                                ?.quickbooksCompanyId && (
                                <DocumentArrowDownIcon
                                  {...{
                                    onClick: async () => {
                                      await downloadPDF()
                                    },
                                    className: `cursor-pointer w-8 h-8 !fill-gray-400 relative right-[20px] top-[5px] ${
                                      loading
                                        ? 'opacity-50 pointer-events-none'
                                        : ''
                                    }`,
                                  }}
                                />
                              )}
                            </Row>
                          )}
                        </HStack>
                        <InputGroup px="7">
                          <VStack spacing="0" gap="0" w="100%">
                            <HStack w="100%">
                              <InputGroup
                                size="xs"
                                w="60%"
                                bgColor="gray.100"
                                border="0px transparent"
                                borderRadius="4px"
                              >
                                <Input
                                  value={value}
                                  isReadOnly
                                  color="gray.600"
                                  cursor={'pointer'}
                                  onClick={onCopy}
                                  _hover={{}}
                                />
                                <InputRightAddon
                                  onClick={onCopy}
                                  _hover={{
                                    cursor: 'pointer',
                                    bgColor: 'gray.200',
                                  }}
                                >
                                  {hasCopied ? 'Copied!' : 'Copy'}
                                </InputRightAddon>
                              </InputGroup>
                            </HStack>
                          </VStack>
                        </InputGroup>
                      </VStack>
                    )}
                  </Row>
                  <PaymentLinkElements
                    tabIndex={
                      tab === 'overview' ? 0 : tab === 'advanced' ? 1 : 2
                    }
                    onClick={(idx) => {
                      if (idx === 0) {
                        setTab('overview')
                      } else if (idx === 1) {
                        setTab('advanced')
                      } else if (idx === 2) {
                        setTab('notifications')
                      }
                    }}
                    nodes={[
                      {
                        label: 'Overview',
                        node: paymentNode,
                      },
                      {
                        label: 'Advanced',
                        node: advancedNode,
                      },
                      {
                        label: 'Send Requests',
                        node: notificationsNode,
                      },
                      { label: 'Transactions', node: transactionsNode },
                    ]}
                  />
                </Column>
              </Column>
              {tab === 'overview' && formik.values && (
                <Box w="100%" mt="auto">
                  <Separator orientation="horizontal" />
                  <Flex
                    flexDirection="row"
                    alignContent="end"
                    justifyContent="end"
                    w="100%"
                    p="4"
                    gap="2"
                  >
                    <Button
                      label={
                        successfulAnswerForms?.length === 0
                          ? 'Delete'
                          : 'Archive'
                      }
                      variant="ghost"
                      display={
                        currentQuestionForm?.status === 'ARCHIVED' ||
                        currentQuestionForm?.invoice
                          ? 'none'
                          : ''
                      }
                      {...{
                        onClick: async () => {
                          const data = await removeQuestion({
                            variables: {
                              questionFormId: questionFormId,
                            },
                          })

                          if (!data.data?.removeQuestionForm?.error) {
                            navigate(GET_PAID_URL)
                          }
                        },
                      }}
                    />
                    <Button
                      label="Mark as Paid"
                      display={
                        currentQuestionForm?.status === 'COMPLETED'
                          ? 'none'
                          : ''
                      }
                      {...{
                        onClick: async () => {
                          const data = await updateQuestion({
                            variables: {
                              questionFormId: questionFormId,
                              checkoutActive: true,
                              status: QuestionFormStatus.Completed,
                            },
                          })

                          if (!data.data?.updateQuestionForm?.error) {
                            navigate(GET_PAID_URL)
                          }
                        },
                      }}
                    />
                  </Flex>
                </Box>
              )}
            </>
          )
        }}
      </Form>
    </>
  )
}

export default PaymentLinkOverlay
