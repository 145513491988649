import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const input = definePartsStyle({
  body: {
    px: 4,
    py: 2,
  },
  header: {
    py: 3,
    px: 4,
    bg: 'gray.100',
    borderRadius: 'md',
    fontWeight: 'normal',
  },
})

export const modalTheme = defineMultiStyleConfig({
  variants: { input },
})
