import { useQuery } from '@apollo/client'
import currency from 'currency.js'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BillDocument,
  GetAllMerchantPaymentMethodsDocument,
} from '../../operations-types'
import Recipient from './components/Recipient'
import ReviewSendPayment from './components/ReviewSendPayment'
import { PaymentAmount } from './components/send-money/PaymentAmount'
import { SendMoneyLayout } from './components/send-money/SendMoneyLayout'
import { SendMoneyPage } from './components/send-money/types'
import { DashboardChoosePaymentMethod } from './components/DashboardChoosePaymentMethod'
import {
  useSendPaymentStore,
  Bill as BillFromStore,
} from './components/SendPaymentRouter'
import SendVendorNotification from './components/SendVendorNotification'
import { PAY_VENDORS_BILLS_URL, TRANSACTIONS_URL } from '../../lib/urls'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { Button, Flex } from '@chakra-ui/react'
import { getRoleLabel } from '../settings/Team'
import BillReceipt from './components/BillReceipt'

export function SendMoneyV2() {
  const navigate = useNavigate()
  const user = useLoggedInStore((state) => state.user)
  const {
    savedPaymentMethods,
    sendMoneyAmountResult,
    selectedSavedPaymentMethod,
    paymentSummary,
    recipientDetails,
    initialRecipients,
    approvalPolicy,
    setInitialRecipients,
    setPaymentMethods,
    setSelectedSavedPaymentMethod,
    setPaymentSummary,
    setPage,
    getPage,
    reset,
    setBill,
    setVendor,
  } = useSendPaymentStore((state) => ({
    sendMoneyAmountResult: state.sendMoneyAmountResult!,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    savedPaymentMethods: state.savedPaymentMethods,
    paymentSummary: state.paymentSummary,
    recipientDetails: state.recipientDetails,
    bill: state.bill,
    setBill: state.setBill,
    setPaymentMethods: state.setPaymentMethods,
    setSelectedSavedPaymentMethod: state.setSelectedSavedPaymentMethod,
    initialRecipients: state.initialRecipients,
    setInitialRecipients: state.setInitialRecipients,
    setPaymentSummary: state.setPaymentSummary,
    clearSelectedSavedPaymentMethod: state.clearSelectedSavedPaymentMethod,
    setPage: state.setPage,
    getPage: state.getPage,
    reset: state.reset,
    setVendor: state.setVendor,
    approvalPolicy: state.approvalPolicy,
  }))

  const { data: merchantPaymentMethodsData } = useQuery(
    GetAllMerchantPaymentMethodsDocument,
  )

  useEffect(() => {
    if (
      merchantPaymentMethodsData?.getAllMerchantPaymentMethods?.paymentMethods
    ) {
      setPaymentMethods(
        merchantPaymentMethodsData.getAllMerchantPaymentMethods.paymentMethods,
      )
    }
  }, [merchantPaymentMethodsData, setPaymentMethods])

  const { billId } = useParams()

  const { loading } = useQuery(BillDocument, {
    variables: {
      billId: billId,
    },
    onCompleted: (data) => {
      if (data.bill?.bill) {
        setBill(data.bill.bill as BillFromStore)
        setVendor(data.bill.bill.vendor!)
        setInitialRecipients(data.bill.bill.vendor?.recipients || [])
      }
    },
    skip: !billId,
    // We need network-only here because we need to refetch the bill data and not just use the cache
    fetchPolicy: 'network-only',
  })

  const onGoBackClick = () => {
    if (billId) {
      navigate(PAY_VENDORS_BILLS_URL)
    } else {
      navigate(TRANSACTIONS_URL)
    }
    reset()
  }

  const approvalStep = approvalPolicy?.approvalSteps?.at(0)
  const components: Record<SendMoneyPage, React.ReactNode> = {
    amount: (
      <SendMoneyLayout
        title={`${user.organization.name} Payment`}
        subtitle="Enter an amount to send and a reason for the payment"
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <PaymentAmount loading={!!billId && loading} />
      </SendMoneyLayout>
    ),
    paymentMethod: (
      <SendMoneyLayout
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
        breakdown={{
          amountCents: sendMoneyAmountResult?.submittedAmountCents || 0,
          feeCents: paymentSummary?.feeTotalCents() || 0,
        }}
        title="Choose Payment Method"
        subtitle="Pay with your linked bank account, or add a credit card to send your payment quicker."
      >
        <DashboardChoosePaymentMethod
          setPage={() => setPage('recipient')}
          savedPaymentMethods={savedPaymentMethods}
          setPaymentMethods={setPaymentMethods}
          sendMoneyAmountResult={sendMoneyAmountResult}
          selectedSavedPaymentMethod={selectedSavedPaymentMethod}
          setSelectedSavedPaymentMethod={setSelectedSavedPaymentMethod}
          paymentSummary={paymentSummary}
          setPaymentSummary={setPaymentSummary}
          achEnabled={true}
          creditCardEnabled={true}
        />
      </SendMoneyLayout>
    ),
    recipient: (
      <SendMoneyLayout
        title="Recipient"
        subtitle="Enter your recipient's information. Your recipient will be notified of payment."
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
        breakdown={{
          amountCents: sendMoneyAmountResult?.submittedAmountCents || 0,
          feeCents: paymentSummary?.feeTotalCents() || 0,
        }}
      >
        <Recipient />
      </SendMoneyLayout>
    ),
    review: (
      <SendMoneyLayout
        title={`Review your payment of ${paymentSummary?.totalString()}`}
        subtitle="Please review the details of your payment."
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
        breakdown={{
          amountCents: sendMoneyAmountResult?.submittedAmountCents || 0,
          feeCents: paymentSummary?.feeTotalCents() || 0,
        }}
      >
        <ReviewSendPayment />
      </SendMoneyLayout>
    ),
    notify: (
      <SendMoneyLayout
        title="Your payment was sent!"
        subtitle={`${
          recipientDetails?.vendorName || 'Your vendor'
        } should receive ${currency(
          sendMoneyAmountResult?.submittedAmountCents || 0,
          { fromCents: true },
        ).format()} in ${
          recipientDetails?.paymentVia === 'ACH' ? '2 to 3' : '5 to 7'
        } business days.`}
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <SendVendorNotification
          {...{
            initialRecipients: initialRecipients || [],
          }}
        />
        <BillReceipt />
      </SendMoneyLayout>
    ),
    approval: (
      <SendMoneyLayout
        title="Pending Approval"
        subtitle={`Your payment is pending approval from ${
          approvalStep?.approver
            ? `${approvalStep?.approver?.firstName} ${approvalStep?.approver?.lastName}`
            : `one of your ${getRoleLabel(approvalStep?.approverRole || '')}s`
        }.`}
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <Flex width="540px" flexDirection="column" gap={6}>
          <Button
            variant="outline"
            onClick={async () => {
              navigate('/dashboard/accounts-payable/approvals')
              reset()
            }}
          >
            Return to Dashboard
          </Button>
        </Flex>
      </SendMoneyLayout>
    ),
  }
  return <>{components[getPage()]}</>
}
