import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Divider,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import currency from 'currency.js'
import { BreadcrumbLinkItem, Icon } from 'ui'
import { SendMoneyPage } from './types'

export type SendMoneyHeaderProps = {
  onGoBackClick: () => void
  breakdown?: BreakdownProps
} & SendMoneyBreadcrumbsProps

type GoBackProps = {
  onClick: () => void
}

type SendMoneyBreadcrumbsProps = {
  currentPage: SendMoneyPage
  onPageChange: (page: SendMoneyPage) => void
}

export function GoBack({ onClick }: GoBackProps) {
  return (
    <HStack onClick={onClick} cursor="pointer">
      <Icon name="xMark" className="stroke-gray-500 stroke-2 !cursor-pointer" />
      <Text color="gray.500" fontSize="md" fontWeight="400">
        Exit
      </Text>
    </HStack>
  )
}

function SendMoneyBreadcrumbs(props: SendMoneyBreadcrumbsProps) {
  return (
    <Breadcrumb
      separator={
        <ChevronRightIcon
          className="stroke-gray-400"
          height="14px"
          width="14px"
        />
      }
    >
      <BreadcrumbItem isCurrentPage={props.currentPage === 'amount'}>
        <BreadcrumbLinkItem
          isCurrentPage={props.currentPage === 'amount'}
          text="Amount"
          onClick={() => props.onPageChange('amount')}
        />
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage={props.currentPage === 'paymentMethod'}>
        <BreadcrumbLinkItem
          isCurrentPage={props.currentPage === 'paymentMethod'}
          text="Payment Method"
          onClick={() => {
            if (
              props.currentPage !== 'amount' &&
              props.currentPage !== 'notify'
            ) {
              props.onPageChange('paymentMethod')
            }
          }}
          isDisabled={
            props.currentPage === 'amount' || props.currentPage === 'notify'
          }
        />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={props.currentPage === 'recipient'}>
        <BreadcrumbLinkItem
          isCurrentPage={props.currentPage === 'recipient'}
          text="Recipient"
          onClick={() => {
            if (
              props.currentPage !== 'amount' &&
              props.currentPage !== 'paymentMethod' &&
              props.currentPage !== 'notify'
            ) {
              props.onPageChange('recipient')
            }
          }}
          isDisabled={
            props.currentPage === 'amount' ||
            props.currentPage === 'paymentMethod' ||
            props.currentPage === 'notify'
          }
        />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={props.currentPage === 'review'}>
        <BreadcrumbLinkItem
          isCurrentPage={props.currentPage === 'review'}
          text="Review"
          onClick={() => {
            if (
              props.currentPage !== 'amount' &&
              props.currentPage !== 'paymentMethod' &&
              props.currentPage !== 'recipient' &&
              props.currentPage !== 'notify'
            ) {
              props.onPageChange('review')
            }
          }}
          isDisabled={
            props.currentPage === 'amount' ||
            props.currentPage === 'paymentMethod' ||
            props.currentPage === 'recipient' ||
            props.currentPage === 'notify'
          }
        />
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

type BreakdownProps = {
  amountCents: number
  feeCents?: number
}

function Breakdown({ amountCents, feeCents }: BreakdownProps) {
  return (
    <Flex flexDirection="column" gap={0}>
      <Text fontWeight="medium" fontSize="gray.800" whiteSpace="nowrap">
        {currency(amountCents, { fromCents: true }).format()}
      </Text>
      {feeCents !== undefined && (
        <Text fontSize="sm" color="gray.500" whiteSpace="nowrap">
          +{currency(feeCents, { fromCents: true }).format()} Fees
        </Text>
      )}
    </Flex>
  )
}

export function SendMoneyHeader(props: SendMoneyHeaderProps) {
  return (
    <Box>
      <Box px={10} py={5}>
        <Flex justify="space-between" align="center" height="40px">
          <GoBack onClick={props.onGoBackClick} />
          <SendMoneyBreadcrumbs
            currentPage={props.currentPage}
            onPageChange={props.onPageChange}
          />
          <Box width="100px">
            {props.breakdown ? (
              <Breakdown
                amountCents={props.breakdown.amountCents}
                feeCents={props.breakdown.feeCents}
              />
            ) : (
              <Box />
            )}
          </Box>
        </Flex>
      </Box>
      <Divider orientation="horizontal" />
    </Box>
  )
}
