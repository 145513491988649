import { tw, property } from '@nickeltech/brise'
import { ReactNode } from 'react'
import classNames from 'classnames'

export type BaseTypographyProps = {
  children?: ReactNode | Array<ReactNode>
  className?: string
  status?: TypographyStatusValue
  size?: TypographySizeValue
  weight?: TypographyWeightValue
  variant?: Variant | ButtonVariants
}

export type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'default'

export const VariantArray: Array<Variant> = [
  'primary',
  'secondary',
  'tertiary',
  'quaternary',
  'default',
]

export type ButtonVariants = 'solid' | 'ghost' | 'soft'

export const ButtonVariantArray: Array<ButtonVariants> = [
  'solid',
  'ghost',
  'soft',
]

export type TypographySizeValue =
  | '2xs'
  | 'xs'
  | 'xsmall'
  | 'small'
  | 'base'
  | 'medium'
  | 'large'
  | 'xl'
  | '2xl'
  | '3xl'

export const TypographySizeValueArray: Array<TypographySizeValue> = [
  '2xs',
  'xs',
  'xsmall',
  'small',
  'base',
  'medium',
  'large',
  'xl',
  '2xl',
  '3xl',
]

export type TypographyWeightValue = 'normal' | 'medium' | 'semibold' | 'bold'

export const TypographyWeightValueArray: Array<TypographyWeightValue> = [
  'normal',
  'medium',
  'semibold',
  'bold',
]

export type TypographyStatusValue =
  | 'default'
  | 'error'
  | 'inactive'
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'info'
  | 'success'
  | 'warning'
  | 'unknown'

export const TypographyStatusValueArray: Array<TypographyStatusValue> = [
  'default',
  'error',
  'inactive',
  'primary',
  'secondary',
  'accent',
  'info',
  'success',
  'warning',
  'unknown',
]

export const TextSize = property<BaseTypographyProps>(
  'size',
  {
    '2xs': 'text-[11px] leading-[11px]',
    xs: 'text-xs leading-[12px]',
    xsmall: 'text-xs leading-[12px]',
    small: 'text-sm leading-[14px]',
    base: 'text-base leading-[16px]',
    medium: 'text-lg leading-[18px]',
    large: 'text-xl leading-[20px]',
    xl: 'text-2xl leading-[24px]',
    '2xl': 'text-[32px] leading-[32px]',
    '3xl': 'text-5xl leading-[48px]',
    none: '',
  },
  'none',
)

export const TextWeight = property<BaseTypographyProps>(
  'weight',
  {
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
    none: '',
  },
  'none',
)

export const TextStatus = property<BaseTypographyProps>(
  'status',
  {
    default: 'text-gray-800',
    inactive: 'text-gray-500',
    primary: 'text-dark-600',
    secondary: 'text-gray-500',
    accent: 'text-purple-600',
    info: 'text-blue-600',
    success: 'text-green-600',
    warning: 'text-yellow-600',
    error: 'text-red-600',
    unknown: 'text-gray-400',
    none: '',
  },
  'none',
)

/**
 * Headline
 */

export type BaseHeadlineProps = BaseTypographyProps & {
  variant: Extract<Variant, 'primary' | 'secondary'>
}

export const HeadlineVariantValueArray: Array<Variant> = [
  'primary',
  'secondary',
]

export const BaseHeadline = tw.h1<BaseHeadlineProps>`
  ${TextSize}
  ${TextWeight}
  text-dark-800
`

export const HeadlineVariantMap: Partial<
  Record<Variant, Record<string, string>>
> = {
  primary: {
    size: '3xl',
    weight: 'bold',
  },
  secondary: {
    size: '2xl',
    weight: 'semibold',
  },
}

export const Headline = (props: BaseHeadlineProps) => {
  const variant = props.variant || 'primary'

  return (
    <BaseHeadline
      variant={variant}
      {...{ className: props.className }}
      {...HeadlineVariantMap[variant as Variant]}
    >
      {props.children}
    </BaseHeadline>
  )
}

/**
 * Title
 */

export type BaseTitleProps = BaseTypographyProps & {
  variant?: Extract<Variant, 'primary' | 'secondary'>
}

export const TitleVariantValueArray: Array<Variant> = ['primary', 'secondary']

export const BaseTitle = tw.h2<BaseTitleProps>`
  ${TextSize}
  ${TextWeight}
  ${TextStatus}
`

export const TitleVariantMap: Partial<Record<Variant, Record<string, string>>> =
  {
    primary: {
      size: 'large',
      weight: 'semibold',
    },
    secondary: {
      size: 'medium',
      weight: 'medium',
    },
  }

export const Title = (props: BaseTitleProps) => {
  const variant = props.variant || 'primary'

  return (
    <BaseTitle
      variant={variant}
      status="default"
      {...{ className: props.className }}
      {...TitleVariantMap[variant as Variant]}
    >
      {props.children}
    </BaseTitle>
  )
}

export const TitleTableHeader = (props: BaseTitleProps) => {
  return (
    <BaseTitle
      {...{ className: props.className }}
      status="default"
      weight="medium"
      size="xs"
    >
      {props.children}
    </BaseTitle>
  )
}

export const TitleSubheader = (props: BaseTitleProps) => {
  return (
    <BaseTitle
      {...{ className: props.className }}
      status="inactive"
      weight="normal"
      size="base"
    >
      {props.children}
    </BaseTitle>
  )
}

/**
 * Label
 */

export type BaseLabelProps = BaseTypographyProps & {
  variant?: Variant | ButtonVariants
}

export const BaseLabel = tw.label<BaseLabelProps>`
  ${TextSize}
  ${TextWeight}
  ${TextStatus}
`

export type LabelSideNavMenuItemProps = BaseLabelProps & {
  active?: boolean
}

export const LabelSidenavMenuItem = (props: LabelSideNavMenuItemProps) => {
  return (
    <BaseLabel
      weight="normal"
      size="base"
      className={classNames(props.className, {
        'text-gray-200': !props.active,
        'text-white': props.active,
      })}
    >
      {props.children}
    </BaseLabel>
  )
}

export const LabelInput = (props: BaseLabelProps) => {
  return (
    <BaseLabel
      {...{ className: props.className }}
      status="default"
      weight="medium"
      size="small"
    >
      {props.children}
    </BaseLabel>
  )
}

export const LabelAvatar = (props: BaseLabelProps) => {
  return (
    <BaseLabel
      {...{ className: classNames(props.className, 'uppercase') }}
      status="unknown"
      weight="medium"
      size="xs"
    >
      {props.children}
    </BaseLabel>
  )
}

export const LabelButtonVariantValueArray: Array<Variant> = [
  'primary',
  'secondary',
]

export const LabelButtonSizeValueArray: Array<TypographySizeValue> = [
  'large',
  'base',
  'small',
  'xs',
]

export type LabelButtonProps = BaseLabelProps & {
  size?: Extract<
    TypographySizeValue,
    'large' | 'base' | 'small' | 'xs' | 'xsmall'
  >
  outline?: boolean
  disabled?: boolean
}

export const LabelButton = ({ size = 'base', ...props }: LabelButtonProps) => {
  return (
    <BaseLabel weight="medium" size={size}>
      {props.children}
    </BaseLabel>
  )
}

export const LabelLinkButton = ({
  size = 'base',
  ...props
}: LabelButtonProps) => {
  return (
    <BaseLabel
      weight="medium"
      className={classNames(props.className, {
        'text-primary-600': props.variant === 'primary',
        'text-dark-800': props.variant === 'default',
        'text-gray-500': props.variant === 'secondary',
        'cursor-pointer': true,
      })}
      size={size}
    >
      {props.children}
    </BaseLabel>
  )
}

export const LabelBadgeVariantValueArray: Array<Variant> = [
  'primary',
  'secondary',
]

export type LabelBadgeProps = BaseTypographyProps & {
  variant?: Variant | ButtonVariants
}

export const LabelBadgeVariantMap: Partial<
  Record<Variant | ButtonVariants, Record<string, string>>
> = {
  primary: {
    size: 'xs',
    weight: 'semibold',
  },
  secondary: {
    size: '2xs',
    weight: 'medium',
  },
}

export const LabelBadge = (props: LabelBadgeProps) => {
  let variant = props.variant || 'primary'
  return (
    <BaseLabel
      {...{
        className: props.className,
        variant: props.variant,
        status: 'default',
        ...LabelBadgeVariantMap[variant],
      }}
    >
      {props.children}
    </BaseLabel>
  )
}

/**
 * Body
 */

export type BaseBodyProps = BaseTypographyProps

export const BaseBody = tw.p<BaseBodyProps>`
  ${TextSize}
  ${TextWeight}
  ${TextStatus}
`

export const BodySizeValueArray: Array<TypographySizeValue> = ['small', 'base']

export type BodyProps = BaseBodyProps & {
  size: Extract<TypographySizeValue, 'small' | 'base'>
  strong?: boolean
}

export const Body = (props: BodyProps) => {
  return (
    <BaseBody
      status={props.status || 'default'}
      size={props.size || 'base'}
      weight={props.strong ? 'medium' : 'normal'}
      {...{ className: props.className }}
    >
      {props.children}
    </BaseBody>
  )
}

export const BodyPlaceholder = (props: BaseBodyProps) => {
  return (
    <BaseBody
      status="inactive"
      size="base"
      weight="normal"
      {...{ className: props.className }}
    >
      {props.children}
    </BaseBody>
  )
}

export const BodyDescription = (props: BaseBodyProps) => {
  return (
    <BaseBody
      status="inactive"
      size="small"
      weight="normal"
      {...{ className: props.className }}
    >
      {props.children}
    </BaseBody>
  )
}

export const BodyDescriptionCaption = (props: BaseBodyProps) => {
  return (
    <BaseBody
      status="inactive"
      size="xs"
      weight="normal"
      {...{ className: props.className }}
    >
      {props.children}
    </BaseBody>
  )
}

export const BodyDescriptionFootNote = (props: BaseBodyProps) => {
  return (
    <BaseBody
      status="inactive"
      size="xs"
      weight="medium"
      {...{ className: props.className }}
    >
      {props.children}
    </BaseBody>
  )
}
