import { Card, Flex } from '@chakra-ui/react'
import { RegistrationLayout } from './RegistrationLayout'
import * as yup from 'yup'
import { Formik } from 'formik'
import { Button, FormikValidatingInput, HFlex, InputField, VFlex } from 'ui'
import { useSelfServiceRegistrationStore } from './selfServiceRegistrationStore'
import moment from 'moment'

const OwnerSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  dateOfBirth: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format')
    .required('Date of birth is required'),
})

type OwnerFormValues = yup.InferType<typeof OwnerSchema>

type OwnerProps = {
  onNextStep: () => void
}

export function Owner({ onNextStep }: OwnerProps) {
  const { setFirstName, setLastName, setDateOfBirth } =
    useSelfServiceRegistrationStore((state) => ({
      setFirstName: state.setFirstName,
      setLastName: state.setLastName,
      setDateOfBirth: state.setDateOfBirth,
    }))

  return (
    <RegistrationLayout
      title="Your information"
      subtitle="Tell us a little about yourself for ownership and compliance purposes."
      currentPage="owner"
    >
      <Formik<OwnerFormValues>
        initialValues={{
          firstName: '',
          lastName: '',
          dateOfBirth: '',
        }}
        onSubmit={(values) => {
          setFirstName(values.firstName)
          setLastName(values.lastName)
          setDateOfBirth(values.dateOfBirth)
          onNextStep()
        }}
        validate={(values) => {
          if (moment(values.dateOfBirth).isAfter('2021-01-01')) {
            return {
              dateOfBirth: 'Invalid date',
            }
          }

          if (moment(values.dateOfBirth).isBefore('1900-01-01')) {
            return {
              dateOfBirth: 'Invalid date',
            }
          }
        }}
        validationSchema={OwnerSchema}
      >
        {(props) => {
          const onClick = () => {
            props.setFieldTouched('firstName', true)
            props.setFieldTouched('lastName', true)
            props.setFieldTouched('dateOfBirth', true)
            props.validateForm()

            if (props.isValid) {
              props.handleSubmit()
            }
          }
          return (
            <Flex width={['340px', '540px']} flexDirection="column" gap={8}>
              <Card py={8} px={6}>
                <VFlex gap={6}>
                  <HFlex gap={2}>
                    <FormikValidatingInput
                      fieldName="firstName"
                      label="First Name"
                      size="md"
                      placeholder="John"
                      fontSize="md"
                    />
                    <FormikValidatingInput
                      fieldName="lastName"
                      label="Last Name"
                      size="md"
                      placeholder="Doe"
                      fontSize="md"
                    />
                  </HFlex>
                  <InputField
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    size="md"
                    label="Date of Birth"
                    cursor="pointer"
                    onChange={props.handleChange}
                    min="1900-01-01"
                    max="2021-01-01"
                    error={
                      !!props.touched.dateOfBirth && props.errors.dateOfBirth
                        ? props.errors.dateOfBirth
                        : undefined
                    }
                    helperText="Your date of birth is required for verification and compliance purposes."
                  />
                </VFlex>
              </Card>
              <Button label="Next" onClick={onClick} />
            </Flex>
          )
        }}
      </Formik>
    </RegistrationLayout>
  )
}
