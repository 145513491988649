import { styles } from '@nickeltech/brise'

export type ClampedProps = {
  clamped?: boolean
}

export const ClampedStyle = styles<ClampedProps>`
    ${(props) =>
      props.clamped
        ? `
        overflow-hidden
        whitespace-nowrap
        text-ellipsis
        line-clamp-1
    `
        : ''}
`
