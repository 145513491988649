import { DashboardBanner } from 'ui'
import { useDisclosure } from '@chakra-ui/react'

import Onboarding from '../onboardingV2/Onboarding'

const IS_DEMO = import.meta.env.VITE_NODE_ENV === 'demo'

function OnboardingDashboardReminder() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return !!IS_DEMO ? (
    <></>
  ) : (
    <>
      <DashboardBanner
        text="To get started receiving real payments and payouts just complete
      your onboarding!"
        buttonLabel="Complete Onboarding"
        buttonOnClick={() => {
          onOpen()
        }}
      />
      <Onboarding isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default OnboardingDashboardReminder
