import {
  FormChildrenProps,
  Icon,
  IconName,
  MerchantFormData,
  PaymentFormData,
} from 'ui'
import braintree from 'braintree-web'
import { useEffect, useState } from 'react'
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'

export type BraintreeCardDetailsFormProps = {
  clientToken: string
  onCardNumberValidityChange?: (valid: boolean) => void
  onExpiryValidityChange?: (valid: boolean) => void
  onCVVValidityChange?: (valid: boolean) => void
  setHostedFields?: (hostedFields: braintree.HostedFields) => void
  children?: React.ReactNode
  formik?:
    | FormChildrenProps<PaymentFormData>
    | FormChildrenProps<MerchantFormData>
  hostedFields?: braintree.HostedFields
  setPaymentNonceToken?: (paymentNonce: string) => void
}

const BORDER_COLOR = '#e5e5e5'
const BORDER_STYLE = `1px solid ${BORDER_COLOR}`
const GRAY_800 = '#292524'
const GRAY_400 = '#a8a29e'

const BraintreeCardTypeToIconName: Record<string, IconName> = {
  'american-express': 'amex',
  visa: 'visa',
  'master-card': 'masterCard',
  maestro: 'maestro',
  'diners-club': 'diners',
  discover: 'discover',
  jcb: 'jcb',
  unionpay: 'creditCard',
}

const ERROR_MESSAGES: Record<CARD_FIELDS, string> = {
  number: 'Please enter a valid card number',
  cvv: 'Please enter a valid card security code',
  expirationDate: 'Please enter a valid expiration date',
}

type CARD_FIELDS = 'number' | 'cvv' | 'expirationDate'

type BraintreeContainerProps = {
  invalid?: boolean
  loading?: boolean
} & BoxProps

export const BraintreeContainer = (props: BraintreeContainerProps) => {
  const { invalid, loading, id, ...rest } = props

  return (
    <Box
      {...rest}
      id={id}
      height="40px"
      background="white"
      width="100%"
      borderColor={invalid ? 'red.600' : BORDER_COLOR}
    >
      {loading && (
        <Box px={1}>
          <Skeleton height="32px" />
        </Box>
      )}
    </Box>
  )
}

export const BraintreeCardDetailsForm = ({
  clientToken,
  onCardNumberValidityChange,
  onExpiryValidityChange,
  onCVVValidityChange,
  setHostedFields,
  children,
  formik,
  hostedFields,
  setPaymentNonceToken,
}: BraintreeCardDetailsFormProps) => {
  const [loading, setLoading] = useState(true)
  const [cardType, setCardType] = useState('unknown')

  const [blurred, setBlurred] = useState<Record<CARD_FIELDS, boolean>>({
    number: false,
    expirationDate: false,
    cvv: false,
  })

  const [validity, setValidity] = useState<Record<CARD_FIELDS, boolean>>({
    number: false,
    expirationDate: false,
    cvv: false,
  })

  // The card form is invalid if all fields have been touched, at least one field is invalid.
  const isCreditCardDetailsInvalid = () => {
    return (
      Object.values(blurred).every((value) => value) &&
      Object.values(validity).some((x) => !x)
    )
  }

  const isCardFormInvalid = isCreditCardDetailsInvalid()
  const invalidItem = (Object.keys(validity) as CARD_FIELDS[]).find(
    (key) => !validity[key],
  )
  const errorMessage = invalidItem ? ERROR_MESSAGES[invalidItem] : null

  useEffect(() => {
    const initClient = async () => {
      await braintree.client.create(
        {
          authorization: clientToken,
        },
        async (error, clientInstance) => {
          if (error) {
            console.error(error)
            return
          }

          await braintree.hostedFields.create(
            {
              client: clientInstance,
              fields: {
                number: {
                  selector: '#card-number',
                  placeholder: 'Card Number',
                },
                cvv: {
                  selector: '#cvv',
                  placeholder: 'Security Code',
                },
                expirationDate: {
                  selector: '#expiration-date',
                  placeholder: 'Expiration (MM/YY)',
                },
              },
              styles: {
                input: {
                  color: GRAY_800,
                  'font-size': '16px',
                  'font-weight': '300',
                  'padding-left': '16px',
                },
                '::placeholder': {
                  color: GRAY_400,
                  opacity: 0.8,
                },
                '.invalid': {
                  color: 'red',
                },
              },
            },
            async (error, hostedFieldsInstance) => {
              if (error || !hostedFieldsInstance) {
                console.error(error)
                return
              }

              hostedFieldsInstance.on('cardTypeChange', (event) => {
                if (!event.cards?.at(0)?.type) {
                  return
                }
                setCardType(event.cards[0].type)
              })

              hostedFieldsInstance.on('validityChange', (event) => {
                const field = event.fields[event.emittedBy]
                setValidity((prev) => ({
                  ...prev,
                  [event.emittedBy]: field.isValid,
                }))

                if (event.emittedBy === 'number') {
                  onCardNumberValidityChange &&
                    onCardNumberValidityChange(field.isValid)
                }

                if (event.emittedBy === 'expirationDate') {
                  onExpiryValidityChange &&
                    onExpiryValidityChange(field.isValid)
                }

                if (event.emittedBy === 'cvv') {
                  onCVVValidityChange && onCVVValidityChange(field.isValid)
                }
              })

              hostedFieldsInstance.on('blur', (event) => {
                setBlurred((prev) => ({
                  ...prev,
                  [event.emittedBy]: true,
                }))
              })

              setHostedFields && setHostedFields(hostedFieldsInstance)

              if (formik && setPaymentNonceToken) {
                if (
                  formik.values.billingAddress.fullName &&
                  formik.values.billingAddress.zipCode &&
                  formik.values.billingAddress.streetAddress &&
                  formik.values.billingAddress.city &&
                  formik.values.billingAddress.state
                ) {
                  await hostedFields
                    ?.tokenize({
                      cardholderName: formik.values.billingAddress.fullName,
                      billingAddress: {
                        postalCode: formik.values.billingAddress.zipCode,
                        streetAddress:
                          formik.values.billingAddress.streetAddress,
                        locality: formik.values.billingAddress.city,
                        region: formik.values.billingAddress.state,
                      },
                    })
                    .then(async (payload) => {
                      setPaymentNonceToken(payload?.nonce || '')
                    })
                }
              }

              setLoading(false)
            },
          )
        },
      )
    }

    initClient()
  }, [clientToken, setHostedFields])

  return (
    <Box w={'100%'}>
      <Flex flexDirection="column" gap="2" width="100%">
        <Flex flexDirection="column" grow={1} width="100%">
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <BraintreeContainer
              id="card-number"
              borderTopRightRadius="5px"
              borderTopLeftRadius="5px"
              borderBottomLeftRadius="0"
              borderBottomRightRadius="0"
              border={BORDER_STYLE}
              invalid={isCardFormInvalid}
              loading={loading}
            />
            <Box w="0">
              <Icon
                className="relative h-[14px] right-[35px]"
                name={BraintreeCardTypeToIconName[cardType]}
              />
            </Box>
          </Flex>
          <Flex flexDirection="row" width="100%">
            <BraintreeContainer
              id="expiration-date"
              borderLeft={BORDER_STYLE}
              borderRight={BORDER_STYLE}
              borderBottom={BORDER_STYLE}
              borderBottomLeftRadius="5px"
              invalid={isCardFormInvalid}
              loading={loading}
            />
            <BraintreeContainer
              id="cvv"
              borderTop="0"
              borderBottom={BORDER_STYLE}
              borderRight={BORDER_STYLE}
              borderBottomRightRadius="5px"
              invalid={isCardFormInvalid}
              loading={loading}
            />
          </Flex>
        </Flex>
        {children}
        {isCardFormInvalid && (
          <Text color="red.600" fontSize="sm">
            {errorMessage}
          </Text>
        )}
      </Flex>
    </Box>
  )
}
