import { styles } from '@nickeltech/brise'

export type SectionedProps = {
  sectioned?: boolean
}

export type OverflowHiddenProps = {
  overflowHidden?: boolean
}

export type OverflowScrollableProps = {
  overflowScrollable?: boolean
}

const SectionedStyle = styles<SectionedProps>`
    ${(props: SectionedProps) =>
      props.sectioned ? 'border-b border-b-gray-200' : ''}
`

export default SectionedStyle
