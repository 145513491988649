import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { ACHForm } from 'ui'
import {
  GetBankRoutingNumberQuery,
  GetBankRoutingNumberDocument,
} from '../../../operations-types'

type ACHDetailsProps = {
  routingNumber: string
  accountNumber: string
  confirmAccountNumber: string
  onChangeRoutingNumber: (routingNumber: string) => void
  onChangeAccountNumber: (accountNumber: string) => void
  onChangeConfirmAccountNumber: (accountNumber: string) => void
}

export function ACHDetails({
  onChangeRoutingNumber,
  onChangeAccountNumber,
  onChangeConfirmAccountNumber,
  routingNumber,
  accountNumber,
  confirmAccountNumber,
}: ACHDetailsProps) {
  const [search, { data, loading }] = useLazyQuery<GetBankRoutingNumberQuery>(
    GetBankRoutingNumberDocument,
  )

  useEffect(() => {
    if (routingNumber.length === 9) {
      search({
        variables: {
          bankRoutingNumber: routingNumber,
        },
      })
    }
  }, [routingNumber])

  return (
    <ACHForm
      loading={loading}
      routingNumber={routingNumber}
      accountNumber={accountNumber}
      confirmedAccountNumber={confirmAccountNumber}
      isDemo={import.meta.env.VITE_NODE_ENV === 'demo'}
      accountSelectInputName="accountType"
      bankName={data?.bankRoutingNumber?.bankRoutingNumber?.bankName}
      onChangeRoutingNumber={(routingNumber: string) => {
        if (routingNumber.length === 9) {
          search({
            variables: {
              bankRoutingNumber: routingNumber,
            },
          })
        }

        onChangeRoutingNumber(routingNumber)
      }}
      onChangeAccountNumber={onChangeAccountNumber}
      onChangeConfirmAccountNumber={onChangeConfirmAccountNumber}
    />
  )
}
