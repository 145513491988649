import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Stack,
} from '@chakra-ui/react'
type DomainProps = {
  subdomain: string
}
export function Domain({ subdomain }: DomainProps) {
  return (
    <Stack spacing="4" w="100%">
      <HStack spacing="4">
        <FormControl>
          <FormLabel variant="small">Nickel Pay URL</FormLabel>
          <InputGroup>
            <InputLeftAddon children="https://" />
            <Input isDisabled value={`${subdomain}.nickelpayments`} />
            <InputRightAddon children=".com" />
          </InputGroup>
        </FormControl>
      </HStack>
    </Stack>
  )
}
