import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import Label from '../Typography/Label'
import Column from '../v2/layout/Column'

type DatePickerWrapperProps = {
  className?: string
  onChange: (date: Date) => void
  selected: Date
} & ReactDatePickerProps

const DatePickerWrapper = styled(
  ({ className, ...props }: DatePickerWrapperProps) => (
    <ReactDatePicker className={className} {...props} />
  ),
)`
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  outline-color: #d1d5db;
  width: 100%;
`

export type DatePickerProps = {
  value: Date
  onChange: (date: Date) => void
  label?: string
  onFocus?: () => void
}

export function DatePicker({
  value,
  onChange,
  label,
  onFocus,
}: DatePickerProps) {
  return (
    <Column gap="small" wGrow>
      {label && label.length > 0 ? (
        <Label variant="secondary">{label}</Label>
      ) : (
        ''
      )}
      <DatePickerWrapper
        onFocus={onFocus}
        className="text-sm"
        selected={value}
        onChange={onChange}
      />
    </Column>
  )
}

export default DatePicker
