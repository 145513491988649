import {
  ChevronRightIcon as HeroChevronRightIcon,
  ChevronLeftIcon as HeroChevronLeftIcon,
  ChevronUpIcon as HeroChevronUpIcon,
  ChevronDoubleLeftIcon as HeroChevronDoubleLeftIcon,
  ChatBubbleLeftIcon as HeroChatBubbleLeftIcon,
  LinkIcon as HeroLinkIcon,
  EllipsisVerticalIcon as HeroEllipsisVerticalIcon,
  MagnifyingGlassIcon as HeroMagnifyingGlassIcon,
  BanknotesIcon as HeroBankotesIcon,
  BellIcon as HeroBellIcon,
  CurrencyDollarIcon as HeroCurrencyDollarIcon,
  CheckBadgeIcon as HeroCheckBadgeIcon,
  CheckIcon as HeroCheckIcon,
  CreditCardIcon as HeroCreditCardIcon,
  ChartBarSquareIcon as HeroChartBarSquareIcon,
  BuildingLibraryIcon as HeroBuildingLibraryIcon,
  BoltIcon as HeroBoltIcon,
  EnvelopeIcon as HeroEnvelopeIcon,
  SparklesIcon as HeroSparklesIcon,
  InboxArrowDownIcon as HeroInboxArrowDownIcon,
  UserIcon as HeroUserIcon,
  TrashIcon as HeroTrashIcon,
  ArrowLeftIcon as HeroLeftArrowIcon,
  XMarkIcon as HeroXMarkIcon,
  CogIcon as HeroCogIcon,
  FunnelIcon as HeroFunnelIcon,
  ShieldExclamationIcon as HeroShieldExclamationIcon,
  ArrowDownTrayIcon as HeroArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon as HeroArrowTopRightOnSquareIcon,
  EllipsisHorizontalIcon as HeroEllipsisHorizontalIcon,
  UserCircleIcon as HeroUserCircleIcon,
  PencilIcon as HeroPencilIcon,
  BarsArrowDownIcon as HeroBarsArrowDownIcon,
  ArrowUturnLeftIcon as HeroArrowUTurnLeftIcon,
  PlusIcon as HeroPlusIcon,
  MinusIcon as HeroMinusIcon,
  EyeIcon as HeroEyeIcon,
  ChevronDoubleRightIcon as HeroChevronDoubleRightIcon,
  ChevronDownIcon as HeroChevronDownIcon,
  InboxIcon as HeroInboxIcon,
  ReceiptPercentIcon as HeroReceiptPercentIcon,
  ReceiptRefundIcon as HeroReceiptRefundIcon,
  CalculatorIcon as HeroCalculatorIcon,
  CalendarIcon as HeroCalendarIcon,
  ArrowRightOnRectangleIcon as HeroArrowRightOnRectangleIcon,
  PowerIcon as HeroPowerIcon,
  CheckCircleIcon as HeroCheckCircleIcon,
  ExclamationCircleIcon as HeroExclamationCircleIcon,
  NoSymbolIcon as HeroNoSymbolIcon,
  ArrowDownCircleIcon as HeroArrowDownCircleIcon,
  DocumentArrowDownIcon as HeroDocumentArrowDownIcon,
  DocumentArrowUpIcon as HeroDocumentArrowUpIcon,
  BeakerIcon as HeroBeakerIcon,
  ArrowRightIcon as HeroArrowRightIcon,
  UserMinusIcon as HeroUserMinusIcon,
  UserPlusIcon as HeroUserPlusIcon,
  InformationCircleIcon as HeroInformationCircleIcon,
  QuestionMarkCircleIcon as HeroQuestionMarkCircleIcon,
  PlusCircleIcon as HeroPlusCircleIcon,
  PaperAirplaneIcon as HeroPaperPlaneIcon,
  PauseIcon as HeroPauseIcon,
  PlayIcon as HeroPlayIcon,
  ArrowPathIcon as HeroArrowPathIcon,
  DocumentTextIcon as HeroDocumentIcon,
} from '@heroicons/react/24/outline'

import { BiWallet as BiWalletIcon } from 'react-icons/bi'

export type IconName =
  | 'chevronRight'
  | 'chevronLeft'
  | 'chevronUp'
  | 'chevronDown'
  | 'chevronDoubleLeft'
  | 'chevronDoubleRight'
  | 'chatBubbleLeft'
  | 'chartBar'
  | 'link'
  | 'ellipsisVertical'
  | 'magnifyingGlass'
  | 'bankNotes'
  | 'bell'
  | 'currencyDollar'
  | 'checkBadge'
  | 'creditCard'
  | 'buildingLibrary'
  | 'bolt'
  | 'envelope'
  | 'sparkles'
  | 'inboxArrowDown'
  | 'user'
  | 'trash'
  | 'leftArrow'
  | 'xMark'
  | 'cog'
  | 'funnel'
  | 'shieldExclamation'
  | 'arrowDownTray'
  | 'arrowTopRightOnSquare'
  | 'ellipsisHorizontal'
  | 'userCircle'
  | 'pencil'
  | 'barsArrowDown'
  | 'arrowUTurnLeft'
  | 'eye'
  | 'inbox'
  | 'receiptPercent'
  | 'receiptRefund'
  | 'calculator'
  | 'calendar'
  | 'arrowRightOnRectangle'
  | 'minus'
  | 'plusIcon'
  | 'check'
  | 'power'
  | 'checkCircle'
  | 'exclamationCircle'
  | 'noSymbol'
  | 'arrowDownCircle'
  | 'wallet'
  | 'documentArrowDown'
  | 'documentArrowUp'
  | 'beaker'
  | 'arrowRight'
  | 'userMinus'
  | 'userPlus'
  | 'informationCircle'
  | 'questionMarkCircle'
  | 'triangleWarningIcon'
  | 'plusCircle'
  | 'paperPlane'
  | 'pause'
  | 'play'
  | 'arrowPath'
  | 'document'

export function TriangleWarningIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4845 3.49512C9.15808 2.32845 10.842 2.32845 11.5156 3.49512L17.7943 14.3701C18.4678 15.5368 17.6259 16.9951 16.2787 16.9951H3.72136C2.37421 16.9951 1.53224 15.5368 2.20582 14.3701L8.4845 3.49512Z"
        fill="#FED7AA"
      />
      <path
        d="M10 7C10.4142 7 10.75 7.33579 10.75 7.75V11.25C10.75 11.6642 10.4142 12 10 12C9.58579 12 9.25 11.6642 9.25 11.25L9.25 7.75C9.25 7.33579 9.58579 7 10 7Z"
        fill="#EA580C"
      />
      <path
        d="M10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15Z"
        fill="#EA580C"
      />
    </svg>
  )
}

export const IconMap = {
  chevronRight: HeroChevronRightIcon,
  chevronLeft: HeroChevronLeftIcon,
  chevronUp: HeroChevronUpIcon,
  chevronDown: HeroChevronDownIcon,
  chevronDoubleLeft: HeroChevronDoubleLeftIcon,
  chevronDoubleRight: HeroChevronDoubleRightIcon,
  chatBubbleLeft: HeroChatBubbleLeftIcon,
  chartBar: HeroChartBarSquareIcon,
  link: HeroLinkIcon,
  ellipsisVertical: HeroEllipsisVerticalIcon,
  magnifyingGlass: HeroMagnifyingGlassIcon,
  bankNotes: HeroBankotesIcon,
  bell: HeroBellIcon,
  currencyDollar: HeroCurrencyDollarIcon,
  checkBadge: HeroCheckBadgeIcon,
  creditCard: HeroCreditCardIcon,
  buildingLibrary: HeroBuildingLibraryIcon,
  bolt: HeroBoltIcon,
  envelope: HeroEnvelopeIcon,
  sparkles: HeroSparklesIcon,
  inboxArrowDown: HeroInboxArrowDownIcon,
  user: HeroUserIcon,
  trash: HeroTrashIcon,
  leftArrow: HeroLeftArrowIcon,
  xMark: HeroXMarkIcon,
  cog: HeroCogIcon,
  funnel: HeroFunnelIcon,
  shieldExclamation: HeroShieldExclamationIcon,
  arrowDownTray: HeroArrowDownTrayIcon,
  arrowTopRightOnSquare: HeroArrowTopRightOnSquareIcon,
  ellipsisHorizontal: HeroEllipsisHorizontalIcon,
  userCircle: HeroUserCircleIcon,
  pencil: HeroPencilIcon,
  barsArrowDown: HeroBarsArrowDownIcon,
  arrowUTurnLeft: HeroArrowUTurnLeftIcon,
  eye: HeroEyeIcon,
  inbox: HeroInboxIcon,
  receiptPercent: HeroReceiptPercentIcon,
  receiptRefund: HeroReceiptRefundIcon,
  calculator: HeroCalculatorIcon,
  calendar: HeroCalendarIcon,
  arrowRightOnRectangle: HeroArrowRightOnRectangleIcon,
  minus: HeroMinusIcon,
  plusIcon: HeroPlusIcon,
  check: HeroCheckIcon,
  power: HeroPowerIcon,
  checkCircle: HeroCheckCircleIcon,
  exclamationCircle: HeroExclamationCircleIcon,
  noSymbol: HeroNoSymbolIcon,
  arrowDownCircle: HeroArrowDownCircleIcon,
  wallet: BiWalletIcon,
  document: HeroDocumentIcon,
  documentArrowDown: HeroDocumentArrowDownIcon,
  documentArrowUp: HeroDocumentArrowUpIcon,
  beaker: HeroBeakerIcon,
  arrowRight: HeroArrowRightIcon,
  userMinus: HeroUserMinusIcon,
  userPlus: HeroUserPlusIcon,
  informationCircle: HeroInformationCircleIcon,
  questionMarkCircle: HeroQuestionMarkCircleIcon,
  plusCircle: HeroPlusCircleIcon,
  triangleWarningIcon: TriangleWarningIcon,
  paperPlane: HeroPaperPlaneIcon,
  pause: HeroPauseIcon,
  play: HeroPlayIcon,
  arrowPath: HeroArrowPathIcon,
}

export const IconNameArray = Object.keys(IconMap) as Array<string>
