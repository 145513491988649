import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Row } from 'ui'

type PaymentLinkElement = {
  node: React.ReactNode
  label: string
}

type PaymentLinkElementsProps = {
  nodes: PaymentLinkElement[]
  onClick?: (idx: number) => void
  tabIndex?: number
}

export const PaymentLinkElements = (props: PaymentLinkElementsProps) => {
  const [selected, setSelected] = useState(props.tabIndex || 0)

  return (
    <Row between className="w-full px-0">
      <Tabs
        className="w-full"
        onChange={(e) => {
          setSelected(e)
          props.onClick && props.onClick(e)
        }}
        defaultIndex={selected}
      >
        <TabList className="px-8">
          {props.nodes.map((node, idx) => (
            <Tab
              key={idx}
              color={selected === idx ? 'dark.800' : 'dark.300'}
              paddingX={0}
              marginRight={5}
              fontSize="sm"
            >
              {node.label}
            </Tab>
          ))}
        </TabList>
        <Row className="px-8">
          <TabPanels>
            {props.nodes.map((node, idx) => (
              <TabPanel key={idx} paddingX={0}>
                {node.node}
              </TabPanel>
            ))}
          </TabPanels>
        </Row>
      </Tabs>
    </Row>
  )
}
