import { OperationVariables } from '@apollo/client'
import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import PaymentInnerOverlay from '../payments/PaymentInnerOverlay'
import PayoutInnerOverlay from '../payouts/PayoutInnerOverlay'
import PaymentOverlayRefund from './PaymentOverlayRefund'
import RefundInnerOverlay from './refunds/RefundInnerOverlay'
import { PAYMENTS_URL } from '../../lib/urls'
import {
  GetPaymentSummaryDocument,
  GetPaymentsDocument,
} from '../../operations-types'

type OVERLAY_STATES = 'payment' | 'refund' | 'payout' | 'refundOverlay'

type PaymentOverlayContext = {
  exitOverlay: () => void
  onRefunded: (paymentId: string, amount: number) => void
  onError: (paymentId: string, error: string) => void
  queryOpts: OperationVariables
}

export default function PaymentOverlay() {
  const { paymentId } = useParams() as { paymentId: string }
  const navigate = useNavigate()
  const [payoutId, setPayoutId] = useState<string | null>(null)
  const [refundId, setRefundId] = useState<string | null>(null)
  const { exitOverlay, onRefunded, onError, queryOpts } =
    useOutletContext() as PaymentOverlayContext

  const [state, setState] = useState<OVERLAY_STATES>('payment')
  const onBack = () => setState('payment')

  const STATE_TO_ELEMENT: { [state in OVERLAY_STATES]: JSX.Element } = {
    payment: (
      <>
        {paymentId && (
          <PaymentInnerOverlay
            exitOverlay={exitOverlay}
            paymentId={paymentId}
            onRefund={() => setState('refund')}
            onPayout={(payoutId) => {
              setPayoutId(payoutId)
              setState('payout')
            }}
            onRefundItem={(refundId) => {
              setRefundId(refundId)
              setState('refundOverlay')
            }}
          />
        )}
      </>
    ),
    refund: (
      <>
        {paymentId && (
          <PaymentOverlayRefund
            onRefunded={(paymentId, amount) => {
              onRefunded(paymentId, amount)
              setState('payment')
            }}
            onError={(paymentId, error) => {
              onError(paymentId, error)
              setState('payment')
            }}
            refetchQueries={[
              {
                query: GetPaymentsDocument,
                variables: {
                  ...queryOpts,
                },
              },
              {
                query: GetPaymentSummaryDocument,
              },
            ]}
            onBack={onBack}
            exitOverlay={exitOverlay}
            paymentId={paymentId}
          />
        )}
      </>
    ),
    payout: (
      <>
        {payoutId && (
          <PayoutInnerOverlay
            payoutId={payoutId}
            onExit={exitOverlay}
            onBack={onBack}
            onTransactionClick={(transaction) => {
              navigate(`${PAYMENTS_URL}/${transaction.transactionId}`)
              setState('payment')
            }}
          />
        )}
      </>
    ),
    refundOverlay: (
      <>
        {refundId && (
          <RefundInnerOverlay
            refundId={refundId}
            exitOverlay={exitOverlay}
            onPayoutClick={(payoutId) => {
              setPayoutId(payoutId)
              setState('payout')
            }}
            onBack={onBack}
          />
        )}
      </>
    ),
  }

  const element = STATE_TO_ELEMENT[state]
  return <>{element}</>
}
