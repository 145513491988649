import styled from 'styled-components'
import { Column } from 'ui'

const ScrollableColumn = styled(Column)`
  height: -webkit-fill-available;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`
export default ScrollableColumn
