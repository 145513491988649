import { useMutation } from '@apollo/client'
import { Card, Flex, VStack, Text, useToast } from '@chakra-ui/react'
import {
  AddressInput,
  Button,
  Form,
  FormikValidatingInput,
  HFlex,
  US_STATES,
  ValidatingInput,
} from 'ui'
import * as yup from 'yup'
import { useAuth } from '../../lib/auth'
import {
  BecomeUserDocument,
  CreateAccountingClientDocument,
  SelfDocument,
} from '../../operations-types'
import { ClientOrg } from './addClientStore'

const ClientCompanyInformation = yup.object({
  businessName: yup.string().required('Business name is required'),
  firstName: yup.string().required('Owner first name is required'),
  lastName: yup.string().required('Owner last name is required'),
  paymentUrl: yup.string().required('Payment URL is required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Owner email is required'),
  companyAddress: yup
    .object({
      zipCode: yup
        .string()
        .required('Business address is required')
        .length(5)
        .matches(/^[0-9]{5}/)
        .required(),
      state: yup
        .string()
        .required('State is required')
        .oneOf(US_STATES.states.map((x) => x.name)),
      streetAddress: yup.string().required('Business address is required'),
      city: yup.string().required('City is required'),
    })
    .required(),
})

type BusinessInformationProps = {
  onClick: (orgId: ClientOrg) => void
}

export function BusinessInformation(props: BusinessInformationProps) {
  const { setAuth } = useAuth()
  const toast = useToast()
  const [becomeUser] = useMutation(BecomeUserDocument, {
    onCompleted: (data) => {
      const token = data?.becomeUser?.token

      setAuth(token)
    },
    refetchQueries: [
      {
        query: SelfDocument,
      },
    ],
  })

  const [addClient, { loading }] = useMutation(CreateAccountingClientDocument, {
    onCompleted: async (data, opts) => {
      if (
        data.createAccountingClient?.error ||
        !data.createAccountingClient?.organization?.id ||
        !data.createAccountingClient?.organization?.employees?.[0]?.id
      ) {
        toast({
          title: 'Error',
          description: data.createAccountingClient?.error?.message,
          status: 'error',
        })
        return
      }

      const onboardingInfo: {
        companyAddress: {
          streetAddress?: string
          city?: string
          state?: string
          zipCode?: string
        }
      } = JSON.parse(
        data.createAccountingClient.organization.accountInfo?.onboardingInfo ||
          '{}',
      )

      await becomeUser({
        variables: {
          becomeUserId:
            data.createAccountingClient.organization.employees?.[0].id,
        },
      })

      props.onClick({
        id: data.createAccountingClient.organization.id,
        name: data.createAccountingClient.organization.name || '',
        ownerFirstName: opts?.variables?.ownerFirstName || '',
        ownerUserId:
          data.createAccountingClient.organization?.employees?.[0].id,
        companyAddresss: {
          streetAddress: onboardingInfo.companyAddress?.streetAddress || '',
          city: onboardingInfo.companyAddress?.city || '',
          state: onboardingInfo.companyAddress?.state || '',
          zipCode: onboardingInfo.companyAddress?.zipCode || '',
        },
      })
    },
  })

  return (
    <Flex width="540px" flexDirection="column">
      <Form
        validationSchema={ClientCompanyInformation}
        initialValues={{
          businessName: '',
          firstName: '',
          lastName: '',
          email: '',
          paymentUrl: '',
          companyAddress: {
            zipCode: '',
            state: '',
            streetAddress: '',
            city: '',
          },
        }}
      >
        {(formik) => {
          const onClick = async () => {
            formik.validateForm()
            formik.setTouched({
              businessName: true,
              firstName: true,
              lastName: true,
              email: true,
              paymentUrl: true,
              companyAddress: {
                zipCode: true,
                state: true,
                streetAddress: true,
                city: true,
              },
            })

            if (formik.isValid) {
              await addClient({
                variables: {
                  name: formik.values.businessName,
                  email: formik.values.email,
                  ownerFirstName: formik.values.firstName,
                  ownerLastName: formik.values.lastName,
                  streetAddress: formik.values.companyAddress.streetAddress,
                  city: formik.values.companyAddress.city,
                  state: formik.values.companyAddress.state,
                  zip: formik.values.companyAddress.zipCode,
                  subdomain: formik.values.paymentUrl,
                },
              })
            }
          }

          return (
            <>
              <Card py={8} px={8}>
                <Flex flexDirection="column" gap={6}>
                  <ValidatingInput
                    id="businessName"
                    fontSize="sm"
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (!formik.touched.paymentUrl) {
                        formik.setFieldValue(
                          'paymentUrl',
                          e.target.value
                            .replace(/[^a-z0-9]/gi, '')
                            .toLowerCase(),
                        )
                      }
                    }}
                    isInvalid={!!formik.errors.businessName}
                    validated={formik.touched.businessName}
                    label="Business Name"
                    placeholder="Business Name"
                    value={formik.values.businessName}
                    error={formik.errors.businessName}
                  />
                  <FormikValidatingInput
                    fieldName="paymentUrl"
                    fontSize="sm"
                    label="Payment Portal URL"
                    value={formik.values.paymentUrl}
                    helperText="Unique URL where payors will be directed to make payments."
                    placeholder="subdomain (e.g. businessname)"
                    rightAddon=".nickelpayments.com"
                  />
                  <HFlex gap={4}>
                    <FormikValidatingInput
                      fieldName="firstName"
                      fontSize="sm"
                      label="Owner First Name"
                    />
                    <FormikValidatingInput
                      fieldName="lastName"
                      fontSize="sm"
                      label="Owner Last Name"
                    />
                  </HFlex>
                  <FormikValidatingInput
                    fieldName="email"
                    fontSize="sm"
                    label="Owner Email"
                    helperText="We'll use this to check if the business is already on Nickel."
                  />
                  <VStack>
                    <AddressInput
                      label="Business Address"
                      apiKey={import.meta.env.VITE_SMARTY_STREETS_KEY || ''}
                      valueKey="companyAddress"
                    />
                    {formik.touched.companyAddress &&
                      formik.errors.companyAddress && (
                        <Text size="sm" color="red.600">
                          {formik.errors.companyAddress.streetAddress ||
                            formik.errors.companyAddress.city ||
                            formik.errors.companyAddress.zipCode ||
                            formik.errors.companyAddress.state}
                        </Text>
                      )}
                  </VStack>
                </Flex>
              </Card>
              <Button
                label="Create"
                width="100%"
                type="submit"
                mt={8}
                isLoading={loading}
                iconPosition="right"
                iconName="chevronRight"
                onClick={(e) => {
                  e.preventDefault()
                  onClick()
                }}
              />
            </>
          )
        }}
      </Form>
    </Flex>
  )
}
