import { property, tw } from '@nickeltech/brise'
import ContainerProps, { StatusProps, VariantProps } from '../../types'
import { VisibilityTransition, VisibilityTransitionProps } from '../Transitions'

export type HeaderProps = ContainerProps &
  VisibilityTransitionProps &
  StatusProps &
  VariantProps

export const HeaderVariantStyle = property(
  'variant',
  {
    quinary: 'text-base font-medium text-gray-800',
    quarternary: 'text-lg font-medium text-gray-800',
    tertiary: 'text-lg font-semibold text-gray-800',
    secondary: 'text-xl font-semibold text-gray-800',
    primary: 'text-2xl font-semibold text-gray-800',
    page: 'text-3xl font-semibold text-gray-800',
  },
  'primary',
)

export const Header = tw.h1<HeaderProps>`
  leading-tight
  ${HeaderVariantStyle}
  ${VisibilityTransition}
`

export default Header
