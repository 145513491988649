import { create } from 'zustand'
import {
  ApprovalPolicy,
  Bill as GraphqlBill,
  BillPayable,
  BillPayment,
  Charge,
  PaymentMethod as GraphqlPaymentMethod,
  Recipient,
  Vendor,
} from '../../../operations-types'
import { PaymentSummary, US_STATES } from 'ui'
import { SendMoneyPage } from './send-money/types'
import * as yup from 'yup'

export const BaseSchema = yup.object().shape({
  vendorName: yup.string().required('Vendor name is required'),
  vendorEmail: yup.string().email('Invalid email'),
  paymentVia: yup
    .string()
    .oneOf(['ACH', 'check'])
    .min(3)
    .required('Payment method is required'),
  achDetails: yup
    .object()
    .when('paymentVia', {
      is: 'ACH',
      then: yup
        .object({
          routingNumber: yup.string().required().length(9),
          accountNumber: yup.string().required(),
          bankName: yup.string(),
          confirmedAccountNumber: yup
            .string()
            .required()
            .oneOf([yup.ref('accountNumber')]),
        })
        .required(),
      otherwise: undefined,
    })
    .when('paymentVia', {
      is: 'check',
      then: yup.object({
        recipientAddress: yup.object().shape({
          zipCode: yup
            .string()
            .required()
            .length(5)
            .matches(/^[0-9]{5}/)
            .required(),
          state: yup
            .string()
            .required()
            .oneOf(US_STATES.states.map((x) => x.name)),
          streetAddress: yup.string().required(),
          city: yup.string().required(),
        }),
      }),
      otherwise: undefined,
    }),
})

export const RecipientDetailsValidationSchema = BaseSchema.required()

export type SendMoneyAmountResult = {
  submittedAmountCents: number
  reason: string
}

export type RecipientDetailsValidationSchemaType = yup.InferType<
  typeof RecipientDetailsValidationSchema
>

type SendPaymentState = {
  page: SendMoneyPage
  sendMoneyAmountResult?: SendMoneyAmountResult
  savedPaymentMethods: PaymentMethod[]
  selectedSavedPaymentMethod?: PaymentMethod | null
  paymentSummary?: PaymentSummary
  vendorId?: string
  vendorPayoutMethodId?: string
  billPaymentId?: string
  initialRecipients?: Array<Recipient>
  vendor: Vendor | null
  bill?: Bill | null
  approvalPolicy?: ApprovalPolicy
  setBill: (_: Bill) => void
  setVendor: (_: Vendor) => void
  setInitialRecipients: (recipients: Array<Recipient>) => void
  setPage: (page: SendMoneyPage) => void
  getPage: () => SendMoneyPage
  setPaymentMethods: (_: PaymentMethod[]) => void
  setSendMoneyAmountResult: (_: SendMoneyAmountResult) => void
  setSelectedSavedPaymentMethod: (_: PaymentMethod | null) => void
  clearSelectedSavedPaymentMethod: () => void
  setPaymentSummary: (_: PaymentSummary) => void
  clearPaymentFees: () => void
  setVendorId: (_: string) => void
  setVendorPayoutMethodId: (_: string | undefined) => void
  setRecipientDetails: (_: RecipientDetailsValidationSchemaType) => void
  setBillPaymentId: (_: string) => void
  setApprovalPolicy: (_: ApprovalPolicy) => void
  recipientDetails?: RecipientDetailsValidationSchemaType
  reset: () => void
}

type LatestCharge = Pick<
  Charge,
  'id' | 'createdAt' | 'failureMessage' | 'status'
>

type PaymentMethodFields = Pick<
  GraphqlPaymentMethod,
  | 'id'
  | 'externalPaymentMethodId'
  | 'card'
  | 'achDebit'
  | 'billingDetails'
  | 'codatChartOfAccountId'
>

type BillPaymentFields = Omit<BillPayment, 'billPayable'>

type BillFields = Omit<GraphqlBill, 'billPayables'>

type BillPayableFields = Omit<BillPayable, 'billPayments'>

export type Bill = BillFields & {
  billPayables: BillPayableFields[] & {
    billPayments: BillPaymentFields[]
  }
}

export type PaymentMethod = PaymentMethodFields & {
  latestCharge?: LatestCharge | null
}

const initialState: Omit<
  SendPaymentState,
  | 'setBill'
  | 'setVendor'
  | 'setPage'
  | 'reset'
  | 'setPaymentMethods'
  | 'setSelectedSavedPaymentMethod'
  | 'setPaymentSummary'
  | 'setInitialRecipients'
  | 'setRecipientDetails'
  | 'setBillPaymentId'
  | 'clearSelectedSavedPaymentMethod'
  | 'clearPaymentFees'
  | 'setVendorId'
  | 'setVendorPayoutMethodId'
  | 'getPage'
  | 'setSendMoneyAmountResult'
  | 'setApprovalPolicy'
> = {
  savedPaymentMethods: [],
  page: 'amount',
  sendMoneyAmountResult: undefined,
  recipientDetails: undefined,
  selectedSavedPaymentMethod: null,
  initialRecipients: [],
  bill: null,
  vendor: null,
  billPaymentId: undefined,
  approvalPolicy: undefined,
  vendorPayoutMethodId: undefined,
}

export const useSendPaymentStore = create<SendPaymentState>((set, get) => ({
  ...initialState,
  setBill: (bill: Bill) =>
    set({
      bill: bill,
    }),
  setVendor: (vendor: Vendor) => set({ vendor }),
  setPage: (page: SendMoneyPage) => set({ page }),
  getPage: () => get().page,
  setSendMoneyAmountResult: (sendMoneyAmountResult: SendMoneyAmountResult) =>
    set({ sendMoneyAmountResult }),
  setPaymentMethods: (paymentMethods: PaymentMethod[]) =>
    set({ savedPaymentMethods: paymentMethods }),
  setSelectedSavedPaymentMethod: (paymentMethod: PaymentMethod | null) =>
    set({ selectedSavedPaymentMethod: paymentMethod }),
  clearSelectedSavedPaymentMethod: () =>
    set({ selectedSavedPaymentMethod: null }),
  setPaymentSummary: (paymentSummary: PaymentSummary) =>
    set({ paymentSummary }),
  setInitialRecipients: (initialRecipients: Array<Recipient>) =>
    set({
      initialRecipients,
    }),
  clearPaymentFees: () =>
    set({
      paymentSummary: new PaymentSummary(0, []),
    }),
  setVendorId: (vendorId: string) => set({ vendorId }),
  setVendorPayoutMethodId: (vendorPayoutMethodId: string | undefined) =>
    set({ vendorPayoutMethodId }),
  setRecipientDetails: (
    recipientDetails: RecipientDetailsValidationSchemaType,
  ) => {
    set({ recipientDetails })
  },
  reset: () => set(initialState),
  setBillPaymentId: (billPaymentId: string) => set({ billPaymentId }),
  setApprovalPolicy: (approvalPolicy: ApprovalPolicy) =>
    set({ approvalPolicy }),
}))
