import { tw } from '@nickeltech/brise'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
  Icon,
  Spinner,
  Card,
  CardBody,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormLabel,
  Checkbox,
  Spacer,
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import {
  ArrowRightIcon,
  BuildingLibraryIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import currency from 'currency.js'
import { useState } from 'react'
import {
  HFlex,
  Overlay,
  Button,
  VFlex,
  Row,
  Column,
  AddressInput,
  Form,
  US_STATES,
  ACHForm,
  FormButton,
  ValidatingInput,
} from 'ui'
import { MoneyCheck } from 'ui/src/components/Icons/MoneyCheck'
import ContainerProps from 'ui/src/types'
import {
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  RequestVendorDeliveryDetailsDocument,
  UpdateVendorCheckPayoutMethodDocument,
  UpdateVendorDocument,
  UpdateVendorPayoutMethodDocument,
  Vendor,
  VendorDocument,
} from '../../operations-types'
import * as yup from 'yup'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

type VendorOverlayProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  vendorId: string
  setNewBillOpen: (open: boolean) => void
  setOverlayVendor: (vendor: Vendor | null) => void
}

function OverlayHeader({
  onClose,
  vendorName,
}: {
  onClose: () => void
  vendorName: string
}) {
  return (
    <Box width="100%" px={8} py={6}>
      <HFlex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="2xl" fontWeight="semibold" color="gray.800">
          {vendorName}
        </Text>
        <IconButton
          aria-label="Close Vendor Overlay"
          as={XMarkIcon}
          className="stroke-gray-500"
          cursor="pointer"
          onClick={onClose}
          size="xs"
          variant="ghost"
        />
      </HFlex>
    </Box>
  )
}

type VendorPayoutDetailsProps = {
  vendorId: string
  vendorPayoutMethods: Vendor['vendorPayoutMethods']
  vendorEmail?: string
  vendorTaxId?: string
}

type VendorTransactionSummaryProps = {
  remainingBalance: string
  totalPaid: string
  unpaidBills: number
}

const VendorDetailCaption = tw.div<ContainerProps>`
  text-gray-500
  text-sm
  font-normal
  !w-[300px]
  whitespace-nowrap
`

const VendorDetailValue = tw.div<ContainerProps>`
  text-md
  text-gray-800
  font-medium
  whitespace-nowrap
`

function VendorTransactionSummary(props: VendorTransactionSummaryProps) {
  return (
    <HFlex justifyContent="space-between" w="100%">
      <Box textAlign="center">
        <Text>Open Balance</Text>
        <Text>{props.remainingBalance}</Text>
      </Box>
      <Box textAlign="center">
        <Text>Total Paid</Text>
        <Text>{props.totalPaid}</Text>
      </Box>
      <Box textAlign="center">
        <Text>Unpaid Bills</Text>
        <Text>{props.unpaidBills}</Text>
      </Box>
    </HFlex>
  )
}

export const EditVendorDetailsValidationSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email(),
  taxId: yup.string().matches(/^\d{9}$/, 'Tax ID must be exactly 9 digits'),
})

export type EditVendorDetailsValidationSchemaType = yup.InferType<
  typeof VendorPayoutDetailsValidationSchema
>

type EditVendorDetailsProps = {
  name: string
  email: string
  taxId: string
  vendorId: string
  setTab: (tab: TabType) => void
}
function EditVendorDetails(props: EditVendorDetailsProps) {
  const [updateVendor] = useMutation(UpdateVendorDocument, {
    refetchQueries: [VendorDocument],
  })

  const toast = useToast()
  return (
    <>
      <OverlayHeader
        vendorName={`Editing ${props.name}`}
        onClose={() => {
          props.setTab('vendor')
        }}
      />
      <VFlex px={8} py={6} w="100%" gap="6">
        <Form
          {...{
            initialValues: {
              name: props.name,
              email: props.email,
              taxId: props.taxId,
            },
            validationSchema: EditVendorDetailsValidationSchema,
            onSubmit: async (values) => {
              const response = await updateVendor({
                variables: {
                  vendorId: props.vendorId,
                  name: values.name,
                  email: values.email,
                  taxId: values.taxId,
                },
              })

              if (response.data?.updateVendor?.error) {
                toast({
                  status: 'error',
                  description: response.data?.updateVendor?.error.message,
                  duration: 9000,
                  isClosable: true,
                })
                return
              }

              toast({
                status: 'success',
                title: 'Success',
                description: 'Vendor details updated!',
                duration: 9000,
                isClosable: true,
              })

              props.setTab('vendor')
            },
          }}
        >
          {(formik) => (
            <VStack spacing={4} w="100%" alignItems="start">
              <ValidatingInput
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.name}
                error={formik.errors.name}
              />
              <ValidatingInput
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.email}
                error={formik.errors.email}
              />
              <InputMask
                mask="99-9999999"
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/\D/g, '')
                  formik.setFieldValue('taxId', rawValue)
                }}
                value={formik.values.taxId}
                onBlur={formik.handleBlur}
              >
                <ValidatingInput
                  id="taxId"
                  name="taxId"
                  label="Tax ID"
                  value={formik.values.taxId}
                  validated={formik.touched.taxId}
                  isInvalid={!!formik.errors.taxId}
                  error={formik.errors.taxId}
                />
              </InputMask>
              <HFlex justifyContent="end" w="100%" gap="4">
                <Button
                  alignSelf="end"
                  label="Cancel"
                  onClick={() => {
                    formik.resetForm()
                    props.setTab('vendor')
                  }}
                />
                <Button
                  alignSelf="end"
                  label="Save"
                  onClick={async () => {
                    await formik.handleSubmit()
                  }}
                />
              </HFlex>
            </VStack>
          )}
        </Form>
      </VFlex>
    </>
  )
}

export const BaseVendorPayoutDetailsValidationSchema = yup.object().shape({
  paymentVia: yup.string().required(),
  email: yup.string().email(),
  achDetails: yup
    .object()
    .when('paymentVia', {
      is: 'ACH',
      then: yup
        .object({
          routingNumber: yup.string().required().length(9),
          accountNumber: yup.string().required(),
          bankName: yup.string(),
          confirmedAccountNumber: yup
            .string()
            .required()
            .oneOf([yup.ref('accountNumber')]),
        })
        .required(),
      otherwise: undefined,
    })
    .when('paymentVia', {
      is: 'check',
      then: yup.object({
        recipientAddress: yup.object().shape({
          zipCode: yup
            .string()
            .required()
            .length(5)
            .matches(/^[0-9]{5}/)
            .required(),
          state: yup
            .string()
            .required()
            .oneOf(US_STATES.states.map((x) => x.name)),
          streetAddress: yup.string().required(),
          city: yup.string().required(),
        }),
      }),
      otherwise: undefined,
    }),
})

export const VendorPayoutDetailsValidationSchema =
  BaseVendorPayoutDetailsValidationSchema.required()

export type VendorPayoutDetailsValidationSchemaType = yup.InferType<
  typeof VendorPayoutDetailsValidationSchema
>

function VendorPayoutDetails({
  vendorPayoutMethods,
  vendorId,
  vendorEmail,
  vendorTaxId,
}: VendorPayoutDetailsProps) {
  const [editMethod, setEditMethod] = useState<'ACH' | 'check' | null>(null)

  const [updateVendorPayoutMethod, { loading: updateAchLoading }] = useMutation(
    UpdateVendorPayoutMethodDocument,
    {
      refetchQueries: [VendorDocument],
    },
  )

  const [updateVendorCheckPayoutMethod, { loading: updateCheckLoading }] =
    useMutation(UpdateVendorCheckPayoutMethodDocument, {
      refetchQueries: [VendorDocument],
    })

  const vendorAchPayoutMethod =
    vendorPayoutMethods?.find((method) => method?.type === 'ACH') || null

  const vendorCheckPayoutMethod =
    vendorPayoutMethods?.find((method) => method?.type === 'CHECK') || null

  const toast = useToast()

  const [search, { loading: bankRoutingLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument)

  const [requestVendorDeliveryDetails] = useMutation(
    RequestVendorDeliveryDetailsDocument,
  )

  const [requestTaxId, setRequestTaxId] = useState(false)

  return (
    <Form
      {...{
        className: 'w-full',
        validationSchema: VendorPayoutDetailsValidationSchema,
        initialValues: {
          paymentVia: '',
          email: vendorEmail || '',
          achDetails: {
            accountNumber: vendorAchPayoutMethod?.accountNumber || '',
            bankName: vendorAchPayoutMethod?.bankName || '',
            bankRoutingNumberLoading: vendorAchPayoutMethod ? true : false,
            routingNumber: vendorAchPayoutMethod?.routingNumber || '',
            confirmedAccountNumber: vendorAchPayoutMethod?.accountNumber || '',
            accountType: 'checking',
            recipientAddress: {
              streetAddress: vendorCheckPayoutMethod?.street || '',
              city: vendorCheckPayoutMethod?.city || '',
              state:
                US_STATES.states.find(
                  (x) =>
                    x.abbreviation === vendorCheckPayoutMethod?.state ||
                    x.name === vendorCheckPayoutMethod?.state,
                )?.name || '',
              zipCode: vendorCheckPayoutMethod?.zip?.split('-')?.at(0) || '',
            },
          },
        } as VendorPayoutDetailsValidationSchemaType,
        validateOnChange: true,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: async (values, props) => {
          if (values.paymentVia === 'ACH') {
            const vendorPayoutMethod = await updateVendorPayoutMethod({
              variables: {
                vendorId: vendorId || '',
                accountNumber: values.achDetails.accountNumber || '',
                routingNumber: values.achDetails.routingNumber || '',
                accountType: values.achDetails.accountType || '',
              },
            })

            if (vendorPayoutMethod.data?.updateVendorPayoutMethod?.error) {
              toast({
                status: 'error',
                description:
                  vendorPayoutMethod.data?.updateVendorPayoutMethod?.error
                    .message,
                duration: 9000,
                isClosable: true,
              })
              return
            }
          } else if (values.paymentVia === 'check') {
            const vendorPayoutMethod = await updateVendorCheckPayoutMethod({
              variables: {
                vendorId: vendorId || '',
                street:
                  values.achDetails?.recipientAddress?.streetAddress || '',
                city: values.achDetails?.recipientAddress?.city || '',
                state: values.achDetails.recipientAddress.state || '',
                zip: values.achDetails.recipientAddress.zipCode || '',
              },
            })

            if (vendorPayoutMethod.data?.updateVendorCheckPayoutMethod?.error) {
              toast({
                status: 'error',
                description:
                  vendorPayoutMethod.data?.updateVendorCheckPayoutMethod?.error
                    .message,
                duration: 9000,
                isClosable: true,
              })
              return
            }
          }

          toast({
            status: 'success',
            title: 'Success',
            description: 'Vendor delivery method updated!',
            duration: 9000,
            isClosable: true,
          })

          setEditMethod(null)
          props.setFieldValue('paymentVia', '')
        },
      }}
    >
      {(props) => (
        <VStack spacing={4} w="100%" alignItems="start">
          (
          <Card w="100%" bgColor="gray.50">
            <CardBody>
              <VFlex w="100%">
                <FormLabel>Request Delivery Information?</FormLabel>
                <HStack>
                  <ValidatingInput
                    id="email"
                    bgColor="white"
                    isInvalid={false}
                    name="email"
                    autoFocus={false}
                    size="sm"
                    type="email"
                    value={props.values.email}
                    placeholder="Your vendor's email"
                    onChange={props.handleChange}
                  />
                  <Button
                    size="sm"
                    label="Request"
                    isDisabled={!props.values.email || !!props.errors.email}
                    onClick={async () => {
                      const response = await requestVendorDeliveryDetails({
                        variables: {
                          vendorId: vendorId,
                          email: props.values.email,
                          taxRequested: requestTaxId,
                        },
                      })

                      if (response.data?.requestVendorDeliveryDetails?.error) {
                        toast({
                          status: 'error',
                          description:
                            response.data?.requestVendorDeliveryDetails?.error
                              .message,
                          duration: 9000,
                          isClosable: true,
                        })
                        return
                      }

                      toast({
                        status: 'success',
                        title: 'Success',
                        description: 'Vendor delivery details requested!',
                        duration: 9000,
                        isClosable: true,
                      })
                    }}
                  />
                </HStack>
                {(!vendorTaxId || vendorTaxId === 'REQUESTED') && (
                  <HStack
                    pt="2"
                    justifyContent="start"
                    alignItems="center"
                    gap="0"
                    spacing="0"
                  >
                    <Text fontSize="sm">Also request Tax ID?</Text>
                    <Checkbox
                      bgColor="white"
                      borderColor="gray.800"
                      m="2"
                      id="request-tax-id"
                      isChecked={requestTaxId}
                      onChange={() => {
                        setRequestTaxId(!requestTaxId)
                      }}
                    />
                  </HStack>
                )}
              </VFlex>
            </CardBody>
          </Card>
          )
          <>
            {vendorAchPayoutMethod && !updateAchLoading ? (
              <Box borderRadius="md" w="100%" mt="-1">
                {editMethod !== 'ACH' && (
                  <Card bgColor="gray.50">
                    <CardBody>
                      <HStack justify="space-between" mb={2}>
                        <HStack>
                          <Text
                            fontSize="md"
                            fontWeight="medium"
                            color="gray.700"
                          >
                            ACH/eCheck
                          </Text>
                          <Icon
                            as={BuildingLibraryIcon}
                            w={5}
                            h={5}
                            color="gray.600"
                          />
                        </HStack>
                        <IconButton
                          aria-label="Edit ACH Payout Method"
                          icon={<Icon as={PencilIcon} />}
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setEditMethod('ACH')
                            props.setFieldValue('paymentVia', 'ACH')
                          }}
                        />
                      </HStack>
                      <Divider mb={2} />
                      <VStack spacing="0" alignItems="start">
                        <Text fontSize="sm" color="gray.800">
                          {vendorAchPayoutMethod.bankName ||
                            `Routing: ${vendorAchPayoutMethod.routingNumber}`}
                        </Text>
                        <Text fontSize="sm">
                          Account #: {vendorAchPayoutMethod.accountNumber}
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                )}
              </Box>
            ) : updateAchLoading ? (
              <Spinner />
            ) : !editMethod ? (
              <Button
                alignSelf="flex-start"
                label="Add ACH Method"
                onClick={() => {
                  setEditMethod('ACH')
                  props.setFieldValue('paymentVia', 'ACH')
                }}
                size="xs"
              />
            ) : (
              ''
            )}

            {editMethod === 'ACH' && props.values.paymentVia === 'ACH' && (
              <Card w="100%">
                <CardBody>
                  <VStack gap="2">
                    <ACHForm
                      loading={bankRoutingLoading}
                      isDemo={false}
                      routingNumber={
                        props.values?.achDetails?.routingNumber || ''
                      }
                      accountNumber={
                        props.values?.achDetails?.accountNumber || ''
                      }
                      confirmedAccountNumber={
                        props.values?.achDetails?.confirmedAccountNumber || ''
                      }
                      bankName={props.values?.achDetails?.bankName}
                      onChangeRoutingNumber={(routingNumber: string) => {
                        props.setFieldValue('achDetails.bankName', undefined)
                        if (routingNumber.length === 9) {
                          search({
                            variables: {
                              bankRoutingNumber: routingNumber,
                            },
                          }).then((response) => {
                            if (
                              response.data?.bankRoutingNumber
                                ?.bankRoutingNumber?.bankName
                            )
                              props.setFieldValue(
                                'achDetails.bankName',
                                response.data.bankRoutingNumber
                                  .bankRoutingNumber.bankName,
                              )
                          })
                        }
                        props.setFieldValue(
                          'achDetails.routingNumber',
                          routingNumber,
                        )
                      }}
                      onChangeAccountNumber={(accountNumber: string) => {
                        props.setFieldValue(
                          'achDetails.accountNumber',
                          accountNumber,
                        )
                      }}
                      onChangeConfirmAccountNumber={(
                        confirmAccountNumber: string,
                      ) => {
                        props.setFieldValue(
                          'achDetails.confirmedAccountNumber',
                          confirmAccountNumber,
                        )
                      }}
                    />
                    <HFlex justifyContent="end" w="100%" gap="4">
                      <Button
                        alignSelf="end"
                        label="Cancel"
                        onClick={() => {
                          setEditMethod(null)
                          props.setFieldValue('paymentVia', '')
                        }}
                      />
                      <Button
                        alignSelf="end"
                        label="Save"
                        isDisabled={
                          !props.values?.achDetails?.routingNumber ||
                          !props.values?.achDetails?.accountNumber ||
                          !props.values?.achDetails?.confirmedAccountNumber ||
                          props.values?.achDetails?.accountNumber !==
                            props.values?.achDetails?.confirmedAccountNumber
                        }
                        onClick={async () => {
                          await props.handleSubmit()
                        }}
                      />
                    </HFlex>
                  </VStack>
                </CardBody>
              </Card>
            )}

            {/* Check Method */}
            {vendorCheckPayoutMethod && !updateCheckLoading ? (
              <Box borderRadius="md" w="100%" mt="-1">
                {editMethod !== 'check' && (
                  <Card bgColor="gray.50">
                    <CardBody>
                      <HStack justify="space-between" mb={2}>
                        <HStack>
                          <Text
                            fontSize="md"
                            fontWeight="medium"
                            color="gray.700"
                          >
                            Physical Check
                          </Text>
                          {/* Replace MoneyCheck with your check icon component */}
                          <MoneyCheck width={20} height={20} />
                        </HStack>
                        <IconButton
                          aria-label="Edit Check Payout Method"
                          icon={<Icon as={PencilIcon} />}
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setEditMethod('check')
                            props.setFieldValue('paymentVia', 'check')
                          }}
                        />
                      </HStack>
                      <Divider mb={2} />
                      <Text fontSize="sm" color="gray.800">
                        {vendorCheckPayoutMethod.street},{' '}
                        {vendorCheckPayoutMethod.city},{' '}
                        {vendorCheckPayoutMethod.state}{' '}
                        {vendorCheckPayoutMethod.zip}
                      </Text>
                    </CardBody>
                  </Card>
                )}
              </Box>
            ) : updateCheckLoading ? (
              <Spinner />
            ) : !editMethod ? (
              <Button
                alignSelf="flex-start"
                label="Add Check Method"
                onClick={() => {
                  setEditMethod('check')
                  props.setFieldValue('paymentVia', 'check')
                }}
                size="xs"
              />
            ) : (
              ''
            )}
            {editMethod === 'check' && (
              <Card>
                <CardBody>
                  <VStack>
                    <AddressInput
                      {...{
                        valueKey: 'achDetails.recipientAddress',
                        apiKey: import.meta.env.VITE_SMARTY_STREETS_KEY,
                        label: 'Check Recipient Address',
                        onChangeAddress: (address: string) => {},
                        onChangeCity(city) {},
                        onChangeState(state) {},
                        onChangeZip(zip) {},
                      }}
                    />
                    <HFlex justifyContent="end" w="100%" gap="4">
                      <Button
                        alignSelf="end"
                        label="Cancel"
                        onClick={() => {
                          setEditMethod(null)
                          props.setFieldValue('paymentVia', '')
                        }}
                      />
                      <FormButton
                        alignSelf="end"
                        label="Save"
                        isDisabled={
                          !props.values?.achDetails?.recipientAddress?.city ||
                          !props.values?.achDetails?.recipientAddress?.state ||
                          !props.values?.achDetails?.recipientAddress
                            ?.zipCode ||
                          !props.values?.achDetails?.recipientAddress
                            ?.streetAddress
                        }
                        onClick={async () => {
                          await props.handleSubmit()
                        }}
                      />
                    </HFlex>
                  </VStack>
                </CardBody>
              </Card>
            )}
          </>
        </VStack>
      )}
    </Form>
  )
}

type TabType = 'vendor' | 'editVendor'

export function VendorOverlay({
  isOpen,
  setOpen,
  vendorId,
  setNewBillOpen,
  setOverlayVendor,
}: VendorOverlayProps) {
  const { data } = useQuery(VendorDocument, {
    variables: {
      vendorId: vendorId,
    },
  })

  const vendor = data?.vendor?.vendor

  const vendorPayoutMethods = vendor?.vendorPayoutMethods || undefined
  const navigate = useNavigate()

  const [tab, setTab] = useState<TabType>('vendor')

  const editVendorNode = (
    <EditVendorDetails
      name={vendor?.name || ''}
      email={vendor?.email || ''}
      taxId={vendor?.taxId || ''}
      vendorId={vendorId}
      setTab={setTab}
    />
  )

  const vendorNode = (
    <>
      <OverlayHeader
        vendorName={vendor?.name || ''}
        onClose={() => {
          setOpen(false)
        }}
      />
      <Divider />
      <VFlex px={8} py={6} w="100%" gap="6">
        <Row grow y="top">
          <VendorDetailCaption>Vendor email</VendorDetailCaption>
          <Column wGrow>
            <VendorDetailValue>{vendor?.email}</VendorDetailValue>
          </Column>
        </Row>
        {vendor?.taxId && (
          <Row grow y="top">
            <VendorDetailCaption>Vendor Tax ID</VendorDetailCaption>
            <Column wGrow>
              <VendorDetailValue>{vendor?.taxId}</VendorDetailValue>
            </Column>
          </Row>
        )}

        <Divider />
        <div className="text-xl font-semibold"> Payments Summary</div>

        <Box w="100%" px="4">
          <VendorTransactionSummary
            remainingBalance={currency(
              vendor?.transactionsSummary?.remainingBalanceInCents || 0,
              { fromCents: true },
            ).format()}
            totalPaid={currency(
              vendor?.transactionsSummary?.totalPaidInCents || 0,
              { fromCents: true },
            ).format()}
            unpaidBills={vendor?.unpaidBills?.length || 0}
          />
        </Box>
        <Divider />
      </VFlex>
      <VFlex px={8} w="100%" gap="4">
        <div className="text-xl font-semibold"> Delivery Methods</div>
        {!!vendorPayoutMethods ? (
          <VendorPayoutDetails
            vendorId={vendorId}
            vendorPayoutMethods={vendorPayoutMethods}
            vendorEmail={vendor?.email || ''}
            vendorTaxId={vendor?.taxId || ''}
          />
        ) : (
          ''
        )}
      </VFlex>
      {vendor?.unpaidBills && vendor?.unpaidBills?.length > 0 && (
        <VFlex px={8} w="100%" gap="4" py="4">
          <div className="text-xl font-semibold"> Unpaid Bills</div>

          <Box w="100%" textAlign="center">
            <Table variant="simple" bgColor="gray.100">
              <Thead>
                <Tr>
                  <Th fontStyle={'inherit'}>Reference</Th>
                  <Th>Due Date</Th>
                  <Th isNumeric>Amount Due</Th>
                  <Th></Th> {/* Empty header for the action column */}
                </Tr>
              </Thead>
              <Tbody>
                {vendor?.unpaidBills?.map((bill) => (
                  <Tr key={bill.id}>
                    <Td>{bill.billData?.reference}</Td>
                    <Td>
                      {moment(bill.billData?.dueDate).format('MM/DD/YYYY')}
                    </Td>
                    <Td isNumeric>
                      {currency(bill.billData?.amountDue || 0, {
                        fromCents: false,
                      }).format()}
                    </Td>
                    <Td>
                      <HStack
                        as="button"
                        cursor="pointer"
                        onClick={() => {
                          navigate(`/send-money/bill/${bill.id}`)
                        }}
                      >
                        <Text
                          fontSize="sm"
                          color="purple.600"
                          fontWeight="medium"
                          _hover={{ color: 'purple.800' }}
                        >
                          Pay Bill
                        </Text>
                        <ArrowRightIcon
                          width={12}
                          height={12}
                          color="purple.600"
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </VFlex>
      )}

      <Box w="100%" mt="auto">
        <Divider />

        <Flex justifyContent="flex-end" py={4} px={8} gap={4}>
          <Button
            label="Edit Vendor"
            variant="ghost"
            onClick={() => {
              setTab('editVendor')
            }}
          />
          <Spacer />
          <Button
            label="Add New Bill"
            onClick={() => {
              setOverlayVendor(vendor || null)
              setNewBillOpen(true)
            }}
          />
        </Flex>
      </Box>
    </>
  )

  return (
    <Overlay open={isOpen} setOpen={setOpen}>
      {tab === 'vendor' && vendorNode}
      {tab === 'editVendor' && editVendorNode}
    </Overlay>
  )
}
