import { Overlay } from 'ui'
import CustomerInnerOverlay from './CustomerInnerOverlay'

type CustomerOverlayProps = {
  open: boolean
  setOpen: (open: boolean) => void
  customerId: string | null
}

export default function CustomerOverlay({
  open,
  setOpen,
  customerId,
}: CustomerOverlayProps) {
  return (
    <Overlay open={open} setOpen={(bool) => setOpen(bool)}>
      {customerId && (
        <CustomerInnerOverlay customerId={customerId} setOpen={setOpen} />
      )}
    </Overlay>
  )
}
