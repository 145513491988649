import { BlankLayout } from '../layout/Layout'
import jwt_decode from 'jwt-decode'
import { useMutation } from '@apollo/client'
import { useAuth } from '../../lib/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { AddNewTeammateDocument } from '../../operations-types'
import * as yup from 'yup'
import { Form, FormButton, InputField, ValidatingInput } from 'ui'
import { Button, Card, Flex, useToast, VStack } from '@chakra-ui/react'
import { useState } from 'react'

type LoginInfoProps = {
  email: string
  onRegister: (firstName: string, lastName: string, password: string) => void
  name: string
}

const LoginInfoFormSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup
    .string()
    .min(8, 'Password must be 8 characters minimum')
    .required('Password is required'),
})

type LoginInfoFormValues = yup.InferType<typeof LoginInfoFormSchema>

function LoginInfo({ email, onRegister, name }: LoginInfoProps) {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Flex flexDirection="column" gap={8}>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Registration
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Let&apos;s get your login information so that you can join {name}
        </p>
      </div>
      <Form<LoginInfoFormValues>
        className="w-full"
        {...{
          validationSchema: LoginInfoFormSchema,
          initialValues: {
            firstName: '',
            lastName: '',
            password: '',
          },
          onSubmit: async (values) =>
            onRegister(values.firstName, values.lastName, values.password),
        }}
      >
        {(formik) => {
          return (
            <Card p={8}>
              <VStack spacing="2" gap="4">
                <ValidatingInput
                  id="username"
                  fontSize="sm"
                  autoComplete="username"
                  label="Email"
                  placeholder={email}
                  isDisabled
                  isInvalid={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ValidatingInput
                  id="firstName"
                  fontSize="sm"
                  autoComplete="first-name"
                  label="First Name"
                  value={formik.values.firstName}
                  isInvalid={
                    !!formik.touched.firstName && !!formik.errors.firstName
                  }
                  error={formik.errors.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ValidatingInput
                  id="lastName"
                  fontSize="sm"
                  autoComplete="family-name"
                  label="Last Name"
                  value={formik.values.lastName}
                  isInvalid={
                    !!formik.touched.lastName && !!formik.errors.lastName
                  }
                  onChange={formik.handleChange}
                  error={formik.errors.lastName}
                  onBlur={formik.handleBlur}
                />
                <InputField
                  id="password"
                  name="password"
                  label="Password"
                  fontWeight="medium"
                  fontSize="sm"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.touched.password && !!formik.errors.password
                  }
                  error={
                    formik.touched.password ? formik.errors.password : undefined
                  }
                  rightElement={
                    formik.values.password && (
                      <Button
                        h="1.5rem"
                        size="sm"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? 'Hide' : 'Show'}
                      </Button>
                    )
                  }
                />
              </VStack>
              <VStack mt={4} alignItems="flex-end" spacing="medium" gap="0">
                <FormButton
                  status="primary"
                  variant="solid"
                  size="md"
                  label="Register"
                  isDisabled={Object.keys(formik.errors).length !== 0}
                />
              </VStack>
            </Card>
          )
        }}
      </Form>
    </Flex>
  )
}

function Expiry() {
  return (
    <BlankLayout>
      <div className="w-full max-w-2xl mt-20">
        <div className="font-medium text-4xl leading-10 tracking-wide">
          Unfortunately, that link is expired. Please request a new link from
          the organization owner.
        </div>
      </div>
    </BlankLayout>
  )
}

export default function NewTeammate() {
  const { token = '' } = useParams()
  const {
    email,
    expiry,
    name,
  }: { email: string; expiry: number; name: string } = jwt_decode(token)
  const { setAuth } = useAuth()

  const navigate = useNavigate()

  const toaster = useToast()

  const [signup] = useMutation(AddNewTeammateDocument, {
    onCompleted: (data) => {
      const token = data.addNewTeammate?.token
      setAuth(token)

      navigate('/dashboard')
    },
    onError: (error) => {
      toaster({
        title: 'Error',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const expired = Date.now() > expiry

  const onRegister = (firstName: string, lastName: string, password: string) =>
    signup({ variables: { password, firstName, lastName, token } })

  if (expired) {
    return <Expiry />
  }

  return (
    <BlankLayout>
      <div className="w-full max-w-2xl mt-20">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <LoginInfo email={email} onRegister={onRegister} name={name} />
        </div>
      </div>
    </BlankLayout>
  )
}
