import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  StackDivider,
} from '@chakra-ui/react'
import { CheckIcon, NoSymbolIcon } from '@heroicons/react/24/outline'

export type BankAccountNumberFieldProps = {
  className?: string
  id?: string
  touched?: boolean
  error?: string
  onBlurAccountNumber?: () => void
  onBlurConfirmAccountNumber?: () => void
  onChangeAccountNumber?: (_e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeConfirmAccountNumber?: (
    _e: React.ChangeEvent<HTMLInputElement>,
  ) => void
  accountNumberInvalid?: boolean
  confirmAccountNumberInvalid?: boolean
  validateAccountNumber?: boolean
  validateConfirmAccountNumber?: boolean
  accountNumber: string
  confirmedAccountNumber: string
  previewMode?: boolean
  accountNumberLabel?: string
}

export function BankAccountNumberField({
  id,
  error,
  onBlurAccountNumber,
  onBlurConfirmAccountNumber,
  onChangeAccountNumber,
  onChangeConfirmAccountNumber,
  accountNumberInvalid,
  confirmAccountNumberInvalid,
  validateAccountNumber,
  validateConfirmAccountNumber,
  accountNumber,
  confirmedAccountNumber,
  previewMode,
  accountNumberLabel,
  ...other
}: BankAccountNumberFieldProps) {
  const invalid =
    (accountNumberInvalid && validateAccountNumber) ||
    (confirmAccountNumberInvalid && validateConfirmAccountNumber)
  return (
    <FormControl isInvalid={invalid}>
      <FormLabel verticalAlign="center">
        {accountNumberLabel || 'Bank Account Number'}
      </FormLabel>

      <VStack
        w="100%"
        gap="0"
        spacing="0"
        divider={
          <StackDivider borderColor={invalid ? 'red.500' : 'gray.200'} />
        }
      >
        <InputGroup size="md">
          <Input
            {...other}
            id="tilled_iframe_bankAccountNumber"
            placeholder="Enter Account Number"
            style={{
              pointerEvents: previewMode ? 'none' : 'auto',
              opacity: previewMode ? 0.5 : 1,
            }}
            type="tel"
            color={invalid ? 'red.600' : ''}
            bgColor="white"
            value={accountNumber}
            borderBottomRadius="0"
            borderColor="gray.300"
            borderBottom="0"
            _focus={{
              boxShadow: 'none',
              outline: 'none',
              borderColor: 'gray.300',
            }}
            _invalid={{
              borderColor: 'red.500',
              borderBottom: '1',
              borderBottomRadius: '0',
            }}
            onBlur={onBlurAccountNumber}
            onChange={onChangeAccountNumber}
          />
          {!(accountNumberInvalid && validateAccountNumber) &&
            validateAccountNumber && (
              <InputRightElement>
                <CheckIcon className="w-4 h-4 stroke-green-800 stroke-2" />
              </InputRightElement>
            )}
          {accountNumberInvalid && validateAccountNumber && (
            <InputRightElement>
              <NoSymbolIcon className="w-4 h-4 stroke-red-600 stroke-2" />
            </InputRightElement>
          )}
        </InputGroup>
        <InputGroup size="md">
          <Input
            {...other}
            value={confirmedAccountNumber}
            id="tilled_iframe_confirmAccountNumber"
            placeholder="Confirm Account Number"
            type="tel"
            style={{
              pointerEvents: previewMode ? 'none' : 'auto',
              opacity: previewMode ? 0.5 : 1,
            }}
            color={invalid ? 'red.600' : ''}
            bgColor="white"
            borderTopRadius="0"
            borderColor="gray.300"
            borderTop="0"
            _focus={{
              boxShadow: 'none',
              outline: 'none',
              borderColor: 'gray.300',
            }}
            _invalid={{
              borderColor: 'red.500',
              borderTop: '1',
              borderTopRadius: '0',
            }}
            onBlur={onBlurConfirmAccountNumber}
            onChange={onChangeConfirmAccountNumber}
          />

          {!(confirmAccountNumberInvalid && validateConfirmAccountNumber) &&
            validateConfirmAccountNumber &&
            !accountNumberInvalid && (
              <InputRightElement>
                <CheckIcon className="w-4 h-4 stroke-green-800 stroke-2" />
              </InputRightElement>
            )}
          {confirmAccountNumberInvalid && validateConfirmAccountNumber && (
            <InputRightElement>
              <NoSymbolIcon className="w-4 h-4 stroke-red-600 stroke-2" />
            </InputRightElement>
          )}
        </InputGroup>
      </VStack>

      {error ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
    </FormControl>
  )
}

export default BankAccountNumberField
