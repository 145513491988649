import { styles, tw } from '@nickeltech/brise'
import { CardContainer } from '.'
import ContainerProps from '../../types'
import Spacing, { SpacingProps } from '../Layout/Spacing'

export type SelectableCardProps = {
  $embedded?: boolean
  selected?: boolean
} & ContainerProps &
  SpacingProps

export const SelectableCardNone = styles<SelectableCardProps>`
  focus:outline-none
  active:outline-none
  hover:outline-none
  focus:border-none
  active:border-none
  hover:border-none
  hover:bg-none
`

export const InteractiveSelectableCard = styles<SelectableCardProps>`
  ${(props) =>
    !props.selected && !props.$embedded
      ? `
  hover:border
  hover:border-gray-400
  
  hover:bg-slate-50
  
  active:border-transparent
  focus:outline
  focus:outline-offset-2
  focus:outline-2
  focus:outline-gray-600
  
  active:border-transparent
  active:outline
  active:outline-offset-2
  active:outline-2
  active:outline-gray-600
  `
      : ''}
`

export const SelectableCardStyles = styles<SelectableCardProps>`
  ${(props) =>
    props.$embedded
      ? `
    outline-none
    border
    bg-gray-100
    ${SelectableCardNone}
  `
      : ''}
  ${(props) =>
    props.selected
      ? `
    border-transparent
    outline
    outline-offset-0
    outline-2
    outline-gray-600
    ${SelectableCardNone}
  `
      : ''}


`

export const SelectableCardContainer = tw(CardContainer)`
  cursor-pointer

  ${Spacing}
  ${SelectableCardStyles}
  ${InteractiveSelectableCard}
`

export const SelectableCard = tw((props: SelectableCardProps) => {
  return (
    <SelectableCardContainer className={props.className}>
      {props.children}
    </SelectableCardContainer>
  )
})`
  cursor-pointer

  ${Spacing}
  ${SelectableCardStyles}
  ${InteractiveSelectableCard}
`

export default SelectableCard
