import { useMutation } from '@apollo/client'
import {
  FormControl,
  FormLabel,
  Stack,
  HStack,
  FormHelperText,
} from '@chakra-ui/react'
import { useState, useRef } from 'react'

import { SelfDocument, UpdateBrandingDocument } from '../../../operations-types'
import '@pqina/pintura/pintura.css'
import { PinturaEditorModal } from '@pqina/react-pintura'
import { getEditorDefaults } from '@pqina/pintura'
import { AWS_BUCKET_ROUTE } from '../../../lib/utils'

type BrandingProps = {
  orgName: string
  logoKey: string
  bannerKey: string
  onSuccess: () => void
}

const editorDefaults = getEditorDefaults({
  imageCropAspectRatio: 1,
  enableCanvasAlpha: true,
  enableUtils: false,
  imageWriter: {
    targetSize: {
      width: 100,
      height: 100,
    },
  },
})

const bannerEditorDefaults = getEditorDefaults({
  imageCropAspectRatio: 4.55,
  enableCanvasAlpha: true,
  enableUtils: false,
})

function UploadImage({
  forLabel,
  onSuccess,
}: {
  forLabel: string
  onSuccess: () => void
}) {
  const [updateBranding] = useMutation(UpdateBrandingDocument, {
    onCompleted: () => {
      onSuccess()
    },
    refetchQueries: [
      {
        query: SelfDocument,
      },
    ],
  })
  const [editorEnabled, setEditorEnabled] = useState(false)

  const [editorSrc, setEditorSrc] = useState<File | undefined>(undefined)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = () => {
    // Exit if no files selected or fileInputRef is null
    if (!fileInputRef.current?.files?.length) return

    // Edit the selected file
    setEditorEnabled(true)
    setEditorSrc(fileInputRef.current.files[0])
  }

  const handleEditorProcess = async (
    imageState: any,
    setImage: (base64Img: string) => void,
  ) => {
    // Exit if fileInputRef is null
    if (!fileInputRef.current) return

    // Create a files list
    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(imageState.dest)

    // Assign new files
    fileInputRef.current.files = dataTransfer.files

    const convertBase64 = (file: File) => {
      return new Promise<string | null>((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result as string)
        }
        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    }

    const base64 = await convertBase64(imageState.dest)
    setImage(base64 as string)
  }

  const handleEditorHide = () => setEditorEnabled(false)

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 w-full">
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 ">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor={forLabel}
                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload an image</span>
                <input
                  id={forLabel}
                  className="sr-only"
                  name={forLabel}
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                {editorEnabled && (
                  <PinturaEditorModal
                    {...(forLabel === 'logo'
                      ? editorDefaults
                      : bannerEditorDefaults)}
                    src={editorSrc}
                    onHide={handleEditorHide}
                    onProcess={async (evt) => {
                      handleEditorProcess(evt, async (img) => {
                        await updateBranding({
                          variables: {
                            companyLogoImage: forLabel === 'logo' ? img : '',
                            companyBannerImage:
                              forLabel === 'banner' ? img : '',
                          },
                        })
                      })
                    }}
                  />
                )}
              </label>
            </div>
            {forLabel === 'logo' && (
              <p className="text-xs text-gray-500">At least 50x50 pixels</p>
            )}
            {forLabel === 'banner' && (
              <p className="text-xs text-gray-500">At least 640x140 pixels</p>
            )}
            <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export function Branding({
  orgName,
  logoKey,
  bannerKey,
  onSuccess,
}: BrandingProps) {
  const logoKeySrc = logoKey ? `${AWS_BUCKET_ROUTE}${logoKey}` : undefined
  const bannerKeySrc = bannerKey ? `${AWS_BUCKET_ROUTE}${bannerKey}` : undefined

  return (
    <Stack spacing="6" w="100%">
      <HStack spacing="4" gap="4">
        <FormControl id="logo" w="100%">
          <FormLabel variant="small">Logo</FormLabel>
          <FormHelperText fontSize="xs" color="gray.500" pb="4">
            Upload the logo that will be displayed on your Nickel Payments
            Portal and any notifications delivered through Nickel
          </FormHelperText>
          {logoKeySrc && (
            <img height="64" width="64" src={logoKeySrc} alt="Logo" />
          )}

          <UploadImage forLabel="logo" onSuccess={onSuccess} />
        </FormControl>
      </HStack>
      <HStack>
        <FormControl id="banner" w="100%">
          <FormLabel variant="small">Banner</FormLabel>
          <FormHelperText fontSize="xs" color="gray.500" pb="4">
            Upload the banner image that will be displayed on your Nickel
            Payments Portal
          </FormHelperText>
          {bannerKeySrc && <img width="640" src={bannerKeySrc} alt="banner" />}

          <UploadImage forLabel="banner" onSuccess={onSuccess} />
        </FormControl>
      </HStack>
    </Stack>
  )
}
