import { useMutation, useQuery } from '@apollo/client'
import { Box } from '@chakra-ui/react'
import { ArrowDownIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import {
  Column,
  OverlayFooter,
  OverlaySectionPayouts,
  OverlaySectionRefund,
  Payout,
  UnstandardizedColumn,
} from 'ui'
import { TransactionStatusValue } from 'ui/src/components/v3/TransactionStatus'

import formatter from '../../lib/formatter'
import { reportErrorIfExists } from '../../lib/utils'
import {
  GetPaymentDocument,
  TogglePaymentStateDocument,
} from '../../operations-types'
import { ChargePayout, SingleCharge } from '../../types'
import AdditionalPaymentDetail from './AdditionalPaymentDetail'
import { OverlayHeader } from './OverlayHeader'
import AmountHeader from './PaymentAmountSubmitted'
import PaymentDetails, {
  BillingAddress,
  ContactInfo,
  PaymentMethod,
} from './PaymentDetails'
import PaymentElements from './PaymentElements'
import { PaymentHelper } from './PaymentHelper'
import { getStatus } from './utils'

type PaymentInnerOverlayProps = {
  paymentId: string
  exitOverlay: () => void
  onRefund?: () => void
  onPayout?: (payoutId: string) => void
  onRefundItem?: (refundId: string) => void
  onBack?: () => void
}

// Attempts to do the following:
// 1. Lump up all the charges and fees into a single payout object
// 2. If they are not in the same payout, then create a new payout object for the charge and fee
// 3. If not paid out, show the payout as pending
function getPayouts(
  charges: SingleCharge[],
  chargeAmount: number,
  feeAmount: number,
): Payout[] {
  const idToPayout: Record<string, Payout> = {}

  const chargePayouts = charges
    .map((x) => x?.payout)
    .filter((x) => x !== undefined && x !== null) as ChargePayout[]

  const feePayouts = charges
    .flatMap((x) => x.fees)
    .map((x) => x?.payout)
    .filter((x) => x !== undefined && x !== null) as ChargePayout[]

  for (const payout of chargePayouts) {
    idToPayout[payout.id] = {
      id: payout.id,
      paidAt: payout.paidAt
        ? moment(payout.paidAt).format('MM/DD/YYYY')
        : undefined,
      chargedAmount: formatter.format(chargeAmount),
    }
  }

  for (const payout of feePayouts) {
    if (idToPayout[payout.id]) {
      idToPayout[payout.id].feeAmount = formatter.format(feeAmount)
    } else {
      idToPayout[payout.id] = {
        id: payout.id,
        paidAt: payout.paidAt
          ? moment(payout.paidAt).format('MM/DD/YYYY')
          : undefined,
        feeAmount: formatter.format(feeAmount),
      }
    }
  }

  return [...Object.values(idToPayout)]
}

function getReturnPayouts(charges: SingleCharge[]): Payout[] {
  const feePayouts = charges
    .flatMap((x) => x.achReturn)
    .filter((x) => x !== undefined && x !== null)
    .map((x) => {
      return {
        ...x?.payout,
        amountInCents: x?.amountCents,
      }
    })

  return feePayouts.map((payout) => {
    return {
      id: payout.id,
      paidAt: payout.paidAt
        ? moment(payout.paidAt).format('MM/DD/YYYY')
        : undefined,
      feeAmount: formatter.format((payout.amountInCents || 0) / 100),
    }
  })
}

export default function PaymentInnerOverlay({
  paymentId,
  exitOverlay,
  onRefund,
  onPayout,
  onRefundItem,
  onBack,
}: PaymentInnerOverlayProps) {
  const { loading, data, error } = useQuery(GetPaymentDocument, {
    variables: { paymentId: paymentId },
  })

  const [toggleState] = useMutation(TogglePaymentStateDocument)

  const [downloadLoading, setDownloadLoading] = useState(false)

  reportErrorIfExists(data?.payment?.error?.message || error)

  if (loading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  let paymentData = data?.payment?.payment

  const paymentHelper = new PaymentHelper(paymentData!)

  const refundPresenters = paymentHelper.refunds().map((x) => {
    return {
      id: x?.id || '',
      amount: (x?.amountCents || 0) / 100,
      dateNumber: parseInt(x?.createdAt || ''),
      date: moment(parseInt(x?.createdAt || '')).format('MM/DD/YYYY'),
      status: _.capitalize(x?.status || ''),
    }
  })

  refundPresenters.sort((a, b) => b.dateNumber - a.dateNumber)

  const allRefunds = [...refundPresenters]
  allRefunds.sort((a, b) => b.dateNumber - a.dateNumber)

  const paymentMethod = data?.payment?.payment?.paymentMethod
  const address = paymentMethod?.billingDetails?.address
  const payouts = getPayouts(
    data?.payment?.payment?.charges || [],
    paymentHelper.chargedAmountDollars(),
    paymentHelper.platformFeeDollars(),
  )

  const returnPayouts = getReturnPayouts(data?.payment?.payment?.charges || [])

  const downloadOnClick = async () => {
    setDownloadLoading(true)

    await fetch(
      `/api/pdf?fileName=invoice&paymentId=${paymentData?.id}` || '',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((e) => e.arrayBuffer())
      .then((e) => {
        const a = document.createElement('a')
        const uintArr = new Uint8Array(e)
        const blob = new Blob([uintArr], { type: 'application/pdf' })

        a.href = URL.createObjectURL(blob)

        a.download = `${moment.now().toString()}.pdf`

        a.click()

        setDownloadLoading(false)
      })
      .finally(() => {
        setDownloadLoading(false)
      })
  }

  return (
    <UnstandardizedColumn className="w-full h-full">
      <OverlayHeader
        onClose={exitOverlay}
        onBack={onBack}
        transactionStatus={
          getStatus(paymentData?.status) as TransactionStatusValue
        }
        check={!!paymentData?.check}
      />
      <AmountHeader
        label={'Amount Submitted'}
        amount={(
          ((paymentData?.submittedAmountInCents || 0) * 1.0) /
          100
        ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        paymentSubmitted={moment(parseInt(paymentData?.createdAt || '')).format(
          'MMMM DD, YYYY',
        )}
      />
      <PaymentElements
        {...{
          paymentNode: (
            <Column gap="large" wGrow className="w-full">
              <PaymentDetails
                {...{
                  headerLabel: 'Order Reference',
                  headerValue: data?.payment?.payment?.notes || '',
                  feesCollected: formatter.format(
                    paymentHelper.platformFeeDollars(),
                  ),
                  chargedAmount: formatter.format(
                    paymentHelper.chargedAmountDollars(),
                  ),
                  netPayment: formatter.format(
                    paymentHelper.netAmountDollars(),
                  ),
                  refundedAmount:
                    paymentHelper.refundAmountDollars() > 0
                      ? formatter.format(paymentHelper.refundAmountDollars())
                      : undefined,
                  paymentMethod: paymentMethod?.card?.last4 ? (
                    <PaymentMethod
                      card={{
                        last4: paymentMethod?.card?.last4,
                        brand: paymentMethod?.card?.brand || '',
                      }}
                    />
                  ) : (
                    <PaymentMethod
                      achDebit={{
                        routingNumber: paymentMethod?.achDebit?.routingNumber!,
                      }}
                    />
                  ),
                  billingAddress: (
                    <BillingAddress
                      companyName={paymentData?.companyName}
                      address={address!}
                    />
                  ),
                  contactInfo: (
                    <ContactInfo
                      name={paymentMethod?.billingDetails?.name}
                      email={paymentMethod?.billingDetails?.email}
                    />
                  ),
                }}
              />
              <OverlaySectionPayouts
                title="Payouts"
                payouts={payouts.concat(returnPayouts)}
                onPayoutClick={onPayout ? onPayout : undefined}
                initialExpanded
              />
              <OverlaySectionRefund
                title="Refunds"
                refunds={allRefunds}
                onRefundClick={onRefundItem}
              />
            </Column>
          ),
          detailNode: (
            <AdditionalPaymentDetail
              {...{
                answerForm: paymentData?.answerForm,
                reference: {
                  paymentId: paymentData?.id || '',
                  reference: paymentData?.externalPaymentIntentId || '',
                  failureReason:
                    paymentData?.charges?.find((x) => x.failureMessage != null)
                      ?.failureMessage || undefined,
                  shortIdentifier: paymentData?.shortIdentifier ?? undefined,
                  checkNumber: paymentData?.check?.checkNumber ?? undefined,
                },
              }}
            />
          ),
        }}
      />
      <Box w={'100%'} mt={'auto'}>
        <OverlayFooter
          buttonText="Refund Payment"
          disabled={downloadLoading}
          options={[
            {
              icon: <ArrowDownIcon />,
              onClick: downloadOnClick,
              children: 'Download Receipt',
              isDisabled: downloadLoading,
            },
          ]}
          onButtonClick={onRefund}
          reconciled={paymentData?.reconciled || false}
          onReconciliationClick={(reconciled) => {
            toggleState({
              variables: {
                paymentId: paymentId,
                reconciled: reconciled,
                seen: true,
              },
            })
          }}
        />
      </Box>
    </UnstandardizedColumn>
  )
}
