import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  Icon,
  Spacer,
} from '@chakra-ui/react'
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline'
import { Upload } from 'antd/lib/index'
import { useState } from 'react'
import { Form } from 'ui'
import * as yup from 'yup'
import { Select } from 'chakra-react-select'

const OnboardingDocumentsSchema = yup.object().shape({
  legalName: yup.object().shape({
    type: yup.string().required('Legal name is required'),
    fileBuffer: yup.string(),
  }),
  physicalAddress: yup.object().shape({
    type: yup.string().required('Legal name is required'),
    fileBuffer: yup.string(),
  }),
  taxIdentification: yup.object().shape({
    type: yup.string().required('Legal name is required'),
    fileBuffer: yup.string(),
  }),
})

const documentTypeOptions = [
  {
    value: 'Articles of Incorporation',
    label: 'Articles of Incorporation',
  },
  {
    value: 'A Govt-Issued Business License',
    label: 'A Govt-Issued Business License',
  },
  {
    value: 'A Partnership Agreement',
    label: 'A Partnership Agreement',
  },
  {
    value: 'A Trust Instrument',
    label: 'A Trust Instrument',
  },
  {
    value: 'A Utility Bill or Invoice',
    label: 'A Utility Bill or Invoice',
  },
  {
    value: 'A Lease Agreement',
    label: 'A Lease Agreement',
  },
  {
    value: 'Certified Articles of Incorporation',
    label: 'Certified Articles of Incorporation',
  },
  {
    value: 'IRS Form SS-4',
    label: 'IRS Form SS-4',
  },
  {
    value: 'Tax Return',
    label: 'Tax Return',
  },
  {
    value: 'IRS Form 1099',
    label: 'IRS Form 1099',
  },
]

const { Dragger } = Upload

type UploadedFile = {
  data: string | ArrayBuffer | null
  name: string
}

type FileUploadDraggerProps = {
  onFile: (e: UploadedFile) => void
  accept?: string
}

const FileUploadDragger = (props: FileUploadDraggerProps) => {
  return (
    <Box w="100%" bgColor="white">
      <Dragger
        {...props}
        customRequest={() => {}}
        beforeUpload={async (file) =>
          await new Promise(() => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              props.onFile({
                data: reader.result,
                name: file.name,
              })
            }
          })
        }
      >
        <HStack px="2">
          <Icon as={DocumentArrowUpIcon} boxSize="4" />
          <Text color="purple.600" fontSize="xs" fontWeight="medium">
            Click or drag file to this area to upload
          </Text>
        </HStack>
      </Dragger>
    </Box>
  )
}

export function OnboardingDocuments() {
  const [legalNameFile, setLegalNameFile] = useState('')
  const [physicalAddressFile, setPhysicalAddressFile] = useState('')
  const [taxIdFile, setTaxIdFile] = useState('')
  return (
    <VStack w="600px">
      <Form
        className="w-full"
        initialValues={{
          legalName: {
            type: '',
            fileBuffer: '',
          },
          physicalAddress: {
            type: '',
            fileBuffer: '',
          },
          taxIdentification: {
            type: '',
            fileBuffer: '',
          },
        }}
        validationSchema={OnboardingDocumentsSchema}
        onSubmit={async (values) => {
          console.log(values)
        }}
      >
        {(formik) => {
          return (
            <Flex flexDirection="column" gap="5">
              <Flex flexDirection="column">
                <Text fontSize="lg" color="gray.800">
                  Required Documents
                </Text>
                <Text fontSize="sm" color="gray.500">
                  We need documents to verify your identity and move money on
                  your behalf. You can finish onboarding and upload the
                  documents later.
                </Text>
              </Flex>

              <Card>
                <CardBody>
                  <Flex flexDirection="row" w="100%">
                    <VStack alignItems="left">
                      <Text
                        fontSize="large"
                        fontWeight="medium"
                        color="gray.800"
                      >
                        Full Legal Name
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        Upload a supporting Document:
                      </Text>
                      <UnorderedList fontSize="xs" color="gray.500">
                        <ListItem>Articles of Incorporation</ListItem>
                        <ListItem>A Govt-Issued Business License</ListItem>
                        <ListItem>A Partnership Agreement</ListItem>
                        <ListItem>A Trust Instrument</ListItem>
                      </UnorderedList>
                    </VStack>
                    <Spacer />
                    <VStack alignItems="left">
                      <Text>Document Type</Text>
                      <Select
                        size="sm"
                        selectedOptionStyle="check"
                        name="legalName.type"
                        onMenuClose={() =>
                          formik.setFieldTouched('legalName.type', true)
                        }
                        options={documentTypeOptions.map((opts) => ({
                          value: opts.value,
                          label: opts.label,
                        }))}
                        onChange={(options) => {
                          if (!options) return
                          formik.setFieldValue('legalName.type', options.value)
                        }}
                        value={documentTypeOptions.find(
                          (opts) => opts.value === formik.values.legalName.type,
                        )}
                      />
                      <FileUploadDragger
                        {...{
                          onFile: async (e) => {
                            await formik.setFieldValue(
                              'physicalAddress.fileBuffer',
                              e.data,
                            )
                            setLegalNameFile(e.name)
                            console.log(legalNameFile)
                          },
                          accept: '.pdf',
                        }}
                      />
                    </VStack>
                  </Flex>
                </CardBody>
              </Card>
              <Card w="100%">
                <CardBody w="100%">
                  <Flex flexDirection="row" w="100%">
                    <VStack alignItems="left">
                      <Text
                        fontSize="large"
                        fontWeight="medium"
                        color="gray.800"
                      >
                        Physical Address
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        Upload a supporting Document:
                      </Text>
                      <UnorderedList fontSize="xs" color="gray.500">
                        <ListItem>A Utility Bill or Invoice</ListItem>
                        <ListItem>A Lease Agreement</ListItem>
                        <ListItem>Articles of Incorporation</ListItem>
                        <ListItem>A Govt-Issued Business License</ListItem>
                        <ListItem>A Partnership Agreement</ListItem>
                        <ListItem>A Trust Instrument</ListItem>
                      </UnorderedList>
                    </VStack>
                    <Spacer />
                    <VStack alignItems="left">
                      <Text>Document Type</Text>
                      <Select
                        size="sm"
                        selectedOptionStyle="check"
                        name="physicalAddress.type"
                        onMenuClose={() =>
                          formik.setFieldTouched('physicalAddress.type', true)
                        }
                        options={documentTypeOptions.map((opts) => ({
                          value: opts.value,
                          label: opts.label,
                        }))}
                        onChange={(options) => {
                          if (!options) return
                          formik.setFieldValue(
                            'physicalAddress.type',
                            options.value,
                          )
                        }}
                        value={documentTypeOptions.find(
                          (opts) =>
                            opts.value === formik.values.physicalAddress.type,
                        )}
                      />
                      <FileUploadDragger
                        {...{
                          onFile: async (e) => {
                            await formik.setFieldValue(
                              'physicalAddress.fileBuffer',
                              e.data,
                            )
                            setPhysicalAddressFile(e.name)
                            console.log(physicalAddressFile)
                          },
                          accept: '.pdf',
                        }}
                      />
                    </VStack>
                  </Flex>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Flex flexDirection="row">
                    <VStack alignItems="left">
                      <Text
                        fontSize="large"
                        fontWeight="medium"
                        color="gray.800"
                      >
                        Taxpayer ID
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        Upload a supporting Document:
                      </Text>
                      <UnorderedList fontSize="xs" color="gray.500">
                        <ListItem>Certified Articles of Incorporation</ListItem>
                        <ListItem>IRS Form SS-4</ListItem>
                        <ListItem>Tax Return</ListItem>
                        <ListItem>IRS Form 1099</ListItem>
                      </UnorderedList>
                    </VStack>
                    <Spacer />
                    <VStack alignItems="left">
                      <Text>Document Type</Text>
                      <Select
                        size="sm"
                        selectedOptionStyle="check"
                        name="taxIdentification.type"
                        onMenuClose={() =>
                          formik.setFieldTouched('taxIdentification.type', true)
                        }
                        options={documentTypeOptions.map((opts) => ({
                          value: opts.value,
                          label: opts.label,
                        }))}
                        onChange={(options) => {
                          if (!options) return
                          formik.setFieldValue(
                            'taxIdentification.type',
                            options.value,
                          )
                        }}
                        value={documentTypeOptions.find(
                          (opts) =>
                            opts.value === formik.values.taxIdentification.type,
                        )}
                      />
                      <FileUploadDragger
                        {...{
                          onFile: async (e) => {
                            await formik.setFieldValue(
                              'taxIdentification.fileBuffer',
                              e.data,
                            )
                            setTaxIdFile(e.name)
                            console.log(taxIdFile)
                          },
                          accept: '.pdf',
                        }}
                      />
                    </VStack>
                  </Flex>
                </CardBody>
              </Card>
            </Flex>
          )
        }}
      </Form>
    </VStack>
  )
}
