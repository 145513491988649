import { Box, Stack, Text } from '@chakra-ui/react'

type InfoCardItemProps = {
  label: string
  sublabel: string
  children: React.ReactNode
}

export const InfoCardItem = (props: InfoCardItemProps) => {
  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: '5', lg: '16' }}
      w="100%"
      alignItems="center"
      className="px-4"
    >
      <Box w="sm" flexShrink={0}>
        <Text fontSize="lg" fontWeight="medium">
          {props.label}
        </Text>
        <Text color="gray.500" fontSize="sm">
          {props.sublabel}
        </Text>
      </Box>
      {props.children}
    </Stack>
  )
}
