import { tw } from '@nickeltech/brise'
import { FieldProps, useFormikContext } from 'formik'
import { HTMLProps, Ref, useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import CreatableReactSelect from 'react-select/creatable'
import get from 'lodash/get'
import ContainerProps from '../../types'
import Column from '../Layout/Column'
import { SpacingProps } from '../Layout/Spacing'
import { DisabledTransitionProps } from '../Transitions'
import Label from '../Typography/Label'

const SELECT_HEIGHT = '120px'

export type Option = {
  label: string
  value: any
}

export type NoBorder = {
  noBorder?: boolean
}

export type OptionProps = {
  options: Array<Option>
}
export type SelectProps = {
  label?: string
  creatable?: boolean
  forwadedRef?: Ref<any>
  onChange?: (...args: any) => void
  hasSuccess?: boolean
  initialValue?: string
  onInputChange?: (...args: any) => void
  formatOptionLabel?: (...args: any) => any
  noIndicator?: boolean
  containerHeight?: any
  valueContainer?: any
  indicatorsContainer?: any
  loadingMessage?: any
  noOptionsMessage?: () => any
  onCreateOption?: (...args: any) => void
  onMenuClose?: (...args: any) => void
  isLoading?: boolean
  filterOption?: (e: any) => boolean
  formatLabel?: (value: unknown) => string
  menuIsOpen?: boolean
  selectId?: string
  previewMode?: boolean
  isClearable?: boolean
} & DisabledTransitionProps &
  SpacingProps &
  HTMLProps<HTMLInputElement> &
  Partial<FieldProps> &
  DisabledTransitionProps &
  OptionProps &
  ContainerProps &
  NoBorder & {
    overflowable?: boolean
    closeMenuOnSelect?: boolean
    menuIsOpen?: boolean
    ref?: Ref<any>
    defaultValue?: {
      value: string
      label: string
    }
  }

export const AccountSelectComponent = tw(
  ({ field, form, isClearable = true, ...props }: SelectProps) => {
    const [dom, setDom] = useState<HTMLDocument | null>(null)
    const [menuIsOpen, setMenuIsOpen] = useState<undefined | boolean>(undefined)

    const [touchedMenuOpen, setTouchedMenuOpen] = useState(false)
    const [initClick, setInitClick] = useState(false)

    useEffect(() => {
      if (typeof window !== 'undefined' && window) {
        setDom(window.document)
      }
    }, [typeof window !== 'undefined'])

    return props.creatable ? (
      <CreatableReactSelect
        onBlur={props.onBlur}
        menuIsOpen={props.menuIsOpen || undefined}
        closeMenuOnSelect={props.closeMenuOnSelect || undefined}
        onMenuClose={props.onMenuClose}
        ref={props.forwadedRef}
        formatCreateLabel={(inputValue) =>
          props.options &&
          props.options.length === 0 &&
          props.value !== '' &&
          !props.isLoading
            ? inputValue
            : null
        }
        isClearable={isClearable}
        id={props.name}
        instanceId={props.selectId ? `__${props.selectId}` : '__react_select_1'}
        name={field?.name}
        loadingMessage={props.loadingMessage}
        isLoading={props.isLoading}
        onCreateOption={props.onCreateOption}
        formatOptionLabel={props.formatOptionLabel}
        allowCreateWhileLoading={true}
        isValidNewOption={() => {
          return props.options && props.options.length === 0 && !props.isLoading
        }}
        noOptionsMessage={props.noOptionsMessage}
        filterOption={props.filterOption}
        onChange={(e) => {
          props.onChange && props.onChange(e?.value || '')
        }}
        onInputChange={(value, meta) => {
          if (
            meta.action !== 'menu-close' &&
            meta.action !== 'input-blur' &&
            meta.action !== 'set-value'
          ) {
            props.onInputChange && props.onInputChange(value || '')
          }
        }}
        options={props.options || []}
        placeholder={props.placeholder}
        menuPortalTarget={
          props.overflowable && dom ? dom.querySelector('body') : null
        }
        styles={{
          container: (provided, state) => ({
            ...provided,
            minWidth: 'fit-content',
            width: '99%',
            position: 'relative',
            pointerEvents: props.previewMode ? 'none' : 'auto',
            opacity: props.previewMode ? 0.5 : 1,
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
          }),
          singleValue: (provided) => ({
            ...provided,
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            display: props.noIndicator ? 'none' : 'block',
            ...props.indicatorsContainer,
            position: 'relative',
            bottom: '3px',
          }),
          control: (provided, state) => ({
            ...provided,
            border: props.noBorder ? 'none' : provided.border,
            outline: props.noBorder ? 'none' : provided.outline,
            boxShadow: props.noBorder ? 'none' : provided.boxShadow,
            fontSize: '1rem',
            minWidth: 'fit-content',
            cursor: 'pointer',
            height: '38px !important',
            pointerEvents: props.previewMode ? 'none' : 'auto',
            opacity: props.previewMode ? 0.5 : 1,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: '0px 10px',
            ...props.valueContainer,
            position: 'relative',
            bottom: '3px',
          }),
          input: (provided, state) => ({
            ...provided,
            width: '100%',
            outline: 'none',
            outlineColor: 'transparent',
            boxShadow: 'none',
            '> input': {
              width: '100%',
              boxShadow: 'none',
              color: props.hasSuccess ? '#292524 !important' : provided.color,
            },
            '> input:focus': {
              boxShadow: 'none',
            },
            '&:after': {
              content: `"${props.value}"`,
            },
            cursor: props.noIndicator ? 'text' : 'pointer',
          }),
          menu: (provided, state) => ({
            ...provided,
          }),
          menuList: (provided, state) => ({
            fontSize: '0.875rem',
            maxHeight: SELECT_HEIGHT,
            '::-webkit-scrollbar': {
              display: 'none',
            },
            ...provided,
          }),
        }}
      />
    ) : (
      <ReactSelect
        menuIsOpen={menuIsOpen || touchedMenuOpen}
        closeMenuOnSelect={props.closeMenuOnSelect || undefined}
        ref={props.forwadedRef}
        instanceId={props.selectId ? `__${props.selectId}` : '__react_select_1'}
        name={field?.name}
        value={props.value}
        isSearchable={true}
        isClearable={isClearable}
        loadingMessage={props.loadingMessage}
        isLoading={props.isLoading}
        noOptionsMessage={props.noOptionsMessage}
        onChange={(e) => {
          props.onChange && props.onChange(e || '')
        }}
        onInputChange={(e, meta) => {
          if (meta.prevInputValue == '' && e.length > 1) {
            // This is from autofill
            setMenuIsOpen(false)
            props.onInputChange && props.onInputChange(e)
          } else if (
            meta.action != 'set-value' &&
            meta.action != 'menu-close'
          ) {
            setMenuIsOpen(true)
          }
        }}
        onFocus={() => {
          setTouchedMenuOpen(true)
          setMenuIsOpen(true)
        }}
        onBlur={() => {
          setTouchedMenuOpen(false)
        }}
        onMenuOpen={() => {
          if (!initClick) {
            setTouchedMenuOpen(false)
            setInitClick(true)
          } else {
            setTouchedMenuOpen(true)
          }
        }}
        onMenuClose={() => {
          setMenuIsOpen(false)
          setTouchedMenuOpen(false)
        }}
        options={(props.options as any) || []}
        placeholder={props.placeholder}
        menuPortalTarget={
          props.overflowable && dom ? dom.querySelector('body') : null
        }
        styles={{
          menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999,
          }),
          container: (provided, state) => ({
            ...provided,
            minWidth: 'fit-content',
            position: 'relative',
            width: '100%',
            pointerEvents: props.previewMode ? 'none' : 'auto',
            opacity: props.previewMode ? 0.5 : 1,
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: props.hasSuccess ? '#292524' : provided.color,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: '#78716c',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            padding: '4px 0px',
            ...props.indicatorsContainer,
            position: 'relative',
            bottom: '3px',
          }),
          control: (provided, state) => ({
            ...provided,
            border: props.noBorder ? 'none' : provided.border,
            outline: props.noBorder ? 'none' : provided.outline,
            boxShadow: props.noBorder ? 'none' : provided.boxShadow,
            fontSize: '1rem',
            minWidth: 'fit-content',
            cursor: 'pointer',
            height: props.containerHeight,
            pointerEvents: props.previewMode ? 'none' : 'auto',
            opacity: props.previewMode ? 0.5 : 1,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: '4px 10px',
            ...props.valueContainer,
            position: 'relative',
            bottom: '3px',
          }),
          input: (provided, state) => ({
            ...provided,
          }),
          menu: (provided, state) => ({
            ...provided,
            position: 'relative',
            bottom: '3px',
          }),
          menuList: (provided, state) => ({
            fontSize: '0.875rem',
            maxHeight: SELECT_HEIGHT,
            '::-webkit-scrollbar': {
              display: 'none',
            },
            ...provided,
          }),
        }}
      />
    )
  },
)``

export const AccountSelectInput = (props: SelectProps) => {
  const { setFieldValue, values } = useFormikContext()

  useEffect(() => {
    if (props.initialValue) {
      setFieldValue(props.name || '', props.initialValue)
    }
  }, [props.initialValue])

  return (
    <Column
      gap="small"
      {...{ disabled: props.disabled }}
      className={props.className}
    >
      {props.label && props.label.length > 0 ? (
        <Label variant="secondary">{props.label}</Label>
      ) : (
        ''
      )}
      <AccountSelectComponent
        {...props}
        forwadedRef={props.forwadedRef}
        value={
          props.options.find(
            (e) =>
              get(values, props.name || '', props.initialValue || '') ===
              e.value,
          ) as any
        }
        overflowable
        style={{
          pointerEvents: props.previewMode ? 'none' : 'auto',
          opacity: props.previewMode ? 0.5 : 1,
        }}
        previewMode={props.previewMode}
        containerHeight={props.containerHeight}
        isLoading={props.isLoading}
        noOptionsMessage={props.noOptionsMessage}
        loadingMessage={props.loadingMessage}
        onCreateOption={props.onCreateOption}
        creatable={props.creatable}
        isClearable={props.isClearable}
        onBlur={props.onBlur}
        filterOption={props.filterOption}
        formatOptionLabel={props.formatOptionLabel}
        onMenuClose={props.onMenuClose}
        formatLabel={props.formatLabel}
        menuIsOpen={props.menuIsOpen}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e)
          } else {
            setFieldValue(props.name || '', e.value)
          }
        }}
        onInputChange={(e) => {
          setFieldValue(props.name || '', e)
        }}
      />
    </Column>
  )
}

export default AccountSelectInput
