import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import Dropdown from '.'
import Row from '../v2/layout/Row'
import Text from '../Typography/Text'

export type DropdownOption = {
  label: string
  onClick?: (label: string) => void
}

export type SimpleDropdownProps = {
  label: string
  options: Array<DropdownOption>
}

export function SimpleDropdown({ label, options }: SimpleDropdownProps) {
  const items = options.map((o, index) => {
    return (
      <Menu.Item key={o.label}>
        <Row
          spacingY="small"
          spacingX="small"
          className={classNames({
            'rounded-t-md': index === 0,
            'rounded-b-md': index === options.length - 1,
            'w-full cursor-pointer hover:bg-gray-200 p-2': true,
          })}
          onClick={() => o.onClick && o.onClick(label)}
        >
          <Text variant="tertiary">{o.label}</Text>
        </Row>
      </Menu.Item>
    )
  })
  return (
    <Dropdown label={label}>
      <Menu.Items className="absolute right-0 mt-2 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {items}
      </Menu.Items>
    </Dropdown>
  )
}

export default SimpleDropdown
