import currency from 'currency.js'
import { Fee, FeeString, Loan, PaymentMethodSelectors } from './types'

export class PaymentSummary {
  amount: number
  fees: Fee[]
  paymentMethod?: PaymentMethodSelectors
  loan?: Loan

  constructor(
    amount: number,
    fees: Fee[],
    paymentMethod?: PaymentMethodSelectors,
    loan?: Loan,
  ) {
    this.amount = amount
    this.fees = fees
    this.paymentMethod = paymentMethod
    this.loan = loan
  }

  amountWithoutFeeCents(): number {
    const feeAmount = this.fees.reduce((acc, fee) => acc + fee.amountCents, 0)
    return this.amount - feeAmount - (this.loan?.feeAmountInCents || 0)
  }

  platformFeeAmountCents(): number {
    const platformFeeAmounts = this.fees.filter(
      (fee) => fee.type === 'PLATFORM_FEE',
    )
    return platformFeeAmounts.reduce((acc, fee) => acc + fee.amountCents, 0)
  }

  amountCents(): number {
    return this.amount
  }

  amountDollars(): number {
    return this.amount / 100
  }

  amountWithoutFeeDollars(): number {
    return this.amountWithoutFeeCents() / 100
  }

  amountWithoutFeeString(): string {
    return currency(this.amountWithoutFeeCents(), { fromCents: true }).format()
  }

  platformFeeAmountDollars(): number {
    return this.platformFeeAmountCents() / 100
  }

  feeTotalCents(): number {
    return this.fees.reduce((acc, fee) => acc + fee.amountCents, 0)
  }

  feeTotalDollars(): number {
    return currency(this.feeTotalCents(), { fromCents: true }).value
  }

  feeTotalString() {
    const feeTotal = this.fees.reduce((acc, fee) => acc + fee.amountCents, 0)
    return currency(feeTotal, { fromCents: true }).format()
  }

  subtotalString(): string {
    return currency(this.amountWithoutFeeCents(), { fromCents: true }).format()
  }

  totalString(): string {
    return currency(this.amount, {
      fromCents: true,
    }).format()
  }

  feePresenter(feeName: string): FeeString | null {
    const fee = this.fees.find((fee) => fee.name === feeName)
    if (!fee) {
      return null
    }

    return this.getFeeString(fee)
  }

  feePresenters(): FeeString[] {
    return this.fees.map((fee) => this.getFeeString(fee))
  }

  principalAmountCents(): number {
    return this.loan?.principalAmountInCents || 0
  }

  principalAmountDollars(): number {
    return currency(this.principalAmountCents(), { fromCents: true }).value
  }

  factoringFeeCents(): number {
    return this.loan?.feeAmountInCents || 0
  }

  factoringFeeDollars(): number {
    return currency(this.factoringFeeCents(), { fromCents: true }).value
  }

  private getFeeString(fee: Fee) {
    return {
      name: fee.name,
      amount: currency(fee.amountCents, { fromCents: true }).format(),
    }
  }
}
