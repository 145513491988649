export function ModalSelect({
  title,
  options,
  onChange,
}: {
  title: string
  options: string[]
  onChange: (value: string) => void
}) {
  const optionElements = options.map((option) => (
    <option key={option}>{option}</option>
  ))
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <label htmlFor="role" className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <div>
        <select
          id="role"
          name="role"
          className="w-full block focus:ring-gray-300 focus:border-gray-300 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          onChange={(e) => onChange(e.target.value)}
        >
          {optionElements}
        </select>
      </div>
    </div>
  )
}

export function ModalInput({
  title,
  onChange,
  maxlength,
  autocomplete,
  type,
  name,
}: {
  title: string
  onChange: (value: string) => void
  maxlength?: number
  autocomplete?: string
  type?: string
  name?: string
}) {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      <div>
        <input
          name={name}
          type={type}
          autoComplete={autocomplete}
          required
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-nickel-purple focus:border-nickel-purple sm:text-sm"
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxlength}
        />
      </div>
    </div>
  )
}
