import { useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import { Column } from 'ui'
import {
  BankAccountsDocument,
  GetReturnDocument,
  PaymentActivitySource,
} from '../../operations-types'
import {
  getTransactionStatusFromActivity,
  reportErrorIfExists,
} from '../../lib/utils'
import { OverlayHeaderV2 } from '../payments/OverlayHeader'
import AmountHeader from '../payments/PaymentAmountSubmitted'
import PaymentElements from '../payments/PaymentElements'
import {
  BillingAddress,
  ContactInfo,
  PaymentMethod,
} from '../payments/PaymentDetails'
import AdditionalPaymentDetail from '../payments/AdditionalPaymentDetail'
import { TransactionActivity } from './TransactionActivity'
import {
  paymentMethodIntoIdentifier,
  payoutOrBankAccountIntoIdentifier,
} from './PaymentInnerOverlay'
import currency from 'currency.js'
import RefundDetails from './components/RefundDetails'
import { ActivityNote, QueryOptsActivityNote } from '../ap/ActivityNoteModal'

type ReturnInnerOverlayProps = {
  returnId: string
  onPaymentClick?: (paymentId: string) => void
  exitOverlay: () => void
  onBack?: () => void
  queryOpts: QueryOptsActivityNote
}

export default function ReturnInnerOverlay({
  returnId,
  onPaymentClick,
  exitOverlay,
  onBack,
  queryOpts,
}: ReturnInnerOverlayProps) {
  const { loading, data, error } = useQuery(GetReturnDocument, {
    variables: { returnId: returnId },
  })
  const { data: organizationInfo, loading: bankAccountLoading } =
    useQuery(BankAccountsDocument)

  const defaultBankAccount =
    organizationInfo?.organization?.organization?.accountInfo?.bankAccounts?.[0]

  reportErrorIfExists(data?.achReturn?.error?.message || error)

  if (loading || bankAccountLoading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const achReturn = data?.achReturn?.achReturn
  const payment = achReturn?.charge?.payment

  const paymentMethod = payment?.paymentMethod

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <OverlayHeaderV2
        onClose={exitOverlay}
        onBack={onBack}
        transactionStatus={
          getTransactionStatusFromActivity(
            achReturn?.activity?.status || '',
            achReturn?.activity?.flags || [],
          ).status
        }
      />
      <AmountHeader
        label="Return Amount"
        amount={(((achReturn?.amountCents || 0) * 1.0) / 100).toLocaleString(
          'en-US',
          { style: 'currency', currency: 'USD' },
        )}
      />
      <TransactionActivity
        payoutMethod={paymentMethodIntoIdentifier(
          paymentMethod ?? null,
          false,
          achReturn?.createdAt || '',
          'Sent',
        )}
        paymentMethod={payoutOrBankAccountIntoIdentifier(
          achReturn?.payout?.payoutMethod || defaultBankAccount || null,
          achReturn?.payout?.paidAt || null,
          'Initiated',
        )}
        completed={!!achReturn?.payout?.paidAt}
      />
      <PaymentElements
        {...{
          paymentNode: (
            <Column gap="large" wGrow className="w-full">
              <RefundDetails
                {...{
                  return: true,
                  headerLabel: 'Return',
                  linkText: 'View Original Payment',
                  onLinkClick: onPaymentClick
                    ? () => onPaymentClick(payment?.id!)
                    : undefined,
                  refundedAmount: currency(achReturn?.amountCents || 0, {
                    fromCents: true,
                  }).format(),
                  paymentMethod: paymentMethod?.card?.last4 ? (
                    <PaymentMethod
                      card={{
                        last4: paymentMethod?.card?.last4,
                        brand: paymentMethod?.card?.brand || '',
                      }}
                    />
                  ) : (
                    <PaymentMethod
                      achDebit={{
                        routingNumber: paymentMethod?.achDebit?.routingNumber!,
                      }}
                    />
                  ),
                  billingAddress: (
                    <BillingAddress
                      companyName={payment?.companyName}
                      address={paymentMethod?.billingDetails?.address}
                    />
                  ),
                  contactInfo: (
                    <ContactInfo
                      name={paymentMethod?.billingDetails?.name}
                      email={paymentMethod?.billingDetails?.email}
                    />
                  ),
                }}
              />
              <ActivityNote
                {...{
                  activityId: achReturn?.activity?.id || '',
                  initialNote: achReturn?.activity?.note?.note || '',
                  sourceType: PaymentActivitySource.Refund,
                  reconciled: achReturn?.activity?.note?.reconciled || false,
                  flagged: achReturn?.activity?.note?.flagged || false,
                  queryOpts,
                }}
              />
            </Column>
          ),
          detailNode: (
            <AdditionalPaymentDetail
              {...{
                reference: {
                  returnId: achReturn?.id || '',
                  reference: achReturn?.externalReturnId || '',
                },
              }}
            />
          ),
        }}
      />
    </Flex>
  )
}
