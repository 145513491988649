import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

export type DropdownProps = {
  label: string | React.ReactNode
  children: React.ReactNode
  className?: string
}

export function Dropdown({ label, children, className = '' }: DropdownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={
            'inline-flex w-full justify-center items-center border border-gray-500 gap-x-1.5 rounded bg-white px-3 h-6 min-h-6 text-xs font-semibold text-gray-500 shadow-sm hover:bg-gray-200' +
            ' ' +
            className
          }
        >
          {label}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {children}
      </Transition>
    </Menu>
  )
}

export default Dropdown
