import * as Sentry from '@sentry/react'

function getSentryEnvironment() {
  if (import.meta.env.VITE_SENTRY_ENVIRONMENT) {
    return import.meta.env.VITE_SENTRY_ENVIRONMENT
  }

  if (import.meta.env.MODE === 'production') {
    return 'production'
  } else {
    return 'development'
  }
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracesSampleRate: 0.5,
  environment: getSentryEnvironment(),
})

export default Sentry
