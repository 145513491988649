import {
  Input,
  Textarea,
  HStack,
  VStack,
  StackDivider,
  Select,
} from '@chakra-ui/react'
import { AddressAutocomplete } from '../v2/input/AddressAutocomplete'

export type InputPreviewProps = {
  inputType: string
}

export function AddressInputPreview({}) {
  return (
    <VStack
      w="100%"
      gap="0"
      spacing="0"
      divider={<StackDivider borderColor={'gray.200'} />}
      pointerEvents="none"
    >
      <HStack
        borderRight="1px solid"
        borderLeft="1px solid"
        borderTopRadius={'base'}
        borderTop={'1px solid'}
        borderColor="gray.300"
        w="100%"
      >
        <AddressAutocomplete
          name="billingAddress.streetAddress"
          {...{
            valueKey: 'billingAddress',
            autocomplete: false,
            setAutocomplete: () => {},
            apiKey: '',
          }}
        />
      </HStack>

      <HStack w="100%" gap="0" spacing="0">
        <Input
          id="billingAddress.city"
          fontSize="md"
          placeholder="City"
          borderTopRadius="0"
          borderBottomRightRadius="0"
          borderColor="gray.300"
          borderTop="0"
          _focus={{
            boxShadow: 'none',
            outline: 'none',
            borderColor: 'gray.300',
          }}
          _invalid={{
            borderColor: 'red.500',
            borderTop: '1px solid',
            borderTopRadius: '0',
          }}
        />

        <Select
          placeholder="State"
          color="gray.400"
          fontSize="lg"
          borderRadius="0"
          borderColor="gray.300"
          borderTop="0"
          _focus={{
            boxShadow: 'none',
            outline: 'none',
            borderColor: 'gray.300',
          }}
          _invalid={{
            borderColor: 'red.500',
            borderTop: '1px solid',
            borderTopRadius: '0',
          }}
          h="43px"
        />

        <Input
          placeholder="Zip Code"
          fontSize="md"
          borderTopRadius="0"
          borderBottomLeftRadius="0"
          borderColor="gray.300"
          borderTop="0"
          _focus={{
            boxShadow: 'none',
            outline: 'none',
            borderColor: 'gray.300',
          }}
          _invalid={{
            borderColor: 'red.500',
            borderTop: '1px solid',
            borderTopRadius: '0',
          }}
        />
      </HStack>
    </VStack>
  )
}

export function InputPreview(props: InputPreviewProps) {
  return (
    <>
      {props.inputType === 'address' ? (
        <AddressInputPreview />
      ) : props.inputType === 'multi' ? (
        <Textarea
          placeholder="Their written response"
          pointerEvents="none"
          size="sm"
          fontSize="md"
          borderColor="gray.300"
          borderRadius="base"
        />
      ) : props.inputType === 'phone' ? (
        <Input
          fontSize="md"
          borderRadius="base"
          placeholder="Their phone response"
          pointerEvents="none"
          size="md"
          borderColor="gray.300"
        />
      ) : props.inputType === 'number' ? (
        <Input
          fontSize="md"
          borderRadius="base"
          placeholder="Their number response"
          pointerEvents="none"
          size="md"
          borderColor="gray.300"
        />
      ) : (
        <Input
          fontSize="md"
          borderRadius="base"
          placeholder="Their written response"
          pointerEvents="none"
          size="md"
          borderColor="gray.300"
        />
      )}
    </>
  )
}

export default InputPreview
