import { lowerCase } from 'lodash'
import capitalize from 'lodash/capitalize'
import { Payment, PaymentStatus } from '../../types'
import { PillStatus } from 'ui'

const STATUS_TO_READABLE: { [status in PaymentStatus]: string } = {
  PENDING: 'In Progress',
  SUCCEEDED: 'Completed',
  FAILED: 'Failed',
  REFUNDED: 'Refunded',
  CREATED: 'Created',
}

export type Status =
  | 'Completed'
  | 'Submitted'
  | 'Confirmed'
  | 'Declined'
  | 'Returned'
  | 'Chargeback'
  | 'Refunded'
  | 'Voided'
  | 'Unknown'

export type StatusCell = {
  label: string
  status: PillStatus
  tooltipText?: string
}

export const StatusMap: Record<Status, StatusCell> = {
  Completed: {
    label: 'Completed',
    status: 'success',
    tooltipText: 'The payment is completed and paid out.',
  },
  Submitted: {
    label: 'Submitted',
    status: 'info',
    tooltipText:
      'The payment has been submitted to the bank for processing and is awaiting confirmation.',
  },
  Confirmed: {
    label: 'Confirmed',
    status: 'info',
    tooltipText:
      'The payment is confirmed by the bank and funds will be deducted from your customer.',
  },
  Declined: {
    label: 'Declined',
    status: 'error',
    tooltipText:
      'The payment could not be processed. The reason for the decline is in the payment details.',
  },
  Returned: {
    label: 'Returned',
    status: 'error',
    tooltipText:
      'The payment was declined by the bank and any associated payouts have been returned.',
  },
  Chargeback: {
    label: 'Chargeback',
    status: 'error',
    tooltipText:
      'The payment was returned to your customer due to a chargeback',
  },
  Refunded: {
    label: 'Refunded',
    status: 'warning',
    tooltipText: 'The payment was refunded.',
  },
  Voided: {
    label: 'Voided',
    status: 'warning',
    tooltipText: 'The payment was voided.',
  },
  Unknown: {
    label: 'Unknown',
    status: 'info',
    tooltipText: 'The payment status is unknown.',
  },
}

export function getStatus(status: Payment['status']) {
  if (!status?.status) {
    return 'Unknown'
  }

  const statusReadable = STATUS_TO_READABLE[status.status as PaymentStatus]

  if (status.substatus) {
    if (status.status === 'PENDING') {
      if (status.substatus === 'AUTHORIZING') {
        return 'Submitted'
      }

      if (status.substatus === 'ACCEPTED') {
        return 'Confirmed'
      }
    }

    if (status.status === 'FAILED') {
      if (status.substatus === 'DECLINED') {
        return 'Declined'
      }

      if (status.substatus === 'RETURNED') {
        return 'Returned'
      }
    }

    if (status.status === 'REFUNDED') {
      return 'Voided'
    }

    return `${statusReadable} - ${capitalize(lowerCase(status.substatus))}`
  }

  return statusReadable
}
