import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'
import Icon from '../Icons'
import { SectionedProps } from '../Layout'
import Row from '../Layout/Row'
import Header from '../Typography/Header'

export type CardHeaderProps = {
  onClose?: (e: any) => void
  onBack?: (e: any) => void
  title?: string
} & ContainerProps &
  SectionedProps

export const CardHeaderContainer = tw(Row)`

`

export const CardHeader = (props: CardHeaderProps) => {
  if (!props.onClose && !props.onBack && !props.title) return <></>

  return (
    <CardHeaderContainer
      around
      y="center"
      grow
      spacing="baseNarrow"
      sectioned={props.sectioned}
    >
      <Row x="left" y="center" grow>
        {props.onBack ? (
          <Icon
            {...{
              onClick: props.onBack,
              variant: 'secondary',
              name: 'leftArrow',
            }}
          />
        ) : (
          ''
        )}
      </Row>
      <Row x="center" y="center" grow>
        {props.title ? <Header variant="secondary">{props.title}</Header> : ''}
      </Row>
      <Row x="right" y="center" grow>
        {props.onClose ? (
          <Icon
            {...{ onClick: props.onClose, variant: 'secondary', name: 'xMark' }}
          />
        ) : (
          ''
        )}
      </Row>
    </CardHeaderContainer>
  )
}

export default CardHeader
