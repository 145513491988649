import { Flex, Center, Input, Icon } from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'

export type QuestionPreviewInputProps = {
  onChange: (e: any) => void
  value?: string
  placeholder?: string
  inputWidth?: string
  hideIcon?: boolean
  name?: string
  inputValue?: string
}

export const NewQuestionInput = (props: QuestionPreviewInputProps) => {
  const [hideIcon, setHideIcon] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)
  const focusOnClick = () => {
    inputRef?.current?.focus()
  }
  return (
    <Flex flexDirection="row" w="100%">
      <Center w="100%">
        {hideIcon ? null : (
          <Icon
            as={PencilSquareIcon}
            boxSize="4"
            onClick={focusOnClick}
            cursor="pointer"
          />
        )}
        <Input
          w="100%"
          ref={inputRef}
          name={props.name}
          p="1"
          border="0"
          _hover={{ border: '0', boxShadow: '0' }}
          _focus={{
            border: '0',
            boxShadow: '0',
            borderColor: 'white',
          }}
          onFocus={() => {
            setHideIcon(true)
          }}
          onBlur={() => {
            setHideIcon(false)
          }}
          value={props.inputValue}
          onChange={(e) => {
            props.onChange(e)
          }}
          fontSize="sm"
          fontWeight="semibold"
          placeholder={props.placeholder}
          sx={{
            '::placeholder': {
              color: 'black',
              fontSize: 'sm',
              fontWeight: 'semibold',
              _hover: {
                color: 'gray.500',
              },
              _focus: {
                color: 'gray.500',
              },
            },
            ':focus': {
              boxShadow: 'none',
              borderColor: 'transparent',
              '::placeholder': {
                color: 'gray.400',
              },
            },
            ':hover::placeholder': {
              color: 'gray.400',
            },
          }}
        />
      </Center>
    </Flex>
  )
}

export default NewQuestionInput
