import { useLazyQuery } from '@apollo/client'
import { Card, Flex } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { ACHForm, GoBackButton, MerchantFormData, PaymentOption } from 'ui'
import {
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  PaymentMethod,
} from '../../../operations-types'
import PlaidLink from '../../onboardingV2/PlaidLink'
import { getFeeString } from '../SchedulePayment'
import { useFeatureFlagEnabled } from 'posthog-js/react'

type PaymentMethodPickerProps = {
  onPayByCardClicked: () => void
  onPayByBankClicked: () => void
  onManualPayByBankClicked: () => void
  onGoBackClicked: () => void
  onBankAccountAdded: (paymentMethod: PaymentMethod) => void
  achEnabled?: boolean
  cardEnabled?: boolean
  setLoading?: (loading: boolean) => void
}

export function ManualACHDetails() {
  const { values, setFieldValue } = useFormikContext<MerchantFormData>()
  const [search, { data, loading: achLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument)

  return (
    <Card p={4}>
      <ACHForm
        loading={achLoading}
        routingNumber={values.paymentMethodDetails?.bankRoutingNumber || ''}
        accountNumber={values.paymentMethodDetails?.bankAccountNumber || ''}
        confirmedAccountNumber={
          values.paymentMethodDetails?.confirmedBankAccountNumber || ''
        }
        accountSelectInputName="paymentMethodDetails.accountType"
        bankName={data?.bankRoutingNumber?.bankRoutingNumber?.bankName}
        onChangeRoutingNumber={(routingNumber: string) => {
          if (routingNumber.length === 9) {
            search({
              variables: {
                bankRoutingNumber: routingNumber,
              },
            })
          }
          setFieldValue('paymentMethodDetails.bankRoutingNumber', routingNumber)
        }}
        onChangeAccountNumber={(accountNumber: string) =>
          setFieldValue('paymentMethodDetails.bankAccountNumber', accountNumber)
        }
        onChangeConfirmAccountNumber={(confirmAccountNumber: string) =>
          setFieldValue(
            'paymentMethodDetails.confirmedBankAccountNumber',
            confirmAccountNumber,
          )
        }
      />
    </Card>
  )
}

export function PaymentMethodPicker({
  onPayByCardClicked,
  onPayByBankClicked,
  onManualPayByBankClicked,
  onGoBackClicked,
  achEnabled = true,
  cardEnabled = true,
  onBankAccountAdded,
  setLoading,
}: PaymentMethodPickerProps) {
  const plaidLinkDisabled = useFeatureFlagEnabled('plaidLinkDisabled')

  return (
    <Flex flexDirection="column" gap={4}>
      {cardEnabled && (
        <PaymentOption
          title="Add a Card"
          icon="creditCard"
          feeString={getFeeString()}
          selected={false}
          onClick={onPayByCardClicked}
          description="Your payment arrives the next business day."
        />
      )}

      {achEnabled && !plaidLinkDisabled && (
        <PlaidLink
          methodType="payment"
          onAddPaymentMethod={onBankAccountAdded}
          setLoading={setLoading}
          component={
            <PaymentOption
              title="Add a Bank Account"
              icon="wallet"
              feeString="No Fees"
              selected={false}
              onClick={onPayByBankClicked}
              description="Your payment arrives in 2-3 business days."
            />
          }
        />
      )}
      {achEnabled && plaidLinkDisabled && (
        <PaymentOption
          title="Add a Bank Account"
          icon="wallet"
          feeString="No Fees`"
          selected={false}
          onClick={onManualPayByBankClicked}
          description="Your payment arrives in 2-3 business days."
        />
      )}
      <GoBackButton onClick={onGoBackClicked} />
    </Flex>
  )
}
