import { useQuery } from '@apollo/client'
import { Box } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'ui'
import { REFUNDS_URL } from '../../../lib/urls'
import {
  GetRefundsDocument,
  GetRefundsQueryVariables,
} from '../../../operations-types'
import { Refunds, Refund } from '../../../types'
import RefundsOverlay from './RefundsOverlay'
import { StatusCell } from '../utils'

type RefundsTableProps = {
  queryOpts: GetRefundsQueryVariables
  onPageChange: (page: number) => void
}

type RefundData = {
  createdAt: number
  status: StatusCell
}

const statusMap: Record<string, StatusCell> = {
  Succeeded: {
    label: 'Succeeded',
    status: 'success',
  },
  Pending: {
    label: 'Pending',
    status: 'info',
  },
  Failed: {
    label: 'Failed',
    status: 'error',
  },
  Canceled: {
    label: 'Canceled',
    status: 'warning',
  },
  '': {
    label: 'Unknown',
    status: 'warning',
  },
}

function getPayout(refund: Refund) {
  if (refund.payout?.paidAt) {
    return {
      availableOn: refund.payout?.paidAt,
      payoutId: refund.payout?.id,
      status: 'Sent',
    }
  }

  if (refund.charge.payout) {
    return {
      status: 'Processing',
    }
  }

  return null
}

function getRefundData(refunds: Refunds) {
  return refunds.map((refund) => ({
    createdAt: parseInt(refund.createdAt || ''),
    status: statusMap[capitalize(refund.status || '')],
    id: refund.id || '',
    name: refund.charge.payment?.paymentMethod?.billingDetails?.name || '',
    amount: (refund.amountCents || 0) / 100,
    paymentAmount: (refund.charge.payment?.amountInCents || 0) / 100,
    payout: getPayout(refund),
  }))
}

export default function RefundsTable({
  queryOpts,
  onPageChange,
}: RefundsTableProps) {
  const { loading, data } = useQuery(GetRefundsDocument, {
    variables: {
      ...queryOpts,
    },
    pollInterval: 30000,
  })

  const navigate = useNavigate()

  const refunds = useMemo(() => data?.refunds?.refunds || [], [data])

  const [refundData, setRefundData] = useState<RefundData[]>([])

  useEffect(() => {
    const data = getRefundData(refunds)
    setRefundData(data)
  }, [refunds])

  const table = (
    <Table
      {...{
        loading,
        roundingStyle: 'bottom',
        headers: [
          {
            type: 'date',
            keyName: 'createdAt',
            label: 'Date',
          },
          {
            type: 'description',
            keyName: 'id',
            label: 'ID',
            grow: 2,
          },
          {
            type: 'status',
            keyName: 'status',
            label: 'Status',
          },
          {
            type: 'description',
            keyName: 'name',
            label: 'Customer',
          },
          {
            type: 'currency',
            keyName: 'amount',
            label: 'Refund Amount',
          },
          {
            type: 'currency',
            keyName: 'paymentAmount',
            label: 'Payment Amount',
          },
          {
            type: 'payout',
            keyName: 'payout',
            label: 'Payout',
          },
        ],
        onPage: onPageChange,
        data: refundData,
        onClick: (e) => {
          navigate(`${REFUNDS_URL}/${e.id}`)
        },
      }}
    />
  )

  return (
    <Box w="100%">
      {table}
      <RefundsOverlay />
    </Box>
  )
}
