import { StackDivider, VStack } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { AddressInput, MerchantFormData, ValidatingInput } from 'ui'

import { PaymentFormData } from 'ui'

export default function BraintreeAddressDetails({
  isMobile,
  popupContainerTarget,
}: {
  isMobile?: boolean
  popupContainerTarget?: string
}) {
  const formik = useFormikContext<PaymentFormData | MerchantFormData>()

  const { values, setFieldTouched } = useFormikContext<
    PaymentFormData | MerchantFormData
  >()

  useEffect(() => {
    if ((values as PaymentFormData | MerchantFormData).billingAddress?.state) {
      setFieldTouched('billingAddress.state')
    }

    if (
      (values as PaymentFormData | MerchantFormData).billingAddress?.zipCode
    ) {
      setFieldTouched('billingAddress.zipCode')
    }

    if ((values as PaymentFormData | MerchantFormData).billingAddress?.city) {
      setFieldTouched('billingAddress.city')
    }
  }, [values])

  return (
    <>
      <VStack
        flexDirection="column"
        w="100%"
        gap="0"
        spacing="0"
        divider={
          <StackDivider
            borderColor={
              (!!formik.errors.billingAddress?.fullName &&
                !!formik.touched.billingAddress?.fullName) ||
              (formik.errors.email && formik.touched.email)
                ? 'red.500'
                : 'gray.200'
            }
          />
        }
      >
        <ValidatingInput
          id="billingAddress.fullName"
          onChange={formik.handleChange}
          validated={!!formik.touched.billingAddress?.fullName}
          value={formik.values.billingAddress?.fullName}
          onBlur={formik.handleBlur}
          isInvalid={
            !!formik.errors.billingAddress?.fullName &&
            !!formik.touched.billingAddress?.fullName
          }
          label="Your Contact Information"
          placeholder="Full Name"
          borderBottomRadius="0"
          borderColor="gray.300"
          borderBottom="0"
          _focus={{
            boxShadow: 'none',
            outline: 'none',
            borderColor: 'gray.300',
          }}
          _invalid={{
            borderColor: 'red.500',
            borderBottom: '1',
            borderBottomRadius: '0',
          }}
        />

        <ValidatingInput
          id="email"
          onChange={(e) => {
            formik.setFieldValue('email', e.target.value.replace(/\s/g, ''))
          }}
          validated={!!formik.touched.email}
          value={formik.values.email}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.email && !!formik.touched.email}
          error={formik.errors.email as string}
          placeholder="Email"
          borderTopRadius="0"
          borderColor="gray.300"
          borderTop="0"
          _focus={{
            boxShadow: 'none',
            outline: 'none',
            borderColor: 'gray.300',
          }}
          _invalid={{
            borderColor: 'red.500',
            borderTop: '1',
            borderTopRadius: '0',
          }}
        />
      </VStack>
      <AddressInput
        valueKey="billingAddress"
        apiKey={import.meta.env.VITE_SMARTY_STREETS_KEY || ''}
        popupContainerTarget={
          !!popupContainerTarget ? popupContainerTarget : undefined
        }
      />
    </>
  )
}
