import { Box } from '@chakra-ui/react'
import { useState } from 'react'
import { Header, TabAndComponent, Tabs } from 'ui'
import { GetRefundsQueryVariables, SortOrder } from '../../../operations-types'
import RefundsTable from './RefundsTable'
import { PageLayout } from '../../layout/PageLayout'

type TabState = 'All' | 'In Progress' | 'Completed' | 'Failed'

const TAB_TO_STATUSES: { [state in TabState]: string[] } = {
  All: ['pending', 'failed', 'succeeded', 'canceled'],
  'In Progress': ['pending'],
  Completed: ['succeeded'],
  Failed: ['failed'],
}

const RENDERED_TABS: TabState[] = ['All', 'In Progress', 'Completed', 'Failed']

export default function Refunds() {
  const [queryOpts, setQueryOpts] = useState<
    NonNullable<GetRefundsQueryVariables>
  >({
    status: TAB_TO_STATUSES['All'],
    page: 1,
    pageSize: 20,
    sortBy: SortOrder.DateDesc,
    startDateEpochMillis: null,
    endDateEpochMillis: null,
  })

  const tabs: Array<TabAndComponent> = RENDERED_TABS.map((tab) => {
    return {
      tab: {
        name: tab,
        onClick: (name: string) => {
          setQueryOpts({
            ...queryOpts,
            status: TAB_TO_STATUSES[name as TabState],
          })
        },
      },
      component: (
        <RefundsTable
          queryOpts={queryOpts}
          onPageChange={(page) => {
            setQueryOpts({
              ...queryOpts,
              page: page,
            })
          }}
        />
      ),
    }
  })

  return (
    <PageLayout>
      <Box pb={4}>
        <Header variant="page">Refunds</Header>
      </Box>
      <Box>
        <Tabs tabs={tabs} header between />
      </Box>
    </PageLayout>
  )
}
