import { tw } from '@nickeltech/brise'
import styled from 'styled-components'
import ContainerProps from '../../types'
import Row from '../Layout/Row'

export type PaymentProgressProps = {
  totalAmount: number
  paidAmount: number
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const PaymentPie = styled.div<{ percent: number }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-image: ${(props) =>
    `conic-gradient(#1a1a1a 0%, #1a1a1a ${props.percent}%, #bababa ${
      props.percent
    }%, #bababa ${100 - props.percent}%);`};
`

const Description = tw.div<ContainerProps>`
    text-xs
    text-gray-800
    font-normal
`

export const PaymentProgress = (props: PaymentProgressProps) => {
  return (
    <Row y="center" gap="small">
      <PaymentPie
        {...{
          percent: 100 * ((props.paidAmount * 1.0) / props.totalAmount),
        }}
      />
      <Description>
        {formatter.format(parseInt(props.totalAmount.toString()) / 100.0)}
      </Description>
    </Row>
  )
}

export default PaymentProgress
