import { Text, Icon, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'ui'
import { CheckBadgeIcon } from '@heroicons/react/24/outline'

function OnboardingSuccess() {
  const navigate = useNavigate()
  return (
    <VStack
      alignSelf="center"
      justifyContent="center"
      justifySelf="center"
      h="100%"
    >
      <Icon as={CheckBadgeIcon} boxSize="5" />
      <Text fontSize="lg" color="gray.800">
        Onboarding Completed
      </Text>
      <Text fontSize="sm" color="gray.500">
        Thanks for sending over your information. We will verify your
        application and get back to you with an update soon.
      </Text>

      <Button
        colorScheme="dark"
        label="Close"
        onClick={() => {
          navigate('/dashboard')
        }}
      />
    </VStack>
  )
}

export default OnboardingSuccess
