import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Text,
  TypographyProps,
  VStack,
} from '@chakra-ui/react'

export type EntityProps = {
  children:
    | Array<React.ReactElement<EntityFieldProps>>
    | React.ReactElement<EntityFieldProps>
  actions?: React.ReactNode
  actionAlignment?: ActionAlignment
  disabled?: boolean
  footer?: React.ReactNode
} & FlexProps

type ActionAlignment = 'top' | 'center' | 'bottom'

export type EntityFieldProps = {
  title?: string
  description?: string
  right?: boolean
  center?: boolean
  muted?: boolean
  titleTextColor?: string
  titleSize?: string
  descriptionTextColor?: string
  titleFontWeight?: TypographyProps['fontWeight']
}

function getItemAlignment(right?: boolean, center?: boolean) {
  if (right) {
    return 'flex-end'
  }

  if (center) {
    return 'center'
  }

  return 'flex-start'
}

function getActionAlignment(actionAlignment?: ActionAlignment) {
  if (actionAlignment === 'top') {
    return 'flex-start'
  }

  if (actionAlignment === 'bottom') {
    return 'flex-end'
  }

  return 'center'
}

export function EntityField({
  title,
  description,
  right,
  center,
  titleFontWeight,
  titleTextColor,
  titleSize,
  descriptionTextColor,
}: EntityFieldProps) {
  return (
    <VStack
      spacing="1"
      flex="1"
      alignItems={getItemAlignment(right, center)}
      justifyContent={'center'}
    >
      <Box>
        {title && (
          <Text
            fontWeight={titleFontWeight ? titleFontWeight : 'medium'}
            textColor={titleTextColor ? titleTextColor : 'gray.800'}
            fontSize={titleSize ? titleSize : 'md'}
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            fontSize="md"
            textColor={descriptionTextColor ? descriptionTextColor : 'gray.500'}
          >
            {description}
          </Text>
        )}
      </Box>
    </VStack>
  )
}

export function Entity(props: EntityProps) {
  const {
    children,
    actions,
    disabled,
    actionAlignment,
    footer,
    py,
    px,
    border,
    ...other
  } = props
  return (
    <Flex
      py={py || '6'}
      px={px || '6'}
      className="rounded-md"
      border={border || '1px'}
      borderColor="gray.300"
      borderRadius={props.borderRadius}
      width="100%"
      bgColor={disabled ? 'gray.100' : 'white'}
      _hover={{
        bgColor: disabled ? 'gray.100' : 'gray.50',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      {...other}
    >
      <Flex grow={'1'} flexDirection={'column'} gap="2">
        <HStack flex="1">
          <HStack flex="1">{children}</HStack>
          {actions && (
            <Flex
              height="100%"
              alignItems={getActionAlignment(actionAlignment)}
            >
              {actions}
            </Flex>
          )}
        </HStack>
        {footer && <Box>{footer}</Box>}
      </Flex>
    </Flex>
  )
}

export default Entity
