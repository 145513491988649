import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Overlay } from 'ui'
import { PAYOUTS_URL, SETTLEMENTS_URL } from '../../lib/urls'

export default function PayoutsOverlay() {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(PAYOUTS_URL)

  const match = location.pathname.match(/\/dashboard\/payouts\/(.*)/)

  return (
    <Overlay
      open={match != null}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet />
    </Overlay>
  )
}

export function SettlementsOverlay() {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(SETTLEMENTS_URL)

  const match = location.pathname.match(/\/dashboard\/settlements\/(.*)/)

  return (
    <Overlay
      open={match != null}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet />
    </Overlay>
  )
}
