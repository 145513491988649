import { useNavigate, useParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import {
  AccountantRegistrationStep,
  useAccountantRegistrationFlow,
} from './registrationFlowStore'
import { RegistrationFlowLayout } from './RegistrationFlowLayout'
import { OwnerInformation } from './OwnerInfo'
import { BusinessInformation } from './BusinessInformation'
import { Button, Flex } from '@chakra-ui/react'

export function AccountingRegistrationFlow() {
  const navigate = useNavigate()
  const { token = '' } = useParams()
  // Handle expiry
  const { email }: { email: string; expiry: number } = jwt_decode(token)

  const { currentStep, setStep, setOwner } = useAccountantRegistrationFlow(
    (state) => ({
      currentStep: state.step,
      setStep: state.setStep,
      setOwner: state.setOwner,
    }),
  )

  const pages: Record<AccountantRegistrationStep, React.ReactNode> = {
    owner: (
      <RegistrationFlowLayout
        title="Registration"
        subtitle="Let's get some registration information."
        currentPage={currentStep}
      >
        <OwnerInformation
          email={email}
          onNextStep={(firstName, lastName, password) => {
            setOwner({ email, firstName, lastName, password })
            setStep('business')
          }}
        />
      </RegistrationFlowLayout>
    ),
    business: (
      <RegistrationFlowLayout
        title="Business Information"
        subtitle="Tell us about your business."
        currentPage={currentStep}
      >
        <BusinessInformation
          token={token}
          onNextStep={() => setStep('newClient')}
        />
      </RegistrationFlowLayout>
    ),
    newClient: (
      <RegistrationFlowLayout
        title="Success!"
        subtitle="You're finished! Would you like to add a client?"
        currentPage={currentStep}
      >
        <Flex width="540px" flexDirection="column" gap={6}>
          <Button onClick={() => navigate('/newclient')}>Yes</Button>
          <Button variant="outline" onClick={() => navigate('/dashboard')}>
            No, take me to my dashboard
          </Button>
        </Flex>
      </RegistrationFlowLayout>
    ),
  }

  return <>{pages[currentStep]}</>
}
