import get from 'lodash/get'
import AddressAutocomplete from '../v2/input/AddressAutocomplete'
import SelectInput from '../Select'
import { useFormikContext } from 'formik'
import {
  Checkbox,
  FormLabel,
  HStack,
  StackDivider,
  VStack,
} from '@chakra-ui/react'
import ValidatingInput from '../v3/ValidatingInput'
import { US_STATES } from '../Payment/CreditCardPaymentForm'
import { useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

const MOBILE_MAX_WIDTH = 500

type AddressInputProps = {
  label?: string
  valueKey: string
  apiKey: string
  popupContainerTarget?: string
  flattenedValues?: boolean
  isMobile?: boolean
  includeBusinessName?: boolean
  abbreviateStates?: boolean
  onChangeAddress?: (address: string) => void
  onChangeState?: (state: string) => void
  onChangeCity?: (city: string) => void
  onChangeZip?: (zip: string) => void
}

export const AddressInput = (props: AddressInputProps) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext()

  const [businessChecked, setBusinessChecked] = useState(
    props.includeBusinessName ?? false,
  )

  const streetKey = props.flattenedValues
    ? `${props.valueKey}_streetAddress`
    : `${props.valueKey}.streetAddress`
  const cityKey = props.flattenedValues
    ? `${props.valueKey}_city`
    : `${props.valueKey}.city`
  const stateKey = props.flattenedValues
    ? `${props.valueKey}_state`
    : `${props.valueKey}.state`
  const zipKey = props.flattenedValues
    ? `${props.valueKey}_zipCode`
    : `${props.valueKey}.zipCode`
  const companyKey = props.flattenedValues
    ? `${props.valueKey}_company`
    : `${props.valueKey}.company`

  const cityValue = get(values, cityKey, '')
  const cityTouched = get(touched, cityKey, false)
  const cityErrors = get(errors, cityKey, undefined)

  const stateValue = get(values, stateKey, '')
  const stateTouched = get(touched, stateKey, false)

  const zipValue = get(values, zipKey, '')
  const zipTouched = get(touched, zipKey, false)
  const zipErrors = get(errors, zipKey, undefined)

  const companyValue = get(values, companyKey, '')
  const companyTouched = get(touched, companyKey, false)
  const companyErrors = get(errors, companyKey, undefined)

  const { width } = useWindowSize()

  const isMobile = width < MOBILE_MAX_WIDTH

  return (
    <VStack spacing="0" gap="0" w="100%">
      <FormLabel justifySelf="flex-start" alignSelf="flex-start">
        {props.label ? props.label : 'Billing Address'}
      </FormLabel>
      {props.includeBusinessName && (
        <Checkbox
          defaultChecked
          justifySelf="flex-start"
          alignSelf="flex-start"
          pb="4"
          ps="1"
          colorScheme="dark"
          onChange={(e) => {
            setBusinessChecked(e.target.checked)
          }}
        >
          I&#39;m making a payment using a business account
        </Checkbox>
      )}
      <VStack
        w="100%"
        gap="0"
        spacing="0"
        divider={<StackDivider borderColor="gray.200" />}
      >
        {businessChecked ? (
          <ValidatingInput
            id={companyKey}
            onChange={handleChange}
            validated={!!companyTouched}
            value={companyValue}
            onBlur={handleBlur}
            isInvalid={!!companyErrors && !!companyTouched}
            placeholder="Company"
            borderBottomRadius="0"
            borderColor="gray.300"
            borderBottom="0"
            _focus={{
              boxShadow: 'none',
              outline: 'none',
              borderColor: 'gray.300',
            }}
            _invalid={{
              borderColor: 'red.500',
              borderBottom: '1',
              borderBottomRadius: '0',
            }}
          />
        ) : (
          ''
        )}
        <HStack
          borderRight="1px solid"
          borderLeft="1px solid"
          borderTopRadius={businessChecked ? '0' : 'base'}
          borderTop={businessChecked ? '0' : '1px solid'}
          borderColor="gray.300"
          w="100%"
        >
          <AddressAutocomplete
            {...{
              name: streetKey,
              valueKey: props.valueKey,
              apiKey: props.apiKey,
              popupContainerTarget: props.popupContainerTarget,
              flattenedValues: props.flattenedValues,
              onChange: (address) =>
                props.onChangeAddress && props.onChangeAddress(address),
            }}
            abbreviateStates={props.abbreviateStates}
          />
        </HStack>
        {isMobile ? (
          <HStack w="100%" gap="0" spacing="0">
            <ValidatingInput
              id={cityKey}
              onChange={(e) => {
                handleChange(e)
                props.onChangeCity && props.onChangeCity(e.target.value)
              }}
              validated={!!cityTouched}
              value={cityValue}
              onBlur={handleBlur}
              isInvalid={!!cityErrors && !!cityTouched}
              placeholder="City"
              borderTopRadius="0"
              borderBottomRightRadius="0"
              borderBottomLeftRadius="0"
              borderColor="gray.300"
              borderTop="0"
              borderBottom={'0'}
              _focus={{
                boxShadow: 'none',
                outline: 'none',
                borderColor: 'gray.300',
              }}
              _invalid={{
                borderColor: 'red.500',
                borderTop: '1px solid',
                borderTopRadius: '0',
              }}
            />
          </HStack>
        ) : (
          ''
        )}
        <HStack w="100%" gap="0" spacing="0">
          {!isMobile ? (
            <ValidatingInput
              id={cityKey}
              onChange={(e) => {
                handleChange(e)
                props.onChangeCity && props.onChangeCity(e.target.value)
              }}
              validated={!!cityTouched}
              value={cityValue}
              onBlur={handleBlur}
              isInvalid={!!cityErrors && !!cityTouched}
              placeholder="City"
              borderTopRadius="0"
              borderBottomRightRadius="0"
              borderColor="gray.300"
              borderTop="0"
              _focus={{
                boxShadow: 'none',
                outline: 'none',
                borderColor: 'gray.300',
              }}
              _invalid={{
                borderColor: 'red.500',
                borderTop: '1px solid',
                borderTopRadius: '0',
              }}
            />
          ) : (
            ''
          )}
          <SelectInput
            name={stateKey}
            className={isMobile ? 'border-l border-l-gray-300 rounded-bl' : ''}
            selectId="state"
            withValidator
            closeMenuOnSelect
            placeholder="State"
            overflowable
            menuPlacement="top"
            isClearable={false}
            validated={!!stateTouched}
            onChange={(e) => {
              setFieldTouched(stateKey, true)
              setFieldValue(stateKey, e.value)
              props.onChangeState && props.onChangeState(e)
            }}
            abbreviations={Object.fromEntries(
              US_STATES.states.map(({ name, abbreviation }) => [
                abbreviation,
                {
                  label: props.isMobile ? abbreviation : name,
                  value: props.abbreviateStates ? abbreviation : name,
                },
              ]),
            )}
            hasSuccess={
              stateValue.length > 0 &&
              !!US_STATES.states.find(
                (x) =>
                  stateValue ===
                  (props.abbreviateStates ? x.abbreviation : x.name),
              )
            }
            {...{
              options: US_STATES.states.map(({ name, abbreviation }) => ({
                label: props.isMobile ? abbreviation : name,
                value: props.abbreviateStates ? abbreviation : name,
              })),
            }}
          />
          <ValidatingInput
            id={zipKey}
            onChange={(e) => {
              handleChange(e)
              props.onChangeZip && props.onChangeZip(e.target.value)
            }}
            validated={!!zipTouched}
            value={zipValue}
            onBlur={handleBlur}
            isInvalid={!!zipErrors && !!zipTouched}
            placeholder="Zip Code"
            borderTopRadius="0"
            borderBottomLeftRadius="0"
            borderColor="gray.300"
            borderTop="0"
            _focus={{
              boxShadow: 'none',
              outline: 'none',
              borderColor: 'gray.300',
            }}
            _invalid={{
              borderColor: 'red.500',
              borderTop: '1px solid',
              borderTopRadius: '0',
            }}
          />
        </HStack>
      </VStack>
    </VStack>
  )
}

export default AddressInput
