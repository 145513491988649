import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { BreadcrumbLinkItem } from 'ui'
import { Header } from '../../layout/Header'
import {
  RegistrationPage,
  useSelfServiceRegistrationStore,
} from './selfServiceRegistrationStore'

type RegistrationBreadcrumbProps = {
  currentPage: RegistrationPage
  onPageChange: (page: RegistrationPage) => void
}

function RegistrationBreadcrumbs({
  currentPage,
  onPageChange,
}: RegistrationBreadcrumbProps) {
  const { setPage } = useSelfServiceRegistrationStore((state) => ({
    setPage: state.setStep,
  }))

  return (
    <Breadcrumb
      separator={
        <ChevronRightIcon
          className="stroke-gray-400"
          height="14px"
          width="14px"
        />
      }
    >
      <BreadcrumbItem isCurrentPage={currentPage === 'email'}>
        <BreadcrumbLinkItem
          isCurrentPage={currentPage === 'email'}
          text="Account"
          onClick={() => setPage('email')}
          isDisabled={currentPage !== 'email'}
        />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={currentPage === 'owner'}>
        <BreadcrumbLinkItem
          isCurrentPage={currentPage === 'owner'}
          text="Owner"
          onClick={() => setPage('owner')}
          isDisabled={currentPage === 'email'}
        />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={currentPage === 'business'}>
        <BreadcrumbLinkItem
          isCurrentPage={currentPage === 'business'}
          text="Business"
          onClick={() => setPage('business')}
          isDisabled={currentPage === 'email' || currentPage === 'owner'}
        />
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={currentPage === 'bankAccount'}>
        <BreadcrumbLinkItem
          isCurrentPage={currentPage === 'bankAccount'}
          text="Bank"
          onClick={() => {}}
          isDisabled={
            currentPage === 'email' ||
            currentPage === 'owner' ||
            currentPage === 'business'
          }
        />
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

export type RegistrationHeaderProps = {
  currentPage: RegistrationPage
}

export function RegistrationHeader({ currentPage }: RegistrationHeaderProps) {
  return (
    <Header
      imageOnClick={() => window.open('https://www.getnickel.com', '_blank')}
    >
      <RegistrationBreadcrumbs
        currentPage={currentPage}
        onPageChange={() => {}}
      />
    </Header>
  )
}
