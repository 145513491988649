import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react'
import { CheckIcon, NoSymbolIcon } from '@heroicons/react/24/outline'
import { Select } from 'chakra-react-select'
import { FormikValues, useFormikContext } from 'formik'
import omit from 'lodash/omit'
import { ReactElement } from 'react'
import { SingleValue } from 'react-select'
import { UsaStates } from 'usa-states'
import { Option } from '../Select'

export type ValidatingInputProps = {
  isInvalid: boolean
  id?: string
  label?: string
  error?: string
  validated?: boolean
  isRequired?: boolean
  helperText?: string
  leftAddon?:
    | string
    | ReactElement<any, string | React.JSXElementConstructor<any>>
  rightAddon?:
    | string
    | ReactElement<any, string | React.JSXElementConstructor<any>>
  popover?: ReactElement<any, string | React.JSXElementConstructor<any>> | any
} & InputProps

type StateInput = {
  isInvalid?: boolean
  errorMessage?: string
  value: Option
  onChange: (newValue: SingleValue<Option>) => void
}

export function StateInput({
  isInvalid,
  onChange,
  errorMessage,
  value,
}: StateInput) {
  const usStates = new UsaStates()
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel>State of Incorporation</FormLabel>
      <Select
        size="sm"
        selectedOptionStyle="check"
        placeholder="State"
        name="stateIncorporated"
        options={usStates.states.map((states) => ({
          value: states.abbreviation,
          label: states.name,
        }))}
        onChange={onChange}
        value={value}
        chakraStyles={{
          control: (provided, _) => ({
            ...provided,
            borderRadius: 'md',
            height: '40px',
          }),
        }}
      />
      <FormErrorMessage m="3px"> {errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

type FormikValidatingInputProps = Omit<ValidatingInputProps, 'isInvalid'> & {
  fieldName: string
}

// This does not work with nested values
export function FormikValidatingInput<T extends FormikValues>(
  props: FormikValidatingInputProps,
) {
  const { fieldName, validated, value, error, ...rest } = props
  const { errors, values, touched, handleChange, handleBlur } =
    useFormikContext<T>()
  return (
    <ValidatingInput
      id={props.fieldName}
      onChange={handleChange}
      onBlur={handleBlur}
      isInvalid={!!errors[fieldName]}
      validated={!!touched[fieldName]}
      value={values[fieldName]}
      error={errors[fieldName] as string}
      {...rest}
    />
  )
}

export function ValidatingInput({
  isInvalid,
  label,
  id,
  error,
  validated,
  isRequired,
  popover,
  leftAddon,
  rightAddon,
  helperText,
  ...other
}: ValidatingInputProps) {
  return (
    <FormControl
      isInvalid={isInvalid && validated}
      id={id}
      isRequired={isRequired}
    >
      {!!label ? (
        <FormLabel fontSize={other.fontSize}>
          <Flex flexDirection="row">
            {label}
            <Center ps="2">{popover}</Center>
          </Flex>
        </FormLabel>
      ) : (
        ''
      )}

      <InputGroup size="md">
        {leftAddon ? (
          <InputLeftAddon
            fontSize={other.fontSize || 'base'}
            pointerEvents="none"
            className="text-gray-500"
          >
            {leftAddon}
          </InputLeftAddon>
        ) : (
          ''
        )}
        <Input
          {...omit(other, 'className')}
          color={isInvalid ? 'red.600' : ''}
          {...other}
        />
        <InputGroup w="0px">
          {!error &&
            validated &&
            !isInvalid &&
            (other.value || '').toString().length > 0 && (
              <InputRightElement>
                <CheckIcon className="w-4 h-4 stroke-green-800 stroke-2" />
              </InputRightElement>
            )}
          {error && validated && (other.value || '').toString().length > 0 && (
            <InputRightElement>
              <NoSymbolIcon className="w-4 h-4 stroke-red-600 stroke-2" />
            </InputRightElement>
          )}
          {!error &&
            validated &&
            isInvalid &&
            (other.value || '').toString().length > 0 && (
              <InputRightElement>
                <NoSymbolIcon className="w-4 h-4 stroke-red-600 stroke-2" />
              </InputRightElement>
            )}
        </InputGroup>
        {rightAddon ? (
          <InputRightAddon
            fontSize={other.fontSize || 'base'}
            pointerEvents="none"
            className="text-gray-500"
          >
            {rightAddon}
          </InputRightAddon>
        ) : (
          ''
        )}
      </InputGroup>
      {error ? <FormErrorMessage fontSize="xs">{error}</FormErrorMessage> : ''}
      {helperText && !(isInvalid && error && validated) && (
        <FormHelperText fontSize="xs">{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default ValidatingInput
