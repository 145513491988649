import { tw } from '@nickeltech/brise'
import styled from 'styled-components'
import ContainerProps from '../../types'
import Column, { ColumnProps } from '../Layout/Column'

export type CondensedMerchantLogoProps = {
  large?: boolean
} & ColumnProps

export const MerchantLogoContainer = tw<ColumnProps>(Column)`
  rounded-md
  shadow-md
  border
  border-gray-200
  w-[64px]
  h-[64px]
`

export const LargeMerchantLogoContainer = tw<ColumnProps>(Column)`
  rounded-md
  shadow-md
  border
  border-gray-200
  w-[120px]
  h-[120px]
`

export const MerchantLogo = styled.div<ContainerProps>`
  img {
    width: 80px !important;
  }
`

export const CondensedMerchantLogo = (props: CondensedMerchantLogoProps) => {
  return props.large ? (
    <LargeMerchantLogoContainer
      className={props.className}
      x="center"
      y="center"
    >
      <MerchantLogo>{props.children}</MerchantLogo>
    </LargeMerchantLogoContainer>
  ) : (
    <MerchantLogoContainer className={props.className} x="center" y="center">
      <MerchantLogo>{props.children}</MerchantLogo>
    </MerchantLogoContainer>
  )
}

export default CondensedMerchantLogo
