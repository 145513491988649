import {
  Card,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import styled from 'styled-components'

type CardInputProps = {
  label: string
  isInvalid?: boolean
  validated?: boolean
  isRequired?: boolean
  error?: string
  labelColor?: string
  inputGroupSize?: string
} & InputProps

type DatePickerCardInputProps = {
  className?: string
  label: string
  onChange: (date: Date) => void
  selected: Date
  disabled?: boolean
  includeDateIntervals?: {
    start: Date
    end: Date
  }[]
}

type DatePickerWrapperProps = {
  className?: string
  onChange: (date: Date) => void
  selected: Date
} & ReactDatePickerProps

const DatePickerWrapper = styled(
  ({ className, ...props }: DatePickerWrapperProps) => (
    <ReactDatePicker className={className} {...props} />
  ),
)`
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  outline-color: transparent;
  width: 100%;
`

export function DatePickerCardInput({
  label,
  onChange,
  selected,
  className,
  disabled,
  includeDateIntervals,
}: DatePickerCardInputProps) {
  return (
    <Card w="100%" border="1px" borderColor="gray.200" px={3} py={2}>
      <FormLabel
        noOfLines={1}
        fontSize={{ base: '2xs', md: 'xs' }}
        color={'gray.800'}
        m={0}
      >
        {label}
      </FormLabel>
      <DatePickerWrapper
        selected={selected}
        onChange={onChange}
        className={className}
        disabled={disabled}
        {...(includeDateIntervals && {
          includeDateIntervals: includeDateIntervals,
        })}
      />
    </Card>
  )
}

export function CardInput({
  label,
  value,
  isInvalid,
  validated,
  isRequired,
  error,
  placeholder,
  ...other
}: CardInputProps) {
  return (
    <Card
      w="100%"
      bgColor={other.bgColor || 'gray.50'}
      border="1px"
      borderColor="gray.200"
    >
      <FormControl isInvalid={isInvalid && validated} isRequired={isRequired}>
        <HStack
          justifyContent="space-between"
          px={3}
          pt={2}
          alignItems="center"
        >
          <FormLabel
            noOfLines={1}
            fontSize={{ base: '2xs', md: 'xs' }}
            color={other.labelColor || 'gray.500'}
            m={0}
          >
            {label}
          </FormLabel>
          <FormErrorMessage fontSize={{ base: '2xs', md: 'xs' }} m={0}>
            {error && validated ? error : ''}
          </FormErrorMessage>
        </HStack>

        <InputGroup size={other.inputGroupSize || 'md'} px={3} pb={2}>
          <Input
            {...other}
            value={value}
            border="0"
            paddingInlineStart={0}
            variant="selectAmountInput"
            placeholder={placeholder}
          />
        </InputGroup>
      </FormControl>
    </Card>
  )
}
