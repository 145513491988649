import {
  Box,
  Text,
  VStack,
  Icon,
  RadioGroup,
  Radio,
  Card,
  CardBody,
} from '@chakra-ui/react'
import {
  CurrencyDollarIcon,
  EyeIcon,
  GlobeAltIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Column, Row } from 'ui'
import { UserRole } from '../../../../operations-types'

type RoleSelectDropdownProps = {
  defaultValue?: UserRole
  onChange: (val: UserRole) => void
}

export const ROLE_OPTIONS = [
  {
    label: 'Administrator',
    value: UserRole.UserAdmin,
    description: 'Admin description',
    icon: GlobeAltIcon,
    additionalDescription: (
      <Text fontSize="xs" color="gray.500">
        Administrators have{' '}
        <Text as="span" fontWeight="semibold" color="gray.800">
          full access
        </Text>{' '}
        to every tool in Nickel and can manage your Nickel account
      </Text>
    ),
  },

  {
    label: 'Manager',
    value: UserRole.Manager,
    description: 'Manager description',
    icon: CurrencyDollarIcon,
    additionalDescription: (
      <Text fontSize="xs" color="gray.500">
        Managers can performs actions that{' '}
        <Text as="span" fontWeight="semibold" color="gray.800">
          transfer funds
        </Text>
        , but cannot make changes to your Nickel account or bank account
      </Text>
    ),
  },
  {
    label: 'Member',
    value: UserRole.User,
    description: 'User description',
    icon: EyeIcon,
    additionalDescription: (
      <Text fontSize="xs" color="gray.500">
        Workspace Members have{' '}
        <Text as="span" color="gray.800" fontWeight="semibold">
          restricted access
        </Text>{' '}
        and cannot perform any actions that will result in a transfer of funds,
        unless an approval workflow is active.
      </Text>
    ),
  },
]

const RoleSelectDropdown = (props: RoleSelectDropdownProps) => {
  const [value, setValue] = useState(props.defaultValue)

  return (
    <>
      <RadioGroup value={value}>
        <VStack w="100%" spacing="2">
          {ROLE_OPTIONS.map((role, index) => {
            return (
              <Card
                key={index}
                w="100%"
                p="0"
                m="0"
                onClick={() => {
                  setValue(role.value)
                  props.onChange(role.value)
                }}
                borderColor="gray.300"
              >
                <CardBody p="0" w="100%" m="0">
                  <Row
                    spacing="medium"
                    grow
                    y="center"
                    between
                    className="cursor-pointer hover:bg-gray-100 rounded-md"
                  >
                    <Row y="center" gap="medium" className="pe-4">
                      <Box bgColor="gray.100" p="2" borderRadius="6px">
                        <Icon as={role.icon} boxSize="6" />
                      </Box>
                      <VStack alignItems="start" pe="2">
                        <div className="text-sm font-medium text-gray-700">
                          {role.label}
                        </div>
                        <div className="text-xs font-normal text-gray-400">
                          {role.additionalDescription}
                        </div>
                      </VStack>
                    </Row>
                    <Column y="center" x="center">
                      <Radio value={role.value} variant="primary" />
                    </Column>
                  </Row>
                </CardBody>
              </Card>
            )
          })}
        </VStack>
      </RadioGroup>
    </>
  )
}

export default RoleSelectDropdown
