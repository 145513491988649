import { Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Column, Icon, Row, Separator } from 'ui'

type PaymentDetailsProps = {
  refundedAmount: string
  headerLabel: string
  linkText?: string
  amountText?: string
  headerValue?: string
  onLinkClick?: () => void
  paymentMethod: ReactNode
  billingAddress: ReactNode
  contactInfo: ReactNode
  return?: boolean
}

const PaymentDetails = (_props: PaymentDetailsProps) => {
  return (
    <Column gap="medium" wGrow>
      <Row grow between rounded y="center">
        <Text fontWeight={'semibold'} color={'dark.800'} fontSize="xl">
          {_props.headerLabel}
        </Text>
        {_props.headerValue && (
          <Text color={'gray.500'}>{_props.headerValue}</Text>
        )}
        {_props.linkText && _props.onLinkClick && (
          <Row gap="small" y="center">
            <Text
              color={'purple.500'}
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              fontSize={'sm'}
              onClick={_props.onLinkClick}
            >
              {_props.linkText}
            </Text>
            <Icon
              name="arrowTopRightOnSquare"
              className="stroke-purple-600"
              onClick={_props.onLinkClick}
            />
          </Row>
        )}
      </Row>
      <Column
        className="border rounded border-gray-300 py-4 px-3 bg-gray-100"
        gap="medium"
        wGrow
      >
        <Row grow between y="center">
          <Text className="text-sm text-gray-800">
            {_props.amountText
              ? _props.amountText
              : _props.return
              ? 'Return Amount'
              : 'Refund Amount'}
          </Text>
          <Text className="text-sm text-gray-800">{_props.refundedAmount}</Text>
        </Row>
        <Separator orientation="horizontal" />
        <Row grow between y="center">
          <Text className="text-sm text-gray-800">Payment Method</Text>
          {_props.paymentMethod}
        </Row>
        <Row grow between y="top">
          <Text className="text-sm text-gray-800">Billing Address</Text>
          {_props.billingAddress}
        </Row>
        <Row grow between y="center">
          <Text className="text-sm text-gray-800">Contact Info</Text>
          {_props.contactInfo}
        </Row>
      </Column>
    </Column>
  )
}

export default PaymentDetails
