import {
  Box,
  Center,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
  Icon,
} from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { Button, Form, FormButton, Modal } from 'ui'
import { InfoCardItem } from '../InfoCardItem'
import {
  MerchantNotificationItem,
  MerchantNotifications,
} from './MerchantNotifications'
import * as yup from 'yup'
import { useMutation } from '@apollo/client'
import {
  SelfDocument,
  UpdateNotificationSettingsDocument,
} from '../../../operations-types'
import { useOutletContext } from 'react-router-dom'
import { User } from '../../../types'
import NotificationArea from '../../utils/NotificationArea'
import Notification from '../../utils/Notification'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

const DEFAULT_NOTIFICATION_SETTINGS = {
  merchant: {
    carbonCopyEmail: {
      enabled: false,
      emails: [],
    },
    paymentMade: {
      enabled: true,
      emails: [],
    },
    payoutPaid: {
      enabled: true,
      emails: [],
    },
    paymentFailed: {
      enabled: true,
      emails: [],
    },
    refundCompleted: {
      enabled: true,
      emails: [],
    },
    billPaymentMade: {
      enabled: true,
      emails: [],
    },
    billPaymentFailed: {
      enabled: true,
      emails: [],
    },
    vendorCheckDelivered: {
      enabled: true,
      emails: [],
    },
    achStatusUpdate: {
      enabled: true,
      emails: [],
    },
  },
  buyer: {
    receipt: {
      enabled: true,
      emails: [],
    },
    buyerPaymentFailed: {
      enabled: true,
      emails: [],
    },
    refundCompleted: {
      enabled: true,
      emails: [],
    },
  },
}

type EmailModalContainerProps = {
  onAddEmail: (email: string) => void
  onCancel: () => void
}

function EmailModalContainer({
  onAddEmail,
  onCancel,
}: EmailModalContainerProps) {
  const [email, setEmail] = useState('')

  return (
    <VStack gap="2">
      <FormControl>
        <FormLabel variant="small">Email</FormLabel>
        <Input
          name="email"
          placeholder="example@test.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <HStack gap="4">
        <Button
          label="Cancel"
          variant="outline"
          status="primary"
          onClick={onCancel}
        />
        <Button label="Add" onClick={() => onAddEmail(email)} />
      </HStack>
    </VStack>
  )
}

type NotificationCollapseProps = {
  children: ReactNode
  label: string
  sublabel?: string
  defaultOpen?: boolean
}

function NotificationCollapse(props: NotificationCollapseProps) {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: props.defaultOpen,
  })
  return (
    <Box alignItems="center" w="100%">
      <Flex
        onClick={onToggle}
        verticalAlign="center"
        flexDirection="row"
        w="100%"
        maxWidth={['700px', '700px', '100%']}
        py={'6'}
        px={'3'}
        border={'1px'}
        borderColor="gray.300"
        borderRadius="4px"
        width="100%"
        bgColor={'white'}
        _hover={{
          bgColor: 'gray.50',
          cursor: 'pointer',
        }}
      >
        <Center>
          <HStack gap="1" spacing="1" w="100%">
            <Box pe="1" />
            <VStack spacing="0" alignItems="left" gap="0">
              <HStack>
                <Text fontSize="md" fontWeight="semibold">
                  {props.label}
                </Text>
              </HStack>
              <Text fontSize="sm" fontWeight="sm">
                {props.sublabel}
              </Text>
            </VStack>
          </HStack>
        </Center>
        <Spacer />
        <Center>
          <Icon
            as={isOpen ? ChevronUpIcon : ChevronDownIcon}
            color="gray.400"
            boxSize="6"
            strokeWidth={2}
          />
          <Box pe="4" />
        </Center>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        {props.children}
      </Collapse>
    </Box>
  )
}

export function Notifications() {
  const data = useOutletContext() as User

  const { organization } = data

  const { notificationSettings } = organization?.accountInfo || {}

  const notificationSettingsJSON = notificationSettings
    ? notificationSettings
    : DEFAULT_NOTIFICATION_SETTINGS

  const [isOpen, setIsOpen] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')

  const [showNotif, setShowNotif] = useState({
    message: '',
    show: false,
    header: '',
  })
  const onClose = () => setShowNotif({ message: '', show: false, header: '' })

  const [emails, setEmails] = useState<{ [index: string]: string[] }>({
    paymentMade: notificationSettingsJSON.merchant?.paymentMade?.emails || [],
    payoutPaid: notificationSettingsJSON.merchant?.payoutPaid?.emails || [],
    paymentFailed:
      notificationSettingsJSON.merchant?.paymentFailed?.emails || [],
    receipt: notificationSettingsJSON.buyer?.receipt?.emails || [],
    merchantRefundCompleted:
      notificationSettingsJSON.merchant?.refundCompleted?.emails || [],
    refundCompleted:
      notificationSettingsJSON.buyer?.refundCompleted?.emails || [],
    buyerPaymentFailed:
      notificationSettingsJSON.buyer?.buyerPaymentFailed?.emails || [],
    billPaymentMade:
      notificationSettingsJSON.merchant?.billPaymentMade?.emails || [],
    billPaymentFailed:
      notificationSettingsJSON.merchant?.billPaymentFailed?.emails || [],
    vendorCheckDelivered:
      notificationSettingsJSON.merchant?.vendorCheckDelivered?.emails || [],
    achStatusUpdate:
      notificationSettingsJSON.merchant?.achStatusUpdate?.emails || [],
    carbonCopyEmail:
      notificationSettingsJSON.merchant?.carbonCopyEmail?.emails || [],
  })

  const [enabled, setEnabled] = useState<{ [index: string]: boolean }>({
    paymentMade: !!notificationSettingsJSON.merchant?.paymentMade?.enabled,
    payoutPaid: !!notificationSettingsJSON.merchant?.payoutPaid?.enabled,
    paymentFailed: !!notificationSettingsJSON.merchant?.paymentFailed?.enabled,
    merchantRefundCompleted:
      !!notificationSettingsJSON.merchant?.refundCompleted?.enabled,
    billPaymentMade:
      !!notificationSettingsJSON.merchant?.billPaymentMade?.enabled,
    billPaymentFailed:
      !!notificationSettingsJSON.merchant?.billPaymentFailed?.enabled,
    vendorCheckDelivered:
      !!notificationSettingsJSON.merchant?.vendorCheckDelivered?.enabled,
    achStatusUpdate:
      !!notificationSettingsJSON.merchant?.achStatusUpdate?.enabled,
    carbonCopyEmail:
      !!notificationSettingsJSON.merchant?.carbonCopyEmail?.enabled,
  })

  const [buyerEnabled, setBuyerEnabled] = useState<{
    [index: string]: boolean
  }>({
    receipt: !!notificationSettingsJSON.buyer?.receipt?.enabled,
    buyerPaymentFailed:
      !!notificationSettingsJSON.buyer?.buyerPaymentFailed?.enabled,
    refundCompleted: !!notificationSettingsJSON.buyer?.refundCompleted?.enabled,
  })

  const [updateSettings, { loading }] = useMutation(
    UpdateNotificationSettingsDocument,
    {
      onCompleted: () => {
        setShowNotif({
          message: `Successfully updated notification settings.`,
          header: 'Nice!',
          show: true,
        })
      },
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    },
  )

  return (
    <>
      <Modal open={isOpen} setOpen={setIsOpen}>
        <EmailModalContainer
          onCancel={() => {
            setIsOpen(false)
          }}
          onAddEmail={(email) => {
            setEmails({
              ...emails,
              [currentEmail]: [...emails[currentEmail], email.trim()],
            })
            setIsOpen(false)
          }}
        />
      </Modal>
      <NotificationArea>
        <Notification
          show={showNotif['show']}
          onClose={onClose}
          header={showNotif['header']}
          message={showNotif['message']}
        />
      </NotificationArea>
      <Box py={{ base: '4', md: '8' }}>
        <Form
          {...{
            validationSchema: yup.object().shape({}),
            initialValues: {},
            onSubmit: async (_values, actions) => {
              updateSettings({
                variables: {
                  notificationSettings: JSON.stringify({
                    buyer: {
                      receipt: {
                        enabled: buyerEnabled.receipt,
                        emails: emails.receipt,
                      },
                      buyerPaymentFailed: {
                        enabled: buyerEnabled.buyerPaymentFailed,
                        emails: emails.buyerPaymentFailed,
                      },
                      refundCompleted: {
                        enabled: buyerEnabled.refundCompleted,
                        emails: emails.refundCompleted,
                      },
                    },
                    merchant: {
                      paymentMade: {
                        enabled: enabled.paymentMade,
                        emails: emails.paymentMade,
                      },
                      paymentFailed: {
                        enabled: enabled.paymentFailed,
                        emails: emails.paymentFailed,
                      },
                      payoutPaid: {
                        enabled: enabled.payoutPaid,
                        emails: emails.payoutPaid,
                      },
                      refundCompleted: {
                        enabled: enabled.merchantRefundCompleted,
                        emails: emails.merchantRefundCompleted,
                      },
                      billPaymentMade: {
                        enabled: enabled.billPaymentMade,
                        emails: emails.billPaymentMade,
                      },
                      billPaymentFailed: {
                        enabled: enabled.billPaymentFailed,
                        emails: emails.billPaymentFailed,
                      },
                      vendorCheckDelivered: {
                        enabled: enabled.vendorCheckDelivered,
                        emails: emails.vendorCheckDelivered,
                      },
                      achStatusUpdate: {
                        enabled: enabled.achStatusUpdate,
                        emails: emails.achStatusUpdate,
                      },
                      carbonCopyEmail: {
                        enabled: enabled.carbonCopyEmail,
                        emails: emails.carbonCopyEmail,
                      },
                    },
                  }),
                },
              })
              actions.setSubmitting(false)
            },
            className: 'w-full h-full',
          }}
        >
          {(_formik) => (
            <Stack spacing="8" divider={<StackDivider />}>
              <InfoCardItem
                label="Get Paid"
                sublabel="Manage notifications related to getting paid"
              >
                <VStack w="100%" spacing="8">
                  <NotificationCollapse
                    label="Merchant Notifications"
                    sublabel="Notifications sent to you and your team"
                  >
                    <MerchantNotifications>
                      <MerchantNotificationItem
                        title="Payment Made"
                        description="Get notified whenever a buyer makes a payment on Nickel Pay to you."
                        emails={emails.paymentMade}
                        disabled={!enabled.paymentMade}
                        onSwitchClick={() =>
                          setEnabled({
                            ...enabled,
                            paymentMade: !enabled.paymentMade,
                          })
                        }
                        onAddClick={() => {
                          setCurrentEmail('paymentMade')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            paymentMade: emails.paymentMade.filter(
                              (x) => x !== email,
                            ),
                          })
                        }}
                      />
                      <MerchantNotificationItem
                        title="Payment Failed"
                        description="Get notified whenever a payment attempt fails to be made to you."
                        emails={emails.paymentFailed}
                        disabled={!enabled.paymentFailed}
                        onSwitchClick={() =>
                          setEnabled({
                            ...enabled,
                            paymentFailed: !enabled.paymentFailed,
                          })
                        }
                        onAddClick={() => {
                          setCurrentEmail('paymentFailed')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            paymentFailed: emails.paymentFailed.filter(
                              (x) => x !== email,
                            ),
                          })
                        }}
                      />
                      <MerchantNotificationItem
                        title="ACH Payment Processed"
                        description="Get notified whenever an incoming ACH transaction is processed."
                        emails={emails.achStatusUpdate}
                        disabled={!enabled.achStatusUpdate}
                        onSwitchClick={() =>
                          setEnabled({
                            ...enabled,
                            achStatusUpdate: !enabled.achStatusUpdate,
                          })
                        }
                        onAddClick={() => {
                          setCurrentEmail('achStatusUpdate')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            achStatusUpdate: emails.achStatusUpdate.filter(
                              (x) => x !== email,
                            ),
                          })
                        }}
                      />
                      <MerchantNotificationItem
                        title="Refund Completed"
                        description="Get notified whenever a refund has been successfully processed."
                        emails={emails.merchantRefundCompleted}
                        disabled={!enabled.merchantRefundCompleted}
                        onSwitchClick={() =>
                          setEnabled({
                            ...enabled,
                            merchantRefundCompleted:
                              !enabled.merchantRefundCompleted,
                          })
                        }
                        onAddClick={() => {
                          setCurrentEmail('merchantRefundCompleted')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            merchantRefundCompleted:
                              emails.merchantRefundCompleted.filter(
                                (x) => x !== email,
                              ),
                          })
                        }}
                      />
                      <MerchantNotificationItem
                        title="Payout Paid"
                        description="Get notified whenever a payout has been sent to your bank account."
                        emails={emails.payoutPaid}
                        disabled={!enabled.payoutPaid}
                        onSwitchClick={() =>
                          setEnabled({
                            ...enabled,
                            payoutPaid: !enabled.payoutPaid,
                          })
                        }
                        onAddClick={() => {
                          setCurrentEmail('payoutPaid')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            payoutPaid: emails.payoutPaid.filter(
                              (x) => x !== email,
                            ),
                          })
                        }}
                      />
                      <MerchantNotificationItem
                        title="Carbon Copy Email"
                        description="Set a specific email address to cc when a payment link is sent out. By default the email address of the user who created the link is copied."
                        emails={emails.carbonCopyEmail}
                        disabled={!enabled.carbonCopyEmail}
                        onSwitchClick={() =>
                          setEnabled({
                            ...enabled,
                            carbonCopyEmail: !enabled.carbonCopyEmail,
                          })
                        }
                        onAddClick={() => {
                          setCurrentEmail('carbonCopyEmail')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            carbonCopyEmail: emails.carbonCopyEmail.filter(
                              (x) => x !== email,
                            ),
                          })
                        }}
                      />
                    </MerchantNotifications>
                  </NotificationCollapse>
                  <NotificationCollapse
                    label="Customer Notifications"
                    sublabel="Notifications sent to your customers"
                  >
                    <MerchantNotifications>
                      <MerchantNotificationItem
                        title="Payment Paid"
                        description="Send an email to your customer when a payment has been made from them and configure the email they'll reply to."
                        onSwitchClick={() => {
                          setBuyerEnabled({
                            ...buyerEnabled,
                            receipt: !buyerEnabled.receipt,
                          })
                        }}
                        emails={emails.receipt}
                        onAddClick={() => {
                          setCurrentEmail('receipt')
                          setIsOpen(true)
                        }}
                        disabled={!buyerEnabled.receipt}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            receipt: emails.receipt.filter((x) => x !== email),
                          })
                        }}
                      />
                      <MerchantNotificationItem
                        title="Payment Failed"
                        description="Send an email to your customer when a payment they have made has failed and configure the email they'll reply to."
                        onSwitchClick={() =>
                          setBuyerEnabled({
                            ...buyerEnabled,
                            buyerPaymentFailed:
                              !buyerEnabled.buyerPaymentFailed,
                          })
                        }
                        disabled={!buyerEnabled.buyerPaymentFailed}
                        emails={emails.buyerPaymentFailed}
                        onAddClick={() => {
                          setCurrentEmail('buyerPaymentFailed')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            buyerPaymentFailed:
                              emails.buyerPaymentFailed.filter(
                                (x) => x !== email,
                              ),
                          })
                        }}
                      />
                      <MerchantNotificationItem
                        title="Refund Completed"
                        description="Send an email to your customer when a refund has been successfully processed and configure the email they'll reply to."
                        onSwitchClick={() => {
                          setBuyerEnabled({
                            ...buyerEnabled,
                            refundCompleted: !buyerEnabled.refundCompleted,
                          })
                        }}
                        disabled={!buyerEnabled.refundCompleted}
                        emails={emails.refundCompleted}
                        onAddClick={() => {
                          setCurrentEmail('refundCompleted')
                          setIsOpen(true)
                        }}
                        onRemoveEmailClick={(email) => {
                          setEmails({
                            ...emails,
                            refundCompleted: emails.refundCompleted.filter(
                              (x) => x !== email,
                            ),
                          })
                        }}
                      />
                    </MerchantNotifications>
                  </NotificationCollapse>
                </VStack>
              </InfoCardItem>
              <InfoCardItem
                label="Send Money"
                sublabel="Manage notifications related to sending money"
              >
                <NotificationCollapse
                  label="Merchant Notifications"
                  sublabel="Notifications sent to you and your team"
                >
                  <MerchantNotifications>
                    <MerchantNotificationItem
                      title="Bill Payment Made"
                      description="Get notified whenever you send a payment to a vendor"
                      emails={emails.billPaymentMade}
                      disabled={!enabled.billPaymentMade}
                      onSwitchClick={() =>
                        setEnabled({
                          ...enabled,
                          billPaymentMade: !enabled.billPaymentMade,
                        })
                      }
                      onAddClick={() => {
                        setCurrentEmail('billPaymentMade')
                        setIsOpen(true)
                      }}
                      onRemoveEmailClick={(email) => {
                        setEmails({
                          ...emails,
                          billPaymentMade: emails.billPaymentMade.filter(
                            (x) => x !== email,
                          ),
                        })
                      }}
                    />
                    <MerchantNotificationItem
                      title="Bill Payment Failed"
                      description="Get notified whenever a bill payment has failed."
                      emails={emails.billPaymentFailed}
                      disabled={!enabled.billPaymentFailed}
                      onSwitchClick={() =>
                        setEnabled({
                          ...enabled,
                          billPaymentFailed: !enabled.billPaymentFailed,
                        })
                      }
                      onAddClick={() => {
                        setCurrentEmail('billPaymentFailed')
                        setIsOpen(true)
                      }}
                      onRemoveEmailClick={(email) => {
                        setEmails({
                          ...emails,
                          billPaymentFailed: emails.billPaymentFailed.filter(
                            (x) => x !== email,
                          ),
                        })
                      }}
                    />
                    <MerchantNotificationItem
                      title="Check Delivered"
                      description="Get notified whenever a check you sent has been delivered."
                      emails={emails.vendorCheckDelivered}
                      disabled={!enabled.vendorCheckDelivered}
                      onSwitchClick={() =>
                        setEnabled({
                          ...enabled,
                          vendorCheckDelivered: !enabled.vendorCheckDelivered,
                        })
                      }
                      onAddClick={() => {
                        setCurrentEmail('vendorCheckDelivered')
                        setIsOpen(true)
                      }}
                      onRemoveEmailClick={(email) => {
                        setEmails({
                          ...emails,
                          vendorCheckDelivered:
                            emails.vendorCheckDelivered.filter(
                              (x) => x !== email,
                            ),
                        })
                      }}
                    />
                  </MerchantNotifications>
                </NotificationCollapse>
              </InfoCardItem>
              <FormButton loading={loading} x="right" label="Save" />
            </Stack>
          )}
        </Form>
      </Box>
    </>
  )
}
