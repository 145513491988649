import {
  Box,
  BoxProps,
  Flex,
  forwardRef,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Image,
} from '@chakra-ui/react'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'

type CurrentAccount = {
  src?: string
  name: string
}

type SwitcherComponentProps = BoxProps & {
  isOpen: boolean
  currentAccount: CurrentAccount
}

type Accountant = {
  id: string
  src?: string
  name: string
  email: string
  isSelected: boolean
}

type Client = {
  id: string
  src?: string
  name: string
  isSelected: boolean
}

const SwitcherComponent = forwardRef<SwitcherComponentProps, 'div'>(
  (props, ref) => {
    const { isOpen, currentAccount, ...rest } = props

    return (
      <Box ref={ref} {...rest} width="100%">
        <Flex flexDirection="column" alignItems="center" py={6} px={2}>
          <HStack
            cursor="pointer"
            borderRadius="4px"
            p={2}
            gap={4}
            _hover={{
              bg: 'dark.600',
            }}
            width="220px"
            height="60px"
          >
            <Image
              height="24px"
              width="24px"
              src={
                props.currentAccount.src ||
                `https://s3.amazonaws.com/nickel-user-uploads/nickel-logo.png`
              }
              alt="logo"
            />
            <Box flexGrow={1} whiteSpace="nowrap" overflow="hidden">
              <Text color="white" fontSize="medium">
                {props.currentAccount.name}
              </Text>
            </Box>
            {props.isOpen ? (
              <Icon as={ChevronUpIcon} color="white" width="20px" />
            ) : (
              <Icon as={ChevronDownIcon} color="white" width="20px" />
            )}
          </HStack>
        </Flex>
      </Box>
    )
  },
)

type AccountingFirmItemProps = Omit<Accountant, 'id'>

function AccountingFirmItem({
  src,
  name,
  email,
  isSelected,
}: AccountingFirmItemProps) {
  return (
    <Box width="100%">
      <HStack justifyContent="space-between" width="100%">
        <HStack gap={4}>
          {src && <Image height="24px" width="24px" src={src} alt="logo" />}
          <Flex gap={0} flexDirection="column">
            <Text>{name}</Text>
            <Text fontSize="xs">{email}</Text>
          </Flex>
        </HStack>
        {isSelected && <CheckIcon height="20px" width="20px" />}
      </HStack>
    </Box>
  )
}

type ClientItemProps = Omit<Client, 'id'>

function ClientItem({ src, name, isSelected }: ClientItemProps) {
  return (
    <Box width="100%">
      <HStack justifyContent="space-between" width="100%">
        <HStack gap={4}>
          {src && <Image height="24px" width="24px" src={src} alt="logo" />}
          <Text>{name}</Text>
        </HStack>
        {isSelected && <CheckIcon height="20px" width="20px" />}
      </HStack>
    </Box>
  )
}

function AddNewClient() {
  return (
    <Box>
      <HStack>
        <PlusIcon height="20px" width="20px" />
        <Text fontWeight="medium">Add New Client</Text>
      </HStack>
    </Box>
  )
}

export type SwitcherProps = {
  currentAccount: CurrentAccount
  accountingFirm: Accountant
  clients: Array<Client>
  onClickAccountingFirm: (accountant: Accountant) => void
  onClickClient: (client: Client) => void
  onClickAddNewClient: () => void
}

export function Switcher({
  currentAccount,
  accountingFirm,
  clients,
  onClickAccountingFirm,
  onClickClient,
  onClickAddNewClient,
}: SwitcherProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Menu>
      <MenuButton
        as={SwitcherComponent}
        width="100%"
        onClick={() => setIsOpen(!isOpen)}
        currentAccount={currentAccount}
        isOpen={isOpen}
      />
      <MenuList ml={6} mt={-6} minWidth="320px">
        <MenuGroup title="Accounting Firm">
          <MenuItem onClick={() => onClickAccountingFirm(accountingFirm)}>
            <AccountingFirmItem
              src={accountingFirm.src}
              name={accountingFirm.name}
              email={accountingFirm.email}
              isSelected={accountingFirm.isSelected}
            />
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        {clients.length > 0 && (
          <>
            <MenuGroup title="Firm's Clients">
              {clients.map((client, idx) => (
                <MenuItem key={idx} onClick={() => onClickClient(client)}>
                  <ClientItem
                    src={client.src}
                    name={client.name}
                    isSelected={client.isSelected}
                  />
                </MenuItem>
              ))}
            </MenuGroup>
            <MenuDivider />
          </>
        )}
        <MenuItem onClick={onClickAddNewClient}>
          <AddNewClient />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
