import { useQuery } from '@apollo/client'
import { useState } from 'react'
import ErrorNotification from '../utils/ErrorNotification'
import Notification from '../utils/Notification'
import Loading from '../utils/Loading'
import NotificationArea from '../utils/NotificationArea'
import CreditCardModal from './billing/CreditCardModal'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { SMSTwoFactorModal } from '../SMSTwoFactor'
import { InfoCardItem } from './InfoCardItem'
import {
  Box,
  Card,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Button,
  Form,
  PaymentFormSchema,
  PaymentFormData,
  PayoutMethodEntity,
} from 'ui'
import {
  BankAccount,
  BankAccountsDocument,
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  GetMerchantSubscriptionDocument,
  OrganizationBillingDocument,
  UserRole,
} from '../../operations-types'
import SubscriptionCard from './billing/SubscriptionCard'
import moment from 'moment'
import SubscriptionPaymentMethodModal from './billing/SubscriptionPaymentMethodModal'
import { EmailTwoFactorModal } from '../EmailTwoFactor'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { useNavigate } from 'react-router-dom'
import { QBOChartOfAccountsModal } from '../erp/QBOChartofAccounts'
import PlaidLink from '../onboardingV2/PlaidLink'
import UpdateBankAccount from './billing/UpdateBankAccount'

type BillingMethodEntityProps = {
  label: string
  paymentMethod?: any
  bankAccount?: Omit<BankAccount, 'id'> | null
  onButtonClick?: () => void
}

function BillingMethodEntity(props: BillingMethodEntityProps) {
  const { data } = useQuery<GetBankRoutingNumberQuery>(
    GetBankRoutingNumberDocument,
    {
      variables: {
        bankRoutingNumber: props.bankAccount?.routingNumber,
      },
      skip: !props.bankAccount,
    },
  )

  const bankName = data?.bankRoutingNumber?.bankRoutingNumber?.bankName

  const { getUser } = useLoggedInStore()

  const IS_PERMITTED = getUser().isUserPermitted(UserRole.UserAdmin)

  return (
    <Flex
      flexDirection="row"
      alignItems="top"
      justifyItems="center"
      p="4"
      paddingRight={0}
      gap="4"
    >
      <Box>
        <Text fontSize="md" fontWeight="medium">
          {props.label}
        </Text>
      </Box>
      <Spacer />

      <>
        {props.paymentMethod?.type === 'card' && (
          <>
            <VStack gap="0" spacing="0" alignItems="left">
              <Text>
                {props.paymentMethod?.card?.brand.charAt(0).toUpperCase() +
                  props.paymentMethod?.card?.brand.slice(1) || ''}
              </Text>
              <Text>
                {props.paymentMethod?.card?.lastFour
                  ? `••• ${props.paymentMethod?.card?.lastFour}`
                  : ''}
              </Text>
              <Text>
                {props.paymentMethod
                  ? `${props.paymentMethod?.card?.expMonth} / ${props.paymentMethod?.card.expYear}`
                  : ''}
              </Text>
            </VStack>
          </>
        )}
        {props.bankAccount && (
          <>
            <PayoutMethodEntity
              bankName={!!bankName ? (bankName as string) : ''}
              routingNumber={props.bankAccount?.routingNumber || ''}
              holderName={props.bankAccount?.holderName || ''}
              lastTwo={props.bankAccount?.lastTwo || ''}
              type={props.bankAccount?.type || ''}
            />
          </>
        )}

        <Spacer />
      </>
      <Box>
        {IS_PERMITTED && (
          <Button
            variant="outline"
            size="sm"
            label="Update"
            onClick={props.onButtonClick}
          />
        )}
      </Box>
    </Flex>
  )
}

type SelectTwoFactorMethodProps = {
  onCloseAction?: () => void
  setTwoFacMethod: (value: string) => void
  smsEnabled: boolean
}

const SelectTwoFactorMethod = (props: SelectTwoFactorMethodProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        props.onCloseAction && props.onCloseAction()
        onClose()
      }}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent p="10">
        <ModalHeader>Select your Two-Factor method</ModalHeader>

        <ModalCloseButton />
        <ModalBody w="100%">
          <Flex flexDirection="column" w="100%" gap="4">
            <HStack w="100%">
              {props.smsEnabled && (
                <Button
                  label="SMS"
                  w="100%"
                  onClick={() => props.setTwoFacMethod('sms')}
                />
              )}

              <Button
                label="Email"
                w="100%"
                onClick={() => props.setTwoFacMethod('email')}
              />
            </HStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default function BillingSettings() {
  const userData = useDashboardOutletContext()
  const {
    data,
    loading: bankAccountLoading,
    refetch: refetchBankAccount,
  } = useQuery(BankAccountsDocument)

  const { data: organizationBillingData, refetch: refetchOrganizationData } =
    useQuery(OrganizationBillingDocument)

  const { data: merchantBillingData, refetch } = useQuery(
    GetMerchantSubscriptionDocument,
  )

  const [twoFactorOpen, setTwoFactorOpen] = useState(false)
  const [updateBankOpen, setUpdateBankOpen] = useState(false)
  const [twoFacMethod, setTwoFacMethod] = useState('')
  const [chartOfAccountModalOpen, setChartOfAccountModalOpen] = useState(false)

  const [creditCardOpen, setCreditCardOpen] = useState(false)
  const [showNotif, setShowNotif] = useState({
    message: '',
    show: false,
    header: '',
  })
  const [showError, setShowError] = useState({
    message: '',
    show: false,
  })

  const accountInfo = data?.organization?.organization?.accountInfo
  const defaultBankAccounts = (accountInfo?.bankAccounts || []).filter(
    (x) => x?.default,
  )

  const bankAccount = defaultBankAccounts?.[0]
  const accountRows = []

  accountRows.push({
    label: 'Account Holder Name',
    value: bankAccount?.holderName || '',
  })

  if (bankAccount?.type) {
    accountRows.push({
      label: 'Account Type',
      value:
        bankAccount.type.split('')[0].toUpperCase() + bankAccount.type.slice(1),
    })
  } else {
    accountRows.push({
      label: 'Account Type',
      value: '',
    })
  }

  accountRows.push({
    label: 'Routing Number',
    value: bankAccount?.routingNumber || '',
  })

  accountRows.push({
    label: 'Account Number',
    value: bankAccount?.lastTwo ? `········${bankAccount?.lastTwo}` : '',
  })

  const twoFactor = useFeatureFlagEnabled('two-factor-authentication')
  const braintreeBillingEnabled = useFeatureFlagEnabled(
    'isBraintreePaymentsBillingEnabled',
  )

  const [subscriptionPaymentMethodModal, setSubscriptionPaymentMethodModal] =
    useState(false)

  const [payoutMethodId, setPayoutMethodId] = useState<null | string>(null)

  const SMS_TWO_FACTOR_ENABLED = !!userData?.twoFactorEnabled

  const hasQuickbooksCompanyId =
    !!userData?.organization?.accountInfo?.quickbooksCompanyId

  const [plaidSucceeded, setPlaidSucceeded] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const plaidLinkDisabled = useFeatureFlagEnabled('plaidLinkDisabled')

  const [gqlToken, setGqlToken] = useState('')

  return (
    <Flex flexDirection="column" py={{ base: '4', md: '8' }} w="100%" gap="5">
      <NotificationArea>
        <Notification
          show={showNotif['show']}
          onClose={() => setShowNotif({ message: '', show: false, header: '' })}
          header={showNotif['header']}
          message={showNotif['message']}
        />
        <ErrorNotification
          show={showError['show']}
          onClose={() => setShowError({ message: '', show: false })}
          errorMessage={showError['message']}
        />
      </NotificationArea>
      {bankAccountLoading && <Loading />}
      {!bankAccountLoading && (
        <Box w="100%">
          <Stack spacing="8">
            {braintreeBillingEnabled &&
              organizationBillingData?.organization?.organization?.accountInfo
                ?.stagedSubscription?.stagedSubscription && (
                <Flex flexDirection="row" gap="5" w="100%">
                  <InfoCardItem
                    label="Nickel Subscription"
                    sublabel="Your Nickel subscription details"
                  >
                    <SubscriptionCard
                      {...{
                        activated:
                          !!organizationBillingData.organization.organization
                            .accountInfo.stagedSubscription
                            ?.stagedSubscription || false,
                        onUpdatePaymentMethod: async () => {
                          navigate('/dashboard/nickel-plans')
                        },
                        createSubscription: () => {},
                        onAutoRenewChange: () => {},
                        lastFour:
                          merchantBillingData?.getMerchantSubscription
                            ?.subscription?.paymentMethod?.lastFour || '',
                        lastTwo: '',
                        cardBrand:
                          merchantBillingData?.getMerchantSubscription
                            ?.subscription?.paymentMethod?.brand || '',
                        cardExpiry:
                          merchantBillingData?.getMerchantSubscription
                            ?.subscription?.paymentMethod?.expiresOn || '',
                        planName:
                          organizationBillingData.organization.organization
                            .accountInfo.stagedSubscription?.stagedSubscription
                            ?.name || '',
                        cycleNumber: 0,
                        numberOfBillingCycles:
                          organizationBillingData.organization.organization
                            .accountInfo?.stagedSubscription?.stagedSubscription
                            .numberOfBillingCycles || 0,
                        billingFrequency:
                          organizationBillingData.organization.organization
                            .accountInfo?.stagedSubscription?.stagedSubscription
                            .billingFrequency || 0,
                        autoRenew: false,
                        nextPayment: {
                          price:
                            organizationBillingData.organization.organization
                              .accountInfo.stagedSubscription
                              ?.stagedSubscription?.price || '0',
                          date: moment(
                            parseInt(
                              organizationBillingData.organization.organization
                                .accountInfo.stagedSubscription
                                .stagedSubscription.startDate || '0',
                            ),
                          )
                            .add(1, 'years')
                            .format('MM/DD/YYYY'), //TODO: This should be based on the last successful subscription payment
                          initiated:
                            organizationBillingData.organization.organization
                              .accountInfo.stagedSubscription.stagedSubscription
                              .initiated || false,
                        },
                      }}
                    />
                  </InfoCardItem>
                </Flex>
              )}
            <Flex flexDirection="row" gap="5" w="100%">
              <InfoCardItem
                label="Receive Payouts"
                sublabel="The bank account where we'll send your money"
              >
                <Card
                  bgColor="white"
                  p="6"
                  w="100%"
                  boxShadow="0"
                  border="1px solid"
                  borderColor="gray.300"
                >
                  <BillingMethodEntity
                    label="Payout Method"
                    bankAccount={bankAccount}
                    onButtonClick={() => {
                      setTwoFacMethod(SMS_TWO_FACTOR_ENABLED ? '' : 'email')
                      twoFactor
                        ? setTwoFactorOpen(true)
                        : setUpdateBankOpen(true)
                    }}
                  />
                </Card>
              </InfoCardItem>
            </Flex>
          </Stack>
          {twoFactorOpen && twoFactor && (
            <SelectTwoFactorMethod
              onCloseAction={() => setTwoFactorOpen(false)}
              setTwoFacMethod={setTwoFacMethod}
              smsEnabled={SMS_TWO_FACTOR_ENABLED}
            />
          )}

          {twoFactorOpen &&
            twoFactor &&
            SMS_TWO_FACTOR_ENABLED &&
            twoFacMethod === 'sms' && (
              <SMSTwoFactorModal
                enrollment={false}
                twoFactorSuccessAction={async (token: string) => {
                  await setGqlToken(token)
                  setTwoFactorOpen(false)
                  setUpdateBankOpen(true)
                }}
                onCloseAction={() => setTwoFactorOpen(false)}
              />
            )}

          {twoFactorOpen && twoFactor && twoFacMethod === 'email' && (
            <EmailTwoFactorModal
              enrollment={false}
              twoFactorSuccessAction={async (token: string) => {
                await setGqlToken(token)
                setTwoFactorOpen(false)
                setUpdateBankOpen(true)
              }}
              onCloseAction={() => setTwoFactorOpen(false)}
            />
          )}

          <Form<PaymentFormData>
            {...{
              className: 'flex flex-col',
              validationSchema: PaymentFormSchema,
              // Fetch initial values from state later
              initialValues: {
                email: '',
                billingAddress: {
                  fullName: '',
                  state: '',
                  city: '',
                  streetAddress: '',
                  zipCode: '',
                  company: '',
                },
              } as PaymentFormData,
            }}
          >
            {() => {
              return (
                <SubscriptionPaymentMethodModal
                  isOpen={subscriptionPaymentMethodModal}
                  onClose={() => setSubscriptionPaymentMethodModal(false)}
                  clientToken={''}
                  organizationId={
                    organizationBillingData?.organization?.organization?.id ||
                    ''
                  }
                  refetch={async () => {
                    await refetch()
                    await refetchOrganizationData()
                  }}
                  subscriptionId={
                    organizationBillingData?.organization?.organization
                      ?.accountInfo?.subscription?.subscription?.id || ''
                  }
                  activated={
                    organizationBillingData?.organization?.organization
                      ?.accountInfo?.stagedSubscription?.stagedSubscription
                      ?.initiated || false
                  }
                  submittedAmountInCents={Math.round(
                    parseFloat(
                      organizationBillingData?.organization?.organization
                        ?.accountInfo?.stagedSubscription?.stagedSubscription
                        ?.price || '0',
                    ) * 100,
                  )}
                />
              )
            }}
          </Form>

          {plaidLinkDisabled && (
            <UpdateBankAccount
              open={updateBankOpen}
              setOpen={setUpdateBankOpen}
              setShowError={setShowError}
              setShowNotif={setShowNotif}
              gqlToken={gqlToken}
              onCompleteAction={(account: BankAccount) => {
                if (hasQuickbooksCompanyId) {
                  setChartOfAccountModalOpen(true)
                  setPayoutMethodId(account.id)
                }
              }}
            />
          )}

          {!plaidLinkDisabled && (
            <Modal
              isCentered
              isOpen={updateBankOpen}
              onClose={() => {
                setUpdateBankOpen(false)
                setTwoFactorOpen(false)
              }}
              trapFocus={false}
            >
              <ModalOverlay />
              <ModalContent p="10">
                <ModalBody>
                  {!plaidSucceeded && !loading && !plaidLinkDisabled ? (
                    <PlaidLink
                      methodType="both"
                      onboarding={true}
                      setLoading={setLoading}
                      onAddPayoutMethod={(payoutMethodDetails: string) => {
                        const details = JSON.parse(payoutMethodDetails) as {
                          id: string
                          name: string
                          routingNumber: string
                          accountNumber: string
                          bankName: string
                          accountType: string
                        }
                        setPlaidSucceeded(true)

                        if (hasQuickbooksCompanyId) {
                          setChartOfAccountModalOpen(true)
                          setPayoutMethodId(details.id)
                        }
                        setUpdateBankOpen(false)
                        refetchBankAccount()
                        setPlaidSucceeded(false)
                      }}
                    />
                  ) : !plaidSucceeded && loading && !plaidLinkDisabled ? (
                    <Loading />
                  ) : (
                    ''
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>
          )}

          <QBOChartOfAccountsModal
            isOpen={chartOfAccountModalOpen}
            setModalOpen={setChartOfAccountModalOpen}
            type="bankAccount"
            accountString="your bank account."
            payoutMethodId={payoutMethodId || ''}
            onCompleted={() => setChartOfAccountModalOpen(false)}
          />
          <CreditCardModal
            publicKey={accountInfo?.publicKey || ''}
            nickelMerchantId={''}
            open={creditCardOpen}
            setOpen={setCreditCardOpen}
            setShowNotif={setShowNotif}
          />
        </Box>
      )}
    </Flex>
  )
}
