import _ from 'lodash'
import Column from '../Layout/Column'
import { Customer } from './CustomerItem'
import CustomerRow from './CustomerRow'

export type CustomerPageProps = {
  customers: Customer[]
  onClick?: (customer: Customer) => void
}

export function CustomersPage({ customers, onClick }: CustomerPageProps) {
  const customerRows = [customers]

  return (
    <Column wGrow gap="medium">
      {customerRows.map((customers, index) => (
        <CustomerRow
          key={index}
          onClick={onClick}
          customers={customers}
        ></CustomerRow>
      ))}
    </Column>
  )
}
