import { useMutation } from '@apollo/client'
import {
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { FieldArray } from 'formik'
import { useState } from 'react'
import { Button, Card, Column, Form } from 'ui'

import * as yup from 'yup'
import {
  Recipient,
  SendVendorNotificationDocument,
} from '../../../operations-types'
import { SlimmedContactInputFields } from '../../paymentLinks/PaymentLinkSuccessOverlay'
import VendorSelect from '../VendorSelect'
import { useSendPaymentStore } from './SendPaymentRouter'

type Props = {
  initialRecipients: Array<Recipient>
}

const SendVendorEmailSchema = yup.object({
  contacts: yup.array().of(
    yup.object({
      name: yup.string().required(),
      phoneNumber: yup.string().length(12),
      email: yup.string(),
    }),
  ),
  newContact: yup.object({
    name: yup.string().required(),
    phoneNumber: yup.string().length(12),
    email: yup.string(),
  }),
  memo: yup.string(),
})

type SendVendorEmailSchemaType = yup.InferType<typeof SendVendorEmailSchema>

type AddVendorContactFormProps = {
  recipientInputName: string
  emailInputName: string
  phoneNumberInputName: string
  onSendNotifications: () => void
  formik: any
}

const AddVendorContactForm = ({
  recipientInputName,
  emailInputName,
  phoneNumberInputName,
  onSendNotifications,
  formik,
}: AddVendorContactFormProps) => {
  return (
    <Column wGrow gap="small" className="py-2">
      <SlimmedContactInputFields
        recipientInputName={recipientInputName}
        emailInputName={emailInputName}
        phoneNumberInputName={phoneNumberInputName}
      />
      <InputGroup pt="4">
        <VStack w="100%" alignItems="left" spacing="0" gap="0">
          <FormLabel>Add a personal note</FormLabel>
          <Input
            name="memo"
            as={Textarea}
            placeholder="Enter any note/memo to be sent with the payment notification"
            onChange={formik.handleChange}
          />
        </VStack>
      </InputGroup>
      <InputGroup pt="2" className="w-full">
        <Button
          onClick={async () => {
            await onSendNotifications()
          }}
          className="w-full"
          color="white"
          label="Send Notifications"
          iconName="envelope"
          iconPosition="left"
          isDisabled={
            !formik.values.newContact.name ||
            (!formik.values.newContact.phoneNumber &&
              !formik.values.newContact.email)
          }
        />
      </InputGroup>
    </Column>
  )
}

type SendVendorNotificationCardProps = {
  initialRecipients: Array<{ label: string; value: Recipient }>
}

const SendVendorNotificationCard = (props: SendVendorNotificationCardProps) => {
  const toaster = useToast()
  const [sendVendorNotification] = useMutation(SendVendorNotificationDocument, {
    onCompleted: () => {
      toaster({
        title: 'Notification sent successfully',
        status: 'success',
      })
    },
    onError: (error) => {
      toaster({
        title: error.message,
        status: 'error',
      })
    },
  })
  const { billPaymentId, recipientDetails } = useSendPaymentStore()
  const [defValue, setDefValue] = useState<string | undefined>(undefined)

  return (
    <>
      <Form<SendVendorEmailSchemaType>
        {...{
          className: 'w-full h-full flex flex-col',
          initialValues: {
            contacts: [],
            newContact: {
              name: '',
              phoneNumber: '',
              email: '',
            },
            memo: '',
          },
        }}
      >
        {(formik) => (
          <>
            <Card className="py-8 px-6 w-[540px]">
              <Flex flexDirection="column" gap={6}>
                <HStack>
                  <Text fontSize="lg" fontWeight="semibold">
                    Send {recipientDetails?.vendorName} a payment notification
                  </Text>
                </HStack>
                <Flex flexDirection="column" gap={2}>
                  <FieldArray
                    name="contacts"
                    render={(vals) => (
                      <VStack w="100%" gap="0" spacing="0" alignItems="left">
                        <FormLabel>Add a contact</FormLabel>
                        <VendorSelect
                          {...{
                            value: defValue,
                            vendors: props.initialRecipients,
                            onValueSelect: async (g: {
                              label: string
                              value: Recipient | string
                            }) => {
                              let currValue = g.value as Recipient

                              if (
                                (currValue.email || currValue.phoneNumber) &&
                                currValue.name
                              ) {
                                await formik.setFieldValue('newContact', {
                                  name: currValue.name,
                                  phoneNumber: currValue.phoneNumber,
                                  email: currValue.email,
                                })

                                setDefValue(currValue.name)
                              }
                            },
                            onAddVendor: async (e) => {
                              setDefValue(e)

                              await formik.setFieldValue('newContact', {
                                name: e,
                                phoneNumber: '',
                                email: '',
                              })
                            },
                          }}
                        />

                        {(formik.values.newContact?.name ||
                          formik.values.contacts?.[0]?.name) && (
                          <AddVendorContactForm
                            recipientInputName="newContact.name"
                            emailInputName="newContact.email"
                            phoneNumberInputName="newContact.phoneNumber"
                            onSendNotifications={async () => {
                              await sendVendorNotification({
                                variables: {
                                  billPaymentId: billPaymentId || '',
                                  email: formik.values.newContact.email,
                                  contactName: formik.values.newContact.name,
                                  phoneNumber:
                                    formik.values.newContact.phoneNumber,
                                  memo: formik.values.memo,
                                },
                              })

                              await vals.push({
                                name: formik.values.newContact.name,
                                email: formik.values.newContact.email,
                                phoneNumber:
                                  formik.values.newContact.phoneNumber,
                              })

                              await formik.setFieldValue('newContact', {
                                name: '',
                                phoneNumber: '',
                                email: '',
                              })

                              setDefValue(undefined)
                            }}
                            formik={formik}
                          />
                        )}
                        <Column spacing="small" wGrow gap="small">
                          {(formik.values.contacts || []).map(
                            (contact: Recipient, index) => (
                              <Flex flexDirection="row" w="100%" key={index}>
                                <Text color="gray.800">
                                  {contact.name} | {contact.email}{' '}
                                  {contact.phoneNumber}
                                </Text>
                              </Flex>
                            ),
                          )}
                        </Column>
                      </VStack>
                    )}
                  />
                </Flex>
              </Flex>
            </Card>
          </>
        )}
      </Form>
    </>
  )
}

const SendVendorNotification = (props: Props) => {
  return (
    <Flex flexDirection="column" gap={8}>
      <SendVendorNotificationCard
        {...{
          initialRecipients: props.initialRecipients.map((e) => ({
            label: e.name || '',
            value: e,
          })),
        }}
      />
    </Flex>
  )
}

export default SendVendorNotification
