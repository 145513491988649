import { AccountInfo } from '../../operations-types'

export function useCodatLinkedConnection(
  accountInfo: Pick<AccountInfo, 'quickbooksCompanyId'> | null,
) {
  if (accountInfo?.quickbooksCompanyId) {
    return {
      hasLinkedConnection: true,
    }
  }

  return {
    hasLinkedConnection: false,
  }
}
