import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Column, Table } from 'ui'
import { TRANSACTIONS_URL } from '../../lib/urls'
import { reportErrorIfExists } from '../../lib/utils'
import { PaymentActivitiesDocument } from '../../operations-types'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { transformTransactionData } from '../transactions/utils'

export default function DashboardTable() {
  const outletContext = useDashboardOutletContext()

  const defaultBankAccount =
    outletContext.organization?.accountInfo?.bankAccounts?.[0]

  const navigate = useNavigate()

  const queryOpts = {
    page: 1,
    pageSize: 5,
  }

  const { loading, data, error } = useQuery(PaymentActivitiesDocument, {
    variables: {
      ...queryOpts,
    },
  })

  reportErrorIfExists(data?.paymentActivities?.error?.message || error)

  const paymentActivities = data?.paymentActivities?.paymentActivities || []

  const transactions = transformTransactionData(
    paymentActivities,
    defaultBankAccount || null,
  )

  const table = (
    <Table
      {...{
        title: `Latest Transactions`,
        rowSize: 'large',
        cellSize: 'large',
        linkText: 'View all transactions',
        loading: loading,
        noPagination: true,
        linkOnClick() {
          navigate(TRANSACTIONS_URL)
        },
        headers: [
          {
            type: 'date',
            keyName: 'createdAt',
            label: 'Date',
          },
          {
            type: 'colorText',
            keyName: 'amount',
            label: 'Amount',
            grow: 1,
            className: '!font-normal',
            right: true,
          },
          {
            type: 'colorText',
            label: 'Type',
            keyName: 'label',
            grow: 1.5,
            className: '!font-normal',
          },
          {
            type: 'methodWithName',
            keyName: 'payoutMethod',
            label: 'Your Account',
            grow: 2,
          },
          {
            type: 'direction',
            keyName: 'direction',
            label: '',
            grow: 0.5,
          },
          {
            type: 'methodWithName',
            keyName: 'paymentMethod',
            label: 'To / From',
            grow: 2,
          },
          {
            type: 'reference',
            keyName: 'reference',
            label: 'Purpose',
            grow: 1,
          },
          {
            type: 'transactionStatus',
            keyName: 'transactionStatus',
            label: 'Status',
            center: true,
          },
        ],
        data: transactions,
        page: 1,
        perPage: 5,
        onChange: () => {},
        totalPages: 1,
        height: '500px',
      }}
    />
  )

  return <Column wGrow>{table}</Column>
}
