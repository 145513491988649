import { useMutation } from '@apollo/client'
import { Switch, VStack } from '@chakra-ui/react'
import {
  Entity,
  EntityField,
  EntityInput,
  Form,
  FormButton,
  calculateFeePercentage,
  calculateFeePercentageForMerchant,
  replaceTrailingZeroes,
} from 'ui'
import * as yup from 'yup'
import { useDashboardOutletContext } from '../../../lib/outletContext'
import {
  SelfDocument,
  UpdatePaymentSettingsDocument,
} from '../../../operations-types'
import { PaymentSettings } from '../../../types'

type PaymentMethodProps = {
  paymentSettings: PaymentSettings
  onCompleted?: () => void
}

export default function PaymentMethods({
  paymentSettings,
  onCompleted,
}: PaymentMethodProps) {
  const data = useDashboardOutletContext()
  const [updatePaymentSettings, { loading }] = useMutation(
    UpdatePaymentSettingsDocument,
    {
      onCompleted: onCompleted,
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    },
  )

  return (
    <Form
      {...{
        validationSchema: yup.object().shape({
          feePassthroughPercentage: yup
            .number()
            .integer()
            .min(0)
            .max(100)
            .required(),
          creditCardEnabled: yup.boolean().required(),
          achEnabled: yup.boolean().required(),
          paymentAmountEditable: yup.boolean().required(),
        }),
        initialValues: {
          feePassthroughPercentage: paymentSettings.feePassthroughPercent,
          creditCardEnabled: paymentSettings.creditCardEnabled,
          achEnabled: paymentSettings.achEnabled,
          debitCardEnabled: paymentSettings.debitCardEnabled,
          paymentAmountEditable: paymentSettings.paymentAmountEditable,
        },
        onSubmit: async (values, actions) => {
          await updatePaymentSettings({
            variables: {
              feePassthroughPercent: values.feePassthroughPercentage,
              creditCardEnabled: values.creditCardEnabled,
              achEnabled: values.achEnabled,
              debitCardEnabled: values.creditCardEnabled,
              paymentAmountEditable: values.paymentAmountEditable,
              automaticQbInvoiceEmail:
                !!data?.organization?.accountInfo?.paymentSettings
                  ?.automaticQbInvoiceEmail,
            },
          })
          actions.setSubmitting(false)
        },
        className: 'w-full h-full',
      }}
    >
      {(formik) => (
        <VStack alignItems="flex-end" spacing="4">
          <VStack width="100%" spacing="2" alignItems="flex-end">
            <Entity
              disabled={!formik.values.creditCardEnabled}
              minHeight="120px"
            >
              <EntityField title="Cards" />
              <EntityField
                titleTextColor="gray.500"
                titleSize="xs"
                descriptionTextColor="gray.800"
                title="You pay"
                description={`${replaceTrailingZeroes(
                  calculateFeePercentageForMerchant(
                    100 - formik.values.feePassthroughPercentage,
                  ),
                )}%`}
              />
              <EntityField
                titleTextColor="gray.500"
                titleSize="xs"
                descriptionTextColor="gray.800"
                title="Your customer pays"
                description={`${replaceTrailingZeroes(
                  calculateFeePercentage(
                    formik.values.feePassthroughPercentage,
                  ),
                )}%`}
              />
              <EntityInput
                label="Fee passthrough percentage"
                id="feePassthroughPercentage"
                type="number"
                value={formik.values.feePassthroughPercentage}
                onChange={formik.handleChange}
                isDisabled={!formik.values.creditCardEnabled}
                rightAddon="%"
                error={
                  formik.errors.feePassthroughPercentage
                    ? 'Fee must be between 0 to 100'
                    : ''
                }
              />
            </Entity>
            <Entity
              disabled={!formik.values.achEnabled}
              minHeight="120px"
              actions={
                <Switch
                  size="lg"
                  isChecked={formik.values.achEnabled}
                  onChange={() => {
                    const achEnabled = !formik.values.achEnabled

                    // If both are disabled, enable Credit Card, because we need at least one enabled
                    if (!achEnabled && !formik.values.creditCardEnabled) {
                      formik.setFieldValue('creditCardEnabled', true)
                    }

                    formik.setFieldValue('achEnabled', achEnabled)
                  }}
                />
              }
            >
              <EntityField title="ACH / eCheck" />
              <EntityField
                titleTextColor="gray.500"
                titleSize="xs"
                descriptionTextColor="gray.800"
                title="You pay"
                description="0%"
              />
              <EntityField
                titleTextColor="gray.500"
                titleSize="xs"
                descriptionTextColor="gray.800"
                title="Your customer pays"
                description="0%"
              />
              <EntityField />
            </Entity>
            <Entity
              disabled={!formik.values.paymentAmountEditable}
              minHeight="120px"
              actions={
                <Switch
                  size="lg"
                  isChecked={formik.values.paymentAmountEditable}
                  onChange={() =>
                    formik.setFieldValue(
                      'paymentAmountEditable',
                      !formik.values.paymentAmountEditable,
                    )
                  }
                />
              }
            >
              <EntityField title="Edit Payment Amount" />
              <EntityField
                title="Allow your customers to edit your requested payment amount to short pay or make multiple payments."
                titleSize="xs"
                titleTextColor="gray.600"
              />
              <EntityField />
              <EntityField />
            </Entity>
          </VStack>
          <FormButton loading={loading} x="right" label="Save" />
        </VStack>
      )}
    </Form>
  )
}
