import { property, styles, tw } from '@nickeltech/brise'
import { motion, MotionProps } from 'framer-motion'
import { Ref } from 'react'

import ContainerProps from '../../../types'
import { Gap, GapProps, SpacingProps, SpacingX, SpacingY } from './Spacing'

export type RowXType = 'left' | 'center' | 'right'
export type RowYType = 'top' | 'center' | 'bottom'

export const RowXPosition: Array<RowXType> = ['left', 'center', 'right']
export const RowYPosition: Array<RowYType> = ['top', 'center', 'bottom']

export type VisibilityRowProps = {
  visible?: boolean
}

export type RowProps = {
  x?: RowXType
  y?: RowYType
  between?: boolean
  around?: boolean
  grow?: boolean
  hGrow?: boolean
  forwardedRef?: Ref<HTMLDivElement>
} & ContainerProps &
  SpacingProps &
  GapProps &
  MotionProps

export const RowXPositionStyle = property(
  'x',
  {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
    default: '',
  },
  'default',
)

export const RowYPositionStyle = property(
  'y',
  {
    top: 'items-start',
    center: 'items-center',
    bottom: 'items-end',
    default: '',
  },
  'default',
)

export const RowAdditionalStyles = styles`
  ${(props: RowProps) => (props.between ? 'justify-between' : '')}
  ${(props: RowProps) => (props.around ? 'justify-around' : '')}
  ${(props: RowProps) => (props.onClick ? 'cursor-pointer' : '')}
  ${(props: RowProps) => (props.grow ? 'grow' : '')}
  ${(props: RowProps) => (props.hGrow ? 'h-full' : '')}
`

export const Row = tw(
  ({
    className,
    style,
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    animate,
    initial,
    forwardedRef,
    onAnimationComplete,
  }: RowProps) => {
    return (
      <motion.div
        {...{
          style,
          onClick,
          onMouseEnter,
          onMouseLeave,
          animate: animate,
          initial: initial || false,
          ref: forwardedRef,
          onAnimationComplete,
          className,
        }}
      >
        {children}
      </motion.div>
    )
  },
)`
  flex
  flex-row
  ${RowXPositionStyle}
  ${RowYPositionStyle}
  ${RowAdditionalStyles}
  ${Gap}
  ${SpacingX}
  ${SpacingY}
`

export default Row
