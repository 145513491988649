import { tw } from '@nickeltech/brise'
import Spacing, { SpacingProps } from '../Layout/Spacing'
import ContainerProps, { Status } from '../../types'
import Row, { RowProps } from '../Layout/Row'
import Text from '../Typography/Text'
import { StatusBackgroundStyle } from '../Indicators'

export type CalloutProps = {
  status?: Status
  label?: string
} & ContainerProps &
  SpacingProps &
  RowProps

export const CalloutContainer = tw<CalloutProps>(Row)`
  rounded
  ${Spacing}
  ${StatusBackgroundStyle}
`

export const Callout = (props: CalloutProps) => {
  return (
    <CalloutContainer {...props} grow spacing="smallNarrow">
      {props.label ? (
        <Text status={props.status} variant="tertiary">
          {props.label}
        </Text>
      ) : (
        props.children
      )}
    </CalloutContainer>
  )
}

export default Callout
