import { NavLink } from 'react-router-dom'

type CodatLinkProps = {
  onLinkClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function CodatLink({ onLinkClick }: CodatLinkProps) {
  return (
    <div className="px-2">
      <div>
        <h3 className="text-3xl leading-6 font-medium text-gray-900">
          Link your accounting software
        </h3>
        <p className="mt-6 max-w-2xl text-md text-gray-500">
          In order for Nickel to do automatic reconciliation for your invoice
          payments, we&apos;ll need you to provide access to your accounting or
          ERP software.
        </p>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          className="ml-3 inline-flex justify-center py-2 px-8 mt-4 border shadow-sm text-lg font-medium rounded-md text-nickel-purple bg-white hover:bg-nickel-purple hover:text-white focus:outline-none"
          onClick={onLinkClick}
        >
          Link
        </button>
      </div>
      <div>
        <h3 className="text-3xl leading-6 font-medium text-gray-700">
          Or, skip
        </h3>
        <p className="mt-6 max-w-2xl text-md text-gray-500">
          You can still use Nickel to send invoices and get paid, and you can
          always link your accounting system later.
        </p>
        <div className="flex justify-end">
          <NavLink
            to="/dashboard"
            className="ml-3 inline-flex justify-center py-2 px-8 mt-4 border shadow-sm text-lg font-medium rounded-md text-grey-500 bg-white hover:bg-nickel-purple hover:text-white focus:outline-none"
          >
            Skip
          </NavLink>
        </div>
      </div>
    </div>
  )
}
