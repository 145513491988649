import { Button, InputProps } from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import { useState } from 'react'
import { InputField } from './InputField'

export type PasswordFieldProps = {
  fieldName: string
} & InputProps

export function PasswordField<T extends FormikValues>({
  fieldName,
  ...other
}: PasswordFieldProps) {
  const { handleChange, values, errors, touched } = useFormikContext<T>()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InputField
      label="Password"
      {...other}
      type={showPassword ? 'text' : 'password'}
      id={fieldName}
      name={fieldName}
      value={values[fieldName]}
      onChange={handleChange}
      error={touched[fieldName] ? (errors[fieldName] as string) : undefined}
      rightElement={
        <Button
          h="1.5rem"
          size="sm"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? 'Hide' : 'Show'}
        </Button>
      }
    />
  )
}
