import { useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import {
  Column,
  OverlayFooter,
  OverlayPayoutBreakdown,
  OverlaySectionDetails,
  OverlaySectionTransactions,
  Separator,
  Transaction,
  UnstandardizedColumn,
} from 'ui'
import { TransactionStatusValue } from 'ui/src/components/v3/TransactionStatus'
import {
  getPaymentMethodIdentifier,
  reportErrorIfExists,
} from '../../lib/utils'
import { GetPayoutDocument } from '../../operations-types'
import { Payout, PayoutHelper } from '../payments/PayoutHelper'
import { OverlayHeader } from '../payments/OverlayHeader'
import AmountHeader from '../payments/PaymentAmountSubmitted'

type PayoutInnerOverlay = {
  payoutId: string
  onExit: () => void
  onTransactionClick?: (transaction: Transaction) => void
  onBack?: () => void
}

type Charge = NonNullable<Payout['charges']>[0]
type Refund = NonNullable<Payout['refunds']>[0]
type Fee = NonNullable<Payout['fees']>[0]
type Return = NonNullable<Payout['returns']>[0]

function getCharges(charges: Charge[]): Transaction[] {
  const sortedCharges = [...charges].sort(
    (a, b) =>
      Number.parseInt(a.createdAt || '0') - Number.parseInt(b.createdAt || '0'),
  )

  return sortedCharges.map((charge) => {
    const paymentAttempt = charge.payment?.paymentAttempts?.at(0)

    const methodType =
      charge.payment?.paymentMethod?.type === 'card' ? 'card' : 'bank'

    const methodText = getPaymentMethodIdentifier(
      charge.payment?.paymentMethod,
    ).identifier

    return {
      amountDollars: (charge.payment?.amountInCents || 0) / 100,
      sublabel: charge.payment?.id || '',
      label: paymentAttempt?.fullName || 'N/A',
      type: 'credit',
      methodText: methodText,
      methodType: methodType,
      transactionId: charge.payment?.id || '',
    }
  })
}

function getFees(fees: Fee[]): Transaction[] {
  const sortedFees = [...fees].sort(
    (a, b) =>
      Number.parseInt(a.charge.createdAt || '0') -
      Number.parseInt(b.charge.createdAt || '0'),
  )

  return sortedFees.map((fee) => {
    const charge = fee.charge
    const paymentAttempt = charge.payment?.paymentAttempts?.at(0)
    const methodType =
      charge.payment?.paymentMethod?.type === 'card' ? 'card' : 'bank'

    const methodText = getPaymentMethodIdentifier(
      charge.payment?.paymentMethod,
    ).identifier

    return {
      // Fees are negative in Tilled but the component expects them as positive
      amountDollars: Math.abs(fee.amountInCents / 100),
      sublabel: charge.payment?.id || '',
      label: paymentAttempt?.fullName || 'N/A',
      type: fee.amountInCents < 0 ? 'debit' : 'credit',
      methodText: methodText,
      methodType: methodType,
      transactionId: charge.payment?.id || '',
    }
  })
}

function getReturns(returns: Return[]): Transaction[] {
  const sortedReturns = [...returns].sort(
    (a, b) =>
      Number.parseInt(a.charge.createdAt || '0') -
      Number.parseInt(b.charge.createdAt || '0'),
  )

  return sortedReturns.map((achReturn) => {
    const charge = achReturn.charge
    const paymentAttempt = charge.payment?.paymentAttempts?.at(0)
    const methodType =
      charge.payment?.paymentMethod?.type === 'card' ? 'card' : 'bank'

    const methodText = getPaymentMethodIdentifier(
      charge.payment?.paymentMethod,
    ).identifier

    return {
      amountDollars: (achReturn.amountCents || 0) / 100,
      sublabel: charge.payment?.id || '',
      label: paymentAttempt?.fullName || 'N/A',
      type: 'debit',
      methodText: methodText,
      methodType: methodType,
      transactionId: charge.payment?.id || '',
    }
  })
}

function getRefunds(refunds: Refund[]): Transaction[] {
  const sortedRefunds = [...refunds].sort(
    (a, b) =>
      Number.parseInt(a.charge.createdAt || '0') -
      Number.parseInt(b.charge.createdAt || '0'),
  )

  return sortedRefunds.map((refund) => {
    const charge = refund.charge
    const paymentAttempt = charge.payment?.paymentAttempts?.at(0)
    const methodType =
      charge.payment?.paymentMethod?.type === 'card' ? 'card' : 'bank'

    const methodText = getPaymentMethodIdentifier(
      charge.payment?.paymentMethod,
    ).identifier

    return {
      amountDollars: (refund.amountCents || 0) / 100,
      sublabel: charge.payment?.id || '',
      label: paymentAttempt?.fullName || 'N/A',
      type: 'debit',
      methodText: methodText,
      methodType: methodType,
      transactionId: charge.payment?.id || '',
    }
  })
}

export default function PayoutsOverlay({
  payoutId,
  onTransactionClick,
  onExit,
  onBack,
}: PayoutInnerOverlay) {
  const { loading, data, error } = useQuery(GetPayoutDocument, {
    variables: { payoutId: payoutId },
  })

  reportErrorIfExists(data?.payout?.error?.message || error)

  const payout = data?.payout?.payout

  if (loading || !payout) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const payoutHelper = new PayoutHelper(payout)

  const charges = getCharges(payout?.charges || [])
  const refunds = getRefunds(payout?.refunds || [])
  const fees = getFees(payout?.fees || [])
  const returns = getReturns(payout?.returns || [])

  const realPayoutAmount = payout?.amountInCents || 0

  let netAmountAdjusted = payoutHelper.netAmountCents()
  let adjustmentCent = 0

  if (
    payoutHelper.hasDiscrepancy() &&
    Math.abs(payoutHelper.discrepancyAmountCents()) < 1
  ) {
    if (payoutHelper.discrepancyAmountCents() > 0) {
      netAmountAdjusted = Math.ceil(netAmountAdjusted)
      adjustmentCent = 1
    } else {
      netAmountAdjusted = Math.floor(netAmountAdjusted)
      adjustmentCent = -1
    }
  }

  return (
    <UnstandardizedColumn className="w-full h-full">
      <OverlayHeader
        onClose={onExit}
        onBack={onBack}
        transactionStatus={
          _.capitalize(payout?.status || '') as TransactionStatusValue
        }
      />
      <AmountHeader
        label={'Payout Amount'}
        amount={((realPayoutAmount * 1.0) / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
        paymentSubmitted={(payout.paidAt
          ? moment(payout.paidAt)
          : moment(parseInt(payout.createdAt))
        ).format('MM/DD/YYYY')}
      />
      <Separator orientation="horizontal" />
      <OverlayPayoutBreakdown
        title="Breakdown"
        refundedAmount={payoutHelper.refundAmountDollars()}
        totalAmount={netAmountAdjusted / 100}
        feeAmount={payoutHelper.feeDollars()}
        chargedAmount={payoutHelper.amountChargedDollars()}
        returnAmount={payoutHelper.returnAmountDollars()}
        adjustmentAmount={adjustmentCent / 100}
      />
      <Separator orientation="horizontal" />
      <OverlaySectionTransactions
        title="Transactions"
        charges={charges}
        refunds={refunds}
        fees={fees}
        returns={returns}
        onClick={
          onTransactionClick
            ? (transaction) => onTransactionClick(transaction)
            : undefined
        }
      />
      <Separator orientation="horizontal" />
      <OverlaySectionDetails
        details={[
          {
            label: 'Payout ID',
            value: payout?.id || 'N/A',
          },
          {
            label: 'Reference',
            value: payout?.externalPayoutId || 'N/A',
          },
        ]}
      />
      <div className="mt-auto w-full">
        <OverlayFooter icon="arrowDownTray" buttonText="Receipt" />
      </div>
    </UnstandardizedColumn>
  )
}
