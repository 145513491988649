import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  VStack,
  Text,
  Checkbox,
  Link,
  CardBody,
  Card,
  Box,
} from '@chakra-ui/react'
import {
  AddressInput,
  Button,
  FieldTooltip,
  Form,
  PayoutMethodEntity,
  ValidatingInput,
  websiteRegex,
} from 'ui'
import { Select } from 'chakra-react-select'
import * as yup from 'yup'
import { UsaStates } from 'usa-states'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  OnboardingCreatePayoutMethodDocument,
  SelfDocument,
  UpdateOnboardingInformationDocument,
} from '../../operations-types'
import OnboardingHeader from '../onboarding/OnboardingHeader'
import RegistrationBreadcrumbNavigator from '../register/RegistrationBreadcrumbNavigator'
import { Field } from 'formik'
import PlaidLink from '../onboardingV2/PlaidLink'
import Loading from '../utils/Loading'

const OnboardingBusinessInfoSchema = yup.object().shape({
  legalName: yup.string().required('Full legal name is required'),
  businessName: yup.string().required('Business name is required'),
  taxIdNumber: yup.string().required('Taxpayer ID is required'),
  website: yup
    .string()
    .matches(
      websiteRegex,
      'Please enter a valid website URL, i.e.: www.example.com',
    ),
  stateIncorporated: yup
    .string()
    .required('State of Incorporation is required'),
  companyAddress: yup.object().shape({
    streetAddress: yup.string().required('Street address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zipCode: yup.string().min(5).max(5).required('Zipcode is required'),
  }),
  beneficialOwner: yup.string().required('Beneficial owner is required'),
  bankName: yup.string(),
  routingNumber: yup
    .string()
    .min(9, '9-digit Routing Number is required.')
    .max(9, '9-digit Routing Number is required.')
    .required('This is a required field.'),
  accountNumber: yup
    .string()
    .min(4, 'Account number must be at least 4 digits.')
    .required('This is a required field.'),
  confirmAccountNumber: yup
    .string()
    .min(4, 'Account number must be at least 4 digits.')
    .required('This is a required field.'),
  accountType: yup.string().required('This is a required field.'),
  accountHolder: yup.string().required('This is a required field.'),
  statementDescriptor: yup.string().required('This is a required field.'),
  termsAccepted: yup.boolean().oneOf([true], 'You must accept the terms'),
})

type OnboardingBusinessInfoProps = {
  drawer?: boolean
  setStep?: (step: string) => void
}

function OnboardingBusinessInfo(props: OnboardingBusinessInfoProps) {
  const usStates = new UsaStates()

  const [stateLabel, setStateLabel] = useState('')

  const [plaidSucceeded, setPlaidSucceeded] = useState(false)

  const [loading, setLoading] = useState(false)

  const [updateOnboardingInfomation] = useMutation(
    UpdateOnboardingInformationDocument,
    {
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    },
  )

  const [createOnboardingPayoutMethod] = useMutation(
    OnboardingCreatePayoutMethodDocument,
  )

  return (
    <Box mt="32px">
      <Card mx="auto" maxW="2xl">
        <Flex flexDirection="column">
          {!props.drawer && (
            <>
              <OnboardingHeader />
              <HStack
                pt="1"
                ps="6"
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor="gray.200"
                pb="1"
              >
                <RegistrationBreadcrumbNavigator currentStep={2} />
              </HStack>
            </>
          )}

          <Form
            className="w-full"
            initialValues={{
              legalName: '',
              businessName: '',
              taxIdNumber: '',
              stateIncorporated: '',
              website: '',
              companyAddress: {
                streetAddress: '',
                city: '',
                state: '',
                zipCode: '',
              },
              bankName: '',
              beneficialOwner: '',
              routingNumber: '',
              accountNumber: '',
              confirmAccountNumber: '',
              accountType: '',
              accountHolder: '',
              statementDescriptor: '',
              termsAccepted: false,
            }}
            validationSchema={OnboardingBusinessInfoSchema}
            onSubmit={async (values) => {
              await updateOnboardingInfomation({
                variables: {
                  onboardingInfo: JSON.stringify({
                    ...values,
                    acceptTerms: values.termsAccepted,
                    street: values.companyAddress.streetAddress,
                    city: values.companyAddress.city,
                    state: values.companyAddress.state,
                    zip: values.companyAddress.zipCode,
                    website: values.website,
                    stateIncorporated: values.stateIncorporated,
                    applicationSubmitted: true,
                  }),
                },
              })

              await createOnboardingPayoutMethod({
                variables: {
                  routingNumber: values.routingNumber,
                  accountNumber: values.accountNumber,
                  accountType: values.accountType,
                  accountHolder: values.accountHolder,
                },
              })
            }}
            onChange={(values, formik) => {
              if (values.routingNumber) {
                formik.setFieldTouched('routingNumber', true)
              }
            }}
          >
            {(formik) => {
              return (
                <>
                  <CardBody p="6" boxShadow="0">
                    <Flex flexDirection="column" gap="5">
                      <Flex flexDirection="column">
                        <Text fontSize="lg" color="gray.800">
                          Business Info
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          We require your business info to verify your identity
                          and move money on your behalf.
                        </Text>
                      </Flex>

                      <HStack spacing="4">
                        <ValidatingInput
                          id="legalName"
                          fontSize="sm"
                          onChange={(e) => {
                            formik.setFieldValue('businessName', e.target.value)
                            formik.handleChange(e)
                          }}
                          validated={!!formik.touched.legalName}
                          isInvalid={!!formik.errors.legalName}
                          onBlur={formik.handleBlur}
                          label="Legal Business Name"
                          placeholder="Legal Business Name"
                          value={formik.values.legalName}
                        />

                        <ValidatingInput
                          id="businessName"
                          fontSize="sm"
                          onChange={formik.handleChange}
                          validated={!!formik.touched.businessName}
                          isInvalid={!!formik.errors.businessName}
                          onBlur={formik.handleBlur}
                          label="Doing business as"
                          placeholder="Business name presented to customer"
                          value={formik.values.businessName || ''}
                          popover={
                            <FieldTooltip text="The business name you present to customers." />
                          }
                        />
                      </HStack>

                      <HStack spacing="4">
                        <FormControl
                          isInvalid={
                            !!formik.errors.stateIncorporated &&
                            formik.touched.stateIncorporated
                          }
                        >
                          <FormLabel>State of Incorporation</FormLabel>
                          <Select
                            size="sm"
                            selectedOptionStyle="check"
                            placeholder="State"
                            name="stateIncorporated"
                            onMenuClose={() =>
                              formik.setFieldTouched('stateIncorporated', true)
                            }
                            options={usStates.states.map((states) => ({
                              value: states.abbreviation,
                              label: states.name,
                            }))}
                            onChange={(newValue) => {
                              if (!newValue) {
                                return
                              }
                              const selectedState = newValue.value
                              formik.setFieldValue(
                                'stateIncorporated',
                                selectedState,
                              )
                              setStateLabel(newValue.label)
                            }}
                            value={{
                              value: usStates.states.find(
                                (states) =>
                                  states.abbreviation ===
                                  formik.values.stateIncorporated,
                              )?.abbreviation,
                              label: stateLabel,
                            }}
                            chakraStyles={{
                              control: (provided, _) => ({
                                ...provided,
                                borderRadius: 'md',
                                height: '40px',
                              }),
                            }}
                          />
                          <FormErrorMessage m="3px">
                            {' '}
                            {formik.errors.stateIncorporated}
                          </FormErrorMessage>
                        </FormControl>
                        <ValidatingInput
                          id="taxIdNumber"
                          fontSize="sm"
                          onChange={formik.handleChange}
                          validated={!!formik.touched.taxIdNumber}
                          isInvalid={!!formik.errors.taxIdNumber}
                          onBlur={formik.handleBlur}
                          label="Business Identifier"
                          placeholder="9-digit Federal employer #"
                          value={formik.values.taxIdNumber}
                          popover={
                            <FieldTooltip
                              text="ie: Tax ID#, Canadian Business #, SSN #
                                        or EIN."
                            />
                          }
                        />
                      </HStack>

                      <HStack spacing="4">
                        <ValidatingInput
                          id="beneficialOwner"
                          fontSize="sm"
                          onChange={formik.handleChange}
                          validated={!!formik.touched.beneficialOwner}
                          isInvalid={!!formik.errors.beneficialOwner}
                          onBlur={formik.handleBlur}
                          label="Beneficial Owner"
                          placeholder="Full Name"
                          value={formik.values.beneficialOwner}
                          popover={
                            <FieldTooltip text="The owner of this business" />
                          }
                        />
                        <ValidatingInput
                          id="website"
                          fontSize="sm"
                          onChange={formik.handleChange}
                          validated={!!formik.touched.website}
                          isInvalid={!!formik.errors.website}
                          onBlur={formik.handleBlur}
                          label="Website"
                          placeholder="www.companywebsite.com"
                          value={formik.values.website}
                          popover={
                            <FieldTooltip text="Your website to redirect customers to" />
                          }
                        />
                      </HStack>

                      <AddressInput
                        {...{
                          valueKey: 'companyAddress',
                          apiKey: import.meta.env.VITE_SMARTY_STREETS_KEY,
                          label: 'Company Address',
                          popupContainerTarget: !!props.drawer
                            ? '.chakra-modal__body'
                            : '.chakra-card__body',
                        }}
                      />
                      <Divider />

                      <Flex flexDirection="column" gap="5">
                        <VStack
                          spacing="0"
                          gap="0"
                          alignItems="left"
                          alignContent="left"
                          justifyItems="left"
                          justifyContent="left"
                        >
                          <Text fontSize="lg" color="gray.800">
                            Bank Info
                          </Text>
                          <Text fontSize="sm" color="gray.500">
                            We require your banking information in order to send
                            you money.
                          </Text>
                        </VStack>

                        <Flex flexDirection="column" gap="2">
                          {!plaidSucceeded && !loading ? (
                            <PlaidLink
                              methodType="both"
                              onboarding={true}
                              setLoading={setLoading}
                              onAddPayoutMethod={(
                                payoutMethodDetails: string,
                              ) => {
                                const details = JSON.parse(
                                  payoutMethodDetails,
                                ) as {
                                  name: string
                                  routingNumber: string
                                  accountNumber: string
                                  bankName: string
                                  accountType: string
                                }

                                formik.setFieldValue(
                                  'routingNumber',
                                  details.routingNumber,
                                )
                                formik.setFieldValue(
                                  'accountNumber',
                                  details.accountNumber,
                                )
                                formik.setFieldValue(
                                  'confirmAccountNumber',
                                  details.accountNumber,
                                )
                                formik.setFieldValue(
                                  'bankName',
                                  details.bankName,
                                )
                                formik.setFieldValue(
                                  'accountType',
                                  details.accountType,
                                )
                                formik.setFieldValue(
                                  'accountHolder',
                                  details.name,
                                )

                                setPlaidSucceeded(true)
                              }}
                            />
                          ) : !plaidSucceeded && loading ? (
                            <Loading />
                          ) : (
                            ''
                          )}

                          {plaidSucceeded && (
                            <Card>
                              <CardBody>
                                {loading ? (
                                  <Loading />
                                ) : (
                                  <PayoutMethodEntity
                                    bankName={formik.values.bankName}
                                    routingNumber={formik.values.routingNumber}
                                    lastTwo={`•••${formik.values.accountNumber.slice(
                                      -2,
                                    )}`}
                                    type={
                                      !!formik.values.accountType
                                        ? formik.values.accountType
                                            .split('')[0]
                                            .toUpperCase() +
                                          formik.values.accountType.slice(1) +
                                          ' •'
                                        : ''
                                    }
                                    holderName={formik.values.accountHolder}
                                  />
                                )}
                              </CardBody>
                            </Card>
                          )}

                          <ValidatingInput
                            id="statementDescriptor"
                            fontSize="sm"
                            onChange={formik.handleChange}
                            validated={!!formik.touched.statementDescriptor}
                            isInvalid={!!formik.errors.statementDescriptor}
                            onBlur={formik.handleBlur}
                            error={formik.errors.statementDescriptor}
                            label="Statement Name"
                            placeholder="What name should appear on your customer statements?"
                            value={formik.values.statementDescriptor}
                          />
                        </Flex>
                        <VStack spacing="md" gap="2" w="100%">
                          <FormControl
                            isInvalid={
                              !!formik.errors.termsAccepted &&
                              !!formik.touched.termsAccepted
                            }
                          >
                            <FormLabel htmlFor="termsAccepted">
                              <HStack>
                                <Field
                                  as={Checkbox}
                                  name="termsAccepted"
                                  id="termsAccepted"
                                  value="true"
                                  checked={formik.values.termsAccepted}
                                  onChange={formik.handleChange}
                                />
                                <Text fontSize="md" fontWeight="md">
                                  I agree to the
                                  <Link
                                    href="https://www.getnickel.com/terms-of-use"
                                    fontSize="md"
                                    fontWeight="md"
                                    color="purple.700"
                                    isExternal
                                  >
                                    {' '}
                                    Terms of Service
                                  </Link>
                                </Text>
                              </HStack>
                            </FormLabel>
                            <FormErrorMessage>
                              {formik.errors.termsAccepted}
                            </FormErrorMessage>
                          </FormControl>
                        </VStack>
                      </Flex>
                      <Button
                        colorScheme="dark"
                        isLoading={formik.isSubmitting}
                        isDisabled={Object.keys(formik.errors).length !== 0}
                        label="Complete and send for review"
                        onClick={async () => {
                          formik.setSubmitting(true)
                          await formik.submitForm()
                          props.setStep && (await props.setStep('success'))
                        }}
                      />
                    </Flex>
                  </CardBody>
                </>
              )
            }}
          </Form>
        </Flex>
      </Card>
    </Box>
  )
}

export default OnboardingBusinessInfo
