import ContainerProps from '../../types'

export const VisaElectron = (props: ContainerProps) => {
  return (
    <svg
      viewBox="0 0 53.333332 34.666668"
      width="53.333332"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="matrix(.13333333 0 0 -.13333333 0 34.666667)">
        <path d="m400 0h-400v260h400z" fill="#eee" />
        <path d="m400 0h-400v80h400z" fill="#265697" />
        <path
          d="m256.926 206.746c-4.094 1.586-10.52 3.254-18.528 3.254-20.429 0-34.824-10.508-34.941-25.578-.117-11.121 10.273-17.34 18.106-21.063 8.046-3.781 10.761-6.211 10.722-9.609-.058-5.195-6.426-7.566-12.375-7.566-8.269 0-12.664 1.171-19.461 4.07l-2.656 1.23-2.91-17.351c4.836-2.16 13.769-4.035 23.039-4.133 21.726 0 35.828 10.391 36.004 26.484.078 8.817-5.43 15.528-17.363 21.055-7.227 3.582-11.653 5.977-11.614 9.609 0 3.223 3.75 6.661 11.848 6.661 6.758.117 11.66-1.395 15.469-2.957l1.855-.911z"
          fill="#265697"
        />
        <path
          d="m284.441 159.492c1.711 4.473 8.247 21.672 8.247 21.672-.129-.207 1.687 4.481 2.734 7.402l1.394-6.691s3.965-18.496 4.786-22.383zm25.5 49.961h-15.976c-4.961 0-8.652-1.375-10.82-6.433l-30.704-71.016h21.7s3.554 9.539 4.355 11.648c2.363 0 23.457-.047 26.477-.047.613-2.707 2.515-11.601 2.515-11.601h19.172z"
          fill="#265697"
        />
        <path
          d="m161.27 132.02 12.929 77.503h20.672l-12.937-77.503z"
          fill="#265697"
        />
        <path
          d="m143.938 209.465-20.239-52.856-2.156 10.743c-3.77 12.382-15.508 25.793-28.6328 32.519l18.5038-67.781 21.875.019 32.551 77.356z"
          fill="#265697"
        />
        <path
          d="m104.922 209.512h-33.34l-.2617-1.61c25.9375-6.41 43.0937-21.918 50.2227-40.55l-7.246 35.625c-1.262 4.906-4.883 6.371-9.375 6.535"
          fill="#d97b16"
        />
        <g fill="#fff">
          <path d="m198.965 54.9727h8.594l-3.633-24.336h4.336l.019.0274.313.0586 3.32 24.25 9.551.0546.566 4.0157-22.48-.0899z" />
          <path d="m148.652 30.6367h4.356v.0274l11.484.0586.586 4.0117-11.484-.0664 1.269 8.5859 8.731.0664.586 4.0039-8.731-.0664 1.133 7.7149 11.113.0546.586 4.0274-15.468-.1016z" />
          <path d="m106.66 30.6367h4.356l.007.0274 11.497.0586.566 4.0117-11.465-.0664 1.262 8.5859 8.738.0664.574 4.0039-8.726-.0664 1.133 7.7149 11.121.0546.566 4.0274-15.449-.1016z" />
          <path d="m136.863 59.043-4.812-.0899-4.16-28.3164h4.355v.0274l11.484.0586.567 4.0117-11.465-.0664z" />
          <path d="m170.859 44.9883c-1.074-8.2695 4.219-14.9883 11.817-14.9883 2.812 0 5.547.918 7.937 2.4883v4.7851c-2.058-1.9296-4.675-3.125-7.39-3.125-5.508 0-9.336 4.8672-8.555 10.8399.781 5.9961 5.867 10.8633 11.367 10.8633 2.656 0 4.922-1.1328 6.465-2.9922l2.324 3.7617c-2.09 2.1094-4.953 3.3789-8.261 3.3789-7.586 0-14.629-6.7305-15.704-15.0117" />
          <path d="m302.559 58.9531-3.465-20.3672-10.598 20.3672h-4.238l-4.922-28.3164 4.355.0781h.325l3.347 21.9024 11.446-21.9024h3.632l4.231 28.2383z" />
          <path d="m271.633 44.9883c-.754-5.9727-5.715-11.6211-10.996-11.6211-5.285 0-8.899 5.6484-8.157 11.6211.743 5.9961 5.645 11.6328 10.938 11.6328 5.281 0 8.965-5.6367 8.215-11.6328zm-7.707 15.0117c-7.305 0-14.082-6.7305-15.11-15.0117-1.043-8.2695 4.055-14.9883 11.372-14.9883 7.312 0 14.07 6.7188 15.105 14.9883 1.035 8.2812-4.055 15.0117-11.367 15.0117" />
          <path d="m234.375 46.1914h-5l1.289 9.3945 5.352-.0664c4.824-.0586 2.363-9.3281-1.641-9.3281zm1.934 12.8516-9.278-.0899-4.531-28.3164 4.348.0781h.007.313l1.602 11.9883 2.343.0117 7.032-12h4.699l-6.887 11.9805c8.645 1.9844 11.152 16.4453.352 16.3477" />
        </g>
      </g>
    </svg>
  )
}

export default VisaElectron
