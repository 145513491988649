import Icon from '../Icons'
import { UnstandardizedRow } from '../Layout/Row'
import Text from '../Typography/Text'

export function OverlayEmptyRefund() {
  return (
    <div className="p-4 bg-gray-100 rounded w-full">
      <UnstandardizedRow className="gap-x-2" y="center">
        <Icon name="receiptRefund"></Icon>
        <Text>You haven&apos;t issued any refunds yet.</Text>
      </UnstandardizedRow>
    </div>
  )
}

export function OverlayEmptyLoan() {
  return (
    <div className="p-4 bg-gray-100 rounded w-full">
      <UnstandardizedRow className="gap-x-2" y="center">
        <Icon name="receiptRefund"></Icon>
        <Text>You haven&apos;t received any advances yet.</Text>
      </UnstandardizedRow>
    </div>
  )
}
