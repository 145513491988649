import { extendTheme } from '@chakra-ui/react'
import { inputTheme } from './InputStyles'
import { buttonTheme, iconButtonTheme } from './ButtonStyles'
import { formLabelTheme } from './FormLabelStyles'
import { switchTheme } from './SwitchStyles'
import { badgeTheme } from './BadgeStyles'
import { selectTheme } from './SelectStyles'
import { modalTheme } from './ModalStyles'
import { radioTheme } from './RadioStyles'
import { dividerTheme } from './DividerStyles'
import { cardTheme } from './CardStyles'

export const ChakraThemeProvider = (bgColor = 'gray.50') =>
  extendTheme({
    fonts: {
      body: 'Inter, Arial, sans-serif',
      heading: 'Inter, Arial, sans-serif',
      mono: '',
    },
    colors: {
      dark: {
        50: '#fefaff',
        100: '#e0d9e3',
        200: '#c3b9c7',
        300: '#a99dad',
        400: '#8f8394',
        500: '#685d6d',
        600: '#4d4351',
        700: '#3b323e',
        800: '#2f2832',
        900: '#29222c',
      },
      primary: {
        50: '#fcf5ff',
        100: '#f3e1fb',
        200: '#e9cef6',
        300: '#d9b6e9',
        400: '#c9a0dc',
        500: '#9c71b0',
        600: '#6c487c',
        700: '#472d53',
        800: '#331f3c',
        900: '#2b1933',
      },
      accent: {
        50: '#fefffa',
        100: '#f7ffd6',
        200: '#f0ffb1',
        300: '#e8ff8b',
        400: '#e0ff65',
        500: '#d1ff3c',
        600: '#c5e55d',
        700: '#a8bb6c',
        800: '#767d5f',
        900: '#323330',
      },
      gray: {
        50: '#fafaf9',
        100: '#f5f5f4',
        200: '#e7e5e4',
        300: '#d6d3d1',
        400: '#a8a29e',
        500: '#78716c',
        600: '#57534e',
        700: '#44403c',
        800: '#292524',
        900: '#1c1917',
      },
      buttonGray: {
        50: '#fafaf9',
        100: '#f5f5f4',
        200: '#e7e5e4',
        300: '#d6d3d1',
        400: '#a8a29e',
        500: '#78716c',
        600: '#e7e5e4',
        700: '#44403c',
        800: '#292524',
        900: '#1c1917',
      },
      blue: {
        50: '#f0f9ff',
        100: '#e0f2fe',
        200: '#bae6fd',
        300: '#7dd3fc',
        400: '#38bdf8',
        500: '#0ea5e9',
        600: '#0284c7',
        700: '#0369a1',
        800: '#075985',
        900: '#0c4a6e',
      },
      green: {
        50: '#f0fdf4',
        100: '#dcfce7',
        200: '#bbf7d0',
        300: '#86efac',
        400: '#4ade80',
        500: '#22c55e',
        600: '#16a34a',
        700: '#15803d',
        800: '#166534',
        900: '#365314',
      },
      yellow: {
        50: '#fefce8',
        100: '#fef9c3',
        200: '#fef08a',
        300: '#fde047',
        400: '#facc15',
        500: '#eab308',
        600: '#ca8a04',
        700: '#a16207',
        800: '#854d0e',
        900: '#713f12',
      },
      orange: {
        50: '#fff7ed',
        100: '#ffedd5',
        200: '#fed7aa',
        300: '#fdba74',
        400: '#fb923c',
        500: '#f97316',
        600: '#ea580c',
        700: '#c2410c',
        800: '#9a3412',
        900: '#7c2d12',
      },
      red: {
        50: '#fef2f2',
        100: '#fee2e2',
        200: '#fecaca',
        300: '#fca5a5',
        400: '#f87171',
        500: '#ef4444',
        600: '#dc2626',
        700: '#b91c1c',
        800: '#991b1b',
        900: '#7f1d1d',
      },
      purple: {
        50: '#faf5ff',
        100: '#f3e8ff',
        200: '#e9d5ff',
        300: '#d8b4fe',
        400: '#c084fc',
        500: '#a855f7',
        600: '#7c3aed',
        700: '#6d28d9',
        800: '#5b21b6',
        900: '#4c1d95',
      },
      brand: {
        50: '#ebe5e9',
        100: '#d8ccd4',
        200: '#c4b3bf',
        300: '#b19aa9',
        400: '#9e8194',
        500: '#8a677f',
        600: '#77we69',
        700: '#633554',
        800: '#501c3f',
      },
      progressBarRed: {
        50: '#fca5a5',
        100: '#fca5a5',
        200: '#fca5a5',
        300: '#fca5a5',
        400: '#fca5a5',
        500: '#fca5a5',
        600: '#fca5a5',
        700: '#fca5a5',
        800: '#fca5a5',
      },
      progressBarOrange: {
        50: '#fdba74',
        100: '#fdba74',
        200: '#fdba74',
        300: '#fdba74',
        400: '#fdba74',
        500: '#fdba74',
        600: '#fdba74',
        700: '#fdba74',
        800: '#fdba74',
      },
    },
    borderRadius: {
      none: '0',
      sm: '0.125rem',
      base: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      full: '9999px',
    },
    fontSize: {
      small: '14px',
      base: '16px',
    },
    shadows: {
      outline: '0 0 0 1px #111111',
    },
    components: {
      FormLabel: formLabelTheme,
      Input: {
        ...inputTheme,
        defaultProps: {
          focusBorderColor: 'gray.800',
          size: 'small',
        },
      },
      Button: buttonTheme,
      Switch: switchTheme,
      Badge: badgeTheme,
      Select: selectTheme,
      Modal: modalTheme,
      Radio: radioTheme,
      IconButton: iconButtonTheme,
      Divider: dividerTheme,
      Card: cardTheme,
      Checkbox: {
        defaultProps: {
          colorScheme: 'dark',
        },
      },
    },
    styles: {
      global: {
        body: {
          bg: bgColor,
        },
      },
    },
  })

export default ChakraThemeProvider()
