import usdFormatter from '../../formatter'
import Icon from '../Icons'
import StatusPill from '../Indicators/StatusPill'
import Column, { UnstandardizedColumn } from '../Layout/Column'
import Row, { UnstandardizedRow } from '../Layout/Row'
import Separator from '../Layout/Separator'
import { UnstandardizedText } from '../Typography/Text'
import { OverlayCard } from './OverlayCard'

export type Refund = {
  id: string
  amount: number
  date: string
  status: string
}

export type Loan = {
  id: string
  principalAmount: number
  issueDate: string
  status: string
}

export type OverlayRefundProps = {
  refunds: Refund[]
  onRefundClick?: (refundId: string) => void
}

export type OverlayLoanProps = {
  loans: Loan[]
  onLoanClick?: (loanId: string) => void
}

function getStatus(refundStatus: string) {
  switch (refundStatus) {
    case 'pending':
      return 'action'
    case 'succeeded':
      return 'success'
    case 'failed':
      return 'error'
    case 'canceled':
      return 'error'
    default:
      return 'action'
  }
}

function getDisplayString(refundStatus: string) {
  switch (refundStatus) {
    case 'pending':
      return 'In Progress'
    case 'succeeded':
      return 'Completed'
    case 'completed':
      return 'Completed'
    case 'failed':
      return 'Failed'
    default:
      return 'In Progress'
  }
}

export function OverlayRefund(refund: Refund) {
  return (
    <UnstandardizedColumn wGrow gap="small">
      <Row grow between>
        <UnstandardizedRow className="gap-x-2" y="center">
          <Icon name="receiptRefund"></Icon>
          <UnstandardizedText className="text-base font-medium text-gray-800">
            {refund.id}
          </UnstandardizedText>
        </UnstandardizedRow>
        <UnstandardizedText className="text-base font-medium text-gray-800">
          {usdFormatter.format(refund.amount)}
        </UnstandardizedText>
      </Row>
      <Row grow between y="center">
        <StatusPill
          status={getStatus(refund.status.toLowerCase())}
          spacing="xsmallNarrow"
        >
          {refund.status}
        </StatusPill>
        <UnstandardizedText className="text-small font-normal text-gray-500">
          {refund.date}
        </UnstandardizedText>
      </Row>
    </UnstandardizedColumn>
  )
}

export function OverlayRefundV2({
  refund,
  onRefundClick,
}: {
  refund: Refund
  onRefundClick?: (refundId: string) => void
}) {
  return (
    <OverlayCard
      boldedText={getDisplayString(refund.status.toLowerCase())}
      remainderText={`on ${refund.date}`}
      onClick={
        onRefundClick && refund.id ? () => onRefundClick(refund.id) : undefined
      }
      subHeading={refund.id}
      firstItem={{
        itemName: 'Refund Amount',
        itemValue: `(${usdFormatter.format(refund.amount)})`,
      }}
    />
  )
}

export function OverlayLoan({
  loan,
  onLoanClick,
}: {
  loan: Loan
  onLoanClick?: (loanId: string) => void
}) {
  return (
    <OverlayCard
      boldedText={getDisplayString(loan.status.toLowerCase())}
      remainderText={`on ${loan.issueDate}`}
      onClick={onLoanClick && loan.id ? () => onLoanClick(loan.id) : undefined}
      subHeading={loan.id}
      firstItem={{
        itemName: 'Advanced Amount',
        itemValue: `${usdFormatter.format(loan.principalAmount)}`,
      }}
    />
  )
}

export function OverlayRefunds({ refunds }: OverlayRefundProps) {
  return (
    <UnstandardizedColumn wGrow className="rounded gap-y-2">
      {refunds.map((refund, index) => (
        <>
          <Column wGrow gap="small" key={index} className="p-4">
            <OverlayRefund {...refund} />
          </Column>
          {index < refunds.length - 1 && <Separator orientation="horizontal" />}
        </>
      ))}
    </UnstandardizedColumn>
  )
}

export function OverlayRefundsV2({
  refunds,
  onRefundClick,
}: OverlayRefundProps) {
  return (
    <UnstandardizedColumn wGrow className="rounded gap-y-2">
      {refunds.map((refund, index) => (
        <Column wGrow gap="small" key={index}>
          <OverlayRefundV2 refund={refund} onRefundClick={onRefundClick} />
        </Column>
      ))}
    </UnstandardizedColumn>
  )
}

export function OverlayLoans({ loans, onLoanClick }: OverlayLoanProps) {
  return (
    <UnstandardizedColumn wGrow className="rounded gap-y-2">
      {loans.map((loan, index) => (
        <Column wGrow gap="small" key={index}>
          <OverlayLoan loan={loan} onLoanClick={onLoanClick} />
        </Column>
      ))}
    </UnstandardizedColumn>
  )
}
