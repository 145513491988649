import { property, tw } from '@nickeltech/brise'
import Row, { RowProps } from '../layout/Row'
import { ContainerProps } from '../types'
import Icon, { IconName } from '../Icon'

export type BadgeVariant =
  | 'default'
  | 'diminished'
  | 'error'
  | 'accent'
  | 'warning'
  | 'success'
  | 'info'

export const BadgeVariantValues: Array<BadgeVariant> = [
  'default',
  'diminished',
  'error',
  'accent',
  'warning',
  'success',
  'info',
]

export type IconPosition = 'left' | 'right'

export const IconPositionValues: Array<IconPosition> = ['left', 'right']

export type BadgeProps = {
  variant?: BadgeVariant
  position?: IconPosition
  icon?: IconName
} & ContainerProps &
  RowProps

const VariantMap = {
  default: 'bg-white border border-gray-200',
  diminished: 'bg-gray-200 text-gray-800 border border-gray-200',
  error: 'bg-red-100 text-red-800 border border-red-100',
  accent: 'bg-accent-400 text-accent-900 border border-accent-400',
  warning: 'bg-orange-100 text-orange-800 border border-orange-100',
  success: 'bg-blue-100 text-blue-800 border border-blue-100',
  info: 'bg-green-100 text-green-800 border border-green-100',
}

export const BadgeVariant = property('variant', VariantMap, 'primary')

const BadgeContainer = tw<BadgeProps>(Row)`
    rounded
    font-medium
    text-[11px]
    px-2
    py-1
    ${BadgeVariant}
`

export const Badge = ({ children, ...props }: BadgeProps) => {
  return (
    <BadgeContainer
      {...{ ...props, x: 'center', y: 'center' }}
      spacingX="small"
      spacingY="xxs"
      gap="xxs"
    >
      {props.icon && props.position === 'left' ? (
        <Icon name={props.icon as IconName} />
      ) : (
        ''
      )}
      {children}
      {props.icon && props.position === 'right' ? (
        <Icon name={props.icon as IconName} />
      ) : (
        ''
      )}
    </BadgeContainer>
  )
}

export default Badge
