import currency from 'currency.js'
import { PaymentSummary } from 'ui'
import { create } from 'zustand'
import { PaymentMethod } from '../../../ap/components/SendPaymentRouter'
import { NickelPlansPage } from './types'

const NICKEL_PRO_PRICE_IN_CENTS = 3500

type NickelPlan = 'Free' | 'Pro' | 'Enterprise'

type NickelPlanState = {
  page: NickelPlansPage
  selectedPlan: NickelPlan | null
  savedPaymentMethods: PaymentMethod[]
  selectedSavedPaymentMethod: PaymentMethod | null
  paymentSummary?: PaymentSummary
  amountResult: AmountResult
  setPage: (page: NickelPlansPage) => void
  getPage: () => NickelPlansPage
  setAmountResult: (_: AmountResult) => void
  setSelectedPlan: (plan: NickelPlan) => void
  setPaymentMethods: (_: PaymentMethod[]) => void
  setSelectedSavedPaymentMethod: (paymentMethod: PaymentMethod | null) => void
  clearSelectedSavedPaymentMethod: () => void
  setPaymentSummary: (paymentSummary: PaymentSummary) => void
  clearPaymentFees: () => void
  reset: () => void
}

export type AmountResult = {
  submittedAmountCents: number
  reason: string
}

const initialState: Pick<
  NickelPlanState,
  | 'savedPaymentMethods'
  | 'page'
  | 'selectedPlan'
  | 'selectedSavedPaymentMethod'
  | 'amountResult'
> = {
  savedPaymentMethods: [],
  page: 'plans',
  selectedPlan: null,
  selectedSavedPaymentMethod: null,
  amountResult: {
    submittedAmountCents: currency(NICKEL_PRO_PRICE_IN_CENTS, {
      fromCents: true,
    }).multiply(12).intValue,
    reason: 'Nickel Pro Annual Subscription',
  },
}

export const useNickelPlansStore = create<NickelPlanState>((set, get) => ({
  ...initialState,
  setPage: (page: NickelPlansPage) => set({ page }),
  getPage: () => get().page,
  setSelectedPlan: (plan: NickelPlan) => set({ selectedPlan: plan }),
  setPaymentMethods: (paymentMethods: PaymentMethod[]) =>
    set({ savedPaymentMethods: paymentMethods }),
  setSelectedSavedPaymentMethod: (paymentMethod: PaymentMethod | null) =>
    set({ selectedSavedPaymentMethod: paymentMethod }),
  clearSelectedSavedPaymentMethod: () =>
    set({ selectedSavedPaymentMethod: null }),
  setPaymentSummary: (paymentSummary: PaymentSummary) =>
    set({ paymentSummary }),
  clearPaymentFees: () =>
    set({
      paymentSummary: new PaymentSummary(0, []),
    }),
  reset: () => set({ ...initialState }),
  setAmountResult: (amountResult: AmountResult) => set({ amountResult }),
}))
