import { defineStyleConfig } from '@chakra-ui/react'

export const selectTheme = defineStyleConfig({
  baseStyle: {
    field: {
      _hover: {
        borderColor: 'gray.600',
      },
      _focus: {
        borderColor: 'gray.800 !important',
        boxShadow: '0 0 0 1px #292524 !important',
      },
      _focusVisible: {
        borderColor: 'gray.800 !important',
        boxShadow: '0 0 0 1px #292524 !important',
      },
    },
  },
})
