import { tw } from '@nickeltech/brise'
import ContainerProps from '../../types'

export const PaymentPie = tw((props: ContainerProps) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="8" fill="#84E1BC" />
      <path
        d="M8 0C9.47538 1.75937e-08 10.922 0.407995 12.18 1.17888C13.438 1.94976 14.4582 3.0535 15.1281 4.36808C15.7979 5.68265 16.0911 7.15684 15.9753 8.62767C15.8596 10.0985 15.3393 11.5087 14.4721 12.7023L8 8L8 0Z"
        fill="#1F2A37"
      />
    </svg>
  )
})`
`

export default PaymentPie
