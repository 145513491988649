import {
  Body,
  Card,
  Icon,
  IconName,
  V2Column as Column,
  V2Row as Row,
} from 'ui'
import { Menu } from '@headlessui/react'
import { MouseEventHandler, useState } from 'react'
import { Flex } from '@chakra-ui/react'

export type FilterMenuItemProps = {
  label: string
  iconName: IconName
  value: string
  active?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

export type FilterMenuItem = FilterMenuItemProps & {
  component: React.ReactNode
}

export type FilterMenuProps = {
  items: FilterMenuItem[]
  selected?: number
  onClick?: (value: string) => void
}

export function FilterItem({
  iconName,
  label,
  active,
  onClick,
}: FilterMenuItemProps) {
  return (
    <Menu.Item>
      <Flex
        flexDirection="row"
        gap={2}
        p={2}
        borderRadius="md"
        background={active ? 'gray.200' : 'transparent'}
        _hover={{ background: 'gray.200' }}
        cursor="pointer"
        onClick={onClick}
        className="w-full"
      >
        <Icon name={iconName} />
        <Body size="small" className="whitespace-nowrap">
          {label}
        </Body>
      </Flex>
    </Menu.Item>
  )
}

export function FilterMenu({ items, selected, onClick }: FilterMenuProps) {
  const incomingSelection =
    (selected || 0) > items.length - 1 ? 0 : selected || 0

  const [selection, setSelection] = useState(incomingSelection)

  return (
    <Card className="w-[640px] h-[400px] shadow-sm w-fit min-w-fit">
      <Row hGrow>
        <Column
          spacingY="medium"
          spacingX="base"
          gap="small"
          grow
          wGrow
          className="bg-gray-100 rounded-l-md border-r border-gray-200 overflow-y-scroll min-w-fit"
        >
          {items.map((item, index) => {
            return (
              <FilterItem
                key={index}
                label={item.label}
                iconName={item.iconName}
                active={index == selection}
                value={item.value}
                onClick={(e) => {
                  e.preventDefault()
                  setSelection(index)
                  onClick && onClick(item.value)
                }}
              />
            )
          })}
        </Column>
        <Flex grow={1} maxW={'480px'} minW={'480px'}>
          {items[selection].component}
        </Flex>
      </Row>
    </Card>
  )
}

export default FilterMenu
